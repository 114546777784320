/* eslint-disable import/no-duplicates */
import React, { useMemo } from 'react';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useModal } from 'react-brave-modal';
import { Container, ExclusionDataCard, ExclusionReasonSection } from './styles';

import Button from '../../components/Button';

import { ReactComponent as DatePickerIconSVG } from '../../assets/images/date-picker.svg';
import { ReactComponent as ClockIconSVG } from '../../assets/images/icon_schedule_time_gray2.svg';
import { ReactComponent as PersonIconSVG } from '../../assets/images/icon_schedule_person_gray2.svg';
import { ReactComponent as StethoscopeIconSVG } from '../../assets/images/stethoscope-two.svg';
import { ReactComponent as StackCheckSVG } from '../../assets/images/stack-check.svg';
import AppointmentStatusEnum from '../../enums/AppointmentStatusEnum';
import AppointmentExclusionReasonEnum from '../../enums/AppointmentExclusionEnum';
import { appointmentExclusionReasons } from '../../utils/getAppointmentExclusionReason';
import BadgeAppointmentStatus from '../../pages/Appointments/List/Table/components/BadgeAppointmentStatus';

interface AppointmentDetailsModalProps {
  appointment: {
    date?: Date;
    patientName?: string;
    professionalName?: string;
    removedByUserName?: string;
    reason?: AppointmentExclusionReasonEnum;
    reasonDescription?: string;
  };
}

const AppointmentDetailsModal: React.FC<AppointmentDetailsModalProps> = ({
  appointment: {
    date,
    patientName,
    professionalName,
    reason,
    reasonDescription,
    removedByUserName,
  },
}) => {
  const { closeModal } = useModal();

  const appointmentHours = useMemo(() => {
    const scheduleTime = format(date ? new Date(date) : new Date(), 'HH:mm', {
      locale: ptBR,
    });

    if (scheduleTime) {
      const splittedTime = scheduleTime.split(':');
      const hours: string = splittedTime[0].slice(-2);
      const minutes: string = splittedTime[1];

      return `${hours}:${minutes}`;
    }

    return ':';
  }, [date]);

  const appointmentDate = useMemo(() => {
    return format(
      date ? new Date(date) : new Date(),
      "d 'de' MMMM 'de' yyyy ",
      { locale: ptBR },
    );
  }, [date]);

  return (
    <Container>
      <h1>Detalhes do agendamento</h1>
      <div>
        <ExclusionDataCard>
          <DatePickerIconSVG />
          <div>
            <strong>Data:</strong> <span>{appointmentDate}</span>
          </div>
          <ClockIconSVG />
          <div>
            <strong>Hora:</strong> <span>{appointmentHours}</span>
          </div>
          <PersonIconSVG />
          <div>
            <strong>Paciente:</strong> <span>{patientName}</span>
          </div>
          <StethoscopeIconSVG />
          <div>
            <strong>Profissional:</strong> <span>{professionalName}</span>
          </div>
          <StackCheckSVG />
          <div>
            <strong>Status:</strong>
            <BadgeAppointmentStatus type={AppointmentStatusEnum.REMOVED} />
          </div>
          <span>Removido por: {removedByUserName}</span>
        </ExclusionDataCard>
        <ExclusionReasonSection>
          <h4>Motivo da exclusão</h4>
          <strong>{reason && appointmentExclusionReasons(reason)}</strong>
          {reasonDescription && (
            <>
              <h4>Detalhes</h4>
              <textarea disabled value={reasonDescription} />
            </>
          )}
        </ExclusionReasonSection>
      </div>
      <Button buttonType="primary" onClick={() => closeModal()}>
        Fechar
      </Button>
    </Container>
  );
};

export default AppointmentDetailsModal;
