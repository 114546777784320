import { api } from '../api';
import { Institution } from '../../entities/Institution';
import FilterInstitutionDTO from '../../dtos/FilterInstitutionDTO';

export interface FilterInstitutionResponse {
  list: Institution[];
  total: number;
  totalPages: number;
  totalInstitutionsOnPage: number;
}

export const findInstitutionsByFilter = async (
  filter: FilterInstitutionDTO,
): Promise<FilterInstitutionResponse> => {
  const { data } = await api.get('/institutions/filter', {
    params: {
      ...filter,
    },
  });

  return data;
};
