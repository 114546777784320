import styled, { css } from 'styled-components';

interface SortButtonProps {
  isAscending: boolean;
  isActive: boolean;
}

interface DataProps {
  hasAnswers?: boolean;
  hasPending?: boolean;
}

export const Container = styled.div`
  margin-top: 60px;
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 40px;

  > span {
    margin-right: 20px;
  }
`;

export const Info = styled.div`
  font-weight: 500;

  strong {
    font-weight: 600;
  }

  strong:last-child {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const SheetList = styled.div`
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  margin-top: 20px;
  text-align: center;
  width: 100%;
`;

export const SheetListRow = styled.div`
  display: grid;
  grid-template-columns:
    minmax(40px, 5%) minmax(600px, 35%) minmax(160px, 20%) minmax(110px, 10%)
    minmax(110px, 10%) minmax(10px, 10%);

  > * {
    padding: 12px 10px;
  }
`;

export const SheetListHeader = styled(SheetListRow)`
  background-color: #eaecf0;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grayTertiary};
  font-size: 13px;
  font-weight: 600;
`;

export const SheetListBody = styled(SheetListRow)`
  background-color: ${({ theme }) => theme.colors.white};

  border-top: 1px solid ${({ theme }) => theme.colors.gray6};
`;

export const SheetListCheckboxCell = styled.div`
  justify-content: center;
  display: flex;

  > div {
    span {
      height: 18px !important;
      width: 18px !important;

      &:after {
        height: 6px !important;
        width: 3px !important;
        padding-top: 2px;
      }
    }
  }
`;

export const SheetListHeaderCell = styled.div`
  span {
    display: flex;
    align-items: center;
  }
`;

export const SheetListHeaderAssignedSheetCell = styled(SheetListHeaderCell)`
  span {
    justify-content: center;
  }
`;

export const SheetListBodyCell = styled.div`
  display: flex;
  align-items: center;
`;

export const SheetListBodySheetNameCell = styled(SheetListBodyCell)`
  color: ${({ theme }) => theme.colors.grayTertiary};
  font-size: 15px;
  font-weight: 600;
  text-align: left;

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SheetListBodyButtonCell = styled(SheetListBodyCell)`
  font-size: 14px;
  font-weight: 500;

  justify-content: center;
`;

export const SheetListBodyAssignedSheetCell = styled(
  SheetListBodyButtonCell,
)<DataProps>`
  a {
    text-decoration: none;

    color: ${({ hasAnswers, hasPending, theme }) =>
      hasAnswers || hasPending ? theme.colors.primary : theme.colors.gray};
  }

  svg {
    font-size: 22px;
    margin-left: 6px;
    margin-bottom: 2px;
    vertical-align: middle;
  }
`;

export const ViewButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: none;
  border: none;
  color: ${(props) => props.theme.colors.primary};

  span {
    font-size: 12px;
    text-transform: uppercase;
  }
`;

export const SortButton = styled.button<SortButtonProps>`
  background-color: transparent;
  border: none;
  margin-left: 8px;

  display: flex;
  align-items: center;

  > svg {
    ${(props) =>
      !props.isAscending
        ? css`
            > g > path {
              stroke: ${props.theme.colors.primary};
            }
          `
        : css`
            > path {
              stroke: ${props.theme.colors.primary};
            }
          `}

    ${({ isActive }) =>
      !isActive &&
      css`
        > path,
        > g > path {
          stroke: ${({ theme }) => theme.colors.grayTertiary};
        }
      `}
  }
`;

export const ContentNothingToDisplayBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  height: 400px;
  margin-top: 30px;
`;
