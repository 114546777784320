/* eslint-disable import/no-duplicates */
import { addHours, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useModal } from 'react-brave-modal';
import Axios from 'axios';
import { toast } from 'react-toastify';
import ModalButton from '../../components/ModalButton';
import { usePatient } from '../../hooks/patient';

import { Container } from './styles';
import FormioComponent, {
  FormioComponentRef,
} from '../../components/FormioComponent';
import Spinner from '../../components/Spinner';
import { SheetSubmission } from '../../entities/SheetSubmission';
import { useCurrentEffect } from '../../lib/use-current-effect';
import { getTokenSheet } from '../../services/sheet';

interface SheetSubmissionModalProps {
  id: string;
  submissionId?: string;
  type: 'view' | 'edit';
}

const SheetSubmissionModal: React.FC<SheetSubmissionModalProps> = ({
  id,
  submissionId,
  type,
}) => {
  const { patient } = usePatient();

  const formRef = useRef<FormioComponentRef>(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { closeModal } = useModal();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [submission, setSubmission] = useState<any>();

  const sheetData = useMemo(
    () => patient?.sheets?.find((sheet) => sheet.id === id),
    [patient, id],
  );

  const sheetSubmissionData = useMemo(() => {
    let data: SheetSubmission | undefined;

    if (submissionId && sheetData) {
      data = sheetData?.sheetSubmissions.find(
        (sheetSubmission) => sheetSubmission.submissionId === submissionId,
      );
    } else {
      data = sheetData?.sheetSubmissions
        ? sheetData?.sheetSubmissions[sheetData.sheetSubmissions.length - 1]
        : undefined;
    }

    return data;
  }, [sheetData, submissionId]);

  const getSubmission = useCallback(
    async (idSubmission: string, isCurrent: () => boolean) => {
      const token = await getTokenSheet();

      if (sheetData?.sheet.formId) {
        const submissionData = await Axios.get(
          `${process.env.REACT_APP_FORMIO_URL}/${sheetData?.sheet.formId}/submission/${idSubmission}`,
          {
            headers: {
              'x-jwt-token': `${token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (isCurrent()) {
          setSubmission(submissionData.data);
        }
      }
    },
    [sheetData],
  );

  useCurrentEffect(
    (isCurrent) => {
      (async () => {
        await getSubmission(
          (submissionId || sheetSubmissionData?.submissionId) ?? '',
          isCurrent,
        );
      })();
    },
    [getSubmission, sheetSubmissionData, submissionId],
  );

  const handleSave = useCallback(() => {
    setIsButtonDisabled(true);

    formRef.current?.submit();

    setIsButtonDisabled(false);

    closeModal();
    toast.info('Ficha editada com sucesso!');
  }, [closeModal]);

  return (
    <Container>
      <header>
        <h1>{sheetData?.sheet.name}</h1>
        <section>
          <div>
            <h2>Paciente</h2>
            <h3>{patient?.user?.name}</h3>
          </div>
          <div>
            <h2>Data</h2>
            <h3>
              {sheetSubmissionData?.created_at &&
                format(
                  addHours(new Date(sheetSubmissionData?.created_at), -3),
                  "dd'/'MM'/'yyyy' - 'HH':'mm",
                  { locale: ptBR },
                )}
            </h3>
          </div>
          <div>
            <h2>Profissional de saúde</h2>
            <h3>{sheetSubmissionData?.professional?.user?.name}</h3>
          </div>
        </section>
      </header>
      <section>
        {submission ? (
          <FormioComponent
            ref={formRef}
            url={`${process.env.REACT_APP_FORMIO_URL}/${sheetData?.sheet.formId}?header=1&reset=1&theme=lumen`}
            options={{ readOnly: type === 'view' }}
            submission={submission}
          />
        ) : (
          <Spinner />
        )}
      </section>
      <footer>
        <ModalButton styleType="blue-light" onClick={() => closeModal()}>
          Fechar
        </ModalButton>
        {type === 'edit' && (
          <ModalButton
            loading={isButtonDisabled}
            styleType="blue"
            onClick={handleSave}
          >
            Salvar
          </ModalButton>
        )}
      </footer>
    </Container>
  );
};

export default SheetSubmissionModal;
