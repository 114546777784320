import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { IoMdSearch } from 'react-icons/io';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  ComponentsInstitutionRegisterEditProps,
  ComponentsInstitutionRegisterEditRef,
} from '..';
import InputMaskAlternative from '../../../../components/InputMaskAlternative';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import RequiredFieldInformation from '../../../../components/RequiredFieldInformation';
import useCep from '../../../../hooks/cep';
import { useInstitution } from '../../../../hooks/institution';
import { useCurrentCallback } from '../../../../lib/use-current-effect';
import getUF from '../../../../utils/getUF';
import getValidationErrors from '../../../../utils/getValidationErrors';

import { Container, GridContent, Field } from './styles';

interface FormProps {
  cep: string;
  street: string;
  number: string;
  complement?: string;
  city: string;
  neighborhood: string;
  uf: string;
}

const Address: React.ForwardRefRenderFunction<
  ComponentsInstitutionRegisterEditRef,
  ComponentsInstitutionRegisterEditProps
> = ({ onSaveSucceed }, ref) => {
  const formRef = useRef<FormHandles>(null);
  const [ufProps] = useState(getUF);
  const { getAddressData, addressData, error } = useCep();
  const { institution, saveLocal } = useInstitution();

  const handleCepSearch = useCallback(async () => {
    await getAddressData(formRef.current?.getFieldValue('cep'));
  }, [getAddressData]);

  useEffect(() => {
    if (addressData) {
      formRef.current?.setData({
        street: addressData.street,
        neighborhood: addressData.neighborhood,
        city: addressData.city,
        uf: addressData.uf,
        complement: addressData.complement,
        cep: addressData.cep,
      });
    }
  }, [addressData]);

  useEffect(() => {
    if (error) {
      formRef.current?.clearField('street');
      formRef.current?.clearField('neighborhood');
      formRef.current?.clearField('city');
      formRef.current?.setFieldValue('uf', '');
      formRef.current?.clearField('complement');

      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (institution?.address) {
      formRef.current?.setData({
        street: institution.address.street,
        neighborhood: institution.address.neighborhood,
        city: institution.address.city,
        uf: institution.address.uf,
        complement: institution.address.complement,
        cep: institution.address.cep,
        number: institution.address.number,
      });
    }
  }, [institution]);

  const getValidate = useCallback(async () => {
    formRef.current?.setErrors({});

    const formData = formRef.current?.getData() as FormProps;

    try {
      const schema = Yup.object().shape({
        street: Yup.string().required('A rua é obrigatória'),
        number: Yup.string().required('O número é obrigatório'),
        complement: Yup.string(),
        cep: Yup.string()
          .test(
            'length',
            'Digite um CEP válido',
            (value) => !!(!value || value.length === 9),
          )
          .required('O CEP é obrigatório.'),
        city: Yup.string().required('A cidade é obrigatório'),
        neighborhood: Yup.string().required('O bairro é obrigatório'),
        uf: Yup.string().required('O estado é obrigatório'),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      } else if (err.response) {
        toast.error(err.response.data.message);
      } else if (err.message) {
        toast.error(err.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }

      return false;
    }
  }, []);

  const handleChange = useCurrentCallback(
    () => async () => {
      const data = formRef.current?.getData() as FormProps;

      if (institution) {
        saveLocal({
          address: {
            ...data,
          },
        });
      }

      return true;
    },
    [],
  );

  useImperativeHandle(ref, () => ({
    back: handleChange,
    cancel: async () => true,
    next: handleChange,
    save: async () => true,
    canSave: getValidate,
  }));

  return (
    <Container>
      <h1>Endereço</h1>
      <Form ref={formRef} onSubmit={() => {}}>
        <GridContent>
          <Field gridArea="ce">
            <label>
              CEP
              <RequiredFieldInformation />
            </label>
            <div className="cep">
              <InputMaskAlternative
                mask="99999-999"
                placeholder="00000-000"
                title=""
                type="text"
                name="cep"
              />
              <button type="button" onClick={handleCepSearch}>
                <IoMdSearch />
              </button>
            </div>
          </Field>
          <Field gridArea="ru">
            <label>
              Rua, Avenida
              <RequiredFieldInformation />
            </label>
            <InputWithoutTitle name="street" />
          </Field>
          <Field gridArea="nu">
            <label>
              Número
              <RequiredFieldInformation />
            </label>
            <InputWithoutTitle name="number" type="number" />
          </Field>
          <Field gridArea="cm">
            <label>Complemento</label>
            <InputWithoutTitle name="complement" />
          </Field>
          <Field gridArea="ci">
            <label>
              Cidade
              <RequiredFieldInformation />
            </label>
            <InputWithoutTitle name="city" />
          </Field>
          <Field gridArea="di">
            <label>
              Bairro
              <RequiredFieldInformation />
            </label>
            <InputWithoutTitle name="neighborhood" />
          </Field>
          <Field gridArea="uf">
            <label>
              Estado
              <RequiredFieldInformation />
            </label>
            <InputSelectAlternative
              name="uf"
              options={ufProps}
              placeholder=""
            />
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default forwardRef(Address);
