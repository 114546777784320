import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import { BiSearchAlt2 } from 'react-icons/bi';
import { FormHandles } from '@unform/core';

import InputWithoutTitle from '../../../../../components/InputWithoutTitle';

import { Container, Form } from './styles';

interface FilterItems {
  name: string;
}

export interface FilterProfileListRef {
  clear: () => void;
}

interface FilterProfileListProps {
  type: 'view' | 'create';
  onSearch?: (items: FilterItems) => void;
  onChangePage: (newPage: number) => void;
}

const FilterProfileList: React.ForwardRefRenderFunction<
  FilterProfileListRef,
  FilterProfileListProps
> = ({ onSearch, onChangePage }, ref) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    (data: FilterItems) => {
      onChangePage(1);

      if (onSearch) {
        onSearch(data);
      }
    },
    [onSearch, onChangePage],
  );

  const handleClear = useCallback(() => {
    formRef.current?.clearField('name');
  }, []);

  useImperativeHandle(ref, () => ({
    clear: handleClear,
  }));

  return (
    <Container>
      <h1>Buscar Usuário</h1>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div>
          <label>Nome do Usuário</label>
          <InputWithoutTitle name="name" />
        </div>
      </Form>
      <footer>
        <button type="button" onClick={() => formRef.current?.submitForm()}>
          <BiSearchAlt2 />
          Buscar
        </button>
      </footer>
    </Container>
  );
};

export default forwardRef(FilterProfileList);
