/* eslint-disable import/no-duplicates */
import React from 'react';
import { FaRegCalendar } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Container, IconArea, Content } from './styles';

interface NotificationItemProps {
  key: string;
  read: boolean;
  title: string;
  message: string;
  date: Date;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  read,
  title,
  message,
  date,
}) => {
  return (
    <Container read={read}>
      <IconArea read={read}>
        <FaRegCalendar size={25} />
      </IconArea>

      <Content>
        <strong>{title}</strong>
        <p>{message}</p>
        <span>
          {formatDistanceToNow(date, {
            addSuffix: true,
            locale: ptBR,
          })}
        </span>
      </Content>
    </Container>
  );
};

export default NotificationItem;
