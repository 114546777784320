import styled from 'styled-components';

export const Container = styled.div``;

export const ProfilesSection = styled.section`
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(6, 92, 119, 0.08);
  border-radius: 6px;
  padding: 35px 25px;
  margin-top: 20px;
  min-height: 80vh;

  > h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #4f4f4f;
  }
`;

export const ProfileList = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
`;

export const ProfileItem = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 25px 35px;
`;

export const ProfileItemInformations = styled.div`
  > h1 {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 18px;
    color: #4f4f4f;
    line-height: 20px;
  }

  > h2 {
    font-weight: 500;
    font-size: 14px;
    color: #707683;
    margin-top: 5px;
    line-height: 20px;
  }

  > button {
    font-weight: bold;
    background: transparent;
    border: 0;
    font-size: 18px;
    color: #1f80b7;
    margin-top: 15px;
    line-height: 20px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
`;

export const ProfileItemImages = styled.div`
  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid white;
    object-fit: cover;
  }

  img + img {
    margin-left: -10px;
  }
`;
