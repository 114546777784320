import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface ColorNumberProps {
  color: 'primary' | 'black';
}

interface StyledLinkProps {
  disabled?: boolean;
}

interface ChangeOrderButtonProps {
  isActive: boolean;
  ordenationType: 'ASC' | 'DESC';
}

interface RiskProps {
  color?: string;
}

interface PatientListBodyRow {
  disabled?: boolean;
}

interface PatientListBodyCellProps {
  alignItens?: 'flex-start' | 'center' | 'flex-end';
}

interface ActionLinkTableProps {
  disabled?: boolean;
}

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > section {
    display: flex;
    flex-direction: row;

    > div {
      margin: 10px;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 40px;

  > span {
    margin-right: 20px;
  }
`;

export const ActionLinkTable = styled(Link)<ActionLinkTableProps>`
  display: flex;
  align-items: center;

  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  span {
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 10px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const ColorNumber = styled.span<ColorNumberProps>`
  font-weight: bolder;
  color: ${({ color, theme }) => theme.colors[color]};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 360px;
  border-radius: 20px;
  margin-top: 21px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  > h1 {
    font-family: 'Raleway';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const PatientList = styled.div`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 400px;

  font-family: Raleway;
  font-size: 14px;
`;

export const PatientListRow = styled.div`
  display: grid;

  padding: 24px 16px;

  grid-template-columns:
    minmax(30px, 4%) minmax(200px, 29%) minmax(80px, 8%) minmax(85px, 8%) minmax(
      90px,
      11%
    )
    minmax(140px, 16%) minmax(105px, 10%) minmax(130px, 7%) minmax(60px, 7%);

  > * {
    padding: 0 10px;
  }
`;

export const PatientListHeaderRow = styled(PatientListRow)`
  background-color: #eaecf0;
`;

export const PatientListBodyCell = styled.div<PatientListBodyCellProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ alignItens }) => alignItens || 'flex-start'};

  > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const PatientListBodyOptionsCell = styled(PatientListBodyCell)``;

export const PatientListBodyHealthConditionCell = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 100%;

    > div:first-child {
      min-height: 32px;
      min-width: 32px;
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const PatientListBodyRow = styled(PatientListRow)<PatientListBodyRow>`
  padding: 18px 16px;
  border-bottom: 1px solid #dddddd;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.white};

      > :not(${PatientListBodyOptionsCell}) {
        opacity: 0.6;
      }
    `}
`;

export const TitleWithButton = styled.div`
  display: flex;
  align-items: center;

  strong {
    font-weight: 600;
  }
`;

export const ChangeOrderButton = styled.button<ChangeOrderButtonProps>`
  background: 0;
  border: 0;
  display: flex;
  margin-left: 10px;

  > svg {
    ${({ ordenationType }) => {
      if (ordenationType === 'ASC') {
        return css`
          > path {
            stroke: #1a7fb8;
          }
        `;
      }

      return css`
        > g > path {
          stroke: #1a7fb8;
        }
      `;
    }}

    ${({ isActive }) =>
      !isActive &&
      css`
        > path {
          stroke: #707683;
        }

        > g > path {
          stroke: #707683;
        }
      `}
  }
`;

export const PatientNameTable = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;

  img {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    border-radius: 6px;
    color: #707683;
    font-size: 9px;
    font-weight: 700;
    margin-left: 15px;
    padding: 10px 8px;
    text-transform: uppercase;
  }

  > svg {
    width: 13px;
    height: 13px;
  }
`;

export const RiskTable = styled.div<RiskProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;

  width: 37px;
  height: 19px;
  border-radius: 6px;

  background-color: ${(props) => (props.color ? props.color : '#ea7676')};

  h1 {
    display: flex;
    justify-content: center;
    margin: 2px;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  display: flex;
  align-items: center;

  overflow: hidden;

  max-width: min-content;

  text-decoration: none;

  &:hover {
    strong {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}

  img {
    height: 30px;
    width: 30px;
    border-radius: 15px;
  }

  strong {
    overflow: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;

    width: 100%;

    margin-right: 7px;

    font-weight: 600;

    color: ${({ theme }) => theme.colors.gray3};

    transition: color 0.7s;
  }
`;
