import styled, { css, keyframes } from 'styled-components';

import DoctorWithStectoscopeImage from '../../assets/images/doctor-with-stethoscope-in-the-hands-and-hospital-background.png';

import CheckBulletListIcon from '../../assets/images/check-bullet-list.svg';

interface CommonBannerProps {
  isDoctor: boolean;
  currentPage?: 'signIn' | 'signUp';
}

const appearFromUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const CommonBanner = styled.div<CommonBannerProps>`
  ${({ isDoctor }) => css`
    background: linear-gradient(
        200.53deg,
        rgba(27, 215, 217, 0.7) 2.14%,
        rgba(45, 156, 219, 0.7) 102.27%
      ),
      url(${DoctorWithStectoscopeImage}) no-repeat;
  `}

  ${({ isDoctor }) =>
    isDoctor &&
    css`
      > img {
        position: absolute;
        top: 57px;
        left: 0;
        z-index: 9999;
      }
    `}

  background-size: cover;
  background-position: center;
  position: relative;
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;

  > div {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BannerHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${({ theme }) => theme.colors.white};

  margin-right: 59px;
  margin-top: 45px;

  > span {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 8px;
  }

  > h1 {
    color: ${({ theme }) => theme.colors.gray4};

    font-weight: bold;
    font-size: 38px;
    line-height: 130%;
  }
`;

export const BannerContent = styled.div<CommonBannerProps>`
  animation: ${appearFromUp} 1s;

  ${({ currentPage }) =>
    currentPage === 'signUp'
      ? css`
          display: flex;
          align-self: flex-start;
          flex-direction: column;
          margin-top: 70px;
          margin-left: 74px;
          margin-right: 161px;
          color: ${({ theme }) => theme.colors.white};

          > ul {
            list-style-position: inside;
            margin-bottom: 30px;

            > li {
              display: inline-block;
              font-size: 18px;
              line-height: 27px;
              margin: 15px auto;
              background: url(${CheckBulletListIcon}) no-repeat left top;
              padding: 0 0 0 35px;
              list-style: none;

              > strong {
                font-weight: bold;
              }
            }
          }

          > strong + ul {
            margin-top: 26px;
          }

          > h2 {
            font-weight: bold;
            font-size: 28px;
            line-height: 50px;
          }

          > span {
            font-weight: normal;
          }

          > strong {
            margin-top: 54px;
            font-weight: bold;
          }
        `
      : css`
          position: absolute;
          top: 40vh;
          left: 28%;
          max-width: 44%;
          color: ${({ theme }) => theme.colors.white};
          text-align: center;

          > span {
            display: inline-block;

            font-size: 24px;
            font-weight: 500;
            line-height: 150%;
          }
        `}
`;
