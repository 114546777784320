import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface StyledLinkProps {
  disabled?: boolean;
}
interface SortButtonProps {
  isAscending: boolean;
  isActive: boolean;
}

export const Container = styled.div`
  margin-top: 62px;
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
`;

export const Info = styled.div`
  font-weight: 500;

  strong {
    font-weight: 600;
  }

  strong:last-child {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const TableBox = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  margin-top: 20px;
  padding: 22px 30px;
`;

export const PendenciesList = styled.div`
  width: 100%;
`;

export const TableRow = styled.div`
  display: grid;

  padding: 18px 16px;

  grid-template-columns: minmax(390px, 65%) minmax(150px, 25%) minmax(60px, 10%);

  > * {
    padding: 0 10px;
  }
`;

export const PendenciesListHeaderRow = styled(TableRow)`
  background-color: #eaecf0;
  border-radius: 4px;
  color: #707683;
  font-size: 13px;
  font-weight: 600;

  span {
    display: flex;
    align-items: center;
  }
`;

export const PendenciesListBodyRow = styled(TableRow)`
  padding: 18px 16px;

  color: #707683;
  font-size: 14px;

  & + & {
    border-top: 1px solid #dddddd;
  }
`;

export const PendenciesListBodyCell = styled.div`
  display: flex;
  align-items: center;

  font-weight: 500;

  &:last-child {
    justify-content: center;
  }

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const PatientNameCell = styled(PendenciesListBodyCell)`
  font-weight: 600;
  overflow: hidden;
`;

export const VisualizeButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  text-transform: uppercase;

  svg {
    font-size: 22px;
    margin-bottom: 2px;
  }
`;

export const SortButton = styled.button<SortButtonProps>`
  background-color: transparent;
  border: none;
  margin-left: 8px;

  > svg {
    ${(props) =>
      !props.isAscending
        ? css`
            > g > path {
              stroke: ${props.theme.colors.primary};
            }
          `
        : css`
            > path {
              stroke: ${props.theme.colors.primary};
            }
          `}

    ${({ isActive }) =>
      !isActive &&
      css`
        > g > path {
          stroke: #707683;
        }

        > path {
          stroke: #707683;
        }
      `}
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  font-weight: 600;
  color: #707683;
  line-height: 18px;
  letter-spacing: 0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-decoration: none;

  transition: color 0.7s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`;

export const ContentNothingToDisplayBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  height: 400px;
  margin-top: 30px;
`;
