import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps {
  name: string;
  onChange?: Function;
  defaultChecked?: boolean;
  disabled?: boolean;
}

const InputSwitch: React.FC<InputProps> = ({
  name,
  onChange,
  defaultChecked,
  disabled,
}) => {
  const { fieldName, defaultValue, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <label className="switch">
        <input
          ref={inputRef}
          type="checkbox"
          onChange={(value) => onChange && onChange(value.target.checked)}
          defaultChecked={defaultValue || defaultChecked}
          disabled={disabled}
        />
        <span className="slider round" />
      </label>
    </Container>
  );
};

export default InputSwitch;
