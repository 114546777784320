import { useState, useCallback } from 'react';
import Axios from 'axios';
import { useCurrentCallback } from '../lib/use-current-effect';

interface AddressData {
  cep: string;
  street: string;
  complement: string;
  neighborhood: string;
  city: string;
  uf: string;
}

interface ApiResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  erro: boolean;
}

const useCep = () => {
  const [addressData, setAddressData] = useState<AddressData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const clearCep = useCallback((cep: string) => cep.replace(/\D/g, ''), []);

  const validateCep = useCallback((cep: string) => {
    const expression = /^[0-9]{8}$/;

    if (!cep) {
      throw new Error('CEP não informado.');
    }

    if (!expression.test(cep)) {
      throw new Error('CEP inválido.');
    }
  }, []);

  const checkCepData = useCallback((data: ApiResponse) => {
    if (data.erro) {
      throw new Error('CEP não encontrado.');
    }
  }, []);

  const getAddressData = useCurrentCallback(
    () => async (cep: string) => {
      const cepOnlyDigits = clearCep(cep);

      setLoading(true);
      setError(undefined);

      try {
        validateCep(cepOnlyDigits);

        const { data } = await Axios.get<ApiResponse>(
          `https://viacep.com.br/ws/${cepOnlyDigits}/json`,
        );

        checkCepData(data);

        setAddressData({
          cep: data.cep,
          street: data.logradouro,
          complement: data.complemento,
          neighborhood: data.bairro,
          city: data.localidade,
          uf: data.uf,
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    },
    [clearCep, validateCep, checkCepData],
  );

  return {
    getAddressData,
    addressData,
    loading,
    error,
  };
};

export default useCep;
