import styled, { css } from 'styled-components';
import { CardHeaderDefault } from '../../styles';

interface ButtonSelectProps {
  selected?: boolean;
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 15px 0 0;
  max-height: 180px;

  > h4 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #2289c4;
  }

  > span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #808080;
  }
`;

export const CardHeader = styled(CardHeaderDefault)`
  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    padding: 3px 3px;
    background: none;
    border: none;
  }
`;

export const ButtonSelect = styled.button<ButtonSelectProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0;
  min-height: 70px;
  min-width: 84px;
  transition: background 0.4s;
  background: ${({ selected }) => (selected ? '#5AB1E3' : '#f8f8f8')};
  border-radius: 15px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${({ selected }) => (selected ? '#fff' : '#707683')};
  margin: 5px 0;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 17px;
    margin-bottom: 5px;
  }

  & + button {
    margin-left: 15px;
  }

  &:hover {
    background: ${({ selected, disabled }) =>
      !selected && !disabled && '#f5f5f5'};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;
