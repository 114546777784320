import styled from 'styled-components';

export const Container = styled.div`
  width: 547px;
  height: 490px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 15%;
  text-align: center;

  img {
    margin-bottom: 35px;
  }

  h1 {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 12px;
  }

  p {
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    text-align: center;

    color: ${({ theme }) => theme.colors.graySecondary};
  }

  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    width: 100%;
    margin-top: 35px;
  }
`;
