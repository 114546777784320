import React, { useMemo } from 'react';
import { useModal } from 'react-brave-modal';

import Button from '../../../../components/Button';

import { Container } from './styles';

import WarningImage from '../../../../assets/images/exclamation.svg';
import CustomErrorEnums from '../../../../enums/CustomErrorsEnum';

interface NotPossibleToAttendModalProps {
  onClose: () => void;
  errorKey?: CustomErrorEnums;
}

const Error: React.FC<NotPossibleToAttendModalProps> = ({
  onClose,
  errorKey,
}) => {
  const { closeModal } = useModal();

  const displayContent = useMemo(() => {
    if (errorKey === CustomErrorEnums.PATIENT_ON_ATTENDANCE) {
      return {
        title: 'O paciente está em outro atendimento',
        content:
          'Este paciente já possui um atendimento em andamento agora. Verifique o status dos atendimentos no menu Agendamento.',
        lastPhrase:
          'Para iniciar um novo atendimento, é necessário finalizar o ativo.',
      };
    }

    if (errorKey === CustomErrorEnums.PROFESSIONAL_ON_ATTENDANCE) {
      return {
        title: 'Você já tem um atendimento ativo',
        content:
          'O profissional de saúde só pode ter uma consulta em andamento. Verifique o status dos atendimentos no menu Agendamento.',
        lastPhrase:
          'Para iniciar um novo atendimento, é necessário finalizar o ativo.',
      };
    }

    return {
      title: 'O atendimento não pode ser iniciado',
      content:
        'Infelizmente um erro inesperado aconteceu. Você pode verificar o status da sua conexão ou tentar novamente.',
      lastPhrase: 'Se o erro persistir, entre em contato com o suporte.',
    };
  }, [errorKey]);

  return (
    <Container>
      <img src={WarningImage} alt="Imagem de aviso" />
      <div>
        <h1>{displayContent.title}</h1>
        <p>{displayContent.content}</p>
        <p>{displayContent.lastPhrase}</p>
      </div>
      <Button
        buttonType="primary-alternative"
        onClick={() => {
          onClose();
          closeModal();
        }}
      >
        Ok, Entendi
      </Button>
    </Container>
  );
};

export default Error;
