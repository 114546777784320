import { api } from '../api';
import { Icd10 } from '../../entities/Icd10';

interface SearchIcd10ByKeywordResponse {
  data: Icd10[];
  total: number;
}
interface GetAllHealthConditionFromPatientsProps {
  limit: number;
  page: number;
  keyword?: string;
}

export const getAllHealthConditionFromPatients = async ({
  limit,
  page,
  keyword,
}: GetAllHealthConditionFromPatientsProps): Promise<SearchIcd10ByKeywordResponse> => {
  const { data } = await api.get<SearchIcd10ByKeywordResponse>(
    'patients/icd10/all',
    {
      params: {
        keyword,
        limit,
        page,
      },
    },
  );

  return data;
};
