import styled, { css } from 'styled-components';

import ButtonBase from '../../../components/Button';
import InputSelectAlternative from '../../../components/InputSelectAlternative';

import datePicker from '../../../assets/images/date-picker.svg';

interface GridItemProps {
  gridArea:
    | 'no'
    | 'cpf'
    | 'dn'
    | 'gn'
    | 'em'
    | 'cl'
    | 'ep'
    | 'idp'
    | 'bu'
    | 'but';
}

interface ButtonProps {
  lite?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  color: 'white' | 'primary';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 300px; //360px

  margin-top: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-top: 30px;

  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  > h1 {
    font-family: 'Raleway';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    color: #4f4f4f;
  }

  .datepicker {
    color: ${(props) => props.theme.colors.graySecondary};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const FormContainer = styled.div`
  width: 100%;
  padding-right: 80px;
  /* margin-bottom: 100px; */

  > h1 {
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;

    color: #707683;
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(13, 1fr);
  grid-template-areas:
    'no no no no no cpf cpf cpf dn dn dn gn gn'
    'em em em em em em cl cl cl ep ep ep bu';
  /* 'idp idp idp idp but but none none none none none none none'; */

  grid-column-gap: 45px;
  grid-row-gap: 35px;

  margin-top: 28px;
  width: 100%;
  height: 100%;
`;

export const Field = styled.div<GridItemProps>`
  display: flex;
  flex-direction: column;
  grid-area: ${({ gridArea }) => gridArea};

  > label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.01em;

    color: #707683;

    > span {
      color: ${({ theme }) => theme.colors.danger};
      font-size: 18px;
      font-weight: 600;
      line-height: 0.8;
    }
  }

  > div {
    margin-top: 4px;

    > input[type='date']::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      background: url(${datePicker}) no-repeat;
      background-size: 75%;
      width: 24px;
      height: 24px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    max-height: 43px;
  }
`;

export const InputSelect = styled(InputSelectAlternative)`
  > div:first-child {
    border: 1px solid ${(props) => props.theme.colors.gray2};
    border-width: 1px !important;

    &:focus {
      border-width: 1px !important;
    }
  }

  > div .react-select__control {
    border-width: 1px !important;

    &:focus {
      border-width: 1px !important;
    }
  }

  &:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }
`;

export const Button = styled(ButtonBase)<ButtonProps>`
  display: flex;
  align-items: center;
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.colors.secondary} 0%,
    ${(props) => props.theme.colors.primary} 100%
  );

  border-radius: 100px;
  border: 0;
  padding: 16px 48px;
  color: #fff;
  margin-top: 0px !important;
  transition: all 0.6s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  letter-spacing: 1.25px;

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #828282;

  &:hover {
    background: ${(props) => props.theme.colors.primary};
  }

  ${({ color, theme }) =>
    color === 'white' &&
    css`
      background: ${theme.colors.white};
      color: #828282;

      &:hover {
        color: ${theme.colors.white};
      }
    `}

  ${({ color }) =>
    color === 'primary' &&
    css`
      color: #ffffff;
    `}

  ${({ isDisabled, color, theme }) =>
    !isDisabled &&
    color === 'primary' &&
    css`
      &:hover {
        background: ${theme.colors.primary};
      }
    `}

  ${({ isDisabled, color }) =>
    isDisabled &&
    color === 'primary' &&
    css`
      opacity: 0.5;

      cursor: default;
      transition: none;
    `}

  ${({ lite }) =>
    lite &&
    css`
      border: 1px solid #2d9cdb;
      color: #2d9cdb;

      background: #f5f6f8;
    `}

  & ~ & {
    margin-left: 20px;
  }
`;

export const SearchButton = styled.button<GridItemProps>`
  display: flex;
  justify-items: center;
  align-items: center;

  grid-area: ${({ gridArea }) => gridArea};

  border: 0;
  background: none;

  max-height: 43px;
  margin-top: 18px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;

  color: #2d9cdb;

  > img {
    margin-right: 10px;
  }
`;
