import { api } from '../api';
import getBase64 from '../../utils/getBase64';
import { ExamProcedure } from '../../entities/ExamProcedure';

interface UpdateExamProcedure {
  id: string;
  patientId: string;
  professionalId: string;
  examType: string;
  name: string;
  description: string;
  file: File;
}

export const updateExamProcedure = async ({
  id,
  patientId,
  professionalId,
  examType,
  name,
  description,
  file,
}: UpdateExamProcedure): Promise<ExamProcedure> => {
  let fileInBase64: string | undefined;

  if (file) {
    fileInBase64 = await getBase64(file);
  }

  const { data } = await api.put<ExamProcedure>(
    `/patients/examProcedure/${id}`,
    {
      patientId,
      professionalId,
      examType,
      name,
      description,
      file: fileInBase64,
    },
  );

  return data;
};
