import { CreateAndUpdateProfessionalScheduleRequestDTO } from '../../dtos/CreateProfessionalScheduleDTO';
import { ProfessionalSchedule } from '../../entities/ProfessionalSchedule';
import { api } from '../api';

export const updateProfessionalSchedule = async ({
  createdUpdatedBy,
  professionalId,
  professionalScheduleAvailabilitiesData,
  scheduleCustomType,
}: CreateAndUpdateProfessionalScheduleRequestDTO): Promise<ProfessionalSchedule> => {
  const { data } = await api.put('/professionalSchedule', {
    createdUpdatedBy,
    professionalId,
    professionalScheduleAvailabilitiesData,
    scheduleCustomType,
  });

  return data;
};
