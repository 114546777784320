import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as BasePersonIcon } from '../../../../assets/images/icon_schedule_person_gray2.svg';
import { ReactComponent as BaseStethoscopeIcon } from '../../../../assets/images/stethoscope.svg';

type GridArea = 'ick' | 'dta' | 'pt' | 'pf' | 'oap' | 'tpa' | 'sta' | 'act';

interface TableStyledCellProps {
  gridArea: GridArea;
  align: 'center' | 'flex-start';
  isRemoved?: boolean;
}
interface ColorNumberProps {
  color: 'primary' | 'black';
}
interface PaginateButtonProps {
  isActual: boolean;
}

interface ChangeOrderButtonProps {
  isActive: boolean;
  ordenationType: 'ASC' | 'DESC';
}

interface StyledLinkProps {
  disabled?: boolean;
}

export const PhotoRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  span {
    width: 100%;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    margin-right: 10px;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  text-decoration: none;
  color: ${(props) => props.theme.colors.graySecondary};

  max-width: 100%;

  width: min-content;
  transition: color 0.7s;

  &:hover {
    color: #1f80b7;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`;

export const ColorNumber = styled.span<ColorNumberProps>`
  font-weight: bolder;
  ${(props) =>
    props.color === 'black' &&
    css`
      color: ${props.theme.colors.black};
    `}

  ${(props) =>
    props.color === 'primary' &&
    css`
      color: ${props.theme.colors.primary};
    `}
`;

export const ChangeOrderButton = styled.button<ChangeOrderButtonProps>`
  display: flex;

  background: 0;
  border: 0;
  margin-left: 10px;

  > svg {
    ${({ ordenationType }) => {
      if (ordenationType === 'DESC') {
        return css`
          > path {
            stroke: #ffffff;
          }
          > g > path {
            stroke: #1a7fb8;
          }
        `;
      }

      return css`
        > path {
          stroke: #1a7fb8;
        }
        > g > path {
          stroke: #ffffff;
        }
      `;
    }}

    ${({ isActive }) =>
      !isActive &&
      css`
        > path {
          stroke: #ffffff;
        }

        > g > path {
          stroke: #ffffff;
        }
      `}
  }
`;

export const StethoscopeIcon = styled(BaseStethoscopeIcon)`
  > path {
    fill: #fff;
  }
`;

export const PersonIcon = styled(BasePersonIcon)`
  > path {
    stroke: #fff;
  }
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 8px;
  margin-top: 20px;

  > section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    :first-child {
      > div {
        :first-child {
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 19px;

          color: #707683;
        }
      }
    }

    :nth-child(2) {
      align-items: center;
      > div {
        :first-child {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;

          letter-spacing: 0.01em;

          color: #4f4f4f;
        }
      }
    }

    :nth-child(3) {
      flex: 1;
      justify-content: flex-end;
      align-items: center;

      margin-right: 40px;

      span {
        margin-right: 20px;
      }
    }

    > div {
      margin: 10px;

      white-space: nowrap;

      > svg {
        margin-right: 10px;
      }
    }

    > span {
      margin: 10px;
    }
  }
`;

export const ShowBtn = styled.button`
  font-size: 17px;
  margin: 0px 20px;
  max-height: 25px;
  color: #a4a9b4;
  font-weight: bolder;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px solid #a4a9b4;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;

  &:disabled {
    ${({ theme }) => css`
      color: ${theme.colors.primary};
      border-bottom: 1px solid ${theme.colors.primary};
    `}
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 360px;
  border-radius: 20px;
  margin: 0px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  > h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4f4f4f;
  }
`;

export const TableStyledGrid = styled.div`
  display: grid;
  grid-template-columns:
    minmax(50px, 5%)
    minmax(145px, 13%)
    minmax(210px, 21%)
    minmax(210px, 21%)
    minmax(80px, 8%)
    minmax(150px, 12%)
    minmax(125px, 12%)
    minmax(80px, 8%);
  grid-template-areas: 'ick dta pt pf oap tpa sta act';
`;

export const TableStyledHead = styled(TableStyledGrid)`
  border-radius: 5px 5px 0 0;
  background-color: #2fc9e1;
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.white};
  padding: 0 10px;
  height: 80px;

  > div {
    strong {
      font-weight: bolder;
      text-align: center;
      max-width: min-content;
    }

    > svg {
      width: 18px;
      height: 18px;

      margin-right: 10px;
    }
  }

  div:first-child {
    > label > span.checkmark {
      border: 2px solid #ffffff;
    }

    input {
      margin-left: 50%;
    }
  }
`;

export const TableStyledBodySection = styled.div`
  h2 {
    margin: 10px 0 10px 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #707683;
  }
`;

export const TableStyledRow = styled(TableStyledGrid)`
  border-radius: 10px;
  margin: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray2};
  color: ${(props) => props.theme.colors.graySecondary};
  font-weight: bolder;
  line-height: 20px;
  height: 80px;
`;

export const TableStyledCell = styled.div<TableStyledCellProps>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: ${({ align }) => align};

  padding: 0 10px;

  ${({ isRemoved }) =>
    isRemoved &&
    css`
      text-decoration-line: line-through;
      color: #707683;
    `}
`;

export const Paginate = styled.nav`
  ul {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: center;
  }
`;

export const PaginateButton = styled.button<PaginateButtonProps>`
  font-family: 'Raleway';
  border: 0.5px solid ${(props) => props.theme.colors.grayAlternative};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grayAlternative};
  width: 42px;
  height: 42px;
  ${(props) =>
    props.isActual &&
    css`
      background-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.white};
      border: 0.5px solid ${props.theme.colors.primary};
    `}

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
`;

export const CardContentNothingToDisplayBox = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgb(6 92 119 / 5%);
  height: 400px;

  > div {
    font-size: 18px;
  }
`;
