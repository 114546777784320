import styled from 'styled-components';

import datePicker from '../../../../assets/images/date-picker.svg';

interface FieldProps {
  gridArea:
    | 'np'
    | 'co'
    | 'gr'
    | 'pr'
    | 'fc'
    | 'st'
    | 'bt'
    | 'cpf'
    | 'ph'
    | 'rs';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  display: flex;

  width: 100%;
  height: 259px;
  min-height: 360px;

  margin-top: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-top: 30px;

  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  form {
    margin-right: 50px;
  }

  > h1 {
    font-family: 'Raleway';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 28px;
    color: #4f4f4f;
  }

  .datepicker {
    color: ${(props) => props.theme.colors.graySecondary};
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(6, 1fr);
  grid-template-areas:
    'np np np cpf ph gr'
    'fc fc rs co st pr'
    'bt bt bt bt bt bt';
  grid-column-gap: 45px;
  grid-row-gap: 25px;

  margin-right: 50px;
  width: 100%;
  height: 100%;
`;

export const Button = styled.button`
  background: ${(props) => props.theme.colors.primary};
  align-self: flex-start;
  border-radius: 100px;
  border: 0;
  padding: 16px 48px;
  color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  letter-spacing: 1.25px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

export const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: column;
  grid-area: ${({ gridArea }) => gridArea};

  &:last-child > button {
    align-self: flex-end;
  }

  > label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.01em;

    color: #707683;
    white-space: nowrap;

    > span {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      letter-spacing: 0.01em;

      color: ${({ theme }) => theme.colors.danger};
    }
  }

  > div {
    margin-top: 4px;
    max-height: 43px;

    > input[type='date']::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      background: url(${datePicker}) no-repeat;
      background-size: 75%;
      width: 24px;
      height: 24px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;
