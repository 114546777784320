import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  visibility: hidden;

  > svg {
    position: absolute;
    bottom: 2px;
    left: 5px;
    width: 20px;
  }

  > span {
    color: ${(props) => props.theme.colors.gray};
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    visibility: visible;
  }

  &:hover {
    visibility: visible;
  }
`;

export const Icd10Balloon = styled.div`
  position: absolute;
  bottom: 35px;
  left: -70px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  max-height: 150px;
  min-width: 350px;
  max-width: 450px;
  overflow-y: auto;
  padding: 20px;
`;

export const Icd10Box = styled.div`
  display: flex;
  align-items: center;

  > span {
    color: #707683;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
  }

  & + & {
    margin-top: 15px;
  }
`;
