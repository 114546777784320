enum RoleEnum {
  NURSING_TECHNICIAN = 'Técnico de Enfermagem',
  NURSE = 'Enfermeiro(a)',
  HEAD_NURSE = 'Enfermeiro(a) Chefe',
  NURSING_COORDINATOR = 'Coordenador de Enfermagem',
  STUDENT = 'Estudante',
  DOCTOR = 'Médico(a)',
}

export default RoleEnum;
