import styled, { css } from 'styled-components';

interface ButtonProps {
  isWhite?: boolean;
  loading?: boolean;
}

export const Container = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  background: transparent;

  ${({ loading }) =>
    loading &&
    css`
      pointer-events: none;
    `}

  border-radius: 100px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  padding: 10px 20px;
  color: ${(props) => props.theme.colors.grayAlternative};
  font-weight: 300;
  margin-top: 0px !important;

  letter-spacing: 1.25px;
`;
