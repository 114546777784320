import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 45px 45px 0;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 26px;
    font-weight: 500;
    margin-top: 25px;
  }

  > p {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    padding: 35px 0 0 0;
  }
`;

export const ContentDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 30px;

  span {
    color: #707683;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  div {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 15px;

    p {
      font-weight: 600;
      font-size: 14px;
      color: ${(props) => props.theme.colors.graySecondary};
      padding: 0;
    }
  }
`;

export const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;

  background: #f8f8f8;
  border-radius: 6px;
  width: 425px;
  margin-top: 25px;
  padding: 35px 40px;
`;

export const Row = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;

  > strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }

  > svg {
    margin-right: 20px;
    color: #707683;
  }

  & + div {
    margin-top: 25px;
  }
`;
