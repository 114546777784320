import React from 'react';
import { Form } from '@unform/web';
import { useModal } from 'react-brave-modal';

import InputIcon from '../../components/InputIcon';
import ModalButton from '../../components/ModalButton';
import IconMessageGray from '../../assets/images/icon_schedule_message_gray.svg';

import {
  Container,
  Content,
  InputTitle,
  InputNumber,
  Textarea,
} from './styles';

interface ShowMessageScheduleModalProps {
  message: {
    text?: string;
    phone?: string;
  };
}

const ShowMessageScheduleModal: React.FC<ShowMessageScheduleModalProps> = ({
  message,
}) => {
  const { closeModal } = useModal();

  return (
    <Container>
      <Content>
        <section>
          <header>
            <h1>
              Mensagem <img src={IconMessageGray} alt="Icone de message" />
            </h1>
          </header>
          <Form
            initialData={{ phonenumber: message.phone, message: message.text }}
            onSubmit={() => {}}
          >
            <InputTitle>Número do destinatário:</InputTitle>
            <InputNumber>
              <InputIcon
                name="phonenumber"
                textSize="24px"
                textColor="#2d9cdb"
                mask="(99) 9 9999-9999"
                placeholder="(00) 00000-0000"
                disabled
              />
            </InputNumber>
            <InputTitle>Mensagem</InputTitle>
            <Textarea name="message" disabled />
          </Form>
        </section>
      </Content>
      <footer>
        <ModalButton
          styleType="gray"
          type="button"
          onClick={() => closeModal()}
        >
          Fechar
        </ModalButton>
      </footer>
    </Container>
  );
};

export default ShowMessageScheduleModal;
