import React, { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useModal } from 'react-brave-modal';
import { IoIosWarning } from 'react-icons/io';

import { usePatient } from '../../../../hooks/patient';

import Img from '../../../../assets/images/alterar-status-paciente.svg';
import Button from '../../../../components/Button';
import InputRadio from '../../../../components/InputRadio';

import { Container, Content, Footer, Error } from './styles';
import PatientMeasurementNoticeStatusEnum from '../../../../enums/PatientMeasurementNoticeStatusEnum';

type OptionType = {
  value: PatientMeasurementNoticeStatusEnum;
  label: string;
};

type DataType = {
  noticeStatus: PatientMeasurementNoticeStatusEnum;
};

const UpdateStatus: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { closeModal } = useModal();
  const { patient, editMeasurementNoticeStatus } = usePatient();

  const [isLoading, setIsLoading] = useState(false);
  const [isErrored, setIsErrored] = useState<string>();

  const inputRadioOptions: OptionType[] = [
    {
      value: PatientMeasurementNoticeStatusEnum.WAITING_FOR_ATTENDANCE,
      label: 'Aguardando atendimento',
    },
    {
      value: PatientMeasurementNoticeStatusEnum.ATTENDED,
      label: 'Atendido',
    },
  ];

  const handleSave = async () => {
    const data = formRef.current?.getData() as DataType;

    if (
      patient &&
      data.noticeStatus !== patient.currentMeasurementNotice?.status
    ) {
      setIsLoading(true);

      try {
        await editMeasurementNoticeStatus();

        closeModal();
      } catch (err) {
        setIsLoading(false);

        setIsErrored(`Erro: ${err.response.data.message}`);
      }
    } else {
      closeModal();
    }
  };

  return (
    <Container>
      <h2>Status do Alerta</h2>

      <Content>
        <div>
          <p>
            O paciente apresenta uma ou mais alterações nos indicadores de
            saúde.
          </p>

          <p>
            Caso o paciente já tenha recebido atendimento para acompanhamento da
            situação, você pode alterar o status do alerta para “Atendido”.
          </p>

          <span>STATUS DO ALERTA:</span>

          <Form ref={formRef} onSubmit={() => {}}>
            <InputRadio
              name="noticeStatus"
              options={inputRadioOptions}
              defaultOption={patient?.currentMeasurementNotice?.status}
            />
          </Form>

          {isErrored && (
            <Error>
              <IoIosWarning size={25} />

              <span>{isErrored}</span>
            </Error>
          )}
        </div>

        <img src={Img} alt="imagem ilustrativa de alteração de status" />
      </Content>

      <Footer>
        <Button buttonType="primary-alternative" onClick={closeModal}>
          Cancelar
        </Button>

        <Button buttonType="primary" onClick={handleSave} loading={isLoading}>
          Salvar
        </Button>
      </Footer>
    </Container>
  );
};

export default UpdateStatus;
