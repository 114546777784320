import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
  lazy,
  Suspense,
} from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { useModal } from 'react-brave-modal';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../../../lib/use-current-effect';

import { useUser } from '../../../../hooks/user';
import { Council } from '../../../../entities/Council';
import { SpecialtyProfessional } from '../../../../entities/SpecialtyProfessional';
import { Role, Professional } from '../../../../entities/Professional';
import getValidationErrors from '../../../../utils/getValidationErrors';
import getOccupationArea from '../../../../utils/getOccupationArea';
import {
  getRoleByOccupationArea,
  RoleOptions,
} from '../../../../utils/getRoleByOccupationArea';
import { validateUserDataService } from '../../../../services/user';

import {
  ComponentsUserRegisterEditProps,
  ComponentsUserRegisterEditRef,
} from '..';
import RequiredFieldInformation from '../../../../components/RequiredFieldInformation';
import CouncilsComponent from './edit/Councils';
import InputWithoutTitle from '../../../../components/InputWithoutTitle';

import {
  Container,
  Field,
  GridContent,
  InputSelect,
  SpecializationsContainer,
} from './styles';
import OccupationAreaEnum from '../../../../enums/OccupationAreaEnum';
import RoleEnum from '../../../../enums/RoleEnum';
import TypeSpecialtyProfessionalEnum from '../../../../enums/TypeSpecialtyProfessionalEnum';

const SpecialtiesComponent = lazy(
  () =>
    import(/* webpackChunkName: "SpecialtiesComponent" */ './edit/Specialties'),
);

const ConfirmEditOccupationArea = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmEditOccupationArea" */ './modals/ConfirmEditOccupationArea'
    ),
);

export interface ProfessionalDataProps {
  occupationArea?: OccupationAreaEnum;
  role?: Role;
}

type StudentDataProps =
  | {
      enrollment: string;
      educationalInstitution: string;
    }
  | undefined;

export interface SpecialtiesByProfessionalProps extends SpecialtyProfessional {
  key: number;
}

const ProfessionalData: React.ForwardRefRenderFunction<
  ComponentsUserRegisterEditRef,
  ComponentsUserRegisterEditProps
> = ({ onSaveSucceed }, ref) => {
  const { saveLocal, save, user } = useUser();
  const professionalFormRef = useRef<FormHandles>(null);
  const studentFormRef = useRef<FormHandles>(null);
  const { showModal } = useModal();

  const [occupationAreaProps] = useState(getOccupationArea);
  const [isStudent, setIsStudent] = useState<boolean>(false);
  const [occupationArea, setOccupationArea] = useState<
    OccupationAreaEnum | undefined
  >(() => (user ? user.professional?.occupationArea : undefined));

  const [roles, setRoles] = useState<RoleOptions[]>(() =>
    user ? getRoleByOccupationArea(user.professional?.occupationArea) : [],
  );
  const [councils, setCouncils] = useState<Council[]>([]);
  const [specialties, setSpecialties] = useState<
    SpecialtiesByProfessionalProps[]
  >([]);

  useCurrentEffect(
    (isCurrent) => {
      if (!user?.professional?.id) {
        const newRoles = getRoleByOccupationArea(occupationArea);

        if (isCurrent()) {
          setRoles(newRoles);

          professionalFormRef.current?.setFieldValue('role', '');

          setCouncils([]);

          setSpecialties(
            (state) =>
              state.filter(
                (specialty) =>
                  specialty.type === TypeSpecialtyProfessionalEnum.GENERAL,
              ) || [],
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [occupationArea],
  );

  useCurrentEffect(
    (isCurrent) => {
      if (user?.professional) {
        const { professional } = user;

        professionalFormRef.current?.setData({
          occupationArea:
            professional.occupationArea ?? occupationAreaProps[0].value,
          role: professional.role,
        } as ProfessionalDataProps);

        if (isCurrent()) {
          if (professional.councils && professional.councils.length > 0) {
            setCouncils(professional.councils);
          }

          if (
            professional.specialtiesProfessional &&
            professional.specialtiesProfessional.length > 0
          ) {
            const newSpecialties = professional.specialtiesProfessional.map(
              (specialty) => {
                return { ...specialty, key: Math.random() };
              },
            );
            setSpecialties(newSpecialties);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  const getValidate = useCallback(async () => {
    professionalFormRef.current?.setErrors({});
    studentFormRef.current?.setErrors({});

    const professionalData =
      professionalFormRef?.current?.getData() as ProfessionalDataProps;
    const studentData = studentFormRef?.current?.getData() as StudentDataProps;

    if (studentData?.educationalInstitution) {
      studentData.educationalInstitution =
        studentData.educationalInstitution.trim();
    }

    if (studentData?.enrollment) {
      studentData.enrollment = studentData.enrollment.trim();
    }

    try {
      const schema = Yup.object().shape({
        occupationArea: Yup.string().required(
          'A área de atuação é obrigatória',
        ),
        role: Yup.string().required('O cargo/especialidade é obrigatório'),
      });
      const studentSchema = Yup.object().shape({
        enrollment: Yup.string().required('A matrícula é obrigatória'),
        educationalInstitution: Yup.string().required(
          'A instituição de ensino é obrigatória',
        ),
      });

      await schema.validate(professionalData, {
        abortEarly: false,
      });

      if (studentData) {
        await studentSchema.validate(studentData, {
          abortEarly: false,
        });
      }

      if (professionalData?.role !== RoleEnum.STUDENT && councils.length <= 0) {
        throw new Error('O registro profissional é obrigatório');
      }

      if (occupationArea && occupationArea === 'Medicina') {
        const countSpecialtiesActives = specialties.reduce(
          (total, specialty) => (specialty.isActive ? total + 1 : total),
          0,
        );

        const countActiveCouncils = councils.reduce(
          (total, council) => (council.isMainCouncil ? total + 1 : total),
          0,
        );

        if (countActiveCouncils === 0) {
          throw new Error(
            'É obrigatório selecionar um conselho como principal.',
          );
        }

        if (countActiveCouncils > 1) {
          throw new Error('Só é permitido manter um conselho como principal.');
        }

        if (countSpecialtiesActives === 0) {
          throw new Error(
            'É obrigatório selecionar ao menos uma especialidade ativa.',
          );
        }

        if (countSpecialtiesActives > 2) {
          throw new Error('Só é permitido duas especialidades ativas.');
        }
      }

      await validateUserDataService({
        id: user?.id,
        role: professionalData?.role,
        occupationArea,
        councils,
        studentDetail: studentData,
      });

      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        professionalFormRef.current?.setErrors(errors);
        studentFormRef.current?.setErrors(errors);
      } else if (err.response) {
        toast.error(err.response.data.message);
      } else if (err.message) {
        toast.error(err.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }

      return false;
    }
  }, [user, councils, specialties, occupationArea]);

  const handleGetData = useCallback(() => {
    const professionalData =
      professionalFormRef?.current?.getData() as ProfessionalDataProps;
    const studentData = studentFormRef?.current?.getData() as StudentDataProps;

    const specialtiesProfessional =
      occupationArea === OccupationAreaEnum.MEDICINE ? specialties : [];

    const professional: Professional = {
      ...professionalData,
      councils,
      specialtiesProfessional,
      studentDetails: studentData ? [studentData] : [],
    };

    return { professionalData: professional };
  }, [councils, occupationArea, specialties]);

  const handleNext = useCurrentCallback(
    (isCurrent) => async () => {
      if (isCurrent()) {
        const { professionalData } = handleGetData();
        const professional = { ...professionalData };

        if (user?.professional) {
          Object.assign(professional, {
            ...user.professional,
            ...professionalData,
          });
        }

        saveLocal({ professional });

        return true;
      }

      return false;
    },
    [handleGetData, saveLocal, user],
  );

  const handleSave = useCallback(async () => {
    try {
      const { professionalData } = handleGetData();
      const professional = { ...professionalData };

      if (user?.professional) {
        Object.assign(professional, {
          ...user.professional,
          ...professionalData,
        });
      }

      const didSave = await save({ professional });
      didSave && onSaveSucceed && onSaveSucceed();

      return didSave;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        professionalFormRef.current?.setErrors(errors);
        studentFormRef.current?.setErrors(errors);
      } else if (err.response) {
        toast.error(err.response.data.message);
      } else if (err.message) {
        toast.error(err.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }

      return false;
    }
  }, [handleGetData, onSaveSucceed, save, user]);

  const handleEditOccupationArea = useCurrentCallback(
    (isCurrent) => (newOccupationArea: OccupationAreaEnum) => {
      if (!occupationArea && isCurrent()) {
        setOccupationArea(newOccupationArea);
      }

      if (occupationArea !== newOccupationArea) {
        if (councils.length > 0 || specialties.length > 1) {
          showModal({
            type: 'custom',
            data: (
              <Suspense fallback={false}>
                <ConfirmEditOccupationArea
                  setOccupationArea={() => setOccupationArea(newOccupationArea)}
                />
              </Suspense>
            ),
          });
        } else if (isCurrent()) {
          setOccupationArea(newOccupationArea);
        }
      }
    },
    [occupationArea, showModal, councils, specialties],
  );

  useImperativeHandle(ref, () => ({
    back: async () => true,
    cancel: async () => true,
    next: handleNext,
    save: handleSave,
    getData: handleGetData,
    canSave: getValidate,
  }));

  const initialData = {
    occupationArea,
    role: roles.find(({ value }) => value === user?.professional?.role)?.value,
  };

  return (
    <Container>
      <h1>Dados profissionais</h1>
      <Form
        ref={professionalFormRef}
        onSubmit={() => {}}
        initialData={initialData}
      >
        <GridContent>
          <Field gridArea="aa">
            <label>
              Área de atuação
              <RequiredFieldInformation />
            </label>
            <InputSelect
              name="occupationArea"
              options={occupationAreaProps}
              placeholder=""
              value={{ value: occupationArea, label: occupationArea }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event: any) => {
                event && event.value && handleEditOccupationArea(event.value);
              }}
              disabled={!!user?.professional?.id}
            />
          </Field>
          <Field gridArea="ce">
            <label>
              Cargo/Especialidade
              <RequiredFieldInformation />
            </label>
            <InputSelect
              name="role"
              options={roles}
              placeholder=""
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event: any) => {
                event && setIsStudent(event.value === 'Estudante');
              }}
            />
          </Field>
        </GridContent>
      </Form>

      <CouncilsComponent
        isStudent={isStudent}
        councils={councils}
        setCouncils={setCouncils}
        occupationArea={occupationArea}
      />

      {occupationArea === 'Medicina' && (
        <Suspense fallback={false}>
          <SpecialtiesComponent
            specialtiesByProfessional={specialties}
            setSpecialtiesByProfessional={setSpecialties}
          />
        </Suspense>
      )}

      {isStudent && (
        <SpecializationsContainer>
          <h1>Informações educacionais:</h1>

          <Form
            ref={studentFormRef}
            initialData={
              user?.professional?.studentDetails &&
              user.professional.studentDetails[0]
            }
            onSubmit={() => {}}
          >
            <div>
              <Field gridArea="f1">
                <label>
                  Instituição de ensino
                  <RequiredFieldInformation />
                </label>
                <InputWithoutTitle name="educationalInstitution" />
              </Field>

              <Field gridArea="f2">
                <label>
                  Matrícula
                  <RequiredFieldInformation />
                </label>
                <InputWithoutTitle name="enrollment" />
              </Field>
            </div>
          </Form>
        </SpecializationsContainer>
      )}
    </Container>
  );
};

export default forwardRef(ProfessionalData);
