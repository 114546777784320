import React, { useRef, useMemo, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';
import InputMaskAlternative from '../../../components/InputMaskAlternative';
import InputWithoutTitle from '../../../components/InputWithoutTitle';
import getValidationErrors from '../../../utils/getValidationErrors';
import { UserData } from '..';
import { identifyUser } from '../../../services/user';

import { Container, Field, SendButton } from './styles';
import useQuery from '../../../hooks/query';
import { useCurrentCallback } from '../../../lib/use-current-effect';

interface IdentificationProps {
  userData: UserData;
  handleSetUserData: (data: UserData) => void;
  handleStepChange: (key: string) => void;
}

const Identification: React.FC<IdentificationProps> = ({
  userData,
  handleSetUserData,
  handleStepChange,
}) => {
  const formRef = useRef<FormHandles>(null);
  const query = useQuery();
  const token = useMemo(() => query.get('token'), [query]);
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async (data: UserData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          cpf: Yup.string()
            .length(14, 'Digite um CPF válido')
            .required('O CPF é obrigatório'),
          birthday: Yup.string().required('A data de nascimento é obrigatória'),
          email: Yup.string()
            .email('Digite um email válido')
            .required('O e-mail é obrigatório'),
          phone: Yup.string()
            .length(16, 'Digite um número de telefone válido')
            .required('O telefone é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await identifyUser({ token: token ?? '', ...data });

        if (isCurrent()) {
          handleSetUserData(data);

          handleStepChange('PasswordCreation');
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }
    },
    [handleSetUserData, handleStepChange, token],
  );

  return (
    <Container>
      <h1>Identificação</h1>
      <h2>Confirme os seus dados para concluir o cadastro na Cinco:</h2>
      <p>Os dados precisam ser iguais às informações fornecidas no cadastro:</p>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={userData}>
        <Field>
          <label>CPF:</label>
          <InputMaskAlternative
            name="cpf"
            type="text"
            title=""
            placeholder="000.000.000-00"
            mask="999.999.999-99"
          />
        </Field>
        <Field>
          <label>Data de nascimento:</label>
          <InputWithoutTitle
            className="datepicker"
            name="birthday"
            type="date"
            max={new Date().toISOString().split('T')[0]}
          />
        </Field>
        <Field>
          <label>Confirme seu e-mail:</label>
          <InputWithoutTitle name="email" />
        </Field>
        <Field>
          <label>Confirme seu telefone:</label>
          <InputMaskAlternative
            name="phone"
            type="text"
            title=""
            placeholder="(00) 0 0000-0000"
            mask="(99) 9 9999-9999"
          />
        </Field>
        <SendButton type="submit">Enviar</SendButton>
      </Form>
    </Container>
  );
};

export default Identification;
