import styled from 'styled-components';
import { Container as GridCardContainer } from '../../../../components/GridCard/styles';

interface CardTypeItemProps {
  selected?: boolean;
}

interface CardDetailsProps {
  position?: 'center' | 'end';
}

export const Container = styled(GridCardContainer)`
  padding: 30px 25px 20px;

  > button {
    height: 35px;
    margin-top: auto;
  }
`;

export const Title = styled.h1`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #4f4f4f;
`;

export const Types = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
`;

export const TypeItem = styled.button<CardTypeItemProps>`
  border-style: none none solid none;
  border-color: ${({ selected }) => (selected ? '#1f80b7' : '#cacaca')};
  background: transparent;
  flex: 1;
  margin: 0 2.5px;

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${({ selected }) => (selected ? '#1f80b7' : '#cacaca')};

  &:hover:enabled {
    color: #1f80b7;
    border-color: #1f80b7;
  }

  &:disabled {
    cursor: default;
  }
`;

export const CardDetails = styled.div<CardDetailsProps>`
  grid-area: dt;
  display: flex;
  flex-direction: column;
  justify-content: ${({ position }) =>
    position === 'center' ? 'center' : 'flex-end'};
`;

export const CardChart = styled.div`
  grid-area: ch;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 5;
  margin-bottom: 15px;

  > div {
    > button {
      height: 35px;
      margin-top: auto;
    }

    > p {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #707683;
      margin-top: 5px;
    }
  }
`;

export const NavigationArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
