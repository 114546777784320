import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import AppointmentAreaEnum from '../../../../../../enums/AppointmentAreaEnum';

export interface ContainerProps {
  type: AppointmentAreaEnum;
}

const containerVariations: {
  [key in AppointmentAreaEnum]: FlattenSimpleInterpolation;
} = {
  medicine: css`
    color: #2d9cdb;
    background: rgba(45, 156, 219, 0.2);
    &::before {
      content: 'M';
    }
  `,
  nursing: css`
    color: #47c27a;
    background: rgba(111, 207, 151, 0.2);
    &::before {
      content: 'E';
    }
  `,
};

export const Container = styled.span<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30px;
  width: 30px;
  font-weight: bold;
  border-radius: 6px;

  ${({ type }) => containerVariations[type]}
`;
