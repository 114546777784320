import React, { useCallback, useState } from 'react';
import { MdNotifications, MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { useNotification } from '../../../hooks/notification';

import NotificationItem from '../../NotificationItem';

import {
  DropNotifications,
  Header,
  WithoutNotifications,
  NotificationsBadge,
} from './styles';

const NotificationsList: React.FC = () => {
  const { notifications } = useNotification();

  const [menuNotificationOpen, setMenuNotificationOpen] = useState(false);

  const handleOpenNotifications = useCallback(
    (event: 'hover' | 'click' | 'mouseleave') => {
      if (event === 'hover') {
        setMenuNotificationOpen(true);
      }
      if (event === 'click') {
        setMenuNotificationOpen((state) => !state);
      }
      if (event === 'mouseleave') {
        setMenuNotificationOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <NotificationsBadge
        hasNewNotifications={notifications.length > 0}
        onClick={() => handleOpenNotifications('click')}
        onMouseEnter={() => handleOpenNotifications('hover')}
      >
        {/* <div>{notifications.length > 99 ? '99' : notifications.length}</div> */}
        <MdNotifications />
      </NotificationsBadge>

      <DropNotifications
        isOpen={menuNotificationOpen}
        onMouseLeave={() => handleOpenNotifications('mouseleave')}
      >
        <Header>
          <h3>Notificações</h3>

          <Link to="/dashboard/notifications">
            <span>Ver todas</span>

            <MdKeyboardArrowRight size={15} />
          </Link>
        </Header>

        {notifications.length > 0 ? (
          <>
            <ul>
              {notifications.map(({ id, title, text, createdAt }) => (
                <NotificationItem
                  key={id}
                  title={title}
                  message={text}
                  date={new Date(createdAt)}
                  read={false}
                />
              ))}
            </ul>
          </>
        ) : (
          <WithoutNotifications>
            <h3>Não há novas notificações no momento</h3>
          </WithoutNotifications>
        )}
      </DropNotifications>
    </>
  );
};

export default NotificationsList;
