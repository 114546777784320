import { formatISO } from 'date-fns';

import { apiMetrics } from '../apiMetrics';
import { GraphData } from '../../entities/GraphData';
import { MetricTypeEnum } from '../../enums/MetricTypeEnum';

interface GetMeasurementsByDateRangeProps {
  patientId: string;
  metrics: MetricTypeEnum | MetricTypeEnum[];
  startDate: Date;
  endDate: Date;
}

export const getMeasurementsByDateRange = async ({
  patientId,
  metrics,
  startDate,
  endDate,
}: GetMeasurementsByDateRangeProps): Promise<GraphData[]> => {
  const filter = `${formatISO(startDate)}/${formatISO(endDate)}`;

  try {
    const url = Array.isArray(metrics)
      ? `/metrics/groupByDayAverage/${filter}/${patientId}?metrics=${JSON.stringify(
          metrics,
        )}`
      : `/metrics/groupByDay/${patientId}/${metrics}/${filter}`;

    const { data } = await apiMetrics.get(url);

    return data;
  } catch {
    return [];
  }
};
