import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import { Formio } from 'formiojs';

import { Container } from './styles';
import Spinner from '../Spinner';
import { getTokenSheet } from '../../services/sheet';

interface FormioComponentProps {
  url: string;
  submission?: any;
  onSubmit?: (submission: any) => void;
  options?: {
    readOnly?: boolean;
    noAlerts?: boolean;
  };
}

export interface FormioComponentRef {
  submit: () => void;
}

const FormioComponent: React.ForwardRefRenderFunction<
  FormioComponentRef,
  FormioComponentProps
> = ({ url, options, submission, onSubmit }, ref) => {
  useEffect(() => {
    const setupFormio = async () => {
      const token = await getTokenSheet();
      Formio.setToken(token);
      Formio.createForm(document.getElementById('formio'), `${url}`, {
        readOnly: options?.readOnly,
        noAlerts: options?.noAlerts,
      }).then((form: any) => {
        form.submission = submission;

        form.on('submit', (data: any) => {
          if (onSubmit) {
            onSubmit(data);
          }
        });
      });
    };

    setupFormio();
  }, [onSubmit, options, submission, url]);

  const handleSubmit = useCallback(() => {
    const button = document.getElementsByName('data[submit]')[0];

    button?.click();
  }, []);

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  return (
    <Container id="formio">
      <Spinner />
    </Container>
  );
};

export default forwardRef(FormioComponent);
