import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import { Container as GridCardContainer } from '../GridCard/styles';

interface StyledLinkProps {
  disabled?: boolean;
}

export const Container = styled(GridCardContainer)`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const CardHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(89.77deg, #30e3e5 -39.87%, #2d9cdb 71.56%);
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px 6px 0 0;
  color: ${(props) => props.theme.colors.white};
  height: 65px;
  padding: 20px 50px 10px 50px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    min-height: 80px;
    padding: 20px 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }

  h1 {
    font-size: 18px;
    font-weight: 600;
  }

  a {
    display: none;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      display: flex;
      align-items: center;

      color: ${(props) => props.theme.colors.white};
      font-size: 12px;
      font-weight: 500;
      padding: 0;
      text-decoration: none;
      text-transform: uppercase;

      svg {
        margin-left: 7px;
      }
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const ContentArea = styled.div`
  height: 400px;
  overflow-y: auto;
  padding: 35px 50px;

  @media screen and (max-width: 1160px) {
    height: 600px;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 25px 15px;
    height: 575px;
  }
`;

export const DateBox = styled.div`
  h2 {
    color: #707683;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  & + div {
    margin-top: 22px;
  }
`;

export const AppointmentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.gray2};
  border-radius: 6px;
  height: 60px;
  padding: 0 15px 0 20px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    height: 100%;
    flex-direction: column;
    gap: 16px;
    padding: 20px 10%;

    & + div {
      margin-top: 16px !important;
    }
  }

  & + div {
    margin-top: 6px;
  }
`;

export const AppointmentInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray3};
  font-size: 14px;

  overflow: auto;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    width: 100%;
  }

  > span {
    font-weight: 800;
    margin-right: 25px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      margin-right: 0;
    }
  }

  svg {
    color: #707683;
    width: 12px;
    min-width: 12px;
    min-height: 19px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      margin-left: 32px;
      margin-right: 7.5px;
    }
  }

  a span:last-child {
    margin-left: 8px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      margin-left: 0;
    }
  }
`;

export const LinkWhite = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;

  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 30px;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 600;
  height: 35px;
  width: 120px;
  text-decoration: none;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    width: 100%;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17.5px 50px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 25px;
    margin: auto 0;
  }

  button {
    padding: 12px 20px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      width: 100%;
    }
  }

  a {
    display: flex;
    align-items: center;

    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    text-decoration: none;
    text-transform: uppercase;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      display: none;
    }

    svg {
      margin-left: 7px;
    }
  }
`;

export const EmptyList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    height: 95px;
    width: 95px;
  }

  span:nth-child(2) {
    color: ${(props) => props.theme.colors.grayAlternative};
    font-size: 14px;
    margin-top: 16px;
    max-width: 270px;
  }

  span:nth-child(3) {
    color: ${(props) => props.theme.colors.gray};
    font-size: 24px;
    margin-top: 30px;
  }

  button {
    margin-top: 25px;
  }
`;

export const StyledPatientLink = styled(Link)<StyledLinkProps>`
  max-width: min-content;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;

  padding: 0 7px;

  color: ${({ theme }) => theme.colors.gray3};

  transition: color 0.7s;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    max-width: 230px;
  }

  @media screen and (max-width: 400px) {
    max-width: 165px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`;
