import styled, { css } from 'styled-components';
import ButtonBase from '../../../components/Button';

interface OptionProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 360px;

  margin-top: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-top: 30px;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  > h1 {
    font-family: 'Raleway';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  flex: 1;
`;

export const MenuOptions = styled.div`
  flex: 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 90px;
`;

export const Option = styled.button<OptionProps>`
  width: 190px;
  border-radius: 6px;
  border: 0;
  background: white;

  display: flex;
  align-items: center;

  padding: 8px;

  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;

    color: #707683;
  }

  > span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    color: #707683;
    margin-left: 14px;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: #daedf7;

      > span,
      svg {
        color: ${({ theme }) => theme.colors.primary};
      }
    `}
`;

export const OptionComponent = styled.div`
  flex: 6;

  display: flex;
  align-items: flex-start;

  margin-top: 40px;
  margin-bottom: 100px;

  @media (max-width: 1100px) {
    flex: 5;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Button = styled(ButtonBase)`
  width: 153px;
  & ~ & {
    margin-left: 20px;
  }
`;
