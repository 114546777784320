import React, { useCallback, useState } from 'react';

import { Container } from './styles';

import CopyImage from '../../assets/images/copy.svg';

interface ButtonCopyProps {
  value: string;
}

const ButtonCopy: React.FC<ButtonCopyProps> = ({ value }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(value || '');
    setCopied(true);
  }, [value]);
  return (
    <Container copied={copied} type="button" onClick={() => handleCopy()}>
      <div>
        <img src={CopyImage} alt="copy" />
      </div>
      <span>{copied ? 'Copiado!' : 'Copiar'}</span>
    </Container>
  );
};

export default ButtonCopy;
