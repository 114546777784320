import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex: 1;
  width: 100%;

  > header {
    width: 100%;
  }

  > section {
    display: flex;
    margin-top: 35px;
    align-items: flex-start;
    flex: 1;

    > div {
      flex: 1;
      margin-left: 40px;

      > h1 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #707683;
        margin-bottom: 15px;
      }

      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 130%;
        color: #707683;
        margin-bottom: 30px;
      }
    }
  }

  > footer {
    display: flex;
    max-width: 350px;
    width: 100%;
    margin-top: 50px;

    > button {
      border-radius: 100px;
      border: 1px solid #2d9cdb;
      padding: 10px 15px;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      flex: 1;
    }

    > button + button {
      margin-left: 19px;
    }

    > button:nth-child(1) {
      background: #2d9cdb;
      color: #ffffff;
    }

    > button:nth-child(2) {
      background: transparent;
      color: #2d9cdb;
    }
  }
`;

export const SheetListAttendance = styled.div`
  overflow-y: auto;
  max-height: 450px;

  > div {
    display: flex;
    padding: 17px 0 10px;

    > section:nth-child(1) {
      flex: 1.5;

      > img {
        margin-right: 15px;
      }
    }

    > section:nth-child(2) {
      flex: 1.5;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #707683;

      > img {
        margin-right: 15px;
      }
    }

    > section:nth-child(3) {
      flex: 2;
      padding-left: 15px;

      > button {
        border: 0;
        background: transparent;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #2d9cdb;
        text-align: left;
      }
    }

    > section + section {
      margin-left: 10px;
    }
  }

  > div + div {
    border-top: 1px solid #efefef;
  }
`;
