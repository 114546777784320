import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Calendar } from 'react-date-range';
import { format } from 'date-fns';

import { Container, DatePickerComponent } from './styles';
import CalendarFilterIcon from '../../assets/images/date-picker-dark.svg';

export interface DateValueProps {
  date: Date;
}
interface ButtonFilterDateProps {
  onChange: (newValue: DateValueProps) => void;
  dateValue: DateValueProps;
}

const ButtonFilterDate: React.FC<ButtonFilterDateProps> = ({
  onChange,
  dateValue,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [value, setValue] = useState<DateValueProps>({
    date: new Date(),
  });
  const [invalidDate, setInvalidDate] = useState(false);

  const maxDate = useMemo<Date>(() => new Date(), []);
  const minDate = useMemo<Date>(() => new Date(2019, 0, 1), []);

  const buttonLabel = useMemo(() => {
    return `${format(dateValue.date, "dd'/'MM")}`;
  }, [dateValue]);

  const setNewValue = useCallback(
    (range: DateValueProps) => {
      const { date } = range;

      setInvalidDate(false);

      setValue({
        date,
      });

      onChange({
        date,
      });

      setShowFilter(false);
    },
    [onChange],
  );

  const handleClick = useCallback(() => {
    setInvalidDate(false);
    setValue({
      date: new Date(dateValue.date),
    });

    setShowFilter((state) => !state);
  }, [dateValue]);

  const handleOnClickListener = useCallback((event: MouseEvent) => {
    const withinBoundaries = event
      .composedPath()
      .includes(containerRef.current as Element);

    if (!withinBoundaries) {
      setShowFilter(false);
    }
  }, []);

  useEffect(() => {
    if (containerRef) {
      document.addEventListener('click', handleOnClickListener);
    }

    return () => {
      document.removeEventListener('click', handleOnClickListener);
    };
  }, [handleOnClickListener]);

  useEffect(() => {
    setValue({
      date: new Date(dateValue.date),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container ref={containerRef}>
      <button type="button" onClick={handleClick}>
        {buttonLabel}
        <div>
          <img src={CalendarFilterIcon} alt="Ícone de Calendário" />
        </div>
      </button>
      <DatePickerComponent invisible={!showFilter} invalidDate={invalidDate}>
        <Calendar
          date={value.date}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(item: any) => setNewValue({ date: item })}
          maxDate={maxDate}
          minDate={minDate}
        />
      </DatePickerComponent>
    </Container>
  );
};

export default ButtonFilterDate;
