import React, { useMemo } from 'react';
import { IoMdCheckmarkCircle, IoMdEye } from 'react-icons/io';
import { useModal } from 'react-brave-modal';

import { usePermission } from '../../../../hooks/permission';
import history from '../../../../services/history';

import Button from '../../../../components/Button';

import { Container } from './styles';

interface PatientAttendedProps {
  patientId?: string;
}

const PatientAttended: React.FC<PatientAttendedProps> = ({ patientId }) => {
  const { closeModal } = useModal();
  const { hasPermission } = usePermission();

  const canViewProfile = useMemo(() => hasPermission('patient_profile'), [
    hasPermission,
  ]);

  const handleViewProfile = () => {
    history.push(`/patient/profile?id=${patientId}`);
    closeModal();
  };

  return (
    <Container>
      <IoMdCheckmarkCircle size={70} />

      <h2>O paciente já foi atendido</h2>

      <p>
        O status do paciente foi resolvido e não será mais necessária nenhuma
        medida. Caso precise verificar alguma informação, poderá acessar o
        prontuário logo abaixo.
      </p>

      <p>O que você deseja fazer agora?</p>

      <div>
        {patientId && (
          <Button
            buttonType="primary"
            disabled={!canViewProfile}
            onClick={handleViewProfile}
          >
            <IoMdEye size={20} />

            <span>Ver prontuário</span>
          </Button>
        )}

        <Button buttonType="secondary" onClick={closeModal}>
          Fechar
        </Button>
      </div>
    </Container>
  );
};

export default PatientAttended;
