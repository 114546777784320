import { CreateAppointmentRequestDTO } from '../../dtos/CreateAppointmentRequestDTO';
import { Appointment } from '../../entities/Appointment';
import { api } from '../api';

export const createAppointment = async ({
  date,
  patientId,
  professionalId,
  type,
  area,
  contactPhone,
  smsText,
  status,
}: CreateAppointmentRequestDTO): Promise<Appointment> => {
  const { data } = await api.post('/appointments', {
    date,
    patientId,
    professionalId,
    type,
    area,
    contactPhone,
    smsText,
    status,
  });

  return data;
};
