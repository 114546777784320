import React, {
  InputHTMLAttributes,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useField } from '@unform/core';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error, EyeButton } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  title: string;
}

const InputPassword: React.FC<InputProps> = ({ name, title, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInvisibility = useCallback(() => {
    setHidden((prevState) => !prevState);
  }, []);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      <strong>{title}</strong>

      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        type={hidden ? 'password' : 'text'}
        ref={inputRef}
        {...rest}
      />

      {error ? (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      ) : (
        <EyeButton type="button" onClick={handleInvisibility}>
          {hidden ? <IoMdEye /> : <IoMdEyeOff />}
        </EyeButton>
      )}
    </Container>
  );
};

export default InputPassword;
