import styled from 'styled-components';
import ButtonBase from '../../../../../components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 360px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;
  font-size: 14px;
`;

export const PatientListRow = styled.div`
  display: grid;

  padding: 14px 16px;

  grid-template-columns: 2fr 1fr 2fr 1fr;

  > * {
    padding: 0 10px;
  }
`;

export const TableHeader = styled(PatientListRow)`
  background-color: #eaecf0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const PatientList = styled.div`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 400px;

  font-family: Raleway;
  font-size: 14px;
`;

export const TableRow = styled(PatientListRow)`
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid #eaecf0;
  padding: 6px 16px;

  strong {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
`;

export const Button = styled(ButtonBase)`
  width: 135px;
  height: 39px;
`;
