import { CreatePatientRequestDTO } from '../../dtos/CreatePatientRequestDTO';
import { User } from '../../entities/User';
import { api } from '../api';

export const createPatient = async ({
  patientData,
  personalData,
  type,
}: CreatePatientRequestDTO): Promise<User> => {
  const { data } = await api.post('/patients', {
    patientData,
    personalData,
    type,
  });

  return data;
};
