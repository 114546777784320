import styled, { css } from 'styled-components';
import { StatusType } from './index';

interface TurnOnTurnOff {
  inCall: boolean;
}

interface CallStatusProps {
  callStatus: StatusType;
}

type StatusMessageType = {
  [key in StatusType]: any;
};

const CallStatusSpanVariations: StatusMessageType = {
  init: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.gray3};
  `,
  ready: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.gray3};
  `,
  calling: css`
    background-color: ${({ theme }) => theme.colors.gray2};
    color: ${({ theme }) => theme.colors.gray3};
  `,
  inProgress: css`
    background-color: #c2f6ba;
    color: #1b870a;
  `,
  closed: css`
    background-color: #d2e7f4;
    color: #1873a7;
  `,
  error: css`
    background-color: #fbe4e4;
    color: #dd3636;
  `,
  serverError: css`
    background-color: #ea7676;
    color: #7b4141;
  `,
};

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: 6px;
  padding: 30px 85px;
  width: 100%;
  min-width: 500px;

  @media (max-width: 600px) {
    padding: 30px 50px;
  }

  @media (max-width: 450px) {
    padding: 30px 10px;
  }
`;

export const PhoneNumber = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1.5px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 22px;
  margin-top: 10px;
  padding: 10px 20px;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 20px;

  svg {
    font-size: 25px;
  }
`;

export const TurnOnTurnOff = styled.button<TurnOnTurnOff>`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ inCall }) => (inCall ? '#e40f0f' : '#40ec24')};
  border: none;
  border-radius: 2rem;
  color: ${({ theme }) => theme.colors.white};
  height: 45px;
  min-width: 150px;
  margin-right: 15px;
  padding: 0 25px;
  transition: all 0.5s;

  > svg {
    margin-right: 10px;
  }

  span {
    font-weight: bold;
    font-size: 14px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: wait;
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background: ${theme.colors.gray};
    `}
`;

export const Mic = styled.button`
  background-color: #f1ba2c;
  border: none;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  height: 45px;
  width: 45px;
  transition: all 0.5s;

  &:hover {
    opacity: 0.8;
  }
`;

export const CallStatus = styled.div<CallStatusProps>`
  display: flex;
  border-bottom: 1px solid #ececec;
  font-size: 14px;
  margin: 30px 0;
  padding-bottom: 30px;

  strong {
    margin-right: 15px;
    padding: 8px 0;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  span {
    border-radius: 5px;
    padding: 8px;
    font-weight: 600;
    font-size: 14px;

    ${({ callStatus }) => CallStatusSpanVariations[callStatus]}
  }
`;
