import styled, { css } from 'styled-components';

export interface StyledSpinnerProps {
  transparent?: boolean;
  size?: number;
}

export const StyledSpinner = styled.div<StyledSpinnerProps>`
  ${({ theme, transparent, size }) => css`
    border: ${size ? size / 7.5 : 16}px solid
      ${transparent ? 'transparent' : theme.colors.gray5};
    border-top: ${size ? size / 7.5 : 16}px solid ${theme.colors.primary};
    width: ${size || 120}px;
    height: ${size || 120}px;
  `}
  border-radius: 50%;
  animation: spin 2s linear infinite;
  margin: auto;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
