import styled from 'styled-components';

export const Container = styled.div`
  max-width: 80vw;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .alert.alert-success {
    display: none;
  }

  div.formio-component {
    width: 100%;
  }

  > div:last-of-type
    > div.formio-form
    .form-group.has-feedback.formio-component.formio-component-button.formio-component-submit.form-group {
    display: none;
  }

  label.col-form-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
  }

  div.form-group {
    margin: 10px 0 15px;
  }

  [name='data[submit]'] {
    visibility: hidden;
  }

  div.form-check {
    padding: 8px;

    > label > span {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 250%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #828282;
      margin-left: 10px;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
  }

  th,
  td {
    padding: 8px;
    word-wrap: break-word;
  }

  table,
  th,
  td {
    border: 1px solid #ddd;
  }

  thead > tr {
    background-color: #ddd;

    > th {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.01em;
    }
  }

  tbody > tr {
    > td {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 250%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #828282;
    }
  }

  tbody > tr:hover {
    background-color: #eee;
  }

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    border: 2px solid #c4c4c4;
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #2d9cdb;
    content: '';
    display: inline-block;
    border: 2px solid #c4c4c4;
  }

  input[type='text'],
  textarea {
    margin-top: 10px;
    padding: 12px 16px;
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'Raleway', sans-serif !important;

    &:focus {
      color: #4f4f4f;
      border: 1px solid ${({ theme }) => theme.colors.primary};
      outline: 0;
    }
  }

  textarea {
    width: 95%;
    min-height: 70px;
    resize: none;
  }
`;
