import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../components/Button';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import DashboardTemplate from '../../../templates/Dashboard';

import { Container, Content, HeaderContent } from './styles';
import TotalPatients from './SmallCards/TotalPatients';
import NewPatientsOfTheMonth from './SmallCards/NewPatientsOfTheMonth';
import { getPatientsReport, PatientReport } from '../../../services/patient';
import Spinner from '../../../components/Spinner';
import MainAreasEnum from '../../../enums/MainAreasEnum';
import PatientsLocation from './PatientsLocation';
import PatientsRiskDegree from './PatientsRiskDegree';
import PatientsXray from './PatientsXray';
import PatientsByInstitution from './PatientsByInstitution';
import PatientsByHealthConditionsChart from '../../../components/PatientsByHealthConditionChart';

const PatientsReports: React.FC = () => {
  const [patientReport, setPatientReport] = useState<PatientReport>();
  const pages = useMemo<ElementPageTree[]>(
    () => [{ name: 'Relatórios' }, { name: 'Pacientes' }],
    [],
  );

  useEffect(() => {
    async function getPatientsReportData() {
      const response = await getPatientsReport();
      setPatientReport(response);
    }
    getPatientsReportData();
  }, []);

  return (
    <DashboardTemplate name={MainAreasEnum.REPORTS}>
      <PageTree pages={pages} />
      <Container>
        <header>
          <HeaderContent>
            <div>
              <h3>Relatório de pacientes</h3>
              <span>Relatório Geral</span>
            </div>
            <Button buttonType="primary-alternative">Filtrar</Button>
          </HeaderContent>
          <HeaderContent>
            <Button>Listagem de Pacientes</Button>
            <span>Último mês</span>
          </HeaderContent>
        </header>
        {patientReport ? (
          <Content>
            <TotalPatients totalPatients={patientReport?.totalPatients} />
            <NewPatientsOfTheMonth
              newPatientsMonth={patientReport?.newPatientsCurrentMonth}
            />
            <PatientsByHealthConditionsChart
              reportData={patientReport.patientsByComorbities}
              totalPatientsByHealthConditions={
                patientReport.totalPatientsByComorbities
              }
            />
            <PatientsLocation data={patientReport.patientsByLocation} />
            <PatientsXray
              data={{
                patientsByImc: patientReport.patientsByImc,
                ageGroupOverSeventy: patientReport.ageGroupOverSeventy,
                ageGroupSixtyToSeventy: patientReport.ageGroupSixtyToSeventy,
                patientsByGender: patientReport.patientsByGender,
              }}
            />
            <PatientsRiskDegree
              data={{
                patientsByRiskDegree: patientReport.patientsByRiskDegree,
                totalPatients: patientReport.totalPatientsByRiskDegree,
              }}
            />
            <PatientsByInstitution
              data={{
                totalInstitutions: patientReport.totalInstitutions,
                patientsByInstitutions: patientReport.patientsByInstitution,
              }}
            />
          </Content>
        ) : (
          <Spinner />
        )}
      </Container>
    </DashboardTemplate>
  );
};

export default PatientsReports;
