import React, { useMemo, useState } from 'react';

import Main from './Main';
import Complete from './Complete';
import ActivePatient from './ActivePatient';

import { Container } from './styles';
import { useCurrentCallback } from '../../lib/use-current-effect';

export interface AddEditHealthConditionModalProps {
  icd10PatientId?: string;
  action: 'add' | 'edit';
}

const AddEditHealthConditionModal: React.FC<
  AddEditHealthConditionModalProps
> = ({ icd10PatientId, action }) => {
  const [selected, setSelected] = useState(0);

  const handleNext = useCurrentCallback(
    (isCurrent) => (step: number) => {
      if (isCurrent()) {
        setSelected(step);
      }
    },
    [],
  );

  const handleBack = useCurrentCallback(
    (isCurrent) => () => {
      if (isCurrent()) {
        setSelected(0);
      }
    },
    [],
  );

  const step = useMemo(
    () => [
      {
        key: 'main',
        component: (
          <Main
            next={handleNext}
            icd10PatientId={icd10PatientId}
            action={action}
          />
        ),
      },
      {
        key: 'complete',
        component: <Complete back={handleBack} action={action} />,
      },
      {
        key: 'activePatient',
        component: <ActivePatient />,
      },
    ],
    [action, icd10PatientId, handleBack, handleNext],
  );

  return <Container>{step[selected].component}</Container>;
};

export default AddEditHealthConditionModal;
