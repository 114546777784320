import React, { useCallback, useMemo, useState } from 'react';
import { differenceInYears } from 'date-fns';
import { useModal } from 'react-brave-modal';
import { useCurrentEffect } from '../../../../lib/use-current-effect';

import { usePermission } from '../../../../hooks/permission';
import { FilterPatientDTO } from '../../../../dtos/FilterPatientDTO';
import getRiskDegree from '../../../../utils/getRiskDegree';
import OrdenationTypeEnum from '../../../../enums/OrdenationTypeEnum';

import { ReactComponent as ArrowUpDownIcon } from '../../../../assets/images/arrowupdown.svg';
import PatientOnAlertModal from '../../../../modals/PatientOnAlertModal';

import InputCheckWithoutForm from '../../../../components/InputCheckWithoutForm';
import { Icd10 } from '../../../../entities/Icd10';
import HealthConditionDynamic from '../../../../components/HealthConditionDynamic';
import Avatar from '../../../../components/Avatar';
import AttendanceNow from './AttendanceNow';

import { ListType } from '..';

import {
  TopBar,
  ColorNumber,
  Container,
  PatientList,
  PatientNameTable,
  RiskTable,
  ChangeOrderButton,
  TitleWithButton,
  StyledName,
  PatientListHeaderRow,
  PatientListBodyRow,
  PatientListBodyCell,
  PatientListBodyHealthConditionCell,
  StatusAlert,
} from './styles';

interface AlertTableProps {
  filters: FilterPatientDTO;
  setFilters: React.Dispatch<React.SetStateAction<FilterPatientDTO>>;
  list: ListType;
}

type ListDisplayType = {
  id: string;
  userId: string;
  isChecked: boolean;
  name: string;
  avatar?: string;
  age: string | number;
  uf: string;
  riskDegree: { color: string; risk: number | string };
  phone?: string;
  displayableIcd10?: Icd10;
  comorbityCount?: number;
};

type OrderBy = 'name' | 'age' | 'uf' | 'icd10' | 'riskDegree';

const AlertTable: React.FC<AlertTableProps> = ({
  filters,
  setFilters,
  list,
}) => {
  const { hasPermission } = usePermission();
  const { showModal } = useModal();

  const [listDisplay, setListDisplay] = useState<ListDisplayType[]>([]);
  const [isAscending, setIsAscending] = useState<'ASC' | 'DESC'>('ASC');
  const [countItemsChecked, setCountItemsChecked] = useState(0);

  const canViewProfile = useMemo(
    () => hasPermission('patient_profile'),
    [hasPermission],
  );

  const handleOrdenation = useCallback(
    (orderKey: OrderBy, isInitialOrdering: boolean) => {
      if (isInitialOrdering) {
        setFilters((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: OrdenationTypeEnum.ASC,
        }));
        setIsAscending('ASC');
      } else {
        setFilters((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType:
            isAscending === 'ASC'
              ? OrdenationTypeEnum.DESC
              : OrdenationTypeEnum.ASC,
        }));
        setIsAscending((prevState) => (prevState === 'ASC' ? 'DESC' : 'ASC'));
      }
    },
    [isAscending, setFilters],
  );

  useCurrentEffect(
    (isCurrent) => {
      if (list.users.length > 0) {
        const newListDisplay: ListDisplayType[] = list.users.map(
          ({
            patient_id,
            name,
            address,
            patient,
            id,
            birthday,
            phone,
            avatar,
          }) => {
            const displayableHealthConditions = patient?.healthConditions?.map(
              ({ icd10, isMainHealthCondition }) => ({
                ...icd10,
                isMainHealthCondition,
              }),
            )[0];

            return {
              id: patient_id ?? '',
              userId: id ?? '',
              isChecked: false,
              name: name ?? '',
              uf: address?.uf ?? '-',
              phone,
              age: birthday
                ? differenceInYears(new Date(), new Date(birthday))
                : '-',
              riskDegree: {
                color: getRiskDegree.color(
                  patient?.currentRiskDegree &&
                    patient.currentRiskDegree.degree,
                ),
                risk: getRiskDegree.risk(
                  patient?.currentRiskDegree &&
                    patient.currentRiskDegree.degree,
                ),
              },
              avatar,
              displayableHealthCondition: displayableHealthConditions,
              icd10Count: patient?.icd10Count,
            };
          },
        );

        if (isCurrent()) {
          setListDisplay(newListDisplay);
        }
      }
    },
    [list],
  );

  const handleCheck = useCallback(
    (key: string, value: boolean) => {
      const newListDisplay = listDisplay.map((item) => {
        if (item.id === key) {
          item.isChecked = value;
        }

        return item;
      });

      setCountItemsChecked((state) => (value ? state + 1 : state - 1));

      setListDisplay([...newListDisplay]);
    },
    [listDisplay],
  );

  const handleCheckAll = useCallback(
    (value: boolean) => {
      const newListDisplay = listDisplay.map((item) => {
        item.isChecked = value;
        return item;
      });

      setCountItemsChecked(value ? newListDisplay.length : 0);

      setListDisplay([...newListDisplay]);
    },
    [listDisplay],
  );

  const handleShowPatientOnAlertModal = useCallback(
    (patientId: string) => {
      showModal({
        type: 'custom',
        data: <PatientOnAlertModal patientId={patientId} />,
      });
    },
    [showModal],
  );

  return (
    <>
      <TopBar>
        <section>
          <p>
            Exibindo{' '}
            <ColorNumber color="black">{listDisplay.length}</ColorNumber>{' '}
            resultados de{' '}
            <ColorNumber color="primary">{list.totalPatients}</ColorNumber>
          </p>
        </section>
      </TopBar>
      <Container>
        {listDisplay && (
          <PatientList>
            <header>
              <PatientListHeaderRow>
                <InputCheckWithoutForm
                  type="checkbox"
                  onChange={(e) => handleCheckAll(e.target.checked)}
                  checked={listDisplay.length === countItemsChecked}
                />
                <TitleWithButton>
                  <strong>Paciente</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'name'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation('name', filters.orderBy !== 'name')
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>
                <TitleWithButton>
                  <strong>Idade</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'age'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation('age', filters.orderBy !== 'age')
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>
                <TitleWithButton>
                  <strong>Estado</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'uf'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation('uf', filters.orderBy !== 'uf')
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>

                <TitleWithButton>
                  <strong>Condição de saúde</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'icd10'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation('icd10', filters.orderBy !== 'icd10')
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>

                <TitleWithButton>
                  <strong>Grau de Risco</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'riskDegree'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation(
                        'riskDegree',
                        filters.orderBy !== 'riskDegree',
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>

                <TitleWithButton>
                  <strong>Status do alerta</strong>
                </TitleWithButton>
              </PatientListHeaderRow>
            </header>

            <section>
              {listDisplay.map((row) => (
                <PatientListBodyRow key={row.id}>
                  <PatientListBodyCell>
                    <InputCheckWithoutForm
                      type="checkbox"
                      onChange={(e) =>
                        handleCheck(row.id ?? '', e.target.checked)
                      }
                      checked={row.isChecked}
                    />
                  </PatientListBodyCell>

                  <PatientNameTable>
                    <StyledName
                      type="button"
                      onClick={() => handleShowPatientOnAlertModal(row.id)}
                      disabled={!canViewProfile}
                    >
                      <Avatar avatarName={row.avatar} />
                      <strong>{row.name}</strong>
                    </StyledName>
                  </PatientNameTable>

                  <PatientListBodyCell>
                    <p>{row.age}</p>
                  </PatientListBodyCell>
                  <PatientListBodyCell>
                    <p>{row.uf}</p>
                  </PatientListBodyCell>

                  <PatientListBodyHealthConditionCell>
                    {row.displayableIcd10 ? (
                      <HealthConditionDynamic
                        healthConditionsCount={row.comorbityCount}
                        displayableIcd10={row.displayableIcd10}
                      />
                    ) : (
                      '-'
                    )}
                  </PatientListBodyHealthConditionCell>
                  <PatientListBodyCell>
                    {row.riskDegree ? (
                      <RiskTable color={row.riskDegree.color}>
                        <span>
                          <h1>{row.riskDegree.risk}</h1>
                        </span>
                      </RiskTable>
                    ) : (
                      '-'
                    )}
                  </PatientListBodyCell>

                  <PatientListBodyCell>
                    <StatusAlert>Aguardando atendimento</StatusAlert>
                  </PatientListBodyCell>

                  <PatientListBodyCell itemAlign="flex-end">
                    <AttendanceNow
                      patient={{
                        id: row.id,
                        name: row.name,
                        userId: row.userId,
                        phone: row.phone,
                      }}
                    />
                  </PatientListBodyCell>
                </PatientListBodyRow>
              ))}
            </section>
          </PatientList>
        )}
      </Container>
    </>
  );
};

export default AlertTable;
