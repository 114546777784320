import React from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import Spinner from '../Spinner';
import { Container, Button, ErrorStatus } from './style';

type Loading = {
  loading: boolean | undefined;
  serverError: boolean | undefined;
  tryAgain(): void;
};

export function LoadingWithTreatment({
  loading,
  serverError,
  tryAgain,
}: Loading) {
  return (
    <Container>
      {loading && (
        <>
          <Spinner transparent />
          <span>Aguardando conexão...</span>
        </>
      )}
      {serverError && (
        <>
          <ErrorStatus>Erro de conexão com o servidor</ErrorStatus>
          <Button onClick={tryAgain}>
            <FiRefreshCw />
            Tentar novamente
          </Button>
        </>
      )}
    </Container>
  );
}
