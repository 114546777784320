import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: relative;
  margin-bottom: 30px;

  > button {
    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.graySecondary};
    background: transparent;
    border: none;

    span {
      font-weight: 600;
      font-size: 12px;
      margin-right: 10px;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 25px;
      width: 25px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.gray2};
    }
  }
`;

export const SelectDateRange = styled.div`
  display: flex;

  position: absolute;
  background: #fff;
  box-shadow: 0px 3.56307px 17.8153px rgba(6, 92, 119, 0.08);
  border-radius: 6px;
  top: 30px;
  padding: 30px;
  min-height: 360px;
  z-index: 9;
`;

export const CalendarBox = styled.div`
  margin: 0 auto;
  max-width: 325px;

  .rdrDays {
    cursor: not-allowed;
    pointer-events: none;
  }

  .rdrWeekDays {
    margin-bottom: 5px;
  }

  .rdrMonth {
    padding: 0;
  }

  .rdrMonthAndYearWrapper {
    padding: 0;
  }

  .rdrDateRangeWrapper {
    display: flex;
    flex-direction: column;
  }

  .titleCalendar {
    color: #707683;
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
    margin: 0 0 10px 20px;
  }

  .rdrMonthAndYearPickers {
    display: flex;
    justify-content: space-between;

    span > select {
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthsVertical {
    order: 1;
  }

  .rdrWeekDay {
    color: ${(props) => props.theme.colors.black};
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.01em;
    line-height: 11px;
  }

  .rdrDay > span {
    color: #5f5c6b;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.01em;
    line-height: 12px;
  }

  .rdrDayPassive {
    color: #c6c5ce;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
  }

  .rdrInRange {
    background: rgba(45, 156, 219, 0.5);
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #5f5c6b;
  }

  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
    font-weight: bold;
  }
`;

export const RangeOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 230px;
  margin-left: 30px;

  span {
    font-weight: bold;
    font-size: 12px;
    color: #000;
    margin-bottom: 20px;
  }

  button {
    width: 100%;
  }

  div {
    width: 100%;
    margin-bottom: auto;

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 35px;
      font-weight: 500;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.graySecondary};
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      background: transparent;

      &:disabled {
        border: 1px solid ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.primary};
        background: #daedf7;
      }

      & + button {
        margin-top: 10px;
      }
    }
  }
`;
