import React from 'react';

import Spinner from '../../../../../../../../components/Spinner';
import Img from '../../../../../../../../assets/images/blank-sketches-2.svg';

import { Container, IsLoading, NoData } from './styles';

export type ShowScreenType = 'loading' | 'noData' | 'chart';

interface DefaultProps {
  showScreen: ShowScreenType;
}

const Default: React.FC<DefaultProps> = ({ showScreen, children }) => {
  return (
    <>
      {showScreen === 'loading' ? (
        <IsLoading>
          <Spinner />
        </IsLoading>
      ) : showScreen === 'noData' ? (
        <NoData>
          <img src={Img} alt="Sem dados de monitoramento" />
          <p>Sem dados de monitoramento</p>
        </NoData>
      ) : (
        <Container>{children}</Container>
      )}
    </>
  );
};

export default Default;
