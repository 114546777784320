import React, { useCallback } from 'react';

import { useModal } from 'react-brave-modal';

import { useHistory } from 'react-router-dom';
import {
  ButtonsContainer,
  ModalElement,
  ModalGrouper,
  RightSide,
} from './styles';

import Building from '../../../../../assets/images/building.svg';
import Button from '../../../../../components/Button';

const FinishRegisterModal: React.FC = () => {
  const { closeModal } = useModal();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/institution/list');
    closeModal();
  }, [closeModal, history]);

  return (
    <ModalElement>
      <ModalGrouper>
        <img src={Building} alt="Hospital" />
        <RightSide>
          <h1>Instituição criada com sucesso!</h1>
          <ButtonsContainer>
            <Button buttonType="primary-alternative" onClick={handleClose}>
              Ok
            </Button>
          </ButtonsContainer>
        </RightSide>
      </ModalGrouper>
    </ModalElement>
  );
};

export default FinishRegisterModal;
