/* eslint-disable import/no-duplicates */
import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useModal } from 'react-brave-modal';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Container,
  ModalContent,
  ModalForm,
  ExclusionDataCard,
  ButtonsContainer,
} from './styles';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import Button from '../../../../components/Button';
import InputTextArea from '../../../../components/InputTextArea';

import { ReactComponent as DatePickerIconSVG } from '../../../../assets/images/date-picker.svg';
import { ReactComponent as ClockIconSVG } from '../../../../assets/images/icon_schedule_time_gray2.svg';
import { ReactComponent as PersonIconSVG } from '../../../../assets/images/icon_schedule_person_gray2.svg';
import { ReactComponent as StethoscopeIconSVG } from '../../../../assets/images/stethoscope-two.svg';
import ConfirmationPanel from '../../ConfirmationPanel';
import { JustifyAppointmentExclusionModalProps } from '../..';
import { useAppointment } from '../../../../hooks/appointment';
import { appointmentExclusionReasonsOptions } from '../../../../utils/getAppointmentExclusionReason';
import getValidationErrors from '../../../../utils/getValidationErrors';
import RequiredFieldInformation from '../../../../components/RequiredFieldInformation';
import AppointmentExclusionReasonEnum from '../../../../enums/AppointmentExclusionEnum';
import { useAuth } from '../../../../hooks/auth';
import { useCurrentCallback } from '../../../../lib/use-current-effect';

interface ConfirmProps extends JustifyAppointmentExclusionModalProps {
  onSuccess?: () => unknown;
}

const Confirm: React.FC<ConfirmProps> = ({
  patientName,
  professionalName,
  date,
  onSuccess,
  appointmentId,
}) => {
  const { closeModal } = useModal();
  const { removeAppointment } = useAppointment();

  const { user } = useAuth();

  const appointmentDate = useMemo(
    () =>
      date &&
      format(new Date(date), "d 'de' MMMM 'de' yyyy ", {
        locale: ptBR,
      }),
    [date],
  );

  const appointmentHours = useMemo(() => {
    const scheduleTime = date
      ? format(new Date(date), 'HH:mm', { locale: ptBR })
      : false;

    if (scheduleTime) {
      const splittedTime = scheduleTime.split(':');
      const hours: string = splittedTime[0].slice(-2);
      const minutes: string = splittedTime[1];

      return `${hours}:${minutes}`;
    }

    return ':';
  }, [date]);

  const has = useMemo(
    () => ({
      descriptionField: ['administrator', 'clerk'].includes(
        user?.profile?.type ?? '',
      )
        ? {
            isOptional: user?.profile?.type === 'administrator',
          }
        : undefined,
    }),
    [user],
  );

  const [confirmationPanelVisibility, setConfirmationPanelVisibility] =
    useState(false);
  const formRef = useRef<FormHandles>(null);

  const handleGetData = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      if (formRef.current) {
        const data = formRef.current.getData();

        const schema = Yup.object().shape({
          reason: Yup.string()
            .required('A razão é um campo obrigatório')
            .oneOf(Object.values(AppointmentExclusionReasonEnum)),
          reasonDescription:
            !has.descriptionField || has.descriptionField?.isOptional
              ? Yup.string()
              : Yup.string().required('A descrição é um campo obrigatório'),
        });

        const validatedData = await schema.validate(data, {
          abortEarly: false,
        });

        return validatedData;
      }

      return false;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
      }

      return false;
    }
  }, [has]);

  const handleRemoveAppointment = useCallback(async () => {
    try {
      const data = await handleGetData();

      if (data) {
        const { reason, reasonDescription } = data;

        const didRemove = await removeAppointment({
          appointmentId,
          reason,
          reasonDescription,
        });

        didRemove && onSuccess && onSuccess();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formRef.current?.setErrors(errors);
      }
    }
  }, [appointmentId, handleGetData, onSuccess, removeAppointment]);

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async () => {
      (await handleGetData()) &&
        isCurrent() &&
        setConfirmationPanelVisibility(true);
    },
    [handleGetData],
  );

  return (
    <Container>
      <h1>Para excluir este agendamento, você precisa selecionar o motivo:</h1>
      <ModalContent>
        <ModalForm ref={formRef} onSubmit={() => {}}>
          <label>
            Selecione o motivo: <RequiredFieldInformation />
          </label>
          <InputSelectAlternative
            placeholder="Selecione"
            options={appointmentExclusionReasonsOptions}
            name="reason"
          />
          {has.descriptionField && (
            <>
              <label>
                Descrição:
                {!has.descriptionField.isOptional && (
                  <RequiredFieldInformation />
                )}
              </label>
              <InputTextArea
                placeholder="Descreva melhor o motivo"
                name="reasonDescription"
              />
            </>
          )}
        </ModalForm>
        <ExclusionDataCard>
          <DatePickerIconSVG />
          <p>
            <b>Data:</b> {appointmentDate}
          </p>
          <ClockIconSVG />
          <p>
            <b>Hora:</b> {appointmentHours}
          </p>
          <PersonIconSVG />
          <p>
            <b>Paciente:</b> {patientName}
          </p>
          <StethoscopeIconSVG />
          <p>
            <b>Profissional:</b> {professionalName}
          </p>
        </ExclusionDataCard>
      </ModalContent>
      <ButtonsContainer>
        <Button
          disabled={confirmationPanelVisibility}
          onClick={() => closeModal()}
          buttonType="secondary"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          loading={confirmationPanelVisibility}
          buttonType="primary"
        >
          Confirmar
        </Button>
      </ButtonsContainer>
      <ConfirmationPanel
        isVisible={confirmationPanelVisibility}
        onCancel={() => {
          setConfirmationPanelVisibility(false);
        }}
        onSubmit={handleRemoveAppointment}
      />
    </Container>
  );
};

export default Confirm;
