import { Icd10 } from '../../entities/Icd10';
import { api } from '../api';

interface GetIcd10ByKeywordProps {
  keyword: string;
  limit: number;
  page: number;
}

interface SearchIcd10ByKeywordResponse {
  data: Icd10[];
  total: number;
}

export const getIcd10 = async ({
  keyword,
  limit,
  page,
}: GetIcd10ByKeywordProps): Promise<SearchIcd10ByKeywordResponse> => {
  const { data } = await api.get<SearchIcd10ByKeywordResponse>('/icd10', {
    params: {
      keyword,
      limit,
      page,
    },
  });

  return data;
};
