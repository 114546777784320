import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import AppointmentStatusEnum from '../../../../../../enums/AppointmentStatusEnum';

export interface ContainerProps {
  type: AppointmentStatusEnum;
}

const getContainerVariation = (
  type: AppointmentStatusEnum,
): FlattenSimpleInterpolation => {
  switch (type) {
    case AppointmentStatusEnum.CONFIRMED:
      return css`
        color: #707683;
        background: rgba(189, 189, 189, 0.2);
      `;
    case AppointmentStatusEnum.STARTED:
      return css`
        color: #2d9cdb;
        background: rgba(45, 156, 219, 0.2);
      `;
    case AppointmentStatusEnum.FINISHED:
      return css`
        color: #6fcf97;
        background: rgba(111, 207, 151, 0.2);
      `;
    case AppointmentStatusEnum.NOT_ACCOMPLISHED:
    case AppointmentStatusEnum.UNFINISHED:
      return css`
        color: #f2994a;
        background: rgba(242, 153, 74, 0.2);
      `;
    case AppointmentStatusEnum.REMOVED:
      return css`
        color: #eb5757;
        background: rgba(235, 87, 87, 0.2);
      `;
    default:
      return css``;
  }
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  height: 30px;
  padding: 0 8px;
  border-radius: 6px;

  ${({ type }) => getContainerVariation(type)}

  span {
    text-transform: uppercase;
    margin-left: 6px;
    font-weight: normal;
    font-size: 10px;
  }
`;
