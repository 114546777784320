import React, { useState, useEffect, useCallback } from 'react';
import { useModal } from 'react-brave-modal';

import { ModalProps } from '../..';
import { useAppointment } from '../../../../hooks/appointment';

import OccupationAreaEnum from '../../../../enums/OccupationAreaEnum';

import { ReactComponent as StethoscopeIcon } from '../../../../assets/images/icon_stethoscope.svg';
import { ReactComponent as NursingIcon } from '../../../../assets/images/icon_nursing.svg';
import { ReactComponent as OccupationImage } from '../../../../assets/images/occupation.svg';

import { ButtonsContainer, Button } from '../../styles';
import { Container, Grouper, ButtonGroup, ButtonSelect } from './styles';
import AppointmentAreaEnum from '../../../../enums/AppointmentAreaEnum';

const OccupationArea: React.FC<ModalProps> = ({
  attendanceType,
  back,
  next,
}) => {
  const { closeModal } = useModal();
  const { appointmentSelected, saveLocal } = useAppointment();
  const [selected, setSelected] = useState<AppointmentAreaEnum>();

  useEffect(() => {
    if (
      appointmentSelected?.professional?.occupationArea ===
        OccupationAreaEnum.NURSING ||
      appointmentSelected?.area === AppointmentAreaEnum.NURSING
    ) {
      setSelected(AppointmentAreaEnum.NURSING);
    }

    if (
      appointmentSelected?.professional?.occupationArea ===
        OccupationAreaEnum.MEDICINE ||
      appointmentSelected?.area === AppointmentAreaEnum.MEDICINE
    ) {
      setSelected(AppointmentAreaEnum.MEDICINE);
    }
  }, [appointmentSelected]);

  const handleBack = useCallback(() => {
    if (back) {
      saveLocal({ area: selected });
      back();
    }
  }, [back, saveLocal, selected]);

  const handleNext = useCallback(() => {
    if (next && selected) {
      saveLocal({ area: selected });
      next();
    }
  }, [next, saveLocal, selected]);

  return (
    <>
      <Container>
        <Grouper>
          <h1>Área de atuação</h1>
          <p>Para qual área você gostaria de agendar?</p>

          <ButtonGroup>
            <ButtonSelect
              selected={selected === AppointmentAreaEnum.NURSING}
              onClick={() => setSelected(AppointmentAreaEnum.NURSING)}
            >
              <div>
                <NursingIcon />
              </div>
              Enfermagem
            </ButtonSelect>
            <ButtonSelect
              selected={selected === AppointmentAreaEnum.MEDICINE}
              onClick={() => setSelected(AppointmentAreaEnum.MEDICINE)}
            >
              <div>
                <StethoscopeIcon />
              </div>
              Medicina
            </ButtonSelect>
          </ButtonGroup>
        </Grouper>

        <Grouper>
          <OccupationImage />
        </Grouper>
      </Container>

      <ButtonsContainer>
        <Button color="white" onClick={() => closeModal()}>
          Cancelar
        </Button>

        {!attendanceType && (
          <Button color="primary" lite onClick={handleBack}>
            Voltar
          </Button>
        )}

        <Button color="primary" isDisabled={!selected} onClick={handleNext}>
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default OccupationArea;
