import styled from 'styled-components';

import Button from '../../../../components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  height: 520px;
  width: 490px;
  line-height: 18px;
  margin: 55px 0;
  text-align: center;

  h1 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 50px;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
    max-width: 275px;
  }
`;

export const ComeBackButton = styled(Button)`
  align-self: center;
  font-size: 14px;
  margin-top: 40px !important;
  min-width: 152px;
  text-transform: none;
`;
