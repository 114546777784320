import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  ChangeEvent,
} from 'react';
import { toast } from 'react-toastify';
import { useField } from '@unform/core';

import { FiCamera } from 'react-icons/fi';

import validateImage from '../../utils/validateImage';

import { ReactComponent as AvatarImage } from '../../assets/images/avatar.svg';
import { ReactComponent as CameraImage } from '../../assets/images/camera.svg';

import { Container, Label } from './styles';
import { useCurrentCallback } from '../../lib/use-current-effect';

interface Props {
  name: string;
  image?: string;
  backgroundType?: 'avatar' | 'camera';
  withButton?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpload: (file: File) => any;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const InputFile: React.FC<InputProps> = ({
  name,
  image,
  backgroundType,
  withButton,
  onUpload,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  const [preview, setPreview] = useState(defaultValue);

  const backgroundImage = useMemo(
    () => (backgroundType === 'avatar' ? <AvatarImage /> : <CameraImage />),
    [backgroundType],
  );

  useEffect(() => {
    setPreview(image);
  }, [image]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  const handlePreview = useCurrentCallback(
    (isCurrent) => async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (!file) {
        return;
      }

      if (validateImage(file)) {
        const previewURL = URL.createObjectURL(file);

        if (isCurrent()) {
          setPreview(previewURL);
          onUpload(file);
        }
      } else {
        if (isCurrent()) {
          setPreview(null);
        }
        toast.error('Arquivo inválido.');
      }
    },
    [onUpload],
  );

  return (
    <Container backgroundType={backgroundType}>
      {preview ? <img src={preview} alt="Avatar" /> : backgroundImage}
      <Label withButton={withButton}>
        <FiCamera />
        <input type="file" ref={inputRef} onChange={handlePreview} {...rest} />
      </Label>
    </Container>
  );
};

export default InputFile;
