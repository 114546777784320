import styled from 'styled-components';
import { Container as GridCard } from '../../../../components/GridCard/styles';

interface LocationPercentageProps {
  barWidth: number;
}

export const Container = styled(GridCard)``;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 45px 0 45px;
  margin: auto 0;
`;

export const RankContainer = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  > div:first-child {
    span {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const LocationPercentage = styled.div<LocationPercentageProps>`
  display: flex;
  align-items: center;
  gap: 10px;

  div {
    width: ${({ barWidth }) => `${barWidth}%`};
    max-width: 280px;
    height: 19px;
    background: ${({ theme }) => theme.colors.primary};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  span {
    white-space: nowrap;
    font-size: 16px;
    line-height: 19px;
    margin-top: 0.5px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.graySecondary};
  }

  & + div {
    div {
      background: #5fb9ec;
    }

    & + div {
      div {
        background: #9ad5f6;
      }

      & + div {
        div {
          background: #bee8ff;
        }
      }
    }
  }
`;

export const BrasilImageContainer = styled.section`
  flex: 0.8;
  display: flex;
  justify-content: center;
`;
