import styled from 'styled-components';
import { CardHeaderDefault } from '../../styles';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 15px 20px;
  max-height: 180px;
`;

export const ContentNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  > button {
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  > div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;

      padding: 0 10px;

      img {
        margin-right: 10px;
      }

      h4 {
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary};
      }

      span {
        font-size: 13px;
        color: ${({ theme }) => theme.colors.gray};
        white-space: nowrap;

        b {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: none;

    color: ${({ theme }) => theme.colors.primary};

    &:disabled {
      opacity: 0.3;
    }
  }
`;

export const CardHeader = styled(CardHeaderDefault)`
  a,
  span {
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 13px;
  }

  span {
    cursor: not-allowed;
  }
`;

export const DataSection = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(45, 156, 219, 0.05);
  border-radius: 12px;
  padding: 10px 10px;
  width: 100%;
  min-height: 45px;

  > div {
    display: flex;
    align-items: center;

    height: 45px;

    &:first-child {
      padding-right: 15px;
    }

    & + div {
      padding-left: 15px;
      border-left: 0.5px solid #d6d6d6;
    }
  }

  p {
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #707683;
  }
`;

export const DateView = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #0480c6;
  display: flex;
  align-items: center;
  margin-left: 8px;

  > h4 {
    font-size: 36px;
    line-height: 30px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-left: 5px;

    > span {
      font-size: 14px;
      text-transform: uppercase;

      & + span {
        font-size: 12px;
      }
    }
  }
`;

export const HourView = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;

  > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #2289c4;
  }
`;

export const DetailSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 0 10px;

  div {
    display: flex;

    img {
      margin-right: 7px;
      width: 20%;
    }

    span {
      margin-right: 10px;
      font-weight: 500;
      font-size: 14px;
      color: #707683;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    background: transparent;
    font-weight: 500;
    font-size: 12px;
    color: #1f80b7;

    svg {
      margin-left: 8px;
    }
  }
`;
