import React, { useState, useCallback } from 'react';
import { useModal } from 'react-brave-modal';
import { useCurrentEffect } from '../../../../../../lib/use-current-effect';

import List from '../../List';
import Pagination from '../../../../../../components/Pagination';
import ModalButton from '../../../../../../components/ModalButton';
import { ExamProcedure } from '../../../../../../entities/ExamProcedure';
import { usePatient } from '../../../../../../hooks/patient';
import { getExamsProceduresByFilter } from '../../../../../../services/examProcedure';
import OrdenationTypeEnum from '../../../../../../enums/OrdenationTypeEnum';

import { Container, Header, Body, Footer } from './styles';

import ExamsProceduresOrderByEnum from '../../../../../../enums/ExamsProceduresOrderByEnum';

export interface ListFilter {
  orderBy: ExamsProceduresOrderByEnum;
  ordenationType: OrdenationTypeEnum;
  limit?: number;
}

interface SeeAllModalProps {
  handleUpdateList: (examProcedure: ExamProcedure) => void;
  handleRemoveList: (examProcedure: ExamProcedure) => void;
}

const SeeAllModal: React.FC<SeeAllModalProps> = ({
  handleUpdateList,
  handleRemoveList,
}) => {
  const { closeModal } = useModal();
  const { patient } = usePatient();
  const [examsProcedures, setExamsProcedures] = useState<ExamProcedure[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);

  const [listFilter, setListFilter] = useState<ListFilter>({
    orderBy: ExamsProceduresOrderByEnum.DATE,
    ordenationType: OrdenationTypeEnum.DESC,
    limit: 10,
  });

  useCurrentEffect(
    (isCurrent) => {
      if (isCurrent() && patient?.id) {
        getExamsProceduresByFilter({
          patientId: patient.id,
          page: currentPage,
          ...listFilter,
        }).then((response) => {
          if (isCurrent()) {
            setExamsProcedures(response.examsProcedures);
            setCount(response.count);

            if (listFilter.limit) {
              setPages(Math.ceil(response.count / listFilter.limit));
            }
          }
        });
      }
    },
    [patient, currentPage, listFilter],
  );

  const handleListFilter = useCallback((filter: ListFilter) => {
    setListFilter((prevState) => ({
      ...prevState,
      ...filter,
    }));
  }, []);

  return (
    <Container>
      <Header>
        <h1>Exames e Procedimentos</h1>
      </Header>

      <Body>
        <List
          examsProcedures={examsProcedures}
          handleUpdateList={handleUpdateList}
          handleRemoveList={handleRemoveList}
          handleListFilter={handleListFilter}
        />
      </Body>

      <Footer>
        {pages > 0 && (
          <Pagination
            totalPages={pages}
            onChangePage={(page: number) => setCurrentPage(page)}
            page={currentPage}
          />
        )}

        <span>
          Exibindo <strong>{examsProcedures.length}</strong> resultados de{' '}
          <strong>{count}</strong>
        </span>

        <ModalButton
          type="button"
          styleType="blue-light"
          onClick={() => closeModal()}
        >
          Fechar
        </ModalButton>
      </Footer>
    </Container>
  );
};

export default SeeAllModal;
