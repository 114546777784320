import React from 'react';
import Sidebar from './Sidebar';
import MenuMobile from './MenuMobile';

interface MenuProps {
  name?: string;
}

const Menu: React.FC<MenuProps> = ({ name }) => {
  return (
    <>
      <Sidebar selected={name} isMobile={false} closeSidebar={() => {}} />
      <MenuMobile selected={name} />
    </>
  );
};

export default Menu;
