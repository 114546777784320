import React from 'react';
import { useModal } from 'react-brave-modal';
import { Container } from './styles';
import TrashImage from '../../../../assets/images/removed-trash.svg';
import Button from '../../../../components/Button';

const Success = () => {
  const { closeModal } = useModal();

  return (
    <Container>
      <img src={TrashImage} alt="Ícone de lixeira" />
      <div>
        <h2>Exclusão realizada com sucesso !</h2>
        <strong>Aviso:</strong>
        <p>
          Os agendamentos excluídos podem ser consultados através do filtro de
          busca : Status {'>'} Removidos
        </p>
        <Button buttonType="primary" onClick={() => closeModal()}>
          Fechar
        </Button>
      </div>
    </Container>
  );
};

export default Success;
