import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 60px;

  width: 930px;
  height: 625px;

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;

    color: ${({ theme }) => theme.colors.primary};
  }

  h3 {
    margin-top: 8px;

    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: ${({ theme }) => theme.colors.graySecondary};
  }

  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: #707683;
  }

  > span:first-of-type {
    margin-top: 30px;
  }

  > span + span {
    margin-top: 5px;
    padding: 10px;
    background: #f8f8f8;
    border: 1px solid ${({ theme }) => theme.colors.gray2};
    box-sizing: border-box;
    border-radius: 4px;

    max-height: 20%;
    overflow: auto;

    font-size: 16px;
  }

  button {
    align-self: flex-end;
    margin-top: auto;

    padding: 16px 56px;
  }
`;

export const InfoCard = styled.div`
  height: 195px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  background: #f8f8f8;
  border-radius: 6px;
  margin-top: 40px;

  padding: 0 21px;
  width: fit-content;

  color: #707683;

  div {
    display: flex;
    align-items: center;

    p {
      padding: unset;

      font-size: 18px;
      line-height: 21px;

      color: inherit;

      b {
        margin-right: 8px;
      }
    }

    svg {
      color: inherit;
      margin-right: 15px;
    }
  }

  div:first-of-type svg path {
    fill: #707683;
  }
`;
