import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 45px 35px;

  h2 {
    font-size: 26px;
    color: ${({ theme }) => theme.colors.graySecondary};
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  margin-top: 50px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;

    color: ${({ theme }) => theme.colors.graySecondary};
    width: 100%;

    p {
      font-size: 14px;
      padding: 0;

      & + p {
        margin-top: 15px;
      }
    }

    > span {
      text-transform: uppercase;
      margin-top: 40px;
    }

    form {
      margin-top: 15px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;
  margin-top: 40px;

  button + button {
    margin-left: 20px;
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  color: ${({ theme }) => theme.colors.danger};
  margin-top: 30px;

  span {
    font-weight: bold;
    margin-left: 10px;
  }
`;
