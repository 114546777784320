import React, { useMemo } from 'react';
import Chart from 'react-google-charts';
import { IoIosArrowForward } from 'react-icons/io';

import {
  Container,
  LegendChart,
  LegendItem,
  LinkButton,
  CardTitle,
} from './styles';

import Spinner from '../Spinner';
import CloudFileGrayImage from '../../assets/images/cloud-file-gray.svg';
import CardContentNothingToDisplay from '../CardContentNothingToDisplay';

interface PatientsByHealthConditionReportData {
  id: string;
  name: string;
  patients: number;
  color: string;
}

interface PatientsByHealthConditionProps {
  reportData: PatientsByHealthConditionReportData[];
  totalPatientsByHealthConditions: number;
}

const PatientsByHealthConditionsChart: React.FC<
  PatientsByHealthConditionProps
> = ({ reportData, totalPatientsByHealthConditions }) => {
  const displayedGraphData = useMemo(() => {
    const orderedData = reportData
      .map((info): [string, number] => [info.name, info.patients])
      .sort((a, b) => (a && b ? ((b[1] - a[1]) as number) : 0));

    const othersGroup = orderedData.reduce(
      (accum, curr, index) =>
        index > 2 ? ['Outros', accum[1] + curr[1]] : ['Outros', 0],
      ['Outros', 0],
    );

    const newData =
      orderedData.length > 3
        ? [orderedData[0], orderedData[1], orderedData[2], othersGroup]
        : orderedData;

    return newData;
  }, [reportData]);

  const displayedGraphInformation: PatientsByHealthConditionReportData[] =
    useMemo(() => {
      const orderedData = reportData.sort((a, b) =>
        a && b ? ((b.patients - a.patients) as number) : 0,
      );

      const others: PatientsByHealthConditionReportData = {
        color: 'gray',
        id: '0',
        name: 'Outros',
        patients: 0,
      };

      const othersGroup: PatientsByHealthConditionReportData =
        orderedData.reduce((accum, curr, index) => {
          if (index > 2) {
            accum.patients += curr.patients;
            return accum;
          }
          return others;
        }, others);

      const newData =
        orderedData.length > 3
          ? [orderedData[0], orderedData[1], orderedData[2], othersGroup]
          : orderedData;

      return newData;
    }, [reportData]);

  const colors = useMemo(
    () => displayedGraphInformation.map((info) => info.color),
    [displayedGraphInformation],
  );

  return (
    <Container gridArea="pc">
      <header>
        <CardTitle>Pacientes por Condição de Saúde</CardTitle>
      </header>
      <section>
        <div>
          {reportData.length > 0 && (
            <>
              <Chart
                width="200px"
                chartType="PieChart"
                loader={<Spinner />}
                data={[
                  ['Condição de saúde', 'Quantidade'],
                  ...displayedGraphData,
                ]}
                options={{
                  pieHole: 0.5,
                  legend: 'none',
                  colors: [...colors],
                  pieSliceTextStyle: {
                    color: 'white',
                    fontSize: 12,
                  },
                  enableInteractivity: false,
                  chartArea: { width: '100%', height: '100%' },
                }}
                rootProps={{ 'data-testid': '2' }}
              />
              <LegendChart>
                <h2>{totalPatientsByHealthConditions}</h2>
                <h4>TOTAL</h4>
              </LegendChart>
            </>
          )}

          {reportData.length <= 0 && (
            <CardContentNothingToDisplay imageWidth="140px">
              <img src={CloudFileGrayImage} alt="" />
              Sem informações cadastradas
            </CardContentNothingToDisplay>
          )}
        </div>

        {!!displayedGraphInformation.length && (
          <div>
            {displayedGraphInformation.map((info) => (
              <LegendItem color="#d4dee" key={info.id}>
                <div />
                <div>
                  <h3>{info.name}</h3>
                  {info.patients > 1 && <h4>{`${info.patients} pacientes`}</h4>}
                  {info.patients === 0 && <h4>nenhum paciente</h4>}
                  {info.patients === 1 && (
                    <h4>{`${info.patients} paciente`}</h4>
                  )}
                </div>
              </LegendItem>
            ))}
          </div>
        )}
      </section>
      {reportData.length > 0 && (
        <LinkButton to="/patient/list">
          {totalPatientsByHealthConditions > 1 ? (
            <span>
              Ver todos os <span>{totalPatientsByHealthConditions}</span>{' '}
              pacientes
            </span>
          ) : (
            <span>Ver paciente</span>
          )}
          <IoIosArrowForward />
        </LinkButton>
      )}
    </Container>
  );
};

export default PatientsByHealthConditionsChart;
