import React from 'react';

import { ReactComponent as TriangleDownImage } from '../../assets/images/triangle-down.svg';

import { Container, Icd10Balloon, Icd10Box } from './styles';
import Spinner from '../Spinner';
import { Icd10 as Icd10Base } from '../../entities/Icd10';
import Icd10Icon from '../Icd10Icon';

interface Icd10 extends Icd10Base {
  isMainHealthCondition?: boolean;
}

export interface TooltipHealhConditionProps {
  healthConditions: Icd10[];
  healthConditionsCount?: number;
  onCountHover?: () => any;
  showSpinner?: boolean;
}

const TooltipHealthCondition: React.FC<TooltipHealhConditionProps> = ({
  healthConditions,
  healthConditionsCount,
  onCountHover,
  showSpinner,
}) => {
  return (
    <Container onMouseEnter={() => onCountHover && onCountHover()}>
      <Icd10Balloon>
        {healthConditions.map((icd10) => (
          <Icd10Box key={icd10.id}>
            <Icd10Icon condition={icd10.chapter} />
            <span>
              {`${icd10.icd} - ${icd10.description}${
                icd10.isMainHealthCondition ? ' (principal)' : ''
              }`}
            </span>
          </Icd10Box>
        ))}
        {showSpinner && <Spinner size={60} />}
      </Icd10Balloon>
      <TriangleDownImage />
      <span>+{healthConditionsCount}</span>
    </Container>
  );
};

export default TooltipHealthCondition;
