import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 570px;
  width: 930px;
  padding: 70px 45px 45px;
`;

export const Header = styled.div`
  margin-bottom: 40px;

  h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-weight: 500;
    font-size: 26px;
  }
`;

export const Body = styled.div`
  height: 300px;
  overflow-y: auto;
  margin-bottom: auto;
  padding-right: 30px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    margin: 0;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: 42px;
    }
  }

  > span {
    color: ${(props) => props.theme.colors.gray3};
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
    margin-right: auto;

    strong {
      font-weight: 600;
    }

    strong:last-child {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  > button {
    max-height: 50px;
  }
`;
