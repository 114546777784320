import React, { useRef, useEffect, useState } from 'react';
import { GroupBase, OptionsOrGroups, SelectInstance } from 'react-select';
import { useField } from '@unform/core';
import { AsyncProps } from 'react-select/dist/declarations/src/Async';
import { AsyncPaginate } from 'react-select-async-paginate';
import { OptionType } from '../../@types/react-select';
import DropdownIndicator from '../InputSelectAlternative/DropdownIndicator';

import theme from '../../styles/theme';

type SelectRef = SelectInstance<OptionType, any, any>;

type Response<TOption, TGroup, TAdditional> = {
  options: OptionsOrGroups<TOption, any>;
  hasMore: boolean;
  additional?: TAdditional;
};

interface Props
  extends Omit<
    AsyncProps<OptionType<string, string>, true, any>,
    'loadOptions'
  > {
  name: string;
  loadOptions: (
    inputValue: string,
    loadedOptions: OptionsOrGroups<OptionType<string, string>, GroupBase<any>>,
    additional?: { page: number },
  ) => Promise<
    Response<OptionType<string, string>, GroupBase<unknown>, { page: number }>
  >;
}

const InputAsyncSelect: React.FC<Props> = ({ name, loadOptions, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: SelectRef) => {
        const refValue = ref?.getValue();

        if (rest.isMulti) {
          return refValue ? refValue.map(({ value }) => value) : [];
        }

        return refValue ? refValue[0]?.value ?? '' : '';
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      color: `${theme.colors.gray2}`,
      fontSize: '14px',
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
      minHeight: '42px',
      borderColor: isFocused ? `${theme.colors.primary}` : '',
      boxShadow: '',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: `${theme.colors.graySecondary}`,
      fontSize: '14px',
      fontFeatureSettings: `'pnum' on, 'lnum' on`,
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      paddingRight: '16px',
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: '14px',
    }),
  };

  return (
    <AsyncPaginate
      loadOptions={loadOptions}
      defaultValue={defaultValue}
      selectRef={selectRef}
      classNamePrefix="react-select"
      isSearchable
      isClearable
      debounceTimeout={400}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      additional={{
        page: 1,
      }}
      styles={customStyles}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      {...rest}
    />
  );
};

export default InputAsyncSelect;
