import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
}

interface ButtonRadioProps {
  isErrored: boolean;
  isSelected: boolean;
  color: string;
  background: string;
  backgroundSelected: string;
  optionStyle: 'circle' | 'roundedRectangle';
}

export const Container = styled.div<ContainerProps>`
  background-color: none;

  position: relative;

  width: 100%;

  color: #828282;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  strong {
    position: absolute;
    top: 9px;
    font-style: normal;
    font-weight: 600;
    font-size: 0.6rem;
    line-height: 0.5rem;
    text-transform: uppercase;
    color: #828282;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  input {
    flex: 1;
    border: 0;
    background: transparent;
    color: #000;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 14px;
    padding-top: 15px;

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &::placeholder {
      color: #4f4f4f;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const ButtonGroupRadio = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-left: -2px;
  width: 100%;

  border-radius: 0 0 6px 6px;
  padding: 0 0 8px 0px;
  font-size: 10px;
  line-height: 14px;
`;

export const ButtonRadio = styled.button<ButtonRadioProps>`
  border-radius: 50px;
  padding: ${({ optionStyle }) =>
    optionStyle === 'circle' ? '10px 13px' : '5px 20px'};

  margin-right: 20px;
  border: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  ${({ optionStyle }) =>
    optionStyle === 'circle'
      ? css`
          font-style: normal;
          font-weight: 800;
          font-size: 22px;
          line-height: 18px;

          text-align: center;
          font-feature-settings: 'pnum' on, 'lnum' on;
        `
      : css`
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;

          text-align: center;
          text-transform: uppercase;
        `}

  color: ${({ color, isSelected, disabled }) => {
    if (disabled) {
      return '#BDBDBD';
    }

    return isSelected ? '#fff' : color;
  }};
  background: ${({ background, isSelected, backgroundSelected, disabled }) => {
    if (disabled) {
      return '#EAECF0';
    }
    return isSelected ? backgroundSelected : background;
  }};

  ${(props) =>
    props.isErrored &&
    css`
      border: 1px solid #c53030;
    `}
`;
