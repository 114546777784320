import { InstitutionType } from '../entities/Institution';

interface GetInstitutionTypeProps {
  value: InstitutionType;
  label: InstitutionType;
}

const getInstitutionType: GetInstitutionTypeProps[] = [
  {
    value: 'Clínica',
    label: 'Clínica',
  },
  {
    value: 'Consultório',
    label: 'Consultório',
  },
  {
    value: 'Homecare',
    label: 'Homecare',
  },
  {
    value: 'Hospital',
    label: 'Hospital',
  },
  {
    value: 'Laboratório',
    label: 'Laboratório',
  },
  {
    value: 'Outros',
    label: 'Outros',
  },
];

export default getInstitutionType;
