import styled, { css } from 'styled-components';

interface ContainerProps {
  backgroundType?: 'avatar' | 'camera';
}
interface LabelProps {
  withButton?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border: 0;
  border-radius: 50%;
  height: 170px;
  width: 170px;
  position: relative;

  > svg,
  > img {
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    height: 170px;
    width: 170px;
  }

  ${(props) =>
    props.backgroundType === 'camera' &&
    css`
      > svg {
        width: 58px;

        path {
          fill: #cbcbcb;
        }
      }
    `}

  input {
    display: none;
  }
`;

export const Label = styled.label<LabelProps>`
  ${(props) =>
    props.withButton
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0;
          right: 15px;
          background-color: #e6e6e6;
          border: 2px solid ${props.theme.colors.white};
          border-radius: 50%;
          color: #707683;
          cursor: pointer;
          height: 32px;
          width: 32px;
        `
      : css`
          display: none;
        `}

  svg {
    color: #707683;
    height: 15px;
    margin-bottom: 2px;
  }
`;
