import styled, { css } from 'styled-components';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';

interface GridItemProps {
  gridArea?: 'di' | 'du' | 'en' | 'sai' | 'int' | 'ints' | 'me';
}

interface OptionProps {
  selected: boolean;
}

interface ScheduleProps {
  isButtonVisible?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding-right: 52px;
`;

export const OptionButton = styled.div<OptionProps>`
  width: 190px;
  border-radius: 6px;

  display: flex;
  align-items: center;

  padding: 6px;

  cursor: pointer;

  > span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    color: #707683;
    margin-left: 14px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: #daedf7;

      svg > path {
        fill: #2d9cdb;
      }

      > span {
        color: #2d9cdb;
      }
    `}
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    margin-bottom: 2px;
    margin-right: 8px;
    vertical-align: middle;
  }

  > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #707683;
  }

  > div {
    display: flex;
    place-items: center;

    margin-right: 15px;

    > span {
      margin-right: 10px;
      white-space: nowrap;
    }

    > input {
      margin-right: 25px;
    }
  }
`;

export const Content = styled.div<ScheduleProps>`
  > button {
    width: unset;
    margin-top: 35px;
  }
`;

export const ScheduleCard = styled.div`
  margin-top: 30px;

  padding: 30px;

  background: #f8f8f8;
  border-radius: 6px;
`;

export const GridContent = styled.div`
  margin-top: 30px;

  display: grid;
  grid-template: 1fr 1fr 1fr auto 1fr / repeat(3, 1fr);
  grid-template-areas:
    'di du NONE'
    'en sai NONE'
    'int NO NONE'
    'ints ints ints'
    'me NONO NONO';

  grid-column-gap: 40px;
  grid-row-gap: 25px;
`;

export const Intervals = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 25px;
  grid-column-gap: 40px;

  > button {
    border: none;
    background: none;
    padding: 0;

    width: 50%;
  }
`;

export const Field = styled.div<GridItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  width: 100%;
  display: flex;

  justify-content: flex-end;

  > label {
    flex: 2;

    margin-right: 10px;

    display: flex;

    place-content: flex-end;
    place-items: center;
  }

  > div {
    flex: 8;
  }
`;

export const FieldAlternative = styled.div<GridItemProps>`
  display: flex;
  place-items: center;

  grid-area: ${({ gridArea }) => gridArea};

  > strong {
    flex: 1;

    text-align: end;
    margin-right: 20px;
  }

  > p {
    flex: 1;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #707683;
  }
`;

export const AddButton = styled.button<ScheduleProps>`
  grid-area: auto;

  border: none;
  background: none;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  font-feature-settings: 'pnum' on, 'lnum' on;

  color: #2d9cdb;

  width: 50%;

  ${({ isButtonVisible }) =>
    !isButtonVisible &&
    css`
      display: none;
    `};
`;

export const InputSelect = styled(InputSelectAlternative)`
  color: #2d9cdb;

  .react-select__single-value {
    color: #2d9cdb !important;
    margin: 0 auto !important;
    font-size: 16px !important;
    height: 18px;
    top: 55%;
  }

  .react-select__value-container--has-value {
    display: flex;
    justify-content: center;
  }

  > div:first-child {
    height: 50px;
    border: 1px solid ${(props) => props.theme.colors.gray2};
    border-width: 1px !important;

    &:focus {
      border-width: 1px !important;
    }
  }

  .react-select__control {
    border-width: 1px !important;
    &:focus {
      border-width: 1px !important;
    }
  }

  .react-select__control--is-focused {
    height: 100%;
  }

  &:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }
`;

export const ScheduleCardHeader = styled.div<ScheduleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #707683;
  }

  > button {
    border: none;
    background: none;
    padding: 0;

    ${({ isButtonVisible }) =>
      !isButtonVisible &&
      css`
        display: none;
      `};
  }
`;
