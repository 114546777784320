import styled from 'styled-components';

export const Container = styled.div`
  height: 550px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 800px;
  padding: 0 90px;

  img {
    width: 175px;
    height: 175px;
  }

  div {
    margin-left: 70px;

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: ${({ theme }) => theme.colors.graySecondary};
    }

    p {
      padding: 15px 0 0 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: ${({ theme }) => theme.colors.black};
    }

    p + p {
      padding: 0;
    }
  }

  button {
    position: absolute;
    bottom: 32px;
    right: 35px;
  }
`;
