import styled from 'styled-components';

export const Container = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;

  tr + tr {
    border-top: 1px solid #efefef;
  }

  th {
    color: ${(props) => props.theme.colors.gray};
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    padding-bottom: 10px;
  }

  td {
    font-weight: 500;
    color: #707683;
    padding: 15px 0 10px;

    > svg {
      margin-right: 8px;
      vertical-align: middle;
      width: 12px;
    }

    button {
      display: flex;
      background: none;
      border: 0;
      color: ${(props) => props.theme.colors.primary};
      cursor: pointer;
      font-size: 24px;
      outline: none;
    }
  }

  tbody tr:last-child {
    border-bottom: 1px solid #efefef;
  }

  td:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SortButton = styled.button`
  background: none;
  border: 0;
  color: ${(props) => props.theme.colors.gray};
  cursor: pointer;
  font-size: 14px;
  outline: none;

  svg {
    margin-left: 5px;
    vertical-align: middle;
  }
`;
