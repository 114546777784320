import { api } from '../api';
import { User } from '../../entities/User';

interface IdentifyUser {
  token: string;
  cpf: string;
  birthday: Date;
  email: string;
  phone: string;
}

export const identifyUser = async (userData: IdentifyUser): Promise<User> => {
  const { data } = await api.get('/users/identify', {
    params: {
      token: userData.token,
      cpf: userData.cpf,
      birthday: userData.birthday,
      email: userData.email,
      phone: userData.phone,
    },
  });

  return data;
};
