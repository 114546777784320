import styled from 'styled-components';

interface ProfileType {
  isSelected: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 45px 45px 7px 45px;
`;

export const Grouper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &:last-child {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    > img {
      margin-right: 90px;
    }
  }

  > h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-top: 25px;
  }

  > p {
    color: ${(props) => props.theme.colors.grayAlternative};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-top: 60px;
    padding: 0;
  }

  > input {
    margin-top: 25px;
  }
`;

export const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 6px;
  height: 270px;
  width: 425px;
  padding: 30px 40px;
`;

export const Row = styled.div`
  display: flex;
  color: #707683;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;

  > strong {
    font-weight: 700;
  }

  > svg {
    margin-right: 20px;
  }

  :first-child,
  :last-child {
    svg {
      > path {
        fill: #707683;
      }
    }
  }

  :not(:first-child, :last-child) {
    svg {
      > path {
        stroke: #707683;
      }
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  place-items: center;

  > div + div {
    margin-left: 22px;
  }
`;

export const OptionCard = styled.div<ProfileType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.isSelected ? '#DAEDF7' : '#f2f2f2')};
  border-radius: 6px;
  cursor: pointer;
  height: 166px;
  width: 166px;

  :first-child > svg {
    margin-left: 8px;
  }

  :last-child {
    cursor: default;

    > svg {
      width: 40px;
      height: 40px;

      /*       > path {
        :first-child {
          fill: #4caf50;
        }
      } */
    }
  }

  > span {
    color: ${(props) => (props.isSelected ? '#1F80B7' : '#707683')};
    font-size: 16px;
    font-style: normal;
    font-weight: ${(props) => (props.isSelected ? 'bold' : 500)};
    letter-spacing: 0.01em;
    line-height: 19px;
    margin-top: 8px;
    max-width: 100px;
    text-align: center;
  }
`;
