import { api } from '../api';
import { Sheet } from '../../entities/Sheet';

export interface GetSheetsByFilter {
  sheetName?: string;
  patientName?: string;
  professionalName?: string;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
  orderBy?: string;
  ordenationType?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export interface GetSheetsByFilterResponse {
  sheets: Sheet[];
  totalSheets: number;
  totalPages: number;
  totalSheetsOnPage: number;
}

export const getSheetsByFilter = async (
  sheetData: GetSheetsByFilter,
): Promise<GetSheetsByFilterResponse> => {
  const { data } = await api.get<GetSheetsByFilterResponse>('sheets/filter', {
    params: { ...sheetData },
  });

  const sheetListData = {
    sheets: data.sheets,
    totalSheets: data.totalSheets,
    totalPages: data.totalPages,
    totalSheetsOnPage: data.totalSheetsOnPage,
  };

  return sheetListData;
};
