import styled from 'styled-components';
import ButtonBase from '../../../components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 360px;

  margin-top: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-top: 30px;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  > h1 {
    font-family: 'Raleway';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const Button = styled(ButtonBase)`
  width: 222px;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 13px;
  }
`;
