import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useField } from '@unform/core';
import { BsFileEarmarkPlus, BsFileEarmarkCheck } from 'react-icons/bs';
import { VscWarning } from 'react-icons/vsc';
import { FiTrash, FiAlertCircle, FiX } from 'react-icons/fi';
import { useDropzone } from 'react-dropzone';

import { InputContainer, UploadedFile, Legend } from './styles';

interface InputProps {
  name: string;
  isDisabled?: boolean;
}
interface InputRefProps extends HTMLInputElement {
  acceptedFiles: File[];
}

const InputDropzone: React.FC<InputProps> = ({
  name,
  isDisabled = false,
  ...rest
}) => {
  const inputRef = useRef<InputRefProps>(null);
  const { fieldName, registerField, defaultValue = [], error } = useField(name);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>(defaultValue);
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: 'image/jpeg,image/gif,image/png,application/pdf,image/x-eps',
      onDrop: (onDropAcceptedFiles) => {
        if (inputRef.current) {
          inputRef.current.acceptedFiles = onDropAcceptedFiles;
          setAcceptedFiles(onDropAcceptedFiles);
        }
      },
    });

  const handleClearInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.acceptedFiles = [];
      inputRef.current.name = '';
      inputRef.current.value = '';

      setAcceptedFiles([]);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: InputRefProps) => {
        return ref.acceptedFiles || [];
      },
      clearValue: (ref: InputRefProps) => {
        ref.acceptedFiles = [];
        setAcceptedFiles([]);
      },
      setValue: (ref: InputRefProps, value) => {
        ref.acceptedFiles = value;
        setAcceptedFiles(value);
      },
    });
  }, [fieldName, registerField]);

  const textInput = useMemo(() => {
    if (isDragActive && isDragReject) {
      return (
        <>
          <VscWarning size={40} />
          <h3>Arquivo não suportado</h3>
        </>
      );
    }
    if (isDragActive && !isDragReject) {
      return (
        <>
          <BsFileEarmarkPlus size={40} />
          <h3>Solte o arquivo aqui</h3>
        </>
      );
    }
    if (acceptedFiles[0]) {
      return (
        <>
          <BsFileEarmarkCheck size={40} />
          <h3>{acceptedFiles[0].name}</h3>
        </>
      );
    }

    return (
      <>
        <BsFileEarmarkPlus size={40} />
        <h3>Arraste o arquivo ou clique para buscar</h3>
      </>
    );
  }, [isDragActive, isDragReject, acceptedFiles]);

  const componentUploadedFile = useMemo(() => {
    if (acceptedFiles[0] && acceptedFiles[0].size > 2097152) {
      return (
        <UploadedFile isErrored>
          <FiAlertCircle size={40} />
          <h3>Tamanho do arquivo excede o limite</h3>

          <button type="button" onClick={handleClearInput}>
            <FiX size={30} />
          </button>
        </UploadedFile>
      );
    }
    if (acceptedFiles[0] && acceptedFiles[0].size <= 2097152) {
      return (
        <UploadedFile isErrored={false}>
          <BsFileEarmarkCheck size={40} />
          <h3>{acceptedFiles[0].name}</h3>

          <button type="button" onClick={handleClearInput}>
            <FiTrash size={30} />
          </button>
        </UploadedFile>
      );
    }

    return undefined;
  }, [acceptedFiles, handleClearInput]);

  return (
    <>
      <InputContainer
        isErrored={!!error}
        isDisabled={isDisabled}
        {...getRootProps()}
        isFilled={!!acceptedFiles[0]}
        isDragActive={isDragActive}
        isDragReject={isDragReject}
      >
        <div>{textInput}</div>

        <input
          {...getInputProps()}
          accept="image/jpeg,image/png,application/pdf"
          ref={inputRef}
          disabled={isDisabled}
          {...rest}
        />
      </InputContainer>

      {acceptedFiles[0] ? (
        componentUploadedFile
      ) : (
        <Legend>Formatos aceitos: PDF, JPG e PNG, até 2Mb.</Legend>
      )}
    </>
  );
};

export default InputDropzone;
