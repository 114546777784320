/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { components } from 'react-select';

import { ReactComponent as DropDownIndicatorIcon } from '../../../assets/images/dropdown-indicator.svg';

const DropdownIndicator: React.FC = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIndicatorIcon />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
