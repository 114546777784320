import React from 'react';
import { useModal } from 'react-brave-modal';

import Check from '../../../../../assets/images/check-ok-2.svg';

import { Container, Button } from './styles';

const RequestSentModal: React.FC = () => {
  const { closeModal } = useModal();

  return (
    <Container>
      <img src={Check} alt="Imagem solicitação enviada" />
      <h1>Solicitação enviada!</h1>
      <p>
        Enviamos um e-mail com instruções de criação de nova senha para o
        usuário. O link disponível no e-mail tem prazo de 48h para utilização.
      </p>
      <Button type="button" onClick={() => closeModal()}>
        Fechar
      </Button>
    </Container>
  );
};

export default RequestSentModal;
