import React, { lazy, Suspense, useMemo, useState } from 'react';
import { useModal } from 'react-brave-modal';
import { useAuth } from '../../../../../../hooks/auth';

import { useCurrentCallback } from '../../../../../../lib/use-current-effect';
import history from '../../../../../../services/history';
import { updateUserProfile } from '../../../../../../services/user';

import Confirm from './Confirm';
import Success from './Success';

const SelfProfileUpdateConfirmationModal = lazy(
  () =>
    import(
      /* webpackChunkName: "SelfProfileUpdateConfirmationModal" */ '../../../../../../modals/SelfProfileUpdateConfirmationModal'
    ),
);

interface UpdateProfileConfirmModalProps {
  keys: string[];
  type: string;
  onUpdate?: () => void;
}

const UpdateProfileConfirmModal: React.FC<UpdateProfileConfirmModalProps> = ({
  keys,
  type,
  onUpdate,
}) => {
  const [selected, setSelected] = useState('Confirm');

  const { user, sync } = useAuth();

  const { closeModal } = useModal();

  const isUserUpdatingOwnProfile = useMemo(
    () => keys.some((item) => item === user?.id),
    [keys, user],
  );

  const items = useMemo(
    () =>
      keys.map((item) => ({
        userID: item,
        typeID: type,
      })),
    [keys, type],
  );

  const handleSuccess = useCurrentCallback(
    (isCurrent) => () => {
      if (isUserUpdatingOwnProfile) {
        setSelected('SelfProfileUpdate');

        return;
      }

      onUpdate && onUpdate();

      isCurrent() && setSelected('Success');
    },
    [isUserUpdatingOwnProfile, onUpdate],
  );

  const handleSelfUpdateSuccess = useCurrentCallback(
    (isCurrent) => async () => {
      await updateUserProfile(items);

      history.push('/profile/list');

      await sync();

      onUpdate && onUpdate();

      isCurrent() && setSelected('Success');
    },
    [items, onUpdate, sync],
  );

  const Screens = useMemo(
    () => ({
      Confirm: {
        component: (
          <Confirm
            isUserUpdatingOwnProfile={isUserUpdatingOwnProfile}
            items={items}
            onSuccess={handleSuccess}
          />
        ),
      },
      SelfProfileUpdate: {
        component: (
          <Suspense fallback={false}>
            <SelfProfileUpdateConfirmationModal
              onCancel={() => closeModal()}
              onProceed={handleSelfUpdateSuccess}
            />
          </Suspense>
        ),
      },
      Success: {
        component: <Success keys={keys} />,
      },
    }),
    [
      closeModal,
      handleSelfUpdateSuccess,
      handleSuccess,
      isUserUpdatingOwnProfile,
      items,
      keys,
    ],
  );

  return <>{Screens[selected as keyof typeof Screens].component}</>;
};

export default UpdateProfileConfirmModal;
