import React, { useCallback, useMemo, useState } from 'react';
import { useModal } from 'react-brave-modal';
import { MdRemoveRedEye } from 'react-icons/md';
import { useCurrentEffect } from '../../../../lib/use-current-effect';

import UserStatus from '../../../../entities/UserStatus';
import { usePermission } from '../../../../hooks/permission';
import { FilterPatientDTO } from '../../../../dtos/FilterPatientDTO';
import getRiskDegree from '../../../../utils/getRiskDegree';
import OrdenationTypeEnum from '../../../../enums/OrdenationTypeEnum';

import { ReactComponent as StethoscopeIcon } from '../../../../assets/images/stethoscope.svg';
import { ReactComponent as ArrowUpDownIcon } from '../../../../assets/images/arrowupdown.svg';
import { ReactComponent as TurnOffIcon } from '../../../../assets/images/turn-on-off.svg';
import ImgAvatar from '../../../../assets/images/avatar.svg';

import CreateEditAppointmentModal from '../../../../modals/CreateEditAppointmentModal';
import ActionMenuButtonList from '../../../../components/ActionMenuButtonList';
import InputCheckWithoutForm from '../../../../components/InputCheckWithoutForm';
import Avatar from '../../../../components/Avatar';
import ActionMenuButtonItemList from '../../../../components/ActionMenuButtonItemList';

import { ListType } from '..';

import {
  TopBar,
  Actions,
  ActionLinkTable,
  ColorNumber,
  Container,
  PatientList,
  PatientNameTable,
  RiskTable,
  ChangeOrderButton,
  TitleWithButton,
  StyledLink,
  PatientListHeaderRow,
  PatientListBodyRow,
  PatientListBodyCell,
  PatientListBodyOptionsCell,
  PatientListBodyHealthConditionCell,
} from './styles';
import UserStatusEnum from '../../../../enums/UserStatusEnum';
import { Icd10 as Icd10Base } from '../../../../entities/Icd10';
import HealthConditionDynamic from '../../../../components/HealthConditionDynamic';

interface TableProps {
  filters: FilterPatientDTO;
  setFilters: React.Dispatch<React.SetStateAction<FilterPatientDTO>>;
  list: ListType;
}

type ActionsType = {
  name: string;
  action: string;
  disabled: boolean;
};

interface Item {
  isChecked: boolean;
  currentStatus?: UserStatus;
  id: string;
  userId: string;
  patientName: string;
  gender?: 'M' | 'F' | 'ND';
  uf: string;
  city: string;
  riskDegree: { color: string; risk: number | string };
  displayableIcd10?: Icd10;
  icd10Count?: number;
  avatar?: string;
  actions: ActionsType[];
}

interface Icd10 extends Icd10Base {
  isMainComorbity?: boolean;
}

type OrderBy = 'name' | 'gender' | 'uf' | 'city' | 'icd10' | 'riskDegree';

const Table: React.FC<TableProps> = ({ filters, setFilters, list }) => {
  const { showModal } = useModal();
  const { hasPermission } = usePermission();

  const [listDisplay, setListDisplay] = useState<Item[]>([]);
  const [isAscending, setIsAscending] = useState<'ASC' | 'DESC'>('ASC');
  const [countItemsChecked, setCountItemsChecked] = useState(0);

  const canViewProfile = useMemo(
    () => hasPermission('patient_profile'),
    [hasPermission],
  );

  const canShow = useMemo(
    () => ({
      createPatient: hasPermission('register_patient'),
      editPatient: hasPermission('edit_patient'),
      viewPatient: hasPermission('patient_profile'),
    }),
    [hasPermission],
  );

  const handleOrdenation = useCallback(
    (orderKey: OrderBy, isInitialOrdering: boolean) => {
      if (isInitialOrdering) {
        setFilters((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: OrdenationTypeEnum.ASC,
        }));
        setIsAscending('ASC');
      } else {
        setFilters((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType:
            isAscending === 'ASC'
              ? OrdenationTypeEnum.DESC
              : OrdenationTypeEnum.ASC,
        }));
        setIsAscending((prevState) => (prevState === 'ASC' ? 'DESC' : 'ASC'));
      }
    },
    [isAscending, setFilters],
  );

  useCurrentEffect(
    (isCurrent) => {
      if (list.users.length > 0) {
        const newListDisplay: Item[] = list.users.map(
          ({
            patient_id,
            currentStatus,
            name,
            gender,
            address,
            patient,
            id,
            avatar,
          }) => {
            const displayableIcd10 = patient?.healthConditions?.map(
              ({ icd10, isMainHealthCondition }) => ({
                ...icd10,
                isMainHealthCondition,
              }),
            )[0];

            const actions: ActionsType[] =
              currentStatus?.status === UserStatusEnum.DRAFT
                ? [
                    {
                      name: 'Completar Cadastro',
                      action: `/patient/register/complete?id=${patient_id}&status=unfinished`,
                      disabled: !canShow.createPatient,
                    },
                  ]
                : [
                    {
                      name: 'Editar Perfil',
                      action: `/patient/edit?id=${patient_id}`,
                      disabled: !canShow.editPatient,
                    },
                    {
                      name:
                        currentStatus?.status === UserStatusEnum.ACTIVE
                          ? 'Desativar'
                          : 'Ativar',
                      action: `/patient/edit?id=${patient_id}&page=Status`,
                      disabled: !canShow.editPatient,
                    },
                  ];

            return {
              id: patient_id ?? '',
              userId: id ?? '',
              isChecked: false,
              currentStatus,
              patientName: name ?? '',
              gender,
              uf: address?.uf ?? '-',
              city: address?.city ?? '-',
              riskDegree: {
                color: getRiskDegree.color(
                  patient?.currentRiskDegree &&
                    patient.currentRiskDegree.degree,
                ),
                risk: getRiskDegree.risk(
                  patient?.currentRiskDegree &&
                    patient.currentRiskDegree.degree,
                ),
              },
              displayableIcd10,
              icd10Count: patient?.icd10Count,
              avatar,
              actions,
            };
          },
        );

        if (isCurrent()) {
          setListDisplay(newListDisplay);
        }
      }
    },
    [list, canShow],
  );

  const handleCheck = useCallback(
    (key: string, value: boolean) => {
      const newListDisplay = listDisplay.map((item) => {
        if (item.id === key) {
          item.isChecked = value;
        }

        return item;
      });

      setCountItemsChecked((state) => (value ? state + 1 : state - 1));

      setListDisplay([...newListDisplay]);
    },
    [listDisplay],
  );

  const handleCheckAll = useCallback(
    (value: boolean) => {
      const newListDisplay = listDisplay.map((item) => {
        item.isChecked = value;
        return item;
      });

      setCountItemsChecked(value ? newListDisplay.length : 0);

      setListDisplay([...newListDisplay]);
    },
    [listDisplay],
  );

  const handleBulkAppointment = useCallback(() => {
    const bulkFilterList = listDisplay
      .filter((patient) => patient.isChecked)
      .map((patient) => ({
        id: patient.id,
        name: patient.patientName,
        avatar: patient.avatar || ImgAvatar,
      }));

    showModal({
      type: 'custom',
      data: (
        <CreateEditAppointmentModal
          bulkFilterList={bulkFilterList}
          onFinish={() => {}}
        />
      ),
    });
  }, [showModal, listDisplay]);

  return (
    <>
      <TopBar>
        <Actions>
          <span>Ações</span>

          <ActionMenuButtonList
            list={[
              {
                icon: <StethoscopeIcon />,
                name: 'Agendamento em massa',
                action: handleBulkAppointment,
              },
            ]}
            disabled={countItemsChecked === 0}
          />
        </Actions>

        <section>
          <div>
            Exibindo{' '}
            <ColorNumber color="black">{listDisplay.length}</ColorNumber>{' '}
            resultados de{' '}
            <ColorNumber color="primary">{list.totalPatients}</ColorNumber>
          </div>
        </section>
      </TopBar>

      <Container>
        {listDisplay && (
          <PatientList>
            <header>
              <PatientListHeaderRow>
                <InputCheckWithoutForm
                  type="checkbox"
                  onChange={(e) => handleCheckAll(e.target.checked)}
                  checked={listDisplay.length === countItemsChecked}
                />
                <TitleWithButton>
                  <strong>Paciente</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'name'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation('name', filters.orderBy !== 'name')
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>

                <TitleWithButton>
                  <strong>Sexo</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'gender'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation('gender', filters.orderBy !== 'gender')
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>

                <TitleWithButton>
                  <strong>Estado</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'uf'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation('uf', filters.orderBy !== 'uf')
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>

                <TitleWithButton>
                  <strong>Cidade</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'city'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation('city', filters.orderBy !== 'city')
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>

                <TitleWithButton>
                  <strong>Condição de saúde</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'icd10'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation('icd10', filters.orderBy !== 'icd10')
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>

                <TitleWithButton>
                  <strong>Grau de Risco</strong>
                  <ChangeOrderButton
                    isActive={filters.orderBy === 'riskDegree'}
                    ordenationType={isAscending}
                    type="button"
                    onClick={() =>
                      handleOrdenation(
                        'riskDegree',
                        filters.orderBy !== 'riskDegree',
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </ChangeOrderButton>
                </TitleWithButton>
              </PatientListHeaderRow>
            </header>

            <section>
              {listDisplay.map((row) => (
                <PatientListBodyRow
                  key={row.id}
                  disabled={
                    row.currentStatus?.status === 'draft' ||
                    row.currentStatus?.status === 'inactive'
                  }
                >
                  <PatientListBodyCell>
                    <InputCheckWithoutForm
                      type="checkbox"
                      onChange={(e) =>
                        handleCheck(row.id ?? '', e.target.checked)
                      }
                      checked={row.isChecked}
                    />
                  </PatientListBodyCell>

                  <PatientNameTable>
                    <StyledLink
                      disabled={!canViewProfile}
                      to={`/patient/profile?id=${row.id}`}
                    >
                      <Avatar avatarName={row.avatar} />
                      <strong>{row.patientName}</strong>
                    </StyledLink>
                    {row.currentStatus?.status === 'draft' && (
                      <span>Rascunho</span>
                    )}
                    {row.currentStatus?.status === 'inactive' && (
                      <TurnOffIcon />
                    )}
                  </PatientNameTable>

                  <PatientListBodyCell>
                    <p>{row.gender}</p>
                  </PatientListBodyCell>

                  <PatientListBodyCell>
                    <p>{row.uf}</p>
                  </PatientListBodyCell>

                  <PatientListBodyCell>
                    <p>{row.city}</p>
                  </PatientListBodyCell>

                  <PatientListBodyHealthConditionCell>
                    {row.displayableIcd10 ? (
                      <HealthConditionDynamic
                        healthConditionsCount={row.icd10Count}
                        displayableIcd10={row.displayableIcd10}
                      />
                    ) : (
                      '-'
                    )}
                  </PatientListBodyHealthConditionCell>

                  <PatientListBodyCell>
                    {row.riskDegree ? (
                      <RiskTable color={row.riskDegree.color}>
                        <span>
                          <h1>{row.riskDegree.risk}</h1>
                        </span>
                      </RiskTable>
                    ) : (
                      '-'
                    )}
                  </PatientListBodyCell>

                  <PatientListBodyOptionsCell alignItens="center">
                    <ActionLinkTable
                      disabled={!canViewProfile}
                      to={`/patient/profile?id=${row.id}`}
                    >
                      <MdRemoveRedEye size={25} />
                      <span>visualizar</span>
                    </ActionLinkTable>
                  </PatientListBodyOptionsCell>

                  <PatientListBodyOptionsCell alignItens="center">
                    <ActionMenuButtonItemList
                      alignBox="right"
                      list={row.actions}
                    />
                  </PatientListBodyOptionsCell>
                </PatientListBodyRow>
              ))}
            </section>
          </PatientList>
        )}
      </Container>
    </>
  );
};

export default Table;
