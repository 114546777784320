import React, {
  forwardRef,
  lazy,
  Suspense,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useModal } from 'react-brave-modal';
import { toast } from 'react-toastify';
import { useCurrentCallback } from '../../../../lib/use-current-effect';
import FillForm from './screens/FillForm';
import Main from './screens/Main';
import RequestAnother from './screens/RequestAnother';
import SatisfactionSurvey from './screens/SatisfactionSurvey';

const EditCallStatusModal = lazy(
  () =>
    import(
      /* webpackChunkName: "EditCallStatusModal" */ '../modals/EditCallStatusModal'
    ),
);

interface ScreenProps {
  key: string;
  component: JSX.Element;
}

export interface ActionsProps {
  goTo?: (key: string) => void;
}

export interface AttendanceDataAreaRef {
  finish(roomSid?: string): Promise<void>;
}

export interface AttendanceFields {
  anamnese: string;
  cid: string;
  doctorObservation: string;
}

export interface AttendanceFieldsRef {
  getFields: () => AttendanceFields;
}

const AttendanceDataArea: React.ForwardRefRenderFunction<AttendanceDataAreaRef> = (
  _,
  ref,
) => {
  const { showModal } = useModal();
  const [selected, setSelected] = useState(0);
  const [sheetSelected, setSheetSelected] = useState<string>();

  const mainRef = useRef<AttendanceFieldsRef>(null);

  const [fields, setFields] = useState<AttendanceFields>({
    anamnese: '',
    cid: '',
    doctorObservation: '',
  });

  const handleSubmit = useCallback(async () => {
    const getFields = mainRef.current ? mainRef.current.getFields : undefined;
    const data = getFields && getFields();

    showModal({
      type: 'custom',
      data: (
        <Suspense fallback={false}>
          <EditCallStatusModal roomAttendanceData={data} finishAttendance />
        </Suspense>
      ),
    });
  }, [showModal]);

  const screenKeys = [
    'main',
    'fill-form',
    'request-another',
    'satisfaction-survey',
  ];

  const goTo = useCurrentCallback(
    (isCurrent) => (key: string) => {
      const index = screenKeys.findIndex((screenKey) => screenKey === key);

      if (isCurrent()) {
        if (mainRef.current) {
          const newFields = mainRef.current.getFields();
          setFields((state) => ({ ...state, ...newFields }));
        }

        setSelected(index);
      }
    },
    [screenKeys],
  );

  const handleFinish = useCallback(async () => {
    if (mainRef.current) {
      const { anamnese } = mainRef.current.getFields();

      if (anamnese && anamnese.length > 0) {
        await handleSubmit();
      } else {
        goTo('main');
        toast.info('Anamnese é obrigatória');
      }
    }
  }, [goTo, handleSubmit]);

  const screens = useMemo<ScreenProps[]>(
    () => [
      {
        key: screenKeys[0],
        component: (
          <Main
            ref={mainRef}
            fields={fields}
            submit={handleSubmit}
            goTo={goTo}
          />
        ),
      },
      {
        key: screenKeys[1],
        component: (
          <FillForm
            goTo={goTo}
            selectSheet={(id: string) => setSheetSelected(id)}
          />
        ),
      },
      {
        key: screenKeys[2],
        component: <RequestAnother goTo={goTo} />,
      },
      {
        key: screenKeys[3],
        component: (
          <SatisfactionSurvey
            ref={mainRef}
            id={sheetSelected}
            goTo={goTo}
            fields={fields}
          />
        ),
      },
    ],
    [fields, goTo, handleSubmit, screenKeys, sheetSelected],
  );

  useImperativeHandle(
    ref,
    () => ({
      finish: handleFinish,
    }),
    [handleFinish],
  );

  return <>{screens[selected].component}</>;
};

export default forwardRef(AttendanceDataArea);
