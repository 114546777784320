import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container as GridCardContainer } from '../GridCard/styles';

interface LegendItemProps {
  color: string;
}

export const Container = styled(GridCardContainer)`
  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    align-items: center;
  }

  > header {
    margin: 25px auto 0 45px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      margin: 25px auto 0 24px;
    }
  }

  > section {
    display: flex;
    justify-content: center;
    padding: 15px 35px 0;
    height: 100%;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      width: 100%;
      flex-direction: column;
      gap: 2rem;
      padding: 15px 35px;
    }

    > div:first-child {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      > div > div > div > div > div > svg > g > path {
        opacity: 0.6;
      }
    }

    > div:last-child {
      flex: 0.75;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const LegendChart = styled.div`
  position: absolute;
  text-align: center;
  font-family: 'Raleway';
  font-style: normal;

  > h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: ${({ theme }) => theme.colors.gray3};
  }

  > h4 {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: ${({ theme }) => theme.colors.grayAlternative};
  }
`;

export const LegendItem = styled.div<LegendItemProps>`
  display: flex;
  align-items: center;

  div:first-child {
    opacity: 0.6;
    background-color: ${({ color }) => color};
    width: 23px;
    height: 23px;
    border-radius: 50%;
  }

  div:last-child {
    display: flex;
    flex-direction: column;
    color: #7c828a;
    text-align: left;
    margin-left: 7px;
    align-self: baseline;

    > h3 {
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
    }

    > h4 {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      margin-top: 3px;
    }
  }

  & + div {
    margin-top: 20px;
  }
`;

export const LinkButton = styled(Link)`
  width: fit-content;
  margin: 0 35px 15px auto;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grayAlternative};

  > span {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;

    span {
      font-weight: 800;
    }
  }
`;

export const CardTitle = styled.h2`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #4f4f4f;
`;
