import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

import { useAuth } from './auth';
import { useSocketIo } from './socketIo';
import { Notification } from '../entities/Notification';

interface NotificationContextData {
  notifications: Notification[];
  newNotificationsCount: number;
  clearNewNotificationsCount(): void;
  clearLocal(): void;
  addNotifications(newNotifications: Notification[]): void;
}

interface ResponseSocketData {
  notification: Notification;
}

const NotificationContext = createContext<NotificationContextData>(
  {} as NotificationContextData,
);

export const NotificationProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const { socket } = useSocketIo();

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [newNotificationsCount, setNewNotificationsCount] = useState<number>(0);

  const addNotifications = useCallback((newNotifications: Notification[]) => {
    setNotifications(newNotifications);
  }, []);

  const clearLocal = useCallback(() => {
    setNotifications([]);
    setNewNotificationsCount(0);
  }, []);

  const clearNewNotificationsCount = useCallback(() => {
    setNewNotificationsCount(0);
  }, []);

  useEffect(() => {
    if (socket && user) {
      socket.on('notification', (data: ResponseSocketData) => {
        setNotifications((state) => [data.notification, ...state]);
        setNewNotificationsCount((state) => state + 1);
      });

      if (user.notifications && user.notifications.length > 0) {
        setNotifications(user.notifications);
      }
    }

    return () => {
      if (socket) {
        socket.off('notification');
      }
    };
  }, [socket, user]);

  useEffect(() => {
    if (notifications.length > 0) {
      document.title = `(${notifications.length}) Cinco`;
    } else {
      document.title = 'Cinco';
    }
  }, [notifications]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        newNotificationsCount,
        clearNewNotificationsCount,
        addNotifications,
        clearLocal,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextData => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification must be used within a SignatureProvider');
  }

  return context;
};
