export const roles = [
  {
    label: 'Enfermeiro(a)',
    value: 'Enfermeiro(a)',
  },
  {
    label: 'Enfermeiro(a) Chefe',
    value: 'Enfermeiro(a) Chefe',
  },
  {
    label: 'Técnico de Enfermagem',
    value: 'Técnico de Enfermagem',
  },
  {
    label: 'Coordenador de Enfermagem',
    value: 'Coordenador de Enfermagem',
  },
  {
    label: 'Estudante',
    value: 'Estudante',
  },
  {
    label: 'Médico(a)',
    value: 'Médico(a)',
  },
];

export default roles;
