import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template: 250px 250px 175px 175px / repeat(2, 1fr);
  grid-template-areas:
    'na ah'
    'na ah'
    'pc am'
    'pc am';
  grid-gap: 24px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.xl}) {
    grid-template: 450px 250px 250px 175px / 1fr;
    grid-template-areas:
      'na'
      'na'
      'ah'
      'ah'
      'pc'
      'pc'
      'am'
      'am';
    grid-gap: 2rem;
  }
`;

export const CardTitle = styled.h2`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #4f4f4f;
`;
