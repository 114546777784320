import React from 'react';
import {
  MdPlayCircleOutline,
  MdWarning,
  MdHourglassEmpty,
  MdDone,
} from 'react-icons/md';
import { IconBaseProps } from 'react-icons';

import { FiPauseCircle, FiTrash } from 'react-icons/fi';

import { Container, ContainerProps } from './styles';
import AppointmentStatusEnum from '../../../../../../enums/AppointmentStatusEnum';

type ContentAppointmentStatusProps = {
  [key in AppointmentStatusEnum]?: {
    subtitle: string;
    icon: IconBaseProps;
  };
};

const BadgeAppointmentStatus: React.FC<ContainerProps> = ({ type }) => {
  const contentAppointmentStatus: ContentAppointmentStatusProps = {
    confirmed: {
      subtitle: 'Aguardando',
      icon: <MdHourglassEmpty size={20} />,
    },
    started: {
      subtitle: 'Em atendimento',
      icon: <MdPlayCircleOutline size={20} />,
    },
    finished: {
      subtitle: 'Realizado',
      icon: <MdDone size={20} />,
    },
    not_accomplished: {
      subtitle: 'Não realizado',
      icon: <MdWarning size={20} />,
    },
    unfinished: {
      subtitle: 'Não finalizado',
      icon: <FiPauseCircle size={20} />,
    },
    removed: {
      subtitle: 'Removido',
      icon: <FiTrash size={20} />,
    },
  };

  return (
    <Container type={type}>
      {
        contentAppointmentStatus[type as keyof typeof contentAppointmentStatus]
          ?.icon
      }

      <span>
        {
          contentAppointmentStatus[
            type as keyof typeof contentAppointmentStatus
          ]?.subtitle
        }
      </span>
    </Container>
  );
};

export default BadgeAppointmentStatus;
