import { api } from '../api';
import { Patient } from '../../entities/Patient';

export const getPatientsByProfessional = async (
  professionalId: string,
): Promise<Patient[]> => {
  const { data } = await api.get<Patient[]>(
    `/patients/professional/${professionalId}`,
  );

  return data;
};
