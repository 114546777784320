import React, { useLayoutEffect, useMemo, useState, useEffect } from 'react';
import { differenceInMilliseconds } from 'date-fns';

import { Appointment } from '../../../../entities/Appointment';
import { useAuth } from '../../../../hooks/auth';
import { usePatient } from '../../../../hooks/patient';
import { getAppointmentsByPatient } from '../../../../services/appointments';

import { GridCardProps } from '../../../../components/GridCard';
import AfterScheduling from './screens/AfterScheduling';
import Main from './screens/Main';

import { Container } from './styles';
import { useCurrentCallback } from '../../../../lib/use-current-effect';
import AppointmentStatusEnum from '../../../../enums/AppointmentStatusEnum';

interface NextAppointmentsProps extends GridCardProps {
  showAttendanceNow?: boolean;
}

export type NextAppointment = Appointment;

const NextAppointments: React.FC<NextAppointmentsProps> = ({
  gridArea,
  showAttendanceNow = true,
}) => {
  const { patient } = usePatient();
  const { user } = useAuth();
  const [display, setDisplay] = useState<'AfterScheduling' | 'Main'>(
    'AfterScheduling',
  );
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [appointmentIndex, setAppointmentIndex] = useState<number>(1);
  const [appointmentsPage, setAppointmentsPage] = useState<number>(0);
  const [totalAppointments, setTotalAppointments] = useState<number>(0);
  const [loadingAppointments, setLoadingAppointments] =
    useState<boolean>(false);

  const isPatientActive = useMemo(
    () => patient?.user?.currentStatus?.status === 'active',
    [patient],
  );

  const isHealthProfessional = useMemo(
    () => user?.type === 'professional',
    [user],
  );

  const appointmentsData = useMemo<Appointment[]>(() => {
    if (appointments) {
      return appointments
        ?.filter(
          (value) =>
            value.status === AppointmentStatusEnum.CONFIRMED &&
            Date.now() < new Date(value.date ?? Date.now()).getTime(),
        )
        .sort((base, value) =>
          differenceInMilliseconds(
            new Date(base.date ?? ''),
            new Date(value.date ?? ''),
          ),
        );
    }
    return [];
  }, [appointments]);

  const getAppointments = useCurrentCallback(
    (isCurrent) => async (page: number) => {
      if (patient && patient.id) {
        if (isCurrent()) {
          setLoadingAppointments(true);
        }

        const data = await getAppointmentsByPatient({
          patientId: patient.id,
          limit: 10,
          page,
          status: 'confirmed',
        });

        if (isCurrent()) {
          setAppointments([...data.appointments]);
          setTotalAppointments(data.totalAppointments);
          setAppointmentsPage(page);
          setLoadingAppointments(false);
        }
      }
    },
    [patient],
  );

  useEffect(() => {
    if (
      appointmentIndex === appointmentsData.length &&
      appointmentsData.length !== totalAppointments
    ) {
      getAppointments(appointmentsPage + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentIndex, appointmentsData, appointmentsPage, totalAppointments]);

  useEffect(() => {
    if (patient) {
      getAppointments(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  useLayoutEffect(() => {
    setDisplay('AfterScheduling');
  }, [appointmentsData]);

  return (
    <Container
      gridArea={gridArea}
      disabled={!isPatientActive || !isHealthProfessional}
    >
      {display === 'AfterScheduling' && appointmentsData.length > 0 ? (
        <AfterScheduling
          appointment={appointmentsData[appointmentIndex - 1]}
          index={appointmentIndex}
          totalAppointments={totalAppointments}
          loadingAppointments={loadingAppointments}
          change={setDisplay}
          setIndex={setAppointmentIndex}
        />
      ) : (
        <Main
          hasAppointment={appointmentsData.length > 0}
          showAttendanceNow={showAttendanceNow}
          isPatientActive={isPatientActive}
          change={setDisplay}
          buttonClose={appointmentsData.length > 0}
        />
      )}
    </Container>
  );
};

export default NextAppointments;
