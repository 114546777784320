import React, { useCallback } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import Spinner from '../../../../components/Spinner';
import ComorbidityWithDangerNoticesDTO from '../../../../dtos/ComorbidityWithDangerNoticesDTO';
import EmptyCard from '../EmptyCard';
import { CardContainer, CardHeader, CardTitle } from '../styles';
import {
  AllNoticesCardContent,
  LegendChart,
  AllNoticeByComorbidity,
  AllNoticesChart,
} from './styles';

interface AllNoticesCardProps {
  loading: boolean;
  data?: ComorbidityWithDangerNoticesDTO[];
  total?: number;
}

const AllNoticesCard: React.FC<AllNoticesCardProps> = ({
  data = [],
  total = 0,
  loading,
}) => {
  const history = useHistory();

  const showAll = useCallback(() => {
    history.push('/patient/list');
  }, [history]);

  return (
    <CardContainer gridArea="an">
      <CardHeader>
        <CardTitle>Pacientes em alerta</CardTitle>
        {data.length > 0 && (
          <button type="button" onClick={showAll}>
            Ver todos <IoIosArrowForward size={12} />
          </button>
        )}
      </CardHeader>
      {!loading ? (
        <>
          {data.length > 0 ? (
            <AllNoticesCardContent>
              <section>
                <AllNoticesChart
                  chartType="PieChart"
                  loader={<Spinner />}
                  data={[
                    ['Comorbidade', 'Quantidade'],
                    ...data.map((comorbidity) => [
                      comorbidity.comorbidityName,
                      comorbidity.total,
                    ]),
                  ]}
                  options={{
                    pieHole: 0.7,
                    legend: 'none',
                    pieSliceText: 'none',
                    colors: data.map((comorbidity) => comorbidity.color),
                    enableInteractivity: false,
                    chartArea: { width: '100%', height: '100%' },
                  }}
                  rootProps={{ 'data-testid': '2' }}
                />
                <LegendChart>
                  <h2>Total</h2>
                  <h4>{total}</h4>
                </LegendChart>
              </section>
              <section>
                {data.map((comorbidity) => (
                  <AllNoticeByComorbidity
                    color={comorbidity.color}
                    key={comorbidity.comorbidityId}
                  >
                    <div />
                    <h3>{comorbidity.comorbidityName}</h3>
                    <h4>{comorbidity.total}</h4>
                    <h5>
                      {comorbidity.percentage
                        ?.toFixed(2)
                        .replace(/[.,]00$/, '')}
                      %
                    </h5>
                  </AllNoticeByComorbidity>
                ))}
              </section>
            </AllNoticesCardContent>
          ) : (
            <EmptyCard />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </CardContainer>
  );
};

export default AllNoticesCard;
