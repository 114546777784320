import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import useQuery from '../../../hooks/query';
import { usePatient } from '../../../hooks/patient';

import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import DashboardTemplate from '../../../templates/Dashboard';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import PatientPersonalDataCard from './PersonalDataCard';
import PatientHealthConditions from './HealthConditions';
import PatientExamsProcedures from './ExamsProcedures';
import PatientEvolution from './Evolution';
import PatientSheets from './Sheets';
import PatientNextAppointments from './NextAppointments';
import AppointmentHistory from './AppointmentHistory';

import { Container, Grid, ButtonBottom } from './styles';
import MainAreasEnum from '../../../enums/MainAreasEnum';

const Profile: React.FC = () => {
  const query = useQuery();
  const id = useMemo<string | undefined>(() => {
    const resp = query.get('id') ?? undefined;
    return resp === 'undefined' ? undefined : resp;
  }, [query]);
  const history = useHistory();
  const { patient, sync, clearLocal } = usePatient();

  useEffect(() => {
    if (id) {
      sync(id).catch((error) => {
        history.push('/patient/list');

        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
    } else {
      history.push('/patient/list');
    }

    return () => {
      clearLocal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const pages = useMemo<ElementPageTree[]>(
    () => [{ name: 'Pacientes', link: '/patient/list' }, { name: 'Perfil' }],
    [],
  );

  const handleBack = useCallback(() => {
    history.push('/patient/list');
  }, [history]);

  return (
    <DashboardTemplate name={MainAreasEnum.PATIENTS}>
      <PageTree pages={pages} />
      <Container>
        {patient ? (
          <Grid>
            <PatientPersonalDataCard gridArea="pr" />
            <PatientNextAppointments gridArea="pa" />
            <PatientEvolution gridArea="ev" />
            <PatientHealthConditions gridArea="co" />
            <PatientExamsProcedures gridArea="ep" />
            <AppointmentHistory gridArea="ha" />
            <PatientSheets gridArea="fp" />
            <ButtonBottom>
              <Button onClick={handleBack}>Voltar</Button>
            </ButtonBottom>
          </Grid>
        ) : (
          <Spinner />
        )}
      </Container>
    </DashboardTemplate>
  );
};

export default Profile;
