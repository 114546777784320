import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
  gap: 20px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #40ec24;
  gap: 4px;
  color: #ffffff;
  border-radius: 33.3px;
  border: none;
`;

export const ErrorStatus = styled.span`
  background-color: #ea7676;
  color: #7b4141;
  padding: 10px;
  border-radius: 6.89px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
`;
