import styled from 'styled-components';

const ButtonBlue = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border-radius: 50px;
  padding: 10px 20px;
  border: 0;

  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export default ButtonBlue;
