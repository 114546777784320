import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  connect,
  isSupported,
  Room as RoomType,
  Participant as ParticipantType,
} from 'twilio-video';
import { toast } from 'react-toastify';

import { useModal } from 'react-brave-modal';

import Room from './Room';
import Lobby from './Lobby';
import { getToken } from '../../services/videoCall';

import { Container } from './styles';
import ModalBeginningAppointment from './Room/modals/ModalBegininngAppointment';
import { useAppointment } from '../../hooks/appointment';
import ModalFinishedAppointment from './Room/modals/ModalFinishedAppointment';
import { LoadingWithTreatment } from '../LoadingWithTreatment';

interface VideoCall {
  roomName: string;
  identity: string;
  isProfessional?: boolean;
  setInCall?: React.Dispatch<React.SetStateAction<boolean>>;
  onHangUp?: () => any;
}

export interface Room extends RoomType {
  appointmentId?: string;
}

export interface VideoCallRef {
  leaveTheRoom: () => void;
}

const VideoCall: React.ForwardRefRenderFunction<VideoCallRef, VideoCall> = (
  { roomName, identity, isProfessional, setInCall, onHangUp },
  ref,
) => {
  const { showModal } = useModal();

  const [token, setToken] = useState<string>();
  const [room, setRoom] = useState<RoomType>();
  const [participants, setParticipants] = useState<ParticipantType[]>([]);
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [statusError, setStatusError] = useState(false);

  const { updateRoomSid } = useAppointment();

  useEffect(() => {
    if (!isSupported) {
      toast.info('Este navegador não é compatível com chamada de vídeo.');
    }
  }, []);

  useEffect(() => {
    if (setInCall) {
      setInCall(
        room?.localParticipant?.state === 'connected' ||
          participants[0]?.state === 'connected',
      );
    }
  }, [participants, room, setInCall]);

  const handleCleanRoom = useCallback(() => {
    setToken(undefined);

    setRoom(undefined);
    setParticipants([]);
  }, []);

  const handleDisconnect = useCallback(() => {
    if (!isProfessional) {
      showModal({
        data: <ModalFinishedAppointment />,
        type: 'custom',
      });

      setIsComponentVisible(false);
    }
  }, [isProfessional, showModal]);

  const handleJoinTheRoom = useCallback(async () => {
    let resultToken;
    const participantList: ParticipantType[] = [];
    try {
      resultToken = await getToken({ roomName, identity });
      const resultRoom = await connect(resultToken, { name: roomName });

      setToken(resultToken);
      setRoom(resultRoom);

      resultRoom.participants.forEach((participant) => {
        participantList.push(participant);
      });

      if (participantList.length) {
        setParticipants((state) => [...state, ...participantList]);
      }

      resultRoom.on('participantConnected', (participant) => {
        setParticipants((state) => [...state, participant]);
      });

      resultRoom.on('disconnected', handleDisconnect);

      resultRoom.on('participantDisconnected', (participant) => {
        setParticipants((state) => state.filter((p) => p !== participant));
      });

      setIsComponentVisible(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setStatusError(true);
      toast.error(
        'Aconteceu um problema. Entre em contato com o administrador.',
      );
    }
  }, [roomName, identity, handleDisconnect]);

  const handleError = () => {
    handleJoinTheRoom();
    setIsLoading(true);
    setStatusError(false);
  };

  useEffect(() => {
    (async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });

        showModal({
          closeActionSync: handleJoinTheRoom,
          type: 'custom',
          data: <ModalBeginningAppointment onCloseModal={handleJoinTheRoom} />,
        });
      } catch (error) {
        toast.info('Permita acesso à câmera e/ou microfone no navegador.');

        showModal({
          closeActionSync: handleJoinTheRoom,
          type: 'custom',
          data: <ModalBeginningAppointment onCloseModal={handleJoinTheRoom} />,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    room && room?.sid && updateRoomSid(room.sid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  const handleLeaveTheRoom = useCallback(() => {
    if (room) {
      room.removeAllListeners();
      room.disconnect();

      handleCleanRoom();

      if (isProfessional) {
        onHangUp && onHangUp();
      } else {
        showModal({
          closeActionSync: handleJoinTheRoom,
          type: 'custom',
          data: (
            <ModalFinishedAppointment handleJoinTheRoom={handleJoinTheRoom} />
          ),
        });
      }
    }
  }, [
    handleCleanRoom,
    handleJoinTheRoom,
    isProfessional,
    onHangUp,
    room,
    showModal,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      leaveTheRoom: handleLeaveTheRoom,
    }),
    [handleLeaveTheRoom],
  );

  window.addEventListener('beforeunload', () => {
    handleLeaveTheRoom();
  });

  return (
    <>
      <LoadingWithTreatment
        loading={isLoading}
        serverError={statusError}
        tryAgain={handleError}
      />
      {isComponentVisible && (
        <Container>
          {token && room ? (
            <Room
              room={room}
              participants={participants}
              handleLeaveTheRoom={handleLeaveTheRoom}
              isProfessional={isProfessional}
            />
          ) : (
            <Lobby isProfessional={isProfessional} />
          )}
        </Container>
      )}
    </>
  );
};

export default forwardRef(VideoCall);
