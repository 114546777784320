import React, { useState, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { BsPlayFill, BsPauseFill } from 'react-icons/bs';
import { HiVolumeUp, HiVolumeOff, HiDownload } from 'react-icons/hi';

import { getLink } from '../../services/videoCall';

import {
  Container,
  PlayPauseButton,
  Time,
  ProgressBox,
  ProgressBar,
  CommonControlButton,
  FlexColumnContainer,
  Loading,
} from './styles';

import { useCurrentEffect } from '../../lib/use-current-effect';
import Spinner from '../Spinner';

interface AudioPlayerProps {
  fileLink: string;
  fileName: string;
  recordingType: 'voice' | 'video';
}

enum PlayerAction {
  Play,
  Pause,
  Mute,
  Unmute,
  Download,
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  fileLink,
  fileName,
  recordingType,
}) => {
  const [videoLink, setVideoLink] = useState('');
  const [currentTime, setCurrentTime] = useState(0);
  const [recordDuration, setRecordDuration] = useState(0);
  const [currentTimeStr, setCurrentTimeStr] = useState('00:00:00');
  const [recordDurationStr, setRecordDurationStr] = useState('00:00:00');
  const [play, setPlay] = useState(false);
  const [mute, setMute] = useState(false);
  const [areControllersEnabled, setAreControllersEnabled] = useState(false);

  useCurrentEffect(
    (isCurrent) => {
      if (recordingType === 'video') {
        (async () => {
          try {
            const linkResponse = await getLink(fileName);
            if (isCurrent()) {
              setVideoLink(linkResponse);
            }
          } catch (error) {
            toast.error('Erro ao obter gravação da consulta.');
          }
        })();
      }
    },
    [fileName, recordingType],
  );

  const data = useMemo(
    () => ({
      link: recordingType === 'voice' ? fileLink : videoLink,
      name: fileName,
    }),
    [fileLink, fileName, recordingType, videoLink],
  );

  useCurrentEffect((isCurrent) => {
    const player = document.getElementById('player') as HTMLAudioElement;

    if (player) {
      player.addEventListener('durationchange', () => {
        if (isCurrent()) {
          setRecordDuration(player.duration);
          setAreControllersEnabled(true);
          setRecordDurationStr(
            new Date(player.duration * 1000).toISOString().substr(11, 8),
          );
        }
      });
    }
  }, []);

  const handlePlayer = useCallback(
    (action: PlayerAction) => {
      const player = document.getElementById('player') as HTMLAudioElement;

      if (player) {
        switch (action) {
          case PlayerAction.Play:
            player.play();
            player.addEventListener('ended', () => {
              setPlay(false);
            });
            player.addEventListener('timeupdate', () => {
              setCurrentTime(player.currentTime);
              setCurrentTimeStr(
                new Date(player.currentTime * 1000).toISOString().substr(11, 8),
              );
            });
            setPlay(true);
            break;

          case PlayerAction.Pause:
            player.pause();
            setPlay(false);
            break;

          case PlayerAction.Mute:
            player.volume = 0;
            setMute(true);
            break;

          case PlayerAction.Unmute:
            player.volume = 1;
            setMute(false);
            break;

          case PlayerAction.Download:
            saveAs(data.link, data.name);
            break;

          default:
            break;
        }
      }
    },
    [data],
  );

  return (
    <Container>
      {areControllersEnabled ? (
        <>
          <PlayPauseButton
            disabled={!areControllersEnabled}
            isPlay={play}
            type="button"
            onClick={() =>
              areControllersEnabled &&
              handlePlayer(play ? PlayerAction.Pause : PlayerAction.Play)
            }
          >
            {play ? <BsPauseFill /> : <BsPlayFill />}
          </PlayPauseButton>
          <FlexColumnContainer>
            <ProgressBox>
              <ProgressBar percentage={(currentTime / recordDuration) * 100} />
              <Time>
                <span>{currentTimeStr}</span>
                <span>{recordDurationStr}</span>
              </Time>
            </ProgressBox>
          </FlexColumnContainer>
          <CommonControlButton disabled={!areControllersEnabled} type="button">
            {mute ? (
              <HiVolumeOff onClick={() => handlePlayer(PlayerAction.Unmute)} />
            ) : (
              <HiVolumeUp onClick={() => handlePlayer(PlayerAction.Mute)} />
            )}
          </CommonControlButton>
          <CommonControlButton
            type="button"
            disabled={!areControllersEnabled}
            onClick={() =>
              areControllersEnabled && handlePlayer(PlayerAction.Download)
            }
          >
            <HiDownload />
          </CommonControlButton>
        </>
      ) : (
        <Loading>
          <Spinner size={20} transparent />
          <p>Carregando gravação</p>
        </Loading>
      )}
      <audio id="player" src={data.link}>
        <span>Seu navegador não suporta HTML5</span>
        <track kind="captions" />
      </audio>
    </Container>
  );
};

export default AudioPlayer;
