import { api } from '../api';
import { Profile } from '../../entities/Profile';

export const getProfiles = async (
  numberOfUsers?: number,
): Promise<Profile[]> => {
  const { data } = await api.get('/profiles', {
    params: { numberOfUsers },
  });

  return data;
};
