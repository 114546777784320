import styled, { css } from 'styled-components';

interface SidebarProps {
  activated?: boolean;
  isExpanded: boolean;
  isMobile?: boolean;
  disabled?: boolean;
}

export const Container = styled.nav<SidebarProps>`
  z-index: 9998;
  position: ${({ isMobile }) => (isMobile ? 'initial' : 'fixed')};
  top: 60px;
  left: 0;
  width: ${({ isExpanded, isMobile }) => {
    if (isMobile) {
      return '100%';
    }
    if (isExpanded) {
      return '155px';
    }
    return '80px';
  }};
  // Enquanto todas as telas não são responsivas, max-width necessário.
  ${({ isMobile }) =>
    isMobile &&
    css`
      max-width: 320px;
    `}
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ isMobile }) =>
    isMobile ? 'none' : '6px 0px 18px rgba(0, 0, 0, 0.06)'};

  > h3 {
    position: absolute;
    top: 37.5px;
    left: 38px;
    font-weight: 600;
    font-size: 18px;
    color: #707683;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
  }
`;

export const OptionsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
`;

export const Separator = styled.div<SidebarProps>`
  width: ${({ isExpanded }) => (isExpanded ? '72.5%' : '55%')};
  border: 1px solid #e5e5e5;
  margin: 21px 0 39px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: none;
    `}
`;

export const ListButton = styled.li<SidebarProps>`
  width: 100%;

  > button {
    display: flex;
    align-items: center;
    border: 0;
    padding: 6px;
    background: transparent;
    margin: ${({ isExpanded, isMobile }) => {
      if (isMobile) {
        return '0';
      }
      if (isExpanded) {
        return '0 0 0 25px !important';
      }
      return '0 auto';
    }};
    transition: filter 0.2s;

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.2;
        cursor: not-allowed;
      `}

    ${({ activated }) =>
      !activated &&
      css`
        &:hover {
          filter: brightness(0.4);
        }
      `}

    img {
      width: 18px;
      margin-right: ${({ isExpanded, isMobile }) => {
        if (isMobile) {
          return '14px';
        }
        if (isExpanded) {
          return '10px';
        }
        return 'none';
      }};
    }

    span {
      display: none;
      color: ${({ activated, theme }) =>
        activated ? theme.colors.primary : theme.colors.grayAlternative};
      font-weight: 500;
      font-size: ${({ isMobile }) => (isMobile ? '12px' : '10px')};
      margin-top: ${({ isMobile }) => (isMobile ? '1.75px' : '1.5px')};
    }

    ${({ isExpanded }) =>
      isExpanded &&
      css`
        span {
          display: block;
        }
      `}
  }

  & + li {
    margin-bottom: 18px;
  }
`;

export const CollapseButton = styled(ListButton)<SidebarProps>`
  margin: ${({ isMobile }) => (isMobile ? '0 0 54px' : '24px 0 33px')};

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      button {
        img {
          margin: 0 14px 0 4px;
          transform: rotate(180deg);
        }
      }
    `}

  button {
    margin: 0 auto;
    ${({ isMobile }) =>
      isMobile &&
      css`
        margin: 0 0 0 auto;
        padding: 0;
      `};

    img {
      width: 9px;
    }

    span {
      font-weight: 300;
      color: #707683;
    }
  }
`;
