import styled, { css } from 'styled-components';

interface PlayPauseButtonProps {
  isPlay: boolean;
}

interface ProgressBarProps {
  percentage: number;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  transform: translateY(50%) translateY(-20%);

  > p {
    font-weight: 600;
    align-self: center;
  }
`;
export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CommonControlButton = styled.button`
  border: none;
  background: none;
  margin-top: auto;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}

  > svg {
    color: ${({ theme, disabled }) =>
      !disabled ? '#707683' : theme.colors.gray};
    font-size: 26px;
  }
`;

export const PlayPauseButton = styled.button<PlayPauseButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, disabled }) =>
    !disabled ? theme.colors.primary : '#ABD7F1'};
  border: none;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
  transition: background-color 1s;

  ${({ isPlay }) =>
    !isPlay &&
    css`
      svg {
        margin-left: 2px;
      }
    `}
`;

export const Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 600;
`;

export const ProgressBox = styled.div`
  background-color: #efefef;
  border-radius: 6px;
  height: 6px;
  width: 198px;
  margin-top: -3px;
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
  height: 100%;
  width: ${({ percentage }) => `${percentage}%`};
`;
