import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useModal } from 'react-brave-modal';

import { differenceInMinutes } from 'date-fns';
import { GridCardProps } from '../../../../components/GridCard';
import ButtonFilterDate from '../../../../components/ButtonFilterDate';
import Button from '../../../../components/Button';

import BloodOxygenationChart from './Charts/BloodOxygenationChart';
import RespiratoryRateChart from './Charts/RespiratoryRateChart';
import BloodPressureChart from './Charts/BloodPressureChart';
import BodyTemperatureChart from './Charts/BodyTemperatureChart';
import CalorieChart from './Charts/CalorieChart';
import HeartRateChart from './Charts/HeartRateChart';
import SleepChart from './Charts/SleepChart';
import StepChart from './Charts/StepChart';
import EvolutionModal from './Modals/EvolutionModal';

import {
  Container,
  Header,
  NavigationArea,
  Title,
  Types,
  TypeItem,
} from './styles';
import { usePatient } from '../../../../hooks/patient';

type EvolutionProps = GridCardProps;

type ApiMetricType =
  | 'heartBeatRate'
  | 'bloodPressure'
  | 'bodyTemperature'
  | 'bloodOxygen'
  | 'sleep'
  | 'steps'
  | 'calories'
  | 'respiratoryRate';

type ChartComponentType = {
  [key in ApiMetricType]: JSX.Element;
};

export interface ChildrenProps {
  dateFilter: Date;
}

export interface ChildrenRefProps {
  reload: () => Promise<void>;
}

export interface DataChildrenProps {
  lastRecordTime: string;
  value: string;
}

const Evolution: React.FC<EvolutionProps> = ({ gridArea }) => {
  const { showModal } = useModal();
  const chartRef = useRef<ChildrenRefProps>(null);
  // const [measurementLoading, setMeasurementLoading] = useState(false);
  // const { user } = useAuth();
  const [timeToNextMeasurement, setTimeToNextMeasurement] = useState(0);

  const [apiMetric, setApiMetric] = useState<ApiMetricType>('heartBeatRate');
  const [dateFilter, setDateFilter] = useState(new Date());
  const { patient } = usePatient();

  const chartComponent: ChartComponentType = {
    heartBeatRate: <HeartRateChart dateFilter={dateFilter} ref={chartRef} />,
    bloodPressure: (
      <BloodPressureChart dateFilter={dateFilter} ref={chartRef} />
    ),
    bloodOxygen: (
      <BloodOxygenationChart dateFilter={dateFilter} ref={chartRef} />
    ),
    bodyTemperature: (
      <BodyTemperatureChart dateFilter={dateFilter} ref={chartRef} />
    ),
    respiratoryRate: (
      <RespiratoryRateChart dateFilter={dateFilter} ref={chartRef} />
    ),
    calories: <CalorieChart dateFilter={dateFilter} ref={chartRef} />,
    sleep: <SleepChart dateFilter={dateFilter} ref={chartRef} />,
    steps: <StepChart dateFilter={dateFilter} ref={chartRef} />,
  };

  const handleSelection = useCallback((metricType: ApiMetricType) => {
    setApiMetric(metricType);
  }, []);

  const handleShowEvolutionModal = useCallback(() => {
    showModal({
      type: 'custom',
      data: <EvolutionModal />,
    });
  }, [showModal]);

  // const handleNewMeasurement = useCallback(async () => {
  //   setMeasurementLoading(true);

  //   try {
  //     if (!user?.professional_id) {
  //       toast.info(
  //         'Apenas profissionais de saúde podem solicitar novas medições.',
  //       );
  //       setMeasurementLoading(false);

  //       return;
  //     }
  //     if (!patient?.id) {
  //       toast.info('Entre em contato com o administrador.');
  //       setMeasurementLoading(false);

  //       return;
  //     }
  //     const { start } = await runNewMeasurement({
  //       patientId: patient.id,
  //       professionalId: user?.professional_id,
  //     });

  //     const difference = differenceInMinutes(new Date(), new Date(start));

  //     setTimeToNextMeasurement(5 - difference);

  //     showModal({
  //       type: 'simple',
  //       data: <MeasurementResponseModal />,
  //     });
  //   } catch (err) {
  //     if (axios.isAxiosError(err) && err.response?.data) {
  //       toast.error(err.response.data.message);
  //     } else {
  //       toast.error('Entre em contato com o administrador.');
  //     }
  //   }

  //   setMeasurementLoading(false);
  // }, [patient, showModal, user]);

  useEffect(() => {
    const start = patient?.lastManualMeasurement?.start;

    if (start && differenceInMinutes(new Date(), new Date(start)) < 5) {
      const difference = differenceInMinutes(new Date(), new Date(start));
      setTimeToNextMeasurement(5 - difference);
    }
  }, [patient]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (timeToNextMeasurement > 0) {
      timeout = setTimeout(() => {
        setTimeToNextMeasurement((state) => state - 1);
      }, 60000);
    } else {
      chartRef.current?.reload();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeToNextMeasurement]);
  return (
    <Container gridArea={gridArea}>
      <Header>
        <Title>Evolução</Title>
        <div>
          {/* <Button
            type="button"
            buttonType="primary-alternative"
            onClick={handleNewMeasurement}
            loading={measurementLoading}
            disabled={
              timeToNextMeasurement !== 0 ||
              !patient?.registeredAtApp ||
              !user?.professional?.id
            }
          >
            {timeToNextMeasurement !== 0 ? 'Em Andamento' : 'Nova medição'}
            {timeToNextMeasurement !== 0 && <SimpleSpinner color="#C4C4C4" />}
          </Button> */}

          <p>
            {timeToNextMeasurement !== 0 && (
              <>Disponível em {timeToNextMeasurement} min...</>
            )}
          </p>
        </div>
      </Header>
      <NavigationArea>
        <Types>
          <TypeItem
            selected={apiMetric === 'heartBeatRate'}
            onClick={() => handleSelection('heartBeatRate')}
          >
            BPM
          </TypeItem>
          <TypeItem
            selected={apiMetric === 'bloodPressure'}
            onClick={() => handleSelection('bloodPressure')}
          >
            P.A
          </TypeItem>
          <TypeItem
            selected={apiMetric === 'bodyTemperature'}
            onClick={() => handleSelection('bodyTemperature')}
          >
            °C
          </TypeItem>

          <TypeItem
            selected={apiMetric === 'bloodOxygen'}
            onClick={() => handleSelection('bloodOxygen')}
          >
            OX
          </TypeItem>

          <TypeItem
            selected={apiMetric === 'sleep'}
            onClick={() => handleSelection('sleep')}
          >
            Sono
          </TypeItem>
          <TypeItem
            selected={apiMetric === 'steps'}
            onClick={() => handleSelection('steps')}
          >
            Passos
          </TypeItem>
          <TypeItem
            selected={apiMetric === 'calories'}
            onClick={() => handleSelection('calories')}
          >
            Cal
          </TypeItem>
          <TypeItem
            selected={apiMetric === 'respiratoryRate'}
            onClick={() => handleSelection('respiratoryRate')}
          >
            FR
          </TypeItem>
        </Types>
        <ButtonFilterDate
          onChange={({ date }) =>
            setDateFilter(new Date(date.setHours(23, 59, 59, 0)))
          }
          dateValue={{ date: dateFilter }}
        />
      </NavigationArea>

      {chartComponent[apiMetric]}

      <Button
        type="button"
        buttonType="primary-alternative"
        onClick={handleShowEvolutionModal}
      >
        Ver evolução detalhada
      </Button>
    </Container>
  );
};

export default Evolution;
