import styled from 'styled-components';

export const FooterSuccess = styled.footer`
  justify-content: flex-start !important;
`;

export const Button = styled.button`
  border: 1px solid #2d9cdb;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
  background: transparent;
  padding: 15px 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2d9cdb;
  justify-self: flex-start;
`;
