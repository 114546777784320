import { api } from '../api';
import { Appointment } from '../../entities/Appointment';

export const getAppointmentById = async (
  id: string,
): Promise<Appointment | undefined> => {
  const { data } = await api.get(`/appointments/${id}`);

  return data;
};
