import React, { createContext, useContext, useState, useCallback } from 'react';

import { Signature } from '../entities/Signature';
import { UpdateSignatureDTO } from '../dtos/UpdateSignatureDTO';
import { getSignature, updateSignature } from '../services/signature';

interface SignatureContextData {
  signature?: Signature;
  sync(id?: string): Promise<void>;
  update(signatureData: UpdateSignatureDTO): Promise<void>;
  saveLocal(signatureData: Signature): void;
  clearLocal(): void;
}

const SignatureContext = createContext<SignatureContextData>(
  {} as SignatureContextData,
);

export const SignatureProvider: React.FC = ({ children }) => {
  const [signature, setSignature] = useState<Signature>();

  const sync = useCallback(
    async (id?: string): Promise<void> => {
      let data: Signature | undefined;

      if (id) {
        data = await getSignature(id);
      } else if (signature) {
        data = await getSignature(signature.id);
      }
      setSignature(data);
    },
    [signature],
  );

  const update = useCallback(
    async (signatureData: UpdateSignatureDTO): Promise<void> => {
      const data = await updateSignature({ ...signatureData });
      setSignature(data);
    },
    [],
  );

  const saveLocal = useCallback((signatureData: Signature) => {
    setSignature((prevState) =>
      prevState ? { ...prevState, ...signatureData } : { ...signatureData },
    );
  }, []);

  const clearLocal = useCallback(() => {
    setSignature(undefined);
  }, []);

  return (
    <SignatureContext.Provider
      value={{
        signature,
        sync,
        update,
        saveLocal,
        clearLocal,
      }}
    >
      {children}
    </SignatureContext.Provider>
  );
};

export const useSignature = (): SignatureContextData => {
  const context = useContext(SignatureContext);

  if (!context) {
    throw new Error('useSignature must be used within a SignatureProvider');
  }

  return context;
};
