import styled from 'styled-components';

export const Container = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    display: flex;
  }

  z-index: 9997;
  position: fixed;
  width: 100%;
  height: 40px;
  top: 60px;
  left: 0;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10.5px 30px 10.5px 35px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);

  > button {
    border: 0;
    background: transparent;
    display: flex;
  }

  > div {
    display: flex;
    align-items: center;

    span {
      margin-right: 2.5px;
      font-size: 12px;
      color: #707683;
      letter-spacing: 0.01em;
    }
  }
`;

export const SidebarContainer = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    display: block;
  }

  padding: 40px;
  z-index: 9997;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 60px;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;
