import BraceletStatusTypeEnum from '../../enums/BraceletStatusTypeEnum';
import { api } from '../api';

const getBandStatusFromPatient = async (
  patientId: string,
): Promise<BraceletStatusTypeEnum> => {
  const { data } = await api.get<BraceletStatusTypeEnum>(
    `/bracelets/patient?patientId=${patientId}`,
  );

  return data;
};

export default getBandStatusFromPatient;
