import React, { lazy, Suspense, useMemo, useState } from 'react';
import { useModal } from 'react-brave-modal';
import { toast } from 'react-toastify';
import ChangeProfile from './screens/ChangeProfile';
import Success from './screens/Success';
import Cancel from './screens/Cancel';
import { updateUserProfile } from '../../services/user';
import { useCurrentCallback } from '../../lib/use-current-effect';
import { useAuth } from '../../hooks/auth';
import history from '../../services/history';

const SelfProfileUpdateConfirmationModal = lazy(
  () =>
    import(
      /* webpackChunkName: "SelfProfileUpdateConfirmationModal" */ '../SelfProfileUpdateConfirmationModal'
    ),
);

export interface ItemProps {
  id: string;
  name: string;
  newTypeID?: string;
  currentTypeID?: string;
}

interface RemoveProfileUserModalProps {
  items: ItemProps[];
  onSuccess: () => any;
}

const RemoveProfileUserModal: React.FC<RemoveProfileUserModalProps> = ({
  items,
  onSuccess,
}) => {
  const { closeModal } = useModal();
  const [screen, setScreen] = useState('ChangeProfile');
  const [response, setResponse] = useState<ItemProps[]>([...items]);

  const { user, sync } = useAuth();

  const handleSuccess = useCurrentCallback(
    (isCurrent) => async (responseItems: ItemProps[]) => {
      try {
        if (response.some((item) => item.id === user?.id)) {
          setScreen('SelfProfileUpdate');

          return;
        }

        const data = responseItems.map((item) => ({
          userID: item.id,
          typeID: item.newTypeID ?? '',
        }));

        await updateUserProfile(data);

        if (isCurrent()) {
          setScreen('Success');
          onSuccess();
        }
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }
    },
    [onSuccess, response, user],
  );

  const handleSelfUpdateSucess = useCurrentCallback(
    (isCurrent) => async () => {
      const data = response.map((item) => ({
        userID: item.id,
        typeID: item.newTypeID ?? '',
      }));

      await updateUserProfile(data);

      history.push('/profile/list');

      await sync();

      isCurrent() && setScreen('Success');
    },
    [response, sync],
  );

  const Options = useMemo(
    () => ({
      ChangeProfile: {
        component: (
          <ChangeProfile
            items={response}
            onChange={(responseItems: ItemProps[]) =>
              setResponse([...responseItems])
            }
            onSuccess={handleSuccess}
            onCancel={() => setScreen('Cancel')}
          />
        ),
      },
      SelfProfileUpdate: {
        component: (
          <Suspense fallback={false}>
            <SelfProfileUpdateConfirmationModal
              onCancel={() => setScreen('Cancel')}
              onProceed={handleSelfUpdateSucess}
            />
          </Suspense>
        ),
      },
      Success: {
        component: <Success />,
      },
      Cancel: {
        component: (
          <Cancel
            goBack={() => setScreen('ChangeProfile')}
            cancel={() => closeModal()}
          />
        ),
      },
    }),
    [closeModal, handleSelfUpdateSucess, handleSuccess, response],
  );

  return <>{Options[screen as keyof typeof Options].component}</>;
};

export default RemoveProfileUserModal;
