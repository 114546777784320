import React, { useState, useCallback, useRef } from 'react';
import { Device, Connection } from 'twilio-client';
import {
  FaPhoneAlt,
  FaPhoneSlash,
  FaMicrophone,
  FaMicrophoneSlash,
  // FaVolumeUp,
} from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { useCurrentEffect } from '../../lib/use-current-effect';

import { getToken } from '../../services/phoneCall';

import {
  Container,
  PhoneNumber,
  Buttons,
  TurnOnTurnOff,
  Mic,
  CallStatus,
  // Controls,
} from './styles';

interface PhoneCallProps {
  phoneNumber: string | undefined;
  appointmentId: string;
  inCall: boolean;
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  onHangUp: () => any;
}

export type StatusType =
  | 'init'
  | 'ready'
  | 'calling'
  | 'inProgress'
  | 'closed'
  | 'error'
  | 'serverError';

type StatusMessageType = {
  [key in StatusType]: string;
};

const PhoneCall: React.FC<PhoneCallProps> = ({
  phoneNumber,
  appointmentId,
  inCall,
  setInCall,
  onHangUp,
}) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [outgoingConnection, setOutgoingConnection] = useState<
    Connection | undefined
  >();
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [status, setStatus] = useState<StatusType>('init');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [device, setDevice] = useState<Device>();

  const statusMessage: StatusMessageType = {
    init: 'Preparando para realizar a ligação',
    ready: 'Selecione o botão verde para chamar',
    calling: 'Chamando',
    inProgress: 'Ligação em andamento',
    closed: 'Ligação encerrada',
    error: 'Não foi possível realizar a ligação',
    serverError: 'Erro de conexão com o servidor',
  };

  useCurrentEffect((isCurrent) => {
    getToken()
      .then((token) => {
        const newDevice = new Device(token, {
          fakeLocalDTMF: true,
          enableRingingState: true,
        });

        if (isCurrent()) {
          setDevice(newDevice);
          setIsLoading(false);
        }

        newDevice.on('ready', () => {
          setStatus('ready');
        });

        newDevice.on('error', () => {
          setStatus('error');
        });

        newDevice.on('connect', () => {
          setStatus('inProgress');
        });

        newDevice.on('disconnect', () => {
          setInCall(false);
          setStatus('closed');
          onHangUp();
        });
      })
      .catch((error) => {
        setStatus('serverError');
        setInCall(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCurrentEffect(
    (isCurrent) => {
      if (isCurrent()) {
        setIsLoading(true);

        window.setTimeout(() => setIsLoading(false), 4000);
      }
    },
    [inCall],
  );

  const handleServerError = useCallback(() => {
    setIsLoading(true);
    window.setTimeout(() => setIsLoading(false), 4000);
    setStatus('init');
    getToken()
      .then((token) => {
        const newDevice = new Device(token, {
          fakeLocalDTMF: true,
          enableRingingState: true,
        });
        setDevice(newDevice);
        // setIsLoading(false);

        newDevice.on('ready', () => {
          setStatus('ready');
        });

        newDevice.on('error', () => {
          setStatus('error');
        });

        newDevice.on('connect', () => {
          setStatus('inProgress');
        });

        newDevice.on('disconnect', () => {
          setInCall(false);
          setStatus('closed');
          onHangUp();
        });

        newDevice.audio?.speakerDevices.get();
        newDevice.audio?.ringtoneDevices.get();

        const tokenLocal = localStorage.getItem('@Cinco:token');

        setOutgoingConnection(
          newDevice.connect({
            toNumber: spanRef.current?.innerText || '',
            token: tokenLocal || '',
            appointmentId: appointmentId || '',
          }),
        );
        setInCall(true);
        setStatus('calling');
      })
      .catch((error) => {
        setStatus('serverError');
        setInCall(false);
      });
  }, [appointmentId, onHangUp, setInCall]);

  const handleCall = useCallback(() => {
    if (device) {
      if (inCall) {
        device.disconnectAll();
      } else {
        device.audio?.speakerDevices.get();
        device.audio?.ringtoneDevices.get();

        const token = localStorage.getItem('@Cinco:token');

        setOutgoingConnection(
          device.connect({
            toNumber: spanRef.current?.innerText || '',
            token: token || '',
            appointmentId: appointmentId || '',
          }),
        );
        setInCall(true);
        setStatus('calling');
      }
    }
  }, [appointmentId, device, inCall, setInCall]);

  const handleMute = useCallback(() => {
    if (outgoingConnection) {
      outgoingConnection.mute(!isMuted);
      setIsMuted(!isMuted);
    }
  }, [outgoingConnection, isMuted]);

  return (
    <Container>
      <strong>Número:</strong>
      <PhoneNumber>
        <span ref={spanRef}>{phoneNumber}</span>
      </PhoneNumber>
      <Buttons>
        {status === 'serverError' ? (
          <TurnOnTurnOff
            onClick={handleServerError}
            inCall={inCall}
            disabled={isLoading}
          >
            <>
              <FiRefreshCcw />
              <span>Chamar novamente</span>
            </>
          </TurnOnTurnOff>
        ) : (
          <TurnOnTurnOff
            onClick={handleCall}
            inCall={inCall}
            disabled={isLoading}
          >
            {inCall ? (
              <>
                <FaPhoneSlash />
                <span>Desligar</span>
              </>
            ) : (
              <>
                <FaPhoneAlt />
                <span>Chamar</span>
              </>
            )}
          </TurnOnTurnOff>
        )}
        <Mic onClick={handleMute}>
          {isMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
        </Mic>
      </Buttons>
      <CallStatus callStatus={status}>
        <strong>STATUS:</strong>
        <span>{statusMessage[status]}</span>
      </CallStatus>
      {/* <Controls>
        <strong>Configurações de Chamada</strong>
        <div>
          <FaVolumeUp />
          <input type="range" min="1" max="100" />
        </div>
        <div>
          <FaMicrophone />
          <input type="range" min="1" max="100" value="50" />
        </div>
      </Controls> */}
    </Container>
  );
};

export default PhoneCall;
