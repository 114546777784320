import React, { ButtonHTMLAttributes } from 'react';
import SmallSpinner from '../SmallSpinner';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: 'gradient' | 'primary' | 'primary-alternative' | 'secondary';
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  buttonType = 'gradient',
  loading,
  ...rest
}) => {
  return (
    <Container
      isLoading={loading}
      type="button"
      buttonType={buttonType}
      {...rest}
    >
      {loading ? <SmallSpinner /> : children}
    </Container>
  );
};

export default Button;
