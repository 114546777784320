import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  z-index: 1;

  > span {
    color: #707683;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;

    span {
      color: ${({ theme }) => theme.colors.gray};
      margin-left: 10px;
    }
  }
`;
