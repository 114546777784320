import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useModal } from 'react-brave-modal';

import InputDegreeRadioColored from '../../../../../components/InputDegreeRadioColored';
import FinishEditRiskDegreeModal from '../FinishEditRiskDegreeModal';
import { usePatient } from '../../../../../hooks/patient';
import getRiskDegree from '../../../../../utils/getRiskDegree';
import riskDegreeImage from '../../../../../assets/images/risk-degree.svg';

import {
  Container,
  Content,
  Table,
  TableHead,
  Row,
  Head,
  TableBody,
  Data,
  Risk,
  Box,
  Image,
  Buttons,
  Button,
} from './styles';
import { useCurrentCallback } from '../../../../../lib/use-current-effect';
import { getRiskDegrees } from '../../../../../services/patient';
import { FilterPatientRiskDegreesDTO } from '../../../../../dtos/FilterPatientRiskDegreesDTO';
import Spinner from '../../../../../components/Spinner';

interface DataForm {
  riskDegree: string;
}

const EditRiskDegreeModal: React.FC = () => {
  const { patient, addRiskDegree } = usePatient();
  const formRef = useRef<FormHandles>(null);
  const [riskDegreeList, setRiskDegreeList] =
    useState<FilterPatientRiskDegreesDTO[]>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { showModal, closeModal } = useModal();
  const risk = useMemo(() => getRiskDegree.risk, []);
  const riskColor = useMemo(() => getRiskDegree.color, []);

  useEffect(() => {
    if (patient?.id) {
      getRiskDegrees(patient.id, 3).then((list) => {
        setRiskDegreeList(list);
      });
    }
  }, [patient]);

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async (data: DataForm) => {
      setIsButtonDisabled(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          riskDegree: Yup.string().test(
            'validate-riskDegree',
            'Grau de risco não selecionado',
            (value) => value !== '',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await addRiskDegree({
          riskDegree: data.riskDegree,
        });

        if (isCurrent()) {
          setIsButtonDisabled(false);
        }

        closeModal();

        showModal({
          type: 'custom',
          data: <FinishEditRiskDegreeModal />,
        });
      } catch (err) {
        if (isCurrent()) {
          setIsButtonDisabled(false);
        }

        if (err instanceof Yup.ValidationError) {
          toast.info(err.message);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }
    },
    [addRiskDegree, closeModal, showModal],
  );

  const riskDegreeOptions = useMemo(
    () => [
      {
        name: '1',
        value: 'safe',
        color: '#27AE60',
        background: 'rgba(111, 207, 151, 0.4)',
        backgroundSelected: 'rgb(111, 207, 151)',
      },
      {
        name: '2',
        value: 'low',
        color: '#C89A0F',
        background: 'rgba(242, 201, 076, 0.4)',
        backgroundSelected: 'rgb(242, 201, 076)',
      },
      {
        name: '3',
        value: 'medium',
        color: '#C8660E',
        background: 'rgba(250, 136, 034, 0.5)',
        backgroundSelected: 'rgb(250, 136, 034)',
      },
      {
        name: '4',
        value: 'high',
        color: '#E62929',
        background: 'rgba(234, 118, 118, 0.5)',
        backgroundSelected: 'rgb(234, 118, 118)',
      },
    ],
    [],
  );

  return (
    <Container>
      <Content>
        <h1>Grau de risco</h1>
        <p>
          Considerando a saúde geral do paciente, qual o Grau de Risco
          atualmente?
        </p>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputDegreeRadioColored
            name="riskDegree"
            title=""
            optionStyle="circle"
            options={riskDegreeOptions}
            disabledOption={
              patient?.currentRiskDegree
                ? patient.currentRiskDegree.degree
                : undefined
            }
          />
        </Form>
        <h2>Histórico de Edição</h2>

        {riskDegreeList ? (
          <>
            <Table>
              <TableHead>
                <Row>
                  <Head>
                    <span>Grau de risco</span>
                  </Head>
                  <Head>
                    <span>Remetente</span>
                  </Head>
                  <Head>
                    <span>Data/hora</span>
                  </Head>
                </Row>
              </TableHead>
              <TableBody>
                {riskDegreeList.map(({ id, degree, createdAt, createdBy }) => (
                  <Row key={id}>
                    <Data>
                      <Risk riskDegreeColor={riskColor(degree)}>
                        <span>{risk(degree)}</span>
                      </Risk>
                    </Data>
                    <Data>
                      <span>{createdBy}</span>
                    </Data>
                    <Data>
                      <span>
                        {format(new Date(createdAt), 'dd/MM/yyyy - HH')}h
                      </span>
                    </Data>
                  </Row>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <Spinner />
        )}
      </Content>
      <Box>
        <Image>
          <img src={riskDegreeImage} alt="" />
        </Image>
        <Buttons>
          <Button color="white" onClick={() => closeModal()}>
            Cancelar
          </Button>
          <Button
            loading={isButtonDisabled}
            color="blue"
            onClick={() => formRef.current?.submitForm()}
          >
            Salvar
          </Button>
        </Buttons>
      </Box>
    </Container>
  );
};

export default EditRiskDegreeModal;
