import styled from 'styled-components';

interface ButtonProps {
  disabled?: boolean;
}

export const Container = styled.nav`
  z-index: 9998;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background: linear-gradient(
    100.1deg,
    ${({ theme }) => theme.colors.secondary} 11.2%,
    ${({ theme }) => theme.colors.primary} 73.5%
  );

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    > button {
      display: none;
    }

    > button:last-child {
      display: flex;
    }
  }
`;

export const Logo = styled.img`
  height: 40px;
  position: absolute;
  top: 10px;
  left: 20px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    height: 25px;
    top: 17px;
  }
`;

export const DropMenuProfile = styled.ul`
  list-style: none;
  width: 160px;
  background: #ffffff;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  opacity: 0;
  transition: opacity 0.4s;
  visibility: hidden;
  display: table;
  position: absolute;
  top: calc(80%);
  transform: translateX(-5%);
  color: #312e38;
  -webkit-box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);
  -moz-box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);
  box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    display: none;
  }

  &::before {
    content: '';
    border-style: solid;
    border-color: #ffffff transparent;
    border-width: 0px 6px 6px 6px;
    bottom: 100%;
    position: absolute;
    left: 92%;
    transform: translateX(-90%);
  }

  li + li {
    border-top: 1px solid #e0e0e0;
  }
`;

export const MenuProfileButton = styled.div<ButtonProps>`
  display: flex;
  justify-content: flex-start;
  text-align: center;
  text-decoration: none;
  color: #fff;
  width: 100%;
  padding: 10px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  border: 0px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    margin-right: 10px;
  }
`;

export const ProfileWeb = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  border: 0;
  background: transparent;

  > img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  > div {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.01em;
    color: #fff;
    margin-right: 10px;

    > strong {
      font-weight: bold;
    }
  }

  > svg {
    margin-left: 10px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      display: none;
    }
  }

  &:hover ul {
    opacity: 1;
    visibility: visible;
  }
`;
