import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 55px 120px;

  > svg {
    color: ${({ theme }) => theme.colors.secondary};
  }

  h2 {
    font-weight: bold;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.primary};
    padding: 0;
    margin: 45px 0 30px;
  }

  p {
    color: ${({ theme }) => theme.colors.graySecondary};
    font-size: 14px;
    padding: 0;
  }

  > button {
    display: flex;
    align-items: center;

    margin: 20px auto 0 0;
    color: ${({ theme }) => theme.colors.primary};
    border: none;
    background: transparent;

    span {
      margin-left: 10px;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 20px;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-top: 20px;

    button {
      width: 190px;
      padding: 15px 10px;
      font-size: 14px;
    }
  }
`;
