import { api } from '../api';

interface ForgotPassword {
  email: string;
  userId?: string;
}

export const forgotPassword = async (
  userData: ForgotPassword,
): Promise<void> => {
  await api.patch('/users/password/forgot', userData);
};
