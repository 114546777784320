import styled from 'styled-components';

export const Container = styled.div`
  width: 781px;
  height: 471px;

  display: flex;

  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;

  padding: 0 110px;
  height: 100%;
  align-items: center;

  > div {
    display: flex;

    align-items: center;

    > img {
      width: 135px;
      height: 135px;
      margin-right: 70px;
    }

    > div {
      > h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 130%;
        color: ${({ theme }) => theme.colors.graySecondary};
      }

      > p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        padding: 13px 0 13px 0;

        color: ${({ theme }) => theme.colors.graySecondary};
      }

      > p + p {
        padding: 0 0 40px 0;
      }

      > div {
        display: flex;

        > button {
          width: 153px;
          padding: 16px 21px;
        }

        > button + button {
          margin-left: 17px;
        }
      }
    }
  }
`;
