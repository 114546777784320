import { api } from '../api';
import { User } from '../../entities/User';
import { CreateSessionDTO } from '../../dtos/CreateSessionDTO';

type Credentials = {
  user: User;
  token: string;
};

export const createSession = async (
  dataRequest: CreateSessionDTO,
): Promise<Credentials> => {
  const { data } = await api.post('sessions', {
    ...dataRequest,
  });

  return data;
};
