import { PatientMeasurementNotices } from '../../entities/PatientMeasurementNotices';
import { api } from '../api';

export const patientMeasurementNoticeUpdate = async (
  patientId: string,
): Promise<PatientMeasurementNotices> => {
  const { data } = await api.put(`/measurements/notices/${patientId}`);

  return data;
};
