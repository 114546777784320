import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface ColorNumberProps {
  color: 'primary' | 'black';
}
interface ShowBtnProps {
  clicked: boolean;
}

interface ChangeOrderButtonProps {
  isActive: boolean;
  ordenationType: 'ASC' | 'DESC';
}

interface ListItemProps {
  disabled?: boolean;
}

interface ActionLinkTableProps {
  disabled?: boolean;
}

export const TopBar = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  section {
    display: flex;
    flex-direction: row;
    align-items: center;

    :first-child + section {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      padding-right: 40px;

      span {
        margin-right: 20px;
      }
    }
  }
`;

export const ShowBtn = styled.button<ShowBtnProps>`
  font-size: 17px !important;
  color: ${({ theme }) => theme.colors.grayAlternative};
  font-weight: bolder;

  margin: 0px 20px !important;
  padding-bottom: 10px;
  background: none;

  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayAlternative};

  ${({ clicked, theme }) =>
    clicked &&
    css`
      color: ${theme.colors.primary};
      border-bottom: 1px solid ${theme.colors.primary};
    `}
`;

export const ProfileTitleBadge = styled.span`
  background: ${({ theme }) => `${theme.colors.primary}33`};
  justify-self: center;
  border-radius: 5px;
  border: 0;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 10px;
  text-transform: capitalize;
`;

export const OrderSelect = styled.select`
  color: ${({ theme }) => theme.colors.graySecondary};
  border-style: unset;
  background: none;
`;

export const ActionLinkTable = styled(Link)<ActionLinkTableProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  span {
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 5px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const ColorNumber = styled.span<ColorNumberProps>`
  font-weight: bolder;
  ${({ color, theme }) =>
    css`
      color: ${theme.colors[color]};
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-width: fit-content;
  min-height: 360px;
  border-radius: 20px;
  margin-top: 26px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  > h1 {
    font-family: 'Raleway';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const UserList = styled.div`
  overflow: visible;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 400px;
`;

export const UserListRow = styled.div`
  display: grid;
  grid-template-columns:
    minmax(50px, 4%) minmax(270px, 27%) minmax(135px, 13%) minmax(165px, 14%)
    minmax(95px, 8%) minmax(210px, 14%) minmax(130px, 8%) minmax(70px, 6%) minmax(70px, 6%);

  > * {
    padding: 18px 8px;
  }
`;

export const UserListHeaderRow = styled(UserListRow)`
  background-color: #eaecf0;
`;

export const UserListCell = styled.div`
  display: flex;
  align-items: center;
`;

export const UserListCollapsableTextCell = styled(UserListCell)`
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const UserListBodyButtonCell = styled(UserListCell)`
  justify-content: center;
`;

export const UserListBodyRow = styled(UserListRow)<ListItemProps>`
  border-bottom: 1px solid #dddddd;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #fcfcfd;

      > *:not(${UserListBodyButtonCell}) {
        opacity: 0.6;
      }
    `}
`;

export const UserListHeaderCell = styled(UserListCell)`
  color: #707683;
  text-align: left;

  > strong {
    font-weight: 600;
  }
`;

export const UserListCheckboxCell = styled(UserListCell)`
  justify-content: center;
`;

export const PhotoRow = styled.div`
  display: flex;
  align-items: center;

  overflow: hidden;

  > img {
    margin-right: 10px;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  > svg {
    margin-left: 12px;
    width: 13px;
    height: 13px;
  }
`;

export const ChangeOrderButton = styled.button<ChangeOrderButtonProps>`
  background: 0;
  border: 0;
  display: flex;
  margin-left: 10px;

  > svg {
    ${({ ordenationType }) =>
      ordenationType === 'ASC'
        ? css`
            > path {
              stroke: #1a7fb8;
            }
          `
        : css`
            > g > path {
              stroke: #1a7fb8;
            }
          `}

    ${({ isActive }) =>
      !isActive &&
      css`
        > path {
          stroke: #707683;
        }

        > g > path {
          stroke: #707683;
        }
      `}
  }
`;
