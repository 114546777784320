import styled from 'styled-components';

interface IconsProps {
  isDisabled: boolean;
}

interface SpanProps {
  isDisabled: boolean;
}

export const Icons = styled.div<IconsProps>`
  display: flex;
  align-items: center;
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.gray : theme.colors.primary};
`;

export const Span = styled.span<SpanProps>`
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 5px;
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.gray : theme.colors.primary};
`;
