import React, { useCallback } from 'react';
import { useModal } from 'react-brave-modal';

import {
  ButtonsContainer,
  ModalElement,
  ModalGrouperText,
  ModalGrouperImage,
} from './styles';

import GroupVideoExample from '../../../../../assets/images/group-video-example.svg';
import Button from '../../../../Button';

interface ModalBeginningAppointmentProps {
  onCloseModal?: () => void;
}

const ModalBeginningAppointment: React.FC<ModalBeginningAppointmentProps> = ({
  onCloseModal,
}) => {
  const { closeModal } = useModal();

  const handleClose = useCallback(() => {
    closeModal();

    if (onCloseModal) {
      onCloseModal();
    }
  }, [closeModal, onCloseModal]);

  return (
    <ModalElement>
      <ModalGrouperImage>
        <img
          src={GroupVideoExample}
          alt="Ícone de preenchimento de formulário"
        />
      </ModalGrouperImage>
      <ModalGrouperText>
        <div>
          <h1>Seu atendimento está prestes a iniciar</h1>
          <p>
            Certifique-se de estar em lugar tranquilo, com boa conexão e sem
            barulhos para uma melhor experiência durante o seu atendimento.
          </p>
        </div>
        <ButtonsContainer>
          <Button buttonType="primary-alternative" onClick={handleClose}>
            Iniciar
          </Button>
        </ButtonsContainer>
      </ModalGrouperText>
    </ModalElement>
  );
};

export default ModalBeginningAppointment;
