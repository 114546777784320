import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 445px;
  width: 545px;

  div:first-child {
    text-align: center;

    h1 {
      color: ${(props) => props.theme.colors.primary};
      font-size: 24px;
      font-weight: bold;
      margin-top: 35px;
    }
  }

  div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;

    button + button {
      margin-left: 20px;
    }
  }

  div + div {
    margin-top: 35px;
  }
`;
