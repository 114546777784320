import styled, { css } from 'styled-components';

import ButtonStyle from '../../../../components/ButtonBlue';
import datePicker from '../../../../assets/images/date-picker.svg';

interface FieldProps {
  isButton?: boolean;
  gridArea: 'pn' | 'dt' | 'an' | 'bt';
}

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  height: 290px;
  margin-top: 25px;
  padding: 30px 30px 40px;

  h1 {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  h2 {
    color: #707683;
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
  }

  > span {
    background-color: ${({ theme }) => theme.colors.gray6};
    border-radius: 18px;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 18px;
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template: repeat(2, 1fr) / 1fr minmax(0, 230px);
  grid-template-areas:
    'pn dt'
    'an bt';
  grid-column-gap: 38px;
  grid-row-gap: 18px;
  height: 100%;
  width: 100%;
  margin-top: 15px;
`;

export const Field = styled.div<FieldProps>`
  grid-area: ${(props) => props.gridArea};

  > label {
    color: #707683;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  > div {
    margin-top: 5px;
    max-height: 42px;

    > input[type='date']::-webkit-calendar-picker-indicator {
      background: url(${datePicker}) no-repeat;
      background-size: 75%;
      height: 24px;
      width: 24px;
    }
  }

  ${(props) =>
    props.isButton &&
    css`
      align-self: end;
      justify-self: end;
    `}

  > button svg {
    font-size: 18px;
    margin-right: 8px;
  }
`;

export const Button = styled(ButtonStyle)`
  height: 42px;
  width: 145px;
`;
