import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 830px;
  padding: 45px;

  > button {
    width: 170px;
    margin: 30px 0 0 auto;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-weight: 500;
    font-size: 26px;
    color: ${({ theme }) => theme.colors.graySecondary};
    margin-right: 20px;
  }

  button {
    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.primary};
    border: none;
    background: transparent;

    strong {
      font-size: 12px;
      margin-right: 5px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 30px;
  width: 100%;

  h3 {
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.grayAlternative};
  }
`;

const GridList = styled.div`
  display: grid;
  grid-template-columns: auto 150px;
  gap: 15px;
  align-items: center;

  width: 100%;
  padding: 0 25px;
`;

export const ListHeader = styled(GridList)`
  background: ${({ theme }) => theme.colors.gray2};

  height: 45px;
  border-radius: 5px 5px 0 0;
  margin-top: 15px;

  span {
    color: ${({ theme }) => theme.colors.graySecondary};
    font-weight: 600;
    font-size: 13px;
  }
`;

export const ListItem = styled(GridList)`
  height: 65px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

  strong {
    font-weight: 600;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray3};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    color: ${({ theme }) => theme.colors.graySecondary};
    font-weight: 500;
    font-size: 16px;
  }
`;

export const NoEmergencyContact = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
    color: ${({ theme }) => theme.colors.danger};
  }
`;
