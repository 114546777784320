import styled from 'styled-components';

export const Container = styled.div`
  max-width: 781px;
  width: 100%;
  padding: 50px 70px;

  > h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 18px;
    color: #2d9cdb;
  }

  > h1 + h1 {
    margin-top: 12px;
  }

  > footer {
    display: flex;
    margin-top: 50px;

    > button + button {
      margin-left: 16px;
    }
  }
`;
