import styled from 'styled-components';
import Button from '../../../../components/Button';

export const Container = styled.div`
  color: #707683;
  min-height: 500px;

  h1 {
    font-size: 16px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin-top: 30px;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`;

export const DeleteBox = styled.div`
  display: flex;
  margin-left: 25px;

  span {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const FileNameBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 35px;

  span + span {
    font-weight: 700;
    margin-bottom: 2px;
    margin-top: 25px;
  }
`;

export const DeleteButton = styled(Button)`
  /* display: flex;
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  border: 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;

  svg {
    margin-right: 15px;
  } */

  background: transparent;
  color: ${(props) => props.theme.colors.primary};
  border: none;
  box-shadow: none;

  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;

  > svg {
    margin-right: 15px;
  }

  &:hover {
    background: transparent;
    color: ${(props) => props.theme.colors.primary};
    border: none;
  }
`;

export const FetchBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;

  span {
    font-size: 12px;
    font-weight: 500;
  }

  span + span {
    margin-top: 5px;
  }
`;

export const FetchButton = styled.button`
  color: ${(props) => props.theme.colors.primary};
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  width: 230px;
  margin-bottom: 12px;
`;
