import styled from 'styled-components';

import { GridContentDefault } from '../styles';

export const GridForm = styled(GridContentDefault)`
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(2, 1fr);
  grid-template-areas:
    'hd ha'
    'obs obs'
    'obs obs';
`;
