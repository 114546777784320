import styled from 'styled-components';

export const Container = styled.div<{ isVisible: boolean }>`
  position: absolute;

  top: 50px;
  left: 195px;

  width: 545px;
  height: 455px;

  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;

  gap: 80px;

  background-color: white;
  border-radius: 6px;
  filter: drop-shadow(0px 4px 20px rgba(6, 92, 119, 0.08));

  > h1 {
    font-weight: bold;
    font-size: 24px;
    text-align: center;

    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
