import { api } from '../api';
import LastNoticesDTO from '../../dtos/LastNoticesDTO';
import ComorbidityWithDangerNoticesDTO from '../../dtos/ComorbidityWithDangerNoticesDTO';

export interface GetNoticeDashboardDataResponse {
  lastNotices: LastNoticesDTO[];
  allNotices: ComorbidityWithDangerNoticesDTO[];
  allNoticesTotal: number;
  noticesByInstitutionTotal: number;
  noticesByInstitution: {
    institutionId: string;
    institutionName: string;
    allNoticesTotal: number;
    allNoticesPercentage: number;
    allNotices: ComorbidityWithDangerNoticesDTO[];
  }[];
}

export const getNoticeDashboardData =
  async (): Promise<GetNoticeDashboardDataResponse> => {
    const { data } = await api.get('/institutions/notices');

    return data;
  };
