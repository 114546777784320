import styled, { css } from 'styled-components';

interface ContainerProps {
  read: boolean;
}

interface IconAreaProps {
  read: boolean;
}

export const Container = styled.li<ContainerProps>`
  display: flex;
  align-items: center;

  width: 100%;
  border: 0;
  background: transparent;
  opacity: ${({ read }) => (read ? '50%' : '100%')};

  &:hover {
    background: ${({ theme }) => theme.colors.gray4};
  }
`;

export const IconArea = styled.div<IconAreaProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  color: ${({ theme }) => theme.colors.primary};
  height: 40px;
  width: 60px;
  margin-right: 15px;

  ${({ read }) =>
    !read &&
    css`
      :first-child::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 8px;
        height: 8px;
        background: #f2c94c;
        content: '';
        border-radius: 50%;
      }
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.gray1};
  padding: 20px 0;

  strong {
    font-size: 14px;
    font-weight: bold;
    ${({ theme }) => theme.colors.graySecondary};
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    ${({ theme }) => theme.colors.graySecondary};
    margin-bottom: 5px;
    text-align: start;
  }

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
  }
`;
