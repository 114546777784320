import React, { useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { resetPassword } from '../../../../services/user';

import { Container, Field, SaveButton } from './styles';
import { useCurrentCallback } from '../../../../lib/use-current-effect';

interface PasswordRedefinitionProps {
  handleStepChange: (key: string) => void;
  token: string;
}

interface FormProps {
  password: string;
  passwordConfirmation: string;
}

const PasswordRedefinition: React.FC<PasswordRedefinitionProps> = ({
  handleStepChange,
  token,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async (data: FormProps) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          password: Yup.string()
            .matches(
              /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).*$/,
              'Utilize pelo menos uma letra minúscula, letra maiúscula e um número',
            )
            .min(8, 'Mínimo de 8 dígitos'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senha e confirmação não coincidem.',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await resetPassword({
          token,
          ...data,
          firstAccess: false,
        });

        if (isCurrent()) {
          handleStepChange('FinishRedefinition');
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }
    },
    [handleStepChange, token],
  );

  return (
    <Container>
      <h1>Redefinir senha</h1>
      <h2>
        O seu e-mail foi confirmado e você já pode criar uma nova senha de
        acesso.
      </h2>
      <p>
        Utilize no mínimo 8 caracteres, pelo menos uma letra maiúscula, um
        número e um caractere especial.
      </p>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Field>
          <label>Insira sua senha:</label>
          <InputWithoutTitle name="password" type="password" />
        </Field>
        <Field>
          <label>Repira sua senha:</label>
          <InputWithoutTitle name="passwordConfirmation" type="password" />
        </Field>
        <SaveButton type="submit">Salvar</SaveButton>
      </Form>
    </Container>
  );
};

export default PasswordRedefinition;
