import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 55px 120px;

  > svg {
    color: ${({ theme }) => theme.colors.secondary};
  }

  h2 {
    font-weight: bold;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.primary};
    padding: 0;
    margin: 40px 0 30px;
  }

  p {
    color: ${({ theme }) => theme.colors.graySecondary};
    font-size: 14px;
    padding: 0;

    & + p {
      margin-top: 50px;
    }
  }

  div {
    display: flex;
    justify-content: center;

    width: 100%;
    margin-top: 30px;

    button {
      width: 190px;
      padding: 15px 10px;
      font-size: 14px;

      svg {
        margin-right: 10px;
      }

      & + button {
        margin-left: 30px;
      }
    }
  }
`;
