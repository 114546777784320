import axios from 'axios';

const baseApiURL = process.env.REACT_APP_API_METRICS_BASE_URL;

const apiMetrics = axios.create({
  baseURL: baseApiURL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_METRICS_TOKEN ?? '',
  },
});

export { apiMetrics };
