import { api } from '../api';
import { ExamProcedure } from '../../entities/ExamProcedure';

interface GetExamsProceduresByFilter {
  patientId: string;
  orderBy: string;
  ordenationType: string;
  limit?: number;
  page?: number;
}

interface GetExamsProceduresByFilterResponse {
  examsProcedures: ExamProcedure[];
  count: number;
}

export const getExamsProceduresByFilter = async ({
  patientId,
  orderBy,
  ordenationType,
  limit,
  page,
}: GetExamsProceduresByFilter): Promise<GetExamsProceduresByFilterResponse> => {
  const { data } = await api.get<GetExamsProceduresByFilterResponse>(
    `/patients/examProcedure/${patientId}`,
    {
      params: {
        orderBy,
        ordenationType,
        status: 'active',
        limit,
        page,
      },
    },
  );

  return data;
};
