import styled, { css } from 'styled-components';

interface ContentProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  textColor: string;
  textSize: string;
  disabled?: boolean;
}

interface ChangeButtonProps {
  isInvisible: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Content = styled.div<ContentProps>`
  z-index: 0 !important;
  background: #fff;
  border-radius: 6px;
  position: relative;
  padding: 12px 16px;
  width: 100%;
  min-height: 51px;

  border: 1px solid #e0e0e0;
  color: #828282;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #4f4f4f;
      border-color: ${props.theme.colors.primary};
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #4f4f4f;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: #f2efef;
    `}

  input {
    flex: 1;
    border: 0;
    background: transparent;
    color: ${({ textColor }) => textColor};
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 14px;
    font-family: 'Raleway', sans-serif !important;
    text-align: center;
    font-size: ${({ textSize }) => textSize};

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.5;
      `}

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &::placeholder {
      color: #dbdbdb;
    }

    &[type='time'] {
      width: 100%;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const ChangeButton = styled.button<ChangeButtonProps>`
  ${({ isInvisible }) =>
    isInvisible &&
    css`
      visibility: hidden;
    `}
  background: rgba(45, 156, 219, 0.1);
  border-radius: 6px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2d9cdb;
  padding: 5px 10px;
  margin-left: 18px;
  border: 0;
`;
