import styled, { css } from 'styled-components';

interface ListBoxProps {
  show: boolean;
}

interface ContainerProps {
  align?: 'left' | 'center' | 'right';
  disabled?: boolean;
}

const alignVariations = {
  left: css`
    align-items: flex-start;
  `,
  center: css`
    align-items: center;
  `,
  right: css`
    align-items: flex-end;
  `,
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 50px;

  ${(props) => alignVariations[props.align || 'center']}

  button {
    border: 0;
    background: transparent;

    ${(props) =>
      props.disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.4;
      `}
  }
`;

export const ListBox = styled.ul<ListBoxProps>`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(6, 92, 119, 0.15);
  border-radius: 6px;
  padding: 13px 0;
  min-width: 200px;
  list-style: none;
  z-index: 1;
  top: calc(100% + 5px);

  > li {
    width: 100%;
    white-space: nowrap;

    > button {
      font-size: 14px;
      color: #828282;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 0;
      padding: 5px 16px;
      background: transparent;
      width: 100%;

      span {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        line-height: 180%;
        text-align: start;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }

      > div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;

        svg {
          height: 15px;
          width: 15px;
        }
      }

      &:hover {
        background: whitesmoke;
      }
    }
  }
`;
