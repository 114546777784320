import React from 'react';

import BloodDiseases from '../../assets/images/blood-diseases.svg';
import CirculatorySystemDiseasesIcon from '../../assets/images/circulatory-system-diseases-icon.svg';
import CongenitalMalformationIcon from '../../assets/images/congenital-malformation-and-abnormality-icon.svg';
import DigestiveSystemDiseasesIcon from '../../assets/images/digestive-system-diseases-icon.svg';
import EarAndMastoidProcessDiseasesIcon from '../../assets/images/ear-and-mastoid-process-diseases-icon.svg';
import EndocrineDiseasesIcon from '../../assets/images/endocrine-diseases-icon.svg';
import ExternalCausesIcon from '../../assets/images/external-causes-icon.svg';
import EyesDiseasesIcon from '../../assets/images/eyes-diseases-icon.svg';
import GenitourinarySystemDiseasesIcon from '../../assets/images/genitourinary-system-diseases-icon.svg';
import ReasonsForAttendanceIcon from '../../assets/images/reasons-for-attendance-icon.svg';
import InfectiousAndParasiticDiseasesIcon from '../../assets/images/infectious-and-parasitic-disease-icon.svg';
import ExternalCausesOfMobidityAndMortalityIcon from '../../assets/images/external-causes-of-morbidity-and-mortality-icon.svg';
import MentalAndBehavioralDisordersIcon from '../../assets/images/mental-and-behavioral-disorders-icon.svg';
import OsteomuscularAndConnectiveTissueDiseasesIcon from '../../assets/images/osteomuscular-and-connective-tissue-diseases-icon.svg';
import NeoplasmsIcon from '../../assets/images/neoplasms-icon.svg';
import NervousSystemDiseasesIcon from '../../assets/images/nervous-system-diseases-icon.svg';
import PerinatalDisordersIcon from '../../assets/images/perinatal-disorders-icon.svg';
import PregnancyChildbirthAndPuerperiumIcon from '../../assets/images/pregnancy-icon.svg';
import RespiratorySystemDiseasesIcon from '../../assets/images/respiratory-system-diseases-icon.svg';
import SkinDiseasesIcon from '../../assets/images/skin-diseases-icon.svg';
import SpecialPurposeCodesIcon from '../../assets/images/special-purpose-codes-icon.svg';
import SymptomsAndSigns from '../../assets/images/symptoms-and-signs.svg';
import HealthConditionPerChapter from '../../enums/HealthConditionPerChapter';

type HealthConditionPerChapterType =
  typeof HealthConditionPerChapter[keyof typeof HealthConditionPerChapter];

interface Icd10IconProps {
  condition: string;
}

const imageMap: Record<HealthConditionPerChapterType, string> = {
  [HealthConditionPerChapter.BloodAndHematopoieticOrganDiseases]: BloodDiseases,
  [HealthConditionPerChapter.CirculatorySystemDiseases]:
    CirculatorySystemDiseasesIcon,
  [HealthConditionPerChapter.CongenitalMalformationsAndAbnormalities]:
    CongenitalMalformationIcon,
  [HealthConditionPerChapter.DigestiveSystemDiseases]:
    DigestiveSystemDiseasesIcon,
  [HealthConditionPerChapter.EarAndMastoidProcessDiseases]:
    EarAndMastoidProcessDiseasesIcon,
  [HealthConditionPerChapter.EndocrineNutritionalMetabolicDiseases]:
    EndocrineDiseasesIcon,
  [HealthConditionPerChapter.ExternalCauses]:
    ExternalCausesOfMobidityAndMortalityIcon,
  [HealthConditionPerChapter.EyeAndAdnexaDiseases]: EyesDiseasesIcon,
  [HealthConditionPerChapter.GenitourinarySystemDiseases]:
    GenitourinarySystemDiseasesIcon,
  [HealthConditionPerChapter.HealthStatusAndHealthServices]:
    ReasonsForAttendanceIcon,
  [HealthConditionPerChapter.InfectiousAndParasiticDiseases]:
    InfectiousAndParasiticDiseasesIcon,
  [HealthConditionPerChapter.InjuriesAndPoisonings]: ExternalCausesIcon,
  [HealthConditionPerChapter.MentalAndBehavioralDisorders]:
    MentalAndBehavioralDisordersIcon,
  [HealthConditionPerChapter.MusculoskeletalSystemDiseases]:
    OsteomuscularAndConnectiveTissueDiseasesIcon,
  [HealthConditionPerChapter.Neoplasms]: NeoplasmsIcon,
  [HealthConditionPerChapter.NervousSystemDiseases]: NervousSystemDiseasesIcon,
  [HealthConditionPerChapter.PerinatalDisorders]: PerinatalDisordersIcon,
  [HealthConditionPerChapter.PregnancyChildbirthAndPuerperium]:
    PregnancyChildbirthAndPuerperiumIcon,
  [HealthConditionPerChapter.RespiratorySystemDiseases]:
    RespiratorySystemDiseasesIcon,
  [HealthConditionPerChapter.SkinAndSubcutaneousTissueDiseases]:
    SkinDiseasesIcon,
  [HealthConditionPerChapter.SpecialPurposeCodes]: SpecialPurposeCodesIcon,
  [HealthConditionPerChapter.SymptomsAndAbnormalFindings]: SymptomsAndSigns,
};

const Icd10Icon: React.FC<Icd10IconProps> = ({ condition }) => {
  const imagePath = imageMap[condition as HealthConditionPerChapterType];

  return (
    <>
      <img src={imagePath} alt={condition} />
    </>
  );
};

export default Icd10Icon;
