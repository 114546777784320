import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { HiOutlineArrowLeft } from 'react-icons/hi';

import InputWithoutTitle from '../../../components/InputWithoutTitle';
import getValidationErrors from '../../../utils/getValidationErrors';
import { resetPassword } from '../../../services/user';

import { Container, Field, SaveButton } from './styles';
import { useCurrentCallback } from '../../../lib/use-current-effect';
import { createProfessionalMemed } from '../../../services/memed';

interface PasswordCreationProps {
  handleStepChange: (key: string) => void;
}

interface FormProps {
  password: string;
  passwordConfirmation: string;
}

const PasswordCreation: React.FC<PasswordCreationProps> = ({
  handleStepChange,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleBack = useCallback(() => {
    handleStepChange('Identification');
  }, [handleStepChange]);

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async (data: FormProps) => {
      setLoading(true);
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          password: Yup.string()
            .matches(
              /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[\W|_]).*$/,
              'Senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
            )
            .min(8, 'Mínimo de 8 dígitos'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas não coincidem',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // eslint-disable-next-line no-restricted-globals
        const token = location.search.replace('?token=', '');

        await createProfessionalMemed(token);

        await resetPassword({ token, ...data, firstAccess: true });

        setLoading(false);

        if (isCurrent()) {
          handleStepChange('FinishRegistration');
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }

        setLoading(false);
      }
    },
    [handleStepChange],
  );

  return (
    <Container>
      <HiOutlineArrowLeft size={28} onClick={handleBack} />
      <h1>Crie sua senha</h1>
      <h2>
        Tudo certo com o cadastro. Agora é a hora de criar a sua senha de
        acesso.
      </h2>
      <p>
        Utilize no mínimo 8 caracteres, pelo menos uma letra maiúscula, um
        número e um caractere especial.
      </p>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Field>
          <label>Insira sua senha:</label>
          <InputWithoutTitle name="password" type="password" />
        </Field>
        <Field>
          <label>Repira sua senha:</label>
          <InputWithoutTitle name="passwordConfirmation" type="password" />
        </Field>
        <SaveButton loading={loading} type="submit">
          Salvar
        </SaveButton>
      </Form>
    </Container>
  );
};

export default PasswordCreation;
