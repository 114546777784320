import React, { useState } from 'react';
import { useModal } from 'react-brave-modal';
import { toast } from 'react-toastify';

import { useCurrentCallback } from '../../../../../../../lib/use-current-effect';

import { updateUserProfile } from '../../../../../../../services/user';

import Banner from '../../../../../../../assets/images/profile_update_confirmation.svg';

import { Button } from '../../../styles';
import { Container } from '../styles';

interface ConfirmModalProps {
  onSuccess: () => void;
  isUserUpdatingOwnProfile?: boolean;
  items: {
    userID: string;
    typeID: string;
  }[];
}

const Confirm: React.FC<ConfirmModalProps> = ({
  onSuccess,
  isUserUpdatingOwnProfile,
  items,
}) => {
  const { closeModal } = useModal();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleConfirm = useCurrentCallback(
    (isCurrent) => async () => {
      setIsButtonDisabled(true);

      try {
        if (isUserUpdatingOwnProfile) {
          onSuccess();

          return;
        }

        await updateUserProfile(items);
        onSuccess();
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      } finally {
        if (isCurrent()) {
          setIsButtonDisabled(false);
        }
      }
    },
    [isUserUpdatingOwnProfile, items, onSuccess],
  );

  return (
    <Container>
      <section>
        <div>
          <img
            src={Banner}
            alt="Banner da página de confirmação de alteração de um perfil"
          />
        </div>
        <div>
          <h1>
            Os usuários selecionados possuirão apenas as permissões de acesso do
            novo perfil
          </h1>
          {items.length > 1 ? (
            <h2>
              Confirma a mudança de perfil e das permissões para todos os{' '}
              <strong>
                {items.length.toLocaleString('pt-BR', {
                  minimumIntegerDigits: 2,
                })}{' '}
                usuários
              </strong>{' '}
              selecionados?
            </h2>
          ) : (
            <h2>
              Confirma a mudança de perfil e das permissões para o usuário
              selecionado?
            </h2>
          )}
          <footer>
            <Button isWhite onClick={() => closeModal()}>
              Cancelar
            </Button>
            <Button loading={isButtonDisabled} onClick={handleConfirm}>
              Confirmar
            </Button>
          </footer>
        </div>
      </section>
    </Container>
  );
};

export default Confirm;
