import React from 'react';

import { Container, LinkTree, SpaceTree } from './styles';
import { ElementPageTree } from './types';

interface PageTree {
  pages: ElementPageTree[];
}

const PageTree: React.FC<PageTree> = ({ pages }) => {
  return (
    <Container>
      {pages.map((page, index) => (
        <LinkTree to={page.link || '#'} disabled={!page.link} key={page.name}>
          {page.name.toUpperCase()}
          {index !== pages.length - 1 && <SpaceTree />}
        </LinkTree>
      ))}
    </Container>
  );
};

export default PageTree;
