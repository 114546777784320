import styled from 'styled-components';

export interface ContainerProps {
  invisible: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #2d9cdb50;
  padding: 20px 25px;
  border-radius: 4px;
  visibility: ${({ invisible }) => (invisible ? 'hidden' : 'visible')};

  strong {
    color: #1f80b7;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }
`;
