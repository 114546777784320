import styled, { css } from 'styled-components';

interface ContainerProps {
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  .switch {
    position: relative;
    display: inline-block;
    height: 25.5px;
    width: 55px;
  }

  .switch input {
    height: 0;
    width: 0;
    opacity: 0;
  }

  .slider {
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    -webkit-transition: 0.4s;
  }

  .slider:before {
    content: '';
    position: absolute;
    height: 19.5px;
    width: 19.5px;
    left: 3px;
    background-color: white;
    transition: 0.4s;
    -webkit-transition: 0.4s;
  }

  ${(props) =>
    props.disabled &&
    css`
      input:checked + .slider {
        cursor: not-allowed;
        opacity: 0.4;
      }
    `}

  input:checked + .slider {
    background-color: #2196f3;

    ${(props) =>
      props.disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.4;
      `}
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(28px);
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
