import styled, { css } from 'styled-components';

interface InputContainerProps {
  isDragActive: boolean;
  isDragReject: boolean;
  isFilled: boolean;
  isErrored?: boolean;
  isDisabled?: boolean;
}

interface UploadedFileProps {
  isErrored: boolean;
}

export const InputContainer = styled.label<InputContainerProps>`
  display: flex;
  height: 100px;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  outline: none;
  padding: 8px 8px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 2px dashed #8c8a97;
    border-radius: 5px;
    color: ${(props) => props.theme.colors.gray3};
    height: 100%;
    width: 100%;
    padding: 0 60px;

    h3 {
      font-size: 14px;
      font-weight: 600;
      margin-left: 18px;
      text-align: center;
    }
  }

  ${(props) =>
    props.isDragActive &&
    css`
      background-color: #dbe7f2;

      div {
        border-color: ${props.theme.colors.primary};
        color: ${props.theme.colors.primary};
      }
    `}

  ${(props) =>
    props.isDragReject &&
    css`
      background-color: #fdbdd1;

      div {
        border-color: ${props.theme.colors.danger};
        color: ${props.theme.colors.danger};
      }
    `}

    ${(props) =>
    props.isFilled &&
    css`
      display: none;
    `}

  input {
    display: none;
  }

  ${(props) =>
    props.isErrored &&
    css`
      div {
        border-color: ${props.theme.colors.danger};
        color: ${props.theme.colors.danger};
      }
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;

export const UploadedFile = styled.div<UploadedFileProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: 2px solid #f6f6f6;
  border-radius: 4px;
  color: ${(props) =>
    props.isErrored ? props.theme.colors.danger : props.theme.colors.primary};
  height: 100px;
  width: 100%;
  padding: 0 25px;

  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    color: ${(props) =>
      props.isErrored ? props.theme.colors.danger : props.theme.colors.primary};
  }
`;

export const Legend = styled.span`
  font-size: 11px;
  font-weight: 500;
  color: #7a7786;
  margin-top: 10px;
  text-transform: uppercase;
`;
