import styled from 'styled-components';
import { Chart } from 'react-google-charts';
import { colors } from '../../../../styles/theme/colors';

interface AllNoticeByComorbidityProps {
  color: string;
}

export const AllNoticesCardContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  margin: 5vh 20px 5vh 10px;

  > section:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 200px;
    height: 200px;
    position: relative;
    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
      width: 150px;
      height: 150px;
    }
  }

  > section:last-child {
    flex: 1;
    margin-left: 15px;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    flex-wrap: wrap;
    margin: 15px 20px 15px 10px;
  }
`;

export const LegendChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 200px;
  height: 200px;

  > h2 {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: ${colors.graySecondary};
  }

  > h4 {
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    color: ${colors.graySecondary};

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
      font-size: 35px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    width: 150px;
    height: 150px;
  }
`;

export const AllNoticeByComorbidity = styled.div<AllNoticeByComorbidityProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #efefef;
  padding: 15px 5px;
  width: 100%;
  font-size: 14px;
  line-height: 16px;

  > div {
    width: 10px;
    height: 10px;
    background: ${({ color }) => color};
    border-radius: 50%;
  }

  > h3 {
    width: 50%;
    text-align: left;
    font-weight: 600;
    color: ${colors.graySecondary};
  }

  > h4 {
    width: 10%;
    text-align: left;
    font-weight: 500;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: ${colors.graySecondary};
  }
`;

export const AllNoticesChart = styled(Chart)`
  width: 200px;
  height: 200px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    width: 150px;
    height: 150px;
  }
`;
