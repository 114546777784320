import { RemoveHealthConditionRequestDTO } from '../../dtos/RemoveHealthConditionRequestDTO';
import { api } from '../api';

export const removeHealthConditionService = async ({
  icd10PatientId,
}: RemoveHealthConditionRequestDTO): Promise<number> => {
  const { status } = await api.delete('/patients/icd10', {
    data: {
      icd10PatientId,
    },
  });

  return status;
};
