import styled from 'styled-components';

import InputTextArea from '../../../components/InputTextArea';

export const Container = styled.div`
  width: 930px;
  padding: 78px 40px 38px 45px;
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    margin-right: 30px;
  }
`;

export const Content = styled.div`
  h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 26px;
    font-weight: 500;
  }

  form {
    margin-top: 30px;

    > div:nth-child(1) {
      align-items: center;
    }

    > div:nth-child(2) {
      justify-content: flex-end;
      margin-top: 15px;
      padding-right: 20px;

      > div {
        margin-right: 28px;
        height: 0;
        width: 0;
      }

      > div label {
        margin: 0;
        padding: 0;
      }

      > span {
        color: ${(props) => props.theme.colors.primary};
        font-size: 14px;
        font-weight: 700;
        padding-top: 2px;
        &[aria-disabled='true'] {
          padding-right: 215px;
        }
      }
    }

    > div:nth-child(3) {
      align-items: center;
      margin-top: 28px;

      div {
        margin: 0;
        padding: 0;
      }
    }

    > div:nth-child(4) {
      margin-top: 45px;
    }

    > div:nth-child(5) {
      margin-top: 40px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.graySecondary};
  width: 100%;

  > label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 22px;
    text-align: end;
    width: 100px;

    span {
      color: ${(props) => props.theme.colors.danger};
      font-size: 18px;
      line-height: 0.8;

      & :disabled {
        opacity: 0.5;
      }
    }
  }

  .inputSelect {
    z-index: 5;
  }
`;

export const InputBox = styled.div`
  width: 100%;
  max-width: 300px;
`;

export const Input = styled(InputTextArea)`
  height: 45px !important;
  width: 300px !important;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button + button {
    margin-left: 20px;
  }
`;
