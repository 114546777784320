import styled from 'styled-components';

interface ReviewCardProps {
  isBulk: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 45px 45px 0;
`;

export const Grouper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
  }

  > h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 26px;
    font-weight: 500;
    margin-top: 25px;

    span {
      color: ${(props) => props.theme.colors.gray};
      font-size: 14px;
      font-weight: 500;
      margin-left: 20px;
    }
  }

  > p {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    padding: 35px 0 0 0;
  }

  > input {
    margin-top: 25px;
  }

  > form {
    > section {
      display: flex;
      flex-direction: column;
      padding-top: 30px;

      > div {
        margin-bottom: 10px;
        border-radius: 8.25317px;
        border-width: 1.37553px;
        box-sizing: border-box;
        width: 320px;

        > textarea {
          height: 139.75px;
        }

        > input {
          color: ${(props) => props.theme.colors.graySecondary};
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          line-height: 1.1;
        }
      }

      > p {
        color: #707683;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        padding: 0 0 10px 0;
      }
    }
  }
`;

export const ReviewCard = styled.div<ReviewCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 6px;
  height: ${(props) => (props.isBulk ? 200 : 270)}px;
  width: 425px;
  margin-top: 25px;
  padding: 35px 40px;
`;

export const Row = styled.div`
  display: flex;
  color: #707683;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;

  > strong {
    font-weight: 700;
  }

  > svg {
    margin-right: 20px;
  }

  :first-child,
  :last-child {
    svg {
      > path {
        fill: #707683;
      }
    }
  }

  :not(:first-child, :last-child) {
    svg {
      > path {
        stroke: #707683;
      }
    }
  }
`;

export const EditButton = styled.button`
  align-self: center;
  background: #d3eaf8;
  border: none;
  border-radius: 4px;
  color: #1f80b7;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-left: 10px;
  padding: 4px 8px;
  text-align: center;
  text-transform: uppercase;
`;
