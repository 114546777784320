import { AppointmentListDTO } from '../../dtos/AppointmentListDTO';
import { GetAppointmentsRequestDTO } from '../../dtos/GetAppointmentsRequestDTO';
import { api } from '../api';

export const getAppointments = async ({
  page,
  filters,
  ordenationType,
  orderBy,
}: GetAppointmentsRequestDTO): Promise<AppointmentListDTO> => {
  const { data } = await api.get(`/appointments/list/${page}?`, {
    params: {
      filters,
      ordenationType,
      orderBy,
    },
  });
  return data;
};
