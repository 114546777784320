import { api } from '../api';
import { Appointment } from '../../entities/Appointment';
import AppointmentTypeEnum from '../../enums/AppointmentTypeEnum';
import AppointmentAreaEnum from '../../enums/AppointmentAreaEnum';

interface CreateBulkAppointmentProps {
  professionalId: string;
  patientIds: string[];
  type: AppointmentTypeEnum;
  area: AppointmentAreaEnum;
  dates: Date[];
}

export const createBulkAppointment = async (
  data: CreateBulkAppointmentProps,
): Promise<Appointment[]> => {
  const response = await api.post('/appointments/bulk', data);

  return response.data;
};
