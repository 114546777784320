import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 60vw;
  padding: 50px 30px;

  h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 25px;
  }

  button {
    align-self: flex-end;
  }
`;

export const FormBox = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 30px;
  overflow-y: auto;

  .formio-component-button,
  .formio-component-submit {
    display: none;
  }
`;
