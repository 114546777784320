import styled from 'styled-components';

export const ModalElement = styled.div`
  width: 781px;
  height: 471px;
`;

export const ModalGrouper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  height: 100%;
  min-height: 300px;

  img {
    flex: 1;
    width: 280px;
    height: 210px;
  }

  h1 {
    width: 290px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;

    color: ${({ theme }) => theme.colors.primary};

    text-align: start;
  }
`;

export const RightSide = styled.div`
  flex: 0.8;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  padding-top: 30px;

  button {
    width: 152px;
  }
`;
