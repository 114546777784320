import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  > button {
    align-self: flex-end;
    margin-top: 11vh !important;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template: 320px 470px 300px / repeat(7, 1fr);
  gap: 24px;
  height: 100vh;
  font-family: 'Raleway';
  font-weight: bold;

  grid-template-areas:
    'pr pr pr pr pr pa pa'
    'ev ev ev co co ha ha'
    'ep ep ep fp fp ha ha'
    'none none none none none none bt';

  margin-bottom: 31px;
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  padding-top: 16px;
`;

export const ButtonBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-area: bt;
  padding-bottom: 15px;
`;
