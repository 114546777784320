import { apiMetrics } from '../apiMetrics';
import { GraphData } from '../../entities/GraphData';
import { MetricTypeEnum } from '../../enums/MetricTypeEnum';

interface GetLastMeasurementsInfoProps {
  patientId: string;
  metrics: MetricTypeEnum[];
}

export const getLastMeasurementsInfo = async ({
  patientId,
  metrics,
}: GetLastMeasurementsInfoProps): Promise<GraphData[]> => {
  const { data } = await apiMetrics.get(
    `/metrics/lastMeasurements/${patientId}?metrics=${JSON.stringify(metrics)}`,
  );

  return data;
};
