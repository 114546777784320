import styled, { css } from 'styled-components';

interface ButtonProps {
  buttonType: 'gradient' | 'primary' | 'primary-alternative' | 'secondary';
  isLoading?: boolean;
}

export const Container = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 0;
  background: transparent;
  padding: 16px 28px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  transition: background 0.6s;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  min-width: 130px;

  ${({ buttonType }) =>
    buttonType === 'gradient' &&
    css`
      background: linear-gradient(180deg, #30e3e5 0%, #2d9cdb 100%);
      color: #ffffff;

      &:hover {
        background: linear-gradient(180deg, #2d9cdb 0%, #30e3e5 100%);
      }

      &:disabled {
        background: #f5f6f8;
        color: #bdbdbd;
        border: 1px solid #bdbdbd;

        &:hover {
          background: #f5f6f8;
          color: #bdbdbd;
        }
        cursor: default;
        transition: none;
      }
    `}

  ${({ buttonType }) =>
    buttonType === 'primary' &&
    css`
      background: #2d9cdb;
      border: 1px solid #2d9cdb;
      color: #ffffff;

      &:hover {
        background: #2d9cdbcc;
      }

      &:disabled {
        border: 1px solid #bdbdbd;
        background: #bdbdbd;
      }
    `}

  ${({ buttonType }) =>
    buttonType === 'primary-alternative' &&
    css`
      border: 1px solid #2d9cdb;
      color: #2d9cdb;

      &:hover {
        background: whitesmoke;
      }

      &:disabled {
        border: 1px solid #bdbdbd;
        color: #bdbdbd;
      }
    `}

  ${({ buttonType }) =>
    buttonType === 'secondary' &&
    css`
      background: #ffffff;
      color: #828282;

      &:hover {
        background: whitesmoke;
      }

      &:disabled {
        background: whitesmoke;
        border: 1px solid #bdbdbd;
      }
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;
