import { api } from '../api';
import { SheetSubmission } from '../../entities/SheetSubmission';

export interface GetSheetSubmissionsByFilter {
  status?: string;
  patientName?: string;
  professionalName?: string;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
  orderBy?: string;
  ordenationType?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export interface GetSheetSubmissionsByFilterResponse {
  sheetName: string;
  sheetSubmissions: SheetSubmission[];
  totalSheetSubmissions: number;
  totalPages: number;
  totalSheetSubmissionsOnPage: number;
}

export const getSheetSubmissionsByFilter = async (
  sheetId: string,
  sheetSubmissionData: GetSheetSubmissionsByFilter,
): Promise<GetSheetSubmissionsByFilterResponse> => {
  const { data } = await api.get<GetSheetSubmissionsByFilterResponse>(
    `sheets/submissions/${sheetId}`,
    {
      params: { ...sheetSubmissionData },
    },
  );

  const sheetSubmissionsListData = {
    sheetName: data.sheetName,
    sheetSubmissions: data.sheetSubmissions,
    totalSheetSubmissions: data.totalSheetSubmissions,
    totalPages: data.totalPages,
    totalSheetSubmissionsOnPage: data.totalSheetSubmissionsOnPage,
  };

  return sheetSubmissionsListData;
};
