import styled, { css } from 'styled-components';

interface ColorNumberProps {
  color: 'primary' | 'black';
}

interface ChangeOrderButtonProps {
  isActive: boolean;
  ordenationType: 'ASC' | 'DESC';
}

interface PatientListBodyRow {
  disabled?: boolean;
}

interface ActionLinkTableProps {
  disabled?: boolean;
}

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > section {
    display: flex;
    flex-direction: row;

    > div {
      margin: 10px;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 40px;

  > span {
    margin-right: 20px;
  }
`;

export const ActionLinkTable = styled.button<ActionLinkTableProps>`
  display: flex;
  align-items: center;

  border: none;
  background: none;

  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  span {
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 10px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const ColorNumber = styled.span<ColorNumberProps>`
  font-weight: bolder;
  color: ${({ color, theme }) => theme.colors[color]};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 360px;
  border-radius: 20px;
  margin-top: 21px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  > h1 {
    font-family: 'Raleway';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const InstitutionList = styled.div`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 400px;
`;

export const InstitutionListRow = styled.div`
  display: grid;

  padding: 24px 16px;

  grid-template-columns:
    minmax(30px, 5%) minmax(200px, 33%) minmax(80px, 10%) minmax(250px, 9%) minmax(
      130px,
      20%
    )
    minmax(115px, 8%);

  > * {
    padding: 0 10px;
  }
`;

export const InstitutionListHeaderRow = styled(InstitutionListRow)`
  background-color: #eaecf0;

  .container {
    padding-top: 7px;
  }
`;

export const InstitutionListBodyCell = styled.div`
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grayTertiary};

  strong {
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.gray3};
    font-weight: 600;
  }

  > p,
  strong {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const InstitutionListBodyActionsCell = styled(InstitutionListBodyCell)`
  justify-content: center;

  a {
    flex-direction: column;
  }
`;

export const InstitutionListBodyComorbitiesCell = styled.div`
  > div {
    width: 100%;

    > div:first-child {
      min-height: 32px;
      min-width: 32px;
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const InstitutionListBoundPatientCountBodyCell = styled(
  InstitutionListBodyCell,
)`
  justify-content: center;
`;

export const InstitutionListBodyRow = styled(
  InstitutionListRow,
)<PatientListBodyRow>`
  padding: 18px 16px;
  border-bottom: 1px solid #dddddd;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      > :not(${InstitutionListBodyActionsCell}) {
        background-color: ${theme.colors.white};
        opacity: 0.6;
      }
    `}
`;

export const TitleWithButton = styled.div`
  display: flex;
  align-items: center;

  strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.grayTertiary};
    font-size: 13px;
  }
`;

export const ChangeOrderButton = styled.button<ChangeOrderButtonProps>`
  background: 0;
  border: 0;
  display: flex;
  margin-left: 10px;

  > svg {
    width: 20px;

    ${({ ordenationType }) => {
      if (ordenationType === 'ASC') {
        return css`
          > path {
            stroke: #1a7fb8;
          }
        `;
      }

      return css`
        > g > path {
          stroke: #1a7fb8;
        }
      `;
    }}

    ${({ isActive, theme }) =>
      !isActive &&
      css`
        > path,
        > g > path {
          stroke: ${theme.colors.grayTertiary};
        }
      `}
  }
`;

export const CardContentNothingToDisplayBox = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgb(6 92 119 / 5%);
  height: 400px;

  > div {
    font-size: 18px;
  }
`;
