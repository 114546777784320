import styled from 'styled-components';

import Button from '../../../components/Button';
import datePicker from '../../../assets/images/date-picker.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  min-height: 590px;
  min-width: 490px;
  line-height: 18px;
  margin: 55px 0;
  padding: 55px 0;

  h1 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
    max-width: 275px;
  }

  p {
    color: #a6a3a3;
    font-size: 12px;
    margin-top: 10px;
    max-width: 275px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-width: 275px;
  }
`;

export const Field = styled.div`
  font-size: 14px;

  .datepicker {
    color: ${(props) => props.theme.colors.graySecondary};

    &::-webkit-calendar-picker-indicator {
      background: url(${datePicker}) no-repeat;
    }
  }

  & + div {
    margin-top: 20px;
  }

  > div {
    height: 40px;
    margin-top: 5px;
  }

  input {
    font-size: 14px;
  }
`;

export const SendButton = styled(Button)`
  align-self: center;
  font-size: 14px;
  margin-top: 35px !important;
  min-width: 152px;
  text-transform: none;
`;
