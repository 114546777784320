import { formatISO } from 'date-fns';

import { GraphData } from '../../entities/GraphData';
import { MetricTypeEnum } from '../../enums/MetricTypeEnum';
import { apiMetrics } from '../apiMetrics';

interface GetMeasurementsByDayProps {
  patientId: string;
  metricType: MetricTypeEnum;
  dateFilter: Date;
}

export const getMeasurementsByDay = async ({
  patientId,
  metricType,
  dateFilter,
}: GetMeasurementsByDayProps): Promise<GraphData[]> => {
  try {
    const { data } = await apiMetrics.get(
      `metrics/${patientId}/${metricType}/${
        formatISO(dateFilter).split('T')[0]
      }`,
    );
    return data;
  } catch (error) {
    return [];
  }
};
