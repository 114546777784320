/* eslint-disable import/no-duplicates */
import React from 'react';

import { useModal } from 'react-brave-modal';
import { subHours, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { RiFile3Line } from 'react-icons/ri';
import { Container, InfoCard } from './styles';

import Button from '../../components/Button';

import { ReactComponent as ScheduleIcon } from '../../assets/images/schedule.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/icon_schedule_time_gray2.svg';
import { ReactComponent as PersonIcon } from '../../assets/images/icon_schedule_person_gray2.svg';
import { User } from '../../entities/User';
import UserStatus from '../../entities/UserStatus';

export interface DetailedStatusObservationModalProps {
  statusData: {
    professionalName: User['name'];
    observation: UserStatus['observation'];
    reason?: string;
    status?: 'Ativo' | 'Rascunho' | 'Inativo';
    created_at: UserStatus['created_at'];
  };
}

const DetailedStatusObservationModal: React.FC<DetailedStatusObservationModalProps> = ({
  statusData: {
    professionalName: name,
    created_at = '',
    observation,
    status,
    reason,
  },
}) => {
  const { closeModal } = useModal();

  const profileStatusText = status
    ? {
        Ativo: 'ativado',
        Inativo: 'inativado',
        Rascunho: 'ainda em rascunho',
      }[status]
    : undefined;

  const statusDate = subHours(new Date(created_at), 3);

  return (
    <Container>
      <h1>Status do perfil</h1>
      <h3>Perfil {profileStatusText}</h3>
      <InfoCard>
        <div>
          <ScheduleIcon />
          <p>
            <b>Registro: </b>{' '}
            {format(statusDate, "dd' de 'MMMM' de 'yyyy", {
              locale: ptBR,
            })}
          </p>
        </div>
        <div>
          <ClockIcon />
          <p>
            <b>Hora: </b>{' '}
            {format(statusDate, "HH'h 'mm'm'", {
              locale: ptBR,
            })}
          </p>
        </div>
        <div>
          <PersonIcon />
          <p>
            <b>Profissional: </b> {name}
          </p>
        </div>

        {reason && (
          <div>
            <RiFile3Line />
            <p>
              <b>Motivo: </b> {reason}
            </p>
          </div>
        )}
      </InfoCard>
      <span>Observações:</span>
      <span>{observation}</span>
      <Button onClick={() => closeModal()} buttonType="primary-alternative">
        Voltar
      </Button>
    </Container>
  );
};

export default DetailedStatusObservationModal;
