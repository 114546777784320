import styled from 'styled-components';
import { Container as GridCard } from '../../../../components/GridCard/styles';

export const Container = styled(GridCard)`
  align-items: center;
  justify-content: center;

  span,
  label {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.graySecondary};
  }

  span {
    font-size: 45px;
  }

  label {
    font-size: 18px;
  }
`;
