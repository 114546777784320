import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useModal } from 'react-brave-modal';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiTrash } from 'react-icons/fi';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { toast } from 'react-toastify';
import InputSwitchWithoutForm from '../../../../../components/InputSwitchWithoutForm';
import { ReactComponent as PeopleIcon } from '../../../../../assets/images/people.svg';
import {
  Container,
  Content,
  Grouper,
  InputSwitchBox,
  OptionsGrouper,
  Option,
  BulkBox,
  List,
  ButtonsContainer,
  Button,
} from './styles';
import InputSuggestUser, {
  BulkList,
  HandleBulkList,
  InputSuggestRef,
} from '../../../../../components/InputSuggestUser';
import { useCurrentCallback } from '../../../../../lib/use-current-effect';

interface BindPatientsProps {
  handleList: (list: BulkList[]) => void;
}

const BindPatientsModal: React.FC<BindPatientsProps> = ({ handleList }) => {
  const formRef = useRef<FormHandles>(null);
  const inputSuggestRef = useRef<InputSuggestRef>(null);
  const [optionFilter, setOptionFilter] = useState<'name' | 'cpf' | 'email'>(
    'name',
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const { closeModal } = useModal();
  const [isBulk, setIsBulk] = useState(true);
  const [bulkList, setBulkList] = useState<BulkList[]>([]);

  useEffect(() => {
    if (isBulk) {
      inputSuggestRef.current?.clearInput();

      setIsDisabled(!bulkList.length);
    }
  }, [isBulk, bulkList]);

  const handleType = useCurrentCallback(
    (isCurrent) => () => {
      if (isCurrent()) {
        setIsBulk((prevState) => !prevState);
      }
    },
    [],
  );

  const handleBulkList = useCurrentCallback(
    (isCurrent) => ({ newPatient, patientId }: HandleBulkList) => {
      if (isCurrent()) {
        if (newPatient) {
          const alreadyAdded = bulkList.find(
            (item) => item.id === newPatient.id,
          );

          if (isBulk) {
            if (alreadyAdded) {
              toast.info('Paciente já adicionado(a)');
              inputSuggestRef.current?.clearInput();
            } else {
              setBulkList([newPatient, ...bulkList]);
            }
          }
        }

        if (patientId) {
          setBulkList(bulkList.filter((item) => item.id !== patientId));
        }

        if (!newPatient && !patientId) {
          setBulkList([]);
        }
      }
    },
    [isBulk, bulkList, setBulkList],
  );

  const handleSwitch = useCallback(() => {
    if (handleType) {
      handleType();
      setIsDisabled(true);
    }
  }, [handleType]);

  const handleDisable = useCallback(
    (state: boolean) => {
      setIsDisabled(!bulkList.length && state);
    },
    [bulkList],
  );

  const handleFinish = useCallback(() => {
    handleList(bulkList);
    closeModal();
  }, [handleList, bulkList, closeModal]);

  return (
    <Container>
      <Content>
        <Grouper>
          <h1>Filtrar {isBulk ? 'pacientes' : 'paciente'}</h1>
          <p>
            Para {isBulk ? 'quais pacientes' : 'qual paciente'} o vínculo será
            realizado?
          </p>
          <strong>
            Utilize um dos campos abaixo para buscar{' '}
            {isBulk ? 'os pacientes' : 'o paciente'}:
          </strong>
          <InputSwitchBox isBulk={isBulk}>
            <InputSwitchWithoutForm
              defaultValue={isBulk}
              change={handleSwitch}
            />
            <label>Vínculo em massa</label>
            <PeopleIcon />
          </InputSwitchBox>
          <OptionsGrouper>
            <Option
              selected={optionFilter === 'name'}
              onClick={() => setOptionFilter('name')}
              type="button"
            >
              Nome
            </Option>
            <Option
              selected={optionFilter === 'cpf'}
              onClick={() => setOptionFilter('cpf')}
              type="button"
            >
              CPF
            </Option>
            <Option
              selected={optionFilter === 'email'}
              onClick={() => setOptionFilter('email')}
              type="button"
            >
              E-mail
            </Option>
          </OptionsGrouper>
          <Form ref={formRef} onSubmit={() => {}}>
            <InputSuggestUser
              institution
              ref={inputSuggestRef}
              onChangeUser={() => setOptionFilter('name')}
              handleBulkList={handleBulkList}
              type="patient"
              optionFilter={optionFilter}
              setIsDisabled={handleDisable}
            />
          </Form>
        </Grouper>
        <Grouper>
          {isBulk && (
            <BulkBox>
              <div>
                <HiOutlineOfficeBuilding />
                <h1>
                  Vinculado para <strong>{bulkList.length}</strong>{' '}
                  {bulkList.length === 1 ? 'pessoa' : 'pessoas'}
                </h1>
              </div>

              <List>
                <div>
                  <span>
                    {bulkList.length === 1 ? 'Paciente' : 'Pacientes'}
                  </span>
                </div>

                <div>
                  {bulkList.map((patient) => (
                    <div key={patient.id}>
                      <img
                        src={patient.avatar}
                        alt={`Foto de ${patient.name}`}
                      />
                      <span>{patient.name}</span>
                      <button
                        type="button"
                        onClick={() =>
                          handleBulkList &&
                          handleBulkList({ patientId: patient.id })
                        }
                      >
                        <FiTrash />
                      </button>
                    </div>
                  ))}
                </div>
              </List>
            </BulkBox>
          )}
        </Grouper>
      </Content>
      <ButtonsContainer>
        <Button color="white" onClick={() => closeModal()}>
          Cancelar
        </Button>

        <Button color="primary" isDisabled={isDisabled} onClick={handleFinish}>
          Finalizar
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default BindPatientsModal;
