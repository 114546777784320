const getExamType = [
  {
    value: 'image',
    label: 'Imagem',
  },
  {
    value: 'laboratory',
    label: 'Laboratorial',
  },
  {
    value: 'genetic',
    label: 'Genético',
  },
  {
    value: 'others',
    label: 'Outros',
  },
];

export default getExamType;
