import { api } from '../api';

interface ChangePassword {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

export const changePassword = async (
  userData: ChangePassword,
): Promise<void> => {
  await api.patch('/users/password/change', userData);
};
