import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';

import { Container } from './styles';

const ShowDetailedObservationsButton: React.FC<{
  onClick: () => any;
  observationsRef: React.MutableRefObject<{
    [key: string]: HTMLParagraphElement | null;
  }>;
  elementKey: string;
}> = ({ onClick, observationsRef, elementKey }) => {
  const [canShowMagnifyingGlass, setCanShowMagnifyingGlass] = useState(false);

  const verifyButton = () => {
    const { scrollHeight, clientHeight } = observationsRef.current[
      elementKey
    ] as HTMLParagraphElement;

    return scrollHeight > clientHeight;
  };

  const handleWindowSizeChange = () => {
    setCanShowMagnifyingGlass(verifyButton());
  };

  useEffect(() => {
    handleWindowSizeChange();

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {canShowMagnifyingGlass && (
        <button type="button" onClick={onClick}>
          <MdSearch color="#2D9CDB" size={15} />
        </button>
      )}
    </Container>
  );
};

export default ShowDetailedObservationsButton;
