import styled from 'styled-components';

export const Container = styled.div`
  > footer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    margin-top: 50px;

    > *:last-child {
      margin-left: 20px;
    }
  }

  > header {
    display: flex;
    align-items: center;

    > h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 18px;
      color: #4f4f4f;
      margin-right: 10px;
    }
  }

  > p {
    padding: 55px 20px 0 0;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #4f4f4f;
  }

  > div {
    display: flex;
    align-items: center;

    margin-top: 25px;

    > * {
      flex: 1;
    }

    > img {
      width: 210px;
      height: 210px;
    }
  }
`;

export const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 425px;
  height: 270px;
  background: #f8f8f8;
  border-radius: 6px;
  padding: 30px 40px;
`;

export const Row = styled.div`
  display: flex;

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;

  color: #2d9cdb;

  > strong {
    font-weight: 700;
  }

  > svg {
    margin-right: 20px;
  }

  :first-child,
  :last-child {
    svg {
      > path {
        fill: #707683;
      }
    }
  }

  :not(:first-child, :last-child) {
    svg {
      > path {
        stroke: #707683;
      }
    }
  }
`;
