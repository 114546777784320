import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { IoMdSearch } from 'react-icons/io';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { ProfessionalDataProps } from '..';

import { Container, Field, GridContent, InputSelect } from './styles';

import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import getValidationErrors from '../../../../utils/getValidationErrors';
import getUF from '../../../../utils/getUF';
import InputMaskAlternative from '../../../../components/InputMaskAlternative';
import { usePatient } from '../../../../hooks/patient';
import useCep from '../../../../hooks/cep';

export interface ProfessionalDataRef {
  getValues(): ProfessionalDataProps;
  getValidate(): Promise<boolean>;
}

const ProfessionalData: React.ForwardRefRenderFunction<ProfessionalDataRef> = (
  _,
  ref,
) => {
  const { patient } = usePatient();
  const formRef = useRef<FormHandles>(null);
  const { getAddressData, addressData, error } = useCep();

  useEffect(() => {
    if (patient) {
      formRef.current?.setData({
        profession: patient.professionalDetail?.profession,
        company: patient.professionalDetail?.company,
        role: patient.professionalDetail?.role,
        street: patient.professionalDetail?.address?.street,
        number: `${patient.professionalDetail?.address?.number ?? ''}`,
        complement: patient.professionalDetail?.address?.complement,
        neighborhood: patient.professionalDetail?.address?.neighborhood,
        cep: patient.professionalDetail?.address?.cep,
        city: patient.professionalDetail?.address?.city,
        uf: patient.professionalDetail?.address?.uf,
      } as ProfessionalDataProps);
    }
  }, [patient]);

  const getValues = useCallback(() => {
    const professionalData: ProfessionalDataProps = {
      ...formRef.current?.getData(),
    };

    return professionalData;
  }, []);

  const getValidate = useCallback(
    async (professionalData: ProfessionalDataProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          profession: Yup.string(),
          company: Yup.string(),
          role: Yup.string(),
          street: Yup.string(),
          number: Yup.string(),
          complement: Yup.string(),
          neighborhood: Yup.string(),
          cep: Yup.string().test(
            'length',
            'Digite um CEP válido',
            (value) => !!(!value || value.length === 9),
          ),
          city: Yup.string(),
        });

        await schema.validate(professionalData, {
          abortEarly: false,
        });

        return true;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }

        return false;
      }
    },
    [],
  );

  const handleCepSearch = useCallback(async () => {
    await getAddressData(formRef.current?.getFieldValue('cep'));
  }, [getAddressData]);

  useEffect(() => {
    if (addressData) {
      formRef.current?.setFieldValue('street', addressData.street);
      formRef.current?.setFieldValue('complement', addressData.complement);
      formRef.current?.setFieldValue('neighborhood', addressData.neighborhood);
      formRef.current?.setFieldValue('city', addressData.city);
      formRef.current?.setFieldValue('uf', addressData.uf);
    }
  }, [addressData]);

  useEffect(() => {
    if (error) {
      formRef.current?.clearField('street');
      formRef.current?.clearField('complement');
      formRef.current?.clearField('neighborhood');
      formRef.current?.clearField('city');
      formRef.current?.setFieldValue('uf', '');

      toast.error(error);
    }
  }, [error]);

  useImperativeHandle(ref, () => ({
    getValues() {
      return getValues();
    },
    async getValidate() {
      return getValidate(getValues());
    },
  }));

  return (
    <Container>
      <h1>Dados profissionais</h1>
      <Form ref={formRef} onSubmit={() => {}}>
        <GridContent>
          <Field gridArea="pr">
            <label>Profissão</label>
            <InputWithoutTitle name="profession" />
          </Field>
          <Field gridArea="em">
            <label>Empresa</label>
            <InputWithoutTitle name="company" />
          </Field>
          <Field gridArea="ca">
            <label>Cargo</label>
            <InputWithoutTitle name="role" />
          </Field>
          <Field gridArea="ce">
            <label>CEP</label>
            <div className="cep">
              <InputMaskAlternative
                mask="99999-999"
                placeholder="00000-000"
                title=""
                type="text"
                name="cep"
              />
              <button type="button" onClick={handleCepSearch}>
                <IoMdSearch />
              </button>
            </div>
          </Field>
          <Field gridArea="en">
            <label>Endereço da empresa:</label>
            <InputWithoutTitle name="street" />
          </Field>
          <Field gridArea="nu">
            <label>Número</label>
            <InputWithoutTitle name="number" type="number" />
          </Field>
          <Field gridArea="co">
            <label>Complemento</label>
            <InputWithoutTitle name="complement" />
          </Field>
          <Field gridArea="ba">
            <label>Bairro</label>
            <InputWithoutTitle name="neighborhood" />
          </Field>
          <Field gridArea="ci">
            <label>Cidade</label>
            <InputWithoutTitle name="city" />
          </Field>
          <Field gridArea="uf">
            <label>Estado</label>
            <InputSelect name="uf" options={getUF} placeholder="" />
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default forwardRef(ProfessionalData);
