import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';
import './style.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  defaultChecked?: boolean;
  change?: Function;
}

const InputCheckAlternative: React.FC<InputProps> = ({
  name,
  defaultChecked = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error}>
      <label className="container">
        <input
          type="checkbox"
          defaultValue={defaultValue}
          defaultChecked={defaultChecked}
          ref={inputRef}
          {...rest}
        />
        <span className="checkmark" />
      </label>
    </Container>
  );
};

export default InputCheckAlternative;
