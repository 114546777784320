import React from 'react';
import { SubMenuContent } from '../Sidebar';
import { Container, LinkButton, OptionsContainer } from './styles';

interface SubMenuProps {
  isMobile: boolean;
  subMenuContent?: SubMenuContent;
}

const SubMenu: React.FC<SubMenuProps> = ({ isMobile, subMenuContent }) => {
  return subMenuContent?.options ? (
    <Container isMobile={isMobile}>
      <h3>{subMenuContent.label}</h3>
      <OptionsContainer isMobile={isMobile}>
        {subMenuContent.options.map((option) => (
          <LinkButton
            key={option.title}
            to={option.route}
            disabled={!option.permission}
          >
            <li>{option.title}</li>
          </LinkButton>
        ))}
      </OptionsContainer>
    </Container>
  ) : (
    <></>
  );
};

export default SubMenu;
