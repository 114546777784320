import React from 'react';
import { useModal } from 'react-brave-modal';

import FormioComponent from '../../components/FormioComponent';
import ModalButton from '../../components/ModalButton';

import { Container, FormBox } from './styles';

interface SheetViewModalProps {
  formId: string;
  sheetName: string;
}

const SheetViewModal: React.FC<SheetViewModalProps> = ({
  formId,
  sheetName,
}) => {
  const { closeModal } = useModal();

  return (
    <Container>
      <h1>{sheetName}</h1>
      <FormBox>
        <FormioComponent
          url={`${process.env.REACT_APP_FORMIO_URL}/${formId}?header=1&reset=1&theme=lumen`}
          options={{ readOnly: true }}
        />
      </FormBox>
      <ModalButton
        type="button"
        styleType="blue-light"
        onClick={() => closeModal()}
      >
        Fechar
      </ModalButton>
    </Container>
  );
};

export default SheetViewModal;
