import { User } from '../entities/User';
import history from '../services/history';

interface ChangePageAfterLoginRegisterProps {
  user?: User;
}

const changePageAfterLoginRegister = ({
  user,
}: ChangePageAfterLoginRegisterProps): void => {
  if (user && user.id) {
    history.push('/dashboard/profile');
  }
};

export default changePageAfterLoginRegister;
