import React from 'react';

import { CardContentNothingToDisplayProps, Container } from './styles';

const CardContentNothingToDisplay: React.FC<CardContentNothingToDisplayProps> = ({
  children,
  imageWidth,
}) => {
  return <Container imageWidth={imageWidth}>{children}</Container>;
};

export default CardContentNothingToDisplay;
