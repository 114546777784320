import { api } from '../api';
import { User } from '../../entities/User';

export const createUser = async (
  userRequest: User,
  createdUpdatedBy: string,
): Promise<User> => {
  const { data } = await api.post('/users', { userRequest, createdUpdatedBy });

  return data;
};
