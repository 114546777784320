import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { Form } from '@unform/web';

import { FormHandles } from '@unform/core';
import ButtonSecondary from '../../../../../../components/ButtonSecondary';
import InputTextArea from '../../../../../../components/InputTextArea';
import { useAppointment } from '../../../../../../hooks/appointment';
import { ActionsProps, AttendanceFieldsRef, AttendanceFields } from '../..';

import { Subtitle } from '../../../styles';
import { Container } from './styles';
import { useAuth } from '../../../../../../hooks/auth';
import InputWithoutTitle from '../../../../../../components/InputWithoutTitle';
import AppointmentStatusEnum from '../../../../../../enums/AppointmentStatusEnum';
import OccupationAreaEnum from '../../../../../../enums/OccupationAreaEnum';

interface MainProps extends ActionsProps {
  submit: () => Promise<void>;
  fields?: AttendanceFields;
  setFields?: React.Dispatch<React.SetStateAction<AttendanceFields>>;
}

const Main: React.ForwardRefRenderFunction<AttendanceFieldsRef, MainProps> = (
  { goTo, fields },
  ref,
) => {
  const { appointmentSelected } = useAppointment();

  const { user } = useAuth();

  const handleFillForm = useCallback(() => {
    goTo && goTo('fill-form');
  }, [goTo]);

  const formRef = useRef<FormHandles>(null);

  useImperativeHandle(
    ref,
    () => ({
      getFields: () => formRef.current?.getData() as AttendanceFields,
    }),
    [],
  );

  return (
    <Container>
      <Subtitle>Fichas:</Subtitle>
      <ButtonSecondary onClick={handleFillForm}>
        Solicitar preenchimento
      </ButtonSecondary>
      <Form ref={formRef} onSubmit={() => {}}>
        <Subtitle>Anamnese:</Subtitle>
        <InputTextArea
          name="anamnese"
          defaultValue={
            fields?.anamnese ?? appointmentSelected?.details?.anamnese
          }
          disabled={
            appointmentSelected?.status === AppointmentStatusEnum.FINISHED
          }
        />

        {user?.professional?.occupationArea === OccupationAreaEnum.MEDICINE && (
          <>
            <Subtitle>CID:</Subtitle>
            <InputWithoutTitle
              type="number"
              name="cid"
              defaultValue={fields?.cid ?? appointmentSelected?.details?.cid}
            />
            <Subtitle>Observações do médico (privadas):</Subtitle>
            <InputTextArea
              name="doctorObservation"
              defaultValue={
                fields?.doctorObservation ??
                appointmentSelected?.details?.doctor_observation
              }
            />
          </>
        )}
      </Form>
    </Container>
  );
};

export default forwardRef(Main);
