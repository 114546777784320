import UserStatusReasonEnum from '../enums/UserStatusReasonEnum';

export const getObservationReason = (reason?: UserStatusReasonEnum) =>
  reason
    ? {
        [UserStatusReasonEnum.DEATH]: 'Óbito',
        [UserStatusReasonEnum.EXPIRED_BY_SYSTEM]: 'Expirado pelo sistema',
        [UserStatusReasonEnum.INCOMPLETE_REGISTRATION]: 'Cadastro incompleto',
        [UserStatusReasonEnum.OTHER]: 'Outros',
        [UserStatusReasonEnum.WITHDRAWAL]: 'Desistência',
      }[reason]
    : undefined;
