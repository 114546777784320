import { OccupationArea } from '../entities/Professional';

export interface RoleOptions {
  label: string;
  value: string;
}

const doctorRoles: RoleOptions[] = [
  {
    label: 'Médico(a)',
    value: 'Médico(a)',
  },
];

const nurseRoles: RoleOptions[] = [
  {
    label: 'Enfermeiro(a)',
    value: 'Enfermeiro(a)',
  },
  {
    label: 'Enfermeiro(a) Chefe',
    value: 'Enfermeiro(a) Chefe',
  },
  {
    label: 'Técnico de Enfermagem',
    value: 'Técnico de Enfermagem',
  },
  {
    label: 'Coordenador de Enfermagem',
    value: 'Coordenador de Enfermagem',
  },
  {
    label: 'Estudante',
    value: 'Estudante',
  },
];

export const getRoleByOccupationArea = (
  occupationArea?: OccupationArea,
): RoleOptions[] => {
  let roleOptions: RoleOptions[];

  switch (occupationArea) {
    case 'Medicina':
      roleOptions = doctorRoles;
      break;
    case 'Enfermagem':
      roleOptions = nurseRoles;
      break;
    default:
      roleOptions = [];
      break;
  }

  return roleOptions;
};
