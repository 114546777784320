import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

import { ModalProvider } from 'react-brave-modal';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './auth';
import { PatientProvider } from './patient';
import { UserProvider } from './user';
import { SignatureProvider } from './signature';
import { AppointmentProvider } from './appointment';
import { PermissionProvider } from './permission';
import { PushNotificationProvider } from './pushNotification';
import { InstitutionProvider } from './institution';
import { NotificationProvider } from './notification';
import { SocketIoProvider } from './socketIo';
import { MemedProvider } from './memed';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <UserProvider>
      <SocketIoProvider>
        <NotificationProvider>
          <PushNotificationProvider>
            <PermissionProvider>
              <PatientProvider>
                <SignatureProvider>
                  <AppointmentProvider>
                    <MemedProvider>
                      <InstitutionProvider>
                        <ModalProvider>{children}</ModalProvider>
                      </InstitutionProvider>
                    </MemedProvider>
                  </AppointmentProvider>
                </SignatureProvider>
              </PatientProvider>
            </PermissionProvider>
          </PushNotificationProvider>
        </NotificationProvider>
      </SocketIoProvider>
    </UserProvider>
    <ToastContainer autoClose={3000} />
  </AuthProvider>
);

export default AppProvider;
