import { communicationService } from '../api';

interface GetTokenResponse {
  token: string;
}

export const getToken = async () => {
  const { data } = await communicationService.get<GetTokenResponse>(
    '/voiceToken',
  );
  return data.token;
};
