import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import heartCareIcon from '../../../../assets/images/heart-care.svg';
import coughCold from '../../../../assets/images/cough-cold.svg';

import { Cards, Container, OptionCard } from './styles';
import {
  ComponentsUserRegisterEditProps,
  ComponentsUserRegisterEditRef,
} from '..';
import { useUser } from '../../../../hooks/user';

const ProfileType: React.ForwardRefRenderFunction<
  ComponentsUserRegisterEditRef,
  ComponentsUserRegisterEditProps
> = ({ onSaveSucceed }, ref) => {
  const { user, saveLocal, save, cleanLocal } = useUser();

  const options = useMemo(
    () => [
      {
        icon: heartCareIcon,
        alt: 'Ícone de cuidado com a saúde',
        description: 'Profissional de saúde',
        key: 'professional',
      },
      {
        icon: coughCold,
        alt: 'Ícone de resfriado',
        description: 'Outro usuário',
        key: 'generic',
      },
    ],
    [],
  );

  const [selected, setSelected] = useState<
    'generic' | 'patient' | 'professional'
  >();

  useEffect(() => {
    if (selected !== user?.type) {
      setSelected(user?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getValidate = useCallback(() => {
    return !!selected;
  }, [selected]);

  const handleNext = useCallback(async () => {
    if (selected === 'professional' || selected === 'generic') {
      saveLocal({ type: selected });
      return true;
    }

    return false;
  }, [saveLocal, selected]);

  const handleSave = useCallback(async () => {
    if (selected === 'professional' || selected === 'generic') {
      const didSave = await save({ type: selected });

      didSave && onSaveSucceed && onSaveSucceed();

      return didSave;
    }

    return false;
  }, [onSaveSucceed, save, selected]);

  const handleCancel = useCallback(async () => {
    cleanLocal();
    return true;
  }, [cleanLocal]);

  useImperativeHandle(ref, () => ({
    back: async () => false,
    cancel: handleCancel,
    next: handleNext,
    save: handleSave,
    canSave: async () => getValidate(),
  }));

  return (
    <Container>
      <h1>Qual o perfil do usuário?</h1>
      <Cards>
        {options.map(({ alt, description, icon, key }) => (
          <OptionCard
            key={key}
            onClick={() =>
              setSelected(key as 'generic' | 'patient' | 'professional')
            }
            isSelected={key === selected}
          >
            <img src={icon} alt={alt} />
            <span>{description}</span>
          </OptionCard>
        ))}
      </Cards>
    </Container>
  );
};

export default forwardRef(ProfileType);
