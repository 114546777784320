import React, { useState } from 'react';
import { Container, ButtonsContainer } from './styles';
import Button from '../../../components/Button';
import { useCurrentCallback } from '../../../lib/use-current-effect';

interface ConfirmationPanelProps {
  onSubmit: () => Promise<unknown>;
  onCancel: () => unknown;
  isVisible: boolean;
}

const ConfirmationPanel: React.FC<ConfirmationPanelProps> = ({
  onSubmit,
  onCancel,
  isVisible,
}) => {
  const [buttonConfirmLoadingStatus, setButtonConfirmLoadingStatus] =
    useState(false);

  const handleSubmit = useCurrentCallback(
    (isCurrent) => () => {
      setButtonConfirmLoadingStatus(true);

      onSubmit().finally(() => {
        if (isCurrent()) {
          setButtonConfirmLoadingStatus(false);
        }
      });
    },
    [onSubmit],
  );

  return isVisible ? (
    <Container isVisible={isVisible}>
      <h1>Tem certeza que quer excluir esse agendamento?</h1>
      <ButtonsContainer>
        <Button
          loading={buttonConfirmLoadingStatus}
          buttonType="primary"
          onClick={handleSubmit}
        >
          Sim, confirmar
        </Button>
        <Button
          disabled={buttonConfirmLoadingStatus}
          buttonType="secondary"
          onClick={() => onCancel()}
        >
          Não, cancelar
        </Button>
      </ButtonsContainer>
    </Container>
  ) : (
    <></>
  );
};

export default ConfirmationPanel;
