import React, { createContext, useCallback, useContext, useMemo } from 'react';
import PermissionType from '../config/types/PermissionType';
import { useAuth } from './auth';

interface PermissionContextData {
  hasPermission(key: PermissionType): boolean;
}

const PermissionContext = createContext<PermissionContextData>(
  {} as PermissionContextData,
);

export const PermissionProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  const permissionsUser = useMemo(
    () => user?.profile?.permission_profiles ?? [],
    [user],
  );

  const permissions = useMemo(
    () =>
      permissionsUser
        .filter(({ is_active }) => is_active)
        .map(({ permission }) => permission?.key) ?? [],
    [permissionsUser],
  );

  const hasPermission = useCallback(
    (key: PermissionType) => {
      return permissions.some((item) => item === key);
    },
    [permissions],
  );

  return (
    <PermissionContext.Provider value={{ hasPermission }}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermission = (): PermissionContextData => {
  const context = useContext(PermissionContext);

  if (!context) {
    throw new Error('usePermission must be used within a PermissionProvider');
  }

  return context;
};
