import React, { forwardRef, useImperativeHandle } from 'react';

import { useUser } from '../../../../hooks/user';

import PersonalData from './PersonalData';
import ProfessionalNextAppointments from '../../../../components/ProfessionalNextAppointments';
import ProfessionalAppointmentHistory from '../../../../components/ProfessionalAppointmentHistory';

import { Container } from './styles';
import { ComponentsProfileRef } from '..';

const GeneralProfile: React.ForwardRefRenderFunction<ComponentsProfileRef> = (
  _,
  ref,
) => {
  const { user } = useUser();

  useImperativeHandle(ref, () => ({
    save: async () => true,
    back: async () => true,
  }));

  return (
    <Container>
      <PersonalData user={user} />
      <ProfessionalNextAppointments user={user} />
      <ProfessionalAppointmentHistory user={user} />
    </Container>
  );
};

export default forwardRef(GeneralProfile);
