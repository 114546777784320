import React, { useState, useEffect } from 'react';
import { createLocalVideoTrack, LocalVideoTrack } from 'twilio-video';
import { useCurrentEffect } from '../../../lib/use-current-effect';

import { AppointmentStatus } from '../Room/styles';

import { Container } from './styles';

interface LobbyProps {
  isProfessional?: boolean;
}

const Lobby: React.FC<LobbyProps> = ({ isProfessional }) => {
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();

  useCurrentEffect((isCurrent) => {
    (async () => {
      const localVideoTrack = await createLocalVideoTrack();
      const lobbyElement = document.getElementById('Lobby');
      if (isCurrent()) {
        setVideoTrack(localVideoTrack);

        if (localVideoTrack && lobbyElement) {
          lobbyElement.appendChild(localVideoTrack.attach());
        }
      }
    })();
  }, []);

  useEffect(
    () => () => {
      videoTrack?.stop();
    },
    [videoTrack],
  );

  return (
    <Container isProfessional={isProfessional} id="Lobby">
      <AppointmentStatus status="finished">
        Atendimento finalizado
      </AppointmentStatus>
    </Container>
  );
};

export default Lobby;
