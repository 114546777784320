import React, { useRef, useState, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { IoMdSearch } from 'react-icons/io';

import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import { getAllHealthConditionFromPatients } from '../../../../services/patient/GetAllHealthConditionFromPatients';

import { GetSheetsByFilter as ListFilter } from '../../../../services/sheet';

import { Container, GridContent, Field, Button } from './styles';
import ButtonFilterRangeDate, {
  DateValueProps,
} from '../../../../components/ButtonFilterRangeDate';
import InputAsyncSelect from '../../../../components/InputAsyncSelect';

interface Icd10Options {
  value: string;
  label: string;
}

interface FilterProps {
  setListFilter(listFilter: React.SetStateAction<ListFilter>): void;
  onChangePage: (newPage: number) => any;
}

const Filter: React.FC<FilterProps> = ({ setListFilter, onChangePage }) => {
  const formRef = useRef<FormHandles>(null);
  const [datesSelected, setDatesSelected] = useState<Partial<DateValueProps>>(
    {},
  );

  const loadHealthConditionOptions = async (
    inputValue: string,
    _: any,
    additional?: { page: number },
  ) => {
    const { data: results } = await getAllHealthConditionFromPatients({
      limit: 10,
      page: additional?.page || 1,
      keyword: inputValue,
    });
    const icd10Options = results.map((option) => ({
      value: option.id,
      label: `${option.icd} - ${option.description}`,
    }));

    return {
      options: icd10Options,
      hasMore: icd10Options.length > 0,
      additional: { page: (additional?.page ? additional.page : 1) + 1 },
    };
  };

  const handleSubmit = useCallback(() => {
    onChangePage(1);

    const { endDate, startDate } = datesSelected;

    setListFilter((prevState) => ({
      ...prevState,
      ...formRef.current?.getData(),
      endDate,
      startDate,
    }));
  }, [onChangePage, setListFilter, datesSelected]);

  const handleUpdateDate = useCallback(
    ({ startDate, endDate }: DateValueProps) => {
      if (startDate && endDate) {
        setDatesSelected({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        });
      }

      if (!startDate && !endDate) {
        setDatesSelected({});
      }
    },
    [],
  );

  return (
    <Container>
      <h1>Filtrar fichas</h1>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <GridContent>
          <Field gridArea="sn">
            <label>Nome da ficha</label>
            <InputWithoutTitle name="sheetName" />
          </Field>
          <Field gridArea="pn">
            <label>Nome do paciente</label>
            <InputWithoutTitle name="patientName" />
          </Field>
          <Field gridArea="dt">
            <label>Período de preenchimento</label>
            <ButtonFilterRangeDate
              dateValue={datesSelected}
              onChange={handleUpdateDate}
              canClearDates
            />
          </Field>
          <Field gridArea="co">
            <label>Condição de saúde</label>
            <InputAsyncSelect
              name="icd10Id"
              cacheOptions
              defaultOptions
              loadOptions={loadHealthConditionOptions}
              placeholder=""
            />
          </Field>
          <Field gridArea="an">
            <label>Nome do profissional de atendimento</label>
            <InputWithoutTitle name="professionalName" />
          </Field>
          <Field gridArea="bt" isButton>
            <Button type="submit">
              <IoMdSearch />
              Buscar
            </Button>
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default Filter;
