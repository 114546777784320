import styled from 'styled-components';

import ButtonBlue from '../../../../../components/ButtonBlue';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 445px;
  width: 545px;

  h1 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
    font-weight: 700;
    margin-top: 35px;
  }

  p {
    color: #707683;
    font-size: 14px;
    line-height: 18px;
    margin-top: 30px;
    padding: 0;
    text-align: center;
    width: 355px;
  }
`;

export const Button = styled(ButtonBlue)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};
  height: 48px;
  width: 135px;
  margin-top: 40px;
`;
