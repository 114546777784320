import React, {
  InputHTMLAttributes,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { useField } from '@unform/core';

import { OptionRadio } from './styles';

interface ButtonOptions {
  label: string;
  value: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: ButtonOptions[];
  defaultOption?: string;
}

const InputRadio: React.FC<InputProps> = ({ name, options, defaultOption }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [elementValue, setElementValue] = useState(defaultOption);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleClick = useCallback((value) => {
    setElementValue(value);
  }, []);

  return (
    <>
      <ul>
        {options.map(({ label, value }) => (
          <OptionRadio key={value}>
            <button
              type="button"
              onClick={() => handleClick(value)}
              disabled={value === elementValue}
            >
              {' '}
            </button>
            <strong>{label}</strong>
          </OptionRadio>
        ))}
      </ul>

      <input
        type="hidden"
        defaultValue={defaultValue}
        ref={inputRef}
        value={elementValue}
      />
    </>
  );
};

export default InputRadio;
