import styled from 'styled-components';
import { colors } from '../../../../styles/theme/colors';

export const LastNoticesCardContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-height: 100vh;
  margin: 20px 0;

  > header {
    display: flex;
    padding: 10px 20px;

    > div {
      > h2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #707683;
      }
    }
    > div:nth-child(1) {
      width: 38%;
    }
    > div:nth-child(2) {
      width: 44%;
    }
    > div:nth-child(3) {
      width: 18%;
    }
  }

  > section {
    display: flex;
    padding: 10px 20px;
    border-top: 1px solid #efefef;

    > div {
      display: flex;
      align-items: center;

      > b {
        font-size: 14px;
        line-height: 16px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #707683;
      }

      > img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }

      > a {
        text-align: left;
        margin-left: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: ${colors.primary};
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        margin-right: 10px;
      }

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #707683;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        margin-right: 10px;
      }
    }

    > div:nth-child(1) {
      width: 38%;
    }
    > div:nth-child(2) {
      width: 44%;
    }
    > div:nth-child(3) {
      width: 18%;
    }
  }
`;
