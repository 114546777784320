import { api } from '../api';

export const generateLastSignedConsentTermPDF = async (
  patientId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Blob> => {
  const { data } = await api.get(`/signedConsentTerms/pdf/${patientId}`, {
    responseType: 'blob',
  });

  return data;
};
