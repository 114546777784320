import { api } from '../api';
import { Appointment } from '../../entities/Appointment';
import AppointmentTypeEnum from '../../enums/AppointmentTypeEnum';

interface GetAppointmentsProps {
  professionalId?: string;
  patientId?: string;
  type?: AppointmentTypeEnum;
  limit?: number;
  page?: number;
  status?: 'confirmed' | 'finished';
}

interface GetAppointmentsAndCountProps {
  appointments: Appointment[];
  totalAppointments: number;
}

export const getAppointmentsByPatient = async ({
  patientId,
  limit,
  type,
  page,
  status,
}: GetAppointmentsProps): Promise<GetAppointmentsAndCountProps> => {
  const { data } = await api.get<GetAppointmentsAndCountProps>(
    `/appointments/patient/${patientId}`,
    {
      params: {
        limit,
        type,
        page,
        status,
      },
    },
  );

  return data;
};
