import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  column-gap: 25px;
  row-gap: 25px;
  grid-template: repeat(7, 1fr) / repeat(7, 1fr);
  grid-template-areas:
    'he he he he he he he'
    'me me co co co co co'
    'me me co co co co co'
    'me me co co co co co'
    'me me co co co co co'
    'me me co co co co co'
    'me me co co co co co';

  width: 930px;
  height: 720px;
  padding: 50px 30px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  grid-area: he;

  h1 {
    font-weight: 500;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.graySecondary};
    margin-right: 15px;
  }

  button {
    width: 50px;
    padding: 4px 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray};
    background: transparent;
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray};

    & + button {
      margin-left: 5px;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.primary};
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
      cursor: default;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: me;

  h3 {
    font-weight: 600;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 40px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 35px;
    border-radius: 6px;
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.graySecondary};
    font-weight: 600;
    font-size: 14px;

    & + button {
      margin-top: 15px;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.primary};
      background: #daedf7;
      cursor: default;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: co;

  width: 100%;
`;
