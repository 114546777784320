import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  position: relative;
  padding: 12px 16px;
  width: 100%;

  border: 1px solid ${(props) => props.theme.colors.gray2};
  color: ${(props) => props.theme.colors.grayAlternative};

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  strong {
    position: absolute;
    top: 9px;
    font-style: normal;
    font-weight: 600;
    font-size: 0.6rem;
    line-height: 0.5rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.grayAlternative};
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: ${props.theme.colors.danger};
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: ${props.theme.colors.graySecondary};
      border-color: ${props.theme.colors.primary};
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: ${props.theme.colors.graySecondary};
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      background: #efefef;
    `}


  input {
    flex: 1;
    border: 0;
    background: transparent;
    color: ${(props) => props.theme.colors.graySecondary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    width: 100%;

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &::placeholder {
      color: ${(props) => props.theme.colors.graySecondary};
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: ${(props) => props.theme.colors.danger};
    color: ${(props) => props.theme.colors.white};

    &::before {
      border-color: ${(props) => props.theme.colors.danger} transparent;
    }
  }
`;
