import React, { useCallback, useState } from 'react';
import { useModal } from 'react-brave-modal';

import { RiCloseCircleFill } from 'react-icons/ri';
import { useCurrentCallback } from '../../lib/use-current-effect';
import { Container, ButtonGroup, Button } from './styles';

interface RemoveModalProps {
  message: string;
  name?: string;
  description?: string;
  onSuccess?: () => void;
  removeFunction: () => Promise<any>;
}

const RemoveModal: React.FC<RemoveModalProps> = ({
  message,
  name,
  description,
  onSuccess,
  removeFunction,
}) => {
  const { closeModal } = useModal();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleConfirm = useCurrentCallback(
    (isCurrent) => async () => {
      setIsButtonDisabled(true);
      const canPass = await removeFunction();
      if (canPass && isCurrent()) {
        if (onSuccess) {
          onSuccess();
        }

        closeModal();
      }

      if (isCurrent()) {
        setIsButtonDisabled(false);
      }
    },
    [onSuccess, closeModal, removeFunction],
  );

  return (
    <Container>
      <RiCloseCircleFill size={100} />
      <h1>{message}</h1>
      <h3>
        {name}
        {description && ` - ${description}`}
      </h3>
      <ButtonGroup>
        <button type="button" onClick={handleCloseModal}>
          Não
        </button>
        <Button
          loading={isButtonDisabled}
          type="button"
          onClick={handleConfirm}
        >
          Sim
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default RemoveModal;
