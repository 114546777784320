/* eslint-disable @typescript-eslint/camelcase */
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useEffect,
  lazy,
  Suspense,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { addHours, format, startOfDay } from 'date-fns';
import * as Yup from 'yup';

import { differenceInDays } from 'date-fns/esm';
import { useModal } from 'react-brave-modal';
import InputTextArea from '../../../../components/InputTextArea';

import {
  Container,
  CardHeader,
  CardContent,
  Box,
  StatusBox,
  StatusTag,
  Buttons,
  Button,
  DateBox,
  ObservationBox,
  MessageTable,
  ReasonDateBox,
  ReasonBox,
} from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { usePatient } from '../../../../hooks/patient';
import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import InputSwitch from '../../../../components/InputSwitch';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import ShowDetailedObservationsButton from '../../../../components/ShowDetailedObservationsButton';
import RequiredFieldInformation from '../../../../components/RequiredFieldInformation';
import DetailedStatusObservationModal, {
  DetailedStatusObservationModalProps,
} from '../../../../modals/DetailedStatusObservationsModal';
import { getObservationReason } from '../../../../utils/getObservationReason';
import UserStatusEnum from '../../../../enums/UserStatusEnum';
import UserStatusReasonEnum from '../../../../enums/UserStatusReasonEnum';

export interface DisplayableStatus {
  key?: string;
  status?: 'Ativo' | 'Rascunho' | 'Inativo';
  observation?: string;
  created_at?: string | Date;
  register: string;
  professionalName: string;
  reason?: string;
  reasonDate?: string;
}

const ConfirmPatientReactivationModal = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmPatientReactivationModal" */ './modals/ConfirmPatientReactivationModal'
    ),
);

const ConfirmPatientDeactivationModal = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmPatientDeactivationModal" */ './modals/ConfirmPatientDeactivationModal'
    ),
);

interface FormProps {
  status: UserStatusEnum;
  reason: UserStatusReasonEnum;
  reason_date?: Date;
  related_monitored_disease?: boolean;
  cause_reason?: string;
  observation?: string;
}

export interface StatusDataRef {
  getValues(): FormProps;
  getValidate(): Promise<boolean>;
}

type RegisteredUserStatus = Exclude<UserStatusEnum, UserStatusEnum.DRAFT>;

const Status: React.ForwardRefRenderFunction<StatusDataRef> = (_, ref) => {
  const { patient, sync } = usePatient();
  const { showModal } = useModal();

  const formRef = useRef<FormHandles>(null);

  const observationsRef = useRef<{
    [key: string]: HTMLParagraphElement | null;
  }>({});

  const [didStatusChange, setDidStatusChange] = useState(false);

  const [disableInactive, setDisableInactive] = useState(false);

  const [reasonItem, setReasonItem] = useState<UserStatusReasonEnum>();

  const [listDisplay, setListDiplay] = useState<DisplayableStatus[]>([]);

  const [selected, setSelected] = useState<UserStatusEnum>(
    UserStatusEnum.ACTIVE,
  );

  useEffect(() => {
    sync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setListDiplay(
      patient?.user?.status?.map(
        ({
          status,
          created_at,
          user_created_updated_by,
          id,
          observation,
          reason,
        }) => ({
          key: id,
          status: userStatusList.find(
            (userStatus) => userStatus.value === status,
          )?.label,
          observation,
          created_at,
          reason: getObservationReason(reason),
          register: created_at
            ? format(
                addHours(new Date(created_at), -3),
                "dd'/'MM'/'yyyy' - 'HH':'mm'h'",
              )
            : `-`,
          professionalName: user_created_updated_by?.name ?? `-`,
        }),
      ) ?? [],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.user]);

  useEffect(() => {
    formRef.current?.setErrors({});
  }, [reasonItem]);

  const buttons: {
    text: string;
    key: RegisteredUserStatus;
  }[] = useMemo(
    () => [
      {
        text: 'Paciente ativo',
        key: UserStatusEnum.ACTIVE,
      },
      {
        text: 'Paciente inativo',
        key: UserStatusEnum.INACTIVE,
      },
    ],
    [],
  );

  useEffect(() => {
    setDisableInactive(
      patient?.user?.currentStatus?.status !== UserStatusEnum.ACTIVE,
    );
    setSelected(patient?.user?.currentStatus?.status ?? UserStatusEnum.ACTIVE);
  }, [patient]);

  useEffect(() => {
    if (selected === UserStatusEnum.INACTIVE) {
      formRef.current?.setData({ ...patient?.user?.currentStatus });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const userStatusList = [
    {
      value: 'active',
      label: 'Ativo',
    },
    {
      value: 'draft',
      label: 'Rascunho',
    },
    {
      value: 'inactive',
      label: 'Inativo',
    },
  ] as const;

  const reasonList = useMemo(
    () => [
      { value: 'death', label: 'Óbito' },
      { value: 'other', label: 'Outros' },
      { value: 'withdrawal', label: 'Desistência' },
    ],
    [],
  );

  const getValues = useCallback(() => {
    const statusPatient: FormProps = {
      ...(formRef.current?.getData() as FormProps),
      status: selected,
    };

    return statusPatient;
  }, [selected]);

  const getValidate = useCallback(async () => {
    const data = getValues();

    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        status: Yup.string().required(),
        reason: Yup.string().when('status', {
          is: 'active',
          then: Yup.string(),
          otherwise: Yup.string().required(
            'Motivo da inatividade é obrigatória',
          ),
        }),
        reason_date: Yup.string().when('status', {
          is: 'active',
          then: Yup.string(),
          otherwise: Yup.string().when('reason', {
            is: 'other',
            then: Yup.string(),
            otherwise: Yup.string()
              .test(
                'future-date',
                'Não pode ser infomado uma data que não passou',
                (value: string) => {
                  const valueSplit = value
                    .split('-')
                    .map((item) => Number(item));
                  const valueDate = new Date(
                    valueSplit[0],
                    valueSplit[1] - 1,
                    valueSplit[2],
                  );

                  return (
                    differenceInDays(valueDate, startOfDay(new Date())) <= 0
                  );
                },
              )
              .test(
                'empty-date',
                'Data obrigatória',
                (value: string) => value.length !== 0,
              ),
          }),
        }),
        related_monitored_disease: Yup.boolean().when('reason', {
          is: 'death',
          then: Yup.boolean().required(
            'Óbito relacionado à doença monitorada é obrigatório',
          ),
          otherwise: Yup.boolean(),
        }),
        cause_reason: Yup.string(),
        observation: Yup.string().when('reason', {
          is: 'other',
          then: Yup.string().required('Observação é obrigatória'),
          otherwise: Yup.string(),
        }),
      });

      didStatusChange &&
        (await schema.validate(data, {
          abortEarly: false,
        }));

      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }
      return false;
    }
  }, [didStatusChange, getValues]);

  const handleChangeStatus = useCallback(
    (param: RegisteredUserStatus) => {
      if (patient?.user?.currentStatus?.status !== param) {
        setDidStatusChange(param === UserStatusEnum.INACTIVE);

        showModal({
          type: 'custom',
          data: (
            <Suspense fallback={false}>
              {param === UserStatusEnum.ACTIVE ? (
                <ConfirmPatientReactivationModal
                  onSuccess={() => setSelected(param)}
                />
              ) : (
                <ConfirmPatientDeactivationModal
                  onSuccess={() => setSelected(param)}
                />
              )}
            </Suspense>
          ),
        });
      } else {
        setSelected(param);
      }
    },
    [patient, showModal],
  );

  const handleShowObservations = useCallback(
    (statusData: DetailedStatusObservationModalProps['statusData']) => {
      showModal({
        type: 'custom',
        data: <DetailedStatusObservationModal statusData={statusData} />,
      });
    },
    [showModal],
  );

  useImperativeHandle(ref, () => ({
    getValidate,
    getValues,
  }));

  return (
    <Container>
      <CardHeader>
        <h1>Status - Ativar/Desativar perfil</h1>
      </CardHeader>
      <CardContent>
        <Form ref={formRef} onSubmit={() => {}}>
          <Box>
            <StatusBox>
              <span>Status atual</span>
              {patient?.user?.currentStatus?.status === UserStatusEnum.DRAFT ? (
                <StatusTag>Rascunho</StatusTag>
              ) : (
                <>
                  {patient?.user?.currentStatus?.reason ===
                  UserStatusReasonEnum.INCOMPLETE_REGISTRATION ? (
                    <StatusTag>Cadastro incompleto</StatusTag>
                  ) : (
                    <Buttons>
                      {buttons.map((button) => (
                        <Button
                          key={button.key}
                          onClick={() => handleChangeStatus(button.key)}
                          selected={button.key === selected}
                        >
                          {button.text}
                        </Button>
                      ))}
                    </Buttons>
                  )}
                </>
              )}
            </StatusBox>
            <DateBox>
              <span>Data de cadastro</span>
              {patient?.user?.created_at && (
                <span>
                  {format(
                    new Date(patient?.user?.created_at),
                    "dd'/'MM'/'yyyy'",
                  )}
                </span>
              )}
            </DateBox>
          </Box>
          {selected === UserStatusEnum.INACTIVE && !disableInactive ? (
            <>
              <ReasonBox>
                <span>
                  Selecione o motivo da inatividade
                  <RequiredFieldInformation />:
                </span>
                <InputSelectAlternative
                  name="reason"
                  options={reasonList}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(event: any) =>
                    setReasonItem(event.value as UserStatusReasonEnum)
                  }
                />
              </ReasonBox>
              {reasonItem &&
                ![
                  UserStatusReasonEnum.OTHER,
                  UserStatusReasonEnum.EXPIRED_BY_SYSTEM,
                  UserStatusReasonEnum.INCOMPLETE_REGISTRATION,
                ].includes(reasonItem) && (
                  <ReasonDateBox>
                    <div>
                      {reasonItem === UserStatusReasonEnum.DEATH && (
                        <span>
                          Data do óbito
                          <RequiredFieldInformation />:
                        </span>
                      )}
                      {reasonItem === UserStatusReasonEnum.WITHDRAWAL && (
                        <span>
                          Data da desistência
                          <RequiredFieldInformation />:
                        </span>
                      )}
                      <InputWithoutTitle
                        className="datepicker"
                        type="date"
                        name="reason_date"
                        disabled={disableInactive}
                        max={new Date().toISOString().split('T')[0]}
                      />
                    </div>

                    {reasonItem === UserStatusReasonEnum.DEATH && (
                      <div>
                        <InputSwitch
                          name="related_monitored_disease"
                          disabled={disableInactive}
                          defaultChecked
                        />
                        <span>Óbito relacionado à doença monitorada</span>
                      </div>
                    )}
                  </ReasonDateBox>
                )}
              <ObservationBox>
                {reasonItem === UserStatusReasonEnum.DEATH && (
                  <>
                    <span>Causa da morte na certidão de óbito:</span>
                    <InputTextArea
                      name="cause_reason"
                      disabled={disableInactive}
                    />
                  </>
                )}
                <span>
                  Observação:
                  {reasonItem === UserStatusReasonEnum.OTHER && (
                    <>
                      <RequiredFieldInformation />:
                    </>
                  )}
                </span>
                <InputTextArea name="observation" disabled={disableInactive} />
              </ObservationBox>
            </>
          ) : (
            <MessageTable>
              <header>
                <h3>Status</h3>
                <h3>Motivo</h3>
                <h3>Observações</h3>
                <span />
                <h3>Registro</h3>
                <h3>Profissional</h3>
              </header>

              <div>
                {listDisplay.map(
                  ({
                    created_at,
                    key,
                    status,
                    observation,
                    register,
                    professionalName,
                    reason,
                    reasonDate,
                  }) => (
                    <section key={key}>
                      <div>{status}</div>
                      <div>
                        {status === 'Inativo' &&
                          (`${reason ?? ''}${
                            reasonDate ? `, ${reasonDate}` : ''
                          }` ??
                            '-')}
                      </div>
                      <div>
                        <p
                          ref={(element) => {
                            if (key) {
                              observationsRef.current[key] = element;
                            }
                          }}
                        >
                          {observation ?? '-'}
                        </p>
                      </div>

                      {key && (
                        <ShowDetailedObservationsButton
                          elementKey={key}
                          observationsRef={observationsRef}
                          onClick={() =>
                            handleShowObservations({
                              professionalName,
                              created_at,
                              observation,
                              reason,
                              status,
                            })
                          }
                        />
                      )}

                      <div>
                        <p>{register}</p>
                      </div>
                      <div>
                        <p>{professionalName}</p>
                      </div>
                    </section>
                  ),
                )}
              </div>
            </MessageTable>
          )}
        </Form>
      </CardContent>
    </Container>
  );
};

export default forwardRef(Status);
