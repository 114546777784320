import styled from 'styled-components';

import { Container as GridCardContainer } from '../../../../components/GridCard/styles';

interface SeverityLabelProps {
  backgroundColor: string;
}

export const Container = styled(GridCardContainer)`
  padding-left: 25px;
  padding-top: 30px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    margin-right: 25px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 24px 30px 0;
  margin-top: 15px;

  height: 100%;
  overflow-y: auto;

  > span {
    align-self: flex-end;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #acabb7;
  }

  > div:last-of-type {
    border-bottom: none;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  padding: 12px 0;
`;

export const HealthConditionContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 285px;

  > span {
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 28px;

  button {
    background-color: transparent;
    border: none;

    svg {
      vertical-align: middle;
    }

    & + button {
      margin-left: 20px;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
`;

export const SeverityLabel = styled.span<SeverityLabelProps>`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  background-color: ${({ backgroundColor }) => backgroundColor};

  min-width: 37px;
  height: 19px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
`;
