import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

interface SelectProps {
  isErrored: boolean;
  isFocused: boolean;
  isCentered?: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const Select = styled(ReactSelect)<SelectProps>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  min-width: 75px;

  input:focus {
    border-color: ${({ theme }) => theme.colors.primary} !important;
  }

  > div:first-of-type {
    border: 1px solid ${({ theme }) => theme.colors.gray2} !important;
    min-height: 42px !important;
  }

  div {
    box-shadow: 0 0 0 !important;

    div > input {
      color: ${({ theme }) => theme.colors.black} !important;
    }
  }

  ${({ isErrored, theme }) =>
    isErrored &&
    css`
      > div:first-of-type {
        border: 1px solid ${theme.colors.danger} !important;
      }
    `}

  div:first-of-type.react-select__control {
    min-height: 42px !important;
    border-width: 1px !important;
  }

  div:first-of-type.react-select__control--is-focused {
    border: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }

  ${({ isCentered }) =>
    isCentered &&
    css`
      .react-select__value-container {
        flex-wrap: nowrap;
        flex-direction: row-reverse;
      }

      .react-select__input-container {
        flex: unset;
        width: 0px;
      }
    `}

  .react-select__single-value {
    color: ${({ theme }) => theme.colors.graySecondary};
    font-size: 14px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .react-select__indicator.react-select__dropdown-indicator {
    padding-right: 16px;
  }

  .react-select__option {
    font-size: 14px;
  }
`;
