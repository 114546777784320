import React from 'react';
import { GoGear } from 'react-icons/go';
import { TiArrowSortedDown } from 'react-icons/ti';

import ActionMenuButton, { ActionMenuButtonProps } from '../ActionMenuButton';

import { Icons, Span } from './styles';

const ActionMenuButtonItemList: React.FC<ActionMenuButtonProps> = ({
  disabled = false,
  ...rest
}) => {
  return (
    <ActionMenuButton disabled={disabled} {...rest}>
      <Icons isDisabled={disabled}>
        <GoGear size={25} />
        <TiArrowSortedDown size={25} />
      </Icons>
      <Span isDisabled={disabled}>ações</Span>
    </ActionMenuButton>
  );
};

export default ActionMenuButtonItemList;
