import React, { useState, useMemo } from 'react';

import Filter from './Filter';
import Table from './Table';
import DashboardTemplate from '../../../templates/Dashboard';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import Spinner from '../../../components/Spinner';
import {
  getSheetsByFilter,
  GetSheetsByFilter as ListFilter,
  GetSheetsByFilterResponse as ListData,
} from '../../../services/sheet';

import Pagination from '../../../components/Pagination';
import { useCurrentEffect } from '../../../lib/use-current-effect';
import MainAreasEnum from '../../../enums/MainAreasEnum';

const List: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [listFilter, setListFilter] = useState<ListFilter>({
    orderBy: 'sheetName',
    ordenationType: 'ASC',
    limit: 10,
  });
  const [listData, setListData] = useState<ListData>({
    sheets: [],
    totalSheets: 0,
    totalPages: 0,
    totalSheetsOnPage: 0,
  });

  const pages = useMemo<ElementPageTree[]>(
    () => [{ name: 'Administração' }, { name: 'Fichas de saúde' }],
    [],
  );

  useCurrentEffect(
    (isCurrent) => {
      getSheetsByFilter({ ...listFilter, page: currentPage })
        .then((listDataResponse) => {
          if (isCurrent()) {
            setListData(listDataResponse);
          }
        })
        .finally(() => {
          if (isCurrent()) {
            setIsLoading(false);
          }
        });
    },
    [currentPage, listFilter],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <PageTree pages={pages} />

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Filter
            setListFilter={setListFilter}
            onChangePage={(page: number) => setCurrentPage(page)}
          />
          <Table setListFilter={setListFilter} listData={listData} />
          {!!listData.sheets.length && (
            <Pagination
              totalPages={listData.totalPages}
              onChangePage={(page: number) => setCurrentPage(page)}
              page={currentPage}
            />
          )}
        </>
      )}
    </DashboardTemplate>
  );
};

export default List;
