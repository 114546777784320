import React, { useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { HiOutlineArrowLeft } from 'react-icons/hi';

import InputWithoutTitleAndFormattedToLower from '../../../../components/InputWithoutTitleAndFormattedToLower';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { forgotPassword } from '../../../../services/user';
import history from '../../../../services/history';

import { Container, Field, SendButton } from './styles';
import { useCurrentCallback } from '../../../../lib/use-current-effect';

interface EmailConfirmationProps {
  handleStepChange: (key: string) => void;
}

interface FormProps {
  email: string;
}

const EmailConfirmation: React.FC<EmailConfirmationProps> = ({
  handleStepChange,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async (data: FormProps) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um email válido')
            .required('O e-mail é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await forgotPassword({ email: data.email.trim().toLowerCase() });

        if (isCurrent()) {
          handleStepChange('SendingInstructions');
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response) {
          if (isCurrent()) {
            handleStepChange('WrongEmail');
          }
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }
    },
    [handleStepChange],
  );

  return (
    <Container>
      <HiOutlineArrowLeft size={28} onClick={() => history.push('/login')} />
      <h1>Redefinir senha</h1>
      <h2>
        Para redefinir a sua senha, insira o seu e-mail cadastrado na
        plataforma:
      </h2>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Field>
          <label>E-mail:</label>
          <InputWithoutTitleAndFormattedToLower name="email" type="email" />
        </Field>
        <SendButton type="submit">Enviar</SendButton>
      </Form>
    </Container>
  );
};

export default EmailConfirmation;
