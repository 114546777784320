import styled from 'styled-components';

import InputTextArea from '../../../../components/InputTextArea';

interface StatusItemTableProps {
  color?: string;
}

export const Container = styled.div`
  display: flex;
  position: relative;
  margin-top: 30px;
  max-height: 50vh;
  padding: 45px 45px 7px 45px;

  > section {
    flex: 1;
    padding-right: 35px;

    > header {
      > h1 {
        color: ${(props) => props.theme.colors.graySecondary};
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }

      > h3 {
        color: ${(props) => props.theme.colors.graySecondary};
        font-size: 16px;
        font-style: normal;
        font-weight: bold;
        line-height: 18px;
        margin-top: 15px;
      }

      > p {
        color: ${(props) => props.theme.colors.grayAlternative};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        margin: 5px 0 30px;
        padding: 0;
      }
    }

    & + section {
      flex: 2;
      padding-left: 50px;
      padding-right: 0;
    }
  }
`;

export const InputTitle = styled.h3`
  display: flex;
  color: #707683;
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 18px;
  margin: 30px 0 20px !important;

  > button {
    background: rgba(45, 156, 219, 0.1);
    border: none;
    border-radius: 100px;
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    white-space: nowrap;
    padding: 10px 20px;
  }
`;

export const Textarea = styled(InputTextArea)`
  color: ${(props) => props.theme.colors.graySecondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  max-height: 150px !important;
  max-width: 70vw !important;
`;

export const InputNumber = styled.div`
  button {
    display: none;
  }
`;
