import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  height: 630px;
  width: 100%;
  padding: 80px 50px;
`;

export const CardHeader = styled.div`
  h1 {
    font-size: 22px;
    font-weight: 700;
  }

  h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 8px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 5px;
  max-width: 800px;
`;

export const Box = styled.div`
  margin-right: 40px;
  max-width: 380px;

  form {
    margin-top: 25px;
    max-width: 420px;

    > div {
      height: 40px;
      margin-top: 5px;
    }

    input {
      font-size: 14px;
    }
  }

  button {
    height: 48px;
    width: 152px;
    margin-left: auto;
    margin-top: 25px;
  }
`;
