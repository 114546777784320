import React from 'react';

import { ReactComponent as TriangleDownImage } from '../../assets/images/triangle-down.svg';

import { Container, Balloon, Box, Icon, Title } from './styles';

interface TooltipGenericProps {
  element: JSX.Element;
  title: string;
}

const TooltipGeneric: React.FC<TooltipGenericProps> = ({ element, title }) => {
  return (
    <Container>
      <Balloon>
        <Box>
          <Icon>{element}</Icon>
          <Title>{title}</Title>
        </Box>
      </Balloon>
      <TriangleDownImage />
      <span>{element}</span>
    </Container>
  );
};

export default TooltipGeneric;
