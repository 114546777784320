import styled from 'styled-components';

interface ContainerProps {
  gridArea: string;
}

export const Container = styled.div<ContainerProps>`
  background-color: white;
  border-radius: 6px;

  display: flex;
  justify-content: flex-start;
  grid-area: ${({ gridArea }) => gridArea};
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
`;

export const CardTitle = styled.h1`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #4f4f4f;
`;
