import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 350px;
  width: 100%;
  padding-right: 80px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;

  > h1 {
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;

    color: #707683;
  }

  > button {
    width: 222px;
    font-weight: 600;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;

  margin-top: 2rem;
  border-radius: 6px;

  background-color: #fcfcfc;
`;
