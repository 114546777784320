import React, { useState, useCallback, useMemo } from 'react';

import Window from '../../../components/Window';
import EmailConfirmation from './EmailConfirmation';
import SendingInstructions from './SendingInstructions';
import WrongEmail from './WrongEmail';

import { Container } from './styles';

const ForgotPassword: React.FC = () => {
  const [stepKey, setStepKey] = useState('EmailConfirmation');

  const handleStepChange = useCallback((key: string) => {
    setStepKey(key);
  }, []);

  const steps = useMemo(
    () => [
      {
        component: <EmailConfirmation handleStepChange={handleStepChange} />,
        key: 'EmailConfirmation',
      },
      {
        component: <SendingInstructions />,
        key: 'SendingInstructions',
      },
      {
        component: <WrongEmail handleStepChange={handleStepChange} />,
        key: 'WrongEmail',
      },
    ],
    [handleStepChange],
  );

  return (
    <Window>
      <Container>
        {steps.find((step) => step.key === stepKey)?.component}
      </Container>
    </Window>
  );
};

export default ForgotPassword;
