import styled, { css } from 'styled-components';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';

interface ButtonProps {
  lite?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  color: 'white' | 'primary';
}

interface ModalElementProps {
  appointmentType: AppointmentTypeEnum;
}

export const ModalElement = styled.div<ModalElementProps>`
  display: flex;
  height: 570px;
  width: 930px;

  > * {
    flex: 1;
  }

  > div:first-child {
    display: flex;
    justify-content: center;

    > img {
      object-fit: contain;
      width: ${({ appointmentType }) =>
        appointmentType === AppointmentTypeEnum.PHONE ? '210px' : '270px'};
    }
  }
`;

export const ModalGrouper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;

  > * {
    max-width: 330px;

    &:last-child {
      margin-top: 20px;
    }
  }

  h1 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 130%;
  }

  > p {
    margin-top: 10px;
    padding: 0;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};

  white-space: nowrap;

  border-radius: 100px;
  border: 0;
  padding: 16px 13px;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 0px !important;
  transition: all 0.6s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #828282;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
  }

  ${({ color, theme }) =>
    color === 'white' &&
    css`
      background: ${theme.colors.white};
      color: #828282;

      &:hover {
        color: ${theme.colors.white};
      }
    `}

  ${({ color, theme }) =>
    color === 'primary' &&
    css`
      color: ${theme.colors.white};
    `}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      background: #f5f6f8;
      color: ${theme.colors.gray};
      border: 1px solid ${theme.colors.gray};

      &:hover {
        background: #f5f6f8;
        color: ${theme.colors.gray};
      }
      cursor: default;
      transition: none;
    `}

    ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}

    ${({ lite, theme }) =>
    lite &&
    css`
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.primary};

      background: ${theme.colors.white};
    `}

  &:last-child {
    margin-left: 15px;
  }
`;
