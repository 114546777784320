import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 70vw;
  height: 80vh;
  padding: 50px 20px;

  > header {
    > h1 {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 18px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #4f4f4f;
    }

    > section {
      display: flex;
      margin-top: 25px;

      > div:nth-child(1) {
        flex: 2;
      }

      > div:nth-child(2) {
        flex: 1;
      }

      > div:nth-child(3) {
        flex: 1;
      }

      > div {
        > h2 {
          padding: 0;
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.01em;
          color: #bdbdbd;
        }

        > h3 {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.01em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #4f4f4f;
          margin-top: 3px;
        }
      }
    }
  }

  > section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    overflow-y: auto;
    margin-top: 25px;

    .alert.alert-success {
      display: none;
    }

    > div:last-child {
      .formio-form {
        .form-group.has-feedback.formio-component.formio-component-button.formio-component-submit.form-group {
          .null.has-success {
            display: none;
          }
        }
      }
    }
  }

  > footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 15px;

    button + button {
      margin-left: 20px;
    }
  }
`;
