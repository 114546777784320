import React, { useCallback, useState, useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useTransition, useSpringRef } from '@react-spring/web';
import { useHistory } from 'react-router-dom';
import InstitutionIcon from '../../../../assets/images/institution-icon.svg';
import ComorbidityWithDangerNoticesDTO from '../../../../dtos/ComorbidityWithDangerNoticesDTO';

import {
  CardContainer,
  CardHeader,
  CardTitle,
  ComorbidityColorBall,
} from '../styles';
import {
  NoticesByInstitutionCardContent,
  NoticesByInstitution,
  NoticesByInstitutionSection,
  NoticesByInstitutionTable,
  InstitutionsWithNotices,
  InstitutionsWithNoticesTable,
  NoticesByInstitutionChart,
  NoticesByInstitutionSlideButton,
} from './styles';
import EmptyCard from '../EmptyCard';
import Spinner from '../../../../components/Spinner';

interface NoticesByInstitutionCardProps {
  loading: boolean;
  total?: number;
  data?: {
    institutionId: string;
    institutionName: string;
    allNoticesTotal: number;
    allNoticesPercentage: number;
    allNotices: ComorbidityWithDangerNoticesDTO[];
  }[];
}

const NoticesByInstitutionCard: React.FC<NoticesByInstitutionCardProps> = ({
  data = [],
  total = 0,
  loading,
}) => {
  const [index, setIndex] = useState(0);
  const transRef = useSpringRef();
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: 'translateX(-20%)' },
    enter: { opacity: 1, transform: 'translateX(0)' },
    leave: { opacity: 0, transform: 'translateX(20%)' },
  });
  const changeData = useCallback((newIndex: number) => {
    setIndex(newIndex);
  }, []);
  const history = useHistory();

  const showAll = useCallback(() => {
    history.push('/patient/list');
  }, [history]);

  useEffect(() => {
    transRef.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((i) => (i === data.length - 1 ? 0 : 1 + i));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [data]);

  return (
    <CardContainer gridArea="ni">
      <CardHeader>
        <CardTitle>Pacientes em alerta | Por Instituição</CardTitle>
        {data.length > 0 && (
          <button type="button" onClick={showAll}>
            Ver todos <IoIosArrowForward size={12} />
          </button>
        )}
      </CardHeader>
      {!loading ? (
        <>
          {data.length > 0 ? (
            <NoticesByInstitutionCardContent>
              <InstitutionsWithNotices>
                <div>
                  <h2>Total</h2>
                  <h4>{total}</h4>
                </div>
                <InstitutionsWithNoticesTable>
                  <header>
                    <div>
                      <h2>Instituição</h2>
                    </div>
                    <div>
                      <h2>Nº</h2>
                    </div>
                    <div>
                      <h2>%</h2>
                    </div>
                  </header>
                  {data.map(
                    (
                      {
                        institutionName,
                        institutionId,
                        allNoticesTotal,
                        allNoticesPercentage,
                      },
                      i,
                    ) => (
                      <section key={institutionId}>
                        <button type="button" onClick={() => changeData(i)}>
                          {institutionName}
                        </button>
                        <span>{allNoticesTotal}</span>
                        <span>
                          {allNoticesPercentage
                            .toFixed(2)
                            .replace(/[.,]00$/, '')}
                          %
                        </span>
                      </section>
                    ),
                  )}
                </InstitutionsWithNoticesTable>
              </InstitutionsWithNotices>
              <div />
              <NoticesByInstitution>
                {transitions((style, i) => (
                  <NoticesByInstitutionSection style={{ ...style }}>
                    <div>
                      <NoticesByInstitutionChart
                        chartType="ColumnChart"
                        options={{
                          legend: { position: 'none', color: '#fff' },
                          hAxis: { textPosition: 'none' },
                        }}
                        data={[
                          ['Comorbidade', 'Quantidade', { role: 'style' }],
                          ...data[i]?.allNotices.map((comorbidity) => [
                            comorbidity.comorbidityName,
                            comorbidity.total,
                            comorbidity.color,
                          ]),
                        ]}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </div>
                    <NoticesByInstitutionTable>
                      <header>
                        <img src={InstitutionIcon} alt="ícone da instituição" />
                        <h2>{data[i].institutionName}</h2>
                      </header>
                      {data[i].allNotices.map((comorbidity) => (
                        <section key={comorbidity.comorbidityId}>
                          <ComorbidityColorBall color={comorbidity.color} />
                          <h3>{comorbidity.comorbidityName}</h3>
                          <span>{comorbidity.total}</span>
                          <span>
                            {comorbidity.percentage
                              .toFixed(2)
                              .replace(/[.,]00$/, '')}
                            %
                          </span>
                        </section>
                      ))}
                    </NoticesByInstitutionTable>
                  </NoticesByInstitutionSection>
                ))}
                {data.length > 1 && (
                  <footer>
                    {data.map(({ institutionId }, i) => (
                      <NoticesByInstitutionSlideButton
                        onClick={() => changeData(i)}
                        selected={i === index}
                        key={institutionId}
                      />
                    ))}
                  </footer>
                )}
              </NoticesByInstitution>
            </NoticesByInstitutionCardContent>
          ) : (
            <EmptyCard />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </CardContainer>
  );
};

export default NoticesByInstitutionCard;
