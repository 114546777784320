import styled from 'styled-components';

export const Container = styled.div`
  width: 929px;
  height: 571px;

  padding: 75px 40px 125px 40px;

  display: flex;
  flex-direction: column;
  gap: 80px;

  > h1 {
    font-weight: 500;
    font-size: 26px;
    color: ${({ theme }) => theme.colors.graySecondary};
  }

  > div {
    display: flex;
    gap: 70px;

    flex: 1;
  }

  > div + button {
    position: absolute;
    bottom: 40px;
    right: 40px;
    min-width: 175px;
  }
`;

export const ExclusionReasonSection = styled.div`
  flex: 1;

  display: grid;
  grid-template-rows: min-content min-content min-content 1fr;
  grid-row-gap: 20px;

  > h4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.grayTertiary};
  }

  > strong {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.graySecondary};

    padding: 5px;

    display: flex;
    justify-content: center;
    background: rgba(45, 156, 219, 0.15);
    border-radius: 5px;
  }

  > strong + h4 {
    margin-top: 15px;
  }

  > textarea {
    padding: 15px;
    background: white;
    resize: none;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-radius: 6px;

    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.grayTertiary};
  }
`;

export const ExclusionDataCard = styled.div`
  flex: 1;
  position: relative;

  overflow-x: hidden;

  background: #f8f8f8;
  border-radius: 6px;

  display: grid;
  grid-template-columns: min-content 1fr;

  align-items: center;
  padding: 25px 30px 35px;
  column-gap: 25px;

  > div {
    padding: 0;

    font-size: 18px;
    color: ${({ theme }) => theme.colors.grayTertiary};

    strong {
      font-weight: 600;
    }
  }

  > div:last-of-type {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  > span {
    position: absolute;

    right: 30px;
    bottom: 10px;
    left: 30px;

    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.grayTertiary};

    white-space: nowrap;
    overflow-x: inherit;
    text-overflow: ellipsis;
  }

  > svg:first-child {
    > path {
      fill: ${({ theme }) => theme.colors.grayTertiary};
    }
  }
`;
