/* eslint-disable import/no-duplicates */
import React, { useCallback, useRef, useMemo, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format, isEqual } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useModal } from 'react-brave-modal';
import { toast } from 'react-toastify';
import { useCurrentCallback } from '../../../../lib/use-current-effect';

import { ModalProps, BulkList, BulkAvailability } from '../..';
import InputTextArea from '../../../../components/InputTextArea';
import InputMaskAlternative from '../../../../components/InputMaskAlternative';
import Button from '../../../../components/Button';

import { useAppointment } from '../../../../hooks/appointment';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { createBulkAppointment } from '../../../../services/appointments';

import { ReactComponent as DatePickerIcon } from '../../../../assets/images/date-picker.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/images/icon_schedule_time_gray2.svg';
import { ReactComponent as PersonIcon } from '../../../../assets/images/icon_schedule_person_gray2.svg';
import { ReactComponent as StethoscopeIcon } from '../../../../assets/images/stethoscope-two.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/images/icon_schedule_phone_gray2.svg';
import { ReactComponent as FilmIcon } from '../../../../assets/images/icon_schedule_film.svg';
import CloudPhonecallManImage from '../../../../assets/images/cloud-phonecall-man.png';
import VideoFilesImage from '../../../../assets/images/video-files.png';

import { ButtonsContainer } from '../../styles';
import { Container, Grouper, ReviewCard, Row, EditButton } from './styles';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';
import AppointmentAreaEnum from '../../../../enums/AppointmentAreaEnum';
import AppointmentStatusEnum from '../../../../enums/AppointmentStatusEnum';

interface ReviewAppointmentProps extends ModalProps {
  isBulk: boolean;
  bulkList: BulkList[];
  bulkAvailability: BulkAvailability[];
  onFinish: () => void;
}

const ReviewAppointment: React.FC<ReviewAppointmentProps> = ({
  flow,
  isBulk,
  bulkList,
  bulkAvailability,
  back,
  next,
  goTo,
  onFinish,
}) => {
  const {
    appointmentSelected,
    createAppointment,
    updateAppointment,
    cleanLocal,
  } = useAppointment();
  const { closeModal } = useModal();
  const formRef = useRef<FormHandles>(null);

  const [buttonsLoadingStatus, setButtonsLoadingStatus] = useState({
    cancel: false,
    back: false,
    confirm: false,
  });

  const isThereAButtonLoading = useMemo(
    () => Object.values(buttonsLoadingStatus).some((value) => value),
    [buttonsLoadingStatus],
  );

  const appointmentDate = useMemo(() => {
    let date: string;

    if (isBulk) {
      const startDate = new Date(bulkAvailability[0].date);
      const endDate = new Date(
        bulkAvailability[bulkAvailability.length - 1].date,
      );

      if (isEqual(startDate, endDate)) {
        date = format(startDate, 'dd/MM/yyyy', { locale: ptBR });
      } else {
        date = `${format(startDate, 'dd/MM/yyyy', {
          locale: ptBR,
        })} a ${format(endDate, 'dd/MM/yyyy', { locale: ptBR })}`;
      }
    } else {
      date = format(
        appointmentSelected?.date
          ? new Date(appointmentSelected?.date)
          : new Date(),
        "d 'de' MMMM 'de' yyyy ",
        { locale: ptBR },
      );
    }

    return date;
  }, [appointmentSelected, isBulk, bulkAvailability]);

  const appointmentHours = useMemo(() => {
    if (!isBulk) {
      const scheduleTime = format(
        appointmentSelected?.date
          ? new Date(appointmentSelected?.date)
          : new Date(),
        'HH:mm',
        { locale: ptBR },
      );

      if (scheduleTime) {
        const splittedTime = scheduleTime.split(':');
        const hours: string = splittedTime[0].slice(-2);
        const minutes: string = splittedTime[1];

        return `${hours}:${minutes}`;
      }
    }

    return ':';
  }, [appointmentSelected, isBulk]);

  const handleValidate = useCallback(async () => {
    const data = formRef?.current?.getData();

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        text: Yup.string()
          .required('O texto é obrigatório')
          .max(150, 'Você passou do limite de caracteres.'),
        phone: Yup.string()
          .length(16, 'Digite um número de telefone válido')
          .required('O telefone é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }

      return false;
    }
  }, []);

  const handleFinish = useCallback(() => {
    onFinish();

    if (next) {
      next();
    }

    cleanLocal();
  }, [onFinish, next, cleanLocal]);

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async () => {
      setButtonsLoadingStatus((state) => ({ ...state, confirm: true }));

      const data = appointmentSelected ?? {};

      if (data?.type === AppointmentTypeEnum.SMS) {
        const canPass = await handleValidate();

        if (!canPass) {
          return;
        }

        if (!data.message) {
          data.message = {};
        }

        data.contact_phone = formRef.current?.getFieldValue('phone');
        data.message.text = formRef.current?.getFieldValue('text');
      }

      if (isBulk) {
        const patientIds = bulkList.map((patient) => patient.id);
        const dates = bulkAvailability
          .map((availability) =>
            availability.availabilities.map(({ interval }) => interval),
          )
          .reduce((acc, curr) => acc.concat(curr), [])
          .slice(0, patientIds.length);

        if (patientIds.length !== dates.length) {
          toast.info(
            'Quantidade de pacientes difere da quantidade de horários.',
          );
        }

        createBulkAppointment({
          professionalId: data?.professionalId ?? '',
          patientIds,
          dates,
          type: data.type as AppointmentTypeEnum,
          area: data.area as AppointmentAreaEnum,
        })
          .then(() => {
            handleFinish();
          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.data.message);
            } else {
              toast.error('Entre em contato com o administrador.');
            }
          })
          .finally(() => {
            if (isCurrent()) {
              setButtonsLoadingStatus((state) => ({
                ...state,
                confirm: false,
              }));
            }
          });
      } else {
        const appointmentCreateUpdate = {
          professionalId: data?.professionalId ?? '',
          patientId: data?.patientId ?? '',
          type: (data?.type ?? '') as AppointmentTypeEnum,
          status: AppointmentStatusEnum.CONFIRMED,
          area: data.area,
          date: new Date(data?.date ?? ''),
          smsText: data?.message?.text,
          contactPhone:
            data?.type !== AppointmentTypeEnum.VIDEO
              ? data?.contact_phone
              : undefined,
        };

        if (!data?.id) {
          createAppointment(appointmentCreateUpdate, () => {
            handleFinish();
          }).finally(() => {
            if (isCurrent()) {
              setButtonsLoadingStatus((state) => ({
                ...state,
                confirm: false,
              }));
            }
          });
        } else {
          updateAppointment(appointmentCreateUpdate, () => {
            handleFinish();
          }).finally(() => {
            if (isCurrent()) {
              setButtonsLoadingStatus((state) => ({
                ...state,
                confirm: false,
              }));
            }
          });
        }
      }
    },
    [
      appointmentSelected,
      isBulk,
      bulkList,
      bulkAvailability,
      handleFinish,
      handleValidate,
      createAppointment,
      updateAppointment,
    ],
  );

  const handleBack = useCallback(() => {
    if (back) {
      back();
    }
  }, [back]);

  const handleCancel = useCallback(() => {
    closeModal();
    cleanLocal();
  }, [cleanLocal, closeModal]);

  return (
    <>
      <Container>
        <Grouper>
          <h1>
            {appointmentSelected?.type === AppointmentTypeEnum.PHONE && (
              <>
                {flow === 'create' ? 'Agendar' : 'Editar'} Ligação <PhoneIcon />
              </>
            )}
            {appointmentSelected?.type === AppointmentTypeEnum.VIDEO && (
              <>
                {flow === 'create' ? 'Agendar' : 'Editar'} Vídeochamada{' '}
                <FilmIcon />
              </>
            )}
            {appointmentSelected?.type === AppointmentTypeEnum.SMS &&
              'Enviar Mensagem'}

            {isBulk && <span>(Em massa)</span>}
          </h1>
          <p>Confira os detalhes do agendamento antes de confirmar:</p>

          {isBulk ? (
            <ReviewCard isBulk={isBulk}>
              <Row>
                <StethoscopeIcon />
                <strong>Profissional:</strong>&nbsp;
                {appointmentSelected?.professional?.user?.name}
              </Row>
              <Row>
                <PersonIcon />
                <strong>Paciente{bulkList.length > 1 && 's'}:</strong>&nbsp;
                {bulkList.length}
              </Row>
              <Row>
                <DatePickerIcon />
                <strong>Data:</strong>&nbsp;
                {appointmentDate}
              </Row>
            </ReviewCard>
          ) : (
            <ReviewCard isBulk={false}>
              <Row>
                <DatePickerIcon />
                <strong>Data:</strong>&nbsp;
                {appointmentDate}
                {flow === 'edit' && (
                  <EditButton
                    onClick={() => goTo && goTo('ScheduleAppointment')}
                  >
                    Editar
                  </EditButton>
                )}
              </Row>
              <Row>
                <ClockIcon />
                <strong>Hora:</strong>&nbsp;{appointmentHours}
                {flow === 'edit' && (
                  <EditButton
                    onClick={() => goTo && goTo('ScheduleAppointment')}
                  >
                    Editar
                  </EditButton>
                )}
              </Row>
              {appointmentSelected?.type !== AppointmentTypeEnum.SMS && (
                <Row>
                  <StethoscopeIcon />
                  <strong>Profissional:</strong>&nbsp;
                  {appointmentSelected?.professional?.user?.name}
                  {flow === 'edit' && (
                    <EditButton
                      onClick={() =>
                        goTo && goTo('ScheduleContactProfessional')
                      }
                    >
                      Editar
                    </EditButton>
                  )}
                </Row>
              )}
              <Row>
                <PersonIcon />
                <strong>Paciente:</strong>&nbsp;
                {appointmentSelected?.patient?.user?.name}
                {flow === 'edit' && (
                  <EditButton
                    onClick={() => goTo && goTo('ScheduleContactPatient')}
                  >
                    Editar
                  </EditButton>
                )}
              </Row>
              <Row>
                {appointmentSelected?.type === AppointmentTypeEnum.PHONE && (
                  <>
                    <PhoneIcon />
                    <strong>Telefone:</strong>&nbsp;
                    {appointmentSelected?.contact_phone}
                    {flow === 'edit' && (
                      <EditButton
                        onClick={() => goTo && goTo('ScheduleAppointment')}
                      >
                        Editar
                      </EditButton>
                    )}
                  </>
                )}
              </Row>
            </ReviewCard>
          )}
        </Grouper>

        <Grouper>
          {appointmentSelected?.type === AppointmentTypeEnum.PHONE && (
            <img src={CloudPhonecallManImage} alt="" />
          )}
          {appointmentSelected?.type === AppointmentTypeEnum.VIDEO && (
            <img src={VideoFilesImage} alt="" />
          )}

          {appointmentSelected?.type === AppointmentTypeEnum.SMS && (
            <Form ref={formRef} onSubmit={() => {}}>
              <section>
                <p>Telefone</p>
                <InputMaskAlternative
                  name="phone"
                  title=""
                  mask="(99) 9 9999-9999"
                  defaultValue={appointmentSelected.contact_phone}
                  disabled={flow === 'create'}
                />
                <p>Mensagem (até 150 caracteres)</p>
                <InputTextArea
                  name="text"
                  defaultValue={appointmentSelected.message?.text}
                  disabled={flow === 'create'}
                />
              </section>
            </Form>
          )}
        </Grouper>
      </Container>

      <ButtonsContainer>
        <Button
          disabled={
            isThereAButtonLoading &&
            (buttonsLoadingStatus.back || buttonsLoadingStatus.confirm)
          }
          buttonType="secondary"
          onClick={handleCancel}
        >
          Cancelar
        </Button>
        {flow === 'create' && (
          <Button
            disabled={
              isThereAButtonLoading &&
              (buttonsLoadingStatus.cancel || buttonsLoadingStatus.confirm)
            }
            buttonType="primary-alternative"
            onClick={handleBack}
          >
            Voltar
          </Button>
        )}
        <Button
          loading={buttonsLoadingStatus.confirm}
          onClick={handleSubmit}
          buttonType="primary"
        >
          Confirmar
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default ReviewAppointment;
