import styled from 'styled-components';

interface OptionProps {
  selected?: boolean;
}

interface StatusItemTableProps {
  color?: string;
}

export const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 45px 45px 7px 45px;
`;

export const Grouper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > form {
    margin-top: 40px;
  }

  &:last-child {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    > img {
      margin-right: 90px;
    }
  }

  > h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-top: 25px;
  }

  > p {
    color: ${(props) => props.theme.colors.grayAlternative};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    padding: 12px 0 0 0;
  }

  > section:first-of-type {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 60%;

    > strong {
      flex: 2;
      color: ${(props) => props.theme.colors.graySecondary};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      white-space: nowrap;
    }

    > form {
      flex: 7;
    }
  }

  > section:last-of-type {
    margin-top: 45px;
  }

  > img {
    align-items: center;
    margin-left: 80px;
    max-width: 276px;
  }

  > input {
    margin-top: 25px;
  }
`;

export const OptionsGrouper = styled.div`
  display: flex;
  margin-top: 28px;
`;

export const Option = styled.button<OptionProps>`
  background: none;
  border: none;
  border-bottom: 1px solid
    ${(props) => (props.selected ? '#1f80b7' : props.theme.colors.gray)};
  color: ${(props) => (props.selected ? '#1f80b7' : props.theme.colors.gray)};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 'bold' : '500')};
  line-height: 19px;
  padding-bottom: 7px;
  text-align: start;
  width: 82px;

  + button {
    margin-left: 11px;
  }
`;

export const InputTitle = styled.h3`
  display: flex;
  color: #707683;
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 18px;
  margin: 30px 0 20px !important;

  > button {
    color: ${(props) => props.theme.colors.primary};
    background: rgba(45, 156, 219, 0.1);
    border: none;
    border-radius: 100px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    padding: 10px 20px;
    text-align: center;
    white-space: nowrap;
  }
`;

export const MessageTable = styled.div`
  > header {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    background: rgba(189, 189, 189, 0.2);
    border-radius: 4px 4px 0 0;
    padding: 5px 15px 5px 10px;

    > h3 {
      color: #707683;
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
      line-height: 18px;
      margin: 0 10px;
    }
  }

  > div {
    max-height: 300px;
    overflow-y: auto;

    > section {
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
      background: #fdfdfd;
      color: #707683;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      padding: 5px 10px;

      > div {
        display: flex;
        align-items: center;
        margin: 0 10px;
        padding: 20px 0;

        :last-of-type {
          justify-content: center;
        }

        > span {
          color: #707683;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
        }

        > button {
          background: 0;
          border: 0;
          color: ${(props) => props.theme.colors.primary};
          font-size: 12px;
          font-style: normal;
          font-weight: bold;
          line-height: 16px;
          padding: 0;
          text-align: center;
        }
      }

      > div > p {
        color: #707683;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding: 0;
      }
    }

    > section + section {
      border-top: 1px solid #eaeaea;
    }
  }
`;

export const StatusItemTable = styled.div<StatusItemTableProps>`
  color: ${(props) => props.color ?? props.theme.colors.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-top: 10px;
  text-transform: uppercase;
`;
