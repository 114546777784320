import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { IoMdSearch } from 'react-icons/io';
import { format, addHours } from 'date-fns';

import { validateUserDataService } from '../../../../services/user';
import getUF from '../../../../utils/getUF';
import getValidationErrors from '../../../../utils/getValidationErrors';
import validationCPF from '../../../../utils/validationCPF';
import { useUser } from '../../../../hooks/user';
import useCep from '../../../../hooks/cep';
import { getProfiles } from '../../../../services/profile';
import { usePermission } from '../../../../hooks/permission';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../../../lib/use-current-effect';

import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import InputMaskAlternative from '../../../../components/InputMaskAlternative';
import RequiredFieldInformation from '../../../../components/RequiredFieldInformation';
import InputWithoutTitleAndFormattedToLower from '../../../../components/InputWithoutTitleAndFormattedToLower';

import {
  ComponentsUserRegisterEditProps,
  ComponentsUserRegisterEditRef,
} from '..';

import { Container, Field, GridContent, InputSelect } from './styles';

export interface PersonalDataProps {
  username?: string;
  email?: string;
  name?: string;
  profileType?: string;
  cpf?: string;
  birthday?: string;
  phone?: string;
  gender?: 'M' | 'F' | 'ND';
  neighborhood?: string;
  street?: string;
  number?: number;
  complement?: string;
  city?: string;
  cep?: string;
  addressUf?: string;
}

type InputOptions = {
  label: string;
  value: string;
};
const PersonalData: React.ForwardRefRenderFunction<
  ComponentsUserRegisterEditRef,
  ComponentsUserRegisterEditProps
> = ({ onSaveSucceed }, ref) => {
  const { hasPermission } = usePermission();
  const { saveLocal, save, user, cleanLocal } = useUser();
  const { getAddressData, addressData, error } = useCep();

  const formRef = useRef<FormHandles>(null);
  const [ufProps] = useState(getUF);

  const canChangeProfile = useMemo(
    () => !user?.id || hasPermission('edit_profile'),
    [hasPermission, user],
  );

  const genders = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' },
    { value: 'ND', label: 'Não declarar' },
  ];

  const [profiles, setProfiles] = useState<InputOptions[]>([
    {
      value: user?.profile?.type ? user.profile.type : '',
      label: user?.profile?.title ? user.profile.title : '',
    },
  ]);

  useCurrentEffect((isCurrent) => {
    getProfiles().then((resp) => {
      const selectableProfiles =
        resp
          .filter(({ type }) => type !== 'patient')
          .map(({ type, title }) => ({
            value: type ?? '',
            label: title ?? '',
          })) ?? [];

      isCurrent() && setProfiles(selectableProfiles);
    });
  }, []);
  useEffect(() => {
    if (user) {
      formRef.current?.setData({
        username: user.draft?.username ?? user.username,
        email: user.draft?.email ?? user.email,
        name: user.name,
        cpf: user.cpf,
        birthday: user.birthday
          ? format(addHours(new Date(user?.birthday), 3), 'yyyy-MM-dd')
          : undefined,
        phone: user.phone,
        gender: user.gender,
        street: user.address?.street,
        number: user.address?.number,
        complement: user.address?.complement,
        cep: user.address?.cep,
        city: user.address?.city,
        neighborhood: user.address?.neighborhood,
        addressUf: user.address?.uf,
      } as PersonalDataProps);
    }
  }, [user]);

  const getValidate = useCallback(async () => {
    const data: PersonalDataProps | undefined = formRef?.current?.getData();

    if (data?.phone && data?.phone.indexOf('_') !== 1) {
      data.phone = data.phone.replace('_', '');
    }

    if (data?.birthday && data?.birthday.indexOf('_') !== 1) {
      data.birthday = data.birthday.replace('_', '');
    }

    if (data?.username) {
      data.username = data?.username?.trim().toLowerCase();
    }

    if (data?.email) {
      data.email = data?.email?.trim().toLowerCase();
    }

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        username: Yup.string()
          .required('O nome de usuário é obrigatório')
          .test(
            'space',
            'Digite um username sem espaço',
            (value) => value.indexOf(' ') < 0,
          ),
        email: Yup.string()
          .required('O e-mail é obrigatório')
          .email('Digite um email válido'),
        name: Yup.string()
          .required('O nome é obrigatório')
          .test(
            'nome',
            'O nome deve ter pelo menos duas palavras',
            (value: string) => {
              const words = value.trim().split(' ');
              return words.length > 1 && words.every((i) => i);
            },
          ),
        cpf: Yup.string()
          .required('O CPF é obrigatório')
          .length(14, 'Digite um CPF válido')
          .test('cpf', 'Digite um CPF válido', (value) => validationCPF(value)),
        birthday: Yup.string().required('A data de nascimento é obrigatória'),
        phone: Yup.string()
          .required('O número de telefone é obrigatório')
          .length(16, 'Digite um número de telefone válido'),
        street: Yup.string(),
        profileType: Yup.string().required(
          'O perfil de permissão do usuário é obrigatório',
        ),
        number: Yup.string(),
        complement: Yup.string(),
        cep: Yup.string().test(
          'length',
          'Digite um CEP válido',
          (value) => !!(!value || value.length === 9),
        ),
        city: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await validateUserDataService({
        id: user?.id,
        username: data?.username,
        email: data?.email,
        cpf: data?.cpf,
        phone: data?.phone,
      });

      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      } else if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }

      return false;
    }
  }, [user]);

  const getData = useCallback(() => {
    const data: PersonalDataProps | undefined = formRef.current?.getData();

    return {
      localSensitiveData: {
        email: data?.email?.trim().toLowerCase(),
        username: data?.username?.trim().toLowerCase(),
        profileType: data?.profileType,
      },
    };
  }, []);

  const handleNext = useCurrentCallback(
    (isCurrent) => async () => {
      if (isCurrent()) {
        const data: PersonalDataProps | undefined = formRef?.current?.getData();

        saveLocal({
          username: data?.username?.trim().toLowerCase(),
          email: data?.email?.trim().toLowerCase(),
          name: data?.name,
          profile: {
            ...user?.profile,
            title: profiles.find(({ value }) => value === data?.profileType)
              ?.label,
            type: data?.profileType,
          },
          cpf: data?.cpf,
          birthday: data?.birthday,
          phone: data?.phone,
          gender: data?.gender,
          address: user?.address
            ? {
                ...user?.address,
                cep: data?.cep,
                city: data?.city,
                complement: data?.complement,
                neighborhood: data?.neighborhood,
                number: data?.number,
                street: data?.street,
                uf: data?.addressUf,
              }
            : {
                cep: data?.cep,
                city: data?.city,
                complement: data?.complement,
                neighborhood: data?.neighborhood,
                number: data?.number,
                street: data?.street,
                uf: data?.addressUf,
              },
        });
        return true;
      }

      return false;
    },
    [profiles, saveLocal, user],
  );

  const handleSaveUser = useCurrentCallback(
    (isCurrent) => async () => {
      const data: PersonalDataProps | undefined = formRef?.current?.getData();

      if (data?.username) {
        data.username = data.username.trim();
      }

      try {
        if (isCurrent()) {
          const didSave = await save({
            username: data?.username?.trim().toLowerCase(),
            email: data?.email?.trim().toLowerCase(),
            name: data?.name,
            profile: {
              ...user?.profile,
              title: profiles.find(({ value }) => value === data?.profileType)
                ?.label,
              type: data?.profileType,
            },
            cpf: data?.cpf,
            birthday: data?.birthday,
            phone: data?.phone,
            gender: data?.gender,
            address: user?.address
              ? {
                  ...user?.address,
                  cep: data?.cep,
                  city: data?.city,
                  complement: data?.complement,
                  neighborhood: data?.neighborhood,
                  number: data?.number,
                  street: data?.street,
                  uf: data?.addressUf,
                }
              : {
                  cep: data?.cep,
                  city: data?.city,
                  complement: data?.complement,
                  neighborhood: data?.neighborhood,
                  number: data?.number,
                  street: data?.street,
                  uf: data?.addressUf,
                },
          });

          return didSave;
        }

        return false;
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }

      return false;
    },
    [profiles, save, user],
  );

  const handleSave = useCallback(async () => {
    if (await handleSaveUser()) {
      onSaveSucceed && onSaveSucceed();
      return true;
    }

    return undefined;
  }, [handleSaveUser, onSaveSucceed]);

  const handleCancel = useCallback(async () => {
    cleanLocal();
    return true;
  }, [cleanLocal]);

  const handleCepSearch = useCallback(async () => {
    await getAddressData(formRef.current?.getFieldValue('cep'));
  }, [getAddressData]);

  useEffect(() => {
    if (addressData) {
      formRef.current?.setFieldValue('street', addressData.street);
      formRef.current?.setFieldValue('neighborhood', addressData.neighborhood);
      formRef.current?.setFieldValue('city', addressData.city);
      formRef.current?.setFieldValue('addressUf', addressData.uf);
    }
  }, [addressData]);

  useEffect(() => {
    if (error) {
      formRef.current?.clearField('street');
      formRef.current?.clearField('neighborhood');
      formRef.current?.clearField('city');
      formRef.current?.setFieldValue('addressUf', '');

      toast.error(error);
    }
  }, [error]);

  useImperativeHandle(ref, () => ({
    back: async () => true,
    cancel: handleCancel,
    next: handleNext,
    save: handleSave,
    finish: handleSaveUser,
    getData,
    canSave: getValidate,
  }));

  const initialData = {
    gender: user?.gender,
    profileType: profiles.find(({ value }) => value === user?.profile?.type)
      ?.value,
    addressUf: user?.address?.uf,
  };

  return (
    <Container>
      <h1>Dados Pessoais</h1>
      <Form ref={formRef} onSubmit={() => {}} initialData={initialData}>
        <GridContent>
          <Field gridArea="lu">
            <label>
              Login do usuário
              <RequiredFieldInformation />
            </label>
            <InputWithoutTitleAndFormattedToLower name="username" />
          </Field>
          <Field gridArea="em">
            <label>
              E-mail
              <RequiredFieldInformation />
            </label>
            <InputWithoutTitleAndFormattedToLower name="email" />
          </Field>
          <Field gridArea="no">
            <label>
              Nome
              <RequiredFieldInformation />
            </label>
            <InputWithoutTitle name="name" />
          </Field>
          <Field gridArea="pu">
            <label>
              Perfil de permissões do usuário
              <RequiredFieldInformation />
            </label>
            <InputSelect
              name="profileType"
              options={profiles}
              placeholder=""
              disabled={!canChangeProfile}
            />
          </Field>
          <Field gridArea="cp">
            <label>
              CPF <RequiredFieldInformation />
            </label>
            <InputMaskAlternative
              mask="999.999.999-99"
              title=""
              placeholder="000.000.000-00"
              type="text"
              name="cpf"
            />
          </Field>
          <Field gridArea="dn">
            <label>
              Data de Nascimento <RequiredFieldInformation />
            </label>
            <InputWithoutTitle
              className="datepicker"
              type="date"
              name="birthday"
              max={new Date().toISOString().split('T')[0]}
            />
          </Field>
          <Field gridArea="cl">
            <label>
              Celular (DDD + Número) <RequiredFieldInformation />
            </label>
            <InputMaskAlternative
              type="text"
              name="phone"
              title=""
              mask="(99) 9 9999-9999"
              placeholder="(00) 0 0000-0000"
            />
          </Field>
          <Field gridArea="gn">
            <label>Gênero</label>
            <InputSelect name="gender" options={genders} placeholder="" />
          </Field>
          <Field gridArea="ce">
            <label>CEP</label>
            <div className="cep">
              <InputMaskAlternative
                mask="99999-999"
                placeholder="00000-000"
                title=""
                type="text"
                name="cep"
              />
              <button type="button" onClick={handleCepSearch}>
                <IoMdSearch />
              </button>
            </div>
          </Field>
          <Field gridArea="ed">
            <label>Endereço</label>
            <InputWithoutTitle name="street" />
          </Field>
          <Field gridArea="nm">
            <label>Número</label>
            <InputWithoutTitle name="number" type="number" />
          </Field>
          <Field gridArea="cm">
            <label>Complemento</label>
            <InputWithoutTitle name="complement" />
          </Field>
          <Field gridArea="ba">
            <label>Bairro</label>
            <InputWithoutTitle name="neighborhood" />
          </Field>
          <Field gridArea="cd">
            <label>Cidade</label>
            <InputWithoutTitle name="city" />
          </Field>
          <Field gridArea="uf">
            <label>Estado</label>
            <InputSelect name="addressUf" options={ufProps} placeholder="" />
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default forwardRef(PersonalData);
