import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import OneSignal from 'react-onesignal';

import { useAuth } from './auth';

interface PushNotificationContextData {
  enablePushNotifications(): void;
}

const PushNotificationContext = createContext<PushNotificationContextData>(
  {} as PushNotificationContextData,
);

export const PushNotificationProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        OneSignal.init({
          appId: process.env.REACT_APP_PUSH_NOTIFICATION_APP_ID || '',
        }).then(() => {
          if (user) {
            OneSignal.setExternalUserId(user.professional_id);
          } else {
            OneSignal.removeExternalUserId();
          }
        });
      }
    }
  }, [user]);

  const enablePushNotifications = useCallback(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_PUSH_NOTIFICATION_APP_ID || '',
    }).then(() => {
      OneSignal.setExternalUserId(user?.professional_id);
    });
  }, [user]);

  return (
    <PushNotificationContext.Provider
      value={{
        enablePushNotifications,
      }}
    >
      {children}
    </PushNotificationContext.Provider>
  );
};

export const usePushNotification = (): PushNotificationContextData => {
  const context = useContext(PushNotificationContext);

  if (!context) {
    throw new Error(
      'usePushNotification must be used within a SignatureProvider',
    );
  }

  return context;
};
