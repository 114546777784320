import { api } from '../api';
import { User } from '../../entities/User';
import AppointmentTypeEnum from '../../enums/AppointmentTypeEnum';

export interface UsersFilterProps {
  cpf?: string;
  name?: string;
  email?: string;
  type: 'generic' | 'patient' | 'professional';
  occupationArea?: string;
  appointmentType?: AppointmentTypeEnum;
}

export const getUsersByFilter = async (
  userData: UsersFilterProps,
): Promise<User[]> => {
  const users = await api.get('users/filterby/type/parameters', {
    params: { ...userData },
  });

  return users.data.isValid;
};
