import React, { useMemo, useState } from 'react';

import { useCurrentCallback } from '../../lib/use-current-effect';

import Confirm from './screens/Confirm';
import Success from './screens/Success';
import Error from './screens/Error';

import { Container } from './styles';
import AppointmentTypeEnum from '../../enums/AppointmentTypeEnum';
import CustomErrorEnums from '../../enums/CustomErrorsEnum';

interface AppointmentItem {
  date?: string | Date;
  type?: AppointmentTypeEnum;
  patientPhone?: string;
  patientName?: string;
  professionalName?: string;
}

export interface RunAppointmentNewTabModalStepsProps {
  appointment: AppointmentItem;
}

interface RunAppointmentNewTabModalProps
  extends RunAppointmentNewTabModalStepsProps {
  onConfirm?: () => Promise<void>;
  onClose?: () => void;
}

type Steps = {
  [key in 'confirm' | 'error' | 'success']: JSX.Element;
};

const RunAppointmentNewTabModal: React.FC<RunAppointmentNewTabModalProps> = ({
  appointment,
  onConfirm,
  onClose,
}) => {
  const [selected, setSelected] = useState<keyof Steps>('confirm');

  const [errorKey, setErrorKey] = useState<CustomErrorEnums>();

  const handleNext = useCurrentCallback(
    (isCurrent) => async () => {
      try {
        if (onConfirm) {
          await onConfirm();
        }

        if (isCurrent()) {
          setSelected('success');
        }
      } catch (error) {
        if (error?.response?.data?.status) {
          setErrorKey(error.response.data.status);
        }

        setSelected('error');
      }
    },
    [onConfirm],
  );

  const steps: Steps = useMemo(
    () => ({
      confirm: <Confirm appointment={appointment} next={handleNext} />,
      success: <Success onClose={() => onClose && onClose()} />,
      error: <Error errorKey={errorKey} onClose={() => onClose && onClose()} />,
    }),
    [appointment, errorKey, handleNext, onClose],
  );

  return <Container>{steps[selected]}</Container>;
};

export default RunAppointmentNewTabModal;
