import styled, { css } from 'styled-components';

import ButtonStyle from '../../../../components/ButtonBlue';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import datePicker from '../../../../assets/images/date-picker.svg';

interface FieldProps {
  isButton?: boolean;
  gridArea: 'sn' | 'pn' | 'dt' | 'co' | 'an' | 'bt';
}

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  height: 260px;
  padding: 30px 25px 40px;

  margin-top: 25px;

  h1 {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template:
    repeat(2, 1fr) /
    minmax(0, 230px)
    repeat(3, 1fr)
    minmax(0, 230px);
  grid-template-areas:
    'sn sn pn pn dt'
    'co an an bt bt';
  grid-column-gap: 38px;
  grid-row-gap: 18px;
  height: 100%;
  width: 100%;
  margin-top: 22px;
`;

export const Field = styled.div<FieldProps>`
  grid-area: ${(props) => props.gridArea};

  .datepicker {
    color: ${(props) => props.theme.colors.graySecondary};
  }

  > label {
    color: #707683;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  > div {
    margin-top: 5px;
    max-height: 42px;

    input,
    input[type='date'] {
      font-size: 14px;
    }

    > input[type='date']::-webkit-calendar-picker-indicator {
      background: url(${datePicker}) no-repeat;
      background-size: 75%;
      color: rgba(0, 0, 0, 0);
      height: 24px;
      width: 24px;
    }
  }

  ${(props) =>
    props.isButton &&
    css`
      align-self: end;
      justify-self: end;
    `}

  > button svg {
    font-size: 18px;
    margin-right: 8px;
  }
`;

export const InputSelect = styled(InputSelectAlternative)`
  font-size: 14px;

  > div:first-child {
    min-height: 42px !important;
  }

  .react-select__control {
    min-height: 42px !important;
  }
`;

export const Button = styled(ButtonStyle)`
  height: 42px;
  width: 145px;
`;
