import styled from 'styled-components';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';

interface FieldProps {
  gridArea?:
    | 'pr'
    | 'em'
    | 'ca'
    | 'en'
    | 'nu'
    | 'co'
    | 'ba'
    | 'ce'
    | 'ci'
    | 'uf';
}

export const Container = styled.div`
  min-height: 500px;
  width: 100%;
  padding-right: 52px;

  > h1 {
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;

    color: #707683;
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(12, 1fr);
  grid-template-areas:
    'pr pr pr pr em em em em ca ca ca ca'
    'ce ce ce en en en en en en en nu nu'
    'co co co co co co ba ba ba ba ba ba'
    'ci ci ci ci ci ci uf uf -- -- -- --';

  grid-column-gap: 45px;
  grid-row-gap: 35px;

  margin-top: 28px;
  width: 100%;
  height: 100%;

  @media (max-width: 1100px) {
    grid-template: repeat(4, 1fr) / repeat(12, 1fr);
    grid-template-areas:
      'pr pr pr pr em em em em ca ca ca ca'
      'ce ce ce en en en en en en en nu nu'
      'co co co co co co ba ba ba ba ba ba'
      'ci ci ci ci ci ci uf uf uf uf uf uf';
  }
`;

export const Field = styled.div<FieldProps>`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  flex-direction: column;

  > label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.01em;

    color: #707683;
  }

  input {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 14px;
  }

  > div {
    margin-top: 4px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .cep {
    display: flex;

    button {
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.colors.primary};
      background-color: transparent;
      border: none;
      font-size: 32px;
      margin-left: 10px;
    }
  }
`;

export const InputSelect = styled(InputSelectAlternative)`
  > div:first-child {
    border: 1px solid ${(props) => props.theme.colors.gray2};
    border-width: 1px !important;
  }

  > div .react-select__control {
    border-width: 1px;
  }
`;
