import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 780px;
  height: 450px;
  width: 100%;
  padding: 0 40px;

  > section {
    display: flex;

    > div:nth-child(2) {
      margin-left: 25px;

      > h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 130%;
        color: #2d9cdb;
      }

      > h2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #4f4f4f;
        padding: 0;
        margin-top: 20px;

        > strong {
          font-weight: bold;
        }
      }

      > footer {
        display: flex;
        margin-top: 40px;
        width: 100%;
        justify-content: flex-end;

        > button + button {
          margin-left: 20px;
        }
      }
    }
  }
`;
