import React, { useEffect, useState } from 'react';
import { Container, PaginationButton } from './styles';

interface PaginationProps {
  totalPages: number;
  onChangePage?: (newPage: number) => any;
  page?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  onChangePage,
  page,
}) => {
  const [paginationPage, setPaginationPage] = useState(page ?? 1);
  const [showPages, setShowPages] = useState([1]);

  useEffect(() => {
    if (page) {
      setPaginationPage(page);
    }
  }, [page]);

  useEffect(() => {
    let newPageList = Array.from(
      { length: totalPages },
      (_, index) => index + 1,
    );

    let numberBefore = 2;
    let numberAfter = 2;

    if (paginationPage <= numberBefore) {
      numberBefore = paginationPage - 1;
      numberAfter = 5 - paginationPage;
    } else if (paginationPage > totalPages - numberAfter) {
      numberAfter = totalPages - paginationPage;
      numberBefore = 4 - numberAfter;
    }

    Array.from({ length: totalPages }, (_, index) => index + 1).forEach(
      (item) => {
        if (
          item < paginationPage - numberBefore ||
          item > paginationPage + numberAfter
        ) {
          newPageList = newPageList.filter((itemList) => itemList !== item);
        }
      },
    );

    if (newPageList.length === 0) {
      newPageList = [1];
    }

    setShowPages([...newPageList]);
    if (onChangePage) {
      onChangePage(paginationPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationPage, totalPages]);

  return (
    <Container>
      <PaginationButton
        onClick={() => setPaginationPage(1)}
        disabled={paginationPage === 1}
      >
        {'<<'}
      </PaginationButton>
      <PaginationButton
        onClick={() => setPaginationPage((state) => state - 1)}
        disabled={paginationPage === 1}
      >
        {'<'}
      </PaginationButton>

      {showPages.map((item) => (
        <PaginationButton
          onClick={() => item !== paginationPage && setPaginationPage(item)}
          selected={item === paginationPage}
          key={item}
        >
          {item}
        </PaginationButton>
      ))}
      <PaginationButton
        onClick={() => setPaginationPage((state) => state + 1)}
        disabled={totalPages === paginationPage}
      >
        {'>'}
      </PaginationButton>
      <PaginationButton
        onClick={() => setPaginationPage(totalPages)}
        disabled={totalPages === paginationPage}
      >
        {'>>'}
      </PaginationButton>
    </Container>
  );
};

export default Pagination;
