import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface LinkProps {
  disabled?: boolean;
}

export const Container = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  letter-spacing: 2px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.grayAlternative};
`;

export const LinkTree = styled(Link)<LinkProps>`
  text-decoration: none;
  color: ${(props) => props.theme.colors.grayAlternative};

  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.disabled &&
    css`
      &:hover {
        text-decoration: none;
      }

      cursor: default;
    `}
`;

export const SpaceTree = styled.span`
  padding: 0 3px;

  &::after {
    content: '>';
  }
`;
