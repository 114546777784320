import styled from 'styled-components';

interface ProfileType {
  isSelected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  > h1 {
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

    color: #707683;
  }
`;

export const OptionCard = styled.div<ProfileType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  width: 166px;
  height: 166px;

  margin-top: 30px;

  background: ${({ isSelected }) => (isSelected ? '#DAEDF7' : '#f2f2f2')};
  border-radius: 6px;

  > span {
    margin-top: 8px;

    max-width: 100px;

    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 500)};
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${({ isSelected }) => (isSelected ? '#1F80B7' : '#707683')};
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: row;
  place-items: center;

  > div + div {
    margin-left: 22px;
  }
`;
