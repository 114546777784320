import React from 'react';

import { Container } from '../styles';

interface NewPatientsOfTheMonth {
  newPatientsMonth: number;
}

const NewPatientsOfTheMonth: React.FC<NewPatientsOfTheMonth> = ({
  newPatientsMonth,
}) => {
  return (
    <Container gridArea="np">
      <span>{`+ ${newPatientsMonth}`}</span>
      <label>Novos no mês</label>
    </Container>
  );
};

export default NewPatientsOfTheMonth;
