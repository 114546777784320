import React, { useMemo, useReducer } from 'react';

import {
  OptionsContainer,
  CollapseButton,
  Container,
  ListButton,
  Separator,
} from './styles';

import sidebarCollapse from '../../../assets/images/collapse.svg';

import sidebarHome from '../../../assets/images/home.svg';
import sidebarHomeActivated from '../../../assets/images/home-activated.svg';
import sidebarPatients from '../../../assets/images/patients.svg';
import sidebarPatientsActivated from '../../../assets/images/patients-activated.svg';
import sidebarSchedule from '../../../assets/images/schedule.svg';
import sidebarScheduleActivated from '../../../assets/images/schedule-activated.svg';
import sidebarReports from '../../../assets/images/report.svg';
import sidebarReportsActivated from '../../../assets/images/report-activated.svg';
import sidebarManagement from '../../../assets/images/management.svg';
import sidebarManagementActivated from '../../../assets/images/management-activated.svg';

import sidebarExit from '../../../assets/images/exit.svg';

import { usePermission } from '../../../hooks/permission';
import { useAuth } from '../../../hooks/auth';
import history from '../../../services/history';
import MainAreasEnum from '../../../enums/MainAreasEnum';
import SubMenu from '../SubMenu';

interface SidebarProps {
  selected?: string;
  isMobile: boolean;
  closeSidebar: (value: boolean) => void;
}

interface SidebarOption {
  section?: MainAreasEnum;
  action: () => void;
  image: string;
  title: string;
  permission?: boolean;
}

interface SidebarOptions {
  toggle: SidebarOption;
  areas: SidebarOption[];
  quit: SidebarOption;
}

type MainAreasWithSubMenu = Exclude<
  MainAreasEnum,
  MainAreasEnum.APPOINTMENTS | MainAreasEnum.HOME
>;

interface SubMenuOption {
  route: string;
  permission: boolean;
  title: string;
}

export interface SubMenuContent {
  label: string;
  options?: SubMenuOption[];
}

type State = {
  toggleSidebar: boolean;
  toggleSubMenu?: MainAreasWithSubMenu;
};

type Action = 'toggle' | MainAreasWithSubMenu | 'reset';

const handleSidebarBehavior = (state: State, action: Action): State => {
  const newState = { ...state };

  if (action === 'reset') {
    newState.toggleSidebar = false;
    newState.toggleSubMenu = undefined;
  } else if (action === 'toggle') {
    newState.toggleSidebar = !newState.toggleSidebar;
    newState.toggleSubMenu = undefined;
  } else {
    newState.toggleSidebar = true;
    newState.toggleSubMenu =
      action === newState.toggleSubMenu ? undefined : action;
  }

  return newState;
};

const Sidebar: React.FC<SidebarProps> = ({
  selected,
  isMobile,
  closeSidebar,
}) => {
  const { hasPermission } = usePermission();
  const { signOut } = useAuth();

  const [sidebar, setSidebar] = useReducer(handleSidebarBehavior, {
    toggleSidebar: !!isMobile,
  });

  const sidebarOptions = useMemo<SidebarOptions>(
    () => ({
      toggle: {
        action: () => {
          if (!sidebar.toggleSidebar) {
            setSidebar('toggle');
          } else {
            setSidebar('reset');

            if (isMobile) {
              closeSidebar(false);
            }
          }
        },
        image: sidebarCollapse,
        title: 'recolher',
      },
      areas: [
        {
          section: MainAreasEnum.HOME,
          action: () => history.push('/dashboard/profile'),
          image:
            selected === MainAreasEnum.HOME
              ? sidebarHomeActivated
              : sidebarHome,
          title: 'Início',
        },
        {
          section: MainAreasEnum.PATIENTS,
          action: () => setSidebar(MainAreasEnum.PATIENTS),
          image:
            selected === MainAreasEnum.PATIENTS
              ? sidebarPatientsActivated
              : sidebarPatients,
          title: 'Pacientes',
        },
        {
          section: MainAreasEnum.APPOINTMENTS,
          action: () => history.push('/appointments/list'),
          image:
            selected === MainAreasEnum.APPOINTMENTS
              ? sidebarScheduleActivated
              : sidebarSchedule,
          title: 'Agenda',
          permission: hasPermission('list_of_appointments'),
        },
        {
          section: MainAreasEnum.REPORTS,
          action: () => setSidebar(MainAreasEnum.REPORTS),
          image:
            selected === MainAreasEnum.REPORTS
              ? sidebarReportsActivated
              : sidebarReports,
          title: 'Relatórios',
        },
        {
          section: MainAreasEnum.MANAGEMENT,
          action: () => setSidebar(MainAreasEnum.MANAGEMENT),
          image:
            selected === MainAreasEnum.MANAGEMENT
              ? sidebarManagementActivated
              : sidebarManagement,
          title: 'Administração',
        },
      ],
      quit: {
        action: () => signOut(),
        image: sidebarExit,
        title: 'Sair',
      },
    }),
    [closeSidebar, hasPermission, isMobile, selected, sidebar, signOut],
  );

  const subMenuContent = useMemo<SubMenuContent | undefined>(() => {
    return sidebar.toggleSubMenu === MainAreasEnum.PATIENTS
      ? {
          label: 'Pacientes',
          options: [
            {
              route: '/patient/register',
              permission: hasPermission('list_of_patients'),
              title: 'Novo Paciente',
            },
            {
              route: '/patient/list',
              permission: hasPermission('list_of_patients'),
              title: 'Filtrar',
            },
            {
              route: '.',
              permission: false,
              title: 'Membros Vinculados',
            },
          ],
        }
      : sidebar.toggleSubMenu === MainAreasEnum.REPORTS
      ? {
          label: 'Relatórios',
          options: [
            {
              route: '/reports/attendance/list',
              permission: hasPermission('attendance_report'),
              title: 'Atendimentos',
            },
            {
              route: '.',
              permission: false && hasPermission('device_report'),
              title: 'Dispositivos',
            },
            {
              route: '/reports/patients',
              permission: hasPermission('patient_report'),
              title: 'Pacientes',
            },
            {
              route: '.',
              permission: false && hasPermission('professional_report'),
              title: 'Profissionais',
            },
          ],
        }
      : sidebar.toggleSubMenu === MainAreasEnum.MANAGEMENT
      ? {
          label: 'Administração',
          options: [
            {
              route: '/dashboard/notices',
              permission: hasPermission('view_notices_dashboard'),
              title: 'Painel de monitoramento',
            },
            {
              route: '.',
              permission: false && hasPermission('list_comorbidities'),
              title: 'Condição de saúde',
            },
            {
              route: '/sheet/list',
              permission: hasPermission('list_health_sheets'),
              title: 'Fichas de saúde',
            },
            {
              route: '.',
              permission: false && hasPermission('list_devices'),
              title: 'Gestão de dispositivos',
            },
            {
              route: '.',
              permission: false && hasPermission('list_health_parameters'),
              title: 'Parâmetros de saúde',
            },
            {
              route: '/profile/list',
              permission: hasPermission('list_profiles'),
              title: 'Perfis e permissões',
            },
            {
              route: '/user/list',
              permission: hasPermission('list_of_users'),
              title: 'Usuários do sistema',
            },
            {
              route: '/institution/list',
              permission: hasPermission('list_institutions'),
              title: 'Instituições',
            },
            {
              route: '.',
              permission: false,
              title: 'Backup',
            },
            {
              route: '.',
              permission: false,
              title: 'NPS',
            },
          ],
        }
      : undefined;
  }, [hasPermission, sidebar]);

  return (
    <>
      <Container isExpanded={sidebar.toggleSidebar} isMobile={isMobile}>
        {isMobile && <h3>Menu</h3>}
        <OptionsContainer>
          <CollapseButton
            isExpanded={sidebar.toggleSidebar}
            isMobile={isMobile}
          >
            <button
              type="button"
              onClick={() => sidebarOptions.toggle.action()}
            >
              <img src={sidebarOptions.toggle.image} alt="Collapse" />
              <span>{sidebarOptions.toggle.title}</span>
            </button>
          </CollapseButton>
          {sidebarOptions.areas.map((area) => (
            <ListButton
              key={area.section}
              isExpanded={sidebar.toggleSidebar}
              isMobile={isMobile}
              activated={selected === area.section}
              disabled={area.permission !== undefined && !area.permission}
            >
              <button
                type="button"
                onClick={() => area.action()}
                disabled={area.permission !== undefined && !area.permission}
              >
                <img src={area.image} alt={area.title} />
                <span>{area.title}</span>
              </button>
            </ListButton>
          ))}
          <Separator isExpanded={sidebar.toggleSidebar} isMobile={isMobile} />
          <ListButton isExpanded={sidebar.toggleSidebar} isMobile={isMobile}>
            <button type="button" onClick={() => sidebarOptions.quit.action()}>
              <img
                src={sidebarOptions.quit.image}
                alt={sidebarOptions.quit.title}
              />
              <span>{sidebarOptions.quit.title}</span>
            </button>
          </ListButton>
        </OptionsContainer>
      </Container>
      <SubMenu isMobile={isMobile} subMenuContent={subMenuContent} />
    </>
  );
};

export default Sidebar;
