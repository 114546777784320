import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import VideoCall from '../../../components/VideoCall';

import CincoLogo from '../../../assets/images/cinco-logo.svg';

import { Container, VideoCallWrapper } from './styles';

const VideoRoom: React.FC = () => {
  const { search } = useLocation();
  const [roomName, setRoomName] = useState<string>();
  const [identity, setIdentity] = useState<string>();

  useEffect(() => {
    const url = new URLSearchParams(search);
    setRoomName(url.get('roomName') ?? undefined);
    setIdentity(url.get('identity') ?? undefined);
  }, [search]);

  return (
    <Container>
      {roomName && identity && (
        <>
          <VideoCallWrapper>
            <img src={CincoLogo} alt="" />
            <VideoCall roomName={roomName} identity={identity} />
          </VideoCallWrapper>
        </>
      )}
    </Container>
  );
};

export default VideoRoom;
