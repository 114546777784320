import { api } from '../api';
import { FilterPatientRiskDegreesDTO } from '../../dtos/FilterPatientRiskDegreesDTO';

interface GetRiskDegrees {
  patientId: string;
  limit: number;
}

export const getRiskDegrees = async (
  patientId: string,
  limit: number,
): Promise<FilterPatientRiskDegreesDTO[]> => {
  const { data } = await api.get(
    `/patients/riskDegree/filter?patientId=${patientId}`,
    {
      params: {
        limit,
      },
    },
  );

  return data;
};
