import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  min-height: 100vh;

  color: ${(props) => props.theme.colors.graySecondary};

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  > p {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;
  }
`;
