import styled from 'styled-components';
import {
  Container as GridCardContainer,
  CardTitle as GridCartTitle,
} from '../../../../components/GridCard/styles';

export const Container = styled(GridCardContainer)`
  flex-direction: column;
  justify-content: end;
  padding: 30px 0 15px 25px;
`;

export const CardTitle = styled(GridCartTitle)`
  flex: 7;
`;

export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  > button {
    margin-right: 25px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 15px;
  overflow-y: auto;
  padding: 5px 24px 30px 0;

  > div:last-of-type {
    border-bottom: none;
  }
`;

// export const Options = styled.div`
//   > button {
//     margin-right: 30px;
//     display: flex;
//     flex-direction: column;

//     background: transparent;
//     border: 0;

//     &:hover {
//       opacity: 0.8;
//     }
//   }
// `;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  padding: 12px 0;

  border-bottom: 1px solid #efefef;
`;

export const LeftSide = styled.div`
  flex: 5;

  display: flex;
  flex-direction: row;
  align-items: center;

  > p {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: #4f4f4f;

    margin-left: 14px;
  }
`;

export const RightSide = styled.div`
  flex: 3;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button {
    border: none;
    background: none;
    outline: none;

    display: flex;

    > :last-of-type {
      margin-right: 16px;
    }
  }

  > img {
    margin-right: 16px;
    width: 21px;
    height: 21px;
  }
`;
