import { UpdateSignatureRequestDTO } from '../../dtos/UpdateSignatureRequestDTO';
import { Signature } from '../../entities/Signature';
import { api } from '../api';

export const updateSignature = async ({
  id,
  status,
  type,
}: UpdateSignatureRequestDTO): Promise<Signature> => {
  const { data } = await api.put(`/signatures/${id}`, {
    status,
    type,
  });

  return data;
};
