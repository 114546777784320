import React, { useState } from 'react';
import Confirm from './screens/Confirm';
import Success from './screens/Success';

export interface JustifyAppointmentExclusionModalProps {
  appointmentId: string;
  date?: string | Date;
  patientName?: string;
  professionalName?: string;
  onSuccess?: (args?: any) => any;
}

type ScreensKeys = 'confirm' | 'success';

const JustifyAppointmentExclusionModal: React.FC<
  JustifyAppointmentExclusionModalProps
> = ({ onSuccess, ...rest }) => {
  const [selected, setSelected] = useState<ScreensKeys>('confirm');

  const screens = {
    confirm: (
      <Confirm
        onSuccess={() => {
          onSuccess && onSuccess();
          setSelected('success');
        }}
        {...rest}
      />
    ),
    success: <Success />,
  };

  return <>{screens[selected]}</>;
};

export default JustifyAppointmentExclusionModal;
