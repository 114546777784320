import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardTemplate from '../../../templates/Dashboard';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import { Profile } from '../../../entities/Profile';
import { usePermission } from '../../../hooks/permission';
import { getProfiles } from '../../../services/profile';
import { getAvatarImage } from '../../../services/user';
import Avatar from '../../../assets/images/avatar.svg';

import { useCurrentEffect } from '../../../lib/use-current-effect';

import {
  Container,
  ProfilesSection,
  ProfileList,
  ProfileItem,
  ProfileItemInformations,
  ProfileItemImages,
} from './styles';
import MainAreasEnum from '../../../enums/MainAreasEnum';

interface ImageList {
  id: string;
  profileId: string;
  avatar: string;
}

interface ProfileItem {
  id: string;
  name: string;
  quantity: string;
  images: {
    id: string;
    avatar: string;
  }[];
  link: string;
}

const List: React.FC = () => {
  const { hasPermission } = usePermission();
  const [list, setList] = useState<Profile[]>([]);
  const [imageList, setImageList] = useState<ImageList[]>([]);
  const history = useHistory();

  const canEditProfileItem = useMemo(
    () => hasPermission('edit_profile'),
    [hasPermission],
  );

  const pageTreeList = useMemo<ElementPageTree[]>(
    () => [{ name: 'ADMINISTRAÇÃO' }, { name: 'PERFIS E PERMISSÕES' }],
    [],
  );

  useCurrentEffect(
    (isCurrent) => {
      const userList = list.flatMap((profile) =>
        profile.users
          ? profile.users?.slice(0, 3).map((user) => ({
              id: user.id,
              profileId: profile.id,
              avatar: user.avatar,
            }))
          : [],
      );

      Promise.all(
        userList.map(async (user) => ({
          id: user.id ?? '',
          profileId: user.profileId ?? '',
          avatar: user.avatar ? await getAvatarImage(user.avatar) : Avatar,
        })),
      ).then((imageListResult) => {
        if (isCurrent()) {
          setImageList(imageListResult);
        }
      });
    },
    [list],
  );

  const listDisplay = useMemo<ProfileItem[]>(
    () =>
      list
        .map((item) => {
          let quantity = '';

          switch (Number(item.userCount)) {
            case 0:
              quantity = 'Nenhum usuário';
              break;
            case 1:
              quantity = `${Number(item.userCount)} usuário`;
              break;
            case undefined:
              quantity = 'Nenhum usuário';
              break;
            default:
              quantity = `${Number(item.userCount)} usuários`;
              break;
          }

          return {
            id: item.id ?? '',
            name: item.title ?? '',
            images: imageList
              .filter(({ profileId }) => profileId === item.id)
              .map(({ id, avatar }) => ({ id, avatar })),
            link: `/profile/${item.type}`,
            quantity,
          };
        })
        .filter((item) => item.name !== 'Paciente'),
    [list, imageList],
  );

  useCurrentEffect((isCurrent) => {
    getProfiles(3).then((resp) => {
      if (isCurrent()) {
        setList(resp);
      }
    });
  }, []);

  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <Container>
        <header>
          <PageTree pages={pageTreeList} />
        </header>
        <ProfilesSection>
          <h1>Perfis Ativos</h1>
          <ProfileList>
            {listDisplay.map((item) => (
              <ProfileItem key={item.id}>
                <ProfileItemInformations>
                  <h1>{item.name}</h1>
                  <h2>{item.quantity}</h2>
                  <button
                    type="button"
                    onClick={() => history.push(item.link)}
                    disabled={!canEditProfileItem}
                  >
                    Ver
                  </button>
                </ProfileItemInformations>
                <ProfileItemImages>
                  {item.images.map((image) => (
                    <img
                      key={image.id}
                      src={image.avatar}
                      alt="Foto do usuário"
                    />
                  ))}
                </ProfileItemImages>
              </ProfileItem>
            ))}
          </ProfileList>
        </ProfilesSection>
      </Container>
    </DashboardTemplate>
  );
};

export default List;
