import styled from 'styled-components';
import Calendar from 'react-calendar';

export const Container = styled.div``;
export const CalendarElement = styled(Calendar)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  button {
    border: 0;
  }

  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      display: flex;
      align-items: center;
      text-align: center;
      text-decoration: none;

      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      font-feature-settings: 'pnum' on, 'lnum' on;

      color: #000;

      mix-blend-mode: normal;
      opacity: 0.6;
    }
  }

  .react-calendar__month-view__days {
    margin-top: 12px;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    mix-blend-mode: normal;

    abbr {
      color: ${(props) => props.theme.colors.gray};
    }
  }

  .react-calendar__navigation__label {
    background: transparent;
  }

  .react-calendar__tile {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    width: 30px !important;
    height: 30px !important;

    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: ${(props) => props.theme.colors.primary};

      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;

      text-align: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
  }

  .react-calendar__tile:hover > * {
    background: #ccc;
    color: #fff;
  }

  .react-calendar__tile--active > *,
  .react-calendar__tile:active > * {
    background: ${(props) => props.theme.colors.primary} !important;
    color: #fff;
  }

  .react-calendar__navigation {
    margin-bottom: 25px;

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
      background: transparent;
      color: ${(props) => props.theme.colors.secondary};
    }

    .react-calendar__navigation__label {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      color: #4f4f4f;
    }
  }
`;
