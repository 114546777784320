import React, { useCallback, useState } from 'react';
import { useModal } from 'react-brave-modal';
import { toast } from 'react-toastify';
import Button from '../../components/Button';

import { usePatient } from '../../hooks/patient';
import { useCurrentCallback } from '../../lib/use-current-effect';

import { Container, ButtonGroup } from './styles';

interface RemoveHealthConditionModalProps {
  icd10PatientId: string;
}

const RemoveHealthConditionModal: React.FC<RemoveHealthConditionModalProps> = ({
  icd10PatientId,
}) => {
  const { removeHealthCondition } = usePatient();
  const { closeModal } = useModal();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleConfirm = useCurrentCallback(
    (isCurrent) => async () => {
      setIsButtonDisabled(true);
      try {
        await removeHealthCondition(icd10PatientId).then(
          () => isCurrent() && setIsButtonDisabled(false),
        );
      } catch (error) {
        if (isCurrent()) {
          setIsButtonDisabled(false);
        }

        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      } finally {
        closeModal();
      }
    },
    [icd10PatientId, closeModal, removeHealthCondition],
  );

  const handleCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Container>
      <h1>
        Confirma a desassociação do paciente à condição de saúde selecionada?
      </h1>
      <ButtonGroup>
        <Button
          loading={isButtonDisabled}
          onClick={handleConfirm}
          buttonType="primary"
        >
          Sim
        </Button>
        <Button
          buttonType="primary-alternative"
          color="primary"
          onClick={handleCancel}
        >
          Não
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default RemoveHealthConditionModal;
