import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { IoMdSearch } from 'react-icons/io';
import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';

import { Container, GridContent, Button, Field } from './styles';
import FilterInstitutionDTO from '../../../../dtos/FilterInstitutionDTO';
import getUF from '../../../../utils/getUF';

interface Options {
  value: string;
  label: string;
}

type Filters = Omit<FilterInstitutionDTO, 'limit' | 'page'>;

interface FilterProps {
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  onChangePage: (newPage: number) => any;
}

const Filter: React.FC<FilterProps> = ({ setFilters, onChangePage }) => {
  const formRef = useRef<FormHandles>(null);

  const [ufOptions] = useState(getUF);

  const statusOptions: Options[] = useMemo(
    () => [
      {
        value: '',
        label: 'Todos',
      },
      {
        value: 'active',
        label: 'Ativo',
      },
      {
        value: 'inactive',
        label: 'Inativo',
      },
    ],
    [],
  );

  const getValues = useCallback(() => {
    onChangePage(1);

    const {
      status,
      filial,
      ...newFilters
    }: {
      [key: string]: any;
    } = { ...formRef.current?.getData() };

    setFilters((state) => ({ ...state, ...newFilters }));
  }, [onChangePage, setFilters]);

  return (
    <Container>
      <h1>Filtrar por Instituição</h1>
      <Form ref={formRef} onSubmit={getValues}>
        <GridContent>
          <Field gridArea="ni">
            <label>Nome da Instituição</label>
            <InputWithoutTitle name="institutionName" />
          </Field>
          <Field gridArea="uf">
            <label>Estado</label>
            <InputSelectAlternative
              name="uf"
              options={[{ value: '', label: 'Todos' }, ...ufOptions]}
              placeholder=""
            />
          </Field>
          <Field gridArea="un">
            <label>Unidade</label>
            <InputSelectAlternative
              disabled
              name="filial"
              options={[]}
              placeholder=""
            />
          </Field>
          <Field gridArea="st">
            <label>Status</label>
            <InputSelectAlternative
              disabled
              name="status"
              options={statusOptions}
              defaultValue={statusOptions[1]}
              placeholder=""
            />
          </Field>
          <Field gridArea="bt">
            <Button>
              <IoMdSearch size={18} />
              Buscar
            </Button>
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default Filter;
