import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  > img {
    max-width: 200px;
    width: 50%;
    margin-bottom: 20px;
  }

  > h2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #707683;
  }
`;
