/* eslint-disable no-unreachable */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { differenceInMinutes } from 'date-fns';

import getSchedulesForAppointments from '../../../../utils/getSchedulesForAppointments';

import {
  ComponentsUserRegisterEditProps,
  ComponentsUserRegisterEditRef,
} from '..';
import InputCheckAlternative from '../../../../components/InputCheckAlternative';
import InputTime from '../../../../components/InputTime';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ReactComponent as ScheduleIcon } from '../../../../assets/images/schedule-set.svg';
import TrashIcon from '../../../../assets/images/trash2.svg';

import {
  Container,
  Content,
  Header,
  ScheduleCard,
  InputSelect,
  GridContent,
  Field,
  FieldAlternative,
  AddButton,
  ScheduleCardHeader,
  Intervals,
} from './styles';

import { useUser } from '../../../../hooks/user';
import { usePermission } from '../../../../hooks/permission';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../../../lib/use-current-effect';
import { Professional } from '../../../../entities/Professional';

export interface IntervalProps {
  startInterval?: string | null;
  endInterval?: string | null;
  key?: number;
  diffInMinutes?: number;
  isAfterEnd?: boolean;
  isBeforeBeginning?: boolean;
}

type Days =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | 'mon_fri'
  | 'mon_sat'
  | 'mon_sun'
  | 'sat_sun';

export interface ScheduleProps {
  duration?: number;
  day?: Days;
  startTime?: string | null;
  endTime?: string | null;
  dailyScheduleIntervals: IntervalProps[];
  diffInMinutes?: number;
}

export interface AvailabilityScheduleProps {
  scheduleCustomType?: boolean;
  schedules?: ScheduleProps[];
}

interface ScheduleCardProps {
  key: number;
  schedule: ScheduleProps;
}

interface OptionProps {
  value: string | number;
  label: string;
  isDisabled?: boolean;
}

interface GetNumberOfAppointmentsPerDayProps {
  scheduleIndex: number;
  intervalIndex?: number;
  startInterval?: string;
  endInterval?: string;
}

interface PossibleAppointmentsPerDayProps {
  interval: [
    {
      fullInterval: OptionProps[];
      startInterval: OptionProps[];
      endInterval?: OptionProps[];
    },
  ];
}

const AvailabilitySchedule: React.ForwardRefRenderFunction<
  ComponentsUserRegisterEditRef,
  ComponentsUserRegisterEditProps
> = ({ onSaveSucceed }, ref) => {
  const { hasPermission } = usePermission();
  const { user, save } = useUser();

  const formRef = useRef<FormHandles>(null);
  const [scheduleType, setScheduleType] = useState(false);
  const [schedules, setSchedules] = useState<ScheduleCardProps[]>([]);
  const [appointmentsPerDay, setAppointmentsPerDay] = useState<number[]>([]);
  const [possibleAppointmentsPerDay, setPossibleAppointmentsPerDay] = useState<
    PossibleAppointmentsPerDayProps[]
  >([]);
  const [usedOptions, setUsedOptions] = useState<
    { value: string; index: number }[]
  >([]);
  const [dayOptions, setDayOptions] = useState<OptionProps[]>([
    { value: 'monday', label: 'Segunda-feira' },
    { value: 'tuesday', label: 'Terça-feira' },
    { value: 'wednesday', label: 'Quarta-feira' },
    { value: 'thursday', label: 'Quinta-feira' },
    { value: 'friday', label: 'Sexta-feira' },
    { value: 'saturday', label: 'Sábado' },
    { value: 'sunday', label: 'Domingo' },
  ]);

  const canEditFields = useMemo(
    () => !user?.id || hasPermission('edit_user_schedule'),
    [hasPermission, user],
  );

  const durationOptions = useMemo<OptionProps[]>(
    () => [
      {
        value: 15,
        label: '15 min',
      },
      {
        value: 30,
        label: '30 min',
      },
      {
        value: 45,
        label: '45 min',
      },
    ],
    [],
  );

  const dayRangeOptions = useMemo<OptionProps[]>(
    () => [
      {
        value: 'mon_fri',
        label: 'Segunda a sexta',
      },
      {
        value: 'mon_sat',
        label: 'Segunda a sábado',
      },
      {
        value: 'mon_sun',
        label: 'Segunda a domingo',
      },
      {
        value: 'sat_sun',
        label: 'Sábado a domingo',
      },
    ],
    [],
  );

  const scheduleCurrentOptions = useMemo(
    () => (scheduleType ? dayOptions : dayRangeOptions),
    [dayOptions, dayRangeOptions, scheduleType],
  );

  const fixTimes = useCallback(
    (data?: ScheduleProps[]): ScheduleProps[] =>
      data?.map((schedule) => {
        const intervalsToRemove = schedule.dailyScheduleIntervals?.map(
          ({ startInterval, endInterval }, index) =>
            !startInterval?.length && !endInterval?.length ? index : -1,
        );

        intervalsToRemove?.forEach((intervalIndex) => {
          if (intervalIndex >= 0)
            schedule.dailyScheduleIntervals.splice(intervalIndex, 1);
        });

        const beginningTime = schedule.startTime
          ?.split(':')
          .map((value: any) => Number(value));

        const endTime = schedule.endTime
          ?.split(':')
          .map((value: any) => Number(value));

        if (beginningTime?.length !== 2 || endTime?.length !== 2) {
          return { ...schedule, diffInMinutes: 0 };
        }

        const beginningDate = new Date(
          0,
          0,
          0,
          beginningTime[0],
          beginningTime[1],
        );

        const endDate = new Date(0, 0, 0, endTime[0], endTime[1]);

        if (
          !schedule.dailyScheduleIntervals ||
          schedule.dailyScheduleIntervals.length === 0
        ) {
          schedule.dailyScheduleIntervals = [{ key: 0 }];
        }

        // Organizando interval
        const dailyScheduleIntervals = schedule.dailyScheduleIntervals?.map(
          (interval) => {
            const beginningTimeInterval = interval.startInterval
              ?.split(':')
              .map((value: any) => Number(value));

            const endTimeInterval = interval.endInterval
              ?.split(':')
              .map((value: any) => Number(value));

            if (
              beginningTimeInterval?.length !== 2 ||
              endTimeInterval?.length !== 2
            ) {
              return { ...interval, diffInMinutes: 0 };
            }

            const beginningDateInterval = new Date(
              0,
              0,
              0,
              beginningTimeInterval[0],
              beginningTimeInterval[1],
            );

            const endDateInterval = new Date(
              0,
              0,
              0,
              endTimeInterval[0],
              endTimeInterval[1],
            );

            const isAfterEnd =
              differenceInMinutes(endDateInterval, endDate) > 0;
            const isBeforeBeginning =
              differenceInMinutes(beginningDateInterval, beginningDate) < 0;

            return {
              ...interval,
              isAfterEnd,
              isBeforeBeginning,
              diffInMinutes: differenceInMinutes(
                endDateInterval,
                beginningDateInterval,
              ),
            };
          },
        );
        // Fim organizando Interval

        return {
          ...schedule,
          dailyScheduleIntervals,
          diffInMinutes: differenceInMinutes(endDate, beginningDate),
        };
      }) ?? [],
    [],
  );

  const getValidate = useCallback(async () => {
    const data: AvailabilityScheduleProps | undefined =
      formRef.current?.getData();

    formRef.current?.setErrors({});

    if (!data) {
      return false;
    }

    data.schedules = fixTimes(data.schedules);

    try {
      const schema = Yup.object().shape({
        scheduleCustomType: Yup.boolean(),
        schedules: Yup.array().of(
          Yup.object().shape({
            diffInMinutes: Yup.number(),
            startTime: Yup.string()
              .required('Este campo é obrigatório')
              .when('diffInMinutes', {
                is: (diffInMinutes) => diffInMinutes < 0,
                then: Yup.string().test(
                  'validate-time',
                  'Horário final não pode/deve anteceder o horário inicial',
                  () => false,
                ),
              }),
            endTime: Yup.string()
              .required('Este campo é obrigatório')
              .when('diffInMinutes', {
                is: (diffInMinutes) => diffInMinutes < 0,
                then: Yup.string().test(
                  'validate-time',
                  'Horário final não pode/deve anteceder o horário inicial',
                  () => false,
                ),
              }),
            day: Yup.string().required('Selecione um dia ou conjunto de dias'),
            duration: Yup.string().required(
              'Selecione uma duração de consulta',
            ),
            dailyScheduleIntervals: Yup.array().of(
              Yup.object()
                .shape(
                  {
                    diffInMinutes: Yup.number(),
                    isAfterEnd: Yup.boolean(),
                    isBeforeBeginning: Yup.boolean(),
                    startInterval: Yup.string()
                      .when('diffInMinutes', {
                        is: (diffInMinutes) => diffInMinutes < 0,
                        then: Yup.string().test(
                          'validate-time',
                          'Horário final não pode/deve anteceder o horário inicial',
                          () => false,
                        ),
                      })
                      .when('isBeforeBeginning', {
                        is: (isBeforeBeginning) => !!isBeforeBeginning,
                        then: Yup.string().test(
                          'validate-time',
                          'O inicio do intervalo não pode/deve anteceder o horário inicial',
                          () => false,
                        ),
                      })
                      .when('endInterval', {
                        is: (endInterval) => !!endInterval,
                        then: Yup.string().required('Horário obrigatório'),
                      }),
                    endInterval: Yup.string()
                      .when('diffInMinutes', {
                        is: (diffInMinutes) => diffInMinutes < 0,
                        then: Yup.string().test(
                          'validate-time',
                          'Horário final não pode/deve anteceder o horário inicial',
                          () => false,
                        ),
                      })
                      .when('isAfterEnd', {
                        is: (isAfterEnd) => !!isAfterEnd,
                        then: Yup.string().test(
                          'validate-time',
                          'O fim do intervalo não pode/deve suceder o horário final',
                          () => false,
                        ),
                      })
                      .when('startInterval', {
                        is: (startInterval) => !!startInterval,
                        then: Yup.string().required('Horário obrigatório'),
                      }),
                  },
                  [['endInterval', 'startInterval']],
                )
                .optional(),
            ),
          }),
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      } else if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }

      return false;
    }
  }, [fixTimes]);

  const getSchedule = useCallback((): ScheduleCardProps[] => {
    const data: AvailabilityScheduleProps | undefined =
      formRef.current?.getData();

    if (!data || !data.schedules) {
      return [];
    }

    const newSchedule = data.schedules.map((schedule, indexSchedule) => {
      return {
        key: indexSchedule,
        schedule: {
          ...schedule,
          dailyScheduleIntervals: schedule.dailyScheduleIntervals
            ? schedule.dailyScheduleIntervals.map((interval, intervalIndex) => {
                return {
                  ...interval,
                  key: intervalIndex,
                };
              })
            : [{ key: 0 }],
        },
      };
    }) as ScheduleCardProps[];

    return newSchedule;
  }, []);

  const getEndIntervalOptions = useCallback(
    (scheduleIndex, intervalIndex, value) => {
      if (value && possibleAppointmentsPerDay.length > 0) {
        const newPossibleAppointmentsPerDay = possibleAppointmentsPerDay;

        const interval =
          possibleAppointmentsPerDay[scheduleIndex].interval[intervalIndex];

        const indexSelect = interval.fullInterval.findIndex(
          (intervalSelected) => intervalSelected.value === value,
        );

        newPossibleAppointmentsPerDay[scheduleIndex].interval[
          intervalIndex
        ].endInterval = interval.fullInterval.slice(
          indexSelect + 1,
          interval.fullInterval.length - 1,
        );

        setPossibleAppointmentsPerDay(newPossibleAppointmentsPerDay);
      }
    },
    [possibleAppointmentsPerDay],
  );

  const getPossibleAppointmentsPerDay = useCallback(
    (scheduleIndex: number) => {
      const data = getSchedule();
      const newPossibleAppointmentsPerDay = possibleAppointmentsPerDay;

      if (data.length > 0 || data[scheduleIndex]) {
        const { schedule } = data[scheduleIndex];

        if (schedule) {
          const times = getSchedulesForAppointments(
            schedule.startTime,
            schedule.endTime,
            schedule.duration,
          );

          const newInterval = {
            interval: possibleAppointmentsPerDay[scheduleIndex].interval.map(
              () => {
                return {
                  fullInterval: times.map((time) => {
                    return { label: time, value: time };
                  }),
                  startInterval: times
                    .filter(
                      (__, index) =>
                        index !== 0 &&
                        index !== times.length - 1 &&
                        index !== times.length - 2,
                    )
                    .map((time) => {
                      return { label: time, value: time };
                    }),
                };
              },
            ),
          } as PossibleAppointmentsPerDayProps;

          newPossibleAppointmentsPerDay[scheduleIndex] = newInterval;

          setPossibleAppointmentsPerDay(newPossibleAppointmentsPerDay);
        }
      }
    },
    [possibleAppointmentsPerDay, getSchedule],
  );

  const getAllPossibleAppointmentsPerDay = useCurrentCallback(
    (isCurrent) => async (dataValue?: ScheduleCardProps[]) => {
      if (dataValue && dataValue.length > 0) {
        const newPossibleAppointmentsPerDay = dataValue.map((schedule) => {
          const times = getSchedulesForAppointments(
            schedule.schedule.startTime,
            schedule.schedule.endTime,
            schedule.schedule.duration,
          );

          return {
            interval: schedule.schedule.dailyScheduleIntervals.map(() => {
              return {
                fullInterval: times.map((time) => {
                  return { label: time, value: time };
                }),
                startInterval: times
                  .filter(
                    (__, index) =>
                      index !== 0 &&
                      index !== times.length - 1 &&
                      index !== times.length - 2,
                  )
                  .map((time) => {
                    return { label: time, value: time };
                  }),
              };
            }),
          };
        }) as PossibleAppointmentsPerDayProps[];

        if (isCurrent()) {
          setPossibleAppointmentsPerDay(newPossibleAppointmentsPerDay);
        }
      }
    },
    [],
  );

  const handleChangeSelect = useCallback(
    (value: string, index: number) => {
      const newUsedOptions = usedOptions;

      const usedOptionIndex = usedOptions.findIndex(
        (option) => option.index === index,
      );

      if (usedOptionIndex >= 0) {
        newUsedOptions[usedOptionIndex].value = value;
      } else {
        newUsedOptions.push({ index, value });
      }

      const newSchedule = schedules;

      newSchedule[index] = {
        ...newSchedule[index],
        schedule: { ...newSchedule[index].schedule, day: value as Days },
      };

      setSchedules(newSchedule);

      setUsedOptions([...newUsedOptions]);
    },
    [schedules, usedOptions],
  );

  const getNumberOfAppointmentsPerDay = useCallback(
    ({
      endInterval,
      intervalIndex,
      scheduleIndex,
      startInterval,
    }: GetNumberOfAppointmentsPerDayProps) => {
      const data = getSchedule();

      if (data.length <= 0 || !data[scheduleIndex]) {
        setAppointmentsPerDay([]);
      } else {
        const { schedule } = data[scheduleIndex];

        if (schedule) {
          const { dailyScheduleIntervals } = schedule;

          if (startInterval) {
            dailyScheduleIntervals[intervalIndex || 0].startInterval =
              startInterval;
          } else if (endInterval) {
            dailyScheduleIntervals[intervalIndex || 0].endInterval =
              endInterval;
          }

          const times = getSchedulesForAppointments(
            schedule.startTime,
            schedule.endTime,
            schedule.duration,
            dailyScheduleIntervals,
          );

          times.pop();

          appointmentsPerDay[scheduleIndex] = times.length;

          setAppointmentsPerDay([...appointmentsPerDay]);
        }
      }
    },
    [appointmentsPerDay, getSchedule],
  );

  const getAllNumberOfAppointmentsPerDay = useCallback(
    (dataValue?: ScheduleCardProps[], isCurrent?: () => boolean) => {
      let data = [] as ScheduleCardProps[] | false;

      if (dataValue) {
        data = dataValue;
      } else {
        data = getSchedule();
      }

      if ((!data || data.length <= 0) && isCurrent && isCurrent()) {
        setAppointmentsPerDay([]);
      } else {
        const newsAppointmentsPerDay = data.map(({ schedule: scheduleAux }) => {
          const times = getSchedulesForAppointments(
            scheduleAux.startTime,
            scheduleAux.endTime,
            scheduleAux.duration,
            scheduleAux.dailyScheduleIntervals,
          );

          times.pop();

          return times.length;
        });

        if (isCurrent && isCurrent()) {
          setAppointmentsPerDay([...newsAppointmentsPerDay]);
        }
      }
    },
    [getSchedule],
  );

  useEffect(() => {
    const newDayOptions = dayOptions.map((option) => ({
      ...option,
      isDisabled: usedOptions.some(
        (usedOption) => option.value.toString() === usedOption.value.toString(),
      ),
    }));

    setDayOptions([...newDayOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedOptions]);

  useEffect(() => {
    let schedulesCards: ScheduleCardProps[] = [
      { key: 0, schedule: { dailyScheduleIntervals: [{ key: 0 }] } },
    ];

    if (user?.professional?.professionalSchedule) {
      const { professionalScheduleAvailabilities, dayType, differentsTimes } =
        user.professional.professionalSchedule;

      if (professionalScheduleAvailabilities) {
        if (dayType) {
          schedulesCards[0] = {
            key: 0,
            schedule: {
              day: dayType,
              duration: professionalScheduleAvailabilities[0]?.duration,
              endTime: professionalScheduleAvailabilities[0]?.endTime
                ?.toString()
                .substring(0, 5),
              startTime: professionalScheduleAvailabilities[0]?.startTime
                ?.toString()
                .substring(0, 5),
              dailyScheduleIntervals: professionalScheduleAvailabilities[0]
                ?.dailyScheduleIntervals?.length
                ? professionalScheduleAvailabilities[0].dailyScheduleIntervals.map(
                    (itemInterval, indexInterval) => ({
                      key: indexInterval,
                      startInterval: itemInterval.startInterval
                        ?.toString()
                        .substring(0, 5),
                      endInterval: itemInterval.endInterval
                        ?.toString()
                        .substring(0, 5),
                    }),
                  )
                : [{ key: 0 }],
            },
          };
        } else {
          schedulesCards = professionalScheduleAvailabilities.map(
            (item, index) =>
              ({
                key: index,
                schedule: {
                  day: item.day,
                  duration: item.duration,
                  startTime: item.startTime?.toString().substring(0, 5),
                  endTime: item.endTime?.toString().substring(0, 5),
                  dailyScheduleIntervals:
                    item.dailyScheduleIntervals &&
                    item.dailyScheduleIntervals.length > 0
                      ? item.dailyScheduleIntervals?.map(
                          (itemInterval, indexInterval) => ({
                            key: indexInterval,
                            startInterval: itemInterval.startInterval
                              ?.toString()
                              .substring(0, 5),
                            endInterval: itemInterval.endInterval
                              ?.toString()
                              .substring(0, 5),
                          }),
                        )
                      : [{ key: 0 }],
                },
              } as ScheduleCardProps),
          );
        }
      }

      setScheduleType(differentsTimes ?? false);
      formRef.current?.setFieldValue(
        'scheduleCustomType',
        differentsTimes ?? false,
      );
    }
    setSchedules(schedulesCards);
  }, [user]);

  useCurrentEffect(
    (isCurrent) => {
      if (schedules.length > 0) {
        getAllPossibleAppointmentsPerDay(schedules).then(() => {
          const data = {
            scheduleCustomType: scheduleType ?? false,
            schedules: schedules.map((item) => ({
              day: item.schedule.day ?? '',
              duration: item.schedule.duration,
              startTime: item.schedule.startTime,
              endTime: item.schedule.endTime,
              dailyScheduleIntervals:
                item.schedule.dailyScheduleIntervals.length > 0
                  ? item.schedule.dailyScheduleIntervals
                  : [{ key: 0 }],
            })),
          } as AvailabilityScheduleProps;

          if (isCurrent()) {
            formRef.current?.setData(data);
          }

          getAllNumberOfAppointmentsPerDay(schedules, isCurrent);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schedules],
  );

  const handleChangeDuration = useCallback(
    (scheduleIndex: number, value: number) => {
      const newSchedule = getSchedule();

      if (schedules[scheduleIndex].schedule.duration !== value) {
        newSchedule[scheduleIndex].schedule.duration = value;
        newSchedule[scheduleIndex].schedule.dailyScheduleIntervals = [
          { key: 0 },
        ];

        setSchedules(newSchedule);
      }
    },
    [getSchedule, schedules],
  );

  const handleChangeScheduleType = useCallback(
    (value: boolean) => {
      setScheduleType(value);
      if (schedules.length > 0) {
        const item = schedules.shift();
        if (item?.schedule) {
          item.schedule.day = undefined;

          if (scheduleType) {
            setSchedules([item]);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            setSchedules(([_updated, ...rest]) => [item, ...rest]);
          }
        }
      }
    },
    [scheduleType, schedules],
  );

  const addSchedule = useCurrentCallback(
    (isCurrent) => async () => {
      const response = await getValidate();
      const newSchedule = getSchedule();

      if (response && newSchedule.length > 0 && isCurrent()) {
        newSchedule.push({
          key: newSchedule.length,
          schedule: {
            dailyScheduleIntervals: [{ key: 0 }],
          },
        });

        setSchedules([...newSchedule]);
      }
    },
    [getValidate, getSchedule],
  );

  const removeSchedule = useCallback(
    (scheduleKey: number) => {
      const schedulesToMaintain = schedules.filter(
        ({ key }) => key !== scheduleKey,
      );

      const usedOptionsToMaintain = usedOptions.filter(
        ({ index }) => index !== scheduleKey,
      );

      setUsedOptions([...usedOptionsToMaintain]);
      setSchedules([...schedulesToMaintain]);
    },
    [schedules, usedOptions],
  );

  const addInterval = useCallback(
    (scheduleIndex: number) => {
      const newSchedule = getSchedule();

      if (newSchedule.length > 0) {
        let lastInterval =
          newSchedule[scheduleIndex].schedule.dailyScheduleIntervals;

        if (!lastInterval) {
          lastInterval = [];
        }

        const intervalKey = lastInterval[lastInterval.length - 1].key ?? -1;

        lastInterval.push({
          key: intervalKey + 1 ?? 0,
        });

        newSchedule[scheduleIndex].schedule.dailyScheduleIntervals =
          lastInterval;

        setSchedules([...newSchedule]);
      }
    },
    [getSchedule],
  );

  const removeInterval = useCallback(
    (scheduleIndex: number, intervalKey: number) => {
      const previousSchedules = schedules;

      previousSchedules[scheduleIndex].schedule.dailyScheduleIntervals =
        previousSchedules[scheduleIndex].schedule.dailyScheduleIntervals.filter(
          ({ key }) => key !== intervalKey,
        );

      setSchedules([...previousSchedules]);
    },
    [schedules],
  );

  const handleFinishOrSave = useCurrentCallback(
    (isCurrent) => async () => {
      if (user && isCurrent()) {
        if (!canEditFields) {
          const didSave = await save({});

          return didSave;
        }

        const data: AvailabilityScheduleProps | undefined =
          formRef.current?.getData();

        formRef.current?.setErrors({});

        if (!data) {
          return false;
        }
        data.schedules = data.schedules?.map<ScheduleProps>(
          (schedule, index) => ({
            ...schedule,
            dailyScheduleIntervals:
              schedules[index].schedule.dailyScheduleIntervals.length <
              schedule.dailyScheduleIntervals.length
                ? // [
                  //   { startInterval: '09:00', endInterval: '09:15' },
                  //   { startInterval: '11:00', endInterval: '11:15' },
                  //   { startInterval: '14:00', endInterval: '14:15' },
                  //   { startInterval: '17:00', endInterval: '17:15' },
                  // ],
                  schedules[index].schedule.dailyScheduleIntervals
                  ? schedules[index].schedule.dailyScheduleIntervals
                  : []
                : schedule.dailyScheduleIntervals,
          }),
        );

        data.schedules = fixTimes(data.schedules);

        const professionalScheduleAvailabilities = data.schedules.map(
          (item) => ({
            ...item,
            dailyScheduleIntervals: item.dailyScheduleIntervals[0].startInterval
              ? item.dailyScheduleIntervals
              : [],
          }),
        );

        try {
          let professional: Professional | undefined = {
            professionalSchedule: {
              differentsTimes: scheduleType,
              professionalScheduleAvailabilities,
            },
          };

          if (user.professional) {
            professional = { ...user.professional, ...professional };
          }

          const didSave = await save({
            ...user,
            professional,
          });

          return didSave;
        } catch (err) {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error('Entre em contato com o administrador.');
          }

          return false;
        }
      }

      return false;
    },
    [canEditFields, fixTimes, save, scheduleType, schedules, user],
  );

  const handleSave = useCallback(async () => {
    const didSave = await handleFinishOrSave();

    didSave && onSaveSucceed && onSaveSucceed();

    return didSave;
  }, [handleFinishOrSave, onSaveSucceed]);

  useImperativeHandle(ref, () => ({
    back: async () => true,
    cancel: async () => true,
    finish: handleFinishOrSave,
    next: async () => false,
    save: handleSave,
    canSave: getValidate,
  }));

  return (
    <Container>
      <Form ref={formRef} onSubmit={() => {}}>
        <Header>
          <h1>
            <ScheduleIcon />
            Configurar agenda
          </h1>
          <div>
            <span>Horários diferentes</span>
            <InputCheckAlternative
              checked={scheduleType}
              name="scheduleCustomType"
              onChange={(e) =>
                handleChangeScheduleType(e.currentTarget.checked)
              }
              disabled={!canEditFields}
            />
          </div>
        </Header>
        <Content isButtonVisible={scheduleType}>
          {schedules.map((schedule, scheduleIndex) => (
            <Scope key={schedule.key} path={`schedules[${scheduleIndex}]`}>
              <ScheduleCard>
                <ScheduleCardHeader
                  isButtonVisible={scheduleType && schedules.length > 1}
                >
                  <h2>Definir horário</h2>
                  <button
                    type="button"
                    onClick={() => removeSchedule(schedule.key)}
                    disabled={!canEditFields}
                  >
                    <img src={TrashIcon} alt="Ícone de lixeira" />
                  </button>
                </ScheduleCardHeader>
                <GridContent>
                  <Field gridArea="di">
                    <label>Dias:</label>
                    <InputSelect
                      name="day"
                      options={scheduleCurrentOptions}
                      onChange={(data?: any) =>
                        handleChangeSelect(data?.value ?? '', scheduleIndex)
                      }
                      disabled={!canEditFields}
                      defaultValue={dayRangeOptions.find(
                        (day) => day.value === schedule.schedule.day,
                      )}
                    />
                  </Field>
                  <Field gridArea="du">
                    <label>Duração:</label>
                    <InputSelect
                      name="duration"
                      options={durationOptions}
                      placeholder=""
                      onChange={(data?: any) =>
                        handleChangeDuration(scheduleIndex, data?.value)
                      }
                      disabled={!canEditFields}
                      defaultValue={durationOptions.find(
                        (duration) =>
                          duration.value === schedule.schedule.duration,
                      )}
                    />
                  </Field>
                  <Field gridArea="en">
                    <label>Entrada:</label>
                    <InputTime
                      name="startTime"
                      textColor="#2d9cdb"
                      placeholder="00:00"
                      onChange={() => {
                        getPossibleAppointmentsPerDay(scheduleIndex);
                        getNumberOfAppointmentsPerDay({ scheduleIndex });
                      }}
                      disabled={!canEditFields}
                    />
                  </Field>
                  <Field gridArea="sai">
                    <label>Saída:</label>
                    <InputTime
                      name="endTime"
                      textColor="#2d9cdb"
                      placeholder="00:00"
                      onChange={() => {
                        getPossibleAppointmentsPerDay(scheduleIndex);
                        getNumberOfAppointmentsPerDay({ scheduleIndex });
                      }}
                      disabled={!canEditFields}
                    />
                  </Field>
                  <FieldAlternative gridArea="int">
                    <p>Intervalos:</p>
                  </FieldAlternative>
                  <Intervals gridArea="ints">
                    {schedule.schedule.dailyScheduleIntervals &&
                      schedule.schedule.dailyScheduleIntervals.length !== 0 &&
                      schedule.schedule.dailyScheduleIntervals.map(
                        (interval, intervalIndex) => (
                          <Scope
                            key={`interval-${interval.key}`}
                            path={`dailyScheduleIntervals[${intervalIndex}]`}
                          >
                            <Field>
                              <label>Início:</label>
                              <InputSelect
                                name="startInterval"
                                isClearable
                                options={
                                  possibleAppointmentsPerDay[scheduleIndex]
                                    ?.interval[intervalIndex]?.startInterval ??
                                  []
                                }
                                placeholder="Nenhum"
                                onChange={(data?: any) => {
                                  getEndIntervalOptions(
                                    scheduleIndex,
                                    intervalIndex,
                                    data?.value,
                                  );
                                  getNumberOfAppointmentsPerDay({
                                    scheduleIndex,
                                    intervalIndex,
                                    startInterval: data?.value ?? '',
                                  });
                                }}
                                disabled={!canEditFields}
                                defaultValue={{
                                  value:
                                    schedule.schedule.dailyScheduleIntervals[
                                      intervalIndex
                                    ].startInterval,
                                  label:
                                    schedule.schedule.dailyScheduleIntervals[
                                      intervalIndex
                                    ].startInterval,
                                }}
                              />
                            </Field>
                            <Field>
                              <label>Fim:</label>
                              <InputSelect
                                name="endInterval"
                                isClearable
                                options={
                                  possibleAppointmentsPerDay[scheduleIndex]
                                    ?.interval[intervalIndex]?.endInterval ?? []
                                }
                                placeholder="Nenhum"
                                onChange={(data?: any) =>
                                  getNumberOfAppointmentsPerDay({
                                    scheduleIndex,
                                    intervalIndex,
                                    endInterval: data?.value ?? '',
                                  })
                                }
                                disabled={!canEditFields}
                                defaultValue={{
                                  value:
                                    schedule.schedule.dailyScheduleIntervals[
                                      intervalIndex
                                    ].endInterval,
                                  label:
                                    schedule.schedule.dailyScheduleIntervals[
                                      intervalIndex
                                    ].endInterval,
                                }}
                              />
                            </Field>
                            {schedule.schedule.dailyScheduleIntervals.length -
                              1 ===
                            intervalIndex ? (
                              <AddButton
                                type="button"
                                isButtonVisible
                                onClick={() => addInterval(scheduleIndex)}
                                disabled={!canEditFields}
                              >
                                Adicionar Intervalo
                              </AddButton>
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  removeInterval(
                                    scheduleIndex,
                                    interval.key as number,
                                  )
                                }
                                disabled={!canEditFields}
                              >
                                <img src={TrashIcon} alt="Ícone de lixeira" />
                              </button>
                            )}
                          </Scope>
                        ),
                      )}
                  </Intervals>
                  <FieldAlternative gridArea="me">
                    <strong>Estimativa de atendimento por dia:</strong>
                    <p>
                      {appointmentsPerDay[schedule.key]
                        ? appointmentsPerDay[schedule.key]
                        : '-'}
                    </p>
                  </FieldAlternative>
                </GridContent>
              </ScheduleCard>
            </Scope>
          ))}
          <AddButton
            isButtonVisible={scheduleType && schedules.length !== 7}
            type="button"
            onClick={() => addSchedule()}
            disabled={!canEditFields}
          >
            Adicionar horário
          </AddButton>
        </Content>
      </Form>
    </Container>
  );
};

export default forwardRef(AvailabilitySchedule);
