import styled from 'styled-components';

interface ContainerProps {
  copied: boolean;
}

export const Container = styled.button<ContainerProps>`
  min-width: 53px;
  border: 0;
  background: none;
  color: ${({ copied, theme }) =>
    copied ? theme.colors.primary : theme.colors.grayAlternative};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 13px;
  line-height: 130%;

  div {
    width: 30px;
    height: 30px;
    background: ${({ copied, theme }) =>
      copied ? theme.colors.primary : theme.colors.gray};

    display: flex;
    align: center;
    justify-content: center;

    border-radius: 50%;
    margin-bottom: 8px;
  }
`;
