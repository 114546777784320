import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useRef,
  lazy,
  Suspense,
} from 'react';

import { useModal } from 'react-brave-modal';
import { format, subHours } from 'date-fns';

import { IoMdTime } from 'react-icons/io';
import { HiOutlinePencil } from 'react-icons/hi';

import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import getValidationErrors from '../../../../utils/getValidationErrors';
import { useAppointment } from '../../../../hooks/appointment';
import { usePatient } from '../../../../hooks/patient';
import { useAuth } from '../../../../hooks/auth';
import getRiskDegree from '../../../../utils/getRiskDegree';
import {
  getAllCallStatusByKey,
  getPhoneCallStatus,
} from '../../../../utils/getCallStatus';
import RequiredFieldInformation from '../../../../components/RequiredFieldInformation';
import AudioPlayer from '../../../../components/AudioPlayer';
import EditRiskDegreeModal from '../modals/EditRiskDegreeModal';
import SheetSubmissionModal from '../../../../modals/SheetSubmissionModal';
import PhoneIcon from '../../../../assets/images/attendance_summary_calling.svg';
import VideoIcon from '../../../../assets/images/icon_schedule_film.svg';
import PlayIconRecorder from '../../../../assets/images/arrow-right.svg';
import {
  Container,
  Field,
  Duration,
  RiskDegree,
  Risk,
  EditButton,
  Status,
  Sheets,
  Sheet,
  InputWrapper,
  Row,
  FlexRow,
  InputPlaceholder,
  PlayRecorderButton,
  GridContainer,
  ContainerAudioPlayer,
} from './styles';

import InputTextArea from '../../../../components/InputTextArea';
import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import SignatureStatusEnum from '../../../../enums/SignatureStatusEnum';
import { useSignature } from '../../../../hooks/signature';

import AppointmentStatusEnum from '../../../../enums/AppointmentStatusEnum';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';
import UserStatusEnum from '../../../../enums/UserStatusEnum';
import OccupationAreaEnum from '../../../../enums/OccupationAreaEnum';
import Button from '../../../../components/Button';
import InputCopyToClipBoard from '../../../../components/InputCopyToClipBoard';
import AppointmentHistory from './AppointmentHistory';

const EditCallStatusModal = lazy(
  () =>
    import(
      /* webpackChunkName: "EditCallStatusModal" */ '../modals/EditCallStatusModal'
    ),
);

interface SelectRecordingProps {
  label: string;
  value: string;
  name: string;
  status: 'completed' | 'in_progress';
}

interface FormData {
  anamnese?: string;
  callStatus?: string;
  observation?: string;
  cid?: string;
  doctorObservation?: string;
}

interface SectionSummaryRef {
  finish: () => any;
}

const SectionSummary: React.ForwardRefRenderFunction<SectionSummaryRef> = (
  _,
  ref,
) => {
  const { user } = useAuth();
  const {
    appointmentSelected,
    updateAppointmentDetails,
    updateAppointmentStatus,
  } = useAppointment();
  const { update: updateSignature } = useSignature();
  const { patient } = usePatient();
  const { showModal } = useModal();
  const [fileLink, setFileLink] = useState('');
  const [fileName, setFileName] = useState('');
  const [recordingStatus, setRecordingStatus] = useState('Sem gravação');
  const [showComponent, setShowComponent] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const risk = useMemo(() => getRiskDegree.risk, []);
  const riskColor = useMemo(() => getRiskDegree.color, []);

  const can = {
    edit: user?.id === appointmentSelected?.attendantProfessional?.user?.id,
    seeMedicalObservations:
      user?.professional?.occupationArea === OccupationAreaEnum.MEDICINE &&
      user.id === appointmentSelected?.attendantProfessional?.user?.id,
  };

  useEffect(() => {
    if (appointmentSelected?.recordings?.length) {
      const {
        link,
        fileName: name,
        status,
      } = appointmentSelected.recordings[0];

      setFileLink(link);
      setFileName(name);

      setRecordingStatus(
        status === 'completed' ? status : 'Gravação em progresso...',
      );
    }
  }, [appointmentSelected]);

  const callStatusData = useMemo(() => {
    const label = getAllCallStatusByKey(
      appointmentSelected?.details?.callStatus,
    );

    return { label, value: appointmentSelected?.details?.callStatus };
  }, [appointmentSelected]);

  const recordings = useMemo(
    () =>
      appointmentSelected?.recordings?.map((recording) => ({
        label: format(subHours(new Date(recording.created_at), 3), 'HH:mm'),
        value: recording.link,
        name: recording.fileName,
        status: recording.status,
      })),
    [appointmentSelected],
  );

  const handleRiskDegreeEditing = useCallback(() => {
    if (
      patient?.user?.currentStatus?.status !== UserStatusEnum.DRAFT ||
      user?.professional_id
    ) {
      showModal({
        type: 'custom',
        data: <EditRiskDegreeModal />,
      });
    }
  }, [patient, showModal, user]);

  const handleCallStatusEditing = useCallback(() => {
    showModal({
      type: 'custom',
      data: (
        <Suspense fallback={false}>
          <EditCallStatusModal
            finishAttendance={
              appointmentSelected?.status === AppointmentStatusEnum.FINISHED
            }
            hasStatusBeenFilled={
              appointmentSelected &&
              appointmentSelected.details &&
              !!appointmentSelected.details.callStatus
            }
          />
        </Suspense>
      ),
    });
  }, [appointmentSelected, showModal]);

  const handleEditOrViewSheet = useCallback(
    (type: 'edit' | 'view', sheetId: string) => {
      showModal({
        type: 'custom',
        data: <SheetSubmissionModal type={type} id={sheetId} />,
      });
    },
    [showModal],
  );

  const handlePlayRecording = () => {
    setShowComponent(true);
  };

  const handleSubmit = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data: FormData = { ...formRef.current?.getData() };

      const schema = Yup.object().shape({
        anamnese: Yup.string().required('A anamnese é obrigatória'),
        callStatus: Yup.string().required('O status de ligação é obrigatório'),
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { callStatus, ...rest } = { ...data };

      await schema.validate(data, {
        abortEarly: false,
      });

      if (appointmentSelected?.id) {
        await updateAppointmentDetails(
          {
            appointmentId: appointmentSelected?.id,
            callStatus: callStatusData?.value,
            anamnese: rest.anamnese,
            cid: rest.cid,
            doctorObservation: rest.doctorObservation,
            observation: rest.observation,
          },
          true,
        );
      }

      if (
        appointmentSelected?.status === AppointmentStatusEnum.STARTED ||
        appointmentSelected?.status === AppointmentStatusEnum.UNFINISHED
      ) {
        await updateAppointmentStatus({
          appointmentId: appointmentSelected?.id,
          status: AppointmentStatusEnum.FINISHED,
        });
      }

      if (
        appointmentSelected?.patient?.currentSignature &&
        appointmentSelected.patient.currentSignature.status ===
          SignatureStatusEnum.AWAITING_FIRST_APPOINTMENT
      ) {
        await updateSignature({
          id: appointmentSelected.patient.currentSignature.id,
          status: SignatureStatusEnum.ACTIVE,
        });
      }

      appointmentSelected?.status !== AppointmentStatusEnum.FINISHED
        ? toast.success('Consulta finalizada com sucesso.')
        : toast.success('Consulta atualizada com sucesso.');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formRef.current?.setErrors(errors);
      } else if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }
    }
  }, [
    appointmentSelected,
    callStatusData,
    updateAppointmentDetails,
    updateAppointmentStatus,
    updateSignature,
  ]);

  useEffect(() => {
    const { anamnese, cid, doctorObservation }: FormData = {
      ...formRef.current?.getData(),
    };

    formRef.current?.setData({
      anamnese: anamnese || appointmentSelected?.details?.anamnese,
      callStatus: callStatusData?.label,
      observation: appointmentSelected?.details?.observation,
      cid: cid || appointmentSelected?.details?.cid,
      doctorObservation:
        doctorObservation || appointmentSelected?.details?.doctor_observation,
    });
  }, [appointmentSelected, callStatusData]);

  useImperativeHandle(
    ref,
    () => {
      return { finish: handleSubmit };
    },
    [handleSubmit],
  );

  const allOptions = getPhoneCallStatus.flatMap((status) => status.options);

  const initialData = {
    callStatus: allOptions.find((status) => status.value)?.value,
  };

  return (
    <>
      <Form ref={formRef} onSubmit={() => {}} initialData={initialData}>
        <GridContainer>
          <Container style={{ gridArea: 'flexRow2', minHeight: 355 }}>
            <FlexRow>
              <Field className="buttons">
                <Row>
                  <label>Duração do atendimento</label>
                </Row>
                <InputPlaceholder>
                  <Duration>
                    <p>
                      <span>
                        <IoMdTime />
                      </span>
                      {appointmentSelected?.details?.duration}
                    </p>
                  </Duration>
                </InputPlaceholder>
              </Field>
              {recordings && recordings.length > 0 && (
                <Field className="buttons">
                  <Row>
                    <label>
                      Consulta
                      <span>
                        <img
                          src={
                            appointmentSelected?.type ===
                            AppointmentTypeEnum.PHONE
                              ? PhoneIcon
                              : VideoIcon
                          }
                          alt={
                            appointmentSelected?.type ===
                            AppointmentTypeEnum.PHONE
                              ? 'Ícone de telefone'
                              : 'Ícone de câmera'
                          }
                        />
                      </span>
                    </label>
                  </Row>
                  <InputPlaceholder>
                    <InputCopyToClipBoard value={fileLink} />
                  </InputPlaceholder>
                </Field>
              )}
              <Field className="buttons">
                {!showComponent ? (
                  <PlayRecorderButton
                    disabled={recordingStatus === 'Sem gravação'}
                    title={
                      recordingStatus === 'completed'
                        ? 'Reproduzir gravação'
                        : recordingStatus
                    }
                    onClick={handlePlayRecording}
                  >
                    <img
                      src={PlayIconRecorder}
                      alt="ícone para iniciar player"
                    />
                    {recordingStatus === 'completed'
                      ? 'Reproduzir gravação'
                      : recordingStatus ?? 'Gravação em Progresso'}
                  </PlayRecorderButton>
                ) : (
                  <ContainerAudioPlayer>
                    <AudioPlayer
                      fileLink={fileLink}
                      fileName={fileName}
                      recordingType={
                        appointmentSelected?.type === AppointmentTypeEnum.PHONE
                          ? 'voice'
                          : 'video'
                      }
                    />
                  </ContainerAudioPlayer>
                )}
              </Field>
            </FlexRow>
            <FlexRow>
              <Field>
                <Row>
                  <label>Fichas preenchidas</label>
                </Row>
                <Sheets>
                  {patient?.sheets && patient.sheets.length > 0 ? (
                    patient.sheets.map((sheet) => (
                      <Sheet key={sheet.id}>
                        {sheet.status === 'received' ? (
                          <Button
                            style={{ width: '100%' }}
                            buttonType="primary-alternative"
                            onClick={() =>
                              handleEditOrViewSheet('view', sheet.id)
                            }
                          >
                            {sheet.sheet.name}
                          </Button>
                        ) : (
                          <p>Sem fichas preenchidas</p>
                        )}
                      </Sheet>
                    ))
                  ) : (
                    <p>Sem fichas preenchidas</p>
                  )}
                </Sheets>
              </Field>
            </FlexRow>

            <FlexRow>
              <Field>
                <Row className="anamnese">
                  <label>
                    Evolução:
                    {appointmentSelected?.status !==
                      AppointmentStatusEnum.FINISHED && (
                      <RequiredFieldInformation />
                    )}
                  </label>
                </Row>
                <InputTextArea
                  style={{
                    height: 94,
                  }}
                  disabled={
                    appointmentSelected?.status ===
                    AppointmentStatusEnum.FINISHED
                  }
                  name="anamnese"
                />
              </Field>
            </FlexRow>
            {appointmentSelected?.attendantProfessional?.occupationArea ===
              OccupationAreaEnum.MEDICINE && (
              <Row className="cid">
                <label>CID:</label>
                <InputWrapper>
                  <InputWithoutTitle
                    type="number"
                    name="cid"
                    className="cid"
                    disabled={
                      appointmentSelected?.status ===
                      AppointmentStatusEnum.FINISHED
                    }
                  />
                </InputWrapper>
              </Row>
            )}
            {can.seeMedicalObservations && (
              <Row className="doctorObservation">
                <label>Observações do médico (privadas):</label>
                <InputTextArea
                  name="doctorObservation"
                  disabled={
                    appointmentSelected?.status ===
                    AppointmentStatusEnum.FINISHED
                  }
                />
              </Row>
            )}
          </Container>
          <Container style={{ gridArea: 'aside' }}>
            <Field>
              <Row>
                <label>Grau de risco do paciente:</label>
              </Row>
              <RiskDegree>
                <Risk
                  riskDegreeColor={
                    patient?.currentRiskDegree
                      ? riskColor(patient?.currentRiskDegree.degree)
                      : riskColor()
                  }
                >
                  <span>{risk(patient?.currentRiskDegree?.degree)}</span>
                </Risk>
                <EditButton
                  type="button"
                  disabled={!can.edit}
                  onClick={handleRiskDegreeEditing}
                >
                  <HiOutlinePencil />
                  Editar
                </EditButton>
              </RiskDegree>
            </Field>
            <Field>
              <Row>
                <label>
                  Status do atendimento
                  <RequiredFieldInformation />
                </label>
              </Row>
              <Status>
                <InputWrapper>
                  <InputWithoutTitle disabled name="callStatus" />
                </InputWrapper>
                <EditButton
                  disabled={!can.edit}
                  type="button"
                  onClick={handleCallStatusEditing}
                >
                  <HiOutlinePencil />
                  Editar
                </EditButton>
              </Status>
            </Field>
            <Field>
              <Row>
                <label>Observações (Controle de ligação):</label>
              </Row>
              <InputTextArea disabled name="observation" />
            </Field>
          </Container>
          <Container
            style={{
              gridArea: 'flexRow1',
              height: 335,
              paddingLeft: 28,
              position: 'relative',
              zIndex: 1,
            }}
          >
            <Row>
              <h1>Histórico de atendimentos </h1>
            </Row>

            <AppointmentHistory />
          </Container>
        </GridContainer>
      </Form>
    </>
  );
};

export default forwardRef(SectionSummary);
