enum HealthConditionPerChapter {
  PerinatalDisorders = 'Algumas afecções originadas no período perinatal',
  InfectiousAndParasiticDiseases = 'Algumas doenças infecciosas e parasitárias',
  ExternalCauses = 'Causas externas de morbidade e mortalidade',
  SpecialPurposeCodes = 'Códigos para propósitos especiais',
  SkinAndSubcutaneousTissueDiseases = 'Doenças da pele e do tecido subcutâneo',
  CirculatorySystemDiseases = 'Doenças do aparelho circulatório',
  DigestiveSystemDiseases = 'Doenças do aparelho digestivo',
  GenitourinarySystemDiseases = 'Doenças do aparelho geniturinário',
  RespiratorySystemDiseases = 'Doenças do aparelho respiratório',
  EyeAndAdnexaDiseases = 'Doenças do olho e anexos',
  EarAndMastoidProcessDiseases = 'Doenças do ouvido e da apófise mastoide',
  BloodAndHematopoieticOrganDiseases = 'Doenças do sangue e dos órgãos hematopoéticos e alguns transtornos imunitários',
  NervousSystemDiseases = 'Doenças do sistema nervoso',
  MusculoskeletalSystemDiseases = 'Doenças do sistema osteomuscular e do tecido conjuntivo',
  EndocrineNutritionalMetabolicDiseases = 'Doenças endócrinas nutricionais e metabólicas',
  HealthStatusAndHealthServices = 'Fatores que influenciam o estado de saúde e o contato com os serviços de saúde',
  PregnancyChildbirthAndPuerperium = 'Gravidez parto e puerpério',
  InjuriesAndPoisonings = 'Lesões, envenenamento e algumas outras conseqüências de causas externas',
  CongenitalMalformationsAndAbnormalities = 'Malformações congênitas, deformidades e anomalias cromossômicas',
  Neoplasms = 'Neoplasias (tumores)',
  SymptomsAndAbnormalFindings = 'Sintomas, sinais e achados anormais de exames clínicos e de laboratório, não classificados em outra parte',
  MentalAndBehavioralDisorders = 'Transtornos mentais e comportamentais',
}

export default HealthConditionPerChapter;
