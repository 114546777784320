import React, { useState, useCallback, useMemo } from 'react';

import Window from '../../components/Window';
import Identification from './Identification';
import PasswordCreation from './PasswordCreation';
import FinishRegistration from './FinishRegistration';

import { Container } from './styles';

export interface UserData {
  cpf: string;
  birthday: Date;
  email: string;
  phone: string;
}

const Welcome: React.FC = () => {
  const [stepKey, setStepKey] = useState('Identification');
  const [userData, setUserData] = useState<UserData>({} as UserData);

  const handleStepChange = useCallback((key: string) => {
    setStepKey(key);
  }, []);

  const handleSetUserData = useCallback(
    (data: UserData) => {
      setUserData(data);
    },
    [setUserData],
  );

  const steps = useMemo(
    () => [
      {
        component: (
          <Identification
            userData={userData}
            handleSetUserData={handleSetUserData}
            handleStepChange={handleStepChange}
          />
        ),
        key: 'Identification',
      },
      {
        component: <PasswordCreation handleStepChange={handleStepChange} />,
        key: 'PasswordCreation',
      },
      {
        component: <FinishRegistration />,
        key: 'FinishRegistration',
      },
    ],
    [userData, handleSetUserData, handleStepChange],
  );

  return (
    <Window>
      <Container>
        {steps.find((step) => step.key === stepKey)?.component}
      </Container>
    </Window>
  );
};

export default Welcome;
