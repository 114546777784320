import React from 'react';

import DateFilter from '../DateFilter';
import BloodPressureChart from '../Charts/BloodPressureChart';
import BodyTemperatureChart from '../Charts/BodyTemperatureChart';
import CalorieChart from '../Charts/CalorieChart';
import HeartRateChart from '../Charts/HeartRateChart';
import SleepChart from '../Charts/SleepChart';
import StepChart from '../Charts/StepChart';
import RespiratoryRateChart from '../Charts/RespiratoryrateChart';
import BloodOxygenationChart from '../Charts/BloodOxygenationChart';

import { Container } from './styles';
import { MetricTypeEnum } from '../../../../../../../enums/MetricTypeEnum';
import { DateRangeType, OptionsType } from '..';

export interface ChartsProps {
  dateRange: DateRangeType;
}

export interface ContentChartProps {
  metric: MetricTypeEnum;
  options: OptionsType[];
  selectedDateOption: OptionsType;
  updateDateOption: React.Dispatch<React.SetStateAction<OptionsType>>;
}

type OptionsChartsType = {
  [key in MetricTypeEnum]?: JSX.Element;
};

const ContentChart: React.FC<ContentChartProps> = ({
  metric,
  options,
  selectedDateOption,
  updateDateOption,
}) => {
  const optionsCharts: OptionsChartsType = {
    bloodPressure: (
      <BloodPressureChart dateRange={selectedDateOption.rangeDate} />
    ),
    bodyTemperature: (
      <BodyTemperatureChart dateRange={selectedDateOption.rangeDate} />
    ),
    bloodOxygen: (
      <BloodOxygenationChart dateRange={selectedDateOption.rangeDate} />
    ),
    respiratoryRate: (
      <RespiratoryRateChart dateRange={selectedDateOption.rangeDate} />
    ),
    calories: <CalorieChart dateRange={selectedDateOption.rangeDate} />,
    heartBeatRate: <HeartRateChart dateRange={selectedDateOption.rangeDate} />,
    sleep: <SleepChart dateRange={selectedDateOption.rangeDate} />,
    steps: <StepChart dateRange={selectedDateOption.rangeDate} />,
  };

  return (
    <Container>
      <DateFilter
        options={options}
        dateRange={selectedDateOption}
        updateDateRange={updateDateOption}
      />
      {optionsCharts[metric]}
    </Container>
  );
};

export default ContentChart;
