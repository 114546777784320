import React, { useState, useMemo, useCallback } from 'react';
import { format } from 'date-fns';
import { useModal } from 'react-brave-modal';
import { MdRemoveRedEye } from 'react-icons/md';

import ContentNothingToDisplay from '../../../../components/ContentNothingToDisplay';
import SheetSubmissionModal from '../../../../modals/SheetSubmissionModal';
import { usePatient } from '../../../../hooks/patient';
import {
  GetSheetSubmissionsByFilter as ListFilter,
  GetSheetSubmissionsByFilterResponse as ListData,
} from '../../../../services/sheet';
import { ReactComponent as ArrowUpDownIcon } from '../../../../assets/images/arrowupdown.svg';

import {
  Container,
  TopBar,
  Info,
  AnswersList,
  AnswersListHeader,
  SortButton,
  AnswersListBody,
  ContentNothingToDisplayBox,
  StyledLink,
  AnswersListHeaderRow,
  AnswersListBodyRow,
  AnswersListBodyCell,
} from './styles';
import { usePermission } from '../../../../hooks/permission';
import OrdenationTypeEnum from '../../../../enums/OrdenationTypeEnum';

interface SheetSubmissionsList {
  id: string;
  submissionId: string;
  sheetPatientId: string;
  patientId: string;
  patientName?: string;
  professionalName?: string;
  date: string;
}

interface TableProps {
  setListFilter(listFilter: React.SetStateAction<ListFilter>): void;
  listData: ListData;
}

enum AnsweredSheetsListOrderByEnum {
  PATIENT_NAME = 'patientName',
  DATE = 'date',
  PROFESSIONAL_NAME = 'professionalName',
}

const Table: React.FC<TableProps> = ({ setListFilter, listData }) => {
  const { sync } = usePatient();
  const { hasPermission } = usePermission();
  const { showModal } = useModal();
  const [orderBy, setOrderBy] = useState(
    AnsweredSheetsListOrderByEnum.PATIENT_NAME,
  );
  const [isAscending, setIsAscending] = useState(true);

  const can = useMemo(
    () => ({
      viewPatientProfile: hasPermission('patient_profile'),
    }),
    [hasPermission],
  );

  const sheetSubmissionsList = useMemo<SheetSubmissionsList[]>(
    () =>
      listData.sheetSubmissions.map((sheetSubmission) => ({
        id: sheetSubmission.id,
        submissionId: sheetSubmission.submissionId,
        sheetPatientId: sheetSubmission.sheetPatientId,
        patientId: sheetSubmission.sheetPatient.patientId,
        patientName: sheetSubmission.sheetPatient.patient.user?.name,
        professionalName: sheetSubmission.professional.user?.name,
        date: format(new Date(sheetSubmission.created_at), "dd'/'MM'/'yyyy'"),
      })),
    [listData],
  );

  const handleOrdenation = useCallback(
    (orderKey: AnsweredSheetsListOrderByEnum, isInitialOrdering: boolean) => {
      if (isInitialOrdering) {
        setListFilter((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: OrdenationTypeEnum.ASC,
        }));
        setIsAscending(true);
      } else {
        setListFilter((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: isAscending
            ? OrdenationTypeEnum.DESC
            : OrdenationTypeEnum.ASC,
        }));

        setIsAscending((prevState) => !prevState);
      }

      setOrderBy(orderKey);
    },
    [isAscending, setListFilter],
  );

  const handleSheetView = useCallback(
    async ({
      patientId,
      sheetPatientId,
      submissionId,
    }: {
      patientId: string;
      sheetPatientId: string;
      submissionId: string;
    }) => {
      await sync(patientId);
      showModal({
        type: 'custom',
        data: (
          <SheetSubmissionModal
            id={sheetPatientId}
            submissionId={submissionId}
            type="view"
          />
        ),
      });
    },
    [showModal, sync],
  );

  return (
    <>
      {sheetSubmissionsList.length ? (
        <Container>
          <TopBar>
            <Info>
              <span>
                Exibindo <strong>{listData.totalSheetSubmissionsOnPage}</strong>{' '}
                resultados de <strong>{listData.totalSheetSubmissions}</strong>
              </span>
            </Info>
          </TopBar>
          <AnswersList>
            <AnswersListHeader>
              <AnswersListHeaderRow>
                <span>
                  Paciente
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={
                      orderBy === AnsweredSheetsListOrderByEnum.PATIENT_NAME
                    }
                    onClick={() =>
                      handleOrdenation(
                        AnsweredSheetsListOrderByEnum.PATIENT_NAME,
                        orderBy !== AnsweredSheetsListOrderByEnum.PATIENT_NAME,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </span>
                <span>
                  Data
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={orderBy === AnsweredSheetsListOrderByEnum.DATE}
                    onClick={() =>
                      handleOrdenation(
                        AnsweredSheetsListOrderByEnum.DATE,
                        orderBy !== AnsweredSheetsListOrderByEnum.DATE,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </span>
                <span>
                  Profissional de saúde
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={
                      orderBy ===
                      AnsweredSheetsListOrderByEnum.PROFESSIONAL_NAME
                    }
                    onClick={() =>
                      handleOrdenation(
                        AnsweredSheetsListOrderByEnum.PROFESSIONAL_NAME,
                        orderBy !==
                          AnsweredSheetsListOrderByEnum.PROFESSIONAL_NAME,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </span>
              </AnswersListHeaderRow>
            </AnswersListHeader>
            <AnswersListBody>
              {sheetSubmissionsList.map(
                ({
                  id,
                  date,
                  patientId,
                  patientName,
                  sheetPatientId,
                  submissionId,
                  professionalName,
                }) => (
                  <AnswersListBodyRow key={id}>
                    <AnswersListBodyCell>
                      <StyledLink
                        disabled={!can.viewPatientProfile}
                        to={`/patient/profile?id=${patientId}`}
                      >
                        {patientName}
                      </StyledLink>
                    </AnswersListBodyCell>
                    <AnswersListBodyCell>
                      <span>{date}</span>
                    </AnswersListBodyCell>
                    <AnswersListBodyCell>
                      <span>{professionalName}</span>
                    </AnswersListBodyCell>
                    <AnswersListBodyCell>
                      <button
                        type="button"
                        onClick={() =>
                          handleSheetView({
                            patientId,
                            sheetPatientId,
                            submissionId,
                          })
                        }
                      >
                        <MdRemoveRedEye />
                        Visualizar
                      </button>
                    </AnswersListBodyCell>
                  </AnswersListBodyRow>
                ),
              )}
            </AnswersListBody>
          </AnswersList>
        </Container>
      ) : (
        <ContentNothingToDisplayBox>
          <ContentNothingToDisplay text="Nenhum dado encontrado" />
        </ContentNothingToDisplayBox>
      )}
    </>
  );
};

export default Table;
