import React, { useCallback } from 'react';

import { MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Container } from './styles';

interface InputCopyProps {
  value?: string;
}

const InputCopy: React.FC<InputCopyProps> = ({ value }) => {
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(value || '');
    toast.success('Copiado');
  }, [value]);

  return (
    <Container>
      <span>{value}</span>
      <button type="button" onClick={() => handleCopy()}>
        <MdContentCopy />
      </button>
    </Container>
  );
};

export default InputCopy;
