import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useModal } from 'react-brave-modal';

import { usePatient } from '../../../hooks/patient';
import validationCPF from '../../../utils/validationCPF';

import getValidationErrors from '../../../utils/getValidationErrors';
import getGender from '../../../utils/getGender';
import { useAuth } from '../../../hooks/auth';
import { useCurrentCallback } from '../../../lib/use-current-effect';

import DashboardTemplate from '../../../templates/Dashboard';
import InputMaskAlternative from '../../../components/InputMaskAlternative';
import InputWithoutTitle from '../../../components/InputWithoutTitle';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import RequiredFieldInformation from '../../../components/RequiredFieldInformation';
import InputWithoutTitleAndFormattedToLower from '../../../components/InputWithoutTitleAndFormattedToLower';
import FinishPatientRegisterModal from './modals/FinishPatientRegisterModal';

import {
  Button,
  ButtonsContainer,
  Container,
  Field,
  FormContainer,
  GridContent,
  InputSelect,
} from './styles';
import MainAreasEnum from '../../../enums/MainAreasEnum';

interface RegisterFormProps {
  personalData: {
    name: string;
    profileType: string;
    cpf: string;
    birthday?: string;
    gender?: 'M' | 'F' | 'ND';
    email: string;
    phone?: string;
  };
  patientData: {
    referredBy?: string;
    bandId?: string;
  };
}

const Register: React.FC = () => {
  const history = useHistory();
  const { createPatient } = usePatient();
  const { showModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [genders] = useState(getGender);

  const { user } = useAuth();

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async (data: RegisterFormProps) => {
      setIsButtonDisabled(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          personalData: Yup.object().shape({
            name: Yup.string()
              .required('O nome é obrigatório')
              .test(
                'nome',
                'O nome deve ter pelo menos duas palavras',
                (value: string) => {
                  const words = value.trim().split(' ');
                  return words.length > 1 && words.every((i) => i);
                },
              ),
            cpf: Yup.string()
              .length(14, 'Digite um CPF válido')
              .test('cpf', 'Digite um CPF válido', (value) =>
                validationCPF(value),
              )
              .required('O CPF é obrigatório'),
            birthday: Yup.date().required('Data nascimento obrigatória'),
            email: Yup.string().email('Digite um email válido'),
            phone: Yup.string()
              .length(16, 'Digite um número de telefone válido')
              .required('O telefone é obrigatório'),
          }),
          patientData: Yup.object().shape({
            referredBy: Yup.string(),
            bandId: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.personalData.profileType = 'patient';

        const patientData = {
          type: 'patient',
          ...data,
          personalData: {
            ...data.personalData,
            email: data.personalData.email.trim().toLowerCase(),
          },
          createdUpdatedBy: user?.id,
        };

        await createPatient({ ...patientData });

        if (isCurrent()) {
          setIsButtonDisabled(false);
        }

        showModal({
          type: 'custom',
          data: <FinishPatientRegisterModal />,
        });
      } catch (err) {
        if (isCurrent()) {
          setIsButtonDisabled(false);
        }

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }
    },
    [createPatient, showModal, user],
  );

  const pages = useMemo<ElementPageTree[]>(
    () => [
      { name: 'Pacientes', link: '/patient/list' },
      { name: 'Novo Paciente' },
    ],
    [],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.PATIENTS}>
      <PageTree pages={pages} />
      <Container>
        <h1>Novo Paciente</h1>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <GridContent>
              <Scope path="personalData">
                <Field gridArea="no">
                  <label>
                    Nome
                    <RequiredFieldInformation />
                  </label>
                  <InputWithoutTitle name="name" />
                </Field>
                <Field gridArea="cpf">
                  <label>
                    CPF
                    <RequiredFieldInformation />
                  </label>
                  <InputMaskAlternative
                    type="text"
                    name="cpf"
                    title=""
                    mask="999.999.999-99"
                    placeholder="000.000.000-00"
                  />
                </Field>
                <Field gridArea="dn">
                  <label>
                    Data de Nascimento
                    <RequiredFieldInformation />
                  </label>
                  <InputWithoutTitle
                    className="datepicker"
                    type="date"
                    name="birthday"
                    max={new Date().toISOString().split('T')[0]}
                  />
                </Field>
                <Field gridArea="gn">
                  <label>Gênero</label>
                  <InputSelect name="gender" options={genders} placeholder="" />
                </Field>
                <Field gridArea="em">
                  <label>E-mail</label>
                  <InputWithoutTitleAndFormattedToLower name="email" />
                </Field>
                <Field gridArea="cl">
                  <label>
                    Celular (DDD + Número)
                    <RequiredFieldInformation />
                  </label>
                  <InputMaskAlternative
                    type="text"
                    name="phone"
                    title=""
                    mask="(99) 9 9999-9999"
                    placeholder="(00) 0 0000-0000"
                  />
                </Field>
              </Scope>
              {/* <Scope path="patientData">
                <Field gridArea="ep">
                  <label>Encaminhado por:</label>
                  <InputWithoutTitle name="referredBy" />
                </Field>
                <SearchButton gridArea="bu">
                  <img src={SearchIcon} alt="Buscar" />
                </SearchButton>
                <Field gridArea="idp">
                  <label>ID da Pulseira</label>
                  <InputWithoutTitle name="bandId" />
                </Field>
              </Scope>
              <SearchButton gridArea="but">
                <img src={SearchIcon} alt="Buscar" />
                Buscar
              </SearchButton> */}
            </GridContent>
          </Form>
        </FormContainer>
      </Container>
      <ButtonsContainer>
        <Button color="white" onClick={() => history.push('/patient/list')}>
          Cancelar
        </Button>
        <Button
          loading={isButtonDisabled}
          color="primary"
          onClick={() => formRef.current?.submitForm()}
          type="submit"
        >
          Cadastrar
        </Button>
      </ButtonsContainer>
    </DashboardTemplate>
  );
};

export default Register;
