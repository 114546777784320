/* eslint-disable import/no-duplicates */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Chart } from 'react-google-charts';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useCurrentEffect } from '../../../../../../lib/use-current-effect';

import { usePatient } from '../../../../../../hooks/patient';
import { getMeasurementsByDay } from '../../../../../../services/metrics';
import { MetricTypeEnum } from '../../../../../../enums/MetricTypeEnum';

import MeasurementsArea from '../../MeasurementsArea';

import { ChildrenProps, DataChildrenProps, ChildrenRefProps } from '../..';

const BloodOxygenationChart: React.ForwardRefRenderFunction<
  ChildrenRefProps,
  ChildrenProps
> = ({ dateFilter }, ref) => {
  const { patient } = usePatient();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<DataChildrenProps>();
  const [oxGraphData, setOxGraphData] = useState<(string | number)[][]>([
    ['00:00', 0],
  ]);

  const handleChange = useCallback(
    async (patientId?: string, isCurrent = () => true) => {
      setIsLoading(true);
      if (!patientId) {
        return;
      }
      const response = await getMeasurementsByDay({
        patientId,
        metricType: MetricTypeEnum.BLOOD_OXYGEN,
        dateFilter,
      });
      if (response.length > 0) {
        const lastRecordTime = format(
          new Date(response[response.length - 1].mensuramentDate),
          "dd'/'MM'/'yyyy', ' HH'h'mm",
          { locale: ptBR },
        );

        const result = response.map(({ bloodOxygen, mensuramentDate }) => [
          format(new Date(mensuramentDate), 'HH:mm', {
            locale: ptBR,
          }),
          bloodOxygen,
        ]);

        const value = result[result.length - 1][1].toString();

        if (isCurrent()) {
          setOxGraphData([...result]);
          setData({
            lastRecordTime,
            value,
          });
        }
      } else if (isCurrent()) {
        setOxGraphData([['00:00', 0, 0]]);
        setData(undefined);
      }

      setIsLoading(false);
    },
    [dateFilter],
  );

  useCurrentEffect(
    (isCurrent) => {
      (() => {
        if (patient?.id) {
          handleChange(patient.id, isCurrent);
        } else {
          setIsLoading(false);
        }
      })();
    },
    [dateFilter, handleChange, patient],
  );

  useImperativeHandle(ref, () => ({
    reload: () => handleChange(patient?.id),
  }));

  return (
    <MeasurementsArea
      data={{
        styleData: 'chart',
        isLoading,
        cardTitle: 'Oxigênio (SpO2%)',
        dataDetails: data
          ? {
              title: 'último registro:',
              position: 'center',
              lastRecordTime: data.lastRecordTime,
              type: '%',
              value: data.value,
              inAlert: false,
            }
          : undefined,
      }}
    >
      <Chart
        width="107%"
        height="110%"
        chartType="LineChart"
        style={{
          marginLeft: '-10px',
          marginTop: '-50px',
        }}
        options={{
          legend: 'none',
          colors: ['#E12F54'],
          vAxis: {
            ticks: [80, 85, 90, 95, 100],
          },
          series: {
            0: { curveType: 'function' },
          },
        }}
        data={[['h', 'SpO2%'], ...oxGraphData]}
        rootProps={{ 'data-testid': '3' }}
      />
    </MeasurementsArea>
  );
};

export default forwardRef(BloodOxygenationChart);
