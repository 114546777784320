import styled from 'styled-components';

interface FieldProps {
  gridArea: 'ce' | 'ru' | 'nu' | 'cm' | 'ci' | 'di' | 'uf';
}

export const Container = styled.div`
  min-height: 350px;
  width: 100%;
  padding-right: 80px;

  > h1 {
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;

    color: #707683;
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(9, 1fr);
  grid-template-areas:
    'ce ce ce ru ru ru ru ru ru'
    'nu nu cm cm cm ci ci ci ci'
    'di di di di uf uf uf . .';

  grid-column-gap: 68px;
  grid-row-gap: 29px;

  margin-top: 28px;
  width: 100%;
  height: 100%;
`;

export const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: column;
  grid-area: ${({ gridArea }) => gridArea};

  > label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.01em;

    color: #707683;
    white-space: nowrap;

    > span {
      color: ${({ theme }) => theme.colors.danger};
      font-size: 18px;
      font-weight: 600;
      line-height: 0.8;
    }
  }

  input {
    color: ${({ theme }) => theme.colors.graySecondary};
    font-size: 14px;
  }

  > div {
    margin-top: 4px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .cep {
    display: flex;

    button {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.primary};
      background-color: transparent;
      border: none;
      font-size: 32px;
      margin-left: 10px;
    }
  }
`;
