import React, { useState, useCallback } from 'react';
import { RiInformationFill } from 'react-icons/ri';

import { usePushNotification } from '../../../../hooks/pushNotification';

import { Container, Button } from './styles';

const ActivePushNotifications: React.FC = () => {
  const { enablePushNotifications } = usePushNotification();
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  const { permission } = Notification;

  const teste = useCallback(() => {
    setButtonIsDisabled(true);

    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        enablePushNotifications();
      } else {
        setButtonIsDisabled(false);
      }
    });
  }, [enablePushNotifications]);

  return (
    <>
      {permission === 'default' && (
        <Container>
          <Button type="button" disabled={buttonIsDisabled} onClick={teste} />

          <p>Ativar notificações na área de trabalho</p>
        </Container>
      )}

      {permission === 'denied' && (
        <Container>
          <RiInformationFill size={25} />

          <p>
            As notificações da área de trabalho estão bloqueadas. Acesse as
            configurações do seu navegador para habilitá-las novamente.
          </p>
        </Container>
      )}
    </>
  );
};

export default ActivePushNotifications;
