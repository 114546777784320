import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  lazy,
  Suspense,
} from 'react';

// import { BsThreeDotsVertical } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useModal } from 'react-brave-modal';
import { useCurrentCallback } from '../../../../lib/use-current-effect';

import {
  Container,
  // Options,
  CardTitle,
  CardHeader,
  CardContent,
  Row,
  LeftSide,
  RightSide,
} from './styles';

import VisibilityIcon from '../../../../assets/images/visibility.svg';
import FileIcon from '../../../../assets/images/file.svg';
import DangerIcon from '../../../../assets/images/danger.svg';
import TrashIcon from '../../../../assets/images/trash.svg';
import EditIcon from '../../../../assets/images/edit.svg';
import CheckOkIcon from '../../../../assets/images/check-ok.svg';
import CloudFileGrayImage from '../../../../assets/images/cloud-file-gray.svg';

import { removeSheet } from '../../../../services/customers';
import { GridCardProps } from '../../../../components/GridCard';
import { usePatient } from '../../../../hooks/patient';
import { SheetPatient } from '../../../../entities/SheetPatient';

import AddButton from '../../../../components/AddButton';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';

const NewSheetModal = lazy(
  () =>
    import(/* webpackChunkName: "NewSheetModal" */ './modals/NewSheetModal'),
);

const SheetSubmissionModal = lazy(
  () =>
    import(
      /* webpackChunkName: "SheetSubmissionModal" */ '../../../../modals/SheetSubmissionModal'
    ),
);

const Sheets: React.FC<GridCardProps> = ({ gridArea }) => {
  const { patient } = usePatient();
  const { showModal } = useModal();

  const [sheets, setSheets] = useState<SheetPatient[]>([]);

  const isPatientActive = useMemo(
    () => patient?.user?.currentStatus?.status === 'active',
    [patient],
  );

  const handleRemoveSheet = useCurrentCallback(
    (isCurrent) => async (id: string) => {
      const canRemove = await removeSheet(id);

      if (canRemove && isCurrent()) {
        setSheets((state) => state.filter((sheet) => sheet.id !== id));
      } else if (!canRemove) {
        toast.error('Tivemos um problema. Tente novamente mais tarde');
      }
    },
    [],
  );

  useEffect(() => {
    patient?.sheets && setSheets([...patient.sheets]);
  }, [patient]);

  const handleCreateSheet = useCallback(() => {
    showModal({
      type: 'custom',
      data: (
        <Suspense fallback={false}>
          <NewSheetModal />
        </Suspense>
      ),
    });
  }, [showModal]);

  const handleEditSheet = useCallback(
    (id: string) => {
      showModal({
        type: 'custom',
        data: (
          <Suspense fallback={false}>
            <SheetSubmissionModal id={id} type="edit" />
          </Suspense>
        ),
      });
    },
    [showModal],
  );

  const handleViewSheet = useCallback(
    (id: string) => {
      showModal({
        type: 'custom',
        data: (
          <Suspense fallback={false}>
            <SheetSubmissionModal id={id} type="view" />
          </Suspense>
        ),
      });
    },
    [showModal],
  );

  return (
    <Container gridArea={gridArea}>
      <CardHeader>
        <CardTitle>Fichas do Paciente</CardTitle>
        <AddButton disabled={!isPatientActive} onClick={handleCreateSheet}>
          Nova
        </AddButton>
        {/* <Options>
          <button type="button">
            <BsThreeDotsVertical size={18} color="#C4C4C4" />
          </button>
        </Options> */}
      </CardHeader>
      <CardContent>
        {sheets.length ? (
          sheets.map((sheet) => (
            <Row key={sheet.id}>
              <LeftSide>
                <img src={FileIcon} alt="Ícone de arquivo" />
                <p>{sheet.sheet.name}</p>
              </LeftSide>
              <RightSide>
                {sheet.status === 'received' ? (
                  <>
                    <button
                      type="button"
                      onClick={() => handleViewSheet(sheet.id)}
                    >
                      <img src={VisibilityIcon} alt="Ícone de visualização" />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleEditSheet(sheet.id)}
                    >
                      <img src={EditIcon} alt="Ícone de edição" />
                    </button>
                    <img src={CheckOkIcon} alt="Ícone de check/ok" />
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        handleRemoveSheet(sheet.id);
                      }}
                    >
                      <img src={TrashIcon} alt="Ícone de lixeira" />
                    </button>
                    <img src={DangerIcon} alt="Ícone de alerta" />
                  </>
                )}
              </RightSide>
            </Row>
          ))
        ) : (
          <CardContentNothingToDisplay imageWidth="140px">
            <img src={CloudFileGrayImage} alt="" />
            Sem informações cadastradas
          </CardContentNothingToDisplay>
        )}
      </CardContent>
    </Container>
  );
};

export default Sheets;
