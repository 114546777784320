import { UserListRequestDTO } from '../../dtos/UserListRequestDTO';
import { UserListResponseDTO } from '../../dtos/UserListResponseDTO';
import { api } from '../api';

export const getUsersList = async ({
  page,
  filters,
  filtersDifferents,
  ordenationType,
  orderBy,
}: UserListRequestDTO): Promise<UserListResponseDTO> => {
  const { data } = await api.get(`/users/list/${page}`, {
    params: {
      equals: {
        ...filters,
      },
      differents: {
        ...filtersDifferents,
      },
      orderBy,
      ordenationType,
    },
  });

  return data;
};
