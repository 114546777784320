import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import {
  ComponentsInstitutionRegisterEditProps,
  ComponentsInstitutionRegisterEditRef,
} from '..';
import InputMaskAlternative from '../../../../components/InputMaskAlternative';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import InputWithoutTitleAndFormattedToLower from '../../../../components/InputWithoutTitleAndFormattedToLower';
import RequiredFieldInformation from '../../../../components/RequiredFieldInformation';
import { useInstitution } from '../../../../hooks/institution';
import getInstitutionType from '../../../../utils/getInstitutionType';
import getValidationErrors from '../../../../utils/getValidationErrors';

import { Container, GridContent, Field } from './styles';
import { useCurrentCallback } from '../../../../lib/use-current-effect';
import validationCNPJ from '../../../../utils/validationCNPJ';
import InstitutionTypeEnum from '../../../../enums/InstitutionTypeEnum';

interface GeneralDataProps {
  type: InstitutionTypeEnum;
  name: string;
  email: string;
  phone: string;
  cnpj: string;
  others?: string;
}

const GeneralData: React.ForwardRefRenderFunction<
  ComponentsInstitutionRegisterEditRef,
  ComponentsInstitutionRegisterEditProps
> = ({ onSaveSucceed }, ref) => {
  const { saveLocal, institution } = useInstitution();
  const formRef = useRef<FormHandles>(null);
  const [othersSelected, setOthersSelected] = useState(false);

  const institutionTypeOptions = useMemo(() => getInstitutionType, []);

  useEffect(() => {
    if (institution) {
      formRef.current?.setData({
        type: institution.type,
        name: institution.name,
        email: institution.email,
        phone: institution.phone,
        cnpj: institution.cnpj,
      });
    }
  }, [institution]);

  const otherType = useMemo<string | undefined>(
    () => (othersSelected ? institution?.othersType : ''),
    [othersSelected, institution],
  );

  const handleSelectChange = useCallback(
    (selected) => setOthersSelected(selected.value === 'Outros'),
    [],
  );

  const getValidate = useCallback(async () => {
    formRef.current?.setErrors({});

    const generalData = formRef.current?.getData() as GeneralDataProps;

    try {
      const schema = Yup.object().shape({
        type: Yup.string().required('O tipo da unidade é obrigatória'),
        name: Yup.string().required('O nome é obrigatório'),
        email: Yup.string()
          .required('O e-mail é obrigatório')
          .email('Digite um email válido'),
        phone: Yup.string()
          .required('O número de telefone é obrigatório')
          .length(16, 'Digite um número de telefone válido'),
        cnpj: Yup.string()
          .length(18, 'Digite um CNPJ válido')
          .test('cnpj', 'Digite um CNPJ válido', (value) =>
            validationCNPJ(value),
          )
          .required('O CNPJ é obrigatório'),
        others: Yup.string().when('type', {
          is: 'Outros',
          then: Yup.string().required('Campo é obritatório'),
        }),
      });

      await schema.validate(generalData, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      } else if (err.response) {
        toast.error(err.response.data.message);
      } else if (err.message) {
        toast.error(err.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }

      return false;
    }
  }, []);

  const handleNext = useCurrentCallback(
    (isCurrent) => async () => {
      if (isCurrent()) {
        const data: GeneralDataProps = formRef.current?.getData() as GeneralDataProps;

        saveLocal({
          type: data.type,
          name: data.name,
          email: data.email,
          phone: data.phone,
          cnpj: data.cnpj,
          othersType: data?.others,
        });
        return true;
      }

      return false;
    },
    [saveLocal],
  );

  useImperativeHandle(ref, () => ({
    back: async () => false,
    cancel: async () => true,
    next: handleNext,
    save: async () => true,
    canSave: getValidate,
  }));

  return (
    <Container>
      <h1>Tipo e nome</h1>
      <Form ref={formRef} onSubmit={() => {}}>
        <GridContent>
          <Field gridArea="un">
            <label>
              Selecione o tipo da unidade
              <RequiredFieldInformation />
            </label>
            <InputSelectAlternative
              name="type"
              options={institutionTypeOptions}
              placeholder=""
              onChange={handleSelectChange}
            />
          </Field>
          {othersSelected && (
            <Field gridArea="oth">
              <label>
                Digite o tipo:
                <RequiredFieldInformation />
              </label>
              <InputWithoutTitle name="others" defaultValue={otherType} />
            </Field>
          )}
          <Field gridArea="na">
            <label>
              Digite o nome da unidade:
              <RequiredFieldInformation />
            </label>
            <InputWithoutTitle name="name" />
          </Field>
          <Field gridArea="cnpj">
            <label>
              CNPJ
              <RequiredFieldInformation />
            </label>
            <InputMaskAlternative
              type="text"
              name="cnpj"
              title=""
              mask="99.999.999/9999-99"
              placeholder="00.000.000/0000-00"
            />
          </Field>
          <Field gridArea="ma">
            <label>
              E-mail
              <RequiredFieldInformation />
            </label>
            <InputWithoutTitleAndFormattedToLower name="email" />
          </Field>
          <Field gridArea="ph">
            <label>
              Telefone
              <RequiredFieldInformation />
            </label>
            <InputMaskAlternative
              type="text"
              name="phone"
              title=""
              mask="(99) 9 9999-9999"
              placeholder="(00) 0 0000-0000"
            />
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default forwardRef(GeneralData);
