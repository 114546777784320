import styled, { css } from 'styled-components';

interface ContainerProps {
  styleType: 'gray' | 'blue-light' | 'blue';
  loading?: boolean;
}

export const Container = styled.button<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  min-width: 135px;

  transition: background 0.4s;
  background: transparent;

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  border-radius: 100px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  ${({ styleType }) =>
    styleType === 'gray' &&
    css`
      border: 1px solid #bdbdbd;
      color: #828282;

      &:hover {
        background: #f9f9f9;
      }
    `}

  ${({ styleType }) =>
    styleType === 'blue-light' &&
    css`
      border: 1px solid #2d9cdb;
      color: #2d9cdb;

      &:hover {
        background: #2d9cdb;
        color: #fff;
      }
    `}

  ${({ styleType }) =>
    styleType === 'blue' &&
    css`
      border: 1px solid #2d9cdb;
      background: #2d9cdb;
      color: #ffffff;
      font-weight: bold;

      &:hover {
        background: #278bc4;
      }
    `}

    ${({ loading }) =>
    loading &&
    css`
      pointer-events: none;
    `}
`;
