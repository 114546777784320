import styled from 'styled-components';

export const Video = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ id }) =>
    id === 'localVideo' ? '#333333' : '#000000'};
`;

export const Audio = styled.div`
  display: none;
`;
