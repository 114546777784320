import styled from 'styled-components';
import { Form as FormUnform } from '@unform/web';

export const Container = styled.div`
  margin-top: 50px;
  background: #fcfcfc;
  border-radius: 6px;
  padding: 30px 25px;
  margin-bottom: 20px;

  > h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #4f4f4f;
  }

  > footer {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: 1px solid #2d9cdb;
      box-sizing: border-box;
      border-radius: 50px;
      padding: 15px 40px;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #2d9cdb;

      > svg {
        margin-right: 7px;
      }

      &:hover {
        background: whitesmoke;
      }
    }
  }
`;

export const Form = styled(FormUnform)`
  margin-top: 23px;
  display: flex;

  > div {
    flex: 1;

    > label {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
      color: #707683;
    }

    > div {
      margin-top: 6px;
    }
  }
`;
