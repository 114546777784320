import React from 'react';

import { useModal } from 'react-brave-modal';

import { Container, Content } from '../styles';

import BlockedAccessImage from '../../../../../../assets/images/blocked-access.svg';
import Button from '../../../../../../components/Button';

interface ConfirmPatientDeactivationModalProps {
  onSuccess: () => any;
}

const ConfirmPatientDeactivationModal: React.FC<ConfirmPatientDeactivationModalProps> = ({
  onSuccess,
}) => {
  const { closeModal } = useModal();

  return (
    <Container>
      <Content>
        <div>
          <img src={BlockedAccessImage} alt="" />
          <div>
            <h1>Atenção! O paciente será desativado da plataforma</h1>

            <p>
              O paciente não poderá mais receber atendimentos e receberá o
              status inativo.
            </p>
            <p>Deseja confirmar a operação?</p>
            <div>
              <Button
                onClick={() => {
                  closeModal();
                }}
                buttonType="primary-alternative"
              >
                Não, cancelar
              </Button>
              <Button
                onClick={() => {
                  onSuccess();
                  closeModal();
                }}
                buttonType="primary"
              >
                Sim, confirmar
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default ConfirmPatientDeactivationModal;
