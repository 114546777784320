import React, { useCallback } from 'react';
import { useModal } from 'react-brave-modal';

import AddEditModal from '../AddEditModal';
import Banner from '../../../../../../assets/images/check-ok-2.svg';
import Button from '../../../../../../components/Button';
import { ExamProcedure } from '../../../../../../entities/ExamProcedure';

import { Container } from './styles';
import SeeAllModal from '../SeeAllModal';

interface SuccessfulModalProps {
  areYouEditing: boolean;
  handleUpdateList: (examProcedure: ExamProcedure) => void;
  handleRemoveList: (examProcedure: ExamProcedure) => void;
}

const SuccessfulModal: React.FC<SuccessfulModalProps> = ({
  areYouEditing,
  handleUpdateList,
  handleRemoveList,
}) => {
  const { showModal, closeModal } = useModal();

  const handleResubmission = useCallback(() => {
    showModal({
      type: 'custom',
      data: (
        <AddEditModal
          handleRemoveList={handleRemoveList}
          handleUpdateList={handleUpdateList}
        />
      ),
    });
  }, [showModal, handleUpdateList, handleRemoveList]);

  const handleReturn = useCallback(() => {
    if (handleUpdateList) {
      showModal({
        type: 'custom',
        data: (
          <SeeAllModal
            handleRemoveList={handleRemoveList}
            handleUpdateList={handleUpdateList}
          />
        ),
      });
    }
  }, [handleUpdateList, handleRemoveList, showModal]);

  return (
    <Container>
      <div>
        <img src={Banner} alt="Ícone OK" />
        <h1>Arquivo {areYouEditing ? 'editado' : 'salvo'} com sucesso</h1>
      </div>

      <div>
        {areYouEditing ? (
          <Button buttonType="primary" onClick={handleReturn}>
            Listar
          </Button>
        ) : (
          <Button buttonType="primary" onClick={handleResubmission}>
            Enviar novo
          </Button>
        )}
        <Button buttonType="primary-alternative" onClick={closeModal}>
          Fechar
        </Button>
      </div>
    </Container>
  );
};

export default SuccessfulModal;
