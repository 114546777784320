import React from 'react';

import { Container } from '../styles';

interface TotalPatientsProps {
  totalPatients: number;
}

const TotalPatients: React.FC<TotalPatientsProps> = ({ totalPatients }) => {
  return (
    <Container gridArea="tp">
      <span>{totalPatients}</span>
      <label>Total de pacientes</label>
    </Container>
  );
};

export default TotalPatients;
