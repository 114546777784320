import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 74px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 100px;
  color: ${(props) => props.theme.colors.primary};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;

  &:disabled {
    background-color: ${(props) => props.theme.colors.white};
    opacity: 0.6;
    cursor: not-allowed;
  }

  > svg {
    margin-right: 3px;
  }
`;
