import React from 'react';

import { useModal } from 'react-brave-modal';

import { Container, Content } from '../styles';

import BlockedAccessImage from '../../../../../../assets/images/exclamation.svg';
import Button from '../../../../../../components/Button';

interface ConfirmPatientReactivationModalProps {
  onSuccess: () => any;
}

const ConfirmPatientReactivationModal: React.FC<ConfirmPatientReactivationModalProps> = ({
  onSuccess,
}) => {
  const { closeModal } = useModal();

  return (
    <Container>
      <Content>
        <div>
          <img src={BlockedAccessImage} alt="" />
          <div>
            <h1>Atenção! Você está prestes a reativar um paciente</h1>
            <p>
              Este paciente encontra-se inativo no nosso sistema. Se você
              reativá-lo, esta operação será registrada e o paciente poderá
              voltar a receber atendimentos.
            </p>
            <p>Deseja prosseguir com essa atualização?</p>
            <div>
              <Button
                onClick={() => {
                  closeModal();
                }}
                buttonType="primary-alternative"
              >
                Não, cancelar
              </Button>
              <Button
                onClick={() => {
                  onSuccess();
                  closeModal();
                }}
                buttonType="primary"
              >
                Sim, confirmar
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default ConfirmPatientReactivationModal;
