import { CouncilType } from '../entities/Council';

interface GetPermitionsInUserEditProfessionalData {
  [key: string]: {
    councilType: CouncilType;
    quantityCouncils: number;
    quantitySpecialties: number;
  };
}

const getPermitionsInUserEditProfessionalData: GetPermitionsInUserEditProfessionalData = {
  Medicina: {
    councilType: 'CRM',
    quantityCouncils: 27,
    quantitySpecialties: 3,
  },
  Enfermagem: {
    councilType: 'COREN',
    quantityCouncils: 1,
    quantitySpecialties: 0,
  },
};

export default getPermitionsInUserEditProfessionalData;
