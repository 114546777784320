import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Props as SelectProps, SelectInstance } from 'react-select';
import { useField } from '@unform/core';

import { Select, Container } from './styles';
import DropdownIndicator from './DropdownIndicator';
import { OptionType } from '../../@types/react-select';

type SelectRef = SelectInstance<OptionType>;

interface Props extends SelectProps {
  name: string;
  disabled?: boolean;
  isCentered?: boolean;
}

const InputSelectAlternative: React.FC<Props> = ({
  name,
  disabled,
  options,
  ...rest
}) => {
  const selectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: SelectRef) => {
        const refValue = ref.getValue();

        if (rest.isMulti) {
          return refValue ? refValue.map(({ value }) => value) : [];
        }

        return refValue ? refValue[0]?.value ?? '' : '';
      },
    });
  }, [fieldName, registerField, rest]);

  return (
    <Container>
      <Select
        defaultValue={
          defaultValue &&
          options?.find((option: any) => option.value === defaultValue)
        }
        options={options}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        ref={selectRef}
        classNamePrefix="react-select"
        isDisabled={disabled}
        isErrored={!!error}
        isFocused={isFocused}
        aria-haspopup="true"
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        {...rest}
      />
    </Container>
  );
};

export default InputSelectAlternative;
