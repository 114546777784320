import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 781px;
  width: 100%;
  padding: 100px 70px;

  > div {
    > img {
      width: 135px;
    }

    > h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;
      color: #2d9cdb;
    }

    > button {
      margin-top: 30px;
    }
  }

  > div + div {
    margin-left: 70px;
  }
`;
