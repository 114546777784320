import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  background: #fff;
  border-radius: 6px;
  position: relative;
  padding: 12px 16px;
  width: 100%;

  border: 1px solid #e0e0e0;
  color: #828282;

  display: flex;
  align-items: center;

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #4f4f4f;
      border-color: ${props.theme.colors.primary};
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #4f4f4f;
    `}



  textarea {
    flex: 1;
    border: 0;
    background: transparent;
    color: ${(props) => props.theme.colors.graySecondary};

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    resize: none;

    line-height: 1.1;
    max-width: 80vw;
    max-height: 40vh;
    height: 40vh;

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &::placeholder {
      color: #828282;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
