/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { initializeFirebase } from './firebase';

console.log(
  '%c AVISO - Equipe Cinco ',
  'color: #fff; font-family:sans-serif; font-size: 20px; background: #2D9CDB;',
);
console.log(
  '%cÉ possível que o uso deste console permita que invasores falsifiquem sua identidade para roubar informações por meio de um ataque chamado Self-XSS. Não insira nem cole códigos que você não conhece.',
  'color: #2D9CDB; font-family:sans-serif; font-size: 20px;',
);
console.log(
  '%c AVISO - Equipe Cinco ',
  'color: #fff; font-family:sans-serif; font-size: 20px; background: #2D9CDB;',
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// initializeFirebase();
