import { api } from '../api';

interface ValidatePatientDataService {
  id?: string;
  email?: string;
  cpf?: string;
  phone: string;
}

export const validatePatientDataService = async (
  patientData: ValidatePatientDataService,
): Promise<boolean> => {
  const { data } = await api.post('/patients/validatePatientData', patientData);

  return data;
};
