import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-weight: 600;
`;

export const IsLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 300px;
`;

export const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 300px;

  p {
    color: #707683;
    font-size: 12px;
    font-weight: 600;
    margin-top: 20px;
  }
`;
