import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MdRemoveRedEye } from 'react-icons/md';
import Avatar from '../../../../assets/images/avatar.svg';
import { User } from '../../../../entities/User';

import {
  TopBar,
  ShowBtn,
  ColorNumber,
  Container,
  UserList,
  ProfileTitleBadge,
  ActionLinkTable,
  PhotoRow,
  ChangeOrderButton,
  UserListBodyRow,
  UserListHeaderRow,
  UserListCell,
  UserListCheckboxCell,
  UserListHeaderCell,
  UserListBodyButtonCell,
  UserListCollapsableTextCell,
} from './styles';
import ActionMenuButtonList from '../../../../components/ActionMenuButtonList';
import { ActionMenuListProps } from '../../../../components/ActionMenuButton';
import ActionMenuButtonItemList from '../../../../components/ActionMenuButtonItemList';
import SelectedItemsInList from '../../../../components/SelectedItemsInList';

import { ReactComponent as ArrowUpDownIcon } from '../../../../assets/images/arrowupdown.svg';
import { ReactComponent as TurnOffIcon } from '../../../../assets/images/turn-on-off.svg';
import UserStatus from '../../../../entities/UserStatus';
import InputCheckWithoutForm from '../../../../components/InputCheckWithoutForm';
import { getAvatarImage } from '../../../../services/user';
import { UserFiltersDTO } from '../../../../dtos/UserFiltersDTO';
import { OrderBy } from '..';
import { usePermission } from '../../../../hooks/permission';
import { useAuth } from '../../../../hooks/auth';
import Pagination from '../../../../components/Pagination';
import { useCurrentEffect } from '../../../../lib/use-current-effect';

interface TableProps {
  list: User[];
  setList(list: React.SetStateAction<User[]>): void;
  currentPage: number;
  setCurrentPage(currentPage: React.SetStateAction<number>): void;
  totalData: number;
  totalPages: number;
  filters: UserFiltersDTO;
  setFilters(filters: React.SetStateAction<UserFiltersDTO>): void;
  orderBy: OrderBy;
  setOrderBy(value?: React.SetStateAction<OrderBy>): void;
  ordenationType?: 'ASC' | 'DESC';
  setOrdenationType(value?: React.SetStateAction<'ASC' | 'DESC'>): void;
}

interface ImageList {
  id: string;
  avatar: string;
}

interface Item {
  name: string;
  isHealthProfessional: boolean;
  role?: string;
  uf?: string;
  city?: string;
  currentStatus?: UserStatus;
  profileTitle?: string;
  isChecked: boolean;
  id?: string;
  avatar?: string;
}

const UserTable: React.FC<TableProps> = ({
  list,
  currentPage,
  setCurrentPage,
  totalData,
  totalPages,
  filters,
  setFilters,
  orderBy,
  setOrderBy,
  ordenationType,
  setOrdenationType,
}) => {
  const history = useHistory();
  const location = useLocation();

  const { hasPermission } = usePermission();
  const { user: userAuth } = useAuth();
  const [listDisplay, setListDisplay] = useState<Item[]>([]);
  const [showAll, setShowAll] = useState(true);
  const [showHealthProfessional, setShowHealthProfessional] = useState(false);
  const [imageList, setImageList] = useState<ImageList[]>([]);

  useCurrentEffect(
    (isCurrent) => {
      Promise.all(
        list.map(async (item) => ({
          id: item.id ?? '',
          avatar: item.avatar ? await getAvatarImage(item.avatar) : Avatar,
        })),
      ).then((imageListResult) => {
        if (isCurrent()) {
          setImageList(imageListResult);
        }
      });
    },
    [list],
  );

  const can = useMemo(
    () => ({
      editOtherUsers: hasPermission('edit_other_users'),
      editMyself: hasPermission('edit_my_details'),
      viewProfile: hasPermission('user_profile'),
    }),
    [hasPermission],
  );

  const allItemsChecked = useMemo(
    () => listDisplay.every((item) => item.isChecked),
    [listDisplay],
  );

  const selectedItemsCount = useMemo(
    () =>
      listDisplay
        .map<number>((item) => (item.isChecked ? 1 : 0))
        .reduce((total, item) => total + item, 0),
    [listDisplay],
  );

  const handleCheckAll = useCallback(
    (value: boolean) => {
      const newListDisplay = listDisplay.map((item) => {
        item.isChecked = value;
        return item;
      });

      setListDisplay([...newListDisplay]);
    },
    [listDisplay],
  );

  const handleCheck = useCallback(
    (key: string, value: boolean) => {
      const newListDisplay = listDisplay.map((item) => {
        if (item.id === key) {
          item.isChecked = value;
        }

        return item;
      });

      setListDisplay([...newListDisplay]);
    },
    [listDisplay],
  );

  const transformListIntoDisplay = useCallback(
    (listToTransform: User[]) => {
      const newListDisplay: Item[] = listToTransform.map(
        ({
          type,
          name = '',
          profile,
          address,
          currentStatus,
          id,
          professional,
        }) => ({
          isHealthProfessional: type === 'professional',
          name,
          profileTitle: profile?.title?.split(' ')[0],
          city: address?.city,
          uf: address?.uf,
          currentStatus,
          isChecked: false,
          id,
          avatar:
            imageList.find(({ id: userId }) => userId === id)?.avatar ?? Avatar,
          role: professional?.role,
        }),
      );

      setListDisplay([...newListDisplay]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageList],
  );

  const handleOrdenation = useCallback(
    (orderKey: OrderBy, isInitialOrdering: boolean) => {
      if (isInitialOrdering) {
        setOrdenationType('ASC');
      } else {
        setOrdenationType((state) => (state === 'ASC' ? 'DESC' : 'ASC'));
      }

      setOrderBy(orderKey);
    },
    [setOrdenationType, setOrderBy],
  );

  useEffect(() => {
    transformListIntoDisplay(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, imageList]);

  const handleShowAllClick = useCallback(() => {
    if (showAll) {
      return;
    }
    setShowAll(!showAll);
    setShowHealthProfessional(!showHealthProfessional);
    setFilters({ ...filters, type: 'all' });
  }, [filters, setFilters, showAll, showHealthProfessional]);

  const handleShowHealthProfessionalBtnClick = useCallback(() => {
    if (showHealthProfessional) {
      return;
    }
    setShowHealthProfessional(!showHealthProfessional);
    setShowAll(!showAll);
    setFilters({ ...filters, type: 'onlyHealthProfessional' });
  }, [filters, setFilters, showAll, showHealthProfessional]);

  const handleEditOne = useCallback(() => {
    const userToRemove = listDisplay.find((item) => item.isChecked === true);

    history.push(`/user/edit?id=${userToRemove?.id}`);
  }, [listDisplay, history]);

  const verufyUserEdit = useCallback(() => {
    const user = listDisplay.find((item) => item.isChecked === true);

    return user?.id === userAuth?.id ? !can.editMyself : !can.editOtherUsers;
  }, [listDisplay, can, userAuth]);

  const listActionsForOne = useMemo<ActionMenuListProps[]>(
    () => [
      { name: 'Editar', action: handleEditOne, disabled: verufyUserEdit() },
    ],
    [handleEditOne, verufyUserEdit],
  );

  const listActionsForAll = useMemo<ActionMenuListProps[]>(
    () => [{ name: 'Remover', disabled: true }],
    [],
  );

  return (
    <>
      <SelectedItemsInList invisible={selectedItemsCount === 0}>
        {selectedItemsCount === 1
          ? '01 Selecionado'
          : `${selectedItemsCount.toLocaleString('pt-BR', {
              minimumIntegerDigits: 2,
            })} Selecionados`}
      </SelectedItemsInList>
      <TopBar>
        <section>
          <div>Exibir:</div>
          <ShowBtn clicked={showAll} onClick={handleShowAllClick}>
            Todos os Usuários
          </ShowBtn>
          <ShowBtn
            clicked={showHealthProfessional}
            onClick={handleShowHealthProfessionalBtnClick}
          >
            Apenas profissionais de Saúde
          </ShowBtn>
        </section>
        <section>
          <span>Ações</span>
          <ActionMenuButtonList
            disabled={selectedItemsCount === 0}
            list={
              selectedItemsCount === 1
                ? listActionsForOne.concat(listActionsForAll)
                : listActionsForAll
            }
          />
        </section>
        <section>
          <div>
            Exibindo <ColorNumber color="black">{list.length}</ColorNumber>{' '}
            resultados de <ColorNumber color="primary">{totalData}</ColorNumber>
          </div>
        </section>
      </TopBar>
      <Container>
        <UserList>
          <header>
            <UserListHeaderRow>
              <UserListCheckboxCell>
                <InputCheckWithoutForm
                  type="checkbox"
                  checked={allItemsChecked}
                  onChange={(e) => handleCheckAll(e.target.checked)}
                />
              </UserListCheckboxCell>
              <UserListHeaderCell>
                <strong>Nome</strong>
                <ChangeOrderButton
                  isActive={orderBy === 'name'}
                  ordenationType={ordenationType ?? 'DESC'}
                  type="button"
                  onClick={() => handleOrdenation('name', orderBy !== 'name')}
                >
                  <ArrowUpDownIcon />
                </ChangeOrderButton>
              </UserListHeaderCell>
              <UserListHeaderCell>
                <strong>Profissional de Saúde?</strong>
              </UserListHeaderCell>
              <UserListHeaderCell>
                <strong>Função</strong>
                <ChangeOrderButton
                  isActive={orderBy === 'role'}
                  ordenationType={ordenationType ?? 'DESC'}
                  type="button"
                  onClick={() => handleOrdenation('role', orderBy !== 'role')}
                >
                  <ArrowUpDownIcon />
                </ChangeOrderButton>
              </UserListHeaderCell>
              <UserListHeaderCell>
                <strong>Estado</strong>
                <ChangeOrderButton
                  isActive={orderBy === 'uf'}
                  ordenationType={ordenationType ?? 'DESC'}
                  type="button"
                  onClick={() => handleOrdenation('uf', orderBy !== 'uf')}
                >
                  <ArrowUpDownIcon />
                </ChangeOrderButton>
              </UserListHeaderCell>
              <UserListHeaderCell>
                <strong>Cidade</strong>
                <ChangeOrderButton
                  isActive={orderBy === 'city'}
                  ordenationType={ordenationType ?? 'DESC'}
                  type="button"
                  onClick={() => handleOrdenation('city', orderBy !== 'city')}
                >
                  <ArrowUpDownIcon />
                </ChangeOrderButton>
              </UserListHeaderCell>
              <UserListHeaderCell>
                <strong>Perfil</strong>
                <ChangeOrderButton
                  isActive={orderBy === 'profileTitle'}
                  ordenationType={ordenationType ?? 'DESC'}
                  type="button"
                  onClick={() =>
                    handleOrdenation('profileTitle', orderBy !== 'profileTitle')
                  }
                >
                  <ArrowUpDownIcon />
                </ChangeOrderButton>
              </UserListHeaderCell>
              <UserListHeaderCell />
              <UserListHeaderCell />
            </UserListHeaderRow>
          </header>
          <section>
            {listDisplay.map((user) => (
              <UserListBodyRow
                key={user.id}
                disabled={user.currentStatus?.status === 'inactive'}
              >
                <UserListCheckboxCell>
                  <InputCheckWithoutForm
                    type="checkbox"
                    checked={user.isChecked}
                    onChange={({ target: { checked } }) =>
                      handleCheck(user.id ?? '', checked)
                    }
                  />
                </UserListCheckboxCell>
                <UserListCollapsableTextCell>
                  <PhotoRow>
                    <img src={user.avatar} alt={`Foto de ${user.name}`} />
                    <span>{user.name}</span>
                    {user.currentStatus === 'inactive' && <TurnOffIcon />}
                  </PhotoRow>
                </UserListCollapsableTextCell>
                <UserListCell>
                  <span>{user.isHealthProfessional ? 'Sim' : 'Não'}</span>
                </UserListCell>
                <UserListCollapsableTextCell>
                  <span>{user.role}</span>
                </UserListCollapsableTextCell>
                <UserListCell>
                  <span>{user.uf}</span>
                </UserListCell>
                <UserListCollapsableTextCell>
                  <span>{user.city}</span>
                </UserListCollapsableTextCell>
                <UserListCell>
                  <ProfileTitleBadge>{user.profileTitle}</ProfileTitleBadge>
                </UserListCell>
                <UserListBodyButtonCell>
                  <ActionLinkTable
                    disabled={!can.viewProfile}
                    to={`/user/profile?id=${user.id}`}
                  >
                    <MdRemoveRedEye size={25} />
                    <span>visualizar</span>
                  </ActionLinkTable>
                </UserListBodyButtonCell>
                <UserListBodyButtonCell>
                  <ActionMenuButtonItemList
                    alignBox="right"
                    previousLocation={location.pathname}
                    list={[
                      {
                        name: 'Editar',
                        action: `/user/edit?id=${user.id}`,
                        disabled:
                          user.id === userAuth?.id
                            ? !can.editMyself
                            : !can.editOtherUsers,
                      },
                      {
                        name:
                          user.currentStatus?.status === 'active'
                            ? 'Desativar'
                            : 'Ativar',
                        disabled:
                          user.id === userAuth?.id
                            ? !can.editMyself
                            : !can.editOtherUsers,
                        action: `/user/profile?id=${user.id}&selectedMenuOption=Status`,
                      },
                    ]}
                  />
                </UserListBodyButtonCell>
              </UserListBodyRow>
            ))}
          </section>
        </UserList>
      </Container>

      <Pagination
        totalPages={totalPages}
        onChangePage={(page: number) => setCurrentPage(page)}
        page={currentPage}
      />
    </>
  );
};

export default UserTable;
