import React from 'react';

import { useModal } from 'react-brave-modal';
import { Container } from './styles';

import Banner from '../../../../assets/images/check-ok-2.svg';
import Button from '../../../../components/Button';

const Success: React.FC = () => {
  const { closeModal } = useModal();
  return (
    <Container>
      <div>
        <img src={Banner} alt="Ícone OK" />
      </div>
      <div>
        <h1>Alterações salvas com sucesso!</h1>
        <Button buttonType="primary-alternative" onClick={closeModal}>
          Fechar
        </Button>
      </div>
    </Container>
  );
};

export default Success;
