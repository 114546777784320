import React from 'react';

import { useAuth } from '../../../hooks/auth';

import DashboardTemplate from '../../../templates/Dashboard';
import ProfessionalNextAppointments from '../../../components/ProfessionalNextAppointments';
import ProfessionalAppointmentHistory from '../../../components/ProfessionalAppointmentHistory';
import PatientsByHealthConditions from './screens/PatientsByHealthConditions';

import { Container } from './styles';
import AttendanceMonitoring from './screens/AttendanceMonitoring';
import MainAreasEnum from '../../../enums/MainAreasEnum';

const Dashboard: React.FC = () => {
  const { user } = useAuth();

  return (
    <DashboardTemplate name={MainAreasEnum.HOME}>
      <Container>
        <ProfessionalNextAppointments user={user} />
        <ProfessionalAppointmentHistory user={user} />
        {/* <PatientsAlert /> */}
        <PatientsByHealthConditions user={user} />
        <AttendanceMonitoring user={user} />
      </Container>
    </DashboardTemplate>
  );
};

export default Dashboard;
