import styled from 'styled-components';

import InputTextArea from '../../components/InputTextArea';

export const Container = styled.div`
  max-width: 1100px;
  min-width: 880px;
  width: 100%;
  height: 100%;
  padding: 35px 50px 10px;

  > footer {
    display: flex;
    justify-content: flex-end;
    margin: 50px 0 20px;

    > button + button {
      margin-left: 18px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  margin-top: 30px;
  max-height: 50vh;

  > section {
    flex: 1;

    > header {
      > h1 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 18px;
        color: #4f4f4f;
      }

      > h3 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #4f4f4f;
        margin-top: 15px;
      }

      > p {
        padding: 0;
        margin: 5px 0 30px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #828282;
      }
    }

    & + section {
      padding-left: 50px;
      padding-right: 0;
    }
  }
`;

export const InputTitle = styled.h3`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 18px;
  color: #4f4f4f;
  margin: 30px 0 20px !important;
`;

export const Textarea = styled(InputTextArea)`
  max-height: 150px !important;
`;

export const InputNumber = styled.div`
  button {
    display: none;
  }
`;
