import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { IoMdSearch } from 'react-icons/io';
import { format, addHours } from 'date-fns';
import { toast } from 'react-toastify';

import getValidationErrors from '../../../../utils/getValidationErrors';
import validationCPF from '../../../../utils/validationCPF';
import { usePatient } from '../../../../hooks/patient';
import getUF from '../../../../utils/getUF';
import getGender from '../../../../utils/getGender';
import useCep from '../../../../hooks/cep';
import { validatePatientDataService } from '../../../../services/patient';

import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import InputMaskAlternative from '../../../../components/InputMaskAlternative';
import RequiredFieldInformation from '../../../../components/RequiredFieldInformation';
import InputWithoutTitleAndFormattedToLower from '../../../../components/InputWithoutTitleAndFormattedToLower';

import { PersonalDataProps as UsersPersonalDataProps } from '..';

import { Container, Field, GridContent, InputSelect } from './styles';

export interface PersonalDataRef {
  getValues(): UsersPersonalDataProps;
  getValidate(): Promise<boolean>;
}

const PersonalData: React.ForwardRefRenderFunction<PersonalDataRef> = (
  _,
  ref,
) => {
  const { patient } = usePatient();
  const formRef = useRef<FormHandles>(null);
  const { getAddressData, addressData, error } = useCep();

  useEffect(() => {
    if (patient) {
      formRef.current?.setData({
        name: patient.user?.name,
        cpf: patient.user?.cpf,
        birthday: patient?.user?.birthday
          ? format(addHours(new Date(patient?.user?.birthday), 3), 'yyyy-MM-dd')
          : undefined,
        gender: patient.user?.gender,
        email: patient.user?.email,
        street: patient.user?.address?.street,
        number: patient.user?.address?.number,
        complement: patient.user?.address?.complement,
        neighborhood: patient.user?.address?.neighborhood,
        cep: patient.user?.address?.cep,
        city: patient.user?.address?.city,
        uf: patient.user?.address?.uf,
        cns: patient.cns,
        phone: patient.user?.phone,
        whatsapp: patient.user?.whatsapp,
      } as UsersPersonalDataProps);
    }
  }, [patient]);

  const getValues = useCallback(() => {
    const personalData: UsersPersonalDataProps = {
      ...formRef.current?.getData(),
    };

    return personalData;
  }, []);

  const getValidate = useCallback(
    async (personalData: UsersPersonalDataProps) => {
      if (personalData.phone && personalData.phone.indexOf('_') !== 1) {
        personalData.phone = personalData.phone.replace('_', '');
      }

      if (personalData.birthday && personalData.birthday.indexOf('_') !== 1) {
        personalData.birthday = personalData.birthday.replace('_', '');
      }

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('O nome é obrigatório')
            .test(
              'nome',
              'O nome deve ter pelo menos duas palavras',
              (value: string) => {
                const words = value.trim().split(' ');
                return words.length > 1 && words.every((i) => i);
              },
            ),
          cpf: Yup.string()
            .length(14, 'Digite um CPF válido')
            .test('cpf', 'Digite um CPF válido', (value) =>
              validationCPF(value),
            )
            .required('O CPF é obrigatório'),
          birthday: Yup.date().required('Data nascimento obrigatória'),
          email: Yup.string().email('Digite um email válido'),
          address: Yup.string(),
          number: Yup.string(),
          addressDetails: Yup.string(),
          cep: Yup.string().test(
            'length',
            'Digite um CEP válido',
            (value) => !!(!value || value.length === 9),
          ),
          city: Yup.string(),
          cns: Yup.string(),
          phone: Yup.string()
            .length(16, 'Digite um número de telefone válido')
            .required('O telefone é obrigatório'),
          whatsapp: Yup.string().test(
            'length',
            'Digite um número de WhatsApp válido',
            (value) => !!(!value || value.length === 16),
          ),
        });

        await schema.validate(personalData, {
          abortEarly: false,
        });

        await validatePatientDataService({
          id: patient?.user?.id,
          email: personalData?.email?.trim().toLowerCase(),
          cpf: personalData?.cpf,
          phone: personalData.phone ?? '',
        });

        return true;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }

        return false;
      }
    },
    [patient],
  );

  const handleCepSearch = useCallback(async () => {
    await getAddressData(formRef.current?.getFieldValue('cep'));
  }, [getAddressData]);

  useEffect(() => {
    if (addressData) {
      formRef.current?.setFieldValue('street', addressData.street);
      formRef.current?.setFieldValue('complement', addressData.complement);
      formRef.current?.setFieldValue('neighborhood', addressData.neighborhood);
      formRef.current?.setFieldValue('city', addressData.city);
      formRef.current?.setFieldValue('uf', addressData.uf);
    }
  }, [addressData]);

  useEffect(() => {
    if (error) {
      formRef.current?.clearField('street');
      formRef.current?.clearField('complement');
      formRef.current?.clearField('neighborhood');
      formRef.current?.clearField('city');
      formRef.current?.setFieldValue('uf', '');

      toast.error(error);
    }
  }, [error]);

  useImperativeHandle(ref, () => ({
    getValues,
    async getValidate() {
      return getValidate(getValues());
    },
  }));

  return (
    <Container>
      <h1>Dados Pessoais</h1>
      <Form ref={formRef} onSubmit={() => {}}>
        <GridContent>
          <Field gridArea="no">
            <label>
              Nome
              <RequiredFieldInformation />
            </label>
            <InputWithoutTitle name="name" />
          </Field>
          <Field gridArea="cp">
            <label>
              CPF
              <RequiredFieldInformation />
            </label>
            <InputMaskAlternative
              mask="999.999.999-99"
              title=""
              placeholder="000.000.000-00"
              type="text"
              name="cpf"
            />
          </Field>
          <Field gridArea="dn">
            <label>
              Data de nascimento
              <RequiredFieldInformation />{' '}
            </label>
            <InputWithoutTitle
              className="datepicker"
              type="date"
              name="birthday"
              max={new Date().toISOString().split('T')[0]}
            />
          </Field>
          <Field gridArea="gn">
            <label>Gênero</label>
            <InputSelect name="gender" options={getGender} placeholder="" />
          </Field>
          <Field gridArea="em">
            <label>E-mail</label>
            <InputWithoutTitleAndFormattedToLower name="email" />
          </Field>
          <Field gridArea="ce">
            <label>CEP</label>
            <div className="cep">
              <InputMaskAlternative
                mask="99999-999"
                placeholder="00000-000"
                title=""
                type="text"
                name="cep"
              />
              <button type="button" onClick={handleCepSearch}>
                <IoMdSearch />
              </button>
            </div>
          </Field>
          <Field gridArea="en">
            <label>Endereço</label>
            <InputWithoutTitle name="street" />
          </Field>
          <Field gridArea="nm">
            <label>Número</label>
            <InputWithoutTitle name="number" type="number" />
          </Field>
          <Field gridArea="co">
            <label>Complemento</label>
            <InputWithoutTitle name="complement" />
          </Field>
          <Field gridArea="ba">
            <label>Bairro</label>
            <InputWithoutTitle name="neighborhood" />
          </Field>
          <Field gridArea="ci">
            <label>Cidade</label>
            <InputWithoutTitle name="city" />
          </Field>
          <Field gridArea="uf">
            <label>Estado</label>
            <InputSelect name="uf" options={getUF} placeholder="" />
          </Field>
          <Field gridArea="cn">
            <label>Carteira Nacional de Saúde (CNS)</label>
            <InputWithoutTitle name="cns" />
          </Field>
          <Field gridArea="cl">
            <label>
              Celular (DDD + número)
              <RequiredFieldInformation />
            </label>
            <InputMaskAlternative
              type="text"
              name="phone"
              title=""
              mask="(99) 9 9999-9999"
              placeholder="(00) 0 0000-0000"
            />
          </Field>
          <Field gridArea="wp">
            <label>Whatsapp (DDD + Número)</label>
            <InputMaskAlternative
              name="whatsapp"
              title=""
              mask="(99) 9 9999-9999"
              placeholder="(00) 0 0000-0000"
              type="text"
            />
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default forwardRef(PersonalData);
