import styled, { css } from 'styled-components';

interface PaginationButtonProps {
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55px;
`;

export const PaginationButton = styled.button<PaginationButtonProps>`
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  box-sizing: border-box;
  padding: 15px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #bdbdbd;

  &:hover {
    background: whitesmoke;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #2d9cdb;
      border: 0.5px solid #2d9cdb;
      font-weight: 600;
      color: #ffffff;

      &:hover {
        background: #2d9cdb;
      }
    `}

  &:disabled {
    background: #ffffff;
    opacity: 0.6;
  }
`;
