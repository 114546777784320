export type Risk = 'safe' | 'low' | 'medium' | 'high';

const getRiskDegre = {
  risk: (riskLabel?: Risk) => {
    switch (riskLabel) {
      case 'safe':
        return 1;
      case 'low':
        return 2;
      case 'medium':
        return 3;
      case 'high':
        return 4;
      default:
        return 'NI';
    }
  },
  color: (riskLabel?: Risk) => {
    switch (riskLabel) {
      case 'safe':
        return '#6FCF97';
      case 'low':
        return '#F2C94C';
      case 'medium':
        return '#FA8822';
      case 'high':
        return '#EA7676';
      default:
        return '#BDBDBD';
    }
  },
};

export default getRiskDegre;
