import { animated } from '@react-spring/web';
import { Chart } from 'react-google-charts';
import styled, { css } from 'styled-components';
import { colors } from '../../../../styles/theme/colors';

interface NoticesByInstitutionSlideButtonType {
  selected?: boolean;
}

export const NoticesByInstitutionCardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > div {
    width: 1px;
    height: 80%;
    background: #a4a9b4;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    flex-direction: column;

    > div {
      height: 1px;
      width: 80%;
    }
  }
`;

export const InstitutionsWithNotices = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 45%;

  > div:first-child {
    width: 40%;

    > h2 {
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      color: ${colors.graySecondary};
    }

    > h4 {
      font-weight: bold;
      font-size: 60px;
      text-align: center;
      color: ${colors.graySecondary};
    }

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
      > h4 {
        font-size: 50px;
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const InstitutionsWithNoticesTable = styled.div`
  width: 60%;
  height: 100%;
  max-height: 20vh;
  margin-right: 20px;
  overflow-y: auto;

  > header,
  section {
    display: flex;
  }

  > header {
    padding: 15px 1vw;

    > div > h2 {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #707683;
    }

    > div:nth-child(1) {
      width: 70%;
    }

    > div:nth-child(2),
    > div:nth-child(3) {
      width: 15%;
    }
  }

  > section {
    padding: 10px 1vw;
    border-top: 1px solid #efefef;
    font-size: 14px;
    line-height: 16px;

    > button {
      width: 70%;
      border: 0;
      background: transparent;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-weight: 600;
      color: ${colors.primary};
    }

    > span {
      width: 15%;
      font-weight: 500;

      color: #4f4f4f;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    margin-right: 0;
    max-height: 30vh;
  }
`;

export const NoticesByInstitution = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 55%;
  height: 100%;
  position: relative;

  > footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    width: 100%;
    margin-top: 15px;
  }
`;

export const NoticesByInstitutionSection = styled(animated.section)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: -1;
`;

export const NoticesByInstitutionTable = styled.div`
  width: 100%;
  height: 100%;
  max-height: 20vh;
  overflow-y: auto;

  > header {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;

    > h2 {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #707683;
    }

    > img {
      width: 17px;
      height: 17px;
      margin-right: 5px;
    }
  }

  > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #efefef;
    padding: 15px 10px;

    > h3 {
      text-align: left;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: ${colors.graySecondary};
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-decoration: none;
    }

    > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${colors.graySecondary};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    max-height: 30vh;
  }
`;

export const NoticesByInstitutionChart = styled(Chart)`
  width: 25vw;
  height: 300px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    width: 20vw;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    width: 30vw;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    width: 40vw;
  }
`;

export const NoticesByInstitutionSlideButton = styled.button<NoticesByInstitutionSlideButtonType>`
  border: 1px solid ${colors.primary};
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;

  ${({ selected }) =>
    selected &&
    css`
      border: 0;
      background: ${colors.primary};
    `}

  & + button {
    margin-left: 5px;
  }
`;
