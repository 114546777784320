import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1100px;
  min-width: 880px;
  width: 100%;
  height: 100%;
  padding: 35px 50px 10px;

  > footer {
    display: flex;
    justify-content: flex-end;
    margin: 50px 0 20px;

    > button + button {
      margin-left: 18px;
    }
  }
`;
