import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  margin-bottom: 20px;

  p {
    color: ${({ theme }) => theme.colors.graySecondary};
    font-weight: 500;
    font-size: 14px;
    margin-left: 15px;
    width: 100%;
  }

  > svg {
    color: ${({ theme }) => theme.colors.danger};
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 26px;
  width: 55px;
  padding: 0 3px;
  position: relative;
  color: #fff;
  border-radius: 50px;
  border: none;
  background: ${({ theme }) => theme.colors.gray};
  transition: 0.4s;
  -webkit-transition: 0.4s;

  &:before {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: white;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    border-radius: 50%;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.primary};

    cursor: default;

    &:before {
      transform: translateX(23px);
      -webkit-transform: translateX(23px);
      -ms-transform: translateX(23px);
    }
  }
`;
