import React from 'react';
import { useHistory } from 'react-router-dom';
import { HiOutlinePencil } from 'react-icons/hi';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useModal } from 'react-brave-modal';

import { usePatient } from '../../../../../../hooks/patient';

import Button from '../../../../../../components/Button';

import {
  Container,
  Header,
  Content,
  ListHeader,
  ListItem,
  NoEmergencyContact,
} from './styles';

const EmergencyContactsModal: React.FC = () => {
  const { patient } = usePatient();
  const { closeModal } = useModal();
  const history = useHistory();

  const handleButtonEdit = () => {
    history.push(`/patient/edit?id=${patient?.id}&page=AdditionalData`);

    closeModal();
  };

  return (
    <Container>
      <Header>
        <h1>Dados de emergência</h1>

        <button type="button" onClick={handleButtonEdit}>
          <strong>Editar</strong>

          <HiOutlinePencil size={15} />
        </button>
      </Header>

      <Content>
        {patient?.complementaryData?.emergencyContacts &&
        patient.complementaryData.emergencyContacts.length > 0 ? (
          <>
            <h3>Contatos de emergência</h3>

            <ListHeader>
              <span>Nome</span>

              <span>Telefone</span>
            </ListHeader>

            {patient.complementaryData.emergencyContacts.map((contact) => (
              <ListItem key={contact.phone}>
                <strong>{contact.name}</strong>

                <span>{contact.phone}</span>
              </ListItem>
            ))}
          </>
        ) : (
          <NoEmergencyContact>
            <AiFillInfoCircle size={20} />

            <h3>Nenhum contato de emergência cadastrado</h3>
          </NoEmergencyContact>
        )}
      </Content>

      <Button
        type="button"
        buttonType="primary-alternative"
        onClick={closeModal}
      >
        Fechar
      </Button>
    </Container>
  );
};

export default EmergencyContactsModal;
