import styled from 'styled-components';

interface OptionProps {
  selected?: boolean;
}

interface InputSwitchBoxProps {
  isBulk: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 45px 45px 7px 45px;
`;

export const Grouper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 26px;
    font-weight: 500;
    margin-top: 25px;

    span {
      color: ${(props) => props.theme.colors.gray};
      font-size: 14px;
      font-weight: 500;
      margin-left: 20px;
    }
  }

  > p {
    color: ${(props) => props.theme.colors.grayAlternative};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    padding: 25px 0 0 0;
  }

  > strong {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-top: 20px;
  }

  > form {
    margin-top: 40px;
  }

  > input {
    margin-top: 25px;
  }

  &:last-child {
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      margin-right: 90px;
    }
  }
`;

export const OptionsGrouper = styled.div`
  display: flex;
  margin-top: 28px;
`;

export const Option = styled.button<OptionProps>`
  background: none;
  border: none;
  border-bottom: 1px solid
    ${(props) => (props.selected ? '#1f80b7' : props.theme.colors.gray)};
  color: ${(props) => (props.selected ? '#1f80b7' : props.theme.colors.gray)};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 'bold' : '500')};
  line-height: 19px;
  padding-bottom: 7px;
  text-align: start;
  width: 82px;

  + button {
    margin-left: 11px;
  }
`;

export const InputSwitchBox = styled.div<InputSwitchBoxProps>`
  display: flex;
  align-items: center;
  margin-top: 35px;

  > div {
    > label.switch {
      height: 22px;
      width: 40px;

      > input:checked + span:before {
        transform: translateX(17.5px);
      }

      > span.slider:before {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
        left: 5px;
        height: 12px !important;
        width: 12px !important;
      }
    }
  }

  > label {
    color: ${(props) =>
      props.isBulk ? props.theme.colors.primary : '#707683'};
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 6px;
    transition: 0.8s color;
  }

  > svg {
    height: 20px;
    width: 20px;

    path {
      fill: ${(props) =>
        props.isBulk ? props.theme.colors.primary : '#707683'};
      transition: 0.8s fill;
    }
  }
`;

export const BulkBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 80px;

  > div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    > svg {
      height: 20px;
      width: 20px;
      margin-right: 6px;
    }

    h1 {
      color: ${(props) => props.theme.colors.graySecondary};
      font-size: 14px;
      font-weight: 600;
    }

    strong {
      color: ${(props) => props.theme.colors.primary};
      font-weight: 700;
    }
  }
`;

export const List = styled.div`
  // Header
  > div:first-child {
    display: flex;
    align-items: center;
    background-color: #eaecf0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 40px;
    padding: 0 25px;

    span {
      color: #707683;
      font-size: 13px;
      font-weight: 600;
    }
  }

  // Items
  > div:last-child {
    height: 230px;
    overflow-y: auto;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      padding: 0 25px;

      img {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        margin-right: 12px;
      }

      span {
        color: ${(props) => props.theme.colors.gray3};
        font-size: 14px;
        font-weight: 600;
        margin-right: auto;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0;
        color: ${(props) => props.theme.colors.danger};
        cursor: pointer;
        outline: none;
        padding: 5px;
      }
    }

    div + div {
      border-top: 1px solid ${({ theme }) => theme.colors.gray6};
    }
  }
`;
