import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { HiOutlinePencil } from 'react-icons/hi';
import { FiTrash, FiPhone, FiVideo, FiFileText, FiHome } from 'react-icons/fi';
import { useModal } from 'react-brave-modal';
import { differenceInMilliseconds, format, isToday } from 'date-fns';
import { RiPlayCircleLine } from 'react-icons/ri';
import { BsThreeDots } from 'react-icons/bs';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../../../lib/use-current-effect';

import { useAppointment } from '../../../../hooks/appointment';
import { AppointmentFiltersDTO } from '../../../../dtos/AppointmentFiltersDTO';
import { getAvatarImage } from '../../../../services/user';
import { Appointment as AppointmentEntity } from '../../../../entities/Appointment';
import { usePermission } from '../../../../hooks/permission';
import { useAuth } from '../../../../hooks/auth';
import useQuery from '../../../../hooks/query';

import Filter from '../Filter';
import Spinner from '../../../../components/Spinner';
import ContentNothingToDisplay from '../../../../components/ContentNothingToDisplay';
import InputCheckWithoutForm from '../../../../components/InputCheckWithoutForm';
import ActionMenuButtonItemList from '../../../../components/ActionMenuButtonItemList';
import ActionMenuButtonList from '../../../../components/ActionMenuButtonList';
import { ActionMenuListProps } from '../../../../components/ActionMenuButton';
import SelectedItemsInList from '../../../../components/SelectedItemsInList';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';
import Pagination from '../../../../components/Pagination';
import CreateEditAppointmentModal from '../../../../modals/CreateEditAppointmentModal';
import RunAppointmentNewTabModal from '../../../../modals/RunAppointmentNewTabModal';
import BadgeAppointmentStatus from './components/BadgeAppointmentStatus';
import BadgeAppointmentArea from './components/BadgeAppointmentArea';

import Avatar from '../../../../assets/images/avatar.svg';
import { ReactComponent as ArrowUpDownIcon } from '../../../../assets/images/arrowupdown.svg';
import CloudSketchesImage from '../../../../assets/images/cloud-sketches.svg';

import {
  TopBar,
  ShowBtn,
  Container,
  TableStyledBodySection,
  TableStyledHead,
  TableStyledRow,
  TableStyledCell,
  ColorNumber,
  StyledLink,
  PhotoRow,
  PersonIcon,
  StethoscopeIcon,
  ChangeOrderButton,
  CardContentNothingToDisplayBox,
  TableStyledGrid,
} from './styles';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';
import AppointmentAreaEnum from '../../../../enums/AppointmentAreaEnum';
import AppointmentStatusEnum from '../../../../enums/AppointmentStatusEnum';
import JustifyAppointmentExclusionModal, {
  JustifyAppointmentExclusionModalProps,
} from '../../../../modals/JustifyAppointmentExclusionModal';
import AppointmentExclusionReasonEnum from '../../../../enums/AppointmentExclusionEnum';
import AppointmentDetailsModal from '../../../../modals/AppointmentDetailsModal/AppointmentDetailsModal';
import { User } from '../../../../entities/User';
import { colors } from '../../../../styles/theme/colors';

interface ImageListProps {
  id?: string;
  avatar?: string;
}

interface Appointment extends AppointmentEntity {
  friendlyDate?: string;
}
interface AppointmentSection {
  sectionDay: string;
  date: string | Date;
  sectionAppointments: Appointment[];
}

interface TableProps {
  updateList: number;
}

interface Item {
  id?: string;
  friendlyDate?: string;
  patientId?: string;
  patientUserId?: string;
  patientName?: string;
  professionalName?: string;
  professionalOccupationArea?: string;
  appointmentType?: AppointmentTypeEnum;
  appointmentArea?: AppointmentAreaEnum;
  appointmentStatus?: AppointmentStatusEnum;
  isChecked?: boolean;
  appointmentDate?: Date;
  didStart?: boolean;
  updatedAt?: Date;
  professionalId?: string;
  professionalAvatar?: string;
  attendantId?: string;
  patientAvatar?: string;
  patientPhone?: string;
  removedBy?: User;
  reason?: AppointmentExclusionReasonEnum;
  reasonDescription?: string;
  options?: ActionMenuListProps[];
}

interface ItemsPerDay {
  day: string | Date;
  date: Date;
  items: Item[];
}

type OrderBy =
  | 'appointmentDate'
  | 'patientName'
  | 'professionalName'
  | 'appointmentType';

type ShowAppointments = 'allAppointments' | 'myAppointments';

type IconAppointmentTypeProps = {
  [key in AppointmentTypeEnum]?: JSX.Element;
};

const Table: React.FC<TableProps> = ({ updateList }) => {
  const formRef = useRef<FormHandles>(null);
  const { hasPermission } = usePermission();
  const { showModal } = useModal();
  const { cleanLocal, getAppointments, updateAppointmentStatus } =
    useAppointment();
  const { user } = useAuth();
  const params = useQuery();

  const [list, setList] = useState<AppointmentSection[]>([]);
  const [listDisplay, setListDisplay] = useState<ItemsPerDay[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalAppointments, setTotalAppointments] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [userImageList, setUserImageList] = useState<ImageListProps[]>([]);
  const [ordenationType, setOrdenationType] = useState<'ASC' | 'DESC'>('DESC');
  const [orderBy, setOrderBy] = useState<OrderBy>('appointmentDate');
  const [patientImageList, setPatientImageList] = useState<ImageListProps[]>(
    [],
  );

  const [totalAppointmentsInThisPage, setTotalAppointmentsInThisPage] =
    useState(0);

  const [filters, setFilters] = useState<AppointmentFiltersDTO>(() =>
    params.get('appointments') === 'my' ? { professionalName: user?.name } : {},
  );

  const isHealthProfessional = useMemo(
    () => user?.type === 'professional',
    [user],
  );

  const [showAppointments, setShowAppointments] = useState<ShowAppointments>(
    () =>
      params.get('appointments') === 'my'
        ? 'myAppointments'
        : 'allAppointments',
  );

  const can = useMemo(
    () => ({
      edit: hasPermission('edit_appointment') && isHealthProfessional,
      remove: hasPermission('remove_appointment'),
      viewPatientProfile: hasPermission('patient_profile'),
    }),
    [hasPermission, isHealthProfessional],
  );

  const isFilterEmpty = useMemo(() => !Object.keys(filters).length, [filters]);

  const selectedItemsCount = useMemo(
    () =>
      listDisplay
        .map<number>((i) =>
          i.items
            .map<number>((item) => (item.isChecked ? 1 : 0))
            .reduce((total, item) => total + item, 0),
        )
        .reduce((total, i) => total + i, 0),
    [listDisplay],
  );

  const iconAppointmentType: IconAppointmentTypeProps = {
    Ligação: <FiPhone size={20} color={colors.black} />,
    Mensagem: <FiFileText size={20} color={colors.black} />,
    Videochamada: <FiVideo size={20} color={colors.black} />,
    Presencial: <FiHome size={20} color={colors.black} />,
  };

  const handleUpdateList = useCurrentCallback(
    (isCurrent) => async (action?: 'remotion') => {
      const pageToSearch =
        action === 'remotion' &&
        totalPages > 1 &&
        totalAppointmentsInThisPage === 1 &&
        currentPage !== 1
          ? currentPage - 1
          : currentPage;

      const response = await getAppointments({
        page: pageToSearch,
        filters,
        orderBy,
        ordenationType,
      });

      if (isCurrent()) {
        setList(response.list);
        setTotalPages(response.totalPages);
        setTotalAppointments(response.totalAppointments);
        setTotalAppointmentsInThisPage(response.totalAppointmentsInThisPage);
      }

      return response;
    },
    [
      currentPage,
      filters,
      getAppointments,
      ordenationType,
      orderBy,
      totalAppointmentsInThisPage,
      totalPages,
    ],
  );

  const handleCheck = useCallback((key: string, value: boolean) => {
    setListDisplay((state) =>
      state.map(({ items, ...rest }) => ({
        items: items.map(({ isChecked, ...iRest }) => ({
          isChecked: iRest.id === key ? value : isChecked,
          ...iRest,
        })),
        ...rest,
      })),
    );
  }, []);

  const handleCheckAll = useCallback((value: boolean) => {
    setListDisplay((state) =>
      state.map(({ items, ...rest }) => ({
        items: items.map((i) => ({
          ...i,
          isChecked: value,
        })),
        ...rest,
      })),
    );
  }, []);

  const handleOrdenation = useCallback(
    (orderKey: OrderBy, isInitialOrdering: boolean) => {
      setOrdenationType((state) =>
        isInitialOrdering || state === 'DESC' ? 'ASC' : 'DESC',
      );

      setOrderBy(orderKey);
    },
    [],
  );

  const handleShowAppointments = useCallback(
    (newShowAppointments: ShowAppointments) => {
      const professionalName =
        newShowAppointments === 'allAppointments' ? '' : user?.name;

      formRef.current?.setData({ professionalName });
      setShowAppointments(newShowAppointments);
      setFilters((state) => ({
        ...state,
        professionalName,
      }));
    },
    [user],
  );

  const handleEditAppointment = useCallback(
    (appointmentID?: string) => {
      if (appointmentID) {
        showModal({
          type: 'custom',
          closeActionSync: () => cleanLocal(),
          data: (
            <CreateEditAppointmentModal
              onFinish={() => {
                handleUpdateList();
              }}
              appointmentId={appointmentID}
            />
          ),
        });
      }
    },
    [cleanLocal, showModal, handleUpdateList],
  );

  const handleRemoveAppointment = useCallback(
    (props: JustifyAppointmentExclusionModalProps) => {
      showModal({
        type: 'custom',
        data: (
          <JustifyAppointmentExclusionModal
            {...props}
            onSuccess={handleUpdateList}
          />
        ),
      });
    },
    [handleUpdateList, showModal],
  );

  const handleOnConfirm = useCallback(
    async (appointment: Item) => {
      await updateAppointmentStatus({
        appointmentId: appointment.id,
        status: AppointmentStatusEnum.STARTED,
        attendNow: true,
      });
    },
    [updateAppointmentStatus],
  );

  const handleShowAppointmentDetails = useCallback(
    (appointment: Item) => {
      showModal({
        type: 'custom',
        data: <AppointmentDetailsModal appointment={appointment} />,
      });
    },
    [showModal],
  );

  const getAppointmentsActionList = useCallback(
    (appointment: Omit<Item, 'options'>) => {
      const actionAppointmentList: ActionMenuListProps[] = [];

      const appointmentData = {
        id: appointment.id,
        date: appointment.appointmentDate,
        type: appointment.appointmentType,
        patientName: appointment.patientName,
        professionalName: appointment.professionalName,
        patientPhone: appointment.patientPhone,
        status: appointment.appointmentStatus,
        removedByUserName: appointment.removedBy?.name,
        reason: appointment.reason,
        reasonDescription: appointment.reasonDescription,
      };
      const proceed: ActionMenuListProps = {
        name: 'Continuar',
        icon: <RiPlayCircleLine size={15} />,
        action: `/patient/attendance?id=${appointment.id}`,
      };

      const attendAppointment: ActionMenuListProps = {
        icon: <RiPlayCircleLine size={15} />,
        name: 'Iniciar consulta',
        action: () => {
          showModal({
            type: 'custom',
            data: (
              <RunAppointmentNewTabModal
                onConfirm={() => handleOnConfirm(appointmentData)}
                onClose={handleUpdateList}
                appointment={appointmentData}
              />
            ),
          });
        },
      };

      const editItem: ActionMenuListProps = {
        icon: <HiOutlinePencil size={15} color={colors.grayAlternative} />,
        name: 'Editar',
        action: () => handleEditAppointment(appointment.id),
        disabled: !can.edit,
      };

      const details: ActionMenuListProps = {
        icon: <BsThreeDots size={20} color={colors.grayAlternative} />,
        name: 'Detalhes',
        action: () => handleShowAppointmentDetails(appointmentData),
      };

      const removeItem: ActionMenuListProps = {
        icon: <FiTrash size={15} color={colors.grayAlternative} />,
        name: 'Excluir',
        action: () =>
          appointment.id &&
          handleRemoveAppointment({
            appointmentId: appointment.id,
            patientName: appointmentData.patientName,
            professionalName: appointmentData.professionalName,
            date: appointmentData.date,
          }),
        disabled: !can.remove,
      };

      const canProceed =
        user?.id === appointment.attendantId &&
        (appointment.appointmentStatus === AppointmentStatusEnum.UNFINISHED ||
          appointment.appointmentStatus === AppointmentStatusEnum.STARTED);

      const canEdit =
        appointment.appointmentType !== AppointmentTypeEnum.SMS &&
        !appointment.didStart &&
        appointment.appointmentStatus !== AppointmentStatusEnum.REMOVED &&
        differenceInMilliseconds(
          new Date(appointment.appointmentDate ?? ''),
          new Date(),
        ) > -1;

      const canAttend =
        ((appointment.appointmentType === AppointmentTypeEnum.PHONE &&
          hasPermission('call_attendance')) ||
          (appointment.appointmentType === AppointmentTypeEnum.VIDEO &&
            hasPermission('video_attendance'))) &&
        user?.type === 'professional' &&
        appointment?.appointmentStatus === AppointmentStatusEnum.CONFIRMED;

      const canRemove =
        !!appointment.appointmentStatus &&
        appointment.appointmentStatus === AppointmentStatusEnum.CONFIRMED;

      const canSeeDetails =
        appointment.appointmentStatus === AppointmentStatusEnum.REMOVED;

      if (canAttend) {
        actionAppointmentList.push(attendAppointment);
      }

      if (canEdit) {
        actionAppointmentList.push(editItem);
      }

      if (canProceed) {
        actionAppointmentList.push(proceed);
      }

      if (canRemove) {
        actionAppointmentList.push(removeItem);
      }

      if (canSeeDetails) {
        actionAppointmentList.push(details);
      }

      return actionAppointmentList;
    },
    [
      can.edit,
      can.remove,
      handleEditAppointment,
      handleOnConfirm,
      handleRemoveAppointment,
      handleShowAppointmentDetails,
      handleUpdateList,
      hasPermission,
      showModal,
      user,
    ],
  );

  const transformListIntoDisplay = useCallback(
    (
      listToTransform: AppointmentSection[],
      professionalAvatarList: ImageListProps[],
      patientAvatarList: ImageListProps[],
    ) => {
      const newListDisplay: ItemsPerDay[] = listToTransform.map((item) => ({
        day: isToday(new Date(item.date))
          ? 'Hoje'
          : format(new Date(item.date), "dd'/'MM'/'yyy"),
        date: new Date(item.date),
        items: item.sectionAppointments.map((appointment): Item => {
          const data: Omit<Item, 'options'> = {
            friendlyDate: appointment.date
              ? format(new Date(appointment.date), "HH':'mm")
              : '',
            appointmentType: appointment.type,
            appointmentArea: appointment.area,
            appointmentStatus: appointment.status,
            isChecked: false,
            patientName: appointment.patient?.user?.name,
            professionalName:
              appointment?.attendantProfessional?.user?.name ??
              appointment.professional?.user?.name,
            professionalOccupationArea:
              appointment.professional?.occupationArea,
            patientUserId: appointment.patient?.user?.id,
            id: appointment.id,
            patientId: appointment.patientId,
            appointmentDate: new Date(appointment.date ?? ''),
            didStart:
              !!appointment.status &&
              [
                AppointmentStatusEnum.STARTED,
                AppointmentStatusEnum.UNFINISHED,
                AppointmentStatusEnum.FINISHED,
              ].includes(appointment.status),
            updatedAt: new Date(appointment.updated_at ?? ''),
            professionalId: appointment.professionalId,
            professionalAvatar:
              professionalAvatarList.find(
                ({ id }) => id === appointment.professionalId,
              )?.avatar ?? Avatar,
            attendantId: appointment.attendantProfessional?.user?.id,
            patientAvatar:
              patientAvatarList.find(({ id }) => id === appointment.patientId)
                ?.avatar ?? Avatar,
            patientPhone: appointment.contact_phone,
            removedBy: appointment.removedByUser,
            reason: appointment.reason?.exclusionReason,
            reasonDescription: appointment.reason?.exclusionReasonDescription,
          };

          return { ...data, options: getAppointmentsActionList(data) };
        }),
      }));

      setListDisplay([...newListDisplay]);
    },
    [getAppointmentsActionList],
  );

  const checkedAppointmentActionList = useMemo(() => {
    const appointments = listDisplay.find((i) =>
      i.items.find((item) => item.isChecked),
    );

    const appointmentItem = appointments?.items.find((item) => item.isChecked);

    return appointmentItem ? getAppointmentsActionList(appointmentItem) : [];
  }, [listDisplay, getAppointmentsActionList]);

  useCurrentEffect(
    (isCurrent) => {
      if (list.length > 0) {
        const userList = list
          .flatMap(({ sectionAppointments }) =>
            sectionAppointments.map((item) => ({
              id: item.professionalId,
              avatar: item.professional?.user?.avatar,
            })),
          )
          .reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.id === current.id)) {
              accumulator.push(current);
            }
            return accumulator;
          }, [] as ImageListProps[]);

        Promise.all(
          userList.map(async (item) => ({
            id: item.id ?? '',
            avatar: item.avatar ? await getAvatarImage(item.avatar) : Avatar,
          })),
        ).then((userImageListResult) => {
          if (isCurrent()) {
            setUserImageList(userImageListResult);
          }
        });

        const patientList = list
          .flatMap(({ sectionAppointments }) =>
            sectionAppointments.map((item) => ({
              id: item.patientId,
              avatar: item.patient?.user?.avatar,
            })),
          )
          .reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.id === current.id)) {
              accumulator.push(current);
            }
            return accumulator;
          }, [] as ImageListProps[]);

        Promise.all(
          patientList.map(async (item) => ({
            id: item.id ?? '',
            avatar: item.avatar ? await getAvatarImage(item.avatar) : Avatar,
          })),
        ).then((patientImageListResult) => {
          if (isCurrent()) {
            setPatientImageList(patientImageListResult);
          }
        });
      }
    },
    [list],
  );

  useCurrentEffect(
    (isCurrent) => {
      handleUpdateList().then(() => {
        if (loading && isCurrent()) {
          setLoading(false);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, filters, updateList, orderBy, ordenationType],
  );

  useEffect(() => {
    if (list.length > 0) {
      transformListIntoDisplay(list, userImageList, patientImageList);
    }
  }, [list, userImageList, patientImageList, transformListIntoDisplay]);

  useEffect(() => {
    if (params.get('appointments') === 'my') {
      formRef.current?.setData({ professionalName: user?.name });
    }
  }, [params, user]);

  if (loading) {
    return <Spinner />;
  }

  return list.length > 0 || !isFilterEmpty ? (
    <>
      <Filter
        formRef={formRef}
        setFilters={setFilters}
        onChangePage={(page: number) => setCurrentPage(page)}
      />
      {list.length > 0 ? (
        <>
          <SelectedItemsInList invisible={selectedItemsCount === 0}>
            {selectedItemsCount === 1
              ? '01 Selecionado'
              : `${selectedItemsCount.toLocaleString('pt-BR', {
                  minimumIntegerDigits: 2,
                })} Selecionados`}
          </SelectedItemsInList>

          <TopBar>
            <section>
              <div>Exibir:</div>
              <ShowBtn
                disabled={showAppointments === 'allAppointments'}
                onClick={() => handleShowAppointments('allAppointments')}
              >
                Agenda Geral
              </ShowBtn>
              <ShowBtn
                disabled={showAppointments === 'myAppointments'}
                onClick={() => handleShowAppointments('myAppointments')}
              >
                Minha Agenda
              </ShowBtn>
            </section>
            <section />
            <section>
              <span>Ações:</span>
              <ActionMenuButtonList
                disabled={selectedItemsCount !== 1}
                list={checkedAppointmentActionList}
              />
            </section>
            <section>
              <div>
                Exibindo{' '}
                <ColorNumber color="black">
                  {totalAppointmentsInThisPage}
                </ColorNumber>{' '}
                resultados de{' '}
                <ColorNumber color="primary">{totalAppointments}</ColorNumber>
              </div>
            </section>
          </TopBar>

          <Container>
            <TableStyledHead>
              <TableStyledCell gridArea="ick" align="center">
                <InputCheckWithoutForm
                  type="checkbox"
                  checked={totalAppointmentsInThisPage === selectedItemsCount}
                  onChange={(e) => handleCheckAll(e.target.checked)}
                />
              </TableStyledCell>

              <TableStyledCell gridArea="dta" align="flex-start">
                <strong>Data do Atendimento</strong>
                <ChangeOrderButton
                  type="button"
                  isActive={orderBy === 'appointmentDate'}
                  ordenationType={ordenationType}
                  onClick={() =>
                    handleOrdenation(
                      'appointmentDate',
                      orderBy !== 'appointmentDate',
                    )
                  }
                >
                  <ArrowUpDownIcon />
                </ChangeOrderButton>
              </TableStyledCell>

              <TableStyledCell gridArea="pt" align="flex-start">
                <PersonIcon />
                <strong>Paciente</strong>
                <ChangeOrderButton
                  type="button"
                  isActive={orderBy === 'patientName'}
                  ordenationType={ordenationType}
                  onClick={() =>
                    handleOrdenation('patientName', orderBy !== 'patientName')
                  }
                >
                  <ArrowUpDownIcon />
                </ChangeOrderButton>
              </TableStyledCell>

              <TableStyledCell gridArea="pf" align="flex-start">
                <StethoscopeIcon />
                <strong>Profissional</strong>
                <ChangeOrderButton
                  type="button"
                  isActive={orderBy === 'professionalName'}
                  ordenationType={ordenationType}
                  onClick={() =>
                    handleOrdenation(
                      'professionalName',
                      orderBy !== 'professionalName',
                    )
                  }
                >
                  <ArrowUpDownIcon />
                </ChangeOrderButton>
              </TableStyledCell>

              <TableStyledCell gridArea="oap" align="center">
                <strong>Área de atuação</strong>
              </TableStyledCell>

              <TableStyledCell gridArea="tpa" align="center">
                <strong>Tipo de atendimento</strong>
                <ChangeOrderButton
                  type="button"
                  isActive={orderBy === 'appointmentType'}
                  ordenationType={ordenationType}
                  onClick={() =>
                    handleOrdenation(
                      'appointmentType',
                      orderBy !== 'appointmentType',
                    )
                  }
                >
                  <ArrowUpDownIcon />
                </ChangeOrderButton>
              </TableStyledCell>

              <TableStyledCell gridArea="sta" align="flex-start">
                <strong>Status do Atendimento</strong>
              </TableStyledCell>
            </TableStyledHead>

            {listDisplay.length > 0 &&
              listDisplay.map((section) => (
                <TableStyledBodySection key={section.day.toString()}>
                  <TableStyledGrid>
                    <div />
                    <TableStyledCell gridArea="sta" align="flex-start">
                      <h2>{section.day}</h2>
                    </TableStyledCell>
                  </TableStyledGrid>
                  {section.items.map((appointment) => (
                    <TableStyledRow key={appointment.id}>
                      <TableStyledCell gridArea="ick" align="center">
                        <InputCheckWithoutForm
                          type="checkbox"
                          checked={appointment.isChecked}
                          onChange={({ target: { checked } }) =>
                            handleCheck(appointment.id ?? '', checked)
                          }
                        />
                      </TableStyledCell>

                      <TableStyledCell
                        isRemoved={
                          appointment.appointmentStatus ===
                          AppointmentStatusEnum.REMOVED
                        }
                        gridArea="dta"
                        align="flex-start"
                      >
                        {appointment.friendlyDate}
                      </TableStyledCell>

                      <TableStyledCell
                        isRemoved={
                          appointment.appointmentStatus ===
                          AppointmentStatusEnum.REMOVED
                        }
                        gridArea="pt"
                        align="flex-start"
                      >
                        <StyledLink
                          to={`/patient/profile?id=${appointment.patientId}`}
                          disabled={!can.viewPatientProfile}
                        >
                          <PhotoRow>
                            <img
                              src={appointment.patientAvatar}
                              alt={`Foto de ${appointment.patientName}`}
                            />
                            <span>{appointment.patientName}</span>
                          </PhotoRow>
                        </StyledLink>
                      </TableStyledCell>

                      <TableStyledCell
                        isRemoved={
                          appointment.appointmentStatus ===
                          AppointmentStatusEnum.REMOVED
                        }
                        gridArea="pf"
                        align="flex-start"
                      >
                        <PhotoRow>
                          <img
                            src={appointment.professionalAvatar}
                            alt={`Foto de ${appointment.professionalName}`}
                          />
                          <span>{appointment.professionalName}</span>
                        </PhotoRow>
                      </TableStyledCell>

                      <TableStyledCell gridArea="oap" align="center">
                        <BadgeAppointmentArea
                          type={
                            appointment.appointmentArea as AppointmentAreaEnum
                          }
                        />
                      </TableStyledCell>

                      <TableStyledCell gridArea="tpa" align="center">
                        {
                          iconAppointmentType[
                            appointment.appointmentType as keyof typeof iconAppointmentType
                          ]
                        }
                      </TableStyledCell>

                      <TableStyledCell gridArea="sta" align="flex-start">
                        <BadgeAppointmentStatus
                          type={
                            appointment.appointmentStatus as AppointmentStatusEnum
                          }
                        />
                      </TableStyledCell>

                      <TableStyledCell gridArea="act" align="center">
                        {appointment.options && (
                          <ActionMenuButtonItemList
                            list={appointment.options}
                            alignBox="center"
                            disabled={
                              appointment.appointmentStatus ===
                                AppointmentStatusEnum.NOT_ACCOMPLISHED ||
                              appointment.appointmentStatus ===
                                AppointmentStatusEnum.FINISHED
                            }
                          />
                        )}
                      </TableStyledCell>
                    </TableStyledRow>
                  ))}
                </TableStyledBodySection>
              ))}
          </Container>

          <Pagination
            totalPages={totalPages}
            onChangePage={(page: number) => setCurrentPage(page)}
            page={currentPage}
          />
        </>
      ) : (
        <CardContentNothingToDisplayBox>
          <CardContentNothingToDisplay>
            <img src={CloudSketchesImage} alt="" />
            Nenhum dado encontrado
          </CardContentNothingToDisplay>
        </CardContentNothingToDisplayBox>
      )}
    </>
  ) : (
    <ContentNothingToDisplay text="Nenhum dado encontrado" />
  );
};

export default Table;
