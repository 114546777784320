import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  height: 630px;
  width: 100%;
  padding: 30px 42px;
`;

export const CardHeader = styled.div`
  h1 {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 90px;
`;

export const ReportBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.gray2};
  border-radius: 6px;
  color: ${(props) => props.theme.colors.primary};
  height: 60px;
  padding: 0 38px;

  svg > path {
    fill: ${(props) => props.theme.colors.primary};
  }

  & + div {
    margin-top: 30px;
  }
`;

export const ReportTitle = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 14px;
    margin-left: 22px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  button {
    cursor: not-allowed;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.colors.primary};

    svg {
      font-size: 18px;
    }

    span {
      font-size: 12px;
      margin-top: 5px;
    }
  }

  button:first-child {
    margin-right: 50px;
  }
`;
