import { communicationService } from '../api';

interface GetLinkResponse {
  link: string;
}

export const getLink = async (compositionSid: string) => {
  const { data } = await communicationService.get<GetLinkResponse>(
    '/videoLink',
    { params: { compositionSid } },
  );

  return data.link;
};
