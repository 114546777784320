import AppointmentExclusionReasonEnum from '../enums/AppointmentExclusionEnum';

const OPTIONS = {
  [AppointmentExclusionReasonEnum.CANT_ATTEND_ON_SCHEDULED_TIME]:
    'Não poderei atender no horário agendado',
  [AppointmentExclusionReasonEnum.DUPLICATION_ERROR]: 'Erro de duplicação',
  [AppointmentExclusionReasonEnum.PATIENT_ATTENDED_ALREADY]:
    'Paciente já recebeu atendimento',
  [AppointmentExclusionReasonEnum.PATIENT_UNAVAILABLE_ON_SCHEDULED_TIME]:
    'Paciente indisponível na data agendada',
  [AppointmentExclusionReasonEnum.OTHERS]: 'Outros',
} as const;

export const appointmentExclusionReasonsOptions = Object.entries(OPTIONS).map(
  ([value, label]) => ({
    value: value as AppointmentExclusionReasonEnum,
    label,
  }),
);

export const appointmentExclusionReasons = (
  reason?: AppointmentExclusionReasonEnum,
) => reason && OPTIONS[reason];
