import { api } from '../api';

interface GetProfessionalScheduleByDay {
  professionalId?: string;
  patientId?: string;
  day: number;
  month: number;
  year: number;
}

interface GetProfessionalScheduleByDayResponse {
  available: boolean;
  booked: boolean;
  interval: Date;
}

export const getProfessionalScheduleByDay = async (
  data: GetProfessionalScheduleByDay,
): Promise<GetProfessionalScheduleByDayResponse[]> => {
  const resp = await api.get('/professionalSchedule/availability/day', {
    params: {
      professionalId: data.professionalId,
      patientId: data.patientId,
      day: data.day,
      month: data.month,
      year: data.year,
    },
  });

  return resp.data.intervals;
};
