import React, {
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import { useUser } from '../../../../hooks/user';
import { changePassword } from '../../../../services/user';
import getValidationErrors from '../../../../utils/getValidationErrors';
import ChangePasswordImage from '../../../../assets/images/change-password.svg';

import { Container, CardHeader, CardContent, Field } from './styles';
import { ComponentsProfileRef } from '..';

interface FormProps {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

const ChangePassword: React.ForwardRefRenderFunction<ComponentsProfileRef> = (
  _,
  ref,
) => {
  const { user } = useUser();
  const formRef = useRef<FormHandles>(null);

  const getValues = useCallback(() => {
    return { ...formRef.current?.getData() } as FormProps;
  }, []);

  const handleSubmit = useCallback(async () => {
    const data = getValues();

    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        oldPassword: Yup.string().required('Senha atual é obrigatória.'),
        password: Yup.string()
          .matches(
            /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[\W|_]).*$/,
            'Senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
          )
          .min(8, 'Mínimo de 8 dígitos'),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Senhas não coincidem',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await changePassword({
        oldPassword: data.oldPassword,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
      });

      toast.success('Senha alterada com sucesso.');
      formRef.current?.reset();
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      } else if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }
      return false;
    }
  }, [getValues]);

  useImperativeHandle(ref, () => ({
    save: handleSubmit,
    back: async () => true,
  }));

  return (
    <Container>
      <CardHeader>
        <h1>Trocar senha</h1>
        <h2>{user?.name}</h2>
      </CardHeader>
      <CardContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Field>
            <label>Senha atual:</label>
            <InputWithoutTitle name="oldPassword" type="password" />
          </Field>
          <Field>
            <label>Nova senha:</label>
            <InputWithoutTitle name="password" type="password" />
          </Field>
          <Field>
            <label>Repita nova senha:</label>
            <InputWithoutTitle name="passwordConfirmation" type="password" />
          </Field>
        </Form>
        <img src={ChangePasswordImage} alt="Imagem trocar senha" />
      </CardContent>
    </Container>
  );
};

export default forwardRef(ChangePassword);
