import styled from 'styled-components';

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: 5px;
  height: 30px;
  width: 60px;
  opacity: 0.6;
`;
