import React, { ButtonHTMLAttributes } from 'react';
import SmallSpinner from '../SmallSpinner';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType: 'gray' | 'blue-light' | 'blue';
  loading?: boolean;
}

const ModalButton: React.FC<ButtonProps> = ({
  styleType,
  loading,
  children,
  ...rest
}) => {
  return (
    <Container loading={loading} styleType={styleType} {...rest}>
      {loading ? <SmallSpinner /> : children}
    </Container>
  );
};

export default ModalButton;
