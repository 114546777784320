import { api } from '../api';
import { User } from '../../entities/User';

export const updateAvatarImage = async (
  userId: string,
  avatarImage: string,
): Promise<User> => {
  const { data } = await api.patch('/users/avatar', {
    userId,
    avatarImage,
  });

  return data;
};
