import { api } from '../api';
import { Appointment } from '../../entities/Appointment';
import { UpdateAppointmentRequestDTO } from '../../dtos/UpdateAppointmentRequestDTO';

export const updateAppointment = async ({
  appointmentId,
  patientId,
  professionalId,
  area,
  contactPhone,
  date,
  smsText,
  status,
  type,
}: UpdateAppointmentRequestDTO): Promise<Appointment> => {
  const { data } = await api.put('/appointments', {
    appointmentId,
    patientId,
    professionalId,
    area,
    contactPhone,
    date,
    smsText,
    status,
    type,
  });

  return data;
};
