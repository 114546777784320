import React, { useCallback, useMemo, useState } from 'react';
// import { MdRemoveRedEye } from 'react-icons/md';
import { toast } from 'react-toastify';
import Filter from '../Filter';
import ActionMenuButtonList from '../../../../components/ActionMenuButtonList';
import Spinner from '../../../../components/Spinner';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';
import InputCheckWithoutForm from '../../../../components/InputCheckWithoutForm';
import Pagination from '../../../../components/Pagination';
import OrdenationTypeEnum from '../../../../enums/OrdenationTypeEnum';

import { ReactComponent as ArrowUpDownIcon } from '../../../../assets/images/arrowupdown.svg';
import CloudSketchesImage from '../../../../assets/images/cloud-sketches.svg';

import {
  TopBar,
  Actions,
  ColorNumber,
  Container,
  InstitutionList,
  ChangeOrderButton,
  TitleWithButton,
  CardContentNothingToDisplayBox,
  InstitutionListHeaderRow,
  InstitutionListBodyRow,
  InstitutionListBodyCell,
  InstitutionListBoundPatientCountBodyCell,
} from './styles';
import { findInstitutionsByFilter } from '../../../../services/institution';
import FilterInstitutionDTO, {
  FilterInstitutionOrderBy as OrderBy,
} from '../../../../dtos/FilterInstitutionDTO';
import { useCurrentEffect } from '../../../../lib/use-current-effect';

type Filters = Omit<FilterInstitutionDTO, 'page' | 'limit'>;

interface Item {
  isChecked: boolean;
  status?: 'active' | 'inactive';
  id: string;
  institutionName: string;
  institutionType?: string;
  uf?: string;
  city?: string;
  boundPatientsCount?: number;
}

const Table: React.FC = () => {
  const [listDisplay, setListDisplay] = useState<Item[]>([]);

  const [totalPages, setTotalPages] = useState(0);
  const [totalInstitutions, setTotalInstitutions] = useState(0);
  const [orderBy, setOrderBy] = useState<OrderBy>('institutionName');
  const [isAscending, setIsAscending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [filters, setFilters] = useState<Filters>({
    orderBy: 'institutionName',
    ordenationType: 'ASC',
  });
  const [loading, setLoading] = useState(true);

  const allItemsChecked = useMemo(
    () => listDisplay.every((item) => item.isChecked),
    [listDisplay],
  );

  const handleOrdenation = useCallback(
    (orderKey: OrderBy, isInitialOrdering: boolean) => {
      if (isInitialOrdering) {
        setFilters((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: OrdenationTypeEnum.ASC,
        }));
        setIsAscending(true);
      } else {
        setFilters((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: isAscending
            ? OrdenationTypeEnum.DESC
            : OrdenationTypeEnum.ASC,
        }));
        setIsAscending((prevState) => !prevState);
      }

      setOrderBy(orderKey);
    },
    [isAscending, setFilters],
  );

  useCurrentEffect(
    (isCurrent) => {
      findInstitutionsByFilter({ ...filters, limit: 10, page: currentPage })
        .then((response) => {
          if (isCurrent()) {
            setListDisplay(
              response.list.map(
                ({
                  id = '',
                  address,
                  boundPatientsCount,
                  name: institutionName = '',
                  type: institutionType,
                }) => ({
                  boundPatientsCount,
                  id,
                  city: address?.city,
                  institutionName,
                  institutionType,
                  isChecked: false,
                  uf: address?.uf,
                }),
              ),
            );
            setTotalPages(response.totalPages);
            setTotalInstitutions(response.total);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          if (isCurrent()) {
            setLoading(false);
          }
        });
    },
    [currentPage, filters],
  );

  const handleCheck = useCallback((key: string, value: boolean) => {
    setListDisplay((state) =>
      state.map((item) => {
        if (item.id === key) {
          item.isChecked = value;
        }

        return item;
      }),
    );
  }, []);

  const handleCheckAll = useCallback((value: boolean) => {
    setListDisplay((state) =>
      state.map((item) => ({ ...item, isChecked: value })),
    );
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Filter
        setFilters={setFilters}
        onChangePage={(page: number) => setCurrentPage(page)}
      />
      {totalInstitutions ? (
        <>
          <TopBar>
            <Actions>
              <span>Ações</span>
              <ActionMenuButtonList disabled />
            </Actions>
            <section>
              <div>
                Exibindo{' '}
                <ColorNumber color="black">{listDisplay.length}</ColorNumber>{' '}
                resultados de{' '}
                <ColorNumber color="primary">{totalInstitutions}</ColorNumber>
              </div>
            </section>
          </TopBar>
          <Container>
            {listDisplay && (
              <InstitutionList>
                <header>
                  <InstitutionListHeaderRow>
                    <InputCheckWithoutForm
                      type="checkbox"
                      onChange={(e) => handleCheckAll(e.target.checked)}
                      checked={allItemsChecked}
                    />
                    <TitleWithButton>
                      <strong>Nome da unidade</strong>
                      <ChangeOrderButton
                        isActive={orderBy === 'institutionName'}
                        ordenationType={isAscending ? 'ASC' : 'DESC'}
                        type="button"
                        onClick={() =>
                          handleOrdenation(
                            'institutionName',
                            orderBy !== 'institutionName',
                          )
                        }
                      >
                        <ArrowUpDownIcon />
                      </ChangeOrderButton>
                    </TitleWithButton>
                    <TitleWithButton>
                      <strong>Tipo</strong>
                      <ChangeOrderButton
                        isActive={orderBy === 'institutionType'}
                        ordenationType={isAscending ? 'ASC' : 'DESC'}
                        type="button"
                        onClick={() =>
                          handleOrdenation(
                            'institutionType',
                            orderBy !== 'institutionType',
                          )
                        }
                      >
                        <ArrowUpDownIcon />
                      </ChangeOrderButton>
                    </TitleWithButton>
                    <TitleWithButton>
                      <strong>Estado</strong>
                      <ChangeOrderButton
                        isActive={orderBy === 'uf'}
                        ordenationType={isAscending ? 'ASC' : 'DESC'}
                        type="button"
                        onClick={() => handleOrdenation('uf', orderBy !== 'uf')}
                      >
                        <ArrowUpDownIcon />
                      </ChangeOrderButton>
                    </TitleWithButton>
                    <TitleWithButton>
                      <strong>Cidade</strong>
                      <ChangeOrderButton
                        isActive={orderBy === 'city'}
                        ordenationType={isAscending ? 'ASC' : 'DESC'}
                        type="button"
                        onClick={() =>
                          handleOrdenation('city', orderBy !== 'city')
                        }
                      >
                        <ArrowUpDownIcon />
                      </ChangeOrderButton>
                    </TitleWithButton>
                    <TitleWithButton>
                      <strong>Pacientes vinculados</strong>
                      <ChangeOrderButton
                        isActive={orderBy === 'boundPatientsCount'}
                        ordenationType={isAscending ? 'ASC' : 'DESC'}
                        type="button"
                        onClick={() =>
                          handleOrdenation(
                            'boundPatientsCount',
                            orderBy !== 'boundPatientsCount',
                          )
                        }
                      >
                        <ArrowUpDownIcon />
                      </ChangeOrderButton>
                    </TitleWithButton>
                  </InstitutionListHeaderRow>
                </header>
                <section>
                  {listDisplay.map((row) => (
                    <InstitutionListBodyRow
                      key={row.id}
                      disabled={row?.status === 'inactive'}
                    >
                      <InstitutionListBodyCell>
                        <InputCheckWithoutForm
                          type="checkbox"
                          onChange={(e) =>
                            handleCheck(row.id ?? '', e.target.checked)
                          }
                          checked={row.isChecked}
                        />
                      </InstitutionListBodyCell>

                      <InstitutionListBodyCell>
                        <strong>{row.institutionName}</strong>
                      </InstitutionListBodyCell>
                      <InstitutionListBodyCell>
                        {row.institutionType}
                      </InstitutionListBodyCell>

                      <InstitutionListBodyCell>
                        <p>{row.uf}</p>
                      </InstitutionListBodyCell>
                      <InstitutionListBodyCell>
                        <p>{row.city}</p>
                      </InstitutionListBodyCell>

                      <InstitutionListBoundPatientCountBodyCell>
                        {row.boundPatientsCount}
                      </InstitutionListBoundPatientCountBodyCell>
                      {/* <InstitutionListBodyActionsCell>
                        <ActionLinkTable>
                          <MdRemoveRedEye size={25} />
                          <span>visualizar</span>
                        </ActionLinkTable>
                      </InstitutionListBodyActionsCell>
                      <InstitutionListBodyActionsCell>
                        <ActionMenuButtonItemList
                          alignBox="right"
                          previousLocation={location.pathname}
                          list={[
                            {
                              name: 'Editar',
                              action: `/institutions/edit?id=${row.id}`,
                              disabled: true,
                            },
                          ]}
                        />
                      </InstitutionListBodyActionsCell> */}
                    </InstitutionListBodyRow>
                  ))}
                </section>
              </InstitutionList>
            )}
          </Container>
          <Pagination
            totalPages={totalPages}
            onChangePage={(page: number) => setCurrentPage(page)}
            page={currentPage}
          />
        </>
      ) : (
        <CardContentNothingToDisplayBox>
          <CardContentNothingToDisplay>
            <img src={CloudSketchesImage} alt="" />
            Nenhum dado encontrado
          </CardContentNothingToDisplay>
        </CardContentNothingToDisplayBox>
      )}
    </>
  );
};

export default Table;
