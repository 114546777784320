import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  width: fit-content;

  > button {
    background: none;
    border: none;
    display: flex;
    padding: 0;
  }
`;
