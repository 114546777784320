import React from 'react';

import { BannerContent, CommonBanner } from './styles';

interface BannerProps {
  isDoctor: boolean;
  currentPage: 'signIn' | 'signUp';
}

const Banner: React.FC<BannerProps> = ({ isDoctor, currentPage }) => {
  return (
    <CommonBanner isDoctor={isDoctor} currentPage={currentPage}>
      <div>
        <BannerContent isDoctor={isDoctor} currentPage={currentPage} />
      </div>
    </CommonBanner>
  );
};

export default Banner;
