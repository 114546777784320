/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  ChangeEvent,
  InputHTMLAttributes,
} from 'react';
import { useField } from '@unform/core';
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';

import { useCurrentEffect } from '../../lib/use-current-effect';

import Spinner from '../Spinner';
import { User } from '../../entities/User';
import { useAppointment } from '../../hooks/appointment';
import getRole from '../../utils/getRole';
import {
  getAvatarImage,
  getUserById,
  getUsersByFilter,
  UsersFilterProps,
} from '../../services/user';

import Avatar from '../../assets/images/avatar.svg';

import {
  Container,
  Error,
  SuggestionList,
  Content,
  ItemSuggestionList,
} from './styles';
import AppointmentAreaEnum from '../../enums/AppointmentAreaEnum';

export interface BulkList {
  id: string;
  name: string;
  avatar: string;
  cpf?: string;
  email?: string;
  institutionId?: string;
}

export interface HandleBulkList {
  newPatient?: BulkList;
  patientId?: string;
}

interface ImageList {
  id: string;
  avatar: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeUser?: (item?: User) => void;
  handleBulkList?: ({ newPatient, patientId }: HandleBulkList) => void;
  setIsDisabled?: (state: boolean) => void;
  type: 'patient' | 'professional';
  institution?: boolean;
  optionFilter: 'name' | 'cpf' | 'email';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  flow?: 'create' | 'edit';
  previousData?: {
    id?: string;
  };
}

export interface InputSuggestRef {
  getSelectedUser: () => User | undefined;
  clearInput: () => void;
}

const InputSuggestUser: React.ForwardRefRenderFunction<
  InputSuggestRef,
  InputProps
> = (
  {
    onChangeUser,
    handleBulkList,
    setIsDisabled,
    type,
    optionFilter,
    institution,
    icon,
    flow,
    previousData,
    ...rest
  },
  ref,
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputMaskRef = useRef<any>(null);
  const { fieldName, defaultValue, error, registerField } = useField(type);

  const { appointmentSelected } = useAppointment();
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [parameterValue, setParameterValue] = useState('');

  const [users, setUsers] = useState<User[]>([]);
  const [imageList, setImageList] = useState<ImageList[]>([]);

  const [selectedUser, setSelectedUser] = useState<User>();
  const [selectedPatient, setSelectedPatient] = useState<User>();
  const [selectedProfessional, setSelectedProfessional] = useState<User>();

  const placeholderText = useMemo(() => {
    if (loading) {
      return 'Carregando...';
    }

    let userType: string;

    if (type === 'professional') {
      if (flow === 'create') {
        userType =
          appointmentSelected?.area === AppointmentAreaEnum.MEDICINE
            ? 'do(a) médico(a)'
            : 'do(a) enfermeiro(a)';
      } else {
        userType = 'do(a) profissional';
      }
    } else {
      userType = 'do(a) paciente';
    }

    switch (optionFilter) {
      case 'cpf':
        return `Digite o CPF ${userType}`;
      case 'email':
        return `Digite o e-mail ${userType}`;
      case 'name':
        return `Digite o nome ${userType}`;
      default:
        return 'parâmetro de busca';
    }
  }, [appointmentSelected, loading, optionFilter, type, flow]);

  useEffect(() => {
    if (optionFilter === 'name' && inputRef.current) {
      inputRef.current.value = selectedUser?.name ?? '';
      setParameterValue('');
    }

    if (optionFilter === 'cpf' && inputMaskRef.current) {
      inputMaskRef.current.setInputValue(selectedUser?.cpf ?? '');
      setParameterValue('');
    }

    if (optionFilter === 'email' && inputRef.current) {
      inputRef.current.value = selectedUser?.email ?? '';
      setParameterValue('');
    }
  }, [optionFilter, selectedUser]);

  useCurrentEffect(
    (isCurrent) => {
      const exec = async () => {
        setLoading(true);
        const searchParameters: UsersFilterProps = {
          type,
          appointmentType: appointmentSelected?.type,
          occupationArea:
            flow === 'create' ? appointmentSelected?.area : undefined,
        };

        searchParameters[optionFilter] = parameterValue;

        const usersData = await getUsersByFilter({
          ...searchParameters,
        });

        if (isCurrent()) {
          setUsers([...usersData]);
          setLoading(false);
        }
      };

      if (parameterValue.length) {
        exec();
      }

      return () => {
        setLoading(false);
      };
    },
    [optionFilter, type, flow, parameterValue, appointmentSelected],
  );

  useCurrentEffect(
    (isCurrent) => {
      Promise.all(
        users.map(async (item) => ({
          id: item.id ?? '',
          avatar: item.avatar ? await getAvatarImage(item.avatar) : Avatar,
        })),
      ).then((imageListResult) => {
        if (isCurrent()) {
          setImageList(imageListResult);
        }
      });
    },
    [users],
  );

  const userList = useMemo(() => {
    return users.map((user) => ({
      id: user.id,
      professional_id: user.professional?.id,
      patient_id: user.patient?.id,
      name: user.name,
      role: getRole.find((role) => role.value === user.professional?.role)
        ?.label,
      avatar: imageList.find(({ id }) => id === user.id)?.avatar ?? Avatar,
      type: user.type,
      phone: user.phone,
      cpf: user.cpf,
      email: user.email,
      patient: {
        institutionId: user.patient?.institutionId,
      },
    }));
  }, [users, imageList]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleSelectUser = useCallback(
    (user: User) => {
      setUsers([]);
      setSelectedUser(user);

      if (type === 'patient') {
        setSelectedPatient(user);

        if (handleBulkList) {
          handleBulkList({
            newPatient: {
              id: user.patient_id ?? '',
              name: user.name ?? '',
              avatar: user.avatar ?? '',
              cpf: user.cpf ?? '',
              email: user.email ?? '',
              institutionId: user.patient?.institutionId ?? '',
            },
          });
        }
      } else {
        setSelectedProfessional(user);
      }

      if (setIsDisabled) {
        setIsDisabled(false);
      }

      if (onChangeUser) {
        onChangeUser(user);
      }
    },
    [type, onChangeUser, handleBulkList, setIsDisabled],
  );

  const getSelectedUser = useCallback(() => {
    setParameterValue('');
    return selectedUser;
  }, [selectedUser]);

  const clearInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    if (inputMaskRef.current) {
      inputMaskRef.current.setInputValue('');
    }

    setUsers([]);
    setParameterValue('');
    setSelectedUser(undefined);

    if (type === 'patient') {
      setSelectedPatient(undefined);
    } else {
      setSelectedProfessional(undefined);
    }

    if (setIsDisabled) {
      setIsDisabled(true);
    }
  }, [type, setIsDisabled]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return selectedUser;
      },
      setValue: (_: unknown, value?: User) => {
        if (value) {
          setSelectedUser(value);
          setUsers([]);
        }
      },
      clearValue: () => {
        clearInput();
      },
    });
  }, [clearInput, fieldName, registerField, selectedUser]);

  const getUserByPreviousData = useCallback(
    async (id: string, isCurrent: () => boolean) => {
      try {
        setLoading(true);
        const currentUser = await getUserById(id);

        currentUser.avatar = currentUser.avatar
          ? await getAvatarImage(currentUser.avatar)
          : Avatar;

        if (isCurrent()) {
          setParameterValue(currentUser.name ?? '');
          setSelectedUser(currentUser);
        }

        if (type === 'patient' && isCurrent()) {
          setSelectedPatient(currentUser);
        } else if (isCurrent()) {
          setSelectedProfessional(currentUser);
        }

        if (setIsDisabled) {
          setIsDisabled(false);
        }

        if (inputRef.current && inputRef.current.value) {
          inputRef.current.value = currentUser.name ?? '';
        }
      } catch (err) {
        toast.error(
          'Não conseguimos recuperar os dados anteriores corretamente.',
        );
      } finally {
        if (isCurrent()) {
          setLoading(false);
        }
      }
    },
    [type, setIsDisabled],
  );

  useCurrentEffect(
    (isCurrent) => {
      const exec = async () => {
        if (previousData && previousData.id) {
          const { id } = previousData;
          await getUserByPreviousData(id, isCurrent);
        }
      };

      exec();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type],
  );

  const handleChangeInputValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setParameterValue(event.target.value ?? '');
      setSelectedUser(undefined);

      if (type === 'patient') {
        setSelectedPatient(undefined);
      } else {
        setSelectedProfessional(undefined);
      }

      if (setIsDisabled) {
        setIsDisabled(true);
      }
    },
    [type, setIsDisabled],
  );

  useImperativeHandle(ref, () => ({
    getSelectedUser,
    clearInput,
  }));

  return (
    <Container>
      <Content isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
        {icon ?? ''}
        {type === 'patient' && selectedPatient && (
          <img
            src={selectedPatient?.avatar}
            alt={`Foto de ${selectedPatient?.name}`}
          />
        )}
        {type === 'professional' && selectedProfessional && (
          <img
            src={selectedProfessional?.avatar}
            alt={`Foto de ${selectedProfessional?.name}`}
          />
        )}
        {optionFilter === 'cpf' ? (
          <ReactInputMask
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            placeholder={placeholderText}
            ref={inputMaskRef}
            onChange={(event) => handleChangeInputValue(event)}
            {...rest}
            mask="999.999.999-99"
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore:next-line
            maskChar={null}
          />
        ) : (
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            placeholder={placeholderText}
            ref={inputRef}
            onChange={(event) => handleChangeInputValue(event)}
            {...rest}
          />
        )}
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Content>
      <SuggestionList loading={loading}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {!!parameterValue.length &&
              userList.map(
                (user) =>
                  user.type === type && (
                    <li key={user.id}>
                      <ItemSuggestionList
                        type="button"
                        institution={
                          !!(institution && user.patient.institutionId)
                        }
                        onClick={() => handleSelectUser(user)}
                        disabled={!!(institution && user.patient.institutionId)}
                      >
                        <img src={user.avatar} alt={`Foto de ${user.name}`} />
                        <div>
                          <strong>{user.name}</strong>
                          {type === 'professional' && <span>{user.role}</span>}
                        </div>
                        {institution && user.patient.institutionId ? (
                          <span>Vinculado</span>
                        ) : (
                          <span>Selecionar</span>
                        )}
                      </ItemSuggestionList>
                    </li>
                  ),
              )}
          </>
        )}
      </SuggestionList>
    </Container>
  );
};

export default forwardRef(InputSuggestUser);
