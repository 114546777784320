import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import InputTextArea from '../../../../../components/InputTextArea';
import InputWithoutTitle from '../../../../../components/InputWithoutTitle';

import { Field } from '../styles';

import { GridForm } from './styles';

interface SleepInformationProps {
  bedTime?: string;
  wakeUpTime?: string;
  observation?: string;
}

export interface SleepInformationRef {
  getValues(): SleepInformationProps;
}

const SleepInformation: React.ForwardRefRenderFunction<
  SleepInformationRef,
  SleepInformationProps
> = ({ bedTime, wakeUpTime, observation }, ref) => {
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    formRef.current?.setData({
      bedTime,
      wakeUpTime,
      observation,
    });
  }, [bedTime, wakeUpTime, observation]);

  const getValues = () => {
    const additionalData: SleepInformationProps = {
      ...formRef.current?.getData(),
    };

    return additionalData;
  };

  useImperativeHandle(ref, () => ({
    getValues,
  }));

  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <h1>Informações sobre o sono do paciente</h1>

      <GridForm>
        <Field gridArea="hd">
          <label>Horário que costuma ir dormir</label>
          <InputWithoutTitle type="time" name="bedTime" />
        </Field>
        <Field gridArea="ha">
          <label>Horário que costuma acordar</label>
          <InputWithoutTitle type="time" name="wakeUpTime" />
        </Field>
        <Field gridArea="obs">
          <label>Outras observações</label>
          <InputTextArea name="observation" />
        </Field>
      </GridForm>
    </Form>
  );
};

export default forwardRef(SleepInformation);
