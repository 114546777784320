import React from 'react';
import { useModal } from 'react-brave-modal';
import Button from '../../../../components/Button';

import { Container, TextWrapper } from './styles';

import AppointmentTabs from '../../../../assets/images/appointment-tabs.svg';

const Finish: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
  const { closeModal } = useModal();

  return (
    <Container>
      <div>
        <img src={AppointmentTabs} alt="" />
        <TextWrapper>
          <h1>A sala da consulta foi aberta em uma nova aba</h1>
          <p>
            A sala do atendimento foi iniciada em uma nova aba no seu navegador.
            Assim que você estiver pronto, basta apertar o botão verde para
            iniciar a chamada.
          </p>
          <p>
            Você poderá, a qualquer momento, visualizar as informações do
            paciente trocando de abas no seu navegador.
          </p>
        </TextWrapper>
      </div>
      <footer>
        <Button
          buttonType="primary"
          onClick={() => {
            onClose && onClose();
            closeModal();
          }}
        >
          Ok, entendi
        </Button>
      </footer>
    </Container>
  );
};

export default Finish;
