import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.white};
  border-radius: 6px;
  color: ${(props) => props.theme.colors.grayAlternative};
  padding: 12px 16px;
  width: 100%;

  & + div {
    margin-top: 8px;
  }

  strong {
    position: absolute;
    top: 9px;
    color: ${(props) => props.theme.colors.grayAlternative};
    font-size: 0.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.5rem;
    text-transform: uppercase;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: ${props.theme.colors.danger};
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.graySecondary};
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: ${props.theme.colors.graySecondary};
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: ${(props) => props.theme.colors.black};
    font-family: 'Raleway', sans-serif !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    line-height: 14px;
    padding-top: 15px;

    &:focus {
      border: 0 none;
      box-shadow: 0 0 0 0;
      outline: 0;
    }

    &::placeholder {
      color: ${(props) => props.theme.colors.graySecondary};
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: ${(props) => props.theme.colors.danger};
    color: ${(props) => props.theme.colors.white};

    &::before {
      border-color: ${(props) => props.theme.colors.danger} transparent;
    }
  }
`;

export const EyeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 25px;
  outline: none;

  svg {
    margin: 0;
  }
`;
