import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;

  div {
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #4f4f4f;

    button {
      background: #ffffff00;
      border: 0;
      font-size: 12px;
      line-height: 14px;

      font-feature-settings: 'pnum' on, 'lnum' on;

      color: ${(props) => props.theme.colors.primary};

      margin-top: 0;
    }
  }

  label {
    font-style: normal;
    font-weight: normal;
    padding-top: 4px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      label {
        span {
          background: red;
        }
      }
    `}
`;
