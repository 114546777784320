import React from 'react';

import { Container } from './styles';
import Loader from '../../assets/images/loader-gif.gif';
import Coletando from '../../assets/images/coletando-informacoes.png';

const Loading: React.FC = () => {
  return (
    <Container>
      <div>
        <img src={Coletando} alt="Cinco" />

        <img src={Loader} alt="Loading" />
        <br />
        <p>Aguarde, estamos</p>
        <p>coletando as informações</p>
      </div>
    </Container>
  );
};

export default Loading;
