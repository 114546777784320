import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  height: 630px;
  width: 100%;
  padding: 80px 50px;
`;

export const CardHeader = styled.div`
  h1 {
    font-size: 22px;
    font-weight: 700;
  }

  h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 8px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 800px;

  form {
    margin-top: 38px;
    margin-right: 40px;
    min-width: 275px;
  }
`;

export const Field = styled.div`
  font-size: 14px;

  & + div {
    margin-top: 20px;
  }

  > div {
    height: 40px;
    margin-top: 5px;
  }
`;
