import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  lazy,
  Suspense,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useModal } from 'react-brave-modal';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../../../../../lib/use-current-effect';

import { Council } from '../../../../../../entities/Council';
import { OccupationArea } from '../../../../../../entities/Professional';
import getUF from '../../../../../../utils/getUF';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import getPermitionsInUserEditProfessionalData from '../../../../../../utils/getPermitionsInUserEditProfessionalData';
import { getCouncilTypeByOccupationArea } from '../../../../../../utils/getCouncilTypeByOccupationArea';

import InputWithoutTitle from '../../../../../../components/InputWithoutTitle';
import RequiredFieldInformation from '../../../../../../components/RequiredFieldInformation';
import Button from '../../../../../../components/Button';

import { ReactComponent as EditIcon } from '../../../../../../assets/images/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../../../assets/images/trash.svg';

import {
  Field,
  SpecializationsContainer,
  InputSelect,
  List,
  ListCard,
} from '../../styles';

const EditCouncilsModal = lazy(
  () =>
    import(/* webpackChunkName: "EditCouncilsModal" */ './EditCouncilsModal'),
);

interface CouncilsProps {
  occupationArea?: OccupationArea;
  isStudent: boolean;
  councils: Council[];
  setCouncils: React.Dispatch<React.SetStateAction<Council[]>>;
}

interface UfListProps {
  value: string;
  label: string;
}

const Councils: React.FC<CouncilsProps> = ({
  occupationArea,
  isStudent,
  councils,
  setCouncils,
}) => {
  const formCouncilsRef = useRef<FormHandles>(null);
  const { showModal } = useModal();

  const [ufList, setUfList] = useState<UfListProps[]>(getUF);
  const [permitionsProps] = useState(getPermitionsInUserEditProfessionalData);

  useCurrentEffect(
    (isCurrent) => {
      const newUfList = getUF.filter(
        (uf) => councils.findIndex((council) => council.uf === uf.label) < 0,
      );

      if (isCurrent()) {
        setUfList(newUfList);
      }
    },
    [councils],
  );

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async (data) => {
      try {
        const schema = Yup.object().shape({
          type: Yup.string().required('O conselho é obrigatório'),
          number: Yup.string().required(
            'O número de registro no conselho é obrigatório',
          ),
          uf: Yup.string().required('O estado é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (isCurrent()) {
          setCouncils((state) => [
            ...state,
            {
              ...data,
              isMainCouncil: !(state.length > 0),
            },
          ]);
        }

        formCouncilsRef.current?.setFieldValue('number', '');
        formCouncilsRef.current?.setFieldValue('uf', '');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formCouncilsRef.current?.setErrors(errors);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.message) {
          toast.error(err.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }
    },
    [setCouncils],
  );

  const handleUpdateMainCouncil = useCurrentCallback(
    (isCurrent) => (councilIndex) => {
      if (isCurrent()) {
        setCouncils((state) =>
          state.map((council, index) => ({
            ...council,
            isMainCouncil: councilIndex === index,
          })),
        );
      }
    },
    [setCouncils],
  );

  const handleRemove = useCurrentCallback(
    (isCurrent) => (councilIndex) => {
      const isCouncilMain = councils[councilIndex].isMainCouncil;

      isCouncilMain
        ? toast.error(
            'Selecione outro registro como principal antes de excluir este.',
          )
        : isCurrent() &&
          setCouncils((state) =>
            state.filter((_, index) => councilIndex !== index),
          );
    },
    [councils, setCouncils],
  );

  const handleEdit = useCallback(
    (index: number) => {
      showModal({
        type: 'custom',
        data: (
          <Suspense fallback={false}>
            <EditCouncilsModal
              councils={councils}
              setCouncils={setCouncils}
              index={index}
            />
          </Suspense>
        ),
      });
    },
    [showModal, councils, setCouncils],
  );

  useEffect(() => {
    if (occupationArea) {
      const councilType = getCouncilTypeByOccupationArea(occupationArea);

      formCouncilsRef.current?.setFieldValue('type', councilType);
    }
  }, [occupationArea]);

  return (
    <SpecializationsContainer>
      <h1>Registros Profissionais</h1>

      <Form ref={formCouncilsRef} onSubmit={handleSubmit}>
        <div>
          <Field gridArea="f1">
            <label>
              Conselho
              {!isStudent && <RequiredFieldInformation />}
            </label>
            <InputWithoutTitle name="type" disabled />
          </Field>
        </div>

        <div>
          <Field gridArea="f1">
            <label>
              Número do Registro
              {!isStudent && <RequiredFieldInformation />}
            </label>
            <InputWithoutTitle
              name="number"
              type="number"
              onWheel={(e) => e.currentTarget.blur()}
              disabled={
                !occupationArea ||
                permitionsProps[occupationArea].quantityCouncils <=
                  councils.length
              }
            />
          </Field>

          <Field gridArea="f2">
            <label>
              UF
              {!isStudent && <RequiredFieldInformation />}
            </label>
            <InputSelect
              name="uf"
              options={ufList}
              placeholder=""
              isClearable
              disabled={
                !occupationArea ||
                permitionsProps[occupationArea].quantityCouncils <=
                  councils.length
              }
            />
          </Field>
        </div>

        <Button
          buttonType="primary"
          type="submit"
          disabled={
            !occupationArea ||
            permitionsProps[occupationArea].quantityCouncils <= councils.length
          }
        >
          Incluir Registro
        </Button>
      </Form>

      {councils.length > 0 && (
        <>
          <h2>seus Registros</h2>

          <List>
            {councils.map((council, index) => (
              <li key={`${council.number}${council.uf}`}>
                <ListCard size="small">
                  <div>
                    <span>
                      {council.type} <b>{`${council.number} ${council.uf}`}</b>
                    </span>
                  </div>

                  <button type="button" onClick={() => handleEdit(index)}>
                    <EditIcon />
                  </button>

                  {(councils.length > 1 || isStudent) && (
                    <button type="button" onClick={() => handleRemove(index)}>
                      <TrashIcon />
                    </button>
                  )}
                </ListCard>

                {occupationArea && occupationArea === 'Medicina' && (
                  <>
                    <button
                      type="button"
                      disabled={council.isMainCouncil}
                      onClick={() => handleUpdateMainCouncil(index)}
                    >
                      <div />
                    </button>

                    <span>Principal</span>
                  </>
                )}
              </li>
            ))}
          </List>
        </>
      )}
    </SpecializationsContainer>
  );
};

export default Councils;
