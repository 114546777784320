import styled from 'styled-components';

export const Container = styled.div`
  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: 500;
    line-height: 14px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4f4f4f;
    padding: 10px 20px;
    margin-top: 40px;

    > svg {
      margin-right: 7px;
    }
  }
`;
