import styled from 'styled-components';

export interface CardContentNothingToDisplayProps {
  imageWidth?: string;
}

export const Container = styled.div<CardContentNothingToDisplayProps>`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;

  text-align: center;

  color: #707683;

  > img {
    width: ${({ imageWidth }) => imageWidth ?? 'initial'};
    margin-bottom: 30px;
  }
`;
