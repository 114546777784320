import React, { useCallback } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';

import { format } from 'date-fns';
import { CardContainer, CardHeader, CardTitle } from '../styles';
import { LastNoticesCardContent } from './styles';

import InstitutionIcon from '../../../../assets/images/institution-icon.svg';
import LastNoticesDTO from '../../../../dtos/LastNoticesDTO';
import EmptyCard from '../EmptyCard';
import Spinner from '../../../../components/Spinner';

interface LastNoticesCardProps {
  loading: boolean;
  data?: LastNoticesDTO[];
}

const LastNoticesCard: React.FC<LastNoticesCardProps> = ({
  data = [],
  loading,
}) => {
  const history = useHistory();

  const showAll = useCallback(() => {
    history.push('/patient/list');
  }, [history]);

  return (
    <CardContainer gridArea="ln">
      <CardHeader>
        <CardTitle>Últimos pacientes em alerta</CardTitle>
        {data.length > 0 && (
          <button type="button" onClick={showAll}>
            Ver todos <IoIosArrowForward size={12} />
          </button>
        )}
      </CardHeader>
      {!loading ? (
        <>
          {data.length > 0 ? (
            <LastNoticesCardContent>
              <header>
                <div>
                  <h2>Paciente</h2>
                </div>
                <div>
                  <h2>Instituição</h2>
                </div>
                <div>
                  <h2>Data</h2>
                </div>
              </header>
              {data.map((patient, index) => (
                <section key={patient.patientId}>
                  <div>
                    <b>{index + 1}º</b>
                    <Link to={`/patient/profile?id=${patient.patientId}`}>
                      {patient.patientName}
                    </Link>
                  </div>
                  <div>
                    {patient.institutionName ? (
                      <>
                        <img src={InstitutionIcon} alt="ícone da instituição" />
                        <span>{patient.institutionName}</span>
                      </>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <div>
                    <span>
                      {format(
                        new Date(patient.noticeDate),
                        "dd'/'MM'/'yyyy' às 'HH'h'mm",
                      )}
                    </span>
                  </div>
                </section>
              ))}
            </LastNoticesCardContent>
          ) : (
            <EmptyCard />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </CardContainer>
  );
};

export default LastNoticesCard;
