import { api } from '../api';
import { User } from '../../entities/User';
import OccupationAreaEnum from '../../enums/OccupationAreaEnum';

interface ValidateUserDataService {
  id?: string;
  username?: string;
  email?: string;
  cpf?: string;
  phone?: string;
  occupationArea?: OccupationAreaEnum;
  role?: string;
  councils?: {
    number?: string;
    type?: string;
    uf?: string;
  }[];
  studentDetail?: {
    enrollment?: string;
    educationalInstitution?: string;
  };
}

export const validateUserDataService = async (
  userData: ValidateUserDataService,
): Promise<User> => {
  const { data } = await api.post('/users/validateUserData', userData);

  return data;
};
