import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 470px;
  width: 780px;
`;

export const Content = styled.div`
  margin-left: 55px;

  h1 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
    font-weight: 700;
  }

  p {
    color: #4c4c4c;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 45px;
    margin-top: 18px;
    max-width: 320px;
    padding: 0;
  }
`;
