import React, { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import { User } from '../../../../../entities/User';
import { useAuth } from '../../../../../hooks/auth';
import { usePermission } from '../../../../../hooks/permission';
import { updateAvatarImage } from '../../../../../services/user';
import getRole from '../../../../../utils/getRole';
import compressImage from '../../../../../utils/compressImage';

import { ReactComponent as EditIcon } from '../../../../../assets/images/edit.svg';

import InputFile from '../../../../../components/InputFile';

import {
  Container,
  CardContent,
  UserData as UserDataProps,
  Title,
  Name,
  ContentTable,
  Column,
  Row,
} from './styles';
import { useCurrentCallback } from '../../../../../lib/use-current-effect';
import TypeSpecialtyProfessionalEnum from '../../../../../enums/TypeSpecialtyProfessionalEnum';

interface UserDataProps {
  id?: string;
  avatar?: string;
  name?: string;
  profile?: string;
  type?: string;
  role?: string;
  councilName?: string;
  councilNumber?: string;
  specialties?: (string | undefined)[];
  uf?: string;
  city?: string;
  email?: string;
  phone?: string;
  isAdministrator: boolean;
  isProfessional: boolean;
}

interface PersonalDataProps {
  user?: User;
}

const PersonalData: React.FC<PersonalDataProps> = ({ user }) => {
  const { hasPermission } = usePermission();
  const { sync, user: userAuth } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const userData = useMemo<UserDataProps>(
    () => ({
      id: user?.id,
      avatar: user?.avatar,
      name: user?.name,
      profile: user?.profile?.type,
      type: user?.type,
      role: getRole.find((role) => role.value === user?.professional?.role)
        ?.label,
      councilName:
        user?.professional?.councils && user.professional.councils.length > 0
          ? user.professional.councils[0].type
          : '-',
      councilNumber:
        user?.professional?.councils && user?.professional?.councils?.length > 0
          ? user?.professional?.councils.find(
              (council) => council.isMainCouncil,
            )?.number
          : '-',
      specialties:
        user?.professional?.specialtiesProfessional &&
        user?.professional?.specialtiesProfessional.length
          ? user?.professional?.specialtiesProfessional
              .filter(({ isActive }) => isActive)
              .map(({ specialty, type }) =>
                type === TypeSpecialtyProfessionalEnum.GENERAL
                  ? 'Generalista'
                  : specialty?.name,
              )
          : [],
      uf: user?.address?.uf,
      city: user?.address?.city,
      email: user?.email,
      phone: user?.phone,
      isAdministrator: user?.profile?.type === 'administrator',
      isProfessional: user?.type === 'professional',
    }),
    [user],
  );

  const handleAvatarUpload = useCurrentCallback(
    (isCurrent) => async (file: File) => {
      try {
        const base64AvatarImage = await compressImage(file);

        if (base64AvatarImage && isCurrent()) {
          const updatedUser = await updateAvatarImage(
            userData.id ?? '',
            base64AvatarImage,
          );

          if (updatedUser.id === userAuth?.id) {
            sync(updatedUser);
          }

          toast.success('Foto alterada com sucesso.');
        }
      } catch (error) {
        toast.error(error);
      }
    },
    [userData, sync, userAuth],
  );

  const canEdit = useMemo(
    () =>
      hasPermission(
        userAuth?.id === user?.id ? 'edit_my_details' : 'edit_other_users',
      ),
    [hasPermission, user, userAuth],
  );

  const handleEditUser = useCallback(() => {
    if (user?.id) {
      history.push(`/user/edit?id=${user.id}`, { from: location.pathname });
    }
  }, [history, location, user]);

  return (
    <Container gridArea="ud">
      <CardContent>
        <Form onSubmit={() => {}}>
          <InputFile
            name="avatar"
            image={userData.avatar}
            backgroundType="avatar"
            onUpload={handleAvatarUpload}
            withButton
          />
        </Form>
        <UserDataProps>
          <Title>
            <Name>
              <h1>{userData.name}</h1>
              {userData.isAdministrator && <span>Adm</span>}
            </Name>
            <button type="button" onClick={handleEditUser} disabled={!canEdit}>
              Editar
              <EditIcon />
            </button>
          </Title>
          {userData.isProfessional && <h2>{userData.role}</h2>}
          <ContentTable>
            <Column>
              {userData.isProfessional &&
                userData.councilName &&
                userData.councilNumber && (
                  <Row>
                    <span>{userData.councilName}:</span>
                    <span>{userData.councilNumber}</span>
                  </Row>
                )}
              <Row>
                <span>UF:</span>
                <span>{userData.uf}</span>
              </Row>
              <Row>
                <span>Cidade:</span>
                <span>{userData.city}</span>
              </Row>
            </Column>
            <Column>
              <Row>
                <span>E-mail:</span>
                <span>{userData.email}</span>
              </Row>
              <Row>
                <span>Telefone:</span>
                <span>{userData.phone}</span>
              </Row>
              {!!userData.specialties?.length && (
                <Row>
                  <span>Especialidades:</span>
                  <div>
                    {userData.specialties.map((specialty) => (
                      <span key={specialty}>{specialty}</span>
                    ))}
                  </div>
                </Row>
              )}
            </Column>
          </ContentTable>
        </UserDataProps>
      </CardContent>
    </Container>
  );
};

export default PersonalData;
