/* eslint-disable @typescript-eslint/camelcase */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { FiDownload } from 'react-icons/fi';

import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import PersonalData, { PersonalDataRef } from './PersonalData';
import ProfessionalData, { ProfessionalDataRef } from './ProfessionalData';
import Foto from './Foto';
import Status, { StatusDataRef } from './Status';
import { usePatient } from '../../../hooks/patient';
import DashboardTemplate from '../../../templates/Dashboard';
import {
  Button,
  Container,
  Content,
  LeftSide,
  RightSide,
  Option,
  ButtonsContainer,
  ConsentTermButton,
} from './styles';

import { ReactComponent as PersonIcon } from '../../../assets/images/person2.svg';
import { ReactComponent as BagIcon } from '../../../assets/images/briefcase.svg';
import { ReactComponent as ThermometerIcon } from '../../../assets/images/thermometer.svg';
// import { ReactComponent as PeopleIcon } from '../../../assets/images/people.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import { ReactComponent as CameraIcon } from '../../../assets/images/camera.svg';
import { ReactComponent as TurnOnOffIcon } from '../../../assets/images/turn-on-off.svg';

// import Kinships, { KinshipsRef } from './Kinships';
import AdditionalData, { AdditionalDataRef } from './AdditionalData';
import useQuery from '../../../hooks/query';
import { usePermission } from '../../../hooks/permission';
import { useAuth } from '../../../hooks/auth';
import { generateLastSignedConsentTermPDF } from '../../../services/signedConsentTerm';
import UserStatusEnum from '../../../enums/UserStatusEnum';
import UserStatusReasonEnum from '../../../enums/UserStatusReasonEnum';
import MainAreasEnum from '../../../enums/MainAreasEnum';
import RisksHealthCondition, {
  RisksHealthConditionRef,
} from './RisksHealthCondition';

export interface PersonalDataProps {
  name?: string;
  cpf?: string;
  birthday?: string;
  gender?: 'M' | 'F' | 'ND';
  email?: string;
  phone?: string;
  whatsapp?: string;
  cns?: string;
  street?: string;
  number?: number;
  complement?: string;
  neighborhood?: string;
  cep?: string;
  city?: string;
  uf?: string;
}
export interface StatusDataProps {
  status: UserStatusEnum;
  reason: UserStatusReasonEnum;
  reason_date?: Date;
  related_monitored_disease?: boolean;
  cause_reason?: string;
  observation?: string;
}

export interface ProfessionalDataProps {
  profession?: string;
  company?: string;
  role?: string;
  street?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  cep?: string;
  uf?: string;
}

export interface RisksHealthConditionProps {
  riskDegree?: string;
  icd10Id?: string;
  severity?: string;
  isMainHealthCondition?: boolean;
  medicine?: string;
  observation?: string;
  type?: 'riskDegree' | 'icd10';
}

export interface KinshipsProps {
  name?: string;
  degree?: string;
}

type State = {
  next: boolean;
  finish: boolean;
  saveAndEditLater: boolean;
};

type Actions = keyof State | 'reset';

const reducer = (state: State, action: Actions): State => {
  const newState = { ...state };

  Object.keys(state).forEach((key) => {
    newState[key as keyof typeof state] =
      action === 'reset' ? false : key === action;
  });

  return newState;
};

const Edit: React.FC = () => {
  const { hasPermission } = usePermission();
  const query = useQuery();
  const history = useHistory();

  const {
    location: { state: locationState },
  } = history;

  const { user: userAuth } = useAuth();

  const { patient, sync, updatePatient, createStatus, saveLocal, clearLocal } =
    usePatient();

  const [loading, setLoading] = useState(false);

  const id = useMemo(() => query.get('id'), [query]);
  const page = useMemo(() => query.get('page'), [query]);
  const location = useLocation();

  const [buttonLoadingStatus, setButtonLoadingStatus] = useReducer(reducer, {
    next: false,
    finish: false,
    saveAndEditLater: false,
  });

  const areThereAnyButtonsLoading = useMemo(
    () => Object.values(buttonLoadingStatus).some((value) => value),
    [buttonLoadingStatus],
  );

  const type = useMemo(
    () => (!id || query.get('status') === 'unfinished' ? 'register' : 'edit'),
    [id, query],
  );

  useEffect(() => {
    let canStayHere = true;
    if (location.pathname.includes('edit')) {
      canStayHere = hasPermission('edit_patient');
    } else {
      canStayHere = hasPermission('register_patient');
    }

    if (!canStayHere) {
      history.push('/error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermission, location]);

  useEffect(() => {
    if (id) {
      sync(id);
    } else if (!patient?.id) {
      history.push('/patient/list');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const personalDataRef = useRef<PersonalDataRef>(null);
  const professionalDataRef = useRef<ProfessionalDataRef>(null);
  const risksHealthConditionRef = useRef<RisksHealthConditionRef>(null);
  // const kinshipsRef = useRef<KinshipsRef>(null);
  const additionalDataRef = useRef<AdditionalDataRef>(null);
  const statusDataRef = useRef<StatusDataRef>(null);

  const options = useMemo(() => {
    const baseOptions = [
      {
        icon: <PersonIcon />,
        name: 'Dados pessoais',
        component: <PersonalData ref={personalDataRef} />,
        key: 'PersonalData',
        visible: true,
      },
      {
        icon: <BagIcon />,
        name: 'Dados profissionais',
        component: <ProfessionalData ref={professionalDataRef} />,
        key: 'ProfessionalData',
        visible: true,
      },
      {
        icon: <ThermometerIcon />,
        name: 'Condição de saúde e Risco',
        component: (
          <RisksHealthCondition type={type} ref={risksHealthConditionRef} />
        ),
        key: 'RisksHealthCondition',
        visible: true,
      },
      // {
      //   icon: <PeopleIcon />,
      //   name: 'Parentescos',
      //   component: (
      //     <Kinships ref={kinshipsRef} previousData={newPatient.kinships} />
      //   ),
      //   key: 'Kinships',
      // visible: true,
      // },
      {
        icon: <PlusIcon />,
        name: 'Dados Complementares',
        component: <AdditionalData ref={additionalDataRef} />,
        key: 'AdditionalData',
        visible: true,
      },
      {
        icon: <CameraIcon />,
        name: 'Foto',
        component: <Foto />,
        key: 'Foto',
        visible: true,
      },
    ];

    type === 'edit' &&
      baseOptions.push({
        icon: <TurnOnOffIcon />,
        name: 'Status',
        component: <Status ref={statusDataRef} />,
        key: 'Status',
        visible: true,
      });

    return baseOptions;
  }, [type]);

  const [selected, setSelected] = useState(options[0].key);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const getRightSideComponent = useMemo(() => {
    const component = options.find(({ key }) => selected === key)?.component;
    return component;
  }, [options, selected]);

  const stepCompleted = useMemo(
    () => ({
      1: !!patient?.user?.address,
      2:
        !!patient?.professionalDetail && !!patient?.professionalDetail?.address,
      3:
        !!patient?.healthConditions?.filter(
          (icd10) => icd10.status === 'active',
        ).length && !!patient?.risksDegrees?.length,
      4: !!patient?.complementaryData,
      5: !!patient?.user?.avatar,
    }),
    [patient],
  );

  useEffect(() => {
    if (isFirstLoad) {
      if (type === 'edit') {
        setSelected(options[0].key);
      } else if (!stepCompleted[1]) {
        setSelected(options[0].key);
      } else if (!stepCompleted[2]) {
        setSelected(options[1].key);
      } else if (!stepCompleted[3]) {
        setSelected(options[2].key);
      } else if (!stepCompleted[4]) {
        setSelected(options[3].key);
      } else if (!stepCompleted[5]) {
        setSelected(options[4].key);
      } else {
        setSelected(options[5].key);
      }

      setIsFirstLoad(false);
    }
  }, [isFirstLoad, options, patient, stepCompleted, type]);

  useEffect(() => {
    const key = options.findIndex((item) => item.key === page);

    if (key >= 0) {
      setSelected(options[key].key);
    }
  }, [options, page]);

  const handleNext = useCallback(async () => {
    setButtonLoadingStatus('next');

    let canProceed = false;

    // PersonalData
    if (selected === options[0].key) {
      const personalData = personalDataRef.current?.getValues();
      canProceed = (await personalDataRef.current?.getValidate()) ?? false;

      if (!canProceed) {
        setButtonLoadingStatus('reset');

        return;
      }

      saveLocal({
        cns: personalData?.cns,
        user: {
          ...patient?.user,
          name: personalData?.name,
          email: personalData?.email?.trim().toLowerCase(),
          cpf: personalData?.cpf,
          birthday: personalData?.birthday
            ? parseISO(personalData?.birthday)
            : undefined,
          phone: personalData?.phone,
          gender: personalData?.gender,
          whatsapp: personalData?.whatsapp,
          address: {
            ...patient?.user?.address,
            street: personalData?.street,
            number: personalData?.number,
            complement: personalData?.complement,
            neighborhood: personalData?.neighborhood,
            cep: personalData?.cep,
            city: personalData?.city,
            uf: personalData?.uf,
          },
        },
      });
    }

    // ProfessionalData
    if (selected === options[1].key) {
      const professionalData = professionalDataRef.current?.getValues();
      canProceed = (await professionalDataRef.current?.getValidate()) ?? false;

      if (!canProceed) {
        setButtonLoadingStatus('reset');

        return;
      }

      saveLocal({
        professionalDetail: {
          ...patient?.professionalDetail,
          profession: professionalData?.profession,
          company: professionalData?.company,
          role: professionalData?.role,
          address: {
            ...patient?.professionalDetail?.address,
            street: professionalData?.street,
            number: professionalData?.number,
            complement: professionalData?.complement,
            neighborhood: professionalData?.neighborhood,
            cep: professionalData?.cep,
            city: professionalData?.city,
            uf: professionalData?.uf,
          },
        },
      });
    }

    // RisksHealthCondition
    if (selected === options[2].key) {
      const risksHealthCondition = risksHealthConditionRef.current?.getValues();
      canProceed =
        (await risksHealthConditionRef.current?.getValidate()) ?? false;

      if (!canProceed) {
        setButtonLoadingStatus('reset');

        return;
      }

      saveLocal({
        newRiskDegree: risksHealthCondition?.riskDegree,
      });
    }

    // AdditionalData
    if (selected === options[3].key) {
      const additionalData = additionalDataRef.current?.getValues();
      canProceed = true;

      saveLocal({
        complementaryData: {
          ...patient?.complementaryData,
          weight: additionalData?.weight,
          height: additionalData?.height,
          emergencyContacts: additionalData?.emergencyContacts,
          bedTime: additionalData?.bedTime,
          wakeUpTime: additionalData?.wakeUpTime,
          observation: additionalData?.observation,
        },
      });
    }

    // Foto
    if (selected === options[4].key) {
      canProceed = true;
    }

    setButtonLoadingStatus('reset');

    if (selected !== options[options.length - 1].key && canProceed) {
      const nextOptionIndex =
        options.findIndex(({ key }) => key === selected) + 1;

      setSelected(options[nextOptionIndex].key);
    }
  }, [selected, options, patient, saveLocal]);

  const handleChange = useCallback(
    async (keyName: string) => {
      let canProceed = false;

      // PersonalData
      if (selected === options[0].key) {
        const personalData = personalDataRef.current?.getValues();
        canProceed = (await personalDataRef.current?.getValidate()) ?? false;

        if (!canProceed) {
          return;
        }

        saveLocal({
          cns: personalData?.cns,
          user: {
            ...patient?.user,
            name: personalData?.name,
            email: personalData?.email?.trim().toLowerCase(),
            cpf: personalData?.cpf,
            birthday: personalData?.birthday
              ? parseISO(personalData?.birthday)
              : undefined,
            phone: personalData?.phone,
            gender: personalData?.gender,
            whatsapp: personalData?.whatsapp,
            address: {
              ...patient?.user?.address,
              street: personalData?.street,
              number: personalData?.number,
              complement: personalData?.complement,
              neighborhood: personalData?.neighborhood,
              cep: personalData?.cep,
              city: personalData?.city,
              uf: personalData?.uf,
            },
          },
        });
      }

      // ProfessionalData
      if (selected === options[1].key) {
        const professionalData = professionalDataRef.current?.getValues();
        canProceed =
          (await professionalDataRef.current?.getValidate()) ?? false;

        if (!canProceed) {
          return;
        }

        saveLocal({
          professionalDetail: {
            ...patient?.professionalDetail,
            profession: professionalData?.profession,
            company: professionalData?.company,
            role: professionalData?.role,
            address: {
              ...patient?.professionalDetail?.address,
              street: professionalData?.street,
              number: professionalData?.number,
              complement: professionalData?.complement,
              neighborhood: professionalData?.neighborhood,
              cep: professionalData?.cep,
              city: professionalData?.city,
              uf: professionalData?.uf,
            },
          },
        });
      }

      // RisksHealthCondition
      if (selected === options[2].key) {
        const risksHealthCondition =
          risksHealthConditionRef.current?.getValues();
        canProceed =
          (await risksHealthConditionRef.current?.getValidate()) ?? false;

        if (!canProceed) {
          return;
        }

        saveLocal({
          newRiskDegree: risksHealthCondition?.riskDegree,
        });
      }

      // AdditionalData
      if (selected === options[3].key) {
        const additionalData = additionalDataRef.current?.getValues();
        canProceed = true;

        saveLocal({
          complementaryData: {
            ...patient?.complementaryData,
            weight: additionalData?.weight,
            height: additionalData?.height,
            emergencyContacts: additionalData?.emergencyContacts,
            bedTime: additionalData?.bedTime,
            wakeUpTime: additionalData?.wakeUpTime,
            observation: additionalData?.observation,
          },
        });
      }

      // Foto
      if (selected === options[4].key) {
        canProceed = true;
      }
      if (selected === options[options.length - 1].key) {
        canProceed = true;
      }

      if (canProceed) {
        setSelected(keyName);
      }
    },
    [selected, options, patient, saveLocal],
  );

  const handleSave = useCallback(
    async (button: keyof typeof buttonLoadingStatus) => {
      setButtonLoadingStatus(button);

      let canProceed = false;

      // PersonalData
      if (selected === options[0].key) {
        const personalData = personalDataRef.current?.getValues();
        canProceed = (await personalDataRef.current?.getValidate()) ?? false;

        if (!canProceed) {
          setButtonLoadingStatus('reset');

          return;
        }

        try {
          await updatePatient({
            cns: personalData?.cns,
            user: {
              ...patient?.user,
              name: personalData?.name,
              email: personalData?.email,
              cpf: personalData?.cpf,
              birthday: personalData?.birthday
                ? parseISO(personalData?.birthday)
                : undefined,
              phone: personalData?.phone,
              gender: personalData?.gender,
              whatsapp: personalData?.whatsapp,
              address: {
                ...patient?.user?.address,
                street: personalData?.street,
                number: personalData?.number,
                complement: personalData?.complement,
                neighborhood: personalData?.neighborhood,
                cep: personalData?.cep,
                city: personalData?.city,
                uf: personalData?.uf,
              },
            },
          });
        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error('Entre em contato com o administrador.');
          }

          canProceed = false;
        }
      }

      // ProfessionalData
      if (selected === options[1].key) {
        const professionalData = professionalDataRef.current?.getValues();
        canProceed =
          (await professionalDataRef.current?.getValidate()) ?? false;

        if (!canProceed) {
          setButtonLoadingStatus('reset');

          return;
        }

        try {
          await updatePatient({
            professionalDetail: {
              ...patient?.professionalDetail,
              profession: professionalData?.profession,
              company: professionalData?.company,
              role: professionalData?.role,
              address: {
                ...patient?.professionalDetail?.address,
                street: professionalData?.street,
                number: professionalData?.number,
                complement: professionalData?.complement,
                neighborhood: professionalData?.neighborhood,
                cep: professionalData?.cep,
                city: professionalData?.city,
                uf: professionalData?.uf,
              },
            },
          });
        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error('Entre em contato com o administrador.');
          }

          canProceed = false;
        }
      }

      // RisksHealthCondition
      if (selected === options[2].key) {
        const risksHealthCondition =
          risksHealthConditionRef.current?.getValues();
        canProceed =
          (await risksHealthConditionRef.current?.getValidate()) ?? false;

        if (!canProceed) {
          setButtonLoadingStatus('reset');

          return;
        }

        if (
          patient?.currentRiskDegree?.degree !==
          risksHealthCondition?.riskDegree
        ) {
          try {
            await updatePatient({
              newRiskDegree: risksHealthCondition?.riskDegree,
            });
          } catch (error) {
            if (error.response) {
              toast.error(error.response.data.message);
            } else {
              toast.error('Entre em contato com o administrador.');
            }

            canProceed = false;
          }
        }
      }

      // AdditionalData
      if (selected === options[3].key) {
        const additionalData = additionalDataRef.current?.getValues();
        canProceed = true;

        try {
          await updatePatient({
            complementaryData: {
              ...patient?.complementaryData,
              weight: additionalData?.weight,
              height: additionalData?.height,
              emergencyContacts: additionalData?.emergencyContacts,
              bedTime: additionalData?.bedTime,
              wakeUpTime: additionalData?.wakeUpTime,
              observation: additionalData?.observation,
            },
          });
        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error('Entre em contato com o administrador.');
          }

          canProceed = false;
        }
      }

      // Foto
      if (selected === options[4].key) {
        canProceed = true;

        try {
          await updatePatient({});
        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error('Entre em contato com o administrador.');
          }

          canProceed = false;
        }
      }

      // Status
      if (options[5] && selected === options[5].key) {
        const statusData = statusDataRef.current?.getValues();
        canProceed = (await statusDataRef.current?.getValidate()) ?? false;

        if (!canProceed) {
          setButtonLoadingStatus('reset');

          return;
        }

        try {
          if (statusData?.status !== patient?.user?.currentStatus?.status) {
            await createStatus({
              ...statusData,
              created_updated_by: userAuth?.id ?? '',
              userId: patient?.user?.id ?? '',
            });
          }

          await updatePatient({});
        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error('Entre em contato com o administrador.');
          }

          canProceed = false;
        }
      }

      if (canProceed) {
        if (type === 'register') {
          history.push(`/patient/profile?id=${patient?.id}`);
        } else {
          toast.success('Paciente atualizado com sucesso.');

          history.push('/patient/list');
        }
      } else {
        setButtonLoadingStatus('reset');
      }
    },
    [
      buttonLoadingStatus,
      selected,
      options,
      history,
      type,
      patient,
      updatePatient,
      createStatus,
      userAuth,
    ],
  );

  const handleBack = useCallback(() => {
    if (selected !== options[0].key) {
      const index = options.findIndex(({ key }) => key === selected);
      setSelected(options[index - 1].key);
    }
  }, [selected, options]);

  const handleCancel = useCallback(() => {
    clearLocal();

    let hasPreviousPath = false;

    if (locationState) {
      hasPreviousPath = !!(history.location.state as { from?: string })?.from
        ?.length;
    }

    if (hasPreviousPath) {
      history.goBack();
    } else {
      history.push('/patient/list');
    }
  }, [clearLocal, history, locationState]);

  const pages = useMemo<ElementPageTree[]>(
    () =>
      type === 'edit'
        ? [
            {
              name: 'Pacientes',
              link: '/patient/list',
            },
            {
              name: patient?.user?.name ?? '',
              link: `/patient/profile?id=${id}`,
            },
            { name: 'Dados de perfil' },
          ]
        : [
            {
              name: 'Pacientes',
              link: '/patient/list',
            },
            {
              name: 'Novo',
            },
            {
              name: 'Dados de perfil',
            },
          ],
    [id, patient, type],
  );

  const handlePdfDownload = useCallback(async () => {
    try {
      if (patient?.id) {
        setLoading(true);
        const pdf = await generateLastSignedConsentTermPDF(patient?.id);
        const url = window.URL.createObjectURL(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          new Blob([pdf as any, { type: 'application/pdf' }]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'termo_de_consentimento_livre_e_esclarecido.pdf',
        );
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const responseJSON = await error.response.data.text();
      if (responseJSON) {
        const response = JSON.parse(responseJSON);
        toast.error(response.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }
    }
  }, [patient]);

  return (
    <DashboardTemplate name={MainAreasEnum.PATIENTS}>
      <PageTree pages={pages} />
      <Container>
        <h1>{type === 'edit' ? 'Editar Perfil' : 'Novo Usuário'}</h1>
        <Content>
          <LeftSide>
            {options.map(({ name, icon, key }) => (
              <Option
                key={key}
                onClick={() => handleChange(key)}
                selected={key === selected}
              >
                {icon}
                <span>{name}</span>
              </Option>
            ))}
            {type === 'edit' && patient?.currentSignedConsentTerm && (
              <ConsentTermButton disabled={loading} onClick={handlePdfDownload}>
                <FiDownload />
                <span>Termo de Consentimento Livre e Esclarecido</span>
              </ConsentTermButton>
            )}
          </LeftSide>
          <RightSide>{getRightSideComponent}</RightSide>
        </Content>
      </Container>
      <ButtonsContainer>
        {/* {type === 'register' && <Button color="white">Cancelar</Button>}
         */}
        <Button
          disabled={areThereAnyButtonsLoading}
          color="white"
          onClick={handleCancel}
        >
          Cancelar
        </Button>
        <Button
          marginIcon="left"
          disabled={selected === options[0].key || areThereAnyButtonsLoading}
          color="lite"
          onClick={handleBack}
        >
          Voltar
        </Button>
        <Button
          loading={buttonLoadingStatus.next}
          disabled={
            selected === options[options.length - 1].key ||
            buttonLoadingStatus.finish ||
            buttonLoadingStatus.saveAndEditLater
          }
          onClick={() => handleNext()}
          color="lite"
          marginIcon="right"
        >
          Avançar
        </Button>
        {type === 'edit' ? (
          <Button
            disabled={
              buttonLoadingStatus.saveAndEditLater || buttonLoadingStatus.next
            }
            loading={buttonLoadingStatus.finish}
            color="primary"
            isHidden={selected === options[options.length - 1].key}
            onClick={() => handleSave('finish')}
          >
            Salvar
          </Button>
        ) : (
          <Button
            disabled={buttonLoadingStatus.finish || buttonLoadingStatus.next}
            loading={buttonLoadingStatus.saveAndEditLater}
            color="primary"
            isHidden={selected === options[options.length - 1].key}
            onClick={() => handleSave('saveAndEditLater')}
          >
            Salvar e continuar depois
          </Button>
        )}
        <Button
          disabled={
            buttonLoadingStatus.next || buttonLoadingStatus.saveAndEditLater
          }
          loading={buttonLoadingStatus.finish}
          isHidden={selected !== options[options.length - 1].key}
          onClick={() => handleSave('finish')}
          color="primary"
        >
          {type === 'edit' ? 'Salvar' : 'Concluir'}
        </Button>
      </ButtonsContainer>
    </DashboardTemplate>
  );
};

export default Edit;
