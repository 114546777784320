import { api } from '../api';
import { Notification } from '../../entities/Notification';

interface ListProps {
  limit: number;
  page: number;
}

interface GetNotificationsProps {
  list: Notification[];
  totalPages: number;
}

export const getNotifications = async ({ limit, page }: ListProps) => {
  const { data } = await api.get<GetNotificationsProps>('/notifications', {
    params: {
      limit,
      page,
    },
  });

  return data;
};
