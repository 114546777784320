import React, {
  useRef,
  useImperativeHandle,
  useState,
  forwardRef,
  useEffect,
} from 'react';
import { HiOutlinePlus } from 'react-icons/hi';
import { FiTrash } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import getValidationErrors from '../../../../../utils/getValidationErrors';
import { EmergencyContact } from '../../../../../entities/EmergencyContact';

import InputWithoutTitle from '../../../../../components/InputWithoutTitle';
import InputMaskAlternative from '../../../../../components/InputMaskAlternative';

import { Field } from '../styles';

import { Content, ListCard, GridForm, ButtonAddContact } from './styles';

interface SleepInformationProps {
  emergencyContacts?: EmergencyContact[];
}
export interface EmergencyContactsRef {
  getValues(): EmergencyContact[];
}

const EmergencyContacts: React.ForwardRefRenderFunction<
  EmergencyContactsRef,
  SleepInformationProps
> = ({ emergencyContacts }, ref) => {
  const formRef = useRef<FormHandles>(null);

  const [contacts, setContacts] = useState<EmergencyContact[]>([]);

  useEffect(() => {
    if (emergencyContacts && emergencyContacts.length > 0) {
      setContacts(emergencyContacts);
    }
  }, [emergencyContacts]);

  const handleSubmit = async (data: EmergencyContact) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        phone: Yup.string()
          .length(16, 'Digite um número de telefone válido')
          .required('O telefone é obrigatório')
          .test(
            'check-duplicate-phone',
            'Este número de telefone já está registrado como contato de emergência',
            (value: string) => {
              const checkDuplicatePhone = contacts.find(
                (contact) => contact.phone === value,
              );

              return !checkDuplicatePhone;
            },
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setContacts((state) => [...state, data]);

      formRef.current?.setData({
        name: '',
        phone: '',
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }
    }
  };

  const handleRemoveContact = async (phone?: string) => {
    setContacts((state) => state.filter((item) => item.phone !== phone));
  };

  const getValues = () => {
    return contacts;
  };

  useImperativeHandle(ref, () => ({
    getValues,
  }));

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <h1>Contatos de emergência</h1>

        <GridForm>
          <Field>
            <label>Nome do contato</label>
            <InputWithoutTitle name="name" disabled={contacts.length >= 3} />
          </Field>

          <Field>
            <label>Telefone (DDD + Número)</label>
            <InputMaskAlternative
              type="text"
              name="phone"
              title=""
              mask="(99) 9 9999-9999"
              placeholder="(00) 0 0000-0000"
              disabled={contacts.length >= 3}
            />
          </Field>

          <ButtonAddContact
            buttonType="primary"
            type="submit"
            disabled={contacts.length >= 3}
          >
            <HiOutlinePlus />
            Adicionar contato
          </ButtonAddContact>
        </GridForm>
      </Form>

      {contacts.length > 0 && (
        <Content>
          <strong>Contatos adicionados</strong>

          <div>
            {contacts.map((contact) => (
              <ListCard key={contact.phone}>
                <div>
                  <strong>{contact.name}</strong>
                  <span>{contact.phone}</span>
                </div>

                <button
                  type="button"
                  onClick={() => handleRemoveContact(contact.phone)}
                >
                  <FiTrash size={20} />
                </button>
              </ListCard>
            ))}
          </div>
        </Content>
      )}
    </>
  );
};

export default forwardRef(EmergencyContacts);
