import React, { useCallback, useState } from 'react';
import { useModal } from 'react-brave-modal';

import { ModalProps } from '../..';
import { useAppointment } from '../../../../hooks/appointment';

import { ReactComponent as MessageIcon } from '../../../../assets/images/icon_schedule_message.svg';
import { ReactComponent as SMSMessageIcon } from '../../../../assets/images/sms-message.svg';
import { ReactComponent as WhatsappIcon } from '../../../../assets/images/menuSuporte.svg';
import CloudPhonecallMan from '../../../../assets/images/cloud-phonecall-man.png';

import { ButtonsContainer, Button } from '../../styles';
import { Container, Grouper, Cards, OptionCard } from './styles';

const MessageType: React.FC<ModalProps> = ({ back, next }) => {
  const { closeModal } = useModal();
  const { saveLocal, appointmentSelected } = useAppointment();
  const [selected, setSelected] = useState<'sms' | 'whatsapp'>();

  const handleBack = useCallback(() => {
    if (back) {
      back();
    }
  }, [back]);

  const handleNext = useCallback(() => {
    if (next && selected) {
      saveLocal({
        message: { ...appointmentSelected?.message, type: 'sms' },
      });
      next();
    }
  }, [appointmentSelected, selected, saveLocal, next]);

  return (
    <>
      <Container>
        <Grouper>
          <h1>
            Enviar mensagem <MessageIcon />
          </h1>
          <p>Você gostaria de enviar a mensagem via SMS ou Whatsapp?</p>

          <Cards>
            <OptionCard
              isSelected={selected === 'sms'}
              onClick={() => setSelected('sms')}
            >
              <SMSMessageIcon />
              <span>SMS</span>
            </OptionCard>
            <OptionCard
              isSelected={selected === 'whatsapp'}
              // onClick={() => setSelected('whatsapp')}
            >
              <WhatsappIcon />
              <span>Whatsapp</span>
            </OptionCard>
          </Cards>
        </Grouper>

        <Grouper>
          <img src={CloudPhonecallMan} alt="" />
        </Grouper>
      </Container>

      <ButtonsContainer>
        <Button color="white" onClick={() => closeModal()}>
          Cancelar
        </Button>

        <Button color="primary" lite onClick={handleBack}>
          Voltar
        </Button>

        <Button color="primary" isDisabled={!selected} onClick={handleNext}>
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default MessageType;
