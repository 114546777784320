import { apiTwilioRecordings } from '../apiTwilioRecordings';

export const getParticipantsNamesFromAppointment = async (
  appointmentId: string,
): Promise<{
  patientName: string;
  professionalName: string;
}> => {
  const {
    data: { participants },
  } = await apiTwilioRecordings.get(
    `/recordings/participants/${appointmentId}`,
  );

  return participants;
};
