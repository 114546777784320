import styled from 'styled-components';

interface ContainerProps {
  items: number;
  disabledScroll: boolean;
}

export const Container = styled.div<ContainerProps>`
  margin: 0 43px;
  padding: 0 12px;
  height: 520px;
  display: flex;
  align-items: center;
  justify-content: ${({ items }) => (items ? 'initial' : 'center')};
  position: relative;
  z-index: 1;

  overflow-x: ${({ items, disabledScroll }) =>
    items && items > 4 ? (disabledScroll ? 'hidden' : 'auto') : 'hidden'};

  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
`;

export const Line = styled.div<{ items: number }>`
  width: ${({ items }) =>
    items && items < 5 ? '1100px' : `${items * 200 + 200}px`};
  height: 0px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

export const SectionAppointmentsPerYear = styled.ul`
  display: flex;
  flex-direction: row;
  position: relative;
  list-style: none;

  > h3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-right: 6px;

    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.grayTertiary};
  }

  .timeline-year {
    position: absolute;
    top: -90px;
    padding: 0;
    left: 30px;
    list-style: none;
  }
`;

export const SectionAppointmentsPerMonth = styled.ul`
  display: flex;
  flex-direction: row;
  position: relative;
  list-style: none;

  > h3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-right: 6px;

    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.grayTertiary};
  }
  .timeline-month {
    position: absolute;
    top: -90px;
    left: -10px;
    padding: 0;
    list-style: none;
  }
`;
