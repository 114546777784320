/* eslint-disable no-unreachable */
import React, { createContext, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { isValid } from 'date-fns';
import { useAuth } from './auth';
import { User as UserBase } from '../entities/User';
import { UserFiltersDTO } from '../dtos/UserFiltersDTO';
import {
  CreateAndUpdateProfessionalScheduleDTO,
  ProfessionalScheduleAvailabilitiesData,
} from '../dtos/CreateProfessionalScheduleDTO';
import {
  createUser,
  createUserStatus,
  updateUser,
  getAvatarImage as getUserAvatarImage,
  getUsersList,
  getUserById,
} from '../services/user';
import {
  createProfessionalSchedule as createProfessionalScheduleService,
  updateProfessionalSchedule as updateProfessionalScheduleService,
} from '../services/professionalSchedule';
import CreateUserStatusDTO from '../dtos/CreateUserStatusDTO';
import transformDateToBack from '../utils/transformDateToBack';

interface User extends UserBase {
  draft?: UserSensisitiveData;
}
interface UserListResponseInterface {
  list: UserBase[];
  totalUsers: number;
  totalPages: number;
}
interface UserContextData {
  user?: User;
  getUsers(
    page: number,
    filters?: UserFiltersDTO,
    filtersDifferents?: UserFiltersDTO,
    orderBy?: string,
    ordenationType?: 'ASC' | 'DESC',
  ): Promise<UserListResponseInterface>;
  previousSensitiveData: UserSensisitiveData;
  sync(id?: string): Promise<void>;
  saveLocal(userData: UserBase): void;
  save(userData: UserBase): Promise<boolean>;
  createStatus(data: CreateUserStatusDTO): Promise<boolean>;
  cleanLocal(): void;
}

export type UserSensisitiveData = Pick<UserBase, 'username' | 'email'> & {
  profileType?: string;
};

const UserContext = createContext<UserContextData>({} as UserContextData);

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [previousSensitiveData, setPreviousSensitiveData] =
    useState<UserSensisitiveData>({});

  const { user: userAuth, sync: syncAuth } = useAuth();

  const getUsers = async (
    page: number,
    filters?: UserFiltersDTO,
    filtersDifferents?: UserFiltersDTO,
    orderBy?: string,
    ordenationType?: 'ASC' | 'DESC',
  ): Promise<UserListResponseInterface> => {
    try {
      const data = await getUsersList({
        page,
        filters,
        filtersDifferents,
        orderBy,
        ordenationType,
      });
      return data as UserListResponseInterface;
    } catch (err) {
      toast.error(
        'Aconteceu um problema. Entre em contato com o administrador.',
      );
      return {
        list: [],
        totalPages: 0,
        totalUsers: 0,
      };
    }
  };

  const createStatus = useCallback(async (status: CreateUserStatusDTO) => {
    const newUser = await createUserStatus(status);

    setUser(newUser);

    return !!newUser;
  }, []);

  const createProfessionalSchedule = useCallback(
    async (data: CreateAndUpdateProfessionalScheduleDTO) => {
      await createProfessionalScheduleService(data);
    },
    [],
  );

  const updateProfessionalSchedule = useCallback(
    async (data: CreateAndUpdateProfessionalScheduleDTO) => {
      await updateProfessionalScheduleService(data);
    },
    [],
  );

  const getAvatarImage = useCallback(
    async (avatar: string) =>
      !avatar.includes('data:image')
        ? getUserAvatarImage(avatar)
        : user?.avatar,
    [user],
  );

  const sync = useCallback(
    async (id?: string) => {
      let userData: User | undefined;

      if (id) {
        userData = await getUserById(id);
      } else if (user && user.id) {
        userData = await getUserById(user.id);
      }

      if (userData && userData.avatar) {
        userData.avatar = await getAvatarImage(userData.avatar);
      }

      if (userData?.id === userAuth?.id) {
        syncAuth();
      }

      setUser(userData);
      setPreviousSensitiveData({
        email: userData?.email,
        profileType: userData?.profile?.type,
        username: userData?.username,
      });
    },
    [user, userAuth, syncAuth, getAvatarImage],
  );

  const saveLocal = useCallback((userData: UserBase) => {
    setUser((state) => ({ ...state, ...userData }));
  }, []);

  const save = useCallback(
    async (userData: UserBase) => {
      if (!user) {
        return false;
      }

      let newUser = { ...user, ...userData };

      const professionalSchedule = newUser?.professional?.professionalSchedule;

      const professionalScheduleAvailabilities: ProfessionalScheduleAvailabilitiesData[] =
        professionalSchedule?.professionalScheduleAvailabilities?.map(
          (schedule) => {
            const beginningField =
              schedule.startTime
                ?.toString()
                ?.split(':')
                .map((value: any) => Number(value)) ?? [];

            const endField =
              schedule.endTime
                ?.toString()
                .split(':')
                .map((value: any) => Number(value)) ?? [];

            const startTime = new Date(
              0,
              0,
              0,
              beginningField[0],
              beginningField[1],
            );

            const endTime = new Date(0, 0, 0, endField[0], endField[1]);

            return {
              duration: schedule.duration,
              day: schedule.day,
              startTime: transformDateToBack(startTime),
              endTime: transformDateToBack(endTime),
              dailyScheduleIntervals: schedule.dailyScheduleIntervals
                ?.map((scheduleInterval) => {
                  const beginningFieldInterval =
                    scheduleInterval.startInterval
                      ?.toString()
                      ?.split(':')
                      .map((value: any) => Number(value)) ?? [];

                  const endFieldInterval =
                    scheduleInterval.endInterval
                      ?.toString()
                      ?.split(':')
                      .map((value: any) => Number(value)) ?? [];

                  const startInterval = new Date(
                    0,
                    0,
                    0,
                    beginningFieldInterval[0],
                    beginningFieldInterval[1],
                  );

                  const endInterval = new Date(
                    0,
                    0,
                    0,
                    endFieldInterval[0],
                    endFieldInterval[1],
                  );

                  return {
                    startInterval: transformDateToBack(startInterval),
                    endInterval: transformDateToBack(endInterval),
                  };
                })
                .filter(
                  (scheduleInterval) =>
                    isValid(new Date(scheduleInterval.startInterval)) &&
                    isValid(new Date(scheduleInterval.endInterval)),
                ),
            } as ProfessionalScheduleAvailabilitiesData;
          },
        ) ?? [];

      if (professionalSchedule?.dayType) {
        professionalScheduleAvailabilities[0].day =
          professionalSchedule?.dayType;
      }

      if (!user?.id) {
        newUser = await createUser(newUser, userAuth?.id ?? 'ADMIN');

        if (newUser && newUser?.type === 'professional') {
          await createProfessionalSchedule({
            createdUpdatedBy: userAuth?.id ?? 'ADMIN',
            professionalId: newUser?.professional_id,
            professionalScheduleAvailabilitiesData:
              professionalScheduleAvailabilities,
            scheduleCustomType: professionalSchedule?.differentsTimes ?? false,
          });
        }
      } else {
        newUser = await updateUser(newUser);

        if (newUser && newUser?.type === 'professional') {
          await updateProfessionalSchedule({
            createdUpdatedBy: userAuth?.id ?? 'ADMIN',
            professionalId: newUser?.professional_id,
            professionalScheduleAvailabilitiesData:
              professionalScheduleAvailabilities,
            scheduleCustomType: professionalSchedule?.differentsTimes ?? false,
          });
        }
      }

      if (newUser.id === userAuth?.id) {
        syncAuth();
      }

      setUser(newUser);

      return true;
    },
    [
      user,
      userAuth,
      createProfessionalSchedule,
      updateProfessionalSchedule,
      syncAuth,
    ],
  );

  const cleanLocal = useCallback(() => {
    setUser(undefined);
    setPreviousSensitiveData({});
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        getUsers,
        sync,
        saveLocal,
        save,
        cleanLocal,
        createStatus,
        previousSensitiveData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextData => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};
