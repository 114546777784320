import React, { useCallback, useMemo } from 'react';
import { useModal } from 'react-brave-modal';

import { GridCardProps } from '../../../../components/GridCard';
import AddButton from '../../../../components/AddButton';
import { CardTitle } from '../../../../components/GridCard/styles';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';
import { usePatient } from '../../../../hooks/patient';
import CloudFileGrayImage from '../../../../assets/images/cloud-file-gray.svg';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/images/trash.svg';

import {
  Container,
  CardHeader,
  CardContent,
  Row,
  HealthConditionContainer,
  Buttons,
  SeverityLabel,
} from './styles';
import { usePermission } from '../../../../hooks/permission';
import AddEditHealthConditionModal from '../../../../modals/AddEditHealthConditionModal';
import RemoveHealthConditionModal from '../../../../modals/RemoveHealthConditionModal';
import Icd10Icon from '../../../../components/Icd10Icon';

type HealthConditionsProps = GridCardProps;

const HealthConditions: React.FC<HealthConditionsProps> = ({ gridArea }) => {
  const { hasPermission } = usePermission();
  const { patient } = usePatient();
  const { showModal } = useModal();
  const canEditPatient = useMemo(
    () =>
      hasPermission('edit_patient') &&
      (patient?.user?.currentStatus?.status === 'active' ||
        patient?.user?.currentStatus?.status === 'draft'),
    [hasPermission, patient],
  );
  const selectColor = useMemo(
    () => ({
      G: '#EA7676',
      M: '#F2B94A',
      B: '#19CAC0',
    }),
    [],
  );
  const ScoredSeverity = useMemo(() => ({ G: 3, M: 2, B: 1 }), []);

  const handleCreate = useCallback(() => {
    showModal({
      type: 'custom',
      data: <AddEditHealthConditionModal action="add" />,
    });
  }, [showModal]);

  const handleRemove = useCallback(
    (id: string) => {
      showModal({
        type: 'custom',
        data: <RemoveHealthConditionModal icd10PatientId={id} />,
      });
    },
    [showModal],
  );

  const handleEdit = useCallback(
    (id: string) => {
      showModal({
        type: 'custom',
        data: <AddEditHealthConditionModal icd10PatientId={id} action="edit" />,
      });
    },
    [showModal],
  );

  const activeHealthCondition = useMemo(
    () =>
      patient?.healthConditions?.filter(({ status }) => status === 'active'),
    [patient],
  );

  return (
    <Container gridArea={gridArea}>
      <CardHeader>
        <CardTitle>Condição de Saúde</CardTitle>
        <AddButton onClick={handleCreate} disabled={!canEditPatient}>
          Nova
        </AddButton>
      </CardHeader>
      <CardContent>
        {activeHealthCondition?.length ? (
          <>
            <span>Gravidade</span>
            {activeHealthCondition
              ?.sort(
                (firstItem, secondItem) =>
                  ScoredSeverity[secondItem.severity] -
                  ScoredSeverity[firstItem.severity],
              )
              .map(({ id, icd10, severity }) => (
                <Row key={id}>
                  <HealthConditionContainer>
                    <Icd10Icon condition={icd10.chapter} />
                    <span>{`${icd10.icd} - ${icd10.description}`}</span>
                  </HealthConditionContainer>
                  <Buttons>
                    {activeHealthCondition.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemove(id)}
                        disabled={!canEditPatient}
                      >
                        <TrashIcon />
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => handleEdit(id)}
                      disabled={!canEditPatient}
                    >
                      <EditIcon />
                    </button>
                  </Buttons>
                  <SeverityLabel backgroundColor={selectColor[severity]}>
                    {severity}
                  </SeverityLabel>
                </Row>
              ))}
          </>
        ) : (
          <CardContentNothingToDisplay imageWidth="160px">
            <img src={CloudFileGrayImage} alt="" />
            Sem informações cadastradas
          </CardContentNothingToDisplay>
        )}
      </CardContent>
    </Container>
  );
};

export default HealthConditions;
