import styled from 'styled-components';
import { Container as GridCardContainer } from '../../../../components/GridCard/styles';

export const Container = styled(GridCardContainer)`
  > header {
    padding: 30px 25px 15px;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.borderGray};

    > h1 {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #4f4f4f;
    }
  }

  > div {
    padding: 10px 20px;
    height: 100%;
    overflow-y: auto;
  }
`;

export const YearTitle = styled.h1`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #bdbdbd;
  padding-top: 5px;
`;

export const MonthTitle = styled.h2`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #7a7786;
  padding: 10px 0 15px;
  text-transform: capitalize;
`;

export const AppointmentSection = styled.div`
  display: grid;
  width: 100%;
  grid-template: repeat(6, 1fr) / repeat(6, 1fr);
  grid-template-areas:
    'dt in in in in in'
    'dt in in in in in'
    'ln in in in in in'
    'ln in in in in in'
    'ln in in in in in'
    'ln in in in in in';
  align-items: flex-start;
  justify-items: center;
`;

export const DateAppointmentSection = styled.div`
  grid-area: dt;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2d9cdb;
  border-radius: 50%;
  box-sizing: border-box;
  width: 41px;
  height: 41px;

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2d9cdb;
`;

export const InformationsAppointmentSection = styled.button`
  background: transparent;
  border: 0;
  grid-area: in;
  padding-left: 10px;
  width: 100%;
  text-align: left;

  > h2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #707683;
  }

  > h3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #707683;
    padding-top: 7px;
  }

  > h4 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #707683;
    padding: 7px 0 20px;
  }

  > h5 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #bdbdbd;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export const LineAppointmentSection = styled.hr`
  grid-area: ln;
  height: 100%;
  border: 0.5px dashed #707683;
  width: 1px;
  height: 100%;
`;
