import styled from 'styled-components';

type MarkerProps = {
  position: 'bottom' | 'top';
};

export const TimelineDay = styled.li<{ position: 'top' | 'bottom' }>`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  list-style: none;

  &::after {
    content: '';
    width: 12px;
    height: 0px;
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
    transform: rotate(90deg);
    display: block;
    position: absolute;
    top: ${(props) => (props.position === 'top' ? '7px' : '-7px')};
    left: -7px;
  }
`;

export const DetailsAppointmentWrapper = styled.div<MarkerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;

  top: ${({ position }) => (position === 'top' ? '40px' : '-50px')};
  left: -20px;

  > h3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.grayTertiary};
  }
  > div {
    display: inline-flex;
    gap: 5px;
    margin-bottom: 10px;
  }
`;

export const Year = styled.h3``;

export const Month = styled.h3`
  text-transform: capitalize;
`;

export const Day = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  border-radius: 50%;

  background: ${({ selected, theme }) =>
    selected === true ? theme.colors.primary : theme.colors.white};
  border: 1px solid
    ${({ selected, theme }) =>
      selected === true ? theme.colors.white : theme.colors.primary};
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;

  color: ${({ selected, theme }) =>
    selected === true ? theme.colors.white : theme.colors.primary};
  transition: all 0.3s ease;
`;

export const TypeAppointment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    color: ${({ theme }) => theme.colors.grayTertiary};
  }

  > time span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    color: ${({ theme }) => theme.colors.grayTertiary};
  }
`;
