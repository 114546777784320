import { format } from 'date-fns';

interface IntervalProps {
  startInterval?: any;
  endInterval?: any;
}

const getSchedulesForAppointments = (
  startTime?: any,
  endTime?: any,
  duration?: any,
  interval: IntervalProps[] = [],
): string[] => {
  if (!startTime || !endTime || !duration) {
    return [];
  }

  let allTimesInDay: number[] = [];

  const [startHours, startMinutes] = startTime?.split(':');
  const [endHours, endMinutes] = endTime?.split(':');

  const startDate = new Date();
  startDate.setHours(parseInt(startHours, 10));
  startDate.setMinutes(parseInt(startMinutes, 10));
  startDate.setSeconds(0);

  const endDate = new Date();
  endDate.setHours(parseInt(endHours, 10));
  endDate.setMinutes(parseInt(endMinutes, 10));
  endDate.setSeconds(0);

  while (startDate.getTime() <= endDate.getTime()) {
    allTimesInDay.push(startDate.getTime());
    startDate.setMinutes(startDate.getMinutes() + duration);
  }

  if (interval.length > 0) {
    interval.forEach((intervalMap) => {
      if (intervalMap.startInterval && intervalMap.endInterval) {
        const [
          startIntervalHours,
          startIntervalMinutes,
        ] = intervalMap.startInterval.split(':');

        const [
          endIntervalHours,
          endIntervalMinutes,
        ] = intervalMap.endInterval.split(':');

        const startInterval = new Date();
        startInterval.setHours(parseInt(startIntervalHours, 10));
        startInterval.setMinutes(parseInt(startIntervalMinutes, 10));
        startInterval.setSeconds(0);

        const endInterval = new Date();
        endInterval.setHours(parseInt(endIntervalHours, 10));
        endInterval.setMinutes(parseInt(endIntervalMinutes, 10));
        endInterval.setSeconds(0);

        allTimesInDay = allTimesInDay.filter(
          (time) =>
            time < startInterval.getTime() || time >= endInterval.getTime(),
        );
      }
    });
  }

  return allTimesInDay.map((time) => {
    return format(new Date(time), 'HH:mm');
  });
};

export default getSchedulesForAppointments;
