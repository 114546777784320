import React, { useCallback } from 'react';

import { useAuth } from '../../../../hooks/auth';
import Check from '../../../../assets/images/check-ok-2.svg';

import { Container, StartButton } from './styles';

interface FinishRedefinitionProps {
  userType: string;
}

const FinishRedefinition: React.FC<FinishRedefinitionProps> = ({
  userType,
}) => {
  const { signOut } = useAuth();

  const handleClick = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <Container>
      <img src={Check} alt="" />
      <h1>Senha redefinida!</h1>
      <h2>
        Sua nova senha já foi cadastrada. Faça login
        {userType === 'patient' && ' no aplicativo'} para acessar a sua conta.
      </h2>
      {userType !== 'patient' && (
        <StartButton onClick={handleClick}>Iniciar</StartButton>
      )}
    </Container>
  );
};

export default FinishRedefinition;
