import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useField } from '@unform/core';

import ReactInputMask from 'react-input-mask';
import { Container, Content, ChangeButton } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: any;
  textColor?: string;
  textSize?: string;
  withChangeButton?: boolean;
  mask?: string;
}

const InputIcon: React.FC<InputProps> = ({
  name,
  icon,
  textColor = '#707683',
  textSize = '16px',
  withChangeButton = false,
  mask,
  ...rest
}) => {
  const [disabled, setDisabled] = useState(withChangeButton);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled] = useState(false);

  const [inputMaskRef, setInputMaskRef] = useState<HTMLInputElement | null>(
    null,
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const inputCurrentRef = useMemo(() => {
    if (mask) {
      return { current: inputMaskRef };
    }

    return inputRef;
  }, [inputMaskRef, mask]);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    if (!disabled) {
      if (mask) {
        inputMaskRef?.focus();
      } else {
        inputRef.current?.focus();
      }
    }
  }, [disabled, inputMaskRef, mask]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleEditInputValue = useCallback(() => {
    setDisabled((state) => !state);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputCurrentRef,
      getValue: (ref: any) => inputCurrentRef?.current?.value ?? '',
      setValue: (ref: any, value: string) => {
        if (inputCurrentRef?.current) {
          inputCurrentRef.current.value = value;
        }
      },
    });
  }, [fieldName, inputCurrentRef, inputMaskRef, registerField]);

  return (
    <Container>
      <Content
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        textColor={textColor}
        textSize={textSize}
        disabled={disabled}
      >
        {icon && icon}
        {mask ? (
          <ReactInputMask
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore:next-line
            maskChar={null}
            mask={mask}
            inputRef={(element) => {
              setInputMaskRef(element);
            }}
            disabled={disabled}
            {...rest}
          />
        ) : (
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={inputRef}
            disabled={disabled}
            {...rest}
          />
        )}
      </Content>
      <ChangeButton
        type="button"
        isInvisible={!withChangeButton}
        onClick={handleEditInputValue}
      >
        Alterar
      </ChangeButton>
    </Container>
  );
};

export default InputIcon;
