import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { Container } from './styles';

const ButtonWhatsApp: React.FC = () => {
  const whatsappUrl =
    'https://api.whatsapp.com/send?phone=5581997751361&text=Tenho%20uma%20duvida&source=&data=&app_absent=';
  return (
    <Container>
      <a href={whatsappUrl} rel="noopener noreferrer" target="_blank">
        <FaWhatsapp /> Falar com o suporte
      </a>
    </Container>
  );
};

export default ButtonWhatsApp;
