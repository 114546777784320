import React, { useMemo, useCallback } from 'react';
import Chart from 'react-google-charts';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';
import CloudFileGrayImage from '../../../../assets/images/cloud-file-gray.svg';

import { CardHeader, CardTitle } from '../styles';
import {
  Container,
  Content,
  Dot,
  LegendContainer,
  Legend,
  TotalLegend,
  ChartContainer,
} from './styles';
import RiskDegreeEnum from '../../../../enums/RiskDegreeEnum';

export interface PatientByRiskDegree {
  degree?: RiskDegreeEnum;
  patients: number;
  percentage: string;
}
interface PatientsRiskDegreeProps {
  data: {
    patientsByRiskDegree: PatientByRiskDegree[];
    totalPatients: number;
  };
}

const PatientsRiskDegree: React.FC<PatientsRiskDegreeProps> = ({ data }) => {
  const handleGetRiskDegreeText = useCallback(
    (degree?: RiskDegreeEnum) =>
      degree
        ? {
            [RiskDegreeEnum.SAFE]: 'Grau de Risco I',
            [RiskDegreeEnum.LOW]: 'Grau de Risco II',
            [RiskDegreeEnum.MEDIUM]: 'Grau de Risco III',
            [RiskDegreeEnum.HIGH]: 'Grau de Risco IV',
          }[degree]
        : 'Não informado',
    [],
  );

  const handleGetColors = useCallback(
    (degree?: RiskDegreeEnum) =>
      degree
        ? {
            [RiskDegreeEnum.SAFE]: '#147AD6',
            [RiskDegreeEnum.LOW]: '#2EBEE0',
            [RiskDegreeEnum.MEDIUM]: '#FDBA7F',
            [RiskDegreeEnum.HIGH]: '#EA6782',
          }[degree]
        : '#BDBDBD',
    [],
  );

  const displayableData = useMemo(
    () =>
      data.patientsByRiskDegree.map<[string, number]>((info) => [
        handleGetRiskDegreeText(info.degree),
        info.patients,
      ]),
    [data, handleGetRiskDegreeText],
  );

  const colors = useMemo(
    () =>
      data.patientsByRiskDegree.map(({ degree }) => handleGetColors(degree)),
    [data.patientsByRiskDegree, handleGetColors],
  );

  return (
    <Container gridArea="pnw">
      <CardHeader>
        <CardTitle>Grau de Risco</CardTitle>
      </CardHeader>
      {data ? (
        <Content>
          <LegendContainer>
            {data.patientsByRiskDegree.map(({ degree, percentage }) => (
              <Legend key={degree}>
                <div>
                  <Dot color={handleGetColors(degree)} />
                  <strong>{`${percentage}%`}</strong>
                </div>
                <span>{`${handleGetRiskDegreeText(degree)}`}</span>
              </Legend>
            ))}
          </LegendContainer>
          <ChartContainer>
            <Chart
              chartType="PieChart"
              data={[
                ['Grau de Risco', 'Número de Pacientes'],
                ...displayableData,
              ]}
              options={{
                colors,
                pieHole: 0.9,
                is3D: false,
                font: 'Raleway',
                legend: {
                  position: 'top',
                  textStyle: { color: 'blue', fontSize: 16 },
                },
                pieSliceText: 'none',
                chartArea: { width: '90%', height: '90%' },
              }}
            />
            <TotalLegend>
              <h2>{data.totalPatients}</h2>
              <h4>TOTAL</h4>
            </TotalLegend>
          </ChartContainer>
        </Content>
      ) : (
        <CardContentNothingToDisplay imageWidth="140px">
          <img src={CloudFileGrayImage} alt="" />
          Sem informações cadastradas
        </CardContentNothingToDisplay>
      )}
    </Container>
  );
};

export default PatientsRiskDegree;
