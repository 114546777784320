import React, { useCallback } from 'react';

import Wrong from '../../../../assets/images/wrong.svg';

import { Container, TryAgainButton } from './styles';

interface WrongEmailProps {
  handleStepChange: (key: string) => void;
}

const WrongEmail: React.FC<WrongEmailProps> = ({ handleStepChange }) => {
  const handleTryAgain = useCallback(() => {
    handleStepChange('EmailConfirmation');
  }, [handleStepChange]);

  return (
    <Container>
      <img src={Wrong} alt="" />
      <h1>Ooops!</h1>
      <h2>
        O e-mail informado não está cadastrado no sistema. Tem certeza que
        informou o e-mail correto?
      </h2>
      <TryAgainButton onClick={handleTryAgain}>Tentar novamente</TryAgainButton>
    </Container>
  );
};

export default WrongEmail;
