/* eslint-disable react/jsx-indent */
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RiEmotionSadLine } from 'react-icons/ri';
import { AiOutlineReload } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { useNotification } from '../../../hooks/notification';
import { Notification as NotificationProps } from '../../../entities/Notification';
import { useCurrentCallback } from '../../../lib/use-current-effect';
import {
  getNotifications,
  updateNotificationsAsRead,
} from '../../../services/notifications';

import { ElementPageTree } from '../../../components/PageTree/types';
import PageTree from '../../../components/PageTree';
import Spinner from '../../../components/Spinner';
import SmallSpinner from '../../../components/SmallSpinner';
import Button from '../../../components/Button';
import NotificationItem from '../../../components/NotificationItem';
import ActivePushNotifications from './ActivePushNotifications';
import DashboardTemplate from '../../../templates/Dashboard';

import {
  Container,
  Card,
  CardHeader,
  LoadingData,
  NotificationsList,
  ThereAreNoNotifications,
  ButtonReload,
} from './styles';

interface PaginationProps {
  page: number;
  totalPages?: number;
}

interface NotificationType extends NotificationProps {
  date: Date;
}

type NotificationsListType = {
  unread: NotificationType[];
  read: NotificationType[];
};

const Notification: React.FC = () => {
  const history = useHistory();
  const { clearLocal, newNotificationsCount, clearNewNotificationsCount } =
    useNotification();

  const [pagesPrev] = useState<ElementPageTree[]>([{ name: 'Notificações' }]);
  const [notificationsList, setNotificationsList] =
    useState<NotificationsListType>();
  const [loadingMoreNotification, setLoadingMoreNotification] = useState(false);
  const [loadingMarkNotificationsAsRead, setLoadingMarkNotificationsAsRead] =
    useState(false);
  const [pagination, setPagination] = useState<PaginationProps>({
    page: 1,
  });

  const getNotificationsData = useCurrentCallback(
    (isCurrent) => async (page: number) => {
      if (isCurrent()) {
        setLoadingMoreNotification(true);
      }

      try {
        const { list, totalPages } = await getNotifications({
          page,
          limit: 10,
        });

        const read: NotificationType[] = [];
        const unread: NotificationType[] = [];

        if (list && list.length > 0) {
          list.forEach((notification) => {
            if (notification.read) {
              read.push({
                ...notification,
                date: new Date(notification.createdAt),
              });
            } else {
              unread.push({
                ...notification,
                date: new Date(notification.createdAt),
              });
            }
          });
        }

        if (isCurrent()) {
          setNotificationsList((state) => {
            if (state) {
              return {
                read: [...state.read, ...read],
                unread: [...state.unread, ...unread],
              };
            }
            return {
              read,
              unread,
            };
          });

          setPagination((state) => ({
            ...state,
            page,
            totalPages,
          }));

          setLoadingMoreNotification(false);
        }
      } catch {
        toast.error(
          'Tivemos um problema para carregar as suas notificações, tente novamente mais tarde.',
        );

        if (isCurrent()) {
          setLoadingMoreNotification(false);
        }
      }
    },
    [],
  );

  useEffect(() => {
    getNotificationsData(1);
    clearNewNotificationsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoaddingMore = async () => {
    if (pagination.page !== pagination.totalPages) {
      getNotificationsData(pagination.page + 1);
    }
  };

  const handleUpdateList = () => {
    clearNewNotificationsCount();
    setNotificationsList(undefined);
    getNotificationsData(1);
  };

  const handleMarkNotificationsAsRead = useCurrentCallback(
    (isCurrent) => async () => {
      if (isCurrent()) {
        setLoadingMarkNotificationsAsRead(true);
      }

      try {
        if (isCurrent()) {
          await updateNotificationsAsRead();

          setNotificationsList((state) => {
            if (state) {
              return {
                unread: [],
                read: [...state.unread, ...state.read],
              };
            }
            return undefined;
          });

          setLoadingMarkNotificationsAsRead(false);
          clearLocal();
        }
      } catch {
        toast.error(
          'Tivemos um problema para atualizar as suas notificações, tente novamente.',
        );

        if (isCurrent()) {
          setLoadingMarkNotificationsAsRead(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <DashboardTemplate name="">
      <PageTree pages={pagesPrev} />
      <Container>
        <Card>
          <CardHeader>
            <h1>Notificações</h1>

            {notificationsList && notificationsList.unread.length > 0 && (
              <button
                type="button"
                disabled={loadingMarkNotificationsAsRead}
                onClick={handleMarkNotificationsAsRead}
              >
                {loadingMarkNotificationsAsRead && <SmallSpinner />}
                <span>Marcar todas como lidas</span>
              </button>
            )}
          </CardHeader>

          {'Notification' in window && <ActivePushNotifications />}

          {!notificationsList ? (
            <LoadingData>
              <Spinner transparent />
            </LoadingData>
          ) : notificationsList.unread.length === 0 &&
            notificationsList.read.length === 0 ? (
            <ThereAreNoNotifications>
              <RiEmotionSadLine size={80} />
              <h1>Não há notificações</h1>
            </ThereAreNoNotifications>
          ) : (
            <NotificationsList>
              {notificationsList.unread.length > 0 && (
                <ul>
                  {notificationsList.unread.map(({ id, date, title, text }) => (
                    <NotificationItem
                      key={id}
                      date={date}
                      title={title}
                      message={text}
                      read={false}
                    />
                  ))}
                </ul>
              )}

              {notificationsList.read.length > 0 && (
                <>
                  {notificationsList.unread.length > 0 && (
                    <h3>Notificações antigas</h3>
                  )}

                  <ul>
                    {notificationsList.read.map(({ id, date, title, text }) => (
                      <NotificationItem
                        key={id}
                        date={date}
                        title={title}
                        message={text}
                        read
                      />
                    ))}
                  </ul>
                </>
              )}

              {pagination.page !== pagination.totalPages && (
                <Button
                  onClick={handleLoaddingMore}
                  buttonType="primary-alternative"
                  loading={loadingMoreNotification}
                >
                  Carregar mais
                </Button>
              )}
            </NotificationsList>
          )}

          {newNotificationsCount > 0 && (
            <ButtonReload onClick={handleUpdateList}>
              <AiOutlineReload size={15} />

              <strong>
                {newNotificationsCount === 1
                  ? 'Ver 1 nova notificação'
                  : `Ver ${newNotificationsCount} novas notificações`}
              </strong>
            </ButtonReload>
          )}
        </Card>

        <Button onClick={handleBack}>Voltar</Button>
      </Container>
    </DashboardTemplate>
  );
};

export default Notification;
