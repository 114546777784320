import styled from 'styled-components';

import { Content } from '../styles';

interface DetailsChartProps {
  color?: string;
}

export const Container = styled(Content)``;

export const DetailsChart = styled.div<DetailsChartProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 380px;
  border-radius: 22px;
  border: 1px solid ${({ theme }) => theme.colors.grayAlternative};
  padding: 40px 0;
  margin: 0 auto;

  h2 {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 50px;
    color: ${({ theme }) => theme.colors.gray3};
    padding: 0;
  }

  div {
    display: flex;

    img {
      width: 100px;
      margin-right: 15px;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      span {
        font-weight: 500;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.grayAlternative};
      }

      h1 {
        font-weight: bold;
        font-size: 40px;
        color: ${({ color, theme }) => color || theme.colors.primary};
      }
    }
  }
`;
