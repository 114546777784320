import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useModal } from 'react-brave-modal';
import { MdRemoveRedEye } from 'react-icons/md';

import InputCheckWithoutForm from '../../../../components/InputCheckWithoutForm';
import ActionMenuButtonList from '../../../../components/ActionMenuButtonList';
import ContentNothingToDisplay from '../../../../components/ContentNothingToDisplay';
import SheetViewModal from '../../../../modals/SheetViewModal';
import {
  GetSheetsByFilter as ListFilter,
  GetSheetsByFilterResponse as ListData,
} from '../../../../services/sheet';
import { ReactComponent as ArrowUpDownIcon } from '../../../../assets/images/arrowupdown.svg';

import {
  Container,
  TopBar,
  Actions,
  Info,
  SheetList,
  SheetListHeader,
  ViewButton,
  SortButton,
  ContentNothingToDisplayBox,
  SheetListHeaderCell,
  SheetListBody,
  SheetListCheckboxCell,
  SheetListHeaderAssignedSheetCell,
  SheetListBodyAssignedSheetCell,
  SheetListBodySheetNameCell,
  SheetListBodyButtonCell,
} from './styles';
import OrdenationTypeEnum from '../../../../enums/OrdenationTypeEnum';

interface SheetList {
  id: string;
  name: string;
  formId: string;
  answers: number;
  pendencies: number;
  isChecked: boolean;
}

interface TableProps {
  setListFilter(listFilter: React.SetStateAction<ListFilter>): void;
  listData: ListData;
}

enum SheetOrderByEnum {
  SHEET_NAME = 'sheetName',
  COMORBITIES = 'comorbities',
  SUBMISSIONS = 'submissions',
  PENDENCIES = 'pendencies',
}

const Table: React.FC<TableProps> = ({ setListFilter, listData }) => {
  const { showModal } = useModal();
  const [sheetList, setSheetList] = useState<SheetList[]>([]);
  const [orderBy, setOrderBy] = useState(SheetOrderByEnum.SHEET_NAME);
  const [isAscending, setIsAscending] = useState(true);

  const allItemsChecked = useMemo(
    () => sheetList.every((item) => item.isChecked),
    [sheetList],
  );

  useEffect(() => {
    setSheetList(
      listData.sheets.map((sheet) => ({
        id: sheet.id,
        name: sheet.name,
        formId: sheet.formId,
        answers: sheet.sheetSubmissions.length,
        pendencies: sheet.patients.filter(
          (sheetPatient) => sheetPatient.status === 'not_received',
        ).length,
        isChecked: false,
      })),
    );
  }, [listData]);

  const handleOrdenation = useCallback(
    (orderKey: SheetOrderByEnum, isInitialOrdering: boolean) => {
      if (isInitialOrdering) {
        setListFilter((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: OrdenationTypeEnum.ASC,
        }));
        setIsAscending(true);
      } else {
        setListFilter((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: isAscending
            ? OrdenationTypeEnum.DESC
            : OrdenationTypeEnum.ASC,
        }));
        setIsAscending((prevState) => !prevState);
      }

      setOrderBy(orderKey);
    },
    [isAscending, setListFilter],
  );

  const handleCheck = useCallback(
    (key: string, value: boolean) => {
      const newListDisplay = sheetList.map((item) => {
        if (item.id === key) {
          item.isChecked = value;
        }

        return item;
      });

      setSheetList([...newListDisplay]);
    },
    [sheetList],
  );

  const handleCheckAllInputs = useCallback((value: boolean) => {
    setSheetList((state) =>
      state.map((item) => {
        item.isChecked = value;
        return item;
      }),
    );
  }, []);

  const handleSheetView = useCallback(
    (formId: string, sheetName: string) => {
      showModal({
        type: 'custom',
        data: <SheetViewModal formId={formId} sheetName={sheetName} />,
      });
    },
    [showModal],
  );

  return (
    <>
      {sheetList.length ? (
        <Container>
          <TopBar>
            <Actions>
              <span>Ações</span>
              <ActionMenuButtonList
                disabled
                list={[
                  { name: 'Editar', disabled: true },
                  { name: 'Remover', disabled: true },
                ]}
              />
            </Actions>
            <Info>
              <span>
                Exibindo <strong>{listData.totalSheetsOnPage}</strong>{' '}
                {listData.totalSheetsOnPage === 1 ? 'resultado' : 'resultados'}
                de <strong>{listData.totalSheets}</strong>
              </span>
            </Info>
          </TopBar>
          <SheetList>
            <header>
              <SheetListHeader>
                <SheetListCheckboxCell>
                  <InputCheckWithoutForm
                    type="checkbox"
                    onChange={(event) =>
                      handleCheckAllInputs(event.target.checked)
                    }
                    checked={allItemsChecked}
                  />
                </SheetListCheckboxCell>
                <SheetListHeaderCell>
                  <span>
                    Nome da ficha
                    <SortButton
                      type="button"
                      isAscending={isAscending}
                      isActive={orderBy === SheetOrderByEnum.SHEET_NAME}
                      onClick={() =>
                        handleOrdenation(
                          SheetOrderByEnum.SHEET_NAME,
                          orderBy !== SheetOrderByEnum.SHEET_NAME,
                        )
                      }
                    >
                      <ArrowUpDownIcon />
                    </SortButton>
                  </span>
                </SheetListHeaderCell>
                <SheetListHeaderCell>
                  <span>
                    Comorbidades
                    <SortButton
                      type="button"
                      isAscending={isAscending}
                      isActive={orderBy === SheetOrderByEnum.COMORBITIES}
                      onClick={() =>
                        handleOrdenation(
                          SheetOrderByEnum.COMORBITIES,
                          orderBy !== SheetOrderByEnum.COMORBITIES,
                        )
                      }
                    >
                      <ArrowUpDownIcon />
                    </SortButton>
                  </span>
                </SheetListHeaderCell>
                <SheetListHeaderAssignedSheetCell>
                  <span>
                    Respostas
                    <SortButton
                      type="button"
                      isAscending={isAscending}
                      isActive={orderBy === SheetOrderByEnum.SUBMISSIONS}
                      onClick={() =>
                        handleOrdenation(
                          SheetOrderByEnum.SUBMISSIONS,
                          orderBy !== SheetOrderByEnum.SUBMISSIONS,
                        )
                      }
                    >
                      <ArrowUpDownIcon />
                    </SortButton>
                  </span>
                </SheetListHeaderAssignedSheetCell>
                <SheetListHeaderAssignedSheetCell>
                  <span>
                    Pendências
                    <SortButton
                      type="button"
                      isAscending={isAscending}
                      isActive={orderBy === SheetOrderByEnum.PENDENCIES}
                      onClick={() =>
                        handleOrdenation(
                          SheetOrderByEnum.PENDENCIES,
                          orderBy !== SheetOrderByEnum.PENDENCIES,
                        )
                      }
                    >
                      <ArrowUpDownIcon />
                    </SortButton>
                  </span>
                </SheetListHeaderAssignedSheetCell>
                <SheetListHeaderCell />
                <SheetListHeaderCell />
              </SheetListHeader>
            </header>
            <section>
              {sheetList.map((sheet) => (
                <SheetListBody key={sheet.id}>
                  <SheetListCheckboxCell>
                    <InputCheckWithoutForm
                      type="checkbox"
                      onChange={({ target: { checked } }) =>
                        handleCheck(sheet.id ?? '', checked)
                      }
                      checked={sheet.isChecked}
                    />{' '}
                  </SheetListCheckboxCell>
                  <SheetListBodySheetNameCell>
                    <span>{sheet.name}</span>
                  </SheetListBodySheetNameCell>
                  <SheetListBodyAssignedSheetCell hasAnswers={!!sheet.answers}>
                    <Link to={`/sheet/answers?id=${sheet.id}`}>
                      {sheet.answers}
                      <MdRemoveRedEye />
                    </Link>
                  </SheetListBodyAssignedSheetCell>
                  <SheetListBodyAssignedSheetCell
                    hasPending={!!sheet.pendencies}
                  >
                    <Link to={`/sheet/pendencies?id=${sheet.id}`}>
                      {sheet.pendencies}
                      <MdRemoveRedEye />
                    </Link>
                  </SheetListBodyAssignedSheetCell>
                  <SheetListBodyButtonCell>
                    <ViewButton
                      type="button"
                      onClick={() => handleSheetView(sheet.formId, sheet.name)}
                    >
                      <MdRemoveRedEye size={20} />
                      <span>Visualizar</span>
                    </ViewButton>
                  </SheetListBodyButtonCell>
                  {/* <SheetListBodyButtonCell>
                    <ActionMenuButtonItemList
                      alignBox="right"
                      list={[
                        { name: 'Editar', disabled: true },
                        { name: 'Remover', disabled: true },
                      ]}
                    />
                  </SheetListBodyButtonCell> */}
                </SheetListBody>
              ))}
            </section>
          </SheetList>
        </Container>
      ) : (
        <ContentNothingToDisplayBox>
          <ContentNothingToDisplay text="Nenhum dado encontrado" />
        </ContentNothingToDisplayBox>
      )}
    </>
  );
};

export default Table;
