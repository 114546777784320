import React, { useState, useCallback } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import InputFile from '../../../../components/InputFile';
import { usePatient } from '../../../../hooks/patient';
import {
  updateAvatarImage,
  deleteAvatarImage,
} from '../../../../services/user';
import compressImage from '../../../../utils/compressImage';
import { ReactComponent as TrashIcon } from '../../../../assets/images/trash.svg';

import {
  Container,
  ContentBox,
  DeleteBox,
  FileNameBox,
  DeleteButton,
  FetchBox,
  FetchButton,
} from './styles';
import { useCurrentCallback } from '../../../../lib/use-current-effect';

const Foto: React.FC = () => {
  const { patient, sync } = usePatient();
  const [fileName, setFileName] = useState<string>();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleSelectFile = useCallback(() => {
    document.getElementById('avatar')?.click();
  }, []);

  const handleAvatarUpload = useCurrentCallback(
    (isCurrent) => async (file: File) => {
      try {
        const base64AvatarImage = await compressImage(file);

        if (isCurrent()) {
          if (base64AvatarImage) {
            Promise.all([
              await updateAvatarImage(
                patient?.user?.id ?? '',
                base64AvatarImage,
              ),
              await sync(),
            ]);
            toast.success('Foto alterada com sucesso.');
          }

          if (isCurrent()) {
            setFileName(file.name);
          }
        }
      } catch (error) {
        toast.error(error);
      }
    },
    [patient, sync],
  );

  const handleAvatarDelete = useCurrentCallback(
    (isCurrent) => async () => {
      setIsButtonDisabled(true);

      try {
        Promise.all([
          await deleteAvatarImage(patient?.user?.id ?? ''),
          await sync(),
        ]);

        if (isCurrent()) {
          setIsButtonDisabled(false);
        }

        toast.success('Foto removida com sucesso.');
      } catch (error) {
        if (isCurrent()) {
          setIsButtonDisabled(false);
        }

        toast.error(error);
      }
    },
    [patient, sync],
  );

  return (
    <Container>
      <h1>Foto</h1>
      <p>Adicione uma foto ao perfil do usuário:</p>
      <ContentBox>
        <Form onSubmit={() => {}}>
          <InputFile
            id="avatar"
            name="avatar"
            image={patient?.user?.avatar}
            backgroundType="camera"
            onUpload={handleAvatarUpload}
          />
        </Form>
        {patient?.user?.avatar ? (
          <DeleteBox>
            {fileName && (
              <FileNameBox>
                <span>Imagem selecionada:</span>
                <span>{fileName}</span>
              </FileNameBox>
            )}
            <DeleteButton
              loading={isButtonDisabled}
              type="button"
              onClick={handleAvatarDelete}
            >
              <TrashIcon /> Remover foto
            </DeleteButton>
          </DeleteBox>
        ) : (
          <FetchBox>
            <FetchButton type="button" onClick={handleSelectFile}>
              Buscar Imagem
            </FetchButton>
            <span>*A imagem não deve conter mais de 2mb</span>
            <span>**Formatos aceitos: JPG ou PNG</span>
          </FetchBox>
        )}
      </ContentBox>
    </Container>
  );
};

export default Foto;
