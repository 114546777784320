import React, { useCallback, lazy, Suspense, useMemo } from 'react';
import { useModal } from 'react-brave-modal';

import { useAuth } from '../../../../../hooks/auth';
import { useAppointment } from '../../../../../hooks/appointment';
import { usePermission } from '../../../../../hooks/permission';

import Button from '../../../../../components/Button';
import AppointmentTypeEnum from '../../../../../enums/AppointmentTypeEnum';
import AppointmentAreaEnum from '../../../../../enums/AppointmentAreaEnum';

const RunAppointmentNewTabModal = lazy(
  () =>
    import(
      /* webpackChunkName: "RunAppointmentNewTabModal" */ '../../../../../modals/RunAppointmentNewTabModal'
    ),
);

interface AttendanceNowProps {
  patient: {
    id: string;
    name: string;
    userId: string;
    phone?: string;
  };
}

const AttendanceNow: React.FC<AttendanceNowProps> = ({ patient }) => {
  const { showModal } = useModal();
  const { user } = useAuth();
  const { createAttendance } = useAppointment();
  const { hasPermission } = usePermission();

  const canViewButton = useMemo(
    () => hasPermission('call_attendance') && user?.type === 'professional',
    [hasPermission, user],
  );

  const handleAttendance = useCallback(() => {
    let attendanceArea: AppointmentAreaEnum | undefined;

    switch (user?.professional?.occupationArea) {
      case 'Medicina':
        attendanceArea = AppointmentAreaEnum.MEDICINE;
        break;
      case 'Enfermagem':
        attendanceArea = AppointmentAreaEnum.NURSING;
        break;
      default:
        attendanceArea = undefined;
        break;
    }

    const appointmentData = {
      patientName: patient.name,
      professionalName: user?.name,
      patientPhone: patient.phone,
      type: AppointmentTypeEnum.PHONE,
    };

    showModal({
      type: 'custom',
      data: (
        <Suspense fallback={false}>
          <RunAppointmentNewTabModal
            appointment={appointmentData}
            onConfirm={async () => {
              await createAttendance({
                patientId: patient.id ?? '',
                professionalId: user?.professional_id ?? '',
                attendanceType: AppointmentTypeEnum.PHONE,
                attendanceArea,
                contactPhone: patient.phone,
              });
            }}
          />
        </Suspense>
      ),
    });
  }, [user, patient, createAttendance, showModal]);

  return (
    <Button
      buttonType="primary-alternative"
      onClick={handleAttendance}
      disabled={!canViewButton}
    >
      Atender agora
    </Button>
  );
};

export default AttendanceNow;
