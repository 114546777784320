import styled, { css } from 'styled-components';
import { Container as GridCardContainer } from '../../../../components/GridCard/styles';

interface ContainerProps {
  disabled?: boolean;
}

interface ButtonFooterDefaultProps {
  color?: 'primary' | 'light' | 'grey';
}

const styleVariations = {
  primary: css`
    color: #fff;
    border: 1px solid #2d9cdb;
    background: #2d9cdb;
  `,
  light: css`
    color: #2d9cdb;
    border: 1px solid #2d9cdb;
  `,
  grey: css`
    color: #828282;
    border: 1px solid #bdbdbd;
  `,
};

export const Container = styled(GridCardContainer)<ContainerProps>`
  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.white};
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;

export const ContainerScreensDefault = styled.div`
  width: 100%;
  height: 100%;
  max-height: 300px;
  padding: 0 0 15px;
`;

export const CardHeaderDefault = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 56px;
  padding: 15px 20px;
  background: ${({ theme }) =>
    `linear-gradient(89.77deg, ${theme.colors.secondary} -39.87%, ${theme.colors.primary} 71.56%)`};
  border-radius: 5px 5px 0px 0px;

  > h2 {
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const CardFooterDefault = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 25px;

  > button + button {
    margin-left: 20px;
  }
`;

export const ButtonFooterDefault = styled.button<ButtonFooterDefaultProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 140px;
  padding: 10px;
  border-radius: 50px;
  background: transparent;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  > svg {
    margin-left: 5px;
  }

  ${({ color = 'light' }) => styleVariations[color]}
`;
