import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  lazy,
  Suspense,
} from 'react';
import { useModal } from 'react-brave-modal';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../../lib/use-current-effect';

import { Professional } from '../../../entities/Professional';
import { usePermission } from '../../../hooks/permission';
import { useAuth } from '../../../hooks/auth';
import useQuery from '../../../hooks/query';
import { UserSensisitiveData, useUser } from '../../../hooks/user';

import { ReactComponent as SettingsIcon } from '../../../assets/images/settings.svg';
import { ReactComponent as PersonIcon } from '../../../assets/images/person2.svg';
import { ReactComponent as BagIcon } from '../../../assets/images/briefcase.svg';
import { ReactComponent as ScheduleIcon } from '../../../assets/images/schedule-set.svg';

import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import DashboardTemplate from '../../../templates/Dashboard';

import ProfileType from './ProfileType';
import PersonalData from './PersonalData';
import ProfessionalData from './ProfessionalData';
import AvailabilitySchedule from './AvailabilitySchedule';

import {
  Button,
  Container,
  Content,
  MenuOptions,
  OptionComponent,
  Option,
  ButtonsContainer,
} from './styles';
import MainAreasEnum from '../../../enums/MainAreasEnum';

const SelfProfileUpdateConfirmationModal = lazy(
  () =>
    import(
      /* webpackChunkName: "SelfProfileUpdateConfirmationModal" */ '../../../modals/SelfProfileUpdateConfirmationModal'
    ),
);

const AskForPasswordModal = lazy(
  () =>
    import(
      /* webpackChunkName: "AskForPasswordModal" */ '../../../modals/AskForPasswordModal'
    ),
);

const ModalFinishRegister = lazy(
  () =>
    import(
      /* webpackChunkName: "ModalFinishRegister" */ './modals/ModalFinishRegister'
    ),
);

const ConfirmSpecialtiesProfessional = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmSpecialtiesProfessional" */ './modals/ConfirmSpecialtiesProfessional'
    ),
);

type TypePageType = 'register' | 'edit';

type SelectedType =
  | 'ProfileType'
  | 'PersonalData'
  | 'ProfessionalData'
  | 'AvailabilitySchedule';

interface GetData {
  professionalData?: Professional;
  localSensitiveData?: UserSensisitiveData;
}

export interface ComponentsUserRegisterEditRef {
  cancel: () => Promise<boolean>;
  back: () => Promise<boolean>;
  next: () => Promise<boolean>;
  finish?: () => Promise<boolean>;
  save: () => Promise<boolean | undefined>;
  getData?: () => GetData;
  canSave?: () => Promise<boolean>;
}

export interface ComponentsUserRegisterEditProps {
  onSaveSucceed?: () => void;
}

const Edit: React.FC = () => {
  const { hasPermission } = usePermission();
  const { user: userAuth } = useAuth();
  const query = useQuery();
  const history = useHistory();
  const {
    location: { state: locationState },
  } = history;
  const { sync, user, cleanLocal, previousSensitiveData } = useUser();
  const { showModal, closeModal } = useModal();
  const location = useLocation();

  const profileTypeRef = useRef<ComponentsUserRegisterEditRef>(null);
  const personalDataRef = useRef<ComponentsUserRegisterEditRef>(null);
  const professionalDataRef = useRef<ComponentsUserRegisterEditRef>(null);
  const availabilityScheduleRef = useRef<ComponentsUserRegisterEditRef>(null);

  const [id] = useState(query.get('id'));
  const [type] = useState<TypePageType>(() =>
    location.pathname.includes('edit') ? 'edit' : 'register',
  );
  const [selected, setSelected] = useState<SelectedType>(() =>
    location.pathname.includes('edit') ? 'PersonalData' : 'ProfileType',
  );
  const [canStayHere, setStayHere] = useState(true);
  const [buttonLoadingStatus, setButtonLoadingStatus] = useState({
    finish: false,
    save: false,
    next: false,
  });

  const areThereAnyButtonsLoading = useMemo(
    () => Object.values(buttonLoadingStatus).some((value) => value),
    [buttonLoadingStatus],
  );

  const pages = useMemo<ElementPageTree[]>(
    () => [
      {
        name: 'Administração',
      },
      {
        name: 'Usuários e profissionais',
        link: '/user/list',
      },
      {
        name: type === 'register' ? 'Novo Usuário' : 'Editar Usuário',
      },
    ],
    [type],
  );

  const handleProceedOnSaveSuccess = useCallback(() => {
    if (type === 'edit') {
      toast.success('Usuário atualizado com sucesso');
    }

    history.push('/user/list');
  }, [history, type]);

  const options = useMemo(
    () => ({
      ProfileType: {
        key: 0,
        icon: <SettingsIcon />,
        name: 'Tipo de Perfil',
        component: (
          <ProfileType
            onSaveSucceed={handleProceedOnSaveSuccess}
            ref={profileTypeRef}
          />
        ),
        ref: profileTypeRef,
        visible: type === 'register',
        buttons: ['cancel', 'back', 'next', 'save'],
      },
      PersonalData: {
        key: 1,
        icon: <PersonIcon />,
        name: 'Dados pessoais',
        component: (
          <PersonalData
            onSaveSucceed={handleProceedOnSaveSuccess}
            ref={personalDataRef}
          />
        ),
        ref: personalDataRef,
        visible: true,
        buttons:
          user?.type === 'professional'
            ? ['cancel', 'back', 'next', 'save']
            : ['cancel', 'back', 'next', 'finish', 'save'],
      },
      ProfessionalData: {
        key: 2,
        icon: <BagIcon />,
        name: 'Dados profissionais',
        component: (
          <ProfessionalData
            onSaveSucceed={handleProceedOnSaveSuccess}
            ref={professionalDataRef}
          />
        ),
        ref: professionalDataRef,
        visible: user?.type === 'professional',
        buttons: ['cancel', 'back', 'next', 'save'],
      },
      AvailabilitySchedule: {
        key: 3,
        icon: <ScheduleIcon />,
        name: 'Configurar Agenda',
        component: (
          <AvailabilitySchedule
            onSaveSucceed={handleProceedOnSaveSuccess}
            ref={availabilityScheduleRef}
          />
        ),
        ref: availabilityScheduleRef,
        visible: user?.type === 'professional',
        buttons: ['cancel', 'back', 'next', 'finish', 'save'],
      },
    }),
    [handleProceedOnSaveSuccess, type, user],
  );

  useEffect(() => {
    if (!canStayHere) {
      history.push('/user/list');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canStayHere]);

  useCurrentEffect((isCurrent) => {
    if (location.pathname.includes('edit')) {
      if (id) {
        sync(id)
          .then(() => {
            if (id === userAuth?.id) {
              if (isCurrent()) {
                setStayHere(hasPermission('edit_my_details'));
              }
            } else if (isCurrent()) {
              setStayHere(hasPermission('edit_other_users'));
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            history.push('/user/list');
          });
      } else {
        history.push('/user/list');
      }
    }

    return () => {
      cleanLocal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowModalConfirmSpecialties = useCurrentCallback(
    (isCurrent) => (newSelected: SelectedType, specialtiesProfessional) => {
      if (isCurrent()) {
        showModal({
          type: 'custom',
          data: (
            <Suspense fallback={false}>
              <ConfirmSpecialtiesProfessional
                nextPage={() => setSelected(newSelected)}
                specialtiesProfessional={specialtiesProfessional}
              />
            </Suspense>
          ),
          closeActionSync: () => {
            setSelected(newSelected);
          },
        });
      }
    },
    [showModal],
  );

  const handleCancel = useCallback(async () => {
    const canProceed = await options[
      selected as keyof typeof options
    ]?.ref?.current?.cancel();

    if (canProceed) {
      cleanLocal();

      let hasPreviousPath = false;

      if (locationState) {
        hasPreviousPath = !!(history.location.state as { from?: string })?.from
          ?.length;
      }

      if (hasPreviousPath) {
        history.goBack();
      } else {
        history.push('/user/list');
      }
    }
  }, [cleanLocal, history, locationState, options, selected]);

  const handleBack = useCurrentCallback(
    (isCurrent) => async () => {
      const canProceed = await options[
        selected as keyof typeof options
      ]?.ref?.current?.back();

      if (canProceed) {
        const steps = Object.entries(options);
        const newIndex = options[selected as keyof typeof options].key - 1;

        if (isCurrent()) {
          if (newIndex >= 0) {
            setSelected(steps[newIndex][0] as SelectedType);
          } else {
            setSelected(steps[0][0] as SelectedType);
          }
        }
      }
    },
    [options, selected],
  );

  const handleFinish = useCurrentCallback(
    (isCurrent) => async () => {
      setButtonLoadingStatus((state) => ({ ...state, finish: true }));

      const canSave =
        options[selected as keyof typeof options].ref.current?.canSave;

      const finish =
        options[selected as keyof typeof options].ref.current?.finish;

      const canProceed =
        canSave && (await canSave()) && finish && (await finish());

      if (canProceed) {
        showModal({
          type: 'custom',
          closeActionSync: () => history.push('/user/list'),
          data: (
            <Suspense fallback={false}>
              <ModalFinishRegister />
            </Suspense>
          ),
        });
      }

      isCurrent() &&
        setButtonLoadingStatus((state) => ({ ...state, finish: false }));
    },
    [options, selected, showModal, history],
  );

  const handleSave = useCurrentCallback(
    (isCurrent) => async () => {
      const getData =
        selected === 'PersonalData' &&
        options[selected as keyof typeof options]?.ref?.current?.getData;

      const canSave =
        options[selected as keyof typeof options]?.ref?.current?.canSave;

      if (canSave && (await canSave())) {
        const localSensitiveData = getData && getData().localSensitiveData;

        const sensitiveData = localSensitiveData || {
          email: user?.email,
          profileType: user?.profile?.type,
          username: user?.username,
        };

        if (
          user?.id !== userAuth?.id &&
          ((sensitiveData.email &&
            sensitiveData.email !== previousSensitiveData?.email) ||
            (sensitiveData.username &&
              sensitiveData.username !== previousSensitiveData?.username))
        ) {
          showModal({
            data: (
              <Suspense fallback={false}>
                <AskForPasswordModal
                  onSuccess={async () => {
                    setButtonLoadingStatus((state) => ({
                      ...state,
                      save: true,
                    }));

                    const didSave = await options[
                      selected as keyof typeof options
                    ]?.ref?.current?.save();

                    didSave
                      ? closeModal()
                      : isCurrent() &&
                        setButtonLoadingStatus((state) => ({
                          ...state,
                          save: false,
                        }));
                  }}
                />
              </Suspense>
            ),
            type: 'custom',
          });

          return;
        }

        if (
          user?.id === userAuth?.id &&
          sensitiveData.profileType &&
          sensitiveData.profileType !== previousSensitiveData?.profileType
        ) {
          showModal({
            type: 'custom',
            data: (
              <Suspense fallback={false}>
                <SelfProfileUpdateConfirmationModal
                  onCancel={() => {
                    closeModal();
                  }}
                  onProceed={async () => {
                    setButtonLoadingStatus((state) => ({
                      ...state,
                      save: true,
                    }));

                    const didSave = await options[
                      selected as keyof typeof options
                    ]?.ref?.current?.save();

                    didSave
                      ? closeModal()
                      : isCurrent() &&
                        setButtonLoadingStatus((state) => ({
                          ...state,
                          save: false,
                        }));
                  }}
                />
              </Suspense>
            ),
          });

          return;
        }

        setButtonLoadingStatus((state) => ({ ...state, save: true }));

        const didSave = await options[
          selected as keyof typeof options
        ]?.ref?.current?.save();

        !didSave &&
          setButtonLoadingStatus((state) => ({ ...state, save: false }));
      }
    },
    [
      closeModal,
      options,
      previousSensitiveData,
      selected,
      showModal,
      user,
      userAuth,
    ],
  );

  const handleNext = useCurrentCallback(
    (isCurrent) => async () => {
      const canSave =
        options[selected as keyof typeof options]?.ref?.current?.canSave;

      if (canSave && (await canSave())) {
        setButtonLoadingStatus((state) => ({ ...state, next: true }));

        const canProceed = await options[
          selected as keyof typeof options
        ]?.ref?.current?.next();

        if (canProceed && isCurrent()) {
          const steps = Object.entries(options);
          const newIndex = options[selected as keyof typeof options].key + 1;

          const getData =
            options[selected as keyof typeof options]?.ref?.current?.getData;

          if (getData) {
            const { localSensitiveData, professionalData }: GetData = getData();

            if (selected === 'PersonalData' && localSensitiveData) {
              setSelected(steps[newIndex][0] as SelectedType);
            }

            if (selected === 'ProfessionalData') {
              if (professionalData) {
                if (
                  professionalData.specialtiesProfessional &&
                  professionalData.specialtiesProfessional.length > 1
                ) {
                  handleShowModalConfirmSpecialties(
                    steps[newIndex][0] as SelectedType,
                    professionalData.specialtiesProfessional,
                  );
                } else {
                  setSelected(steps[newIndex][0] as SelectedType);
                }
              }
            }
          } else if (newIndex >= steps.length - 1) {
            setSelected(steps[steps.length - 1][0] as SelectedType);
          } else {
            setSelected(steps[newIndex][0] as SelectedType);
          }
        }

        setButtonLoadingStatus((state) => ({ ...state, next: false }));
      }
    },
    [options, selected, handleShowModalConfirmSpecialties],
  );

  const handleChange = useCurrentCallback(
    (isCurrent) => async (keyName: SelectedType) => {
      const canSave =
        options[selected as keyof typeof options].ref.current?.canSave;

      if (canSave && (await canSave())) {
        const canProceed = await options[
          selected as keyof typeof options
        ].ref.current?.next();

        if (
          (canProceed || selected === 'AvailabilitySchedule') &&
          isCurrent()
        ) {
          const getData =
            options[selected as keyof typeof options]?.ref?.current?.getData;

          if (getData) {
            const { localSensitiveData, professionalData } = getData();

            if (selected === 'PersonalData' && localSensitiveData) {
              setSelected(keyName);
            }

            if (selected === 'ProfessionalData' && professionalData) {
              if (
                professionalData.specialtiesProfessional &&
                professionalData.specialtiesProfessional.length > 1
              ) {
                handleShowModalConfirmSpecialties(
                  keyName,
                  professionalData.specialtiesProfessional,
                );
              } else {
                setSelected(keyName);
              }
            }
          } else {
            setSelected(keyName);
          }
        }
      }
    },
    [options, selected, handleShowModalConfirmSpecialties],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <PageTree pages={pages} />
      <Container>
        <h1>{type === 'register' ? 'Novo Usuário' : 'Editar Usuário'}</h1>
        <Content>
          <MenuOptions>
            {Object.entries(options).map(
              ([keyName, { visible, icon, name, key }]) =>
                visible && (
                  <Option
                    key={keyName}
                    onClick={() => {
                      handleChange(keyName as SelectedType);
                    }}
                    selected={keyName === selected}
                    disabled={
                      options[selected as keyof typeof options].key < key &&
                      type !== 'edit'
                    }
                  >
                    {icon}

                    <span>{name}</span>
                  </Option>
                ),
            )}
          </MenuOptions>
          <OptionComponent>
            {options[selected as keyof typeof options].component}
          </OptionComponent>
        </Content>
      </Container>
      <ButtonsContainer>
        {options[selected as keyof typeof options].buttons.map((buttonType) => (
          <React.Fragment key={buttonType}>
            {buttonType === 'cancel' && (
              <Button
                disabled={areThereAnyButtonsLoading}
                onClick={handleCancel}
                color="white"
              >
                Cancelar
              </Button>
            )}
            {buttonType === 'back' && (
              <Button
                disabled={
                  selected === 'ProfileType' ||
                  areThereAnyButtonsLoading ||
                  (type === 'edit' && selected === 'PersonalData')
                }
                onClick={handleBack}
                color="lite"
              >
                Voltar
              </Button>
            )}
            {buttonType === 'next' && (
              <Button
                disabled={
                  selected === 'AvailabilitySchedule' ||
                  (user?.type !== 'professional' &&
                    selected === 'PersonalData') ||
                  buttonLoadingStatus.finish ||
                  buttonLoadingStatus.save
                }
                loading={buttonLoadingStatus.next}
                onClick={handleNext}
                color="lite"
              >
                Avançar
              </Button>
            )}
            {buttonType === 'finish' && type !== 'edit' && (
              <Button
                disabled={buttonLoadingStatus.next || buttonLoadingStatus.save}
                loading={buttonLoadingStatus.finish}
                onClick={handleFinish}
                color="primary"
              >
                Finalizar
              </Button>
            )}
            {buttonType === 'save' && type === 'edit' && (
              <Button
                loading={buttonLoadingStatus.save}
                onClick={handleSave}
                disabled={
                  selected === 'ProfileType' ||
                  buttonLoadingStatus.finish ||
                  buttonLoadingStatus.next
                }
                color="primary"
              >
                Salvar
              </Button>
            )}
          </React.Fragment>
        ))}
      </ButtonsContainer>
    </DashboardTemplate>
  );
};

export default Edit;
