import React, { createContext, useContext, useState, useEffect } from 'react';
import io, { Socket } from 'socket.io-client';

import { useAuth } from './auth';

interface SocketIoContextData {
  socket?: Socket;
}

const SocketIoContext = createContext<SocketIoContextData>(
  {} as SocketIoContextData,
);

export const SocketIoProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    if (user) {
      if (process.env.REACT_APP_API_BASE_URL) {
        const token = localStorage.getItem('@Cinco:token');

        const socketIo = io(process.env.REACT_APP_API_BASE_URL, {
          extraHeaders: {
            authorization: `Bearer ${token}`,
          },
        });

        socketIo.emit('setExternalId', {
          externalId: user.id,
        });

        setSocket(socketIo);
      }
    }
  }, [user]);

  return (
    <SocketIoContext.Provider
      value={{
        socket,
      }}
    >
      {children}
    </SocketIoContext.Provider>
  );
};

export const useSocketIo = (): SocketIoContextData => {
  const context = useContext(SocketIoContext);

  if (!context) {
    throw new Error('useSocketIo must be used within a SocketIoProvider');
  }

  return context;
};
