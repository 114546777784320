import React, { useMemo, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardTemplate from '../../../templates/Dashboard';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import Filter from './Filter';
import {
  Button,
  ButtonsContainer,
  CardContentNothingToDisplayBox,
} from './styles';
import CloudSketchesImage from '../../../assets/images/cloud-sketches.svg';
import { User } from '../../../entities/User';
import { useUser } from '../../../hooks/user';
import UserTable from './UserTable';
import ContentNothingToDisplay from '../../../components/ContentNothingToDisplay';
import Spinner from '../../../components/Spinner';
import { UserFiltersDTO } from '../../../dtos/UserFiltersDTO';
import { usePermission } from '../../../hooks/permission';
import CardContentNothingToDisplay from '../../../components/CardContentNothingToDisplay';
import { useCurrentEffect } from '../../../lib/use-current-effect';
import MainAreasEnum from '../../../enums/MainAreasEnum';

export type OrderBy =
  | 'name'
  | 'role'
  | 'uf'
  | 'city'
  | 'profileTitle'
  | undefined;

const List: React.FC = () => {
  const { hasPermission } = usePermission();
  const [list, setList] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filters, setFilters] = useState<UserFiltersDTO>({});
  const [orderBy, setOrderBy] = useState<OrderBy>('name');
  const [ordenationType, setOrdenationType] = useState<'ASC' | 'DESC'>('ASC');
  const [areThereUsers, setAreThereUsers] = useState(false);
  const [loading, setLoading] = useState(true);

  const isFilterEmpty = useMemo(() => !Object.keys(filters).length, [filters]);

  const { getUsers } = useUser();

  const history = useHistory();

  const can = useMemo(
    () => ({
      create: hasPermission('register_user'),
    }),
    [hasPermission],
  );

  const handleOnClick = useCallback(
    (local) => history.push(`/${local}/register`),
    [history],
  );

  const pages = useMemo<ElementPageTree[]>(
    () => [
      {
        name: 'Administração',
      },
      {
        name: 'Usuários e profissionais',
      },
    ],
    [],
  );

  useCurrentEffect(
    (isCurrent) => {
      getUsers(currentPage, filters, {}, orderBy, ordenationType)
        .then((response) => {
          if (isCurrent()) {
            setList(response.list);
            setTotalPages(response.totalPages);
            setTotalUsers(response.totalUsers);
            setAreThereUsers(!!response.totalUsers);
          }
        })
        .finally(() => {
          if (isCurrent()) {
            setLoading(false);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, filters, orderBy, ordenationType, getUsers],
  );

  const displayedComponent = useMemo<JSX.Element>(() => {
    if (loading) {
      return <Spinner />;
    }

    return areThereUsers || !isFilterEmpty ? (
      <>
        <Filter
          filters={filters}
          setFilters={setFilters}
          onChangePage={(page: number) => setCurrentPage(page)}
        />
        {areThereUsers ? (
          <UserTable
            list={list}
            setList={setList}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalData={totalUsers}
            totalPages={totalPages}
            filters={filters}
            setFilters={setFilters}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            ordenationType={ordenationType}
            setOrdenationType={setOrdenationType}
          />
        ) : (
          <CardContentNothingToDisplayBox>
            <CardContentNothingToDisplay>
              <img src={CloudSketchesImage} alt="" />
              Nenhum dado encontrado
            </CardContentNothingToDisplay>
          </CardContentNothingToDisplayBox>
        )}
      </>
    ) : (
      <ContentNothingToDisplay text="Nenhum dado encontrado" />
    );
  }, [
    areThereUsers,
    currentPage,
    filters,
    isFilterEmpty,
    list,
    loading,
    ordenationType,
    orderBy,
    totalPages,
    totalUsers,
  ]);

  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <PageTree pages={pages} />
      <ButtonsContainer>
        <Button
          onClick={() => handleOnClick('user')}
          color="primary"
          disabled={!can.create}
        >
          Novo Usuário
        </Button>
      </ButtonsContainer>
      {displayedComponent}
    </DashboardTemplate>
  );
};

export default List;
