import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  height: min-content;
`;
