import styled, { css } from 'styled-components';
import ButtonBase from '../../../components/Button';

interface OptionProps {
  selected: boolean;
}

interface ButtonProps {
  lite?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  color: 'white' | 'primary';
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 20px;

  flex: 1;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;

  button + button {
    margin-left: 18px;
  }
`;

export const MenuOptions = styled.div`
  flex: 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  padding: 30px 25px;

  margin-right: 24px;

  > h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    align-items: center;
    color: #4f4f4f;
    margin-bottom: 100px;
  }
`;

export const Option = styled.button<OptionProps>`
  width: 100%;
  border-radius: 6px;
  border: 0;
  background: white;

  display: flex;
  align-items: center;

  padding: 8px;

  cursor: pointer;

  > span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    color: #707683;
    margin-left: 14px;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: #daedf7;

      svg > path {
        fill: #2d9cdb;
      }

      > span {
        color: #2d9cdb;
      }
    `}
`;

export const OptionComponent = styled.div`
  flex: 6;

  display: flex;
  align-items: flex-start;

  @media (max-width: 1100px) {
    flex: 5;
  }
`;

export const ButtonTemplate = styled.button`
  border-radius: 30px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  font-size: 14px;
  padding: 16px 28px;
  width: 135px;
`;

export const Button = styled(ButtonBase)<ButtonProps>`
  display: flex;
  align-items: center;
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.colors.secondary} 0%,
    ${(props) => props.theme.colors.primary} 100%
  );

  border-radius: 100px;
  border: 0;
  padding: 16px 48px;
  color: #fff;
  margin-top: 0px !important;
  transition: all 0.6s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  letter-spacing: 1.25px;

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #828282;

  &:hover {
    background: ${(props) => props.theme.colors.primary};
  }

  ${(props) =>
    props.color === 'white' &&
    css`
      background: ${props.theme.colors.white};
      color: #828282;

      &:hover {
        color: ${props.theme.colors.white};
      }
    `}

  ${(props) =>
    props.color === 'primary' &&
    css`
      color: #ffffff;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background: #f5f6f8;
      color: #bdbdbd;
      border: 1px solid #bdbdbd;

      &:hover {
        background: #f5f6f8;
        color: #bdbdbd;
      }
      cursor: default;
      transition: none;
    `}

    ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}

    ${({ lite }) =>
    lite &&
    css`
      border: 1px solid #2d9cdb;
      color: #2d9cdb;

      background: #f5f6f8;
    `}

    & ~ & {
    margin-left: 20px;
  }
`;
