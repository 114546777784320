enum AppointmentStatusEnum {
  PRE_APPOINTMENT = 'pre_appointment',
  CONFIRMED = 'confirmed',
  STARTED = 'started',
  FINISHED = 'finished',
  REMOVED = 'removed',
  NOT_ACCOMPLISHED = 'not_accomplished',
  UNFINISHED = 'unfinished',
}

export default AppointmentStatusEnum;
