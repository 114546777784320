import styled, { css } from 'styled-components';

interface ButtonSelectProps {
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 45px;
`;

export const Grouper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  &:last-child {
    justify-content: center;
  }

  > h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 26px;
    font-weight: 500;
    line-height: 18px;
    margin-top: 25px;
  }

  > p {
    color: ${(props) => props.theme.colors.grayAlternative};
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    padding: 25px 0 0 0;
  }

  > img {
    align-items: center;
    margin-left: 80px;
    max-width: 190px;
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 45px;
  width: 100%;
`;

export const ButtonSelect = styled.button<ButtonSelectProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.selected ? '#5AB1E3' : '#f8f8f8')};
  color: ${(props) => (props.selected ? props.theme.colors.white : '#707683')};
  border: none;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 500;
  height: 68px;
  width: 280px;
  padding: 0 28px;
  transition: background-color 0.4s;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    width: 35px;

    svg > path {
      fill: ${(props) => props.selected && props.theme.colors.white};
    }
  }

  & + button {
    margin-top: 20px;
  }

  &:hover {
    background: ${(props) => !props.selected && !props.disabled && '#f5f5f5'};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;
