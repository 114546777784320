import styled from 'styled-components';
import Button from '../../../../../components/Button';

import { GridContentDefault } from '../styles';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 30px 0 45px;
  background: #f8f8f8;
  border-radius: 6px;
  padding: 30px;
  width: 100%;

  > strong {
    margin-bottom: 20px;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    width: 100%;
  }
`;

export const GridForm = styled(GridContentDefault)`
  display: grid;
  grid-template-columns: 1fr 1fr 200px;
  grid-row-gap: 20px;
  align-items: end;
`;

export const ListCard = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto min-content;
  width: 100%;

  padding: 15px 20px;
  background: rgba(45, 156, 219, 0.25);
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.graySecondary};

  div {
    display: grid;
    flex-direction: column;

    strong {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      font-size: 14px;
      margin-top: 5px;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    border: none;
    margin-left: 20px;
    width: min-content;

    svg {
      color: ${({ theme }) => theme.colors.graySecondary};
    }
  }
`;

export const ButtonAddContact = styled(Button)`
  height: 40px;
  max-width: 200px;
  padding: 0;
  color: #ffffff;

  svg {
    margin-right: 4px;
  }
`;
