import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 445px;
  width: 545px;

  img {
    margin-bottom: 35px;
  }

  h1 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 55px;
  }
`;
