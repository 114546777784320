import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/cincoLogo1.svg';

import { Container, Header, Content } from './styles';

interface WindowProps {
  userType?: string;
}

const Window: React.FC<WindowProps> = ({ children, userType }) => {
  return (
    <Container>
      <Header>
        <img src={Logo} alt="Cinco" />
        {userType !== 'patient' && <Link to="/login">Já tenho login</Link>}
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

export default Window;
