import styled, { css } from 'styled-components';

import ButtonSecondary from '../../../../../components/ButtonSecondary';

interface RiskDegreeColorProps {
  riskDegreeColor?: string;
}

interface ButtonProps {
  color: 'white' | 'blue';
}

export const Container = styled.div`
  display: flex;
  height: 570px;
  width: 930px;
  padding: 80px 45px 40px;
`;

export const Content = styled.div`
  margin-right: 95px;

  h1,
  h2 {
    color: ${(props) => props.theme.colors.graySecondary};
  }

  h1 {
    font-size: 26px;
    margin-bottom: 35px;
  }

  h2 {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 0;
  }

  h2 + div {
    margin-top: 50px;
    height: 100px;
    width: 100px;
  }

  p {
    color: #707683;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    max-width: 335px;
    padding: 0;
  }

  form {
    margin-bottom: 48px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  color: #707683;
  width: 448px;
`;

export const TableHead = styled.thead`
  background-color: #f2f2f2;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
`;

export const Row = styled.tr`
  height: 35px;

  & + tr {
    border-top: 1px solid #efefef;
  }
`;

export const Head = styled.th`
  &:first-child {
    text-align: center;
  }
`;

export const TableBody = styled.tbody``;

export const Data = styled.td`
  background-color: #fdfdfd;

  span {
    color: #707683;
    font-size: 12px;
  }
`;

export const Risk = styled.div<RiskDegreeColorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.riskDegreeColor};
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin: 0 auto;

  span {
    color: ${(props) => props.theme.colors.white};
    font-size: 16px;
    font-weight: 800;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Buttons = styled.div`
  display: flex;

  button + button {
    margin-left: 20px;
  }
`;

export const Button = styled(ButtonSecondary)<ButtonProps>`
  display: flex;
  justify-content: center;
  font-size: 14;
  font-weight: 500;
  margin-left: auto;
  height: 48px;
  width: 135px;
  transition: 0.5s;

  ${(props) =>
    props.color === 'blue' &&
    css`
      background-color: ${props.theme.colors.primary};
      border: none;
      color: ${props.theme.colors.white};
    `}

  ${(props) =>
    props.color === 'white' &&
    css`
      &:hover {
        border-color: ${props.theme.colors.primary};
        color: ${props.theme.colors.primary};
      }
    `}
`;

export const Image = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
