import React from 'react';

import history from '../../../../services/history';
import Email from '../../../../assets/images/email.svg';

import { Container, ComeBackButton } from './styles';

const SendingInstructions: React.FC = () => {
  return (
    <Container>
      <img src={Email} alt="" />
      <h1>Instruções enviadas</h1>
      <h2>
        As instruções para redefinir a sua senha foram enviadas para o seu
        e-mail cadastrado.
      </h2>
      <ComeBackButton onClick={() => history.push('/login')}>
        Voltar ao login
      </ComeBackButton>
    </Container>
  );
};

export default SendingInstructions;
