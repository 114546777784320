import React, { useCallback } from 'react';

import { toast } from 'react-toastify';
import { InputCopy } from './styles';

import ClipboardIcon from '../../assets/images/clipboard_icon_blue.svg';

interface InputCopyToClipBoardProps {
  value?: string;
  style?: React.CSSProperties;
}

const InputCopyToClipBoard: React.FC<InputCopyToClipBoardProps> = ({
  value,
  style,
  ...rest
}) => {
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(value || '');
    toast.success('Copiado');
  }, [value]);

  return (
    <InputCopy style={style} {...rest}>
      <span
        style={{
          overflow: 'hidden',
          display: 'inline-flex',
          flexWrap: 'nowrap',
        }}
      >
        <a
          target="_blank"
          href={value}
          rel="noopener noreferrer"
          style={{ whiteSpace: 'nowrap' }}
        >
          {value}
        </a>
      </span>
      <button type="button" onClick={() => handleCopy()}>
        <img src={ClipboardIcon} alt="ícone do botão para copiar" />
      </button>
    </InputCopy>
  );
};

export default InputCopyToClipBoard;
