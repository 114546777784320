import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Header = styled.header`
  height: 100px;
  display: flex;
  justify-content: space-between;

  img {
    margin-left: 45px;
    margin-top: 60px;
    height: 50px;
    width: 150px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      margin: 60px auto 0 auto;
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  position: relative;
  background: linear-gradient(108.28deg, #30e3e5 4.43%, #2d9cdb 107.87%);
  max-width: 768px;
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 110px);
  margin-top: 0 !important;
  padding: 0 30px;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -5vh;
    min-width: 75%;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      min-width: 95%;
      margin-top: 1rem;
    }

    h1 {
      align-self: flex-start;
      color: ${(props) => props.theme.colors.white};
      font-size: 26px;
      font-style: normal;
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 25px;
    }

    > div {
      > strong {
        font-size: 11px;
        top: 12px;
      }

      & + div {
        margin-top: 20px;
      }
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: ${(props) => props.theme.colors.white};
      border-radius: 100px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
      color: ${(props) => props.theme.colors.secondary};
      font-size: 14px;
      font-weight: 600;
      height: 48px;
      max-width: 158px;
      width: 100%;
      letter-spacing: 1.25px;
      margin-left: auto;
      margin-top: 20px;
      text-transform: uppercase;

      > svg {
        margin-left: 5px;
      }
    }
  }

  > button {
    background-color: transparent;
    border: 0;
    color: ${(props) => props.theme.colors.white};
    outline: none;
    font-size: 12px;
    font-weight: 600;
    margin-top: 112px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      margin-top: 96px;
    }
  }
`;

export const CheckBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;

  > div {
    height: 20px;
    width: 20px;
  }

  > span {
    color: ${(props) => props.theme.colors.white};
    font-size: 12px;
    font-weight: 600;
    margin-left: 8px;
    padding-top: 5px;
  }
`;
