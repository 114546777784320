import { api } from '../api';
import { Institution } from '../../entities/Institution';

interface PatientData {
  id: string;
  name: string;
  avatar: string;
  cpf: string;
  email?: string;
}

interface ListRelatedAndUnrelatedResponse {
  relatedPatients: PatientData[];
  unrelatedPatients: PatientData[];
}

export interface CreateInstitutionResponse {
  instituition: Institution;
  patientsLists: ListRelatedAndUnrelatedResponse;
}

export const createInstitution = async (
  institutitonRequest: Institution,
): Promise<CreateInstitutionResponse> => {
  const { data } = await api.post('/institutions', institutitonRequest);

  return data;
};
