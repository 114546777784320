import { api } from '../api';

interface RemoveExamsProceduresProps {
  examProcedureId: string;
  patientId: string;
}

export const removeExamProcedure = async ({
  examProcedureId,
  patientId,
}: RemoveExamsProceduresProps): Promise<boolean> => {
  const { status } = await api.delete('patients/examProcedure', {
    data: {
      examProcedureId,
      patientId,
    },
  });

  return status === 204;
};
