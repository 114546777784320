import React, { useCallback, useMemo } from 'react';
import { ActionsProps } from '../..';
import { Title } from '../../../styles';
import Banner from '../../../../../../assets/images/sheet_attendance_banner.svg';

import { Container, SheetListAttendance } from './styles';

import Icon from '../../../../../../assets/images/scheet_icon.svg';
import DangerIcon from '../../../../../../assets/images/danger.svg';
import CheckOkIcon from '../../../../../../assets/images/check-ok.svg';
import { usePatient } from '../../../../../../hooks/patient';

interface ItemProps {
  id: string;
  sheetPatientId: string;
  name: string;
  status: 'received' | 'not_received' | 'removed';
}

interface FillFormProps extends ActionsProps {
  selectSheet: (id: string) => void;
}

const FillForm: React.FC<FillFormProps> = ({ goTo, selectSheet }) => {
  const { patient } = usePatient();

  const list = useMemo<ItemProps[]>(() => {
    const sheets =
      patient?.sheets
        ?.filter((sheet) => sheet.status !== 'removed')
        .map((sheet) => ({
          id: sheet.sheet.id,
          sheetPatientId: sheet.id,
          name: sheet.sheet.name,
          status: sheet.status,
        })) ?? [];

    return [...sheets];
  }, [patient]);

  const handleFillSheet = useCallback(
    (id: string) => {
      if (id && goTo) {
        selectSheet(id);
        goTo('satisfaction-survey');
      }
    },
    [goTo, selectSheet],
  );

  return (
    <Container>
      <header>
        <Title>Preencher Ficha</Title>
      </header>
      <section>
        <img src={Banner} alt="ficha" />
        <div>
          <h1>Fichas do Paciente</h1>
          <p>
            As fichas abaixo são fichas relacionadas às comorbidade do paciente
            e é recomendado que sejam preenchidas. Para escolher outra ficha que
            não esteja listada, vá no botão “Solicitar outra ficha”
          </p>
          <SheetListAttendance>
            {list.map((item) => (
              <div key={item.id}>
                <section>
                  <img src={Icon} alt="icone de ficha" />
                  {item.name}
                </section>
                <section>
                  {item.status === 'received' ? (
                    <>
                      <img src={CheckOkIcon} alt="Ícone de check/ok" />
                      Preenchida
                    </>
                  ) : (
                    <>
                      <img src={DangerIcon} alt="Ícone de alerta" />
                      Pendente
                    </>
                  )}
                </section>
                <section>
                  <button
                    type="button"
                    onClick={() => handleFillSheet(item.sheetPatientId)}
                  >
                    {item.status === 'received' ? (
                      <>Preencher novamente</>
                    ) : (
                      <>Preencher</>
                    )}
                  </button>
                </section>
              </div>
            ))}
          </SheetListAttendance>
        </div>
      </section>
      <footer>
        <button type="button" onClick={() => goTo && goTo('request-another')}>
          Solicitar outra ficha
        </button>
        <button type="button" onClick={() => goTo && goTo('main')}>
          Voltar
        </button>
      </footer>
    </Container>
  );
};

export default FillForm;
