import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { toast } from 'react-toastify';
import { ItemProps } from '../..';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import { Profile } from '../../../../entities/Profile';
import { getProfiles } from '../../../../services/profile';

import { Container, ButtonFooter } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Button from '../../../../components/Button';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../../../lib/use-current-effect';

interface ChangeProfileProps {
  items: ItemProps[];
  onChange: (responseItems: ItemProps[]) => any;
  onSuccess: (responseItems: ItemProps[]) => Promise<any>;
  onCancel: () => any;
}

interface FormProps {
  profile: string;
}

const ChangeProfile: React.FC<ChangeProfileProps> = ({
  items,
  onChange,
  onCancel,
  onSuccess,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const canBackUserProfile = useMemo(() => currentItem > 0, [currentItem]);
  const canForwardUserProfile = useMemo(
    () => items.length - 1 !== currentItem,
    [currentItem, items],
  );
  const [list, setList] = useState<Profile[]>([]);

  const listDisplay = useMemo(
    () =>
      list
        .filter(
          (item) =>
            item.id !== items[currentItem].currentTypeID &&
            item.type !== 'patient',
        )
        .map((item) => ({ label: item.title, value: item.id })),
    [list, items, currentItem],
  );

  const validate = useCallback(async (data: FormProps) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        profile: Yup.string().required('Você precisa selecionar uma opção.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.error('Você precisa selecionar uma opção.');
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }

      return false;
    }
  }, []);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleBack = useCallback(() => {
    if (!canBackUserProfile) {
      return;
    }
    setCurrentItem((state) => state - 1);
    formRef.current?.setFieldValue('profile', items[currentItem - 1].newTypeID);
  }, [canBackUserProfile, currentItem, items]);

  const handleForward = useCurrentCallback(
    (isCurrent) => async () => {
      if (!canForwardUserProfile) {
        return;
      }

      const data = formRef.current?.getData() as FormProps;

      const canPass = await validate(data);

      if (!canPass && !isCurrent()) {
        return;
      }

      const listUpdated = items;
      listUpdated[currentItem] = {
        ...listUpdated[currentItem],
        newTypeID: data?.profile,
      };

      onChange([...listUpdated]);

      setCurrentItem((state) => state + 1);
      formRef.current?.setFieldValue(
        'profile',
        items[currentItem + 1].newTypeID,
      );
    },
    [canForwardUserProfile, currentItem, items, onChange, validate],
  );

  const handleSave = useCurrentCallback(
    (isCurrent) => async () => {
      setIsButtonDisabled(true);

      const data = formRef.current?.getData() as FormProps;

      const canPass = await validate(data);

      if (!canPass) {
        if (isCurrent()) {
          setIsButtonDisabled(false);
        }
        return;
      }

      const listUpdated = items;
      listUpdated[currentItem] = {
        ...listUpdated[currentItem],
        newTypeID: data?.profile,
      };

      await onSuccess([...listUpdated]);

      if (isCurrent()) {
        setIsButtonDisabled(false);
      }
    },
    [currentItem, items, onSuccess, validate],
  );

  useCurrentEffect((isCurrent) => {
    getProfiles().then((response) => {
      if (isCurrent()) {
        setList([...response]);
        formRef.current?.setFieldValue('profile', items[currentItem].newTypeID);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {items.length > 1 && (
        <header>
          <strong>{currentItem + 1}</strong>- {items.length}
          <div>
            <button type="button" onClick={handleBack}>
              <IoIosArrowBack
                size={30}
                color={canBackUserProfile ? '#30E3E5' : '#F3F3F3'}
              />
            </button>
            <button type="button" onClick={handleForward}>
              <IoIosArrowForward
                size={30}
                color={canForwardUserProfile ? '#30E3E5' : '#F3F3F3'}
              />
            </button>
          </div>
        </header>
      )}
      <section>
        <h1>
          Para remover este usuário da lista, você precisa associá-lo a um novo
          perfil:
        </h1>
        <h2>{items[currentItem].name}</h2>
        <Form ref={formRef} onSubmit={() => {}}>
          <label>Selecione um perfil:</label>
          <InputSelectAlternative name="profile" options={listDisplay} />
        </Form>
      </section>
      <footer>
        <ButtonFooter buttonType="secondary" onClick={handleCancel}>
          Cancelar
        </ButtonFooter>
        {canBackUserProfile && (
          <ButtonFooter buttonType="primary-alternative" onClick={handleBack}>
            Anterior
          </ButtonFooter>
        )}
        {canForwardUserProfile && (
          <ButtonFooter
            buttonType="primary-alternative"
            onClick={handleForward}
          >
            Próximo
          </ButtonFooter>
        )}
        {items.length - 1 === currentItem && (
          <Button
            loading={isButtonDisabled}
            buttonType="primary"
            onClick={handleSave}
          >
            Salvar
          </Button>
        )}
      </footer>
    </Container>
  );
};

export default ChangeProfile;
