import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useModal } from 'react-brave-modal';

import InputIcon from '../../../../components/InputIcon';
import Button from '../../../../components/Button';
import { usePatient } from '../../../../hooks/patient';
import getValidationErrors from '../../../../utils/getValidationErrors';

import IconMessageGray from '../../../../assets/images/icon_schedule_message_gray.svg';

import { SmsDataType } from '../..';

import { ButtonsContainer } from '../../styles';
import { Container, InputNumber, InputTitle, Textarea } from './styles';

interface SMSAttendanceProps {
  back(): void;
  next(): void;
  setSmsData: React.Dispatch<React.SetStateAction<SmsDataType | undefined>>;
}

const SMSAttendance: React.FC<SMSAttendanceProps> = ({
  back,
  next,
  setSmsData,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { closeModal } = useModal();
  const { patient } = usePatient();

  const handleNext = async () => {
    try {
      const data = formRef.current?.getData() as SmsDataType;

      const schema = Yup.object().shape({
        phone: Yup.string()
          .required('O telefone é obrigatório')
          .length(16, 'Digite um número de telefone válido'),
        message: Yup.string()
          .required('Mensagem obrigatória')
          .max(150, 'Limite: 150 caracteres'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setSmsData(data);

      next();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }
    }
  };

  return (
    <>
      <Container>
        <section>
          <header>
            <h1>
              Enviar Mensagem{' '}
              <img src={IconMessageGray} alt="Icone de message" />
            </h1>
          </header>
          <Form
            ref={formRef}
            initialData={{ phonenumber: patient?.user?.phone }}
            onSubmit={() => {}}
          >
            <InputTitle>Número do destinatário:</InputTitle>
            <InputNumber>
              <InputIcon
                name="phone"
                textSize="24px"
                textColor="#2d9cdb"
                mask="(99) 9 9999-9999"
                placeholder="(00) 00000-0000"
                defaultValue={patient?.user?.phone}
              />
            </InputNumber>

            <InputTitle>Mensagem (até 150 caracteres)</InputTitle>
            <Textarea name="message" />
          </Form>
        </section>
      </Container>

      <ButtonsContainer>
        <Button buttonType="secondary" onClick={() => closeModal()}>
          Cancelar
        </Button>

        <Button buttonType="primary-alternative" onClick={() => back()}>
          Voltar
        </Button>

        <Button buttonType="primary" onClick={handleNext}>
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default SMSAttendance;
