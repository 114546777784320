import styled, { css } from 'styled-components';

import ButtonBlue from '../../components/ButtonBlue';

interface ButtonProps {
  color: 'blue' | 'white';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 445px;
  width: 545px;
  text-align: center;

  h1 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    max-width: 400px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 40px;

  > :last-child {
    margin-left: 20px;
  }
`;

export const Button = styled(ButtonBlue)<ButtonProps>`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  height: 48px;
  width: 135px;

  ${(props) =>
    props.color === 'white' &&
    css`
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.primary};
      color: ${props.theme.colors.primary};
    `}

  & + button {
    margin-left: 20px;
  }
`;
