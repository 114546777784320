import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from './styles/global';
import theme from './styles/theme';

import AppProvider from './hooks';

import Routes from './routes';

import history from './services/history';

const App: React.FC = () => {
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <Routes />
        </AppProvider>
        <GlobalStyle />
      </ThemeProvider>
    </Router>
  );
};

export default App;
