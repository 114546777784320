import React, { useEffect, useLayoutEffect, useState } from 'react';
import DashboardTemplate from '../../../templates/Dashboard';
import AllNoticesCard from './AllNoticesCard';
import LastNoticesCard from './LastNoticesCard';
import NoticesByInstitutionCard from './NoticesByInstitutionCard';
import {
  getNoticeDashboardData,
  GetNoticeDashboardDataResponse,
} from '../../../services/institution';

import { Container } from './styles';
import { useSocketIo } from '../../../hooks/socketIo';
import MainAreasEnum from '../../../enums/MainAreasEnum';

const NoticesDashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<GetNoticeDashboardDataResponse>();
  const { socket } = useSocketIo();

  useLayoutEffect(() => {
    setLoading(true);
    getNoticeDashboardData()
      .then((newData) => {
        setData(newData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    socket?.on('newNotices', (newData: GetNoticeDashboardDataResponse) => {
      setData(newData);
    });

    return () => {
      socket?.off('newNotices');
    };
  }, [socket]);

  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <Container>
        <AllNoticesCard
          data={data?.allNotices}
          total={data?.allNoticesTotal}
          loading={loading}
        />
        <LastNoticesCard data={data?.lastNotices} loading={loading} />
        <NoticesByInstitutionCard
          data={data?.noticesByInstitution}
          total={data?.noticesByInstitutionTotal}
          loading={loading}
        />
      </Container>
    </DashboardTemplate>
  );
};

export default NoticesDashboard;
