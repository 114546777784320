import styled from 'styled-components';

export const Container = styled.div`
  width: 930px;
  height: 570px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > footer {
    display: flex;
    justify-content: flex-end;
    margin: 50px 0 20px;

    > button + button {
      margin-left: 18px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  z-index: 0 !important;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-right: 45px;
  margin-bottom: 45px;

  button + button {
    margin-left: 20px;
  }
`;
