import React from 'react';
import { TiWarning } from 'react-icons/ti';

import { Container, CardAttention, CardLatestRecords } from './styles';

export interface ChartDetailsProps {
  position?: 'center' | 'end';
  inAlert?: boolean;
  title: string;
  image?: string;
  value: number | string;
  type: string;
  lastRecordTime: string;
}

const ChartDetails: React.FC<ChartDetailsProps> = ({
  position,
  image,
  lastRecordTime,
  title,
  type,
  value,
  inAlert,
}) => {
  return (
    <Container position={position}>
      {inAlert && (
        <CardAttention>
          <div>
            <TiWarning size={13} />
            <strong>Atenção</strong>
          </div>
        </CardAttention>
      )}

      <CardLatestRecords>
        <h4>{title}</h4>
        {image && <img src={image} alt="Ícone" />}
        <div>
          <h3>{value}</h3>
          <h5>{type}</h5>
        </div>
        <div>
          <span>Última Sincronização:</span>
          <span>{lastRecordTime}</span>
        </div>
      </CardLatestRecords>
    </Container>
  );
};

export default ChartDetails;
