import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useModal } from 'react-brave-modal';

import { AddEditHealthConditionModalProps } from '..';
import InputDegreeRadioColored from '../../../components/InputDegreeRadioColored';
import InputCheck from '../../../components/InputCheckAlternative';
import InputText from '../../../components/InputText';
import ModalButton from '../../../components/ModalButton';
import RequiredFieldInformation from '../../../components/RequiredFieldInformation';
import { usePatient } from '../../../hooks/patient';
import { createUserStatus } from '../../../services/user';
import getValidationErrors from '../../../utils/getValidationErrors';
import BannerImg from '../../../assets/images/comorbity_banner.svg';

import {
  Container,
  ContentArea,
  Content,
  Row,
  Input,
  ButtonArea,
  InputBox,
} from './styles';
import { useAuth } from '../../../hooks/auth';
import Button from '../../../components/Button';
import { useCurrentCallback } from '../../../lib/use-current-effect';
import InputAsyncSelect from '../../../components/InputAsyncSelect';
import { Icd10Patient } from '../../../entities/Icd10Patient';
import { getIcd10 } from '../../../services/icd10';

interface FormProps {
  icd10Id: string;
  isMainHealthCondition: boolean;
  severity: string;
  medicine: string;
  observation: string;
}

interface Icd10Options {
  value: string;
  label: string;
}

interface MainProps extends AddEditHealthConditionModalProps {
  next: (step: number) => void;
}

const Main: React.FC<MainProps> = ({ next, icd10PatientId, action }) => {
  const [healthConditionPatient, setHealthConditionPatient] =
    useState<Icd10Patient>();
  const formRef = useRef<FormHandles>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { patient, sync, addHealthCondition, editHealthCondition } =
    usePatient();
  const { user: userAuth } = useAuth();
  const { closeModal } = useModal();
  const severityOptions = [
    {
      name: 'B',
      value: 'B',
      color: '#19CAC0',
      background: 'rgba(025, 202, 192, 0.1)',
      backgroundSelected: 'rgb(025, 202, 192)',
    },
    {
      name: 'M',
      value: 'M',
      color: '#F2B94A',
      background: 'rgba(242, 185, 074, 0.1)',
      backgroundSelected: 'rgb(242, 185, 074)',
    },
    {
      name: 'G',
      value: 'G',
      color: '#EA7676',
      background: 'rgba(234, 118, 118, 0.1)',
      backgroundSelected: 'rgb(234, 118, 118)',
    },
  ];

  const loadIcd10Options = async (
    inputValue: string,
    _: any,
    additional?: { page: number },
  ) => {
    const { data: results } = await getIcd10({
      keyword: inputValue,
      limit: 10,
      page: additional?.page || 1,
    });

    const options: Icd10Options[] = results.map((item) => ({
      value: item.id,
      label: `${item.icd} - ${item.description}`,
    }));

    return {
      options,
      hasMore: options.length > 0,
      additional: { page: (additional?.page ? additional.page : 1) + 1 },
    };
  };

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async (data: FormProps) => {
      setIsButtonDisabled(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          icd10Id: Yup.string().required('Condição de saúde obrigatória'),
          isMainHealthCondition: Yup.boolean(),
          severity: Yup.string().required('Gravidade obrigatória'),
          medicine: Yup.string(),
          observation: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (action === 'add') {
          await addHealthCondition({
            ...data,
          });
        }

        if (action === 'edit') {
          await editHealthCondition({
            ...data,
            icd10PatientId: icd10PatientId || '',
          });
        }

        if (patient?.user?.currentStatus?.status === 'draft') {
          await createUserStatus({
            userId: patient.user?.id,
            created_updated_by: userAuth?.id ?? '',
          });

          if (isCurrent()) {
            setIsButtonDisabled(false);
          }

          next(2);
        } else {
          if (isCurrent()) {
            setIsButtonDisabled(false);
          }

          next(1);
        }

        await sync();
      } catch (err) {
        if (isCurrent()) {
          setIsButtonDisabled(false);
        }

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          toast.info(
            'Selecione uma condição de saúde e sua respectiva gravidade.',
          );
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }
    },
    [
      action,
      addHealthCondition,
      icd10PatientId,
      editHealthCondition,
      next,
      patient,
      sync,
      userAuth,
    ],
  );

  useEffect(() => {
    if (action === 'edit' && icd10PatientId) {
      setHealthConditionPatient(
        patient?.healthConditions?.find(({ id }) => id === icd10PatientId),
      );

      formRef.current?.setFieldValue(
        'icd10Id',
        `${healthConditionPatient?.icd10.icd} -${healthConditionPatient?.icd10.description}`,
      );
      formRef.current?.setFieldValue(
        'medicine',
        healthConditionPatient?.medicine,
      );
      formRef.current?.setFieldValue(
        'observation',
        healthConditionPatient?.observation,
      );
    }
  }, [action, icd10PatientId, healthConditionPatient, patient]);

  const isMainHealthCondition =
    patient &&
    patient.healthConditions?.find(({ id }) => id === icd10PatientId)
      ?.isMainHealthCondition;

  return (
    <Container>
      <ContentArea>
        <Content>
          {action === 'add' && <h1>Adicionar condição de saúde</h1>}
          {action === 'edit' && <h1>Editar condição de saúde</h1>}
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
              <label>
                Condição de saúde
                <RequiredFieldInformation />:
              </label>
              <InputBox className="inputSelect">
                {action === 'add' && (
                  <InputAsyncSelect
                    cacheOptions
                    defaultOptions
                    name="icd10Id"
                    placeholder="Pesquisar"
                    loadOptions={loadIcd10Options}
                  />
                )}
                {action === 'edit' && <InputText name="icd10Id" disabled />}
              </InputBox>
            </Row>
            <Row>
              <InputCheck
                name="isMainHealthCondition"
                defaultChecked={isMainHealthCondition || false}
                disabled={isMainHealthCondition}
              />
              <span aria-disabled={isMainHealthCondition}>
                {isMainHealthCondition
                  ? 'Principal'
                  : 'Definir como condição de saúde principal'}
              </span>
            </Row>
            <Row>
              <label>
                Gravidade
                <RequiredFieldInformation />:
              </label>
              <InputBox>
                <InputDegreeRadioColored
                  name="severity"
                  title=""
                  options={severityOptions}
                  defaultOption={healthConditionPatient?.severity}
                  optionStyle="roundedRectangle"
                />
              </InputBox>
            </Row>
            <Row>
              <label>Medicamentos utilizados pelo paciente:</label>
              <InputBox>
                <Input name="medicine" />
              </InputBox>
            </Row>
            <Row>
              <label>Observações</label>
              <InputBox>
                <Input name="observation" />
              </InputBox>
            </Row>
          </Form>
        </Content>
        <img src={BannerImg} alt="Profissionais de saúde" />
      </ContentArea>
      <ButtonArea>
        <ModalButton
          styleType="blue-light"
          type="button"
          onClick={() => closeModal()}
        >
          Cancelar
        </ModalButton>
        <Button
          loading={isButtonDisabled}
          type="submit"
          buttonType="primary"
          onClick={() => formRef?.current?.submitForm()}
        >
          Próximo
        </Button>
      </ButtonArea>
    </Container>
  );
};

export default Main;
