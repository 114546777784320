import styled, { css } from 'styled-components';
import ButtonBase from '../../../components/Button';

interface OptionProps {
  selected: boolean;
}

interface ButtonProps {
  lite?: boolean;
  isHidden?: boolean;
  color: 'white' | 'primary' | 'lite';
  marginIcon?: 'left' | 'right';
}

interface ConsentTermButtonProps {
  loading: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 360px;
  margin-top: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-top: 30px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  > h1 {
    font-family: 'Raleway';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    color: #4f4f4f;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  flex: 1;
`;

export const LeftSide = styled.div`
  flex: 2;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 90px;
`;

export const Option = styled.div<OptionProps>`
  width: 218px;
  border-radius: 6px;

  display: flex;
  align-items: center;

  padding: 8px;

  cursor: pointer;

  > span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    color: #707683;
    margin-left: 14px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: #daedf7;

      svg > path {
        fill: #2d9cdb;
      }

      > span {
        color: #2d9cdb;
      }
    `}
`;

export const RightSide = styled.div`
  flex: 6;

  display: flex;
  align-items: flex-start;

  margin-top: 40px;
  margin-bottom: 100px;

  @media (max-width: 1100px) {
    flex: 5;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Button = styled(ButtonBase)<ButtonProps>`
  display: flex;
  align-items: center;
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.colors.secondary} 0%,
    ${(props) => props.theme.colors.primary} 100%
  );
  height: 50px;

  border-radius: 100px;
  border: 0;
  padding: 16px 48px;
  color: #fff;
  margin-top: 0px !important;
  transition: all 0.6s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  letter-spacing: 1.25px;

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #828282;

  span {
    margin-left: ${({ marginIcon }) => (marginIcon === 'left' ? '5px' : '0px')};
    margin-right: ${({ marginIcon }) =>
      marginIcon === 'right' ? '5px' : '0px'};
  }

  &:hover {
    background: ${(props) => props.theme.colors.primary};
  }

  & ~ & {
    margin-left: 20px;
  }

  ${(props) =>
    props.color === 'white' &&
    css`
      background: ${props.theme.colors.white};
      color: #828282;

      &:hover {
        color: ${props.theme.colors.white};
      }
    `}

  ${(props) =>
    props.color === 'primary' &&
    css`
      color: #ffffff;
    `}

  ${(props) =>
    props.color === 'lite' &&
    css`
      border: 1px solid #2d9cdb;
      color: #2d9cdb;
      min-width: 185px;

      background: #f5f6f8;

      &:hover {
        color: ${props.theme.colors.white};
      }
    `}

  &:disabled {
    background: #f5f6f8;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;

    &:hover {
      background: #f5f6f8;
      color: #bdbdbd;
    }
    cursor: default;
    transition: none;
    pointer-events: none;
  }

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`;

export const ConsentTermButton = styled.button`
  width: 180px;

  border: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};

  position: absolute;

  &:disabled {
    color: ${({ theme }) => theme.colors.gray};
    cursor: not-allowed;
  }

  bottom: 25px;

  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;

  svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
`;
