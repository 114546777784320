/* eslint-disable import/no-duplicates */
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useModal } from 'react-brave-modal';

import { useCurrentEffect } from '../../../../lib/use-current-effect';

import { GridCardProps } from '../../../../components/GridCard';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';
import ShowMessageScheduleModal from '../../../../modals/ShowMessageScheduleModal';

import { usePermission } from '../../../../hooks/permission';
import { usePatient } from '../../../../hooks/patient';
import { Appointment } from '../../../../entities/Appointment';
import { getAppointmentsByPatient } from '../../../../services/appointments';
import { getAllCallStatusByKey } from '../../../../utils/getCallStatus';

import BlankSketchesImage from '../../../../assets/images/blank-sketches.svg';

import {
  Container,
  YearTitle,
  MonthTitle,
  AppointmentSection,
  DateAppointmentSection,
  InformationsAppointmentSection,
  LineAppointmentSection,
} from './styles';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';
import AppointmentStatusEnum from '../../../../enums/AppointmentStatusEnum';

type AppointmentHistoryProps = GridCardProps;

interface ItemProps {
  year: string;
  months: {
    name: string;
    appointments: {
      id: string;
      day: string;
      time: string;
      title: string;
      description?: string;
      professionalName: string;
      type: AppointmentTypeEnum;
      message?: string;
      contactPhone?: string;
    }[];
  }[];
}

const AppointmentHistory: React.FC<AppointmentHistoryProps> = ({
  gridArea,
}) => {
  const history = useHistory();
  const { hasPermission } = usePermission();
  const { patient } = usePatient();
  const { showModal } = useModal();
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const canView = useMemo(
    () => ({
      [AppointmentTypeEnum.PHONE]: hasPermission('call_attendance'),
      [AppointmentTypeEnum.SMS]: hasPermission('message_attendance'),
      [AppointmentTypeEnum.VIDEO]: hasPermission('video_attendance'),
      [AppointmentTypeEnum.PRESENTIAL]: hasPermission('attendances'),
    }),
    [hasPermission],
  );

  useCurrentEffect(
    (isCurrent) => {
      if (patient && patient.id) {
        getAppointmentsByPatient({
          patientId: patient.id,
          limit: 10,
          page: 1,
          status: 'finished',
        }).then((response) => {
          if (isCurrent()) {
            setAppointments(response.appointments);
          }
        });
      }
    },
    [patient],
  );

  const getAppointmentDescription = useCallback(
    (appointment: Appointment): string | undefined => {
      if (appointment.status === AppointmentStatusEnum.REMOVED) {
        return 'Atendimento removido';
      }

      if (appointment.status === AppointmentStatusEnum.FINISHED) {
        return appointment.type === AppointmentTypeEnum.PHONE ||
          appointment.type === AppointmentTypeEnum.VIDEO
          ? getAllCallStatusByKey(appointment.details?.callStatus)
          : '';
      }

      return '';
    },
    [],
  );

  const list = useMemo<ItemProps[]>(() => {
    const listTemp: ItemProps[] = [];
    appointments
      ?.filter(
        ({ status }) =>
          status === AppointmentStatusEnum.FINISHED ||
          status === AppointmentStatusEnum.REMOVED,
      )
      .forEach((appointment) => {
        const dateAppointment = new Date(
          appointment?.details?.start ?? appointment.date ?? '',
        );

        const monthAppointment = format(dateAppointment, 'MMMM', {
          locale: ptBR,
        });

        const TITLE_OPTIONS = {
          [AppointmentTypeEnum.VIDEO]: 'Chamada de Vídeo',
          [AppointmentTypeEnum.PHONE]: 'Chamada telefônica',
          [AppointmentTypeEnum.SMS]: 'Mensagem de texto',
        };

        const titleAppointment =
          TITLE_OPTIONS[appointment.type as keyof typeof TITLE_OPTIONS];

        const yearIndex = listTemp.findIndex(
          (item) => item.year === dateAppointment.getFullYear().toString(),
        );

        const monthIndex = listTemp[yearIndex]?.months?.findIndex(
          (item) => item.name === monthAppointment,
        );

        const appointmentData = {
          id: appointment.id ?? '',
          day: format(dateAppointment, 'dd'),
          time: format(dateAppointment, 'HH:mm'),
          title: titleAppointment,
          description: getAppointmentDescription(appointment),
          professionalName: appointment.attendantProfessional?.user?.name ?? '',
          type: appointment.type as AppointmentTypeEnum,
          message: appointment.message?.text,
          contactPhone: appointment.contact_phone,
        };

        if (yearIndex === -1) {
          listTemp.push({
            year: dateAppointment.getFullYear().toString(),
            months: [
              {
                name: monthAppointment,
                appointments: [
                  {
                    ...appointmentData,
                  },
                ],
              },
            ],
          });
        } else if (monthIndex === -1) {
          listTemp[yearIndex].months.push({
            name: monthAppointment,
            appointments: [
              {
                ...appointmentData,
              },
            ],
          });
        } else {
          listTemp[yearIndex].months[monthIndex].appointments.push({
            ...appointmentData,
          });
        }
      });

    return listTemp;
  }, [getAppointmentDescription, appointments]);

  const handleGoToConsult = useCallback(
    (id: string, type: AppointmentTypeEnum, text?: string, phone?: string) => {
      if (
        type === AppointmentTypeEnum.VIDEO ||
        type === AppointmentTypeEnum.PHONE
      ) {
        history.push(`/patient/attendance?id=${id}`);
      } else if (text && phone) {
        showModal({
          type: 'custom',
          data: (
            <ShowMessageScheduleModal
              message={{
                text,
                phone,
              }}
            />
          ),
        });
      }
    },
    [history, showModal],
  );

  return (
    <Container gridArea={gridArea}>
      <header>
        <h1>Histórico de Atendimentos</h1>
      </header>
      <div>
        {list.length ? (
          list.map((item) => (
            <React.Fragment key={item.year}>
              <YearTitle>{item.year}</YearTitle>
              {item.months.map((monthItem) => (
                <section key={monthItem.name}>
                  <MonthTitle>{monthItem.name}</MonthTitle>
                  {monthItem.appointments.map((appointmentItem, index) => (
                    <AppointmentSection key={appointmentItem.id}>
                      <DateAppointmentSection>
                        {appointmentItem.day}
                      </DateAppointmentSection>
                      <InformationsAppointmentSection
                        disabled={!canView[appointmentItem.type]}
                        onClick={() =>
                          handleGoToConsult(
                            appointmentItem.id,
                            appointmentItem.type,
                            appointmentItem.message,
                            appointmentItem.contactPhone,
                          )
                        }
                      >
                        <h2>{appointmentItem.title}</h2>
                        {appointmentItem.description &&
                          appointmentItem.description?.length > 0 && (
                            <h4>{appointmentItem.description}</h4>
                          )}
                        <h5>Atendido por:</h5>
                        <h3>{`${appointmentItem.professionalName} - ${appointmentItem.time}h`}</h3>
                      </InformationsAppointmentSection>
                      {monthItem.appointments.length - 1 !== index && (
                        <LineAppointmentSection />
                      )}
                    </AppointmentSection>
                  ))}
                </section>
              ))}
            </React.Fragment>
          ))
        ) : (
          <CardContentNothingToDisplay imageWidth="140px">
            <img src={BlankSketchesImage} alt="" />
            Nenhum atendimento realizado
          </CardContentNothingToDisplay>
        )}
      </div>
    </Container>
  );
};

export default AppointmentHistory;
