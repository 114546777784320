import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Participant as ParticipantType } from 'twilio-video';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  BiMicrophoneOff,
  BiVideoOff,
  BiFullscreen,
  BiExitFullscreen,
} from 'react-icons/bi';

import { toast } from 'react-toastify';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ReactComponent as VideoCameraIcon } from '../../../assets/images/video-camera.svg';
import { ReactComponent as MicrophoneIcon } from '../../../assets/images/microphone.svg';
import { ReactComponent as PhoneDeclineIcon } from '../../../assets/images/phone-decline.svg';
import { ReactComponent as CincoLogo } from '../../../assets/images/cincoLogo1.svg';

import Participant from '../Participant';

import {
  Container,
  Remote,
  Local,
  Controls,
  Button,
  AppointmentStatus,
  ParticipantName,
  SpinnerWrapper,
  AppointmentStatusLabelProps as AppointmentStatusLabelPropsBase,
} from './styles';

import { Room as RoomType } from '..';

import WhiteSpinner from '../../../assets/images/white-spinner.gif';
import { useCurrentEffect } from '../../../lib/use-current-effect';
import useCheckMobileScreen from '../../../hooks/checkMobileScreen';
import { getParticipantsNamesFromAppointment } from '../../../services/appointments';

interface RoomProps {
  room: RoomType;
  participants: ParticipantType[];
  isProfessional?: boolean;
  handleLeaveTheRoom: () => void;
}

interface AppointmentStatusLabelProps extends AppointmentStatusLabelPropsBase {
  text: string;
}

const Room: React.FC<RoomProps> = ({
  room,
  participants,
  handleLeaveTheRoom,
  isProfessional,
}) => {
  const fullScreen = useFullScreenHandle();
  const [micEnabled, setMicEnabled] = useState(true);
  const [participantEntering, setParticipantEntering] = useState(false);
  const [localCameraEnabled, setLocalCameraEnabled] = useState(true);
  const [areControlsVisible, setAreControlsVisible] = useState(true);

  const history = useHistory();

  const { isMobile } = useCheckMobileScreen();

  const [{ patientName, professionalName }, setParticipantsNames] = useState({
    professionalName: '',
    patientName: '',
  });

  const hasRemoteParticipant = useMemo(
    () => participants.length > 0,
    [participants],
  );

  const appointmentStatusLabel = useMemo<AppointmentStatusLabelProps>(
    () =>
      isProfessional || hasRemoteParticipant
        ? {
            status: 'ongoing',
            text: 'Atendimento em andamento',
          }
        : {
            status: 'waitingForProfessional',
            text: 'Aguardando profissional entrar na sala',
          },
    [hasRemoteParticipant, isProfessional],
  );

  useEffect(() => {
    let visibilityTimeout: NodeJS.Timeout | undefined;

    const setVisibilityTimeout = () => {
      setAreControlsVisible(true);
      visibilityTimeout = setTimeout(() => {
        setAreControlsVisible(false);
      }, 3000);
    };

    const clearVisibilityTimeout = () => {
      visibilityTimeout && clearInterval(visibilityTimeout);
    };

    const resetVisibilityTimeout = () => {
      clearVisibilityTimeout();
      setVisibilityTimeout();
    };

    const localVideoElement = document.getElementById('Local');
    const remoteVideoElement = document.getElementById('Remote');

    localVideoElement &&
      localVideoElement.addEventListener('click', resetVisibilityTimeout);
    remoteVideoElement &&
      remoteVideoElement.addEventListener('click', resetVisibilityTimeout);

    setVisibilityTimeout();

    return () => {
      localVideoElement &&
        localVideoElement.removeEventListener('click', resetVisibilityTimeout);
      remoteVideoElement &&
        remoteVideoElement.removeEventListener('click', resetVisibilityTimeout);
      clearVisibilityTimeout();
    };
  }, [participants]);

  const handleEnableDisableMic = useCallback(() => {
    room.localParticipant.audioTracks.forEach((publication) => {
      if (micEnabled) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }

      setMicEnabled((prevState) => !prevState);
    });
  }, [room, micEnabled]);

  const handleEnableDisableCamera = useCallback(() => {
    room.localParticipant.videoTracks.forEach((publication) => {
      if (localCameraEnabled) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }

      setLocalCameraEnabled((prevState) => !prevState);
    });
  }, [room, localCameraEnabled]);

  useEffect(() => {
    if (isMobile) {
      fullScreen.enter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleFullScreen = useCallback(async () => {
    if (fullScreen.active) {
      await fullScreen.exit();
    } else {
      await fullScreen.enter();
    }
  }, [fullScreen]);

  useCurrentEffect(
    (isCurrent) => {
      getParticipantsNamesFromAppointment(room.name)
        .then((newParticipantNames) => {
          if (isCurrent()) {
            setParticipantsNames(newParticipantNames);
          }
        })
        .catch((err) => {
          if (axios.isAxiosError(err) && err.response) {
            toast.error(err.response.data.message);

            history.push('/patient/list');
          }
        });
    },
    [history, room],
  );

  useEffect(() => {
    if (hasRemoteParticipant) {
      setParticipantEntering(true);
      setTimeout(() => setParticipantEntering(false), 1000);
    }

    if (!participants.length) {
      setParticipantEntering(false);
    }
  }, [hasRemoteParticipant, participants]);

  return (
    <FullScreen handle={fullScreen}>
      <Container>
        {hasRemoteParticipant && (
          <Remote
            isProfessional={isProfessional}
            areControlsVisible={areControlsVisible}
            id="Remote"
            fullscreen={fullScreen.active}
          >
            <div>
              <ParticipantName>
                {isProfessional ? patientName : professionalName}
              </ParticipantName>
            </div>

            <Participant
              participantEntering={participantEntering}
              participant={participants[0]}
              type="remote"
            >
              <SpinnerWrapper fullscreen={fullScreen.active}>
                <img src={WhiteSpinner} alt="" />
              </SpinnerWrapper>
            </Participant>
          </Remote>
        )}
        {room.localParticipant && (
          <>
            <Local
              areControlsVisible={areControlsVisible}
              id="Local"
              fullscreen={fullScreen.active}
              hasParticipant={hasRemoteParticipant}
              isProfessional={isProfessional}
            >
              <div>
                <ParticipantName>
                  {!isProfessional ? patientName : professionalName}
                </ParticipantName>
              </div>
              <Participant participant={room.localParticipant} type="local" />
            </Local>

            <div>
              <AppointmentStatus
                hasParticipant={hasRemoteParticipant}
                status={appointmentStatusLabel.status}
              >
                {appointmentStatusLabel.text}
              </AppointmentStatus>

              <CincoLogo />
            </div>

            <Controls
              fullscreen={fullScreen.active}
              visible={areControlsVisible}
            >
              <Button onClick={handleEnableDisableMic}>
                {micEnabled ? <MicrophoneIcon /> : <BiMicrophoneOff />}
              </Button>
              <Button onClick={handleLeaveTheRoom}>
                <PhoneDeclineIcon />
              </Button>
              <Button onClick={handleEnableDisableCamera}>
                {localCameraEnabled ? <VideoCameraIcon /> : <BiVideoOff />}
              </Button>
              <Button onClick={handleToggleFullScreen}>
                {fullScreen.active ? <BiExitFullscreen /> : <BiFullscreen />}
              </Button>
            </Controls>
          </>
        )}
      </Container>
    </FullScreen>
  );
};

export default Room;
