import React, { useCallback, useEffect, useState } from 'react';
import { useModal } from 'react-brave-modal';

import { ModalProps } from '../..';
import { useAppointment } from '../../../../hooks/appointment';
import FilmIcon from '../../../../assets/images/icon_schedule_film.svg';
import PhoneIcon from '../../../../assets/images/icon_schedule_phone.svg';
import MessageIcon from '../../../../assets/images/icon_schedule_message.svg';
import FilmIconWhite from '../../../../assets/images/icon_schedule_film_white.svg';
import PhoneIconWhite from '../../../../assets/images/icon_schedule_phone_white.svg';
import MessageIconWhite from '../../../../assets/images/icon_schedule_message_white.svg';
import ScheduleFormImage from '../../../../assets/images/agenda-form.svg';

import { ButtonsContainer, Button } from '../../styles';
import { Container, Grouper, ButtonGroup, ButtonSelect } from './styles';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';

interface AppointmentTypeProps extends ModalProps {
  isBulkFilter: boolean;
}

const AppointmentType: React.FC<AppointmentTypeProps> = ({
  attendanceType,
  isBulkFilter,
  next,
}) => {
  const { closeModal } = useModal();
  const { appointmentSelected, saveLocal, cleanLocal } = useAppointment();
  const [selected, setSelected] = useState<AppointmentTypeEnum | undefined>(
    attendanceType,
  );

  useEffect(() => {
    if (appointmentSelected?.type) {
      setSelected(appointmentSelected.type);

      cleanLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = useCallback(() => {
    if (next && selected) {
      saveLocal({ type: selected });
      next(selected);
    }
  }, [next, saveLocal, selected]);

  return (
    <>
      <Container>
        <Grouper>
          <h1>Novo agendamento</h1>
          <p>Qual tipo de atendimento você gostaria de agendar?</p>

          <ButtonGroup>
            <ButtonSelect
              selected={selected === AppointmentTypeEnum.VIDEO}
              onClick={() =>
                setSelected(
                  selected === AppointmentTypeEnum.VIDEO
                    ? undefined
                    : AppointmentTypeEnum.VIDEO,
                )
              }
            >
              <div>
                {selected === AppointmentTypeEnum.VIDEO ? (
                  <img src={FilmIconWhite} alt="Ícone de Vídeo" />
                ) : (
                  <img src={FilmIcon} alt="Ícone de Vídeo" />
                )}
              </div>
              Vídeo
            </ButtonSelect>
            <ButtonSelect
              selected={selected === AppointmentTypeEnum.PHONE}
              onClick={() =>
                setSelected(
                  selected === AppointmentTypeEnum.PHONE
                    ? undefined
                    : AppointmentTypeEnum.PHONE,
                )
              }
            >
              <div>
                {selected === AppointmentTypeEnum.PHONE ? (
                  <img src={PhoneIconWhite} alt="Ícone de Ligação" />
                ) : (
                  <img src={PhoneIcon} alt="Ícone de Ligação" />
                )}
              </div>
              Ligação
            </ButtonSelect>

            {!isBulkFilter && (
              <ButtonSelect
                selected={selected === AppointmentTypeEnum.SMS}
                onClick={() =>
                  setSelected(
                    selected === AppointmentTypeEnum.SMS
                      ? undefined
                      : AppointmentTypeEnum.SMS,
                  )
                }
              >
                <div>
                  {selected === AppointmentTypeEnum.SMS ? (
                    <img src={MessageIconWhite} alt="Ícone de Mensagem" />
                  ) : (
                    <img src={MessageIcon} alt="Ícone de Mensagem" />
                  )}
                </div>
                Mensagem
              </ButtonSelect>
            )}
          </ButtonGroup>
        </Grouper>

        <Grouper>
          <img src={ScheduleFormImage} alt="" />
        </Grouper>
      </Container>

      <ButtonsContainer>
        <Button color="primary" lite onClick={() => closeModal()}>
          Cancelar
        </Button>

        <Button color="primary" isDisabled={!selected} onClick={handleNext}>
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default AppointmentType;
