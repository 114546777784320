import { api } from '../api';
import { Icd10Patient } from '../../entities/Icd10Patient';
import { Icd10 } from '../../entities/Icd10';

export const getAdditionalHealthConditionPatient = async (): Promise<
  Icd10[]
> => {
  const { data } = await api.get<Icd10Patient[]>(
    'patients/additionalHealthConditions',
  );

  return data.map(({ icd10, isMainHealthCondition }) => ({
    ...icd10,
    isMainHealthCondition,
  }));
};
