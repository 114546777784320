import styled, { css } from 'styled-components';

interface RoomProps {
  fullscreen: boolean;
  hasParticipant?: boolean;
  areControlsVisible: boolean;
  isProfessional?: boolean;
}

interface ControlsProps {
  visible: boolean;
  fullscreen?: boolean;
}

export interface AppointmentStatusLabelProps {
  status: 'ongoing' | 'finished' | 'waitingForProfessional';
  hasParticipant?: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 3vmin;

  @media only screen and (orientation: portrait) and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const Remote = styled.div<RoomProps>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  > div:first-child {
    width: 100%;
    bottom: 96px;
    position: absolute;
    background-image: -webkit-linear-gradient(
      bottom,
      rgba(0, 0, 0, 0.5),
      transparent
    );

    @media only screen and (orientation: portrait) and (max-width: 550px) {
      bottom: unset;
    }

    @media only screen and (orientation: landscape) and (max-width: 920px) {
      ${({ areControlsVisible }) =>
        !areControlsVisible
          ? css`
              bottom: unset;
            `
          : css`
              bottom: 6em;
            `}
    }

    @media only screen and (orientation: portrait) and (max-width: 920px) {
      bottom: 6em;
    }
  }

  #remoteVideo > video {
    width: ${({ fullscreen, isProfessional }) =>
      fullscreen
        ? '100vw'
        : `${isProfessional ? '640px' : 'min(800px, 75vw)'}`};

    ${({ isProfessional }) =>
      isProfessional &&
      css`
        height: 480px;
      `}

    ${(props) =>
      props.fullscreen &&
      css`
        height: 100vh;
      `}

    ${({ isProfessional, fullscreen }) =>
      !isProfessional &&
      !fullscreen &&
      css`
        @media only screen and (orientation: portrait) and (max-width: 550px) {
          height: 60vh;
        }

        @media only screen and (orientation: landscape) and (max-width: 920px) {
          height: 80vh;
        }

        @media only screen and (max-width: 920px) {
          object-fit: cover;
        }
      `}
  }
`;

export const Local = styled.div<RoomProps>`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  > div:first-child {
    width: 100%;
    bottom: ${({ hasParticipant }) => (hasParticipant ? 0 : '96px')};
    background-image: -webkit-linear-gradient(
      bottom,
      rgba(0, 0, 0, 0.5),
      transparent
    );

    position: absolute;

    @media only screen and (orientation: portrait) and (max-width: 550px) {
      bottom: unset;
    }

    @media only screen and (orientation: landscape) and (max-width: 920px) {
      bottom: ${({ hasParticipant, areControlsVisible }) =>
        hasParticipant ? 0 : `${areControlsVisible ? '6em' : 'unset'}`};
    }

    @media only screen and (orientation: portrait) and (max-width: 920px) {
      bottom: ${({ hasParticipant }) => (!hasParticipant ? '6em' : 0)};
    }
  }

  #localVideo > video {
    width: ${({ fullscreen, isProfessional }) =>
      fullscreen ? '100vw' : `min(${isProfessional ? 640 : 800}px, 75vw)`};

    ${(props) =>
      props.fullscreen &&
      css`
        height: 100vh;
      `}

    @media only screen and (orientation: portrait) and (max-width: 550px) {
      width: ${({ fullscreen }) => (fullscreen ? '100vw' : '80vw')};
      height: ${({ fullscreen }) => (fullscreen ? '100vh' : '60vh')};
      object-fit: cover;
    }

    @media only screen and (orientation: landscape) and (max-width: 920px) {
      width: ${({ fullscreen }) => (fullscreen ? '100vw' : 'unset')};
      height: ${({ fullscreen }) => (fullscreen ? '100vh' : '80vh')};
    }
  }

  ${(props) =>
    props.hasParticipant &&
    css`
      position: absolute;
      right: 8%;
      top: 8%;
      border-radius: 6px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      overflow: hidden;

      #localVideo > video {
        width: min(250px, 30vw);
        object-fit: contain;
        height: min-content;
      }
    `}

  & + div {
    font-size: 0.5em;

    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    top: 8%;
    padding: 0 8%;
    align-items: center;
    pointer-events: none;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 50%;
  height: 56px;
  width: 56px;

  transition: 0.5s;

  &:nth-child(2) {
    background-color: #eb5757;
  }

  svg {
    color: ${(props) => props.theme.colors.white};
    font-size: 24px;
  }

  &:hover {
    background-color: #ea0000;
    border-color: #ea0000;
    opacity: 1;
  }

  @media only screen and (max-width: 920px) {
    font-size: 1em;

    width: 3em;
    height: 3em;

    > svg {
      width: 1em;
    }

    &:nth-child(1) > svg {
      font-size: 1.5em;
    }

    &:nth-child(2) > svg {
      font-size: 1.8em;
    }

    &:nth-child(3) > svg {
      font-size: 1.2em;
    }

    &:nth-child(4) > svg {
      font-size: 1.25em;
    }
  }
`;

export const Controls = styled.div<ControlsProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  z-index: 1;

  background-color: rgba(255, 255, 255, 0.7);

  padding: 20px 0;

  button + button {
    margin-left: 12px;
  }

  @media only screen and (orientation: portrait) and (max-width: 550px) {
    position: ${({ fullscreen }) => (fullscreen ? 'absolute' : 'static')};
    padding: 1em 0;

    width: 100%;

    justify-content: space-evenly;

    button + button {
      margin-left: unset;
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 920px) {
    ${({ visible }) =>
      !visible &&
      css`
        display: none;
      `}

    button + button {
      margin-left: 1.5em;
    }
  }

  @media only screen and (max-width: 920px) {
    padding: 0;
    height: 6em;
  }
`;

export const AppointmentStatus = styled.div<AppointmentStatusLabelProps>`
  font-size: min(14px, 1.85em);

  padding: min(10px, 0.75em);

  ${({ hasParticipant }) =>
    hasParticipant &&
    css`
      max-width: 50%;
    `}

  border-radius: 6px;
  background-color: ${({ status }) => {
    switch (status) {
      case 'ongoing':
        return '#2d9cdb';
      case 'finished':
        return '#DB4C2D';
      case 'waitingForProfessional':
      default:
        return '#858585';
    }
  }};

  font-style: normal;
  font-weight: 600;

  color: #ffffff;

  cursor: default;
  user-select: none;
  pointer-events: none;

  & + svg {
    width: 25%;
    margin-left: 8%;
    pointer-events: none;
  }

  ${({ status }) =>
    status === 'finished' &&
    css`
      position: absolute;
      top: 8%;
      left: 8%;
    `}

  @media only screen and (min-width: 920px) {
    & + svg {
      display: none;
    }
  }
`;

export const ParticipantName = styled.p`
  color: #fff;
  font-size: min(18px, 1.6em);
  font-weight: 600;
  margin: 15px 0 15px 15px;

  @media only screen and (orientation: portrait) and (max-width: 550px) {
    margin: 1em 0 1em 1em;
  }
`;

export const SpinnerWrapper = styled.div<{
  fullscreen: boolean;
  isProfessional?: boolean;
}>`
  width: 640px;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;

  width: ${({ fullscreen, isProfessional }) =>
    fullscreen ? '100vw' : `${isProfessional ? '640px' : 'min(800px, 75vw)'}`};

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      height: 100vh;
    `}

  > img {
    max-width: 18%;
  }
`;
