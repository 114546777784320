import React, { createContext, useCallback, useState, useContext } from 'react';
import { Institution } from '../entities/Institution';
import {
  createInstitution,
  CreateInstitutionResponse,
} from '../services/institution';

interface InstitutionContextData {
  institution?: Institution;
  cleanLocal(): void;
  saveLocal(institutionData: Institution): void;
  save(saveinstitutionData: Institution): Promise<CreateInstitutionResponse>;
}

const InstitutionContext = createContext<InstitutionContextData>(
  {} as InstitutionContextData,
);

export const InstitutionProvider: React.FC = ({ children }) => {
  const [institution, setInstitution] = useState<Institution>();

  const cleanLocal = useCallback(() => {
    setInstitution(undefined);
  }, []);

  const saveLocal = useCallback((institutionData: Institution) => {
    setInstitution((state) => ({ ...state, ...institutionData }));
  }, []);

  const save = useCallback(async (institutionData: Institution) => {
    const response = await createInstitution(institutionData);

    return response;
  }, []);

  return (
    <InstitutionContext.Provider
      value={{
        institution,
        cleanLocal,
        saveLocal,
        save,
      }}
    >
      {children}
    </InstitutionContext.Provider>
  );
};

export const useInstitution = (): InstitutionContextData => {
  const context = useContext(InstitutionContext);

  if (!context) {
    throw new Error('useInstitution must be used within a InstitutionProvider');
  }

  return context;
};
