import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { isToday } from 'date-fns';
import { useCurrentEffect } from '../../../../../../lib/use-current-effect';

import { usePatient } from '../../../../../../hooks/patient';
import { getLastMeasurementsInfo } from '../../../../../../services/metrics';

import MeasurementsArea from '../../MeasurementsArea';
import Img from '../../../../../../assets/images/calorias.svg';

import { ChildrenProps, ChildrenRefProps } from '../..';
import { MetricTypeEnum } from '../../../../../../enums/MetricTypeEnum';

const CalorieChart: React.ForwardRefRenderFunction<
  ChildrenRefProps,
  ChildrenProps
> = ({ dateFilter }, ref) => {
  const { patient } = usePatient();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<string | number>();

  const handleChange = useCallback(
    async (patientId?: string, isCurrent = () => true) => {
      setIsLoading(true);
      if (!patientId) {
        setIsLoading(false);
        return;
      }
      const response = await getLastMeasurementsInfo({
        patientId,
        metrics: [MetricTypeEnum.STEPS],
      });
      const checkMeasurementToday = response.find(
        (item) =>
          item.metricType === MetricTypeEnum.STEPS &&
          isToday(new Date(item.mensuramentDate)),
      );

      if (isCurrent() && checkMeasurementToday) {
        setData(checkMeasurementToday.calories);
      }

      setIsLoading(false);
    },
    [],
  );

  useCurrentEffect(
    (isCurrent) => {
      (() => {
        if (patient?.id) {
          handleChange(patient.id, isCurrent);
        } else {
          setIsLoading(false);
        }
      })();
    },
    [dateFilter, handleChange, patient],
  );

  useImperativeHandle(ref, () => ({
    reload: () => handleChange(patient?.id),
  }));

  return (
    <MeasurementsArea
      data={{
        isLoading,
        styleData: 'info',
        dataDetails: data,
      }}
    >
      <img src={Img} alt="icone de calorias" />

      <div>
        <span>Hoje o paciente gastou</span>

        <h1>{data}</h1>

        <span>calorias</span>
      </div>
    </MeasurementsArea>
  );
};

export default forwardRef(CalorieChart);
