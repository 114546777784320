import styled from 'styled-components';

export const Container = styled.div`
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 14px;
  /* identical to box height, or 75% */

  text-align: center;

  color: #707683;

  > img {
    margin-bottom: 30px;
  }

  > button {
    margin-top: 30px;
    border: 0;
    background: transparent;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #2d9cdb;
  }
`;
