import styled from 'styled-components';

export const ModalElement = styled.div`
  width: 547px;
  height: 387px;
`;

export const ModalGrouper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  height: 100%;
  min-height: 300px;

  padding: 20px;

  h1 {
    width: 350px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    color: ${({ theme }) => theme.colors.primary};

    text-align: center;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 50px;

  button {
    display: flex;
    align-items: center;

    &:first-child {
      margin-right: 16px;
    }
  }
`;
