import React from 'react';
import { format } from 'date-fns';
import { CSSProperties } from 'styled-components';
import { ReactComponent as IconPhoneOutline } from '../../../../../assets/images/icon-timeline-appointmentType-calling-outline-gray.svg';
import { ReactComponent as IconFilmOutline } from '../../../../../assets/images/icon-timeline-appointmentType-video-outline-gray.svg';
import { ReactComponent as IconSMSOutline } from '../../../../../assets/images/icon-timeline-appointmentType-sms-outline-gray.svg';
import {
  ButtonArea,
  Container,
  ContainerEvolution,
  DayTitle,
  DetailsAppointment,
  Header,
  Paragraph,
} from './styles';
import AppointmentTypeEnum from '../../../../../enums/AppointmentTypeEnum';
import Button from '../../../../../components/Button';
import { getAllCallStatusByKey } from '../../../../../utils/getCallStatus';
import { Appointment } from '../../../../../entities/Appointment';
import { useAppointment } from '../../../../../hooks/appointment';

interface AppointmentEventTooltipProps {
  coordMouseX: number;
  coordMouseY: number;
  appointment: Appointment;
  OnHandleGoToConsult: (
    id: string,
    type: AppointmentTypeEnum,
    text?: string,
    phone?: string,
  ) => void;
  style?: CSSProperties;
}

const AppointmentEventTooltip: React.FC<AppointmentEventTooltipProps> = ({
  appointment,
  coordMouseX,
  coordMouseY,
  OnHandleGoToConsult,
  style,
}) => {
  const { appointmentSelected } = useAppointment();
  const callStatus = getAllCallStatusByKey(appointment.details?.callStatus);
  const selected = appointmentSelected?.id === appointment.id;

  const handleClick = () => {
    if (appointment.id && appointment.type) {
      OnHandleGoToConsult(
        appointment.id,
        appointment.type,
        appointment.message?.text,
        appointment.contact_phone ?? '',
      );
    }
  };

  return (
    <Container
      coordMouseX={coordMouseX}
      coordMouseY={coordMouseY}
      style={style}
    >
      <Header>
        <DayTitle>
          {format(new Date(appointment.date ? appointment.date : ''), 'dd')}
        </DayTitle>
        <DetailsAppointment>
          <div>
            <span>
              {appointment.type === AppointmentTypeEnum.PHONE && (
                <IconPhoneOutline />
              )}
              {appointment.type === AppointmentTypeEnum.VIDEO && (
                <IconFilmOutline />
              )}
              {appointment.type === AppointmentTypeEnum.SMS && (
                <IconSMSOutline />
              )}
            </span>
            <h3>{appointment.professional?.user?.name}</h3>
          </div>
          <p>{callStatus}</p>

          <ButtonArea selected={selected}>
            <Button
              disabled={selected}
              title="Ver consulta"
              onClick={handleClick}
            >
              Ver consulta
            </Button>
          </ButtonArea>
        </DetailsAppointment>
      </Header>

      <ContainerEvolution>
        <label>Evolução</label>

        {appointment.details?.anamnese || appointment.message?.text ? (
          <Paragraph>
            {appointment.details?.anamnese || appointment.message?.text}
          </Paragraph>
        ) : (
          <Paragraph>Sem dados para exibir</Paragraph>
        )}
      </ContainerEvolution>
    </Container>
  );
};
export default AppointmentEventTooltip;
