import React, { useState, useMemo, useCallback } from 'react';
import { format } from 'date-fns';
import { useModal } from 'react-brave-modal';
import { MdRemoveRedEye } from 'react-icons/md';

import ContentNothingToDisplay from '../../../../components/ContentNothingToDisplay';
import SheetViewModal from '../../../../modals/SheetViewModal';
import {
  GetSheetPatientsByFilter as ListFilter,
  GetSheetPatientsByFilterResponse as ListData,
} from '../../../../services/sheet';
import { ReactComponent as ArrowUpDownIcon } from '../../../../assets/images/arrowupdown.svg';

import {
  Container,
  TopBar,
  Info,
  TableBox,
  SortButton,
  ContentNothingToDisplayBox,
  StyledLink,
  PendenciesList,
  PendenciesListHeaderRow,
  PendenciesListBodyRow,
  PatientNameCell,
  PendenciesListBodyCell,
  VisualizeButton,
} from './styles';
import { usePermission } from '../../../../hooks/permission';
import OrdenationTypeEnum from '../../../../enums/OrdenationTypeEnum';

interface SheetPatientsList {
  id: string;
  formId: string;
  sheetName: string;
  patientName?: string;
  patientId?: string;
  date: string;
}

interface TableProps {
  setListFilter(listFilter: React.SetStateAction<ListFilter>): void;
  listData: ListData;
}

enum PendentSheetsListOrderByEnum {
  PATIENT_NAME = 'patientName',
  DATE = 'date',
}

const Table: React.FC<TableProps> = ({ setListFilter, listData }) => {
  const { showModal } = useModal();
  const { hasPermission } = usePermission();

  const [orderBy, setOrderBy] = useState(
    PendentSheetsListOrderByEnum.PATIENT_NAME,
  );
  const [isAscending, setIsAscending] = useState(true);

  const can = useMemo(
    () => ({
      viewPatientProfile: hasPermission('patient_profile'),
    }),
    [hasPermission],
  );

  const sheetPatientsList = useMemo<SheetPatientsList[]>(
    () =>
      listData.sheetPatients.map((sheetPatient) => ({
        id: sheetPatient.id,
        formId: sheetPatient.sheet.formId,
        sheetName: sheetPatient.sheet.name,
        patientName: sheetPatient.patient.user?.name,
        patientId: sheetPatient.patientId,
        date: format(new Date(sheetPatient.created_at), "dd'/'MM'/'yyyy'"),
      })),
    [listData],
  );

  const handleOrdenation = useCallback(
    (orderKey: PendentSheetsListOrderByEnum, isInitialOrdering: boolean) => {
      if (isInitialOrdering) {
        setListFilter((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: OrdenationTypeEnum.ASC,
        }));
        setIsAscending(true);
      } else {
        setListFilter((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: isAscending
            ? OrdenationTypeEnum.DESC
            : OrdenationTypeEnum.ASC,
        }));
        setIsAscending((prevState) => !prevState);
      }

      setOrderBy(orderKey);
    },
    [isAscending, setListFilter],
  );

  const handleSheetView = useCallback(
    (formId: string, sheetName: string) => {
      showModal({
        type: 'custom',
        data: <SheetViewModal formId={formId} sheetName={sheetName} />,
      });
    },
    [showModal],
  );

  return (
    <>
      {sheetPatientsList.length ? (
        <Container>
          <TopBar>
            <Info>
              <span>
                Exibindo <strong>{listData.totalSheetPatientsOnPage}</strong>{' '}
                resultados de <strong>{listData.totalSheetPatients}</strong>
              </span>
            </Info>
          </TopBar>
          <TableBox>
            <PendenciesList>
              <header>
                <PendenciesListHeaderRow>
                  <span>
                    Paciente
                    <SortButton
                      type="button"
                      isAscending={isAscending}
                      isActive={
                        orderBy === PendentSheetsListOrderByEnum.PATIENT_NAME
                      }
                      onClick={() =>
                        handleOrdenation(
                          PendentSheetsListOrderByEnum.PATIENT_NAME,
                          orderBy !== PendentSheetsListOrderByEnum.PATIENT_NAME,
                        )
                      }
                    >
                      <ArrowUpDownIcon />
                    </SortButton>
                  </span>
                  <span>
                    Data
                    <SortButton
                      type="button"
                      isAscending={isAscending}
                      isActive={orderBy === PendentSheetsListOrderByEnum.DATE}
                      onClick={() =>
                        handleOrdenation(
                          PendentSheetsListOrderByEnum.DATE,
                          orderBy !== PendentSheetsListOrderByEnum.DATE,
                        )
                      }
                    >
                      <ArrowUpDownIcon />
                    </SortButton>
                  </span>
                </PendenciesListHeaderRow>
              </header>
              <section>
                {sheetPatientsList.map((sheetPatient) => (
                  <PendenciesListBodyRow key={sheetPatient.id}>
                    <PatientNameCell>
                      <StyledLink
                        to={`/patient/profile?id=${sheetPatient.patientId}`}
                        disabled={!can.viewPatientProfile}
                      >
                        {sheetPatient.patientName}
                      </StyledLink>
                    </PatientNameCell>
                    <PendenciesListBodyCell>
                      <span>{sheetPatient.date}</span>
                    </PendenciesListBodyCell>
                    <PendenciesListBodyCell>
                      <VisualizeButton
                        type="button"
                        onClick={() =>
                          handleSheetView(
                            sheetPatient.formId,
                            sheetPatient.sheetName,
                          )
                        }
                      >
                        <MdRemoveRedEye />
                        Visualizar
                      </VisualizeButton>
                    </PendenciesListBodyCell>
                  </PendenciesListBodyRow>
                ))}
              </section>
            </PendenciesList>
          </TableBox>
        </Container>
      ) : (
        <ContentNothingToDisplayBox>
          <ContentNothingToDisplay text="Nenhum dado encontrado" />
        </ContentNothingToDisplayBox>
      )}
    </>
  );
};

export default Table;
