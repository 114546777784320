/* eslint-disable import/no-duplicates */
import React, { useCallback, useMemo, useState } from 'react';

import { ptBR } from 'date-fns/locale';
import {
  differenceInMilliseconds,
  endOfDay,
  format,
  formatDistance,
  startOfDay,
} from 'date-fns';

import { User } from '../../../../../entities/User';
import { Appointment } from '../../../../../entities/Appointment';
import { getAppointmentsByProfessionalBetweenDates } from '../../../../../services/appointments';

import CalendarIcon from '../../../../../assets/images/calendar-ok.svg';
import TimeIcon from '../../../../../assets/images/time.svg';
import WithoutDataImg from '../../../../../assets/images/cloud-sketches.svg';

import Chart from './Chart';
import ButtonFilterRangeDate, {
  DateValueProps,
} from '../../../../../components/ButtonFilterRangeDate';
import Spinner from '../../../../../components/Spinner';

import { Container, Content, FooterItem, WithoutDataChart } from './styles';
import { useCurrentEffect } from '../../../../../lib/use-current-effect';
import AppointmentStatusEnum from '../../../../../enums/AppointmentStatusEnum';

interface AppointmentListProps {
  [date: string]: Appointment[];
}

interface AttendanceMonitoringProps {
  user?: User;
}

const AttendanceMonitoring: React.FC<AttendanceMonitoringProps> = ({
  user,
}) => {
  const [loading, setLoading] = useState(true);
  const [datesSelected, setDatesSelected] = useState<DateValueProps>({
    startDate: new Date(Date.now() - 60000 * 60 * 24 * 6),
    endDate: new Date(),
  });
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const dateRangeText = useMemo(
    () =>
      `de ${format(
        datesSelected.startDate ?? new Date(),
        "dd'/'MM'/'yyyy",
      )} a ${format(datesSelected.endDate ?? new Date(), "dd'/'MM'/'yyyy")}`,
    [datesSelected],
  );

  const appointmentList = useMemo<AppointmentListProps>(() => {
    const list = {} as AppointmentListProps;

    if (!datesSelected.startDate || !datesSelected.endDate) {
      return list;
    }

    let indexDate = datesSelected.startDate;

    while (indexDate <= datesSelected.endDate) {
      const title = format(indexDate, "dd'/'MM");
      list[title] = [];
      indexDate = new Date(
        new Date(indexDate).setDate(indexDate.getDate() + 1),
      );
    }

    appointments.forEach((item) => {
      if (!item.date) {
        return;
      }

      const dateFormat = format(new Date(item.date), "dd'/'MM");

      if (!list[dateFormat]) {
        list[dateFormat] = [];
      }

      list[dateFormat].push(item);
    });

    return list;
  }, [appointments, datesSelected]);

  const listDisplay = useMemo<{ date: string; number: number }[]>(() => {
    const newList = Object.entries(appointmentList).map(([key, item]) => ({
      date: key,
      number: item.length,
    }));

    return newList;
  }, [appointmentList]);

  const averageAttendancePerDay = useMemo(
    () =>
      Math.floor(appointments.length / Object.keys(appointmentList).length) ??
      0,
    [appointments, appointmentList],
  );

  const averageServiceTime = useMemo(() => {
    if (appointments.length) {
      const differenceTime = appointments.map((item) => {
        if (!item.details) {
          return 0;
        }

        const { end, start } = item.details;

        if (!end || !start) {
          return 0;
        }

        return differenceInMilliseconds(new Date(end), new Date(start));
      });

      const distance =
        differenceTime.reduce((total, item) => item + total, 0) /
        appointments.length;

      return formatDistance(0, distance, {
        locale: ptBR,
      });
    }
    return 'Nenhum';
  }, [appointments]);

  const patientsSeen = useMemo(() => {
    const patientList: string[] = [];

    appointments.forEach((appointment) => {
      if (
        appointment.patientId &&
        !patientList.includes(appointment.patientId)
      ) {
        patientList.push(appointment.patientId);
      }
    });

    return patientList.length;
  }, [appointments]);

  const handleUpdateDate = useCallback(
    ({ startDate, endDate }: DateValueProps) => {
      if (startDate && endDate) {
        setDatesSelected({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        });
      }
    },
    [],
  );

  useCurrentEffect(
    (isCurrent) => {
      const { endDate, startDate } = datesSelected;

      if (user?.professional_id) {
        if (endDate && startDate) {
          setLoading(true);
          getAppointmentsByProfessionalBetweenDates({
            professionalId: user?.professional_id ?? '',
            userType: user?.type,
            startDate: startDate
              ? startOfDay(startDate).toISOString()
              : undefined,
            endDate: endDate ? endOfDay(endDate).toISOString() : undefined,
            status: AppointmentStatusEnum.FINISHED,
          })
            .then((response) => {
              if (isCurrent()) {
                setAppointments(response);
              }
            })
            .finally(() => {
              if (isCurrent()) {
                setLoading(false);
              }
            });
        }
      } else if (user && !user.professional_id && isCurrent()) {
        setLoading(false);
      }
    },
    [datesSelected, user],
  );

  return (
    <Container gridArea="am">
      <header>
        <div>
          <h1>Acompanhamento de Atendimentos</h1>
        </div>
        <ButtonFilterRangeDate
          rangeLimit={7}
          onChange={handleUpdateDate}
          dateValue={datesSelected}
          disabled={!user?.professional_id}
        />
      </header>
      <Content>
        <section>
          <h1>{patientsSeen}</h1>
          <h2>
            {patientsSeen === 1 ? 'paciente atendido' : 'pacientes atendidos'}
          </h2>
          <h3>{dateRangeText}</h3>
        </section>
        <section>
          {!loading ? (
            <>
              {appointments.length ? (
                <Chart data={listDisplay} />
              ) : (
                <WithoutDataChart>
                  <img src={WithoutDataImg} alt="cloud-sketches" />
                  <span>Nenhum atendimento encontrado para este período</span>
                </WithoutDataChart>
              )}
            </>
          ) : (
            <Spinner />
          )}
        </section>
      </Content>
      <footer>
        <h1>TOTAL NO PERÍODO:</h1>
        <div>
          <FooterItem>
            <div>
              <img src={CalendarIcon} alt="Ícone de Calendário" />
            </div>
            <div>
              <h1>Média de atendimentos por dia</h1>
              <h2>{averageAttendancePerDay}</h2>
            </div>
          </FooterItem>
          <FooterItem>
            <div>
              <img src={TimeIcon} alt="Ícone de Horário" />
            </div>
            <div>
              <h1>Tempo médio de atendimento</h1>
              <h2>{averageServiceTime}</h2>
            </div>
          </FooterItem>
        </div>
      </footer>
    </Container>
  );
};

export default AttendanceMonitoring;
