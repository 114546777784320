import styled, { css } from 'styled-components';

interface ContainerProps {
  isProfessional?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > video {
    ${({ isProfessional }) =>
      isProfessional
        ? css`
            width: 640px;
          `
        : css`
            width: min(800px, 70vw);

            @media only screen and (orientation: portrait) and (max-width: 550px) {
              width: 80vw;
              object-fit: cover;
              height: 55vh;
            }

            @media only screen and (orientation: landscape) and (max-width: 950px) {
              width: 70vw;
              height: unset;
            }
          `}
  }
`;
