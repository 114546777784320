import styled, { css } from 'styled-components';

interface ButtonProps {
  lite?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  color: 'white' | 'primary';
  loading?: boolean;
}

export const Container = styled.div`
  width: 930px;
  height: 570px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > footer {
    display: flex;
    justify-content: flex-end;
    margin: 50px 0 20px;

    > button + button {
      margin-left: 18px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  z-index: 0 !important;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-right: 45px;
  margin-bottom: 45px;

  button + button {
    margin-left: 20px;
  }
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 16px 0;

  min-width: 130px;

  background: #2d9cdb;

  border-radius: 100px;
  border: 0;
  color: #fff;
  margin-top: 0px !important;
  transition: all 0.6s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #828282;

  &:hover {
    background: ${(props) => props.theme.colors.primary};
  }

  ${(props) =>
    props.color === 'white' &&
    css`
      background: ${props.theme.colors.white};
      color: #828282;

      &:hover {
        color: ${props.theme.colors.white};
      }
    `}

  ${(props) =>
    props.color === 'primary' &&
    css`
      color: #ffffff;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background: #f5f6f8;
      border: 1px solid #bdbdbd;
      color: #bdbdbd;
      cursor: not-allowed;
      transition: none;

      &:hover {
        background: #f5f6f8;
        color: #bdbdbd;
      }
    `}

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}

  ${({ lite }) =>
    lite &&
    css`
      border: 1px solid #2d9cdb;
      color: #2d9cdb;

      background: #fff;

      &:hover {
        color: #fff;
      }
    `}

  & ~ & {
    margin-left: 20px;
  }
`;
