import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface SortButtonProps {
  isAscending: boolean;
  isActive: boolean;
}

interface StyledLinkProps {
  disabled?: boolean;
}

export const Container = styled.div`
  margin-top: 62px;
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
`;

export const Info = styled.div`
  font-weight: 500;

  strong {
    font-weight: 600;
  }

  strong:last-child {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const AnswersList = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  margin-top: 20px;
  padding: 22px 30px;
  width: 100%;
`;

export const AnswersListHeader = styled.header`
  background-color: #eaecf0;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grayTertiary};
  font-size: 13px;
  font-weight: 600;
`;

export const SortButton = styled.button<SortButtonProps>`
  background-color: transparent;
  border: none;
  margin-left: 8px;

  > svg {
    ${(props) =>
      !props.isAscending
        ? css`
            > g > path {
              stroke: ${props.theme.colors.primary};
            }
          `
        : css`
            > path {
              stroke: ${props.theme.colors.primary};
            }
          `}

    ${({ isActive }) =>
      !isActive &&
      css`
        > path,
        > g > path {
          stroke: ${({ theme }) => theme.colors.grayTertiary};
        }
      `}
  }
`;

export const AnswersListBody = styled.section``;

export const AnswersListRow = styled.div`
  display: grid;

  padding: 18px 16px;

  grid-template-columns:
    minmax(255px, 41.6%) minmax(90px, 10%) minmax(255px, 41.6%)
    minmax(60px, 10%);

  > * {
    padding: 0 10px;
  }
`;

export const AnswersListHeaderRow = styled(AnswersListRow)`
  background-color: #eaecf0;

  span {
    display: flex;
    align-items: center;
  }

  font-weight: 600;
`;

export const AnswersListBodyRow = styled(AnswersListRow)`
  padding: 18px 16px;

  color: ${({ theme }) => theme.colors.grayTertiary};
  font-size: 14px;

  & + & {
    border-top: 1px solid #dddddd;
  }
`;

export const AnswersListBodyCell = styled.div`
  display: flex;
  align-items: center;

  &:nth-child(1) {
    font-weight: 600;
    overflow: hidden;
  }

  &:nth-child(1),
  :nth-child(3) {
    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &:nth-child(2),
  &:nth-child(3) {
    font-weight: 500;
  }

  &:nth-child(4) button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;

    svg {
      font-size: 22px;
      margin-bottom: 2px;
    }
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grayTertiary};
  line-height: 18px;
  letter-spacing: 0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-decoration: none;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  transition: color 0.7s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`;

export const ContentNothingToDisplayBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  height: 400px;
  margin-top: 30px;
`;
