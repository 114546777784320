export enum MetricTypeEnum {
  HEART_BEAT_RATE = 'heartBeatRate',
  BLOOD_PREASSURE = 'bloodPressure',
  BODY_TEMPERATURE = 'bodyTemperature',
  BLOOD_OXYGEN = 'bloodOxygen',
  SLEEP = 'sleep',
  STEPS = 'steps',
  CALORIES = 'calories',
  RESPIRATORY_RATE = 'respiratoryRate',
}
