import React, { useMemo } from 'react';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';
import CloudFileGrayImage from '../../../../assets/images/cloud-file-gray.svg';

import ImcImage from '../../../../assets/images/imc.svg';
import AgeGroupImage from '../../../../assets/images/ageGroup.svg';
import GenderImage from '../../../../assets/images/gender.svg';
import PatientXRayImage from '../../../../assets/images/patientxray.svg';

import { CardHeader, CardTitle } from '../styles';
import { Container, XrayImage, Content, Item } from './styles';

export interface PatientsXrayData {
  patientsByImc: {
    overweight: number;
    healthy: number;
    unknown: number;
  }[];
  patientsByGender: {
    gender: 'M' | 'F' | 'ND';
    percentage: number;
  }[];
  ageGroupSixtyToSeventy: number;
  ageGroupOverSeventy: number;
}

interface PatientsXrayProps {
  data: PatientsXrayData;
}

const PatientsXray: React.FC<PatientsXrayProps> = ({ data }) => {
  const displayableImc = useMemo(
    () => Object.entries(data.patientsByImc[0]).sort((a, b) => b[1] - a[1]),
    [data.patientsByImc],
  );

  return (
    <Container gridArea="pxr">
      <CardHeader>
        <CardTitle>Raio-x dos pacientes</CardTitle>
      </CardHeader>
      {data ? (
        <Content>
          <XrayImage src={PatientXRayImage} alt="raio x do paciente" />
          <Item>
            <img src={GenderImage} alt="Gênero" />
            <div>
              <h2>GÊNERO</h2>
              {data.patientsByGender.map((item) => {
                return (
                  <strong>
                    {`${item.percentage}% ${
                      item.gender === 'F'
                        ? 'feminino'
                        : item.gender === 'M'
                        ? 'masculino'
                        : 'não informado'
                    }`}
                  </strong>
                );
              })}
            </div>
          </Item>
          <Item>
            <img src={AgeGroupImage} alt="Faixa Etária" />
            <div>
              <h2>FAIXA ETÁRIA</h2>
              {data.ageGroupOverSeventy > data.ageGroupSixtyToSeventy ? (
                <>
                  <strong>{`${data.ageGroupOverSeventy}% acima de 70 anos`}</strong>
                  <strong>{`${data.ageGroupSixtyToSeventy}% 60 e 70 anos`}</strong>
                </>
              ) : (
                <>
                  <strong>{`${data.ageGroupSixtyToSeventy}% 60 e 70 anos`}</strong>
                  <strong>{`${data.ageGroupOverSeventy}% acima de 70 anos`}</strong>
                </>
              )}
            </div>
          </Item>
          <Item>
            <img src={ImcImage} alt="IMC" />
            <div>
              <h2>IMC</h2>
              {displayableImc.map((item) => (
                <strong>
                  {`${item[1]}% ${
                    item[0] === 'overweight'
                      ? 'a partir de 25'
                      : item[0] === 'healthy'
                      ? 'até 24.9'
                      : 'não informado'
                  }`}
                </strong>
              ))}
            </div>
          </Item>
        </Content>
      ) : (
        <CardContentNothingToDisplay imageWidth="140px">
          <img src={CloudFileGrayImage} alt="" />
          Sem informações cadastradas
        </CardContentNothingToDisplay>
      )}
    </Container>
  );
};

export default PatientsXray;
