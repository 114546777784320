import React, { useState } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import { Container, CalendarElement } from './styles';

interface CalendarDefaultProps {
  onChange: (value: Date) => void;
  defaultDate?: Date | Date[];
  selectRange?: boolean;
}

const CalendarDefault: React.FC<CalendarDefaultProps> = ({
  onChange,
  defaultDate,
  selectRange,
}) => {
  const [maxDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 3)),
  );
  const [day] = useState(['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB']);
  const [month] = useState([
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]);

  return (
    <Container>
      <CalendarElement
        locale="pt-BR"
        view="month"
        minDate={new Date()}
        maxDate={maxDate}
        prevLabel={<FiArrowLeft size={24} />}
        nextLabel={<FiArrowRight size={24} />}
        navigationLabel={({ date }) =>
          `${month[date.getMonth()]}, ${date.getFullYear()}`
        }
        defaultValue={defaultDate ?? new Date()}
        formatShortWeekday={(locale, date) => day[date.getDay()]}
        showFixedNumberOfWeeks
        onClickDay={(value) => onChange(value)}
        selectRange={selectRange}
      />
    </Container>
  );
};

export default CalendarDefault;
