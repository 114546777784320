import styled from 'styled-components';

interface CheckBoxProps {
  isChecked: boolean;
}

export const Container = styled.div`
  min-height: 500px;
  width: 100%;
  padding-right: 52px;

  > h1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;

    color: #707683;
  }
`;

export const Content = styled.div`
  margin-top: 28px;

  display: flex;
  flex-direction: column;

  > p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.01em;

    color: #707683;

    > span {
      color: ${({ theme }) => theme.colors.danger};
      font-size: 16px;
      font-weight: 600;
      line-height: 0.8;
    }
  }

  > button {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;

    max-width: 200px;

    margin-top: 33px;
    padding: 12px 20px;

    background: #2d9cdb;
    color: #ffffff;

    border: 0;
    border-radius: 50px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;

    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #ffffff;

    > svg {
      margin-right: 4px;
    }
  }
`;

export const RiskDegrees = styled.div`
  display: flex;

  margin-top: 15px;
`;

export const HealthConditionList = styled.ul`
  margin-top: 28px;

  li {
    display: flex;
    align-items: center;
    color: #707683;
    font-size: 14px;
    font-weight: 500;
  }

  li + li {
    margin-top: 15px;
  }
`;

export const HealthConditionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  height: 43px;
  min-width: 344px;
  padding: 0 20px 0 12px;

  span {
    margin-left: 8px;
    margin-right: 8px;
    width: 400px;
  }

  button {
    background-color: transparent;
    border: none;

    svg {
      vertical-align: middle;
    }

    & + button {
      margin-left: 20px;
    }
  }
`;

export const CheckBox = styled.button<CheckBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid #d5d5d5;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  width: 22px;
  margin-left: 45px;
  margin-right: 10px;

  span {
    background-color: ${(props) =>
      props.isChecked && props.theme.colors.primary};
    border-radius: 50%;
    height: 14px;
    width: 14px;
  }
`;

export const HealthConditionForm = styled.div`
  background: #f8f8f8;
  border-radius: 6px;

  margin-top: 30px;

  padding-left: 40px;
  padding-bottom: 40px;

  div:first-of-type {
    label:first-of-type {
      margin-top: 30px;
    }
  }
`;

export const Field = styled.div`
  max-width: 500px;

  > label {
    display: inline-block;
    margin-bottom: 8px;

    > span {
      color: ${({ theme }) => theme.colors.danger};
      font-size: 18px;
      font-weight: 600;
      line-height: 0.8;
    }
  }

  > button {
    display: flex;

    align-items: center;

    margin-top: 15px;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;

    color: #2d9cdb;

    background: none;
    border: none;

    text-transform: uppercase;
  }

  > div > textarea {
    max-height: 100px;
  }

  margin-top: 30px;

  &:last-child {
    margin-bottom: 45px;
  }
`;
