import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  visibility: hidden;

  > svg {
    position: absolute;
    bottom: 12px;
    left: 6px;
    width: 20px;
  }

  > span {
    visibility: visible;
  }

  &:hover {
    visibility: visible;
  }
`;

export const Balloon = styled.div`
  position: absolute;
  bottom: 45px;
  left: -70px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  min-height: 62px;
  min-width: 175px;
  padding: 15px 18px;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  min-width: 20px;
`;

export const Title = styled.span`
  color: #707683;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
`;
