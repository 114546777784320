import React, { useMemo, useCallback } from 'react';
import { Form } from '@unform/web';

import { ModalProps } from '../..';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import { useAppointment } from '../../../../hooks/appointment';

import { ButtonsContainer, Button } from '../../styles';
import { Container, Grouper, MessageTable } from './styles';

const SMSModelSet: React.FC<ModalProps> = ({ goTo }) => {
  const { appointmentSelected, saveLocal } = useAppointment();
  const historySMS = useMemo(() => true, []);

  const smsTextModels = useMemo<
    { type: 'reminder' | 'instructional'; text: string }[]
  >(
    () => [
      {
        type: 'reminder',
        text:
          'Obrigada por responder o questionário enviado. Na próxima quinzena, temos um atendimento marcado.',
      },
      {
        type: 'instructional',
        text:
          'Você sabia que a Hipertensão é uma condição em que a força do sangue contra a parede das artérias é muito grande?',
      },
    ],
    [],
  );

  const handleBack = useCallback(() => {
    if (goTo) {
      goTo('SMSTextHistory');
    }
  }, [goTo]);

  const handleSelectTextModel = useCallback(
    (smsTextModelIndex: number) => {
      saveLocal({
        message: {
          ...appointmentSelected?.message,
          text: smsTextModels[smsTextModelIndex].text,
        },
      });

      handleBack();
    },
    [appointmentSelected, handleBack, saveLocal, smsTextModels],
  );

  return (
    <>
      <Container>
        <Grouper>
          <h1>Enviar Mensagem</h1>
          <p>Escolha um modelo padrão para usar em sua mensagem</p>
          <section>
            <strong>Filtrar por Tipo: </strong>
            <Form onSubmit={() => {}}>
              <InputSelectAlternative name="type" placeholder="Selecionar" />
            </Form>
          </section>

          {!!historySMS && (
            <section>
              <MessageTable>
                <header>
                  <h3>Tipo</h3>
                  <h3>Mensagem</h3>
                </header>

                <div>
                  {/* <section>
                    <div>
                      <span>Lembrete</span>
                    </div>
                    <div>
                      <p>{smsTextModels[0]}</p>
                    </div>
                    <div>
                      <button type="button">Selecionar</button>
                    </div>
                  </section>
                  <section>
                    <div>
                      <span>Educativo</span>
                    </div>
                    <div>
                      <p>{smsTextModels[1]}</p>
                    </div>
                    <div>
                      <button type="button">Selecionar</button>
                    </div>
                  </section> */}
                  {smsTextModels.map((model, index) => (
                    <section>
                      <div>
                        <span>
                          {model.type === 'reminder' ? 'Lembrete' : 'Educativo'}
                        </span>
                      </div>
                      <div>
                        <p>{model.text}</p>
                      </div>
                      <div>
                        <button
                          onClick={() => handleSelectTextModel(index)}
                          type="button"
                        >
                          Selecionar
                        </button>
                      </div>
                    </section>
                  ))}
                </div>
              </MessageTable>
            </section>
          )}
        </Grouper>
      </Container>

      <ButtonsContainer>
        <Button color="primary" lite onClick={handleBack}>
          Voltar
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default SMSModelSet;
