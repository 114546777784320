import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import InputMaskAlternative from '../../../../../components/InputMaskAlternative';

import { Field } from '../styles';

import { GridForm } from './styles';

interface AdditionalDataProps {
  weight?: number;
  height?: number;
}

export interface AdditionalDataRef {
  getValues(): AdditionalDataProps;
}

const AdditionalData: React.ForwardRefRenderFunction<
  AdditionalDataRef,
  AdditionalDataProps
> = ({ height, weight }, ref) => {
  const formRef = useRef<FormHandles>(null);

  const [inputMaskWeight, setInputMaskWeight] = useState(
    `9,99${`9`.repeat(50)}`,
  );
  const [inputMaskHeight, setInputMaskHeight] = useState(
    `9,99${`9`.repeat(50)}`,
  );

  useEffect(() => {
    formRef.current?.setData({
      weight: weight?.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      height: height?.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    } as AdditionalDataProps);

    if (weight) {
      const newInputMaskWeight =
        weight
          ?.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace(/\d/g, '9') ?? '';

      setInputMaskWeight(`${newInputMaskWeight}${`9`.repeat(50)}`);
    }

    if (height) {
      const newInputMaskHeight =
        height
          ?.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace(/\d/g, '9') ?? '';

      setInputMaskHeight(`${newInputMaskHeight}${`9`.repeat(50)}`);
    }
  }, [weight, height]);

  const handleChangeInputMask = (value: string, callback: any) => {
    if (value.length > 4) {
      callback(`${`9`.repeat(value.length - 3)},999`);
      return;
    }

    callback(`9,99${`9`.repeat(50)}`);
  };

  const getValues = () => {
    const additionalData: AdditionalDataProps = {
      ...formRef.current?.getData(),
    };

    if (additionalData.weight) {
      const weightData =
        additionalData.weight.toString().replace(',', '.') ?? '';

      additionalData.weight = parseFloat(weightData);
    }

    if (additionalData.height) {
      const heightData =
        additionalData.height.toString().replace(',', '.') ?? '';

      additionalData.height = parseFloat(heightData);
    }

    return additionalData;
  };

  useImperativeHandle(ref, () => ({
    getValues,
  }));

  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <h1>Dados Complementares</h1>

      <GridForm>
        <Field gridArea="pe">
          <label>Peso (KG)</label>
          <InputMaskAlternative
            mask={inputMaskWeight}
            onChange={(e) =>
              handleChangeInputMask(e.target.value, setInputMaskWeight)
            }
            title=""
            placeholder="0,00"
            type="text"
            name="weight"
          />
        </Field>
        <Field gridArea="al">
          <label>Altura (Metros)</label>
          <InputMaskAlternative
            mask={inputMaskHeight}
            onChange={(e) =>
              handleChangeInputMask(e.target.value, setInputMaskHeight)
            }
            title=""
            placeholder="0,00"
            type="text"
            name="height"
          />
        </Field>
      </GridForm>
    </Form>
  );
};

export default forwardRef(AdditionalData);
