import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  label {
    font-style: normal;
    font-weight: normal;
    padding-top: 4px;
  }
`;
