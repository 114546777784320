/* eslint-disable import/no-duplicates */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FaRegCalendar } from 'react-icons/fa';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { OptionsType } from '..';

import Button from '../../../../../../../components/Button';

import {
  Container,
  SelectDateRange,
  CalendarBox,
  RangeOptions,
} from './styles';

interface DateFilterProps {
  options: OptionsType[];
  dateRange: OptionsType;
  updateDateRange: React.Dispatch<React.SetStateAction<OptionsType>>;
}

const DateFilter: React.FC<DateFilterProps> = ({
  options,
  dateRange,
  updateDateRange,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [optionSelected, setOptionSelected] = useState<OptionsType>(dateRange);
  const [showSelectedDateRage, setShowSelectedDateRage] = useState(false);

  const handleOnClickListener = useCallback((event: MouseEvent) => {
    const withinBoundaries = event
      .composedPath()
      .includes(containerRef.current as Element);

    if (!withinBoundaries) {
      setShowSelectedDateRage(false);
    }
  }, []);

  useEffect(() => {
    if (containerRef) {
      document.addEventListener('click', handleOnClickListener);
    }

    return () => {
      document.removeEventListener('click', handleOnClickListener);
    };
  }, [handleOnClickListener]);

  const handleConfirm = () => {
    updateDateRange(optionSelected);
    setShowSelectedDateRage(false);
  };

  return (
    <Container ref={containerRef}>
      <button
        type="button"
        onClick={() => setShowSelectedDateRage((state) => !state)}
      >
        <span>{dateRange.title}</span>

        <div>
          <FaRegCalendar size={15} />
        </div>
      </button>

      {showSelectedDateRage && (
        <SelectDateRange>
          <CalendarBox>
            <DateRange
              weekStartsOn={0}
              locale={locales.pt}
              ranges={[optionSelected.rangeDate]}
              maxDate={new Date()}
              showMonthArrow={false}
            />
          </CalendarBox>

          <RangeOptions>
            <span>Filtrar por Período:</span>

            <div>
              {options.map((item) => (
                <button
                  type="button"
                  key={item.key}
                  disabled={item.key === optionSelected.key}
                  onClick={() => setOptionSelected(item)}
                >
                  {item.title}
                </button>
              ))}
            </div>

            <Button type="button" buttonType="primary" onClick={handleConfirm}>
              Confirmar
            </Button>
          </RangeOptions>
        </SelectDateRange>
      )}
    </Container>
  );
};

export default DateFilter;
