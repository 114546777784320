import React, { useMemo } from 'react';
import { CardHeader, CardTitle } from '../styles';
import {
  Container,
  Content,
  RankContainer,
  LocationPercentage,
  BrasilImageContainer,
} from './styles';
import BrasilImage from '../../../../assets/images/brasil.svg';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';
import CloudFileGrayImage from '../../../../assets/images/cloud-file-gray.svg';

interface PatientsLocationData {
  uf: string;
  percentage: number;
}

interface PatientsLocationProps {
  data: PatientsLocationData[];
}

const PatientsLocation: React.FC<PatientsLocationProps> = ({ data }) => {
  const otherGroup = useMemo(
    () =>
      data.reduce((accum, curr, index) => ({
        uf: 'Outros',
        percentage: index > 2 ? accum.percentage + curr.percentage : 0,
      })),
    [data],
  );

  const displayableData = useMemo(
    () => [data[0], data[1], data[2], otherGroup],
    [data, otherGroup],
  );

  return (
    <Container gridArea="pl">
      <CardHeader>
        <CardTitle>Localização dos pacientes</CardTitle>
      </CardHeader>
      {data.length > 0 ? (
        <Content>
          <RankContainer>
            {displayableData.map((item) => (
              <LocationPercentage barWidth={item.percentage} key={item.uf}>
                <div />
                <span>
                  {item.percentage}% - {item.uf}
                </span>
              </LocationPercentage>
            ))}
          </RankContainer>
          <BrasilImageContainer>
            <img src={BrasilImage} alt="Brasil" />
          </BrasilImageContainer>
        </Content>
      ) : (
        <CardContentNothingToDisplay imageWidth="140px">
          <img src={CloudFileGrayImage} alt="" />
          Sem informações cadastradas
        </CardContentNothingToDisplay>
      )}
    </Container>
  );
};

export default PatientsLocation;
