import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useModal } from 'react-brave-modal';
import { FiTrash } from 'react-icons/fi';

import { ModalProps, BulkList, HandleBulkList } from '../..';
import InputSwitchWithoutForm from '../../../../components/InputSwitchWithoutForm';
import InputSuggestUser, {
  InputSuggestRef,
} from '../../../../components/InputSuggestUser';
import { Appointment } from '../../../../entities/Appointment';
import { useAppointment } from '../../../../hooks/appointment';

import { ReactComponent as FilmIcon } from '../../../../assets/images/icon_schedule_film.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/images/icon_schedule_phone.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/images/icon_schedule_message.svg';
import { ReactComponent as PeopleIcon } from '../../../../assets/images/people.svg';
import { ReactComponent as WomanSearchImage } from '../../../../assets/images/woman-search.svg';
import { ReactComponent as NurseDoctorImage } from '../../../../assets/images/nurse-doctor.svg';

import { ButtonsContainer, Button } from '../../styles';
import {
  Container,
  Grouper,
  OptionsGrouper,
  Option,
  InputSwitchBox,
  BulkBox,
  List,
} from './styles';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';

interface ScheduleContactProps extends ModalProps {
  isBulk: boolean;
  isBulkFilter: boolean;
  bulkList?: BulkList[];
  handleBulkList?: (data: HandleBulkList) => void;
  handleType?: () => void;
}

const ScheduleContact: React.FC<ScheduleContactProps> = ({
  flow,
  userType,
  isBulk,
  bulkList,
  isBulkFilter,
  handleBulkList,
  handleType,
  goTo,
  next,
  back,
  edit,
}) => {
  const { closeModal } = useModal();
  const { appointmentSelected, saveLocal } = useAppointment();
  const formRef = useRef<FormHandles>(null);
  const inputSuggestRef = useRef<InputSuggestRef>(null);
  const [optionFilter, setOptionFilter] = useState<'name' | 'cpf' | 'email'>(
    'name',
  );
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (isBulk) {
      inputSuggestRef.current?.clearInput();

      if (bulkList?.length) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [isBulk, bulkList]);

  const handleDisable = useCallback(
    (state: boolean) => {
      if (bulkList?.length) {
        setIsDisabled(false);
      } else {
        setIsDisabled(state);
      }
    },
    [bulkList],
  );

  const handleNext = useCallback(() => {
    if (isBulk && bulkList?.length && next) {
      next();
    }

    const user = inputSuggestRef.current?.getSelectedUser();

    if (!user) {
      return;
    }

    const newAppointmentData = {} as Appointment;

    if (user?.type === 'patient') {
      if (appointmentSelected?.type === AppointmentTypeEnum.SMS) {
        newAppointmentData.contact_phone = user.phone;
      }

      newAppointmentData.patientId = user.patient_id;
      newAppointmentData.patient = {
        ...user.patient,
        user: { ...user, patient: undefined },
      };
    }

    if (user?.type === 'professional') {
      newAppointmentData.professionalId = user.professional_id;
      newAppointmentData.professional = {
        ...user.professional,
        user: { ...user, professional: undefined },
      };
    }

    saveLocal(newAppointmentData);
    inputSuggestRef.current?.clearInput();

    if (flow === 'create') {
      if (isBulkFilter && goTo) {
        goTo('ScheduleAppointment');
      } else if (next) {
        next();
      }
    } else if (edit) {
      edit();
    }
  }, [
    appointmentSelected,
    flow,
    isBulk,
    isBulkFilter,
    bulkList,
    goTo,
    next,
    edit,
    saveLocal,
  ]);

  const handleBack = useCallback(() => {
    inputSuggestRef.current?.clearInput();

    if (flow === 'create') {
      if (back) {
        back();
      }
    } else if (edit) {
      edit();
    }
  }, [back, edit, flow]);

  const inputSuggestPreviousData = useMemo(() => {
    if (appointmentSelected) {
      if (appointmentSelected.patient && userType === 'patient') {
        return { id: appointmentSelected.patient.user?.id };
      }

      if (appointmentSelected.professional && userType === 'professional') {
        return { id: appointmentSelected.professional.user?.id };
      }
    }

    return { id: undefined };
  }, [appointmentSelected, userType]);

  const canDisplaySwitch = useMemo(
    () =>
      appointmentSelected?.type !== AppointmentTypeEnum.SMS &&
      flow === 'create' &&
      userType === 'patient',
    [appointmentSelected, flow, userType],
  );

  const handleSwitch = useCallback(() => {
    if (handleType) {
      handleType();
      setIsDisabled(true);
    }
  }, [handleType]);

  return (
    <>
      <Container>
        <Grouper>
          <h1>
            {flow === 'create'
              ? `Agendar ${appointmentSelected?.type} `
              : `Editar ${appointmentSelected?.type} `}

            {appointmentSelected?.type === AppointmentTypeEnum.PHONE && (
              <PhoneIcon />
            )}
            {appointmentSelected?.type === AppointmentTypeEnum.SMS && (
              <MessageIcon />
            )}
            {appointmentSelected?.type === AppointmentTypeEnum.VIDEO && (
              <FilmIcon />
            )}

            {isBulk && <span>(Em massa)</span>}
          </h1>

          {userType === 'patient' ? (
            <>
              <p>
                Para {isBulk ? 'quais pacientes' : 'qual paciente'} o
                atendimento será realizado?
              </p>
              <strong>
                Utilize um dos campos abaixo para buscar{' '}
                {isBulk ? 'os pacientes' : 'o paciente'}:
              </strong>
            </>
          ) : (
            <>
              <p>Qual profissional irá realizar o atendimento?</p>
              <strong>
                Utilize um dos campos abaixo para buscar o profissional:
              </strong>
            </>
          )}

          {canDisplaySwitch && (
            <InputSwitchBox isBulk={isBulk}>
              <InputSwitchWithoutForm
                defaultValue={isBulk}
                change={handleSwitch}
                disabled={isBulkFilter}
              />
              <label>Agendamento em massa</label>
              <PeopleIcon />
            </InputSwitchBox>
          )}

          <OptionsGrouper>
            <Option
              selected={optionFilter === 'name'}
              onClick={() => setOptionFilter('name')}
              type="button"
            >
              Nome
            </Option>
            <Option
              selected={optionFilter === 'cpf'}
              onClick={() => setOptionFilter('cpf')}
              type="button"
            >
              CPF
            </Option>
            <Option
              selected={optionFilter === 'email'}
              onClick={() => setOptionFilter('email')}
              type="button"
            >
              E-mail
            </Option>
          </OptionsGrouper>

          <Form ref={formRef} onSubmit={() => {}}>
            <InputSuggestUser
              ref={inputSuggestRef}
              onChangeUser={() => setOptionFilter('name')}
              handleBulkList={handleBulkList}
              setIsDisabled={handleDisable}
              type={userType as 'patient' | 'professional'}
              flow={flow}
              optionFilter={optionFilter}
              previousData={inputSuggestPreviousData}
            />
          </Form>
        </Grouper>

        <Grouper>
          {isBulk && bulkList?.length ? (
            <BulkBox>
              <div>
                <PeopleIcon />
                <h1>
                  Agendando para <strong>{bulkList.length}</strong>{' '}
                  {bulkList.length === 1 ? 'pessoa' : 'pessoas'}
                </h1>
              </div>

              <List>
                <div>
                  <span>
                    {bulkList.length === 1 ? 'Paciente' : 'Pacientes'}
                  </span>
                </div>

                <div>
                  {bulkList.map((patient) => (
                    <div key={patient.id}>
                      <img
                        src={patient.avatar}
                        alt={`Foto de ${patient.name}`}
                      />
                      <span>{patient.name}</span>
                      <button
                        type="button"
                        onClick={() =>
                          handleBulkList &&
                          handleBulkList({ patientId: patient.id })
                        }
                      >
                        <FiTrash />
                      </button>
                    </div>
                  ))}
                </div>
              </List>
            </BulkBox>
          ) : (
            <>
              {userType === 'patient' ? (
                <WomanSearchImage />
              ) : (
                <NurseDoctorImage />
              )}
            </>
          )}
        </Grouper>
      </Container>

      <ButtonsContainer>
        <Button color="white" onClick={() => closeModal()}>
          Cancelar
        </Button>

        <Button color="primary" lite onClick={handleBack}>
          Voltar
        </Button>

        <Button color="primary" isDisabled={isDisabled} onClick={handleNext}>
          {flow === 'create' ? 'Próximo' : 'Salvar'}
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default ScheduleContact;
