import React, { createContext, useCallback, useContext, useState } from 'react';

import { CreatePatientDTO } from '../dtos/CreatePatientDTO';
import { UpdatePatientDTO } from '../dtos/UpdatePatientDTO';
import CreateUserStatusDTO from '../dtos/CreateUserStatusDTO';
import { Patient } from '../entities/Patient';
import {
  getAvatarImage as getUserAvatarImage,
  createUserStatus,
} from '../services/user';
import {
  getCurrentPatientMeasurementNotice,
  patientMeasurementNoticeUpdate,
} from '../services/patientMeasurementNotice';
import PatientMeasurementNoticeStatusEnum from '../enums/PatientMeasurementNoticeStatusEnum';
import RiskDegreeEnum from '../enums/RiskDegreeEnum';
import { getPatient } from '../services/patient/GetPatient';
import {
  updatePatient as updatePatientService,
  getPatientsByProfessional,
  createPatient as createPatientService,
  addRiskDegree as addRiskDegreeService,
  associateSheet as associateSheetService,
  sendSheet as sendSheetService,
  editHealthConditionService,
  addHealthConditionService,
  removeHealthConditionService,
} from '../services/patient';
import { SendSheetRequestDTO } from '../dtos/SendSheetRequestDTO';
import { AddHealthConditionPatientDTO } from '../dtos/AddHealthConditionPatientDTO';
import { EditHealthConditionPatientDTO } from '../dtos/EditHealthConditionPatientDTO';

interface PatientContextData {
  patient?: UpdatePatientDTO;
  patientsByProfessional: Patient[];
  sync(id?: string): Promise<Patient | undefined>;
  syncByProfessional(professionalId: string): Promise<void>;
  createPatient(data: CreatePatientDTO): Promise<void>;
  updatePatient(data: UpdatePatientDTO): Promise<void>;
  associateSheet(sheetId: string): Promise<void>;
  sendSheet(data: SendSheetRequestDTO): Promise<void>;
  addRiskDegree(data: { riskDegree: string }): Promise<void>;
  addHealthCondition(data: AddHealthConditionPatientDTO): Promise<void>;
  editHealthCondition(data: EditHealthConditionPatientDTO): Promise<void>;
  removeHealthCondition(icd10PatientId: string): Promise<void>;
  createStatus(data: CreateUserStatusDTO): Promise<boolean>;
  saveLocal(patientData: UpdatePatientDTO): void;
  checkPatientWaitingForAttendance(): Promise<boolean>;
  editMeasurementNoticeStatus(): Promise<void>;
  clearLocal(): void;
}

const PatientContext = createContext<PatientContextData>(
  {} as PatientContextData,
);

export const PatientProvider: React.FC = ({ children }) => {
  const [patientsByProfessional, setPatientsByProfessional] = useState<
    Patient[]
  >([]);
  const [patient, setPatient] = useState<UpdatePatientDTO>();

  const getAvatarImage = useCallback(
    async (avatar: string, userId?: string) =>
      userId !== patient?.user?.id
        ? getUserAvatarImage(avatar)
        : patient?.user?.avatar,
    [patient],
  );

  const sync = useCallback(
    async (id?: string): Promise<Patient | undefined> => {
      let data: Patient | undefined;

      if (id) {
        data = await getPatient(id);
      } else if (patient && patient.id) {
        data = await getPatient(patient.id);
      }

      if (data?.user && data.user.avatar) {
        data.user.avatar = await getAvatarImage(data.user.avatar, data.id);
      }

      setPatient(data);

      return data;
    },
    [getAvatarImage, patient],
  );

  const syncByProfessional = useCallback(
    async (professionalId: string): Promise<void> => {
      const data = await getPatientsByProfessional(professionalId);

      data.forEach(async (patientData, index) => {
        data[index] = {
          ...patientData,
          user: {
            ...patientData.user,
            avatar: await getAvatarImage(
              patientData.user?.avatar ?? '',
              patientData.id,
            ),
          },
        };
      });

      setPatientsByProfessional(data);
    },
    [getAvatarImage],
  );

  const createPatient = useCallback(
    async (dataCreate: CreatePatientDTO) => {
      const newPatient = await createPatientService(dataCreate);

      await sync(newPatient.patient_id);
    },
    [sync],
  );

  const updatePatient = useCallback(
    async (dataUpdate: UpdatePatientDTO) => {
      const patientData = { ...patient, ...dataUpdate };

      const updatedPatient = await updatePatientService(patientData);

      await sync(updatedPatient.id);
    },
    [patient, sync],
  );

  const saveLocal = useCallback((patientData: UpdatePatientDTO) => {
    setPatient((state) =>
      state ? { ...state, ...patientData } : { ...patientData },
    );
  }, []);

  const createStatus = useCallback(async (status: CreateUserStatusDTO) => {
    const newUserStatus = await createUserStatus(status);

    return !!newUserStatus;
  }, []);

  const addRiskDegree = useCallback(
    async (data: { riskDegree: RiskDegreeEnum }) => {
      const { riskDegree } = data;

      if (patient && patient.id) {
        const addRiskDegreePatient = await addRiskDegreeService({
          patientId: patient?.id,
          riskDegree,
        });

        setPatient(addRiskDegreePatient);
      }
    },
    [patient],
  );

  const addHealthCondition = useCallback(
    async (data: AddHealthConditionPatientDTO) => {
      if (patient && patient.id) {
        const addHealthConditionPatient = await addHealthConditionService({
          ...data,
          patientId: patient?.id,
        });
        setPatient(addHealthConditionPatient);
      }
    },
    [patient],
  );

  const editHealthCondition = useCallback(
    async (data: EditHealthConditionPatientDTO) => {
      if (patient && patient.id) {
        const editHealthConditionPatient = await editHealthConditionService({
          ...data,
          patientId: patient?.id,
        });
        setPatient(editHealthConditionPatient);
      }
    },
    [patient],
  );

  const removeHealthCondition = useCallback(
    async (icd10PatientId: string) => {
      await removeHealthConditionService({
        icd10PatientId,
      });
      await sync();
    },
    [sync],
  );

  const associateSheet = useCallback(
    async (sheetId: string) => {
      if (patient && patient.id) {
        const newAssociateSheetPatient = await associateSheetService({
          sheetId,
          patientId: patient?.id,
        });

        setPatient(newAssociateSheetPatient);
      }
    },
    [patient],
  );

  const sendSheet = useCallback(async (data: SendSheetRequestDTO) => {
    const result = await sendSheetService(data);

    setPatient(result);
  }, []);

  const checkPatientWaitingForAttendance =
    useCallback(async (): Promise<boolean> => {
      if (patient?.id) {
        const currentMeasurementNotice =
          await getCurrentPatientMeasurementNotice(patient.id);

        if (currentMeasurementNotice) {
          if (
            currentMeasurementNotice.status !==
              patient.currentMeasurementNotice?.status ||
            currentMeasurementNotice.id !== patient.currentMeasurementNoticeId
          ) {
            setPatient((state) => ({
              ...state,
              currentMeasurementNotice,
              currentMeasurementNoticeId: currentMeasurementNotice.id,
            }));
          }

          if (
            currentMeasurementNotice.status ===
            PatientMeasurementNoticeStatusEnum.WAITING_FOR_ATTENDANCE
          ) {
            return true;
          }
        }

        return false;
      }
      return false;
    }, [patient]);

  const editMeasurementNoticeStatus = useCallback(async () => {
    if (patient && patient.id) {
      const currentMeasurementNotice = await patientMeasurementNoticeUpdate(
        patient.id,
      );
      if (
        currentMeasurementNotice &&
        (currentMeasurementNotice.status !==
          patient.currentMeasurementNotice?.status ||
          currentMeasurementNotice.id !== patient.currentMeasurementNoticeId)
      ) {
        setPatient((state) => ({
          ...state,
          currentMeasurementNotice,
          currentMeasurementNoticeId: currentMeasurementNotice.id,
        }));
      }
    }
  }, [patient]);

  const clearLocal = useCallback(() => {
    setPatient(undefined);
  }, []);

  return (
    <PatientContext.Provider
      value={{
        patient,
        patientsByProfessional,
        sync,
        syncByProfessional,
        createPatient,
        updatePatient,
        associateSheet,
        sendSheet,
        addRiskDegree,
        addHealthCondition,
        editHealthCondition,
        removeHealthCondition,
        createStatus,
        checkPatientWaitingForAttendance,
        editMeasurementNoticeStatus,
        saveLocal,
        clearLocal,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export const usePatient = (): PatientContextData => {
  const context = useContext(PatientContext);

  if (!context) {
    throw new Error('usePatient must be used within a PatientProvider');
  }

  return context;
};
