import React, { useCallback } from 'react';
import { useModal } from 'react-brave-modal';
import { useHistory } from 'react-router-dom';
import { BulkList } from '../../../../../components/InputSuggestUser';

import { Container, PatientsList, PatientsItem } from './styles';
import Warning from '../../../../../assets/images/warning.svg';
import Danger from '../../../../../assets/images/danger.svg';
import ButtonCopy from '../../../../../components/ButtonCopy';
import Button from '../../../../../components/Button';

interface BoundPatientsProps {
  patients: BulkList[];
}

const BoundPatientsModal: React.FC<BoundPatientsProps> = ({ patients }) => {
  const { closeModal } = useModal();
  const history = useHistory();
  const patientsDataCopy = patients.map(
    (patient) => `${patient.name} - ${patient.cpf}`,
  );

  const handleClose = useCallback(() => {
    history.push('/institution/list');
    closeModal();
  }, [closeModal, history]);
  return (
    <Container>
      <img src={Warning} alt="aviso" />
      <h2>Atenção: Um ou mais pacientes não foram vinculados à instituição</h2>
      <p>Alguns pacientes já possuem vínculo com outra instituição:</p>
      <PatientsList>
        <ButtonCopy value={patientsDataCopy.toString()} />
        <strong>Nome - CPF</strong>
        {patients.map((patient) => (
          <PatientsItem key={patient.id}>
            <img src={Danger} alt="aviso" />
            <span>{`${patient.name} - ${patient.cpf}`}</span>
          </PatientsItem>
        ))}
      </PatientsList>
      <p>
        Para revindicar o vínculo desses pacientes, por favor, envie uma
        solicitação para <strong>suporte@saudecinco.com</strong>
      </p>
      <Button buttonType="primary" onClick={handleClose}>
        Entendi
      </Button>
    </Container>
  );
};

export default BoundPatientsModal;
