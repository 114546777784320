import styled from 'styled-components';

import { Container as GridCardContainer } from '../../../../components/GridCard/styles';

export const Container = styled(GridCardContainer)`
  max-height: 300px;
  height: 100%;
  padding: 30px 0 15px 25px;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  > button {
    margin-right: 25px;
  }
`;

export const CardContent = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-bottom: 30px;
  padding-right: 24px;

  > div {
    padding-top: 13px;

    svg {
      margin-bottom: 30px;
    }
  }
`;

export const SpinnerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SeeAllButton = styled.button`
  display: flex;
  background-color: transparent;
  border: 0px;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  padding: 5px 8px;
  margin: 35px auto;
  margin-bottom: 0;
`;
