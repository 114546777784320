import { communicationService } from '../api';

interface GetToken {
  roomName: string;
  identity: string;
}

interface GetTokenResponse {
  token: string;
}

export const getToken = async ({ roomName, identity }: GetToken) => {
  const { data } = await communicationService.get<GetTokenResponse>(
    '/videoToken',
    { params: { roomName, identity } },
  );

  return data.token;
};
