import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface StyledLinkProps {
  disabled?: boolean;
}

interface SortButtonProps {
  isAscending: boolean;
  isActive: boolean;
}

interface DegreeBoxProps {
  color: string;
}

interface StatusTagProps {
  background: string;
  color: string;
}

export const Container = styled.div`
  margin-top: 60px;
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 40px;

  > span {
    margin-right: 20px;
  }
`;

export const Info = styled.div`
  font-weight: 500;

  strong {
    font-weight: 600;
  }

  strong:last-child {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const AttendanceList = styled.div`
  border-collapse: collapse;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  margin-top: 20px;
  width: 100%;

  header {
    background-color: #eaecf0;
    border-radius: 4px;
    color: #707683;
    font-size: 13px;
    font-weight: 600;
  }
`;

export const AttendanceListRow = styled.div`
  display: grid;

  padding: 20px 16px;

  grid-template-columns:
    minmax(30px, 3%)
    minmax(200px, 19.5%) minmax(130px, 9%) minmax(85px, 8%) minmax(125px, 8%) minmax(
      95px,
      7%
    )
    minmax(200px, 19.5%) minmax(130px, 10%) minmax(110px, 10%) minmax(100px, 6%);

  > * {
    padding: 0 10px;
  }
`;

export const TableStyledCell = styled.div`
  display: flex;
  align-items: center;
`;

export const AttendanceListSectionRow = styled(AttendanceListRow)`
  background-color: ${({ theme }) => theme.colors.white};

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.gray6};
  }
`;

export const AttendanceListSectionCell = styled(TableStyledCell)`
  color: #707683;

  &:nth-child(3),
  &:nth-child(7),
  &:nth-child(8),
  &:nth-child(9) {
    font-size: 14px;
    font-weight: 500;
  }

  &:nth-child(7) > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    width: 100%;
  }
`;

export const SortButton = styled.button<SortButtonProps>`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  margin-left: 8px;

  > svg {
    ${({ isAscending, theme }) =>
      !isAscending
        ? css`
            > g > path {
              stroke: ${theme.colors.primary};
            }
          `
        : css`
            > path {
              stroke: ${theme.colors.primary};
            }
          `}

    ${({ isActive }) =>
      !isActive &&
      css`
        > g > path {
          stroke: #707683;
        }

        > path {
          stroke: #707683;
        }
      `}
  }
`;

export const DegreeTag = styled.span<DegreeBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  border-radius: 6px;
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  font-weight: 700;
  height: 19px;
  width: 37px;
`;

export const TypeTag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 29px;
`;

export const StatusTag = styled.span<StatusTagProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.background};
  border-radius: 6px;
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: 700;
  width: 33px;
  height: 29px;
`;

export const ViewButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.primary};

  span {
    font-size: 12px;
    text-transform: uppercase;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  display: flex;
  align-items: center;

  max-width: 100%;
  width: min-content;

  margin-right: 7px;

  font-size: 15px;
  font-weight: 600;

  line-height: 18px;
  letter-spacing: 0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-decoration: none;

  color: ${({ theme }) => theme.colors.gray3};

  transition: color 0.7s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}

  img {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    margin-right: 10px;
  }

  span {
    margin-right: 5px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    width: 100%;
  }
`;

export const ContentNothingToDisplayBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  height: 400px;
  margin-top: 30px;
`;
