import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface SuggestionListProps {
  loading: boolean;
}

interface ItemSuggestionListProps {
  institution?: boolean;
}

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div`
  position: relative;
  z-index: 3 !important;
  max-width: 360px;
`;

export const Content = styled.div<ContainerProps>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  position: relative;
  height: 42px;
  padding: 0 16px;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  color: ${({ theme }) => theme.colors.grayAlternative};

  display: flex;
  align-items: center;

  > img {
    border-radius: 50%;
    height: 30px;
    margin-right: 10px;
    width: 30px;
  }

  ${({ isErrored, theme }) =>
    isErrored &&
    css`
      border-color: ${theme.colors.danger};
    `}

  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      color: ${theme.colors.graySecondary};
      border-color: ${theme.colors.primary};
    `}

  ${({ isFilled, theme }) =>
    isFilled &&
    css`
      color: ${theme.colors.graySecondary}; ;
    `}



  input {
    flex: 1;
    border: 0;
    background: transparent;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.01em;

    color: ${({ theme }) => theme.colors.graySecondary};

    width: 100%;

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.grayAlternative};
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.white};

    &::before {
      border-color: ${({ theme }) => theme.colors.danger} transparent;
    }
  }
`;

export const SuggestionList = styled.ul<SuggestionListProps>`
  display: flex;
  flex-direction: column;

  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 8px rgba(14, 70, 101, 0.1);
  max-height: 162px;
  width: 100%;
  max-width: 360px;
  overflow-y: ${({ loading }) => (loading ? 'hidden' : 'scroll')};
  list-style: none;

  > div {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  li {
    padding: 10px 18px;
    width: 100%;

    & + li {
      border-top: 1px solid #f2f2f2;
    }
  }
`;

export const ItemSuggestionList = styled.button<ItemSuggestionListProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;
  border: 0;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    strong {
      width: 100%;
      max-width: 190px;
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
    }

    span {
      font-size: 12px;
      font-weight: 500;
      margin-top: 2px;
      color: ${({ institution, theme }) =>
        institution ? '#A7A7A8' : theme.colors.grayAlternative};
    }
  }

  > span {
    color: ${({ institution, theme }) =>
      institution ? '#A7A7A8' : theme.colors.primary};
    font-size: 14px;
    font-weight: 600;
  }
`;
