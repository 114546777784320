import styled from 'styled-components';

interface LegendProps {
  markerColor: string;
}

export const ChartArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
`;

export const Legend = styled.div<LegendProps>`
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.graySecondary};
    margin-left: 13px;
  }

  &:before {
    content: '';
    background: ${({ markerColor }) => markerColor};
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }

  & + div {
    margin-top: 10px;
  }
`;
