/* eslint-disable @typescript-eslint/camelcase */
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { addHours, format } from 'date-fns';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useModal } from 'react-brave-modal';
import InputTextArea from '../../../../components/InputTextArea';
import { useUser } from '../../../../hooks/user';
import { ComponentsProfileRef } from '..';

import {
  Container,
  CardHeader,
  CardContent,
  Box,
  StatusBox,
  Buttons,
  Button,
  DateBox,
  ObservationBox,
  MessageTable,
} from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { useAuth } from '../../../../hooks/auth';
import { useCurrentCallback } from '../../../../lib/use-current-effect';
import ConfirmPatientReactivationModal from '../../../Patient/Edit/Status/modals/ConfirmPatientReactivationModal';
import ConfirmPatientDeactivationModal from '../../../Patient/Edit/Status/modals/ConfirmPatientDeactivationModal';
import RequiredFieldInformation from '../../../../components/RequiredFieldInformation';
import ShowDetailedObservationsButton from '../../../../components/ShowDetailedObservationsButton';
import DetailedStatusObservationModal, {
  DetailedStatusObservationModalProps,
} from '../../../../modals/DetailedStatusObservationsModal';
import UserStatusEnum from '../../../../enums/UserStatusEnum';

export interface DisplayableStatus {
  key?: string;
  status?: 'Ativo' | 'Rascunho' | 'Inativo';
  observation?: string;
  register: string;
  created_at?: string | Date;
  professional: string;
}
interface FormProps {
  status: UserStatusEnum;
  observation: string;
}

interface StatusComponentsProfileRef {
  canChange?: boolean;
  setCanUpdateUser?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Status: React.ForwardRefRenderFunction<
  ComponentsProfileRef,
  StatusComponentsProfileRef
> = ({ canChange = false, setCanUpdateUser }, ref) => {
  const { user, createStatus } = useUser();
  const { user: userAuth } = useAuth();
  const { showModal } = useModal();

  const formRef = useRef<FormHandles>(null);

  const observationsRef = useRef<{
    [key: string]: HTMLParagraphElement | null;
  }>({});

  const [listDisplay, setListDiplay] = useState<DisplayableStatus[]>([]);

  const [selected, setSelected] = useState<UserStatusEnum>(
    UserStatusEnum.ACTIVE,
  );

  useEffect(() => {
    setListDiplay(
      user?.status?.map(
        ({ status, created_at, user_created_updated_by, id, observation }) => ({
          key: id,
          status: userStatusList.find(
            (userStatus) => userStatus.value === status,
          )?.label,
          observation,
          register: created_at
            ? format(
                addHours(new Date(created_at), -3),
                "dd'/'MM'/'yyyy' - 'HH':'mm'h'",
              )
            : `-`,
          created_at,
          professional: user_created_updated_by?.name ?? `-`,
        }),
      ) ?? [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setCanUpdateUser &&
      setCanUpdateUser(selected === user?.currentStatus?.status);
  }, [selected, setCanUpdateUser, user]);

  const [disableObservation, setDisableObservation] = useState(false);

  const buttons = useMemo(
    () => [
      {
        text: 'Usuário ativo',
        key: 'active' as 'active' | 'inactive',
      },
      {
        text: 'Usuário inativo',
        key: 'inactive' as 'active' | 'inactive',
      },
    ],
    [],
  );

  useEffect(() => {
    setDisableObservation(
      user?.currentStatus?.status !== UserStatusEnum.ACTIVE,
    );
    setSelected(user?.currentStatus?.status ?? UserStatusEnum.ACTIVE);
  }, [user]);

  useEffect(() => {
    if (selected !== 'active') {
      formRef.current?.setFieldValue(
        'observation',
        user?.currentStatus?.observation,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const userStatusList = [
    {
      value: 'active',
      label: 'Ativo',
    },
    {
      value: 'draft',
      label: 'Rascunho',
    },
    {
      value: 'inactive',
      label: 'Inativo',
    },
  ] as const;

  const handleChangeStatus = useCallback(
    (key: 'active' | 'inactive') => {
      if (user?.currentStatus?.status !== key) {
        showModal({
          type: 'custom',
          data:
            key === UserStatusEnum.ACTIVE ? (
              <ConfirmPatientReactivationModal
                onSuccess={() => setSelected(key as UserStatusEnum)}
              />
            ) : (
              <ConfirmPatientDeactivationModal
                onSuccess={() => setSelected(key as UserStatusEnum)}
              />
            ),
        });
      } else {
        setSelected(key as UserStatusEnum);
      }
    },
    [showModal, user],
  );

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async () => {
      const data = { ...formRef.current?.getData() } as FormProps;

      data.status = selected;

      formRef.current?.setErrors({});

      if (data.status === user?.currentStatus?.status) {
        return false;
      }

      try {
        const schema = Yup.object().shape({
          status: Yup.string().required(),
          observation: Yup.string().when('status', {
            is: 'active',
            then: Yup.string(),
            otherwise: Yup.string().required('Observação é obrigatória'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (isCurrent()) {
          await createStatus({
            ...data,
            userId: user?.id,
            created_updated_by: userAuth?.id ?? '',
          });
        }

        toast.info('Status atualizado com sucesso!');
        return true;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
        return false;
      }
    },
    [createStatus, selected, user, userAuth],
  );

  const handleShowObservations = useCallback(
    (statusData: DetailedStatusObservationModalProps['statusData']) => {
      showModal({
        type: 'custom',
        data: <DetailedStatusObservationModal statusData={statusData} />,
      });
    },
    [showModal],
  );

  useImperativeHandle(ref, () => ({
    back: async () => true,
    save: handleSubmit,
  }));

  return (
    <Container>
      <CardHeader>
        <h1>Status - Ativar/Desativar perfil</h1>
      </CardHeader>
      <CardContent>
        <Form ref={formRef} onSubmit={() => {}}>
          <Box>
            <StatusBox>
              <span>Status atual</span>
              <Buttons>
                {buttons.map((button) => (
                  <Button
                    key={button.key}
                    onClick={() => handleChangeStatus(button.key)}
                    selected={button.key === selected}
                    disabled={!canChange}
                  >
                    {button.text}
                  </Button>
                ))}
              </Buttons>
            </StatusBox>
            <DateBox>
              <span>Data de cadastro</span>
              {user?.created_at && (
                <span>
                  {format(new Date(user?.created_at), "dd'/'MM'/'yyyy'")}
                </span>
              )}
            </DateBox>
          </Box>
          {selected === UserStatusEnum.INACTIVE && !disableObservation ? (
            <ObservationBox>
              <span>
                Observação: <RequiredFieldInformation />
              </span>
              <InputTextArea name="observation" disabled={disableObservation} />
            </ObservationBox>
          ) : (
            <MessageTable>
              <header>
                <h3>Status</h3>
                <h3>Observações</h3>
                <h3>Registro</h3>
                <h3>Profissional</h3>
              </header>

              <div>
                {listDisplay.map(
                  ({
                    key,
                    status,
                    observation,
                    register,
                    professional,
                    created_at,
                  }) => (
                    <section key={key}>
                      <div>{status}</div>
                      <div>
                        <p
                          ref={(element) => {
                            if (key) {
                              observationsRef.current[key] = element;
                            }
                          }}
                        >
                          {observation ?? '-'}
                        </p>
                      </div>
                      {key && (
                        <ShowDetailedObservationsButton
                          elementKey={key}
                          observationsRef={observationsRef}
                          onClick={() =>
                            handleShowObservations({
                              created_at,
                              professionalName: professional,
                              observation,
                              status,
                            })
                          }
                        />
                      )}
                      <div>
                        <p>{register}</p>
                      </div>
                      <div>
                        <p>{professional}</p>
                      </div>
                    </section>
                  ),
                )}
              </div>
            </MessageTable>
          )}
        </Form>
      </CardContent>
    </Container>
  );
};

export default forwardRef(Status);
