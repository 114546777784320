import { colors, ColorsProps } from './colors';
import { gridBreakpoints } from './gridBreakpoints';

export interface Theme {
  colors: ColorsProps;
  gridBreakpoints: typeof gridBreakpoints;
}

const theme: Theme = {
  colors,
  gridBreakpoints,
};

export default theme;
