import styled from 'styled-components';
import { Container as GridCard } from '../../../../components/GridCard/styles';

export const Container = styled(GridCard)``;

export const Content = styled.div`
  width: 90%;
  height: 393px;

  margin: 30px;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  background: #fbfbfb;
  position: relative;

  h2 {
    font-size: 11px;
    font-weight: 500;
  }

  strong {
    font-size: 16px;
    font-weight: 700;
  }

  strong:first-of-type {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const XrayImage = styled.img`
  position: absolute;
  right: 0;
  top: 0;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 23px;
  padding-bottom: 30px;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    margin-left: 13px;
  }

  strong:nth-child(4) {
    font-size: 14px;
  }
`;
