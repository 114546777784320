import styled from 'styled-components';

export const ModalElement = styled.div`
  display: flex;

  width: 831px;
  height: 463px;

  font-size: 3vmin;

  @media only screen and (orientation: portrait) and (max-width: 550px) {
    width: 80vw;
    height: 70vh;
    flex-direction: column;
  }

  @media only screen and (orientation: portrait) and (max-width: 850px) {
    width: 80vw;
    height: 70vh;
    flex-direction: column;
  }

  @media only screen and (orientation: landscape) and (max-width: 950px) {
    width: 80vw;
    height: 80vh;
  }
`;

export const ModalGrouper = styled.div`
  display: flex;

  flex: 1;

  font-size: inherit;
`;

export const ModalGrouperImage = styled(ModalGrouper)`
  margin-left: 42px;
  margin-right: 50px;

  > img {
    width: 100%;
  }

  @media only screen and (orientation: portrait) and (max-width: 550px) {
    margin: unset;

    > img {
      width: 50%;
      margin: 10% auto;
    }
  }

  @media only screen and (orientation: portrait) and (max-width: 850px) {
    margin: unset;

    > img {
      width: 50%;
      margin: 10% auto auto;
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 950px) {
    > img {
      margin-left: 10%;
    }
  }
`;

export const ModalGrouperText = styled(ModalGrouper)`
  flex-direction: column;

  justify-content: center;
  align-items: center;

  font-size: inherit;

  min-height: 300px;

  > * {
    max-width: 330px;

    &:last-child {
      align-self: flex-end;
      margin-right: 42px;
      margin-bottom: 50px;
    }
  }

  > div:first-child {
    flex: 1;

    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 70px;

    font-size: inherit;

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;

      color: ${({ theme }) => theme.colors.primary};
    }

    p {
      width: 320px;

      padding: 0;
      margin-top: 10px;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;

      color: ${({ theme }) => theme.colors.graySecondary};
    }
  }

  @media only screen and (orientation: portrait) and (max-width: 550px) {
    flex: 2;

    > * {
      max-width: 80%;

      &:last-child {
        align-self: center;
        margin-bottom: 2em;
        margin-right: initial;
      }
    }

    > div:first-child {
      align-items: center;
      margin-top: auto;

      > h1 {
        font-size: 1.5em;
        text-align: center;
        width: 100%;
      }

      > p {
        margin-top: 1.25em;
        font-size: 0.87em;
        text-align: center;
        width: 100%;
      }
    }
  }

  @media only screen and (orientation: portrait) and (max-width: 850px) {
    flex: 2;

    > * {
      max-width: 80%;

      &:last-child {
        align-self: center;
        margin-right: unset;
      }
    }

    > div:first-child {
      align-items: center;
      margin-top: auto;

      > h1 {
        font-size: 1.5em;
        text-align: center;
        width: 100%;
      }

      > p {
        margin-top: 1.25em;
        font-size: 0.87em;
        text-align: center;
        width: 100%;
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 950px) {
    height: 100%;
    margin-right: 2em;
    margin-left: 2em;
    min-height: unset;

    > *:last-child {
      align-self: flex-end;
      margin: unset;

      margin: 0 0 1.5em 0;
    }

    > div:first-child {
      max-width: unset;
      margin-top: auto;

      > h1 {
        font-size: 1.5em;
        text-align: center;
        width: 100%;
      }

      > p {
        margin-top: 1.25em;
        font-size: 0.87em;
        text-align: center;
        width: 100%;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: inherit;

  > button {
    padding: 16px 30px;
  }

  @media only screen and (orientation: portrait) and (max-width: 550px) {
    justify-content: center;

    > button {
      font-size: 0.87em;
      padding: 0.6em 0.95em;
    }
  }

  @media only screen and (orientation: portrait) and (max-width: 850px) {
    justify-content: center;

    > button {
      font-size: 0.92em;
      padding: 0.8em 1.2em;
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 950px) {
    justify-content: flex-end;

    > button {
      font-size: 0.8em;
      padding: 0.8em 1.5em;
    }
  }
`;
