import styled from 'styled-components';

export const Container = styled.div<{
  coordMouseX: number;
  coordMouseY: number;
}>`
  width: 317px;
  height: 237px;
  padding: 17px;

  background: #eaf6fc;

  box-shadow: 0px 4px 20px rgba(6, 92, 119, 0.08);
  border-radius: 6px;

  position: fixed;
  z-index: 9999;
  top: ${({ coordMouseY }) => coordMouseY - 10}px;
  left: ${({ coordMouseX }) => coordMouseX - 5}px;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
`;

export const DayTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  border-radius: 50%;

  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;

  color: ${({ theme }) => theme.colors.primary};
`;
export const DetailsAppointment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;

  > p {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.grayTertiary};
  }

  > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;

    > h3 {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;

      color: ${({ theme }) => theme.colors.grayTertiary};
      margin-bottom: 8px;
    }
  }
`;

export const ButtonArea = styled.div<{ selected: boolean }>`
  margin-top: 10px;
  height: 28px;
  min-width: 128px;

  > button {
    &:disabled {
      pointer-events: none;
    }
  }
`;
export const Paragraph = styled.div`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  max-width: 205px;
  text-align: justify;
  margin-left: 20px;
  text-align: left;

  overflow: hidden;
  color: ${({ theme }) => theme.colors.grayTertiary};
`;
export const ContainerEvolution = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  height: 101px;
  overflow: hidden;

  > label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    display: flex;
    align-items: flex-start;

    color: ${({ theme }) => theme.colors.grayTertiary};
  }
`;
