import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90.43deg, #30e3e5 11.2%, #2d9cdb 73.5%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  min-height: 60px;
  width: 100%;
  padding-left: 22px;
  padding-right: 36px;

  a {
    background-color: ${(props) => props.theme.colors.white};
    border: none;
    border-radius: 100px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.25px;
    padding: 12px 28px;
    text-decoration: none;
  }
`;

export const Content = styled.div``;
