import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useModal } from 'react-brave-modal';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import {
  useCurrentEffect,
  useCurrentCallback,
} from '../../../../../../lib/use-current-effect';

import SeeAllModal from '../SeeAllModal';
import SuccessfulModal from '../SuccessfulModal';
import InputSelect from '../../../../../../components/InputSelectAlternative';
import InputDropzone from '../../../../../../components/InputDropzone';
import ModalButton from '../../../../../../components/ModalButton';
import Button from '../../../../../../components/Button';
import { usePatient } from '../../../../../../hooks/patient';
import { useAuth } from '../../../../../../hooks/auth';
import getExamType from '../../../../../../utils/getExamType';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { ExamProcedure } from '../../../../../../entities/ExamProcedure';
import {
  createExamProcedure,
  updateExamProcedure,
  getExamProcedureFile,
  removeExamProcedure,
} from '../../../../../../services/examProcedure';

import { ReactComponent as EditIcon } from '../../../../../../assets/images/edit.svg';
import { ReactComponent as FileIcon } from '../../../../../../assets/images/file.svg';
import { ReactComponent as TrashIcon } from '../../../../../../assets/images/trash.svg';

import {
  Container,
  Header,
  Body,
  Row,
  SendBy,
  InputBox,
  InputText,
  InputTextArea,
  FileBox,
  DownloadButton,
  TrashButton,
  File,
  ButtonBox,
} from './styles';
import RemoveModal from '../../../../../../components/RemoveModal';

interface FileList {
  id: string;
  file: string;
  name: string;
}

interface FormData {
  examType: string;
  name: string;
  description: string;
}

interface FileFormData {
  file: File[];
}

interface AddEditModalProps {
  examProcedure?: ExamProcedure;
  canBack?: boolean;
  handleUpdateList: (examProcedure: ExamProcedure) => void;
  handleRemoveList: (examProcedure: ExamProcedure) => void;
}

const AddEditModal: React.FC<AddEditModalProps> = ({
  examProcedure,
  canBack,
  handleUpdateList,
  handleRemoveList,
}) => {
  const { showModal, closeModal } = useModal();
  const { patient } = usePatient();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const fileFormRef = useRef<FormHandles>(null);

  const [areYouEditing] = useState(!!examProcedure);
  const [disabled, setDisabled] = useState(!!examProcedure);
  const [fileList, setFileList] = useState<FileList>();
  const [isLoading, setIsLoading] = useState(false);

  const examTypeOptions = useMemo(() => getExamType, []);

  useEffect(() => {
    if (examProcedure) {
      formRef.current?.setData({
        examType: getExamType.find((type) => type.value === examProcedure.type)
          ?.label,
        name: examProcedure.name,
        description: examProcedure.description,
      });
    }
  }, [examProcedure]);

  useCurrentEffect(
    (isCurrent) => {
      if (examProcedure) {
        getExamProcedureFile(examProcedure.file).then((response) => {
          if (isCurrent()) {
            const extension = response.split(';')[0].slice(-3);

            setFileList({
              id: examProcedure.id,
              file: response,
              name: `${examProcedure.name}.${extension}`,
            });
          }
        });
      }
    },
    [examProcedure],
  );

  const handleDownload = useCallback((file: string, name: string) => {
    saveAs(file, name);
  }, []);

  const handleFileRemoval = useCallback(() => {
    setFileList(undefined);
  }, []);

  const handleRemoveExamProcedure = useCallback(async (): Promise<boolean> => {
    try {
      if (patient?.id && examProcedure?.id) {
        const status = await removeExamProcedure({
          patientId: patient.id,
          examProcedureId: examProcedure.id,
        });

        return status;
      }
      return false;
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }
      return false;
    }
  }, [examProcedure, patient]);

  const handleShowRemoveModal = useCallback(() => {
    if (examProcedure) {
      showModal({
        type: 'custom',
        data: (
          <RemoveModal
            message="Deseja remover esse exame/procedimento?"
            name={examProcedure.name}
            description={examProcedure.description}
            removeFunction={handleRemoveExamProcedure}
            onSuccess={() => handleRemoveList(examProcedure)}
          />
        ),
      });
    }
  }, [showModal, handleRemoveList, handleRemoveExamProcedure, examProcedure]);

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async () => {
      const formData = formRef.current?.getData() as FormData;
      const fileFormData = fileFormRef.current?.getData() as FileFormData;

      try {
        formRef.current?.setErrors({});
        fileFormRef.current?.setErrors({});
        setIsLoading(true);

        const formSchema = Yup.object().shape({
          examType: Yup.string().required('Tipo do exame é obrigatório'),
          name: Yup.string().required('Nome do exame é obrigatório'),
          description: Yup.string(),
        });

        const fileFormSchema = Yup.object().shape({
          file: Yup.array()
            .required('Arquivo do exame é obrigatório')
            .test(
              'length file',
              'Arquivo excede o tamanho limite',
              () =>
                !fileFormData.file[0] ||
                (fileFormData.file[0] && fileFormData.file[0].size <= 2097152),
            ),
        });

        await formSchema.validate(formData, { abortEarly: false });

        if (!areYouEditing || (areYouEditing && !fileList)) {
          await fileFormSchema.validate(fileFormData, { abortEarly: false });
        }

        let createdUpdatedExamProcedure: ExamProcedure;

        if (areYouEditing) {
          createdUpdatedExamProcedure = await updateExamProcedure({
            id: examProcedure?.id ?? '',
            patientId: patient?.id ?? '',
            professionalId: user?.professional?.id ?? '',
            examType: formData.examType,
            name: formData.name,
            description: formData.description,
            file: fileFormData.file[0],
          });
        } else {
          createdUpdatedExamProcedure = await createExamProcedure({
            patientId: patient?.id ?? '',
            professionalId: user?.professional?.id ?? '',
            examType: formData.examType,
            name: formData.name,
            description: formData.description,
            file: fileFormData.file[0],
          });
        }

        if (handleUpdateList) {
          handleUpdateList(createdUpdatedExamProcedure);
        }

        showModal({
          type: 'custom',
          data: (
            <SuccessfulModal
              areYouEditing={areYouEditing}
              handleUpdateList={handleUpdateList}
              handleRemoveList={handleRemoveList}
            />
          ),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
          fileFormRef.current?.setErrors(errors);
        } else if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      } finally {
        if (isCurrent()) {
          setIsLoading(false);
        }
      }
    },
    [
      patient,
      user,
      areYouEditing,
      fileList,
      examProcedure,
      handleUpdateList,
      handleRemoveList,
      showModal,
    ],
  );

  const handleReturn = useCallback(() => {
    if (handleUpdateList) {
      showModal({
        type: 'custom',
        data: (
          <SeeAllModal
            handleRemoveList={handleRemoveList}
            handleUpdateList={handleUpdateList}
          />
        ),
      });
    }
  }, [handleUpdateList, handleRemoveList, showModal]);

  return (
    <Container>
      <Header>
        <h1>{!areYouEditing && 'Adicionar'} Exame/Procedimento</h1>

        {areYouEditing && (
          <>
            <button type="button" onClick={() => setDisabled(false)}>
              Editar
              <EditIcon />
            </button>

            <button type="button" onClick={handleShowRemoveModal}>
              Remover
              <TrashIcon />
            </button>
          </>
        )}
      </Header>

      <Body>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <label>Nome:</label>
            <InputBox>
              <InputText name="name" disabled={disabled} />
            </InputBox>
          </Row>

          <Row>
            <label>Tipo:</label>
            <InputBox className="inputSelect">
              {disabled ? (
                <InputText name="examType" disabled={disabled} />
              ) : (
                <InputSelect
                  name="examType"
                  placeholder="Selecionar"
                  defaultValue={
                    examProcedure &&
                    getExamType.find(
                      (type) => type.value === examProcedure.type,
                    )
                  }
                  options={examTypeOptions}
                />
              )}
            </InputBox>
          </Row>

          <Row>
            <label>Descrição:</label>
            <InputBox>
              <InputTextArea
                name="description"
                disabled={disabled}
                editing={areYouEditing}
              />
            </InputBox>
          </Row>

          {examProcedure && (
            <SendBy>
              <h1>Enviado por:</h1>
              <span>
                {examProcedure.professional
                  ? examProcedure.professional.user?.name
                  : examProcedure.patient.user?.name}{' '}
                em
                {format(new Date(examProcedure?.updated_at), ' dd/MM/yyyy')}
              </span>
            </SendBy>
          )}
        </Form>

        <FileBox>
          <span>{!disabled && 'Upload de '}Arquivos:</span>

          <Form ref={fileFormRef} onSubmit={handleSubmit}>
            <InputDropzone name="file" isDisabled={disabled || !!fileList} />

            {fileList && (
              <File>
                <DownloadButton
                  type="button"
                  onClick={() => handleDownload(fileList.file, fileList.name)}
                >
                  <FileIcon />
                  <span>{fileList.name}</span>
                </DownloadButton>

                {!disabled && (
                  <TrashButton type="button" onClick={handleFileRemoval}>
                    <span>remover</span>
                    <TrashIcon />
                  </TrashButton>
                )}
              </File>
            )}
          </Form>

          <ButtonBox>
            {canBack ? (
              <ModalButton
                type="button"
                styleType="blue-light"
                onClick={handleReturn}
              >
                Voltar
              </ModalButton>
            ) : (
              <ModalButton
                type="button"
                styleType="blue-light"
                onClick={() => closeModal()}
              >
                {disabled ? 'Fechar' : 'Cancelar'}
              </ModalButton>
            )}

            {!disabled && (
              <Button
                type="submit"
                buttonType="primary"
                loading={isLoading}
                onClick={() => formRef?.current?.submitForm()}
              >
                Próximo
              </Button>
            )}
          </ButtonBox>
        </FileBox>
      </Body>
    </Container>
  );
};

export default AddEditModal;
