import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ActionsProps, AttendanceFields, AttendanceFieldsRef } from '../..';
import FormioComponent, {
  FormioComponentRef,
} from '../../../../../../components/FormioComponent';
import InputTextArea from '../../../../../../components/InputTextArea';

import { useAppointment } from '../../../../../../hooks/appointment';
import { useAuth } from '../../../../../../hooks/auth';
import { usePatient } from '../../../../../../hooks/patient';
import { useCurrentCallback } from '../../../../../../lib/use-current-effect';
import { Subtitle, Title } from '../../../styles';

import { Button, Container, Content, AnamneseArea } from './styles';
import AppointmentStatusEnum from '../../../../../../enums/AppointmentStatusEnum';
import OccupationAreaEnum from '../../../../../../enums/OccupationAreaEnum';

interface SubmitResponseProps {
  _id: string;
  state: string;
  data: {
    [key: string]: string;
  };
  form: string;
  project: string;
}

interface SatisfactionSurveyProps extends ActionsProps {
  id?: string;
  fields: AttendanceFields;
  setFields?: React.Dispatch<React.SetStateAction<AttendanceFields>>;
}

const SatisfactionSurvey: React.ForwardRefRenderFunction<
  AttendanceFieldsRef,
  SatisfactionSurveyProps
> = ({ goTo, id, fields }, ref) => {
  const { sendSheet, patient } = usePatient();
  const { appointmentSelected } = useAppointment();
  const { user } = useAuth();

  const formioRef = useRef<FormioComponentRef>(null);
  const formRef = useRef<FormHandles>(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const formId = useMemo(
    () => patient?.sheets?.find((sheet) => sheet.id === id)?.sheet.formId,
    [id, patient],
  );

  const formName = useMemo(
    () => patient?.sheets?.find((sheet) => sheet.id === id)?.sheet.name,
    [id, patient],
  );

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async (data: SubmitResponseProps) => {
      setIsButtonDisabled(true);
      // eslint-disable-next-line no-underscore-dangle
      if (data._id && id && goTo) {
        await sendSheet({
          id,
          // eslint-disable-next-line no-underscore-dangle
          submissionId: data._id,
        });

        if (isCurrent()) {
          goTo('fill-form');
        }
      }

      if (isCurrent()) {
        setIsButtonDisabled(false);
      }
    },
    [goTo, id, sendSheet],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        getFields: () => formRef.current?.getData() as AttendanceFields,
      };
    },
    [],
  );

  return (
    <Container>
      <Content>
        <header>
          <Title>Pesquisa de Satisfação - {formName}</Title>
        </header>
        <section>
          <FormioComponent
            ref={formioRef}
            url={`${process.env.REACT_APP_FORMIO_URL}/${formId}?header=1&reset=1&theme=lumen`}
            onSubmit={handleSubmit}
            options={{ noAlerts: true }}
          />
        </section>
        <footer>
          <Button
            loading={isButtonDisabled}
            type="button"
            onClick={() => formioRef.current?.submit()}
          >
            Continuar
          </Button>
          <button type="button" onClick={() => goTo && goTo('fill-form')}>
            Cancelar
          </button>
        </footer>
      </Content>
      <AnamneseArea>
        <Form ref={formRef} onSubmit={() => {}}>
          <Subtitle>Anamnese:</Subtitle>
          <InputTextArea
            name="anamnese"
            defaultValue={
              fields.anamnese ?? appointmentSelected?.details?.anamnese
            }
            disabled={
              appointmentSelected?.status === AppointmentStatusEnum.FINISHED
            }
          />

          {user?.professional?.occupationArea &&
            user.professional.occupationArea ===
              OccupationAreaEnum.MEDICINE && (
              <>
                <Subtitle>CID:</Subtitle>
                <InputTextArea
                  type="number"
                  name="cid"
                  defaultValue={
                    fields?.cid ?? appointmentSelected?.details?.cid
                  }
                />
                <Subtitle>Observações do médico (privadas):</Subtitle>
                <InputTextArea
                  name="doctorObservation"
                  defaultValue={
                    fields?.doctorObservation ??
                    appointmentSelected?.details?.doctor_observation
                  }
                />
              </>
            )}
        </Form>
      </AnamneseArea>
    </Container>
  );
};

export default forwardRef(SatisfactionSurvey);
