import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
}

type OptionsContainerProps = ContainerProps;

interface LinkButtonProps {
  disabled?: boolean;
}

export const Container = styled.nav<ContainerProps>`
  z-index: 9998;
  position: fixed;
  padding: 75px 0 0 15px;
  top: 60px;
  left: 155px;
  width: 175px;
  height: 100vh;
  background: ${({ theme }) => theme.colors.white};
  border-left: 1px solid #e5e5e5;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 20px 0 0 24px;
      top: 140px;
      left: 175px;
      width: 50%;
      box-shadow: none;
    `}

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
  }

  h3 {
    font-weight: 800;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.grayAlternative};
  }
`;

export const OptionsContainer = styled.ul<OptionsContainerProps>`
  list-style: none;
  margin-top: 20px;
  margin-left: ${({ isMobile }) => (isMobile ? '-8px' : '0')};

  a {
    text-decoration: none;
  }
`;

export const LinkButton = styled(Link)<LinkButtonProps>`
  text-decoration: none;

  li {
    padding: 8px 0 8px 8px;
    font-size: 12px;
    font-weight: 500;
    color: #707683;
    margin-bottom: 8px;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;

          li {
            opacity: 0.4;
          }
        `
      : css`
          li:hover {
            background: #eef7fc;
            border-radius: 3px 0px 0px 3px;
            color: ${({ theme }) => theme.colors.primary};
          }
        `}
`;
