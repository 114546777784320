import styled from 'styled-components';

export const Container = styled.div`
  width: 925px;
  height: 570px;

  display: flex;

  justify-content: center;
  align-items: center;

  gap: 100px;

  img {
    max-width: 215px;
  }

  > div {
    max-width: 40%;

    > h2,
    > p,
    > strong {
      padding: unset;
    }

    > h2 {
      padding-bottom: 15px;
      max-width: 80%;

      font-weight: bold;
      font-size: 24px;
      color: ${({ theme }) => theme.colors.primary};
    }

    > p,
    > strong {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.graySecondary};
    }

    > p {
      padding-bottom: 40px;
      font-weight: 500;
    }

    > strong {
      font-weight: 700;
    }
  }
`;
