import styled from 'styled-components';

interface FieldProps {
  gridArea?: 'pe' | 'al' | 'name' | 'phone' | 'hd' | 'ha' | 'obs';
}

export const Container = styled.div`
  min-height: 500px;
  width: 100%;
  padding-right: 52px;

  > h1 {
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;

    color: #707683;
  }

  > form {
    h1 {
      font-family: 'Raleway';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;

      color: #707683;
    }

    & + form {
      margin-top: 45px;
    }
  }
`;

export const GridContentDefault = styled.div`
  display: grid;
  grid-column-gap: 45px;
  grid-row-gap: 35px;

  margin-top: 28px;
  width: 100%;
  height: min-content;
`;

export const Field = styled.div<FieldProps>`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  flex-direction: column;

  > label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.01em;

    color: #707683;

    white-space: nowrap;
  }

  > div {
    margin-top: 4px;

    > textarea {
      max-height: 150px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;
