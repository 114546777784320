/* eslint-disable import/no-duplicates */
import React, { useMemo } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FiX, FiUser } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';
import history from '../../services/history';

import LogoSrc from '../../assets/images/cincoLogo1.svg';
import DefaultAvatarImage from '../../assets/images/avatar.svg';
import NotificationsList from './NotificationsList';

import {
  Container,
  Logo,
  ProfileWeb,
  DropMenuProfile,
  MenuProfileButton,
} from './styles';

const MenuTop: React.FC = () => {
  const { user, signOut } = useAuth();

  const userData = useMemo(() => {
    let gender = '';

    switch (user?.gender) {
      case 'M':
        gender = 'Sr. ';
        break;
      case 'F':
        gender = 'Sra. ';
        break;
      default:
        gender = 'Sr(a). ';
        break;
    }

    return {
      name: user?.name ? user?.name?.split(' ')[0] : '',
      avatar: user?.avatar ?? DefaultAvatarImage,
      gender,
    };
  }, [user]);

  return (
    <Container>
      <Logo src={LogoSrc} alt="Cinco" />

      <NotificationsList />

      <ProfileWeb>
        <div>
          <span>Olá,</span>
          <strong>
            {userData.gender}
            {userData.name}
          </strong>
        </div>

        <img src={userData.avatar} alt={`Foto de ${userData.name}`} />

        <MdKeyboardArrowDown size={14} color="#fff" />

        <DropMenuProfile>
          <li>
            <MenuProfileButton
              onClick={() => {
                history.push(`/user/profile?id=${user?.id}`);
              }}
            >
              <FiUser />
              Perfil
            </MenuProfileButton>
          </li>

          <li>
            <MenuProfileButton onClick={() => signOut()}>
              <FiX />
              Sair
            </MenuProfileButton>
          </li>
        </DropMenuProfile>
      </ProfileWeb>
    </Container>
  );
};

export default MenuTop;
