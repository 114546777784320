import styled from 'styled-components';
import Button from '../../../../components/Button';

export const Container = styled.div`
  max-width: 781px;
  width: 100%;
  padding: 50px 70px;

  > header {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    color: #707683;
    margin-bottom: 15px;

    > strong {
      font-weight: 800;
      color: #30e3e5;
      margin-right: 4px;
    }

    > div {
      margin-left: 15px;

      > button {
        border: 0;
        background: transparent;
      }
    }
  }

  > section {
    > h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;
      color: #2d9cdb;
    }

    > h2 {
      padding: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #4f4f4f;
      margin: 13px 0 23px;
    }

    > form {
      > div {
        margin-top: 4px;
      }

      > label {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: #707683;
      }
    }
  }

  > footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 100px;

    > button + button {
      margin-left: 10px;
    }
  }
`;

export const ButtonFooter = styled(Button)``;
