import axios from 'axios';
import history from './history';

const baseApiURL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: baseApiURL,
});

const baseCommunicationServiceURL =
  process.env.REACT_APP_COMMUNICATION_SERVICE_BASE_URL;

const communicationService = axios.create({
  baseURL: baseCommunicationServiceURL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_COMMUNICATION_SERVICE_TOKEN ?? '',
  },
});

api.interceptors.request.use(
  async (config) => {
    const userToken = localStorage.getItem('@Cinco:token');
    if (config.headers) {
      config.headers.Authorization = `Bearer ${userToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('@Cinco:token');
      history.push('/');
    }
    return Promise.reject(error);
  },
);

export { api, communicationService };
