import { OccupationArea } from '../entities/Professional';
import { CouncilType } from '../entities/Council';

export const getCouncilTypeByOccupationArea = (
  occupationArea?: OccupationArea,
): CouncilType | undefined => {
  let roleOptions: CouncilType | undefined;

  switch (occupationArea) {
    case 'Medicina':
      roleOptions = 'CRM';
      break;
    case 'Enfermagem':
      roleOptions = 'COREN';
      break;
    default:
      roleOptions = undefined;
      break;
  }

  return roleOptions;
};
