import styled, { css } from 'styled-components';

interface ButtonProps {
  lite?: boolean;
  isHidden?: boolean;
  color: 'white' | 'primary';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 360px;

  margin-top: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-top: 30px;

  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  > h1 {
    font-family: 'Raleway';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    color: #4f4f4f;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #30e3e5 0%, #2d9cdb 100%);
  color: #ffffff;

  &:hover {
    background: linear-gradient(180deg, #2d9cdb 0%, #30e3e5 100%);
  }

  border-radius: 100px;
  border: 0;
  padding: 16px 48px;
  color: #fff;
  margin-top: 0px !important;
  transition: all 0.6s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  letter-spacing: 1.25px;

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #828282;

  ${(props) =>
    props.color === 'white' &&
    css`
      background: ${props.theme.colors.white};
      color: #828282;

      &:hover {
        color: ${props.theme.colors.white};
      }
    `}

  ${(props) =>
    props.color === 'primary' &&
    css`
      color: #ffffff;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: #f5f6f8;
      color: #bdbdbd;
      border: 1px solid #bdbdbd;

      &:hover {
        background: #f5f6f8;
        color: #bdbdbd;
      }
      cursor: default;
      transition: none;
    `}

    ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}

    ${({ lite }) =>
    lite &&
    css`
      border: 1px solid #2d9cdb;
      color: #2d9cdb;

      background: #f5f6f8;
    `}

    & ~ & {
    margin-left: 20px;
  }
`;

export const CardContentNothingToDisplayBox = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgb(6 92 119 / 5%);
  height: 400px;

  > div {
    font-size: 18px;
  }
`;
