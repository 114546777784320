import React, { useCallback } from 'react';

import { Container } from './styles';

import CloudSketchesImage from '../../assets/images/cloud-sketches.svg';

interface ContentNothingToDisplayProps {
  text: string;
  buttonText?: string;
  onButtonClick?: () => any;
}

const ContentNothingToDisplay: React.FC<ContentNothingToDisplayProps> = ({
  text,
  buttonText,
  onButtonClick,
}) => {
  const handleClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick();
    }
  }, [onButtonClick]);

  return (
    <Container>
      <img src={CloudSketchesImage} alt="" />
      {text}
      {!!buttonText && (
        <button type="button" onClick={handleClick}>
          {buttonText}
        </button>
      )}
    </Container>
  );
};

export default ContentNothingToDisplay;
