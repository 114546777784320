import React from 'react';

import { Container } from './styles';
import TopImage from '../../../../assets/images/cloud-sketches.svg';

const EmptyCard: React.FC = () => {
  return (
    <Container>
      <img src={TopImage} alt="Imagem que simboliza sem dados encontrados" />
      <h2>Nenhum dado encontrado</h2>
    </Container>
  );
};

export default EmptyCard;
