import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.gray3};
  height: 100vh;
`;

export const VideoCallWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: center;

  height: -webkit-fill-available;
  width: -webkit-fill-available;

  > img {
    width: 20vmin;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (orientation: portrait) and (max-width: 550px) {
    > img {
      position: unset;
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 950px) {
    flex-direction: column;
    align-items: center;

    > img {
      display: none;
    }
  }
`;
