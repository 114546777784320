import React from 'react';

import { Container, ContainerProps } from './styles';

const SelectedItemsInList: React.FC<ContainerProps> = ({
  children,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <strong>{children}</strong>
    </Container>
  );
};

export default SelectedItemsInList;
