import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  Suspense,
  lazy,
} from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useModal } from 'react-brave-modal';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useCurrentCallback } from '../../../lib/use-current-effect';

import Loading from '../../../components/Loading';
import Banner from '../../../components/LoginRegisterBanner';
import Input from '../../../components/Input';
import InputPassword from '../../../components/InputPassword';
import InputCheck from '../../../components/InputCheckAlternative';
import Button from '../../../components/Button';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useAuth } from '../../../hooks/auth';
import history from '../../../services/history';
import changePageAfterLoginRegister from '../../../utils/changePageAfterLoginRegister';

import CincoLogo from '../../../assets/images/cincoLogo1.svg';
import { Container, Header, Content, ContentArea, CheckBox } from './styles';

const FailedPasswordAttemptsModal = lazy(
  () =>
    import(
      /* webpackChunkName: "FailedPasswordAttemptsModal" */ '../../../modals/FailedPasswordAttemptsModal'
    ),
);

interface RegisterFormData {
  username: string;
  password: string;
  extendedSession: boolean;
}

const SignIn: React.FC = () => {
  const { showModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const {
    signIn,
    user: userAuth,
    passwordAttemptsFailed,
    resetPasswordAttempts,
  } = useAuth();
  const [completed, setCompleted] = useState<boolean>(true);

  useEffect(() => {
    const hasToken = localStorage.getItem('@Cinco:token');

    if (userAuth && hasToken) {
      changePageAfterLoginRegister({
        user: userAuth,
      });
    }
  }, [userAuth]);

  useEffect(() => {
    if (passwordAttemptsFailed) {
      showModal &&
        showModal({
          data: (
            <Suspense fallback={false}>
              <FailedPasswordAttemptsModal />
            </Suspense>
          ),
          type: 'custom',
        });
    }

    resetPasswordAttempts();
  }, [passwordAttemptsFailed, resetPasswordAttempts, showModal]);

  const loginSubmit = useCurrentCallback(
    (isCurrent) => async (data) => {
      setCompleted(false);
      const { token, user } = await signIn({
        ...data,
      });

      if (token) {
        changePageAfterLoginRegister({ user });
      } else if (isCurrent()) {
        setCompleted(true);
      }
    },
    [signIn],
  );

  const handleSubmit = useCallback(
    async (data: RegisterFormData, _, event) => {
      event.preventDefault();

      try {
        data.username = data.username.trim().toLowerCase();
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string()
            .required('Informe seu nome de usuário de login.')
            .test(
              'space',
              'Digite um username sem espaço',
              (value) => value.indexOf(' ') < 0,
            ),
          password: Yup.string().min(6, 'Mínimo de 6 dígitos'),
          extendedSession: Yup.boolean(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await loginSubmit(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }
    },
    [loginSubmit],
  );

  if (!completed) {
    return <Loading />;
  }

  return (
    <Container>
      <Banner currentPage="signIn" isDoctor />

      <Content>
        <Header>
          <img src={CincoLogo} alt="cinco" />
        </Header>

        <ContentArea>
          <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
            <h1>Entrar</h1>

            <Input name="username" title="Nome de usuário" />
            <InputPassword name="password" title="Insira sua senha" />
            <CheckBox>
              <InputCheck name="extendedSession" />
              <span>Lembrar senha</span>
            </CheckBox>

            <Button type="submit" buttonType="secondary">
              Entrar
              <MdKeyboardArrowRight size={22} />
            </Button>
          </Form>

          <button
            type="button"
            onClick={() => history.push('/password/forgot')}
          >
            Esqueceu sua senha?
          </button>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default SignIn;
