/* eslint-disable import/no-duplicates */
import React, { useState } from 'react';
import { format, subDays, isToday } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Chart } from 'react-google-charts';
import { useCurrentEffect } from '../../../../../../../../lib/use-current-effect';

import {
  getMeasurementsByDateRange,
  getLastMeasurementsInfo,
} from '../../../../../../../../services/metrics';
import { usePatient } from '../../../../../../../../hooks/patient';
import Img from '../../../../../../../../assets/images/calorias.svg';

import Default, { ShowScreenType } from '../_Default';
import { ChartsProps } from '../../ContentChart';
import { DetailsChart } from '../../ContentChart/styles';
import { MetricTypeEnum } from '../../../../../../../../enums/MetricTypeEnum';

const CalorieChart: React.FC<ChartsProps> = ({ dateRange }) => {
  const { patient } = usePatient();

  const [showScreen, setShowScreen] = useState<ShowScreenType>('loading');
  const [dataOneDay, setDataOneDay] = useState<string | number>();
  const [graphData, setGraphData] = useState<(string | number)[][]>([
    ['00:00', 0],
  ]);

  useCurrentEffect(
    (isCurrent) => {
      (() => {
        if (patient?.id) {
          if (isCurrent()) {
            setShowScreen('loading');
          }

          const { startDate, endDate } = dateRange;

          if (startDate === endDate) {
            getLastMeasurementsInfo({
              patientId: patient.id,
              metrics: [MetricTypeEnum.STEPS],
            })
              .then((response) => {
                const checkMeasurementToday = response.find(
                  (item) =>
                    item.metricType === MetricTypeEnum.STEPS &&
                    isToday(new Date(item.mensuramentDate)),
                );

                if (isCurrent()) {
                  if (checkMeasurementToday) {
                    setShowScreen('chart');
                    setDataOneDay(checkMeasurementToday.calories);
                  } else {
                    setShowScreen('noData');
                  }
                }
              })
              .catch(() => {
                if (isCurrent()) {
                  setShowScreen('noData');
                }
              });
          } else {
            getMeasurementsByDateRange({
              patientId: patient.id,
              metrics: MetricTypeEnum.CALORIES,
              startDate,
              endDate,
            })
              .then((response) => {
                if (isCurrent()) {
                  if (response.length > 0) {
                    const result = response.map(
                      ({ calories, mensuramentDate }) => [
                        format(new Date(mensuramentDate), "dd'/'MM"),
                        calories,
                      ],
                    );

                    if (result.length === 1) {
                      const date = new Date(response[0].mensuramentDate);
                      const dayOne = format(
                        new Date(subDays(date, 1)),
                        "dd'/'MM'/'yyyy",
                      );
                      const dayTwo = format(
                        new Date(subDays(date, 2)),
                        "dd'/'MM'/'yyyy",
                      );

                      result.unshift([dayOne, 0]);
                      result.unshift([dayTwo, 0]);
                    }

                    setShowScreen('chart');
                    setGraphData(result);
                  } else {
                    setShowScreen('noData');
                  }
                }
              })
              .catch(() => {
                if (isCurrent()) {
                  setShowScreen('noData');
                }
              });
          }
        }
      })();
    },
    [dateRange, patient],
  );

  return (
    <Default showScreen={showScreen}>
      {dateRange.startDate === dateRange.endDate ? (
        <DetailsChart color="#FF9A1F">
          <h2>
            {`Hoje - ${format(dateRange.startDate, "dd'/'MM", {
              locale: ptBR,
            })}`}
          </h2>

          <div>
            <img src={Img} alt="icone de calorias" />

            <div>
              <span>Hoje o paciente gastou</span>

              <h1>{dataOneDay}</h1>

              <span>calorias</span>
            </div>
          </div>
        </DetailsChart>
      ) : (
        <Chart
          width="100%"
          height="300px"
          chartType="Bar"
          options={{
            legend: { position: 'none' },
            colors: ['#30E3E5', '#2D9CDB'],
          }}
          data={[['', 'Calorias'], ...graphData]}
          rootProps={{ 'data-testid': '5' }}
        />
      )}
    </Default>
  );
};

export default CalorieChart;
