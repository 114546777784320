import React, { useState } from 'react';

import AttendanceType from './screens/AttendanceType';
import ReviewAttendance from './screens/ReviewAttendance';
import SMSAttendance from './screens/SMSAttendance';
import Finish from './screens/Finish';

import { Container } from './styles';
import AppointmentTypeEnum from '../../enums/AppointmentTypeEnum';

type ScreensType =
  | 'attendanceType'
  | 'reviewAttendance'
  | 'smsAttendance'
  | 'finish';

type ScreensObjectType = {
  [key in ScreensType]: JSX.Element;
};

export type SmsDataType = {
  phone: string;
  message: string;
};

const AttendanceNowModal: React.FC = () => {
  const [selected, setSelected] = useState<ScreensType>('attendanceType');
  const [attendanceType, setAttendanceType] = useState<AppointmentTypeEnum>();
  const [smsData, setSmsData] = useState<SmsDataType>();

  const handleNextScreen = (attendanceSelected?: AppointmentTypeEnum) => {
    if (attendanceSelected === AppointmentTypeEnum.SMS) {
      setSelected('smsAttendance');
    } else {
      setSelected('reviewAttendance');
    }
  };

  const handleBackScreen = () => {
    if (
      attendanceType === AppointmentTypeEnum.SMS &&
      selected === 'reviewAttendance'
    ) {
      setSelected('smsAttendance');
    } else {
      setSelected('attendanceType');
    }
  };

  const screens: ScreensObjectType = {
    attendanceType: (
      <AttendanceType
        next={handleNextScreen}
        setAttendanceType={setAttendanceType}
      />
    ),
    reviewAttendance: (
      <ReviewAttendance
        attendanceType={attendanceType || AppointmentTypeEnum.PHONE}
        back={() => handleBackScreen()}
        finish={() => setSelected('finish')}
        smsData={smsData}
      />
    ),
    smsAttendance: (
      <SMSAttendance
        next={handleNextScreen}
        back={handleBackScreen}
        setSmsData={setSmsData}
      />
    ),
    finish: <Finish />,
  };

  return <Container>{screens[selected]}</Container>;
};

export default AttendanceNowModal;
