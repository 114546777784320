/* eslint-disable no-shadow */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Container,
  Line,
  SectionAppointmentsPerMonth,
  SectionAppointmentsPerYear,
} from './styles';
import { Appointment } from '../../../../../entities/Appointment';
import { useAppointment } from '../../../../../hooks/appointment';
import { getAppointmentsByPatient } from '../../../../../services/appointments';
import { AppointmentEventDetails } from '../AppointmentEventDetails';
import CardContentNothingToDisplay from '../../../../../components/CardContentNothingToDisplay';
import BlankSketchesImage from '../../../../../assets/images/blank-sketches.svg';

interface AppointmentsMap {
  appointmentsPerYear?: Map<number, Map<number, Appointment[]>>;
}

const AppointmentHistory: React.FC = () => {
  const { appointmentSelected } = useAppointment();
  const [list, setList] = useState<Appointment[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const appointmentsListRef = useRef<HTMLDivElement>(null);
  const [disabledScroll, setDisabledScroll] = useState(false);

  const patientId = useMemo(() => {
    if (appointmentSelected?.patient?.id) {
      return appointmentSelected.patient.id;
    }
    return undefined;
  }, [appointmentSelected]);

  useEffect(() => {
    const loadAppointments = async () => {
      setLoading(true);
      try {
        const appointments = await getAppointmentsByPatient({
          status: 'finished',
          patientId,
          page,
          limit: 10,
        });

        if (appointments.appointments) {
          const listAppointments = appointments.appointments.map(
            (appointment) => appointment,
          );

          setList((state) => {
            if (state) {
              return [...state, ...listAppointments];
            }
            return listAppointments;
          });
        }
      } catch (error) {
        console.log(error);
        if (error) {
          setList(undefined);
        }
      } finally {
        setLoading(false);
      }
    };

    loadAppointments();
  }, [page, patientId]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = appointmentsListRef.current;

      if (
        currentScroll &&
        currentScroll.scrollLeft + currentScroll.offsetWidth >=
          currentScroll.scrollWidth &&
        !loading &&
        list &&
        list.length === 10
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const element = appointmentsListRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [appointmentsListRef, list, loading]);

  const appointmentsMap = useMemo<AppointmentsMap>(() => {
    const appointmentsPerYear = new Map<number, Map<number, Appointment[]>>();
    let appointmentsPerMonth: Map<number, Appointment[]> | undefined;
    let appointmentsPerDay: Appointment[] | undefined;
    let year: number | undefined;
    let month: number | undefined;

    list?.forEach((appointment) => {
      if (appointment.date) {
        year = new Date(appointment.date).getFullYear();
        month = new Date(appointment.date).getMonth();
        if (!appointmentsPerYear.has(year)) {
          appointmentsPerYear.set(year, new Map<number, Appointment[]>());
        }
        appointmentsPerMonth = appointmentsPerYear.get(year);
        if (!appointmentsPerMonth?.has(month)) {
          appointmentsPerMonth?.set(month, []);
        }
        appointmentsPerDay = appointmentsPerMonth?.get(month);
        appointmentsPerDay?.push(appointment);
      }
    });

    return {
      appointmentsPerYear,
    };
  }, [list]);

  return (
    <Container
      ref={appointmentsListRef}
      items={list?.length || 0}
      disabledScroll={disabledScroll}
    >
      {!loading && !list ? (
        <CardContentNothingToDisplay imageWidth="140px">
          <img src={BlankSketchesImage} alt="" />
          <p>Houve um problema de conexão com o nosso servidor.</p>
          <p>Tente novamente mais tarde!</p>
        </CardContentNothingToDisplay>
      ) : loading ? (
        <strong>Carregando...</strong>
      ) : (
        <Line items={list?.length || 0}>
          {appointmentsMap &&
            appointmentsMap.appointmentsPerYear &&
            Array.from(appointmentsMap.appointmentsPerYear.entries()).map(
              ([year, appointmentsPerMonth]) => (
                <SectionAppointmentsPerYear key={year}>
                  {Array.from(appointmentsPerMonth.entries()).map(
                    ([month, appointmentsPerDay], indexMonth) => (
                      <SectionAppointmentsPerMonth key={`${year}-${month}`}>
                        {Array.from(appointmentsPerDay).map(
                          (appointment, index) => {
                            const position = index % 2 !== 0 ? 'top' : 'bottom';
                            const selected =
                              appointment.id === appointmentSelected?.id;
                            return (
                              <AppointmentEventDetails
                                appointment={appointment}
                                indexDay={index}
                                indexMonth={indexMonth}
                                position={position}
                                selected={selected}
                                key={`${appointment.id}`}
                                disableScrolling={setDisabledScroll}
                              />
                            );
                          },
                        )}
                      </SectionAppointmentsPerMonth>
                    ),
                  )}
                </SectionAppointmentsPerYear>
              ),
            )}
        </Line>
      )}
    </Container>
  );
};

export default AppointmentHistory;
