import React, {
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useModal } from 'react-brave-modal';

import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import RequestSentModal from '../modals/RequestSentModal';
import { useUser } from '../../../../hooks/user';
import { forgotPassword } from '../../../../services/user';
import getValidationErrors from '../../../../utils/getValidationErrors';
import ChangePasswordImage from '../../../../assets/images/change-password.svg';

import { Container, CardHeader, CardContent, Box } from './styles';
import { ComponentsProfileRef } from '..';

interface FormProps {
  email: string;
}

const ResetPassword: React.ForwardRefRenderFunction<ComponentsProfileRef> = (
  _,
  ref,
) => {
  const { user } = useUser();
  const { showModal } = useModal();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async () => {
    const data = { ...formRef.current?.getData() } as FormProps;

    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um email válido')
          .required('O e-mail é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await forgotPassword({ email: data.email, userId: user?.id });

      showModal({
        type: 'custom',
        data: <RequestSentModal />,
      });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      } else if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }
      return false;
    }
  }, [showModal, user]);

  useImperativeHandle(ref, () => ({
    back: async () => true,
    save: handleSubmit,
  }));

  return (
    <Container>
      <CardHeader>
        <h1>Trocar senha</h1>
        <h2>{user?.name}</h2>
      </CardHeader>
      <CardContent>
        <Box>
          <span>
            Ao resetar a senha do usuário, nós o enviaremos um link para a
            criação de uma nova senha.
          </span>
          <Form ref={formRef} onSubmit={() => {}}>
            <label>Confirme o e-mail do usuário:</label>
            <InputWithoutTitle name="email" type="email" />
          </Form>
        </Box>
        <img src={ChangePasswordImage} alt="Imagem trocar senha" />
      </CardContent>
    </Container>
  );
};

export default forwardRef(ResetPassword);
