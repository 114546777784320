import styled from 'styled-components';

import datePicker from '../../../../assets/images/date-picker.svg';

interface FieldProps {
  gridArea: 'ni' | 'uf' | 'un' | 'st' | 'bt';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  display: flex;

  width: 100%;
  min-height: 230px;

  margin-top: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-top: 30px;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  form {
    margin-right: 50px;
  }

  > h1 {
    font-family: 'Raleway';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(7, 1fr);
  grid-template-areas:
    'ni ni uf uf un un st'
    'bt bt bt bt bt bt bt';
  grid-column-gap: 45px;
  grid-row-gap: 25px;

  margin-top: 28px;
  margin-right: 50px;

  width: 100%;
  height: 100%;
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  align-self: flex-start;
  border-radius: 100px;
  border: 0;

  color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;

  height: 42px;
  width: 145px;
`;

export const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: column;
  grid-area: ${({ gridArea }) => gridArea};

  &:last-child > button {
    align-self: flex-end;
  }

  > label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.01em;

    color: ${({ theme }) => theme.colors.grayTertiary};
    white-space: nowrap;

    > span {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      letter-spacing: 0.01em;

      color: ${({ theme }) => theme.colors.danger};
    }
  }

  > div {
    margin-top: 4px;
    max-height: 43px;

    > input[type='date']::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      background: url(${datePicker}) no-repeat;
      background-size: 75%;
      width: 24px;
      height: 24px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;
