import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from 'react-brave-modal';
import { CgTrashEmpty } from 'react-icons/cg';

import { useCurrentEffect } from '../../../../../lib/use-current-effect';

import { OrderBy } from '..';
import { ActionMenuListProps } from '../../../../../components/ActionMenuButton';
import ActionMenuButtonList from '../../../../../components/ActionMenuButtonList';
import ContentNothingToDisplay from '../../../../../components/ContentNothingToDisplay';
import InputCheckWithoutForm from '../../../../../components/InputCheckWithoutForm';
import SelectedItemsInList from '../../../../../components/SelectedItemsInList';
import Spinner from '../../../../../components/Spinner';
import Pagination from '../../../../../components/Pagination';
import RemoveProfileUserModal, {
  ItemProps,
} from '../../../../../modals/RemoveProfileUserModal';

import { User } from '../../../../../entities/User';
import { getAvatarImage } from '../../../../../services/user';

import { usePermission } from '../../../../../hooks/permission';

import { ReactComponent as ArrowUpDownIcon } from '../../../../../assets/images/arrowupdown.svg';
import Avatar from '../../../../../assets/images/avatar.svg';

import { Button, ChangeOrderButton } from '../styles';
import {
  Container,
  Table,
  ColorNumber,
  ActionSection,
  SpinnerWrapper,
} from './styles';

interface UserProfileItem {
  isChecked: boolean;
  id: string;
  name: string;
  avatar: string;
  healthcareProfessional: string;
  occupation: string;
  profileName?: string;
  profileID?: string;
}

interface ImageList {
  id: string;
  avatar: string;
}

interface UserProfileListProps {
  type: 'view' | 'create';
  list?: User[];
  totalUsers?: number;
  totalPages?: number;
  loading?: boolean;
  onChangePage?: (newPage: number) => void;
  changeType?: (type: 'view' | 'create') => void;
  clearFilters?: () => void;
  onItemSelected?: (keys: string[]) => void;
  onSave?: (keys: string[]) => void;
  onOrdenationChange?: (
    newOrderBy: OrderBy,
    newOrdenationType: boolean,
  ) => void;
  orderBy: OrderBy;
  ordenationType: 'ASC' | 'DESC';
  page: number;
}

const UserProfileList: React.FC<UserProfileListProps> = ({
  type,
  list = [],
  totalUsers = 0,
  totalPages = 1,
  loading = true,
  onChangePage,
  changeType,
  clearFilters,
  onItemSelected,
  onSave,
  onOrdenationChange,
  orderBy,
  ordenationType,
  page,
}) => {
  const { showModal } = useModal();
  const { hasPermission } = usePermission();
  const [listDisplay, setListDisplay] = useState<UserProfileItem[]>([]);

  const canChangeProfile = useMemo(() => hasPermission('edit_profile'), [
    hasPermission,
  ]);

  const selectedItemsCount = useMemo(
    () =>
      listDisplay
        .map<number>((item) => (item.isChecked ? 1 : 0))
        .reduce((total, item) => total + item, 0),
    [listDisplay],
  );

  const itemsSelected = useMemo(
    () => listDisplay.filter((item) => item.isChecked).map((item) => item.id),
    [listDisplay],
  );

  const handleRemoveProfileUser = useCallback(
    (items: ItemProps[]) => {
      showModal({
        type: 'custom',
        data: (
          <RemoveProfileUserModal
            items={items}
            onSuccess={() => clearFilters && clearFilters()}
          />
        ),
      });
    },
    [clearFilters, showModal],
  );

  const handleRemoveMany = useCallback(() => {
    const listToRemove: ItemProps[] = listDisplay
      .filter((item) => item.isChecked)
      .map((item) => ({
        id: item.id,
        name: item.name,
        currentTypeID: item.profileID,
      }));

    handleRemoveProfileUser(listToRemove);
  }, [handleRemoveProfileUser, listDisplay]);

  const listActionsForAll = useMemo<ActionMenuListProps[]>(
    () => [{ name: 'Remover', action: handleRemoveMany }],
    [handleRemoveMany],
  );

  const handleOrdenation = useCallback(
    (orderKey: OrderBy, isInitialOrdering: boolean) => {
      if (onOrdenationChange) {
        onOrdenationChange(orderKey, isInitialOrdering);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCheck = useCallback(
    (key: string, value: boolean) => {
      const newListDisplay = listDisplay.map((item) => {
        if (item.id === key) {
          item.isChecked = value;
        }

        return item;
      });

      setListDisplay([...newListDisplay]);
    },
    [listDisplay],
  );

  const handleCheckAll = useCallback(
    (value: boolean) => {
      const newListDisplay = listDisplay.map((item) => {
        item.isChecked = value;
        return item;
      });

      setListDisplay([...newListDisplay]);
    },
    [listDisplay],
  );

  const handleClearFilters = useCallback(() => {
    if (clearFilters) {
      clearFilters();
    }
  }, [clearFilters]);

  const transformListToDisplay = useCallback(
    (imageList?: ImageList[], isCurrent?: () => boolean) => {
      let newListDisplay: UserProfileItem[] = [];

      if (imageList) {
        newListDisplay = list.map((item) => ({
          isChecked: false,
          id: item.id ?? '',
          name: item.name ?? '-',
          avatar: imageList.find(({ id }) => id === item.id)?.avatar ?? Avatar,
          healthcareProfessional: item.type === 'professional' ? 'Sim' : 'Não',
          occupation: item.professional?.occupationArea ?? '-',
          profileName: item.profile?.title,
          profileID: item.profile?.id,
        }));
      } else {
        newListDisplay = list.map((item) => ({
          isChecked: false,
          id: item.id ?? '',
          name: item.name ?? '-',
          avatar: Avatar,
          healthcareProfessional: item.type === 'professional' ? 'Sim' : 'Não',
          occupation: item.professional?.occupationArea ?? '-',
          profileName: item.profile?.title,
          profileID: item.profile?.id,
        }));
      }

      if (isCurrent && isCurrent()) {
        setListDisplay(newListDisplay);
      }
    },
    [list],
  );

  useCurrentEffect(
    (isCurrent) => {
      transformListToDisplay();

      Promise.all(
        list.map(async (item) => ({
          id: item.id ?? '',
          avatar: item.avatar ? await getAvatarImage(item.avatar) : Avatar,
        })),
      ).then((imageListResult) => {
        transformListToDisplay(imageListResult, isCurrent);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list],
  );

  useEffect(() => {
    if (onItemSelected) {
      onItemSelected(itemsSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsSelected]);

  return (
    <>
      {!loading ? (
        <>
          {listDisplay.length > 0 ? (
            <>
              <SelectedItemsInList invisible={selectedItemsCount === 0}>
                {selectedItemsCount === 1
                  ? '01 Selecionado'
                  : `${selectedItemsCount.toLocaleString('pt-BR', {
                      minimumIntegerDigits: 2,
                    })} Selecionados`}
              </SelectedItemsInList>

              <Container>
                <header>
                  <div>
                    <ActionSection>
                      <span>Ações:</span>
                      <ActionMenuButtonList
                        disabled={selectedItemsCount <= 0 || type === 'create'}
                        list={listActionsForAll}
                      />
                    </ActionSection>
                    Exibindo{' '}
                    <ColorNumber color="black">{list.length ?? 0}</ColorNumber>{' '}
                    resultados de{' '}
                    <ColorNumber color="primary">{totalUsers}</ColorNumber>
                  </div>
                </header>
                <Table>
                  <header>
                    <div>
                      <InputCheckWithoutForm
                        type="checkbox"
                        onChange={(e) => handleCheckAll(e.target.checked)}
                        checked={listDisplay.length === selectedItemsCount}
                      />
                    </div>
                    <div>
                      Nome
                      <ChangeOrderButton
                        isActive={orderBy === 'name'}
                        ordenationType={ordenationType}
                        type="button"
                        onClick={() =>
                          handleOrdenation('name', orderBy !== 'name')
                        }
                      >
                        <ArrowUpDownIcon />
                      </ChangeOrderButton>
                    </div>
                    <div>Profissional de Saúde?</div>
                    <div>
                      Função
                      <ChangeOrderButton
                        isActive={orderBy === 'role'}
                        ordenationType={ordenationType}
                        type="button"
                        onClick={() =>
                          handleOrdenation('role', orderBy !== 'role')
                        }
                      >
                        <ArrowUpDownIcon />
                      </ChangeOrderButton>
                    </div>
                    <div>
                      Perfil
                      <ChangeOrderButton
                        isActive={orderBy === 'profileTitle'}
                        ordenationType={ordenationType}
                        type="button"
                        onClick={() =>
                          handleOrdenation(
                            'profileTitle',
                            orderBy !== 'profileTitle',
                          )
                        }
                      >
                        <ArrowUpDownIcon />
                      </ChangeOrderButton>
                    </div>
                    <div />
                  </header>
                  {listDisplay.map(
                    ({
                      id,
                      isChecked,
                      name,
                      avatar,
                      healthcareProfessional,
                      occupation,
                      profileName,
                      profileID,
                    }) => (
                      <section key={id}>
                        <div>
                          <InputCheckWithoutForm
                            type="checkbox"
                            onChange={(e) => handleCheck(id, e.target.checked)}
                            checked={isChecked}
                          />
                        </div>
                        <div>
                          <img src={avatar} alt={`Foto de ${name}`} />
                          {name}
                        </div>
                        <div>{healthcareProfessional}</div>
                        <div>{occupation}</div>
                        <div>
                          <div>{profileName}</div>
                        </div>
                        <div>
                          {type === 'view' && (
                            <button
                              type="button"
                              onClick={() =>
                                handleRemoveProfileUser([
                                  { id, name, currentTypeID: profileID },
                                ])
                              }
                              disabled={!canChangeProfile}
                            >
                              <CgTrashEmpty color="#2D9CDB" size={20} />
                            </button>
                          )}
                        </div>
                      </section>
                    ),
                  )}
                </Table>

                <Pagination
                  totalPages={totalPages}
                  onChangePage={onChangePage}
                  page={page}
                />

                {type === 'create' && (
                  <footer>
                    <Button
                      onClick={() => changeType && changeType('view')}
                      isWhite
                    >
                      Cancelar
                    </Button>
                    <Button
                      disabled={selectedItemsCount === 0}
                      onClick={() => onSave && onSave(itemsSelected)}
                    >
                      Salvar
                    </Button>
                  </footer>
                )}
              </Container>
            </>
          ) : (
            <ContentNothingToDisplay
              text="Nenhum dado encontrado"
              buttonText="Limpar busca"
              onButtonClick={handleClearFilters}
            />
          )}
        </>
      ) : (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
    </>
  );
};

export default UserProfileList;
