import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../lib/use-current-effect';

import AppointmentType from './screens/AppointmentType';
import MessageType from './screens/MessageType';
import OccupationArea from './screens/OccupationArea';
import ScheduleContact from './screens/ScheduleContact';
import SMSTextHistory from './screens/SMSTextHistory';
import SMSModelSet from './screens/SMSModelSet';
import ScheduleAppointment from './screens/ScheduleAppointment';
import ReviewAppointment from './screens/ReviewAppointment';
import FinishAppointment from './screens/FinishAppointment';

import { Patient } from '../../entities/Patient';
import { useAppointment } from '../../hooks/appointment';

import { Container } from './styles';
import AppointmentTypeEnum from '../../enums/AppointmentTypeEnum';

export interface BulkList {
  id: string;
  name: string;
  avatar: string;
}

export interface HandleBulkList {
  newPatient?: BulkList;
  patientId?: string;
}

export interface BulkAvailability {
  date: string;
  availabilities: {
    available: boolean;
    booked: boolean;
    interval: Date;
  }[];
}

interface OptionsProps {
  [key: string]: {
    component: JSX.Element;
    visible: boolean;
  };
}

export interface ModalProps {
  flow?: 'create' | 'edit';
  userType?: string;
  attendanceType?: AppointmentTypeEnum;
  next?: (type?: string) => void;
  back?: () => void;
  edit?: () => void;
  goTo?: (key: string) => void;
  changeToEdit?: () => void;
}

interface CreateEditAppointmentModalProps {
  onFinish: () => void;
  appointmentId?: string;
  patient?: Patient;
  bulkFilterList?: BulkList[];
  attendanceType?: AppointmentTypeEnum;
}

const CreateEditAppointmentModal: React.FC<CreateEditAppointmentModalProps> = ({
  onFinish,
  appointmentId,
  patient,
  bulkFilterList,
  attendanceType,
}) => {
  const { appointmentSelected, sync, saveLocal, cleanLocal } = useAppointment();
  const [selected, setSelected] = useState<string>(
    attendanceType ? 'OccupationArea' : 'AppointmentType',
  );
  const [nextKey, setNextKey] = useState<string>();
  const [backKey, setBackKey] = useState<string>();
  const [flow, setFlow] = useState<'create' | 'edit'>('create');

  const [isBulk, setIsBulk] = useState(!!bulkFilterList?.length);
  const [bulkList, setBulkList] = useState<BulkList[]>(bulkFilterList ?? []);
  const [bulkAvailability, setBulkAvailability] = useState<BulkAvailability[]>(
    [],
  );

  const isBulkFilter = useMemo(
    () => !!bulkFilterList?.length,
    [bulkFilterList],
  );

  useCurrentEffect((isCurrent) => {
    if (appointmentId) {
      sync(appointmentId);
      setFlow('edit');

      if (isCurrent()) {
        setSelected('ReviewAppointment');
      }
    } else {
      cleanLocal();
    }

    if (patient) {
      saveLocal({
        patient,
        patientId: patient.id,
        contact_phone: patient.user?.phone,
      });
    }

    if (attendanceType) {
      saveLocal({ type: attendanceType });
    }

    return () => {
      cleanLocal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appointmentSelected?.id) {
      setFlow('edit');
    } else {
      setFlow('create');
    }
  }, [appointmentSelected]);

  const handleBack = useCurrentCallback(
    (isCurrent) => () => {
      if (isCurrent()) {
        setSelected(backKey as string);
      }
    },
    [backKey],
  );

  const handleNext = useCurrentCallback(
    (isCurrent) => () => {
      if (isCurrent()) {
        setSelected(nextKey as string);
      }
      if (selected === 'OccupationArea') {
        if (patient) {
          saveLocal({
            patient,
            patientId: patient.id,
            contact_phone: patient.user?.phone,
          });
        }
      }
    },
    [nextKey, selected, patient, saveLocal],
  );

  const handleBulkList = useCurrentCallback(
    (isCurrent) =>
      ({ newPatient, patientId }: HandleBulkList) => {
        if (isCurrent()) {
          if (newPatient) {
            const alreadyAdded = bulkList.find(
              (item) => item.id === newPatient.id,
            );

            if (isBulk) {
              if (alreadyAdded) {
                toast.info('Paciente já adicionado(a)');
              } else {
                setBulkList((prevState) => [newPatient, ...prevState]);
              }
            }
          }

          if (patientId) {
            setBulkList((prevState) =>
              prevState.filter((item) => item.id !== patientId),
            );
          }

          if (!newPatient && !patientId) {
            setBulkList([]);
          }
        }
      },
    [isBulk, bulkList],
  );

  const handleType = useCurrentCallback(
    (isCurrent) => () => {
      if (isCurrent()) {
        setIsBulk((prevState) => !prevState);
      }
    },
    [],
  );

  const handleBulkAvailability = useCurrentCallback(
    (isCurrent) => (availabilities: BulkAvailability[]) => {
      if (isCurrent()) {
        setBulkAvailability(availabilities);
      }
    },
    [],
  );

  useEffect(() => {
    if (appointmentSelected?.type === AppointmentTypeEnum.SMS) {
      setIsBulk(false);
    }

    if (!isBulk) {
      setBulkList([]);
    }
  }, [appointmentSelected, isBulk]);

  const options = useMemo<OptionsProps>(
    () => ({
      AppointmentType: {
        visible: true,
        component: (
          <AppointmentType
            attendanceType={attendanceType}
            isBulkFilter={isBulkFilter}
            next={handleNext}
          />
        ),
      },
      MessageType: {
        visible: false,
        component: <MessageType next={handleNext} back={handleBack} />,
      },
      OccupationArea: {
        visible: true,
        component: (
          <OccupationArea
            attendanceType={attendanceType}
            next={handleNext}
            back={handleBack}
          />
        ),
      },
      ScheduleContactProfessional: {
        visible: true,
        component: (
          <ScheduleContact
            flow={flow}
            userType="professional"
            isBulk={false}
            isBulkFilter={isBulkFilter}
            next={handleNext}
            back={handleBack}
            goTo={setSelected}
            edit={() => setSelected('ReviewAppointment')}
          />
        ),
      },
      ScheduleContactPatient: {
        visible: !patient,
        component: (
          <ScheduleContact
            flow={flow}
            userType="patient"
            isBulk={isBulk}
            isBulkFilter={isBulkFilter}
            bulkList={bulkList}
            handleBulkList={handleBulkList}
            handleType={handleType}
            next={handleNext}
            back={handleBack}
            edit={() => setSelected('ReviewAppointment')}
          />
        ),
      },
      SMSTextHistory: {
        visible: appointmentSelected?.type === AppointmentTypeEnum.SMS,
        component: <SMSTextHistory next={handleNext} back={handleBack} />,
      },
      SMSModelSet: {
        visible: false,
        component: <SMSModelSet goTo={setSelected} />,
      },
      ScheduleAppointment: {
        visible: true,
        component: (
          <ScheduleAppointment
            flow={flow}
            isBulk={isBulk}
            isBulkFilter={isBulkFilter}
            bulkList={bulkList}
            bulkAvailability={bulkAvailability}
            handleBulkAvailability={handleBulkAvailability}
            back={handleBack}
            next={handleNext}
            edit={() => setSelected('ReviewAppointment')}
          />
        ),
      },
      ReviewAppointment: {
        visible: true,
        component: (
          <ReviewAppointment
            flow={flow}
            isBulk={isBulk}
            bulkList={bulkList}
            bulkAvailability={bulkAvailability}
            back={handleBack}
            next={handleNext}
            goTo={setSelected}
            onFinish={onFinish}
          />
        ),
      },
      FinishAppointment: {
        visible: true,
        component: (
          <FinishAppointment
            bulkList={bulkList}
            isBulkFilter={isBulkFilter}
            handleBulkList={handleBulkList}
            goTo={setSelected}
          />
        ),
      },
    }),
    [
      flow,
      appointmentSelected,
      isBulk,
      isBulkFilter,
      bulkList,
      bulkAvailability,
      attendanceType,
      patient,
      handleBack,
      handleNext,
      onFinish,
      handleType,
      handleBulkList,
      handleBulkAvailability,
    ],
  );

  useEffect(() => {
    const optionsArray = Object.entries(options);
    let control = false;
    let next = selected;
    let back = selected;

    optionsArray.forEach(([key, { visible }]) => {
      if (control && visible) {
        next = key;
        control = false;
      }

      if (key === selected) {
        control = true;
      }
    });

    optionsArray.reverse().forEach(([key, { visible }]) => {
      if (control && visible) {
        back = key;
        control = false;
      }

      if (key === selected) {
        control = true;
      }
    });

    setNextKey(next);
    setBackKey(back);
  }, [options, selected]);

  return <Container>{selected && options[selected].component}</Container>;
};

export default CreateEditAppointmentModal;
