import React, { useMemo, useState, useCallback } from 'react';
import { format } from 'date-fns';
import { useModal } from 'react-brave-modal';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { MdRemoveRedEye } from 'react-icons/md';

import AddEditModal from '../modals/AddEditModal';
import { ListFilter } from '../modals/SeeAllModal';
import { ExamProcedure } from '../../../../../entities/ExamProcedure';
import OrdenationTypeEnum from '../../../../../enums/OrdenationTypeEnum';
import { ReactComponent as StethoscopeIcon } from '../../../../../assets/images/stethoscope.svg';
import { ReactComponent as PersonIcon } from '../../../../../assets/images/person.svg';

import { Container, SortButton } from './styles';
import ExamsProceduresOrderByEnum from '../../../../../enums/ExamsProceduresOrderByEnum';

interface ListData {
  id: string;
  name: string;
  sendByIcon: JSX.Element;
  sendByName: string;
  date: string;
}

interface ListProps {
  examsProcedures: ExamProcedure[];
  handleUpdateList: (examProcedure: ExamProcedure) => void;
  handleRemoveList: (examProcedure: ExamProcedure) => void;
  handleListFilter?: (filter: ListFilter) => void;
}

const List: React.FC<ListProps> = ({
  examsProcedures,
  handleUpdateList,
  handleRemoveList,
  handleListFilter,
}) => {
  const { showModal } = useModal();
  const [orderBy, setOrderBy] = useState<ExamsProceduresOrderByEnum>(
    ExamsProceduresOrderByEnum.DATE,
  );
  const [isAscending, setIsAscending] = useState(true);

  const listData = useMemo(() => {
    const list: ListData[] = [];

    examsProcedures.forEach((examProcedure) => {
      const sendBy = {
        name: examProcedure.patient.user?.name ?? '-',
        icon: <PersonIcon />,
      };

      if (examProcedure.professional) {
        sendBy.name = examProcedure.professional.user?.name ?? '-';
        sendBy.icon = <StethoscopeIcon />;
      }

      const data: ListData = {
        id: examProcedure.id,
        name: examProcedure.name,
        sendByIcon: sendBy.icon,
        sendByName: sendBy.name,
        date: format(new Date(examProcedure.created_at), 'dd/MM/yyyy'),
      };

      list.push(data);
    });

    const filteredList = list.filter(
      (compare, index, self) =>
        index === self.findIndex((item) => item.id === compare.id),
    );

    return filteredList;
  }, [examsProcedures]);

  const handleOrdenation = useCallback(
    (orderKey: ExamsProceduresOrderByEnum, isInitialOrdering: boolean) => {
      if (handleListFilter) {
        if (isInitialOrdering) {
          handleListFilter({
            orderBy: orderKey,
            ordenationType: OrdenationTypeEnum.ASC,
          });
          setIsAscending(true);
        } else {
          handleListFilter({
            orderBy: orderKey,
            ordenationType: isAscending
              ? OrdenationTypeEnum.DESC
              : OrdenationTypeEnum.ASC,
          });
          setIsAscending((prevState) => !prevState);
        }

        setOrderBy(orderKey);
      }
    },
    [isAscending, handleListFilter],
  );

  const handleEditExamProcedure = useCallback(
    (examProcedureId: string) => {
      const examProcedure = examsProcedures.find(
        (item) => item.id === examProcedureId,
      );

      showModal({
        type: 'custom',
        data: (
          <AddEditModal
            examProcedure={examProcedure}
            canBack={!!handleListFilter}
            handleUpdateList={handleUpdateList}
            handleRemoveList={handleRemoveList}
          />
        ),
      });
    },
    [
      examsProcedures,
      showModal,
      handleUpdateList,
      handleListFilter,
      handleRemoveList,
    ],
  );

  return (
    <Container>
      <thead>
        <tr>
          {handleListFilter ? (
            <>
              <th>
                <SortButton
                  onClick={() =>
                    handleOrdenation(
                      ExamsProceduresOrderByEnum.NAME,
                      orderBy !== ExamsProceduresOrderByEnum.NAME,
                    )
                  }
                >
                  Nome
                  {orderBy === ExamsProceduresOrderByEnum.NAME &&
                  isAscending ? (
                    <TiArrowSortedUp />
                  ) : (
                    <TiArrowSortedDown />
                  )}
                </SortButton>
              </th>
              <th>
                <SortButton
                  onClick={() =>
                    handleOrdenation(
                      ExamsProceduresOrderByEnum.SEND_BY,
                      orderBy !== ExamsProceduresOrderByEnum.SEND_BY,
                    )
                  }
                >
                  Enviado por
                  {orderBy === ExamsProceduresOrderByEnum.SEND_BY &&
                  isAscending ? (
                    <TiArrowSortedUp />
                  ) : (
                    <TiArrowSortedDown />
                  )}
                </SortButton>
              </th>
              <th>
                <SortButton
                  onClick={() =>
                    handleOrdenation(
                      ExamsProceduresOrderByEnum.DATE,
                      orderBy !== ExamsProceduresOrderByEnum.DATE,
                    )
                  }
                >
                  Data
                  {orderBy === ExamsProceduresOrderByEnum.DATE &&
                  isAscending ? (
                    <TiArrowSortedUp />
                  ) : (
                    <TiArrowSortedDown />
                  )}
                </SortButton>
              </th>
            </>
          ) : (
            <>
              <th>Nome</th>
              <th>Enviado por</th>
              <th>Data</th>
            </>
          )}
        </tr>
      </thead>

      <tbody>
        {listData?.map((examProcedure) => (
          <tr key={examProcedure.id}>
            <td>{examProcedure.name}</td>

            <td>
              {examProcedure.sendByIcon}
              {examProcedure.sendByName}
            </td>

            <td>
              {examProcedure.date}
              <button
                type="button"
                onClick={() => handleEditExamProcedure(examProcedure.id)}
              >
                <MdRemoveRedEye />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Container>
  );
};

export default List;
