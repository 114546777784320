import { api } from '../api';
import { UpdateAppointmentDetailsRequestDTO } from '../../dtos/UpdateAppointmentDetailsRequestDTO';

export const updateAppointmentDetails = async ({
  anamnese,
  appointmentId,
  callStatus,
  cid,
  doctorObservation,
  observation,
}: UpdateAppointmentDetailsRequestDTO): Promise<number> => {
  const { status } = await api.put('/appointments/appointmentDetails', {
    anamnese,
    appointmentId,
    callStatus,
    cid,
    doctorObservation,
    observation,
  });

  return status;
};
