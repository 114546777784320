/* eslint-disable import/no-duplicates */
import React, { useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Chart } from 'react-google-charts';
import { useCurrentEffect } from '../../../../../../../../lib/use-current-effect';

import {
  getMeasurementsByDay,
  getMeasurementsByDateRange,
} from '../../../../../../../../services/metrics';
import { usePatient } from '../../../../../../../../hooks/patient';

import Default, { ShowScreenType } from '../_Default';
import { ChartsProps } from '../../ContentChart';
import { MetricTypeEnum } from '../../../../../../../../enums/MetricTypeEnum';

const BodyTemperatureChart: React.FC<ChartsProps> = ({ dateRange }) => {
  const { patient } = usePatient();

  const [showScreen, setShowScreen] = useState<ShowScreenType>('loading');
  const [graphData, setGraphData] = useState<(string | number)[][]>([
    ['00:00', 0],
  ]);

  useCurrentEffect(
    (isCurrent) => {
      (() => {
        if (patient?.id) {
          if (isCurrent()) {
            setShowScreen('loading');
          }

          const { startDate, endDate } = dateRange;

          if (startDate === endDate) {
            getMeasurementsByDay({
              patientId: patient.id,
              metricType: MetricTypeEnum.BODY_TEMPERATURE,
              dateFilter: startDate,
            })
              .then((response) => {
                if (isCurrent()) {
                  if (response.length > 0) {
                    const result = response.map(
                      ({ bodyTemperature, mensuramentDate }) => [
                        format(new Date(mensuramentDate), 'HH:mm', {
                          locale: ptBR,
                        }),
                        bodyTemperature,
                      ],
                    );

                    setShowScreen('chart');
                    setGraphData(result);
                  } else {
                    setShowScreen('noData');
                  }
                }
              })
              .catch(() => {
                if (isCurrent()) {
                  setShowScreen('noData');
                }
              });
          } else {
            getMeasurementsByDateRange({
              patientId: patient.id,
              metrics: MetricTypeEnum.BODY_TEMPERATURE,
              startDate,
              endDate,
            })
              .then((response) => {
                if (isCurrent()) {
                  if (response.length > 0) {
                    const result = response.map(
                      ({ bodyTemperature, mensuramentDate }) => [
                        format(new Date(mensuramentDate), "dd'/'MM", {
                          locale: ptBR,
                        }),
                        bodyTemperature,
                      ],
                    );

                    setShowScreen('chart');
                    setGraphData(result);
                  } else {
                    setShowScreen('noData');
                  }
                }
              })
              .catch(() => {
                if (isCurrent()) {
                  setShowScreen('noData');
                }
              });
          }
        }
      })();
    },
    [dateRange, patient],
  );

  return (
    <Default showScreen={showScreen}>
      <Chart
        width="100%"
        height="300px"
        chartType="LineChart"
        options={{
          legend: 'none',
          colors: ['#E12F54'],
          series: {
            0: { curveType: 'function' },
          },
          chartArea: { left: 40, right: 40 },
          vAxis: {
            ticks: [34, 35, 36, 37, 38, 39, 40],
          },
        }}
        data={[['h', '°C'], ...graphData]}
        rootProps={{ 'data-testid': '7' }}
      />
    </Default>
  );
};

export default BodyTemperatureChart;
