import styled from 'styled-components';

import { Container as GridCardContainer } from '../../../../../components/GridCard/styles';

export const Container = styled(GridCardContainer)`
  padding: 38px 55px;
`;

export const CardContent = styled.div`
  display: flex;

  h1 {
    font-size: 22px;
    font-weight: 600;
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    margin-top: 12px;
  }
`;

export const UserData = styled.div`
  margin-left: 35px;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
    font-weight: 700;

    svg {
      margin-left: 10px;
      height: 15px;
      width: 15px;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
`;

export const Name = styled.div`
  display: flex;
  align-items: center;

  span {
    background-color: #daedf7;
    border-radius: 5px;
    color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
    font-weight: 700;
    margin-left: 24px;
    padding: 3px 12px;
  }
`;

export const ContentTable = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 32px;

  > div:first-child {
    margin-right: 100px;
  }
`;

export const Column = styled.div`
  & + & > div > span:first-child {
    width: 110px;
  }
`;

export const Row = styled.div`
  display: flex;

  & + div {
    margin-top: 12px;
  }

  > span:first-child {
    text-align: right;
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 14px;
    font-weight: 600;
    margin-right: 18px;
    width: 62px;
  }

  > div {
    display: flex;
    flex-direction: column;

    > span + span {
      margin-top: 8px;
    }
  }
`;
