import React, { HTMLAttributes } from 'react';

import { Container } from './styles';
import MenuTop from '../../components/MenuTop';
import Menu from '../../components/Menu';
import MainAreasEnum from '../../enums/MainAreasEnum';

interface DashboardProps extends HTMLAttributes<HTMLDivElement> {
  name: MainAreasEnum | '';
}

const DashboardTemplate: React.FC<DashboardProps> = ({ name, children }) => {
  return (
    <Container>
      <MenuTop />
      <Menu name={name} />
      {children}
    </Container>
  );
};

export default DashboardTemplate;
