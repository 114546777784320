import React, { createContext, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { CreateAppointmentDTO } from '../dtos/CreateAppointmentDTO';
import { CreateAttendanceDTO } from '../dtos/CreateAttendanceDTO';
import { UpdateAppointmentStatusDTO } from '../dtos/UpdateAppointmentStatusDTO';
import { UpdateAppointmentDetailsDTO } from '../dtos/UpdateAppointmentDetailsDTO';
import { Appointment } from '../entities/Appointment';

import { usePatient } from './patient';
import { destroyVideoRoom } from '../services/videoCall';
import AppointmentTypeEnum from '../enums/AppointmentTypeEnum';
import RemoveAppointmentDTO from '../dtos/RemoveAppointmentDTO';
import { getAvatarImage } from '../services/user';
import { getAppointmentById } from '../services/appointments/GetAppointmentById';
import { createAppointment as createAppointmentService } from '../services/appointments/CreateAppointment';
import { createAttendance as createAttendanceService } from '../services/appointments/CreateAttendance';
import { updateAppointment as updateAppointmentService } from '../services/appointments/UpdateAppointment';
import { updateAppointmentStatus as updateAppointmentStatusService } from '../services/appointments/UpdateAppointmentStatus';
import { updateAppointmentDetails as updateAppointmentDetailsService } from '../services/appointments/UpdateAppointmentDetails';
import {
  deleteAppointment,
  getAppointmentsByProfessional,
} from '../services/appointments';
import { UpdateAppointmentDTO } from '../dtos/UpdateAppointmentDTO';
import { getAppointments as getAppointmentsService } from '../services/appointments/GetAppointments';
import { AppointmentListDTO } from '../dtos/AppointmentListDTO';
import { GetAppointmentsRequestDTO } from '../dtos/GetAppointmentsRequestDTO';

interface AppointmentContextData {
  appointmentSelected?: Appointment;
  appointmentsByProfessional: Appointment[];
  roomSid?: string;
  updateRoomSid(roomSid?: string): void;
  destroyRoom(): Promise<void>;
  saveLocal(appointmenData: Appointment): void;
  sync(id?: string): Promise<Appointment | undefined>;
  syncByProfessional(professionalId: string): Promise<void>;
  createAttendance(data: CreateAttendanceDTO): Promise<void>;
  removeAppointment(data: RemoveAppointmentDTO): Promise<boolean>;
  createAppointment(
    data: CreateAppointmentDTO,
    callback?: () => void,
  ): Promise<void>;
  updateAppointment(
    data: CreateAppointmentDTO,
    callback?: () => void,
  ): Promise<void>;
  updateAppointmentStatus(data: UpdateAppointmentStatusDTO): Promise<void>;
  getAppointments(data: GetAppointmentsRequestDTO): Promise<AppointmentListDTO>;
  updateAppointmentDetails(
    data: UpdateAppointmentDetailsDTO,
    syncAppointment: boolean,
  ): Promise<void>;
  cleanLocal(): void;
}

const AppointmentContext = createContext<AppointmentContextData>(
  {} as AppointmentContextData,
);

export const AppointmentProvider: React.FC = ({ children }) => {
  const { sync: syncPatient } = usePatient();
  const [appointmentSelected, setAppointmentSelected] = useState<Appointment>();
  const [appointmentsByProfessional, setAppointmentsByProfessional] = useState<
    Appointment[]
  >([]);
  const [roomSid, setRoomSid] = useState<string>();

  const updateRoomSid = useCallback((newRoomSid?: string) => {
    setRoomSid(newRoomSid);
  }, []);

  const destroyRoom = useCallback(async () => {
    if (roomSid) {
      await destroyVideoRoom(roomSid);
      setRoomSid(undefined);
    }
  }, [roomSid]);

  const saveLocal = useCallback((appointmenData: Appointment) => {
    setAppointmentSelected((state) =>
      state ? { ...state, ...appointmenData } : { ...appointmenData },
    );
  }, []);

  const sync = useCallback(
    async (id?: string): Promise<Appointment | undefined> => {
      let data;

      if (id) {
        data = await getAppointmentById(id);
      } else if (appointmentSelected) {
        data = await getAppointmentById(appointmentSelected.id as string);
      }

      if (data?.patient && data?.patient.user?.avatar) {
        const avatarImg = await getAvatarImage(data.patient.user.avatar);
        data.patient.user.avatarImage = avatarImg;
      }

      setAppointmentSelected(data);

      return data;
    },
    [appointmentSelected],
  );

  const syncByProfessional = useCallback(
    async (professionalId: string): Promise<void> => {
      const data = await getAppointmentsByProfessional({ professionalId });
      setAppointmentsByProfessional(data);
    },
    [],
  );

  const createAppointment = useCallback(
    async (
      createAppointmentData: CreateAppointmentDTO,
      callback?: () => void,
    ) => {
      try {
        const newAppointment = await createAppointmentService(
          createAppointmentData,
        );

        await syncPatient(createAppointmentData.patientId);
        setAppointmentSelected(newAppointment);

        if (callback) {
          callback();
        }
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      }
    },
    [syncPatient],
  );

  const createAttendance = useCallback(
    async (createAttendanceData: CreateAttendanceDTO) => {
      const newAttendance = await createAttendanceService(createAttendanceData);

      await syncPatient();

      if (createAttendanceData.attendanceType !== AppointmentTypeEnum.SMS) {
        const newTab = window.open(
          `/patient/attendance?id=${newAttendance.id}`,
          '_blank',
        );
        newTab?.focus();
      }

      setAppointmentSelected(newAttendance);
    },
    [syncPatient],
  );

  const updateAppointment = useCallback(
    async (
      updateAppointmentData: UpdateAppointmentDTO,
      callback?: () => void,
    ) => {
      try {
        const updatedAppointment = await updateAppointmentService({
          ...updateAppointmentData,
          appointmentId: appointmentSelected?.id as string,
        });

        await syncPatient(updateAppointmentData.patientId);
        setAppointmentSelected(updatedAppointment);

        if (callback) {
          callback();
        }
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      }
    },
    [appointmentSelected, syncPatient],
  );

  const updateAppointmentStatus = useCallback(
    async ({
      status,
      appointmentId,
      attendNow,
    }: UpdateAppointmentStatusDTO) => {
      const updatedAppointmentStatus = await updateAppointmentStatusService({
        appointmentId,
        status,
      });

      await syncPatient();
      await sync();

      setAppointmentSelected(updatedAppointmentStatus);

      if (attendNow) {
        const newTab = window.open(
          `/patient/attendance?id=${updatedAppointmentStatus.id}`,
          '_blank',
        );
        newTab?.focus();
      }
    },
    [sync, syncPatient],
  );

  const updateAppointmentDetails = useCallback(
    async (data: UpdateAppointmentDetailsDTO, syncAppointment: boolean) => {
      await updateAppointmentDetailsService(data);

      if (syncAppointment) {
        await sync();
      }
    },
    [sync],
  );

  const getAppointments = useCallback(
    async (data: GetAppointmentsRequestDTO) => {
      try {
        const appointmentList = await getAppointmentsService(data);

        return appointmentList;
      } catch (err) {
        toast.error(
          'Aconteceu um problema. Entre em contato com o administrador.',
        );
        return {
          list: [],
          totalPages: 0,
          totalAppointments: 0,
          totalAppointmentsInThisPage: 0,
        };
      }
    },
    [],
  );

  const cleanLocal = useCallback(() => {
    setAppointmentSelected(undefined);
    setRoomSid(undefined);
  }, []);

  const removeAppointment = useCallback(
    async (data: RemoveAppointmentDTO) => {
      try {
        await deleteAppointment(data);

        cleanLocal();

        return true;
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.message);
        }

        return false;
      }
    },
    [cleanLocal],
  );

  return (
    <AppointmentContext.Provider
      value={{
        roomSid,
        updateRoomSid,
        destroyRoom,
        appointmentSelected,
        appointmentsByProfessional,
        saveLocal,
        sync,
        syncByProfessional,
        createAppointment,
        createAttendance,
        updateAppointment,
        updateAppointmentStatus,
        getAppointments,
        updateAppointmentDetails,
        cleanLocal,
        removeAppointment,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};

export const useAppointment = (): AppointmentContextData => {
  const context = useContext(AppointmentContext);

  if (!context) {
    throw new Error('useAppointment must be used within a AppointmentProvider');
  }

  return context;
};
