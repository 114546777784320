import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import { Sheet } from '../../../../entities/Sheet';
import { getSheets } from '../../../../services/sheet';
import { FilterPatientDTO } from '../../../../dtos/FilterPatientDTO';
import { getAllHealthConditionFromPatients } from '../../../../services/patient/GetAllHealthConditionFromPatients';

import { Container, GridContent, Button, Field } from './styles';
import { useCurrentEffect } from '../../../../lib/use-current-effect';
import InputMaskAlternative from '../../../../components/InputMaskAlternative';
import InputAsyncSelect from '../../../../components/InputAsyncSelect';
import ButtonFilterRangeDate, {
  DateValueProps,
} from '../../../../components/ButtonFilterRangeDate';
import PatientStatusAlertEnum from '../../../../enums/PatientStatusAlertEnum';

interface Options {
  value: string;
  label: string;
}

interface FilterProps {
  setFilters: React.Dispatch<React.SetStateAction<FilterPatientDTO>>;
  onChangePage: (newPage: number) => any;
}

const Filter: React.FC<FilterProps> = ({ setFilters, onChangePage }) => {
  const formRef = useRef<FormHandles>(null);

  const [sheets, setSheets] = useState<Options[]>();

  const [datesSelected, setDatesSelected] = useState<Partial<DateValueProps>>(
    {},
  );

  const loadHealthConditionOptions = async (
    inputValue: string,
    _: any,
    additional?: { page: number },
  ) => {
    const { data: results } = await getAllHealthConditionFromPatients({
      limit: 10,
      page: additional?.page || 1,
      keyword: inputValue,
    });

    const icd10Options = results.map((option) => ({
      value: option.id,
      label: `${option.icd} - ${option.description}`,
    }));

    return {
      options: icd10Options,
      hasMore: results.length > 0,
      additional: { page: (additional?.page ? additional.page : 1) + 1 },
    };
  };

  useCurrentEffect((isCurrent) => {
    const getData = async () => {
      const allSheets = await getSheets();

      const sheetOptions = allSheets.map((sheet: Sheet) => ({
        value: sheet.id,
        label: sheet.name,
      }));

      sheetOptions.unshift({ value: '', label: 'Todas' });
      sheetOptions.push({ value: 'noSheets', label: 'Sem fichas' });

      if (isCurrent()) {
        setSheets(sheetOptions);
      }
    };

    getData();
  }, []);

  const riskDegreeOptions: Options[] = useMemo(
    () => [
      {
        value: '',
        label: 'Todos',
      },
      {
        value: 'safe',
        label: '1',
      },
      {
        value: 'low',
        label: '2',
      },
      {
        value: 'medium',
        label: '3',
      },
      {
        value: 'high',
        label: '4',
      },
    ],
    [],
  );

  const statusOptions: Options[] = useMemo(
    () => [
      {
        value: '',
        label: 'Todos',
      },
      {
        value: 'active',
        label: 'Ativo',
      },
      {
        value: 'draft',
        label: 'Rascunho',
      },
      {
        value: 'inactive',
        label: 'Inativo',
      },
    ],
    [],
  );

  const alertOptions: Options[] = useMemo(
    () => [
      {
        value: PatientStatusAlertEnum.IN_ALERT,
        label: 'Em alerta',
      },
      {
        value: PatientStatusAlertEnum.NO_HAVE_ALERT,
        label: 'Sem alerta',
      },
      {
        value: PatientStatusAlertEnum.ALL,
        label: 'Todos',
      },
    ],
    [],
  );

  const getValues = useCallback(() => {
    onChangePage(1);

    const { endDate, startDate } = datesSelected;

    const newFilters = { ...formRef.current?.getData() };

    setFilters((state) => ({ ...state, ...newFilters, endDate, startDate }));
  }, [onChangePage, datesSelected, setFilters]);

  const handleUpdateDate = useCallback(
    ({ startDate, endDate }: DateValueProps) => {
      if (startDate && endDate) {
        setDatesSelected({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        });
      }

      if (!startDate && !endDate) {
        setDatesSelected({});
      }
    },
    [],
  );

  return (
    <Container>
      <h1>Filtrar Pacientes</h1>
      <Form ref={formRef} onSubmit={getValues}>
        <GridContent>
          <Field gridArea="np">
            <label>Nome do paciente</label>
            <InputWithoutTitle name="name" />
          </Field>

          <Field gridArea="cpf">
            <label>CPF</label>
            <InputMaskAlternative
              type="text"
              name="cpf"
              title=""
              mask="999.999.999-99"
              placeholder="000.000.000-00"
            />
          </Field>

          <Field gridArea="ph">
            <label>Telefone</label>
            <InputMaskAlternative
              type="text"
              name="phone"
              title=""
              mask="(99) 9 9999-9999"
              placeholder="(00) 0 0000-0000"
            />
          </Field>

          <Field gridArea="gr">
            <label>Grau de Risco</label>
            <InputSelectAlternative
              name="risk"
              options={riskDegreeOptions}
              placeholder=""
            />
          </Field>

          <Field gridArea="fc">
            <label>Fichas de saúde</label>
            <InputSelectAlternative
              name="sheet"
              options={sheets}
              placeholder=""
            />
          </Field>

          <Field gridArea="rs">
            <label>Status de alerta</label>
            <InputSelectAlternative
              name="statusAlert"
              options={alertOptions}
              defaultValue={alertOptions[2]}
              placeholder=""
            />
          </Field>

          <Field gridArea="co">
            <label>Condição de saúde</label>
            <InputAsyncSelect
              name="icd10"
              cacheOptions
              defaultOptions
              loadOptions={loadHealthConditionOptions}
              placeholder=""
            />
          </Field>

          <Field gridArea="st">
            <label>Status</label>
            <InputSelectAlternative
              name="status"
              options={statusOptions}
              defaultValue={statusOptions[1]}
              placeholder=""
            />
          </Field>

          <Field gridArea="pr">
            <label>Período de atendimento</label>
            <ButtonFilterRangeDate
              dateValue={datesSelected}
              onChange={handleUpdateDate}
              canClearDates
            />
          </Field>

          <Field gridArea="bt">
            <Button>Buscar</Button>
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default Filter;
