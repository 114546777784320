import styled from 'styled-components';
import ButtonBase from '../Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 40px 35px;

  > svg {
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.danger};
  }

  > h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.danger};
  }
`;

export const Button = styled(ButtonBase)`
  border: 1px solid;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 15px 30px;
  background: transparent;

  + button {
    margin-left: 15px;
  }

  &:first-child {
    color: ${({ theme }) => theme.colors.danger};

    &:hover {
      background: ${({ theme }) => theme.colors.danger};
      color: white;
    }
  }

  &:last-child {
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      color: white;
    }
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 25px;

  display: flex;

  > button + button {
    margin-left: 15px;
    min-width: initial;
  }

  > button {
    border: 1px solid;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 100px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding: 15px 30px;
    background: transparent;
  }

  > button:first-child {
    color: ${({ theme }) => theme.colors.danger};

    &:hover {
      background: ${({ theme }) => theme.colors.danger};
      color: white;
    }
  }

  > button:last-child {
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      color: white;
    }
  }
`;
