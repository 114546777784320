import styled from 'styled-components';

import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import InputMask from '../../../../components/InputMask';

import datePicker from '../../../../assets/images/date-picker.svg';

interface FieldProps {
  gridArea:
    | 'no'
    | 'cp'
    | 'dn'
    | 'gn'
    | 'em'
    | 'cl'
    | 'en'
    | 'nm'
    | 'co'
    | 'ba'
    | 'ce'
    | 'ci'
    | 'uf'
    | 'cn'
    | 'wp';
}

export const Container = styled.div`
  min-height: 500px;
  width: 100%;
  padding-right: 80px;

  > h1 {
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;

    color: ${({ theme }) => theme.colors.grayTertiary};
  }

  .datepicker {
    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template: repeat(6, 1fr) / repeat(12, 1fr);
  grid-template-areas:
    'no no no no no no no no no cp cp cp'
    'dn dn dn gn gn gn em em em em em em'
    'ce ce ce en en en en en en en nm nm'
    'co co co co co co ba ba ba ba ba ba'
    'ci ci ci ci ci ci uf uf cn cn cn cn'
    'cl cl cl wp wp wp -- -- -- -- -- --';

  grid-column-gap: 45px;
  grid-row-gap: 35px;

  margin-top: 28px;
  width: 100%;
  height: 100%;

  @media (max-width: 1100px) {
    grid-template: repeat(6, 1fr) / repeat(12, 1fr);
    grid-template-areas:
      'no no no no no no no no cp cp cp cp'
      'dn dn dn dn gn gn em em em em em em'
      'ce ce ce en en en en en en en nm nm'
      'co co co co co co ba ba ba ba ba ba'
      'ci ci ci ci ci uf uf cn cn cn cn cn'
      'cl cl cl cl cl cl wp wp wp wp wp wp';
  }
`;

export const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: column;
  grid-area: ${({ gridArea }) => gridArea};

  > label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.01em;

    color: ${({ theme }) => theme.colors.grayTertiary};
    white-space: nowrap;

    > span {
      color: ${({ theme }) => theme.colors.danger};
      font-size: 18px;
      font-weight: 600;
      line-height: 0.8;
    }
  }

  input {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 14px;
  }

  > div {
    margin-top: 4px;

    > input[type='date']::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      background: url(${datePicker}) no-repeat;
      background-size: 75%;
      width: 24px;
      height: 24px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    max-height: 43px;
  }

  .cep {
    display: flex;

    button {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.primary};
      background-color: transparent;
      border: none;
      font-size: 32px;
      margin-left: 10px;
    }
  }
`;

export const InputSelect = styled(InputSelectAlternative)`
  > div:first-of-type {
    border: 1px solid ${({ theme }) => theme.colors.gray2};
    border-width: 1px !important;

    &:focus {
      border-width: 1px !important;
    }
  }

  > div .react-select__control {
    border-width: 1px !important;

    &:focus {
      border-width: 1px !important;
    }
  }

  .react-select__single-value {
    color: ${({ theme }) => theme.colors.graySecondary};
    font-size: 14px;
  }

  &:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }
`;

export const InputPhone = styled(InputMask)`
  input {
    padding-top: 0 !important;
  }

  > div:first-child {
    border: 1px solid ${({ theme }) => theme.colors.gray2};
    border-width: 1px !important;

    &:focus {
      border-width: 1px !important;
    }
  }
`;
