import React, { useCallback } from 'react';

import finishImage from '../../../assets/images/finish-registration.svg';

import { Container, StartButton } from './styles';
import { useAuth } from '../../../hooks/auth';

const FinishRegistration: React.FC = () => {
  const { signOut } = useAuth();
  const handleClick = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <Container>
      <img src={finishImage} alt="Término do registro" />
      <h1>Cadastro finalizado!</h1>
      <h2>
        Você receberá o seu login por e-mail em instantes e poderá fazer login
        no CINCO. Seja muito bem-vinda(o)!
      </h2>
      <StartButton onClick={handleClick}>Iniciar</StartButton>
    </Container>
  );
};

export default FinishRegistration;
