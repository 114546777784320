/* eslint-disable import/no-duplicates */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Chart } from 'react-google-charts';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useCurrentEffect } from '../../../../../../lib/use-current-effect';

import { usePatient } from '../../../../../../hooks/patient';
import { getMeasurementsByDay } from '../../../../../../services/metrics';

import MeasurementsArea from '../../MeasurementsArea';

import { ChildrenProps, ChildrenRefProps, DataChildrenProps } from '../..';
import { MetricTypeEnum } from '../../../../../../enums/MetricTypeEnum';

const BloodPressureChart: React.ForwardRefRenderFunction<
  ChildrenRefProps,
  ChildrenProps
> = ({ dateFilter }, ref) => {
  const { patient } = usePatient();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<DataChildrenProps>();
  const [paGraphData, setPaGraphData] = useState<(string | number)[][]>([
    ['00:00', 0, 0],
  ]);

  const handleChange = useCallback(
    async (patientId?: string, isCurrent = () => true) => {
      setIsLoading(true);
      if (!patientId) {
        return;
      }
      const response = await getMeasurementsByDay({
        patientId,
        metricType: MetricTypeEnum.BLOOD_PREASSURE,
        dateFilter,
      });
      if (response.length > 0) {
        const lastRecordTime = format(
          new Date(response[response.length - 1].mensuramentDate),
          "dd'/'MM'/'yyyy', ' HH'h'mm",
          { locale: ptBR },
        );

        const result = response.map(
          ({ hightPressure, lowPressure, mensuramentDate }) => [
            format(new Date(mensuramentDate), 'HH:mm', {
              locale: ptBR,
            }),
            hightPressure,
            lowPressure,
          ],
        );

        const pas = result[result.length - 1][1].toString();
        const pad = result[result.length - 1][2].toString();

        if (isCurrent()) {
          setPaGraphData(result);
          setData({
            lastRecordTime,
            value: `${pas}/${pad}`,
          });
        }
      } else if (isCurrent()) {
        setPaGraphData([['00:00', 0, 0]]);
        setData(undefined);
      }

      setIsLoading(false);
    },
    [dateFilter],
  );

  useCurrentEffect(
    (isCurrent) => {
      (() => {
        if (patient?.id) {
          handleChange(patient?.id, isCurrent);
        } else {
          setIsLoading(false);
        }
      })();
    },
    [dateFilter, handleChange, patient],
  );

  useImperativeHandle(ref, () => ({
    reload: () => handleChange(patient?.id),
  }));

  return (
    <MeasurementsArea
      data={{
        styleData: 'chart',
        isLoading,
        cardTitle: 'Pressão Arterial (mmHg)',
        dataDetails: data
          ? {
              title: 'último registro:',
              position: 'end',
              lastRecordTime: data.lastRecordTime,
              type: 'mmHg',
              value: data.value,
              inAlert: false,
            }
          : undefined,
      }}
    >
      <Chart
        chartType="LineChart"
        style={{
          marginLeft: '-10px',
        }}
        width="107%"
        height="100%"
        options={{
          colors: ['#2D9CDB', '#30E3E5'],
        }}
        data={[['h', 'PAS', 'PAD'], ...paGraphData]}
        rootProps={{ 'data-testid': '1' }}
      />
    </MeasurementsArea>
  );
};

export default forwardRef(BloodPressureChart);
