import React, { useCallback, useState } from 'react';

import { Icd10 as Icd10Base } from '../../entities/Icd10';
import { getAdditionalHealthConditionPatient } from '../../services/patient';
import TooltipHealthCondition from '../TooltipHealthCondition';
import Icd10Icon from '../Icd10Icon';

import { Container } from './styles';

interface Icd10 extends Icd10Base {
  isMainHealthCondition?: boolean;
}

interface HealthConditionDynamicProps {
  displayableIcd10?: Icd10;
  healthConditionsCount?: number;
}

const HealthConditionDynamic: React.FC<HealthConditionDynamicProps> = ({
  healthConditionsCount,
  displayableIcd10,
}) => {
  const [healthCondition, setHealthCondition] = useState<Icd10[]>([]);

  const handleGetHealthCondition = useCallback(async () => {
    if (healthCondition.length <= 0) {
      const data = await getAdditionalHealthConditionPatient();
      setHealthCondition(data);
    }
  }, [healthCondition]);

  return (
    <Container>
      {displayableIcd10?.chapter && (
        <Icd10Icon condition={displayableIcd10?.chapter} />
      )}
      <span>
        {`${displayableIcd10?.icd}-${displayableIcd10?.description} ${
          displayableIcd10?.isMainHealthCondition ? ' (principal)' : ''
        }`}
      </span>

      {!!healthConditionsCount && (
        <TooltipHealthCondition
          showSpinner={
            !!healthCondition &&
            healthConditionsCount !== healthCondition.length
          }
          onCountHover={handleGetHealthCondition}
          healthConditions={healthCondition}
          healthConditionsCount={healthConditionsCount}
        />
      )}
    </Container>
  );
};

export default HealthConditionDynamic;
