import React from 'react';
import { useModal } from 'react-brave-modal';

import ModalButton from '../../../components/ModalButton';
import { ReactComponent as WomanCheck } from '../../../assets/images/woman-check.svg';

import { Container, Content } from './styles';

const ActivePatient: React.FC = () => {
  const { closeModal } = useModal();

  return (
    <Container>
      <WomanCheck />
      <Content>
        <h1>O paciente está ativo!</h1>
        <p>
          Todas as pendências de cadastro foram resolvidas, portanto, este
          usuário está ativo e já pode receber agendamentos e atendimentos.
        </p>
        <ModalButton styleType="blue-light" onClick={() => closeModal()}>
          Fechar
        </ModalButton>
      </Content>
    </Container>
  );
};

export default ActivePatient;
