import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  Suspense,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useModal } from 'react-brave-modal';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FiMenu } from 'react-icons/fi';
import { BsPeople } from 'react-icons/bs';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import DashboardTemplate from '../../../templates/Dashboard';
import { useCurrentCallback } from '../../../lib/use-current-effect';
import {
  Container,
  Content,
  MenuOptions,
  Option,
  OptionComponent,
  ButtonsContainer,
  Button,
} from './styles';

import GeneralData from './GeneralData';
import Address from './Address';
import FinishRegisterModal from './modals/FinishRegister';
import CancelRegisterModal from './modals/CancelRegister';
import BindPatients from './BindPatients';
import { useInstitution } from '../../../hooks/institution';
import MainAreasEnum from '../../../enums/MainAreasEnum';

type TypePageType = 'register' | 'edit';

type SelectedType = 'GeneralData' | 'Address' | 'BindPatients';

export interface ComponentsInstitutionRegisterEditRef {
  cancel: () => Promise<boolean>;
  back: () => Promise<boolean>;
  next: () => Promise<boolean>;
  finish?: () => Promise<boolean | undefined>;
  save: () => Promise<boolean | undefined>;
  canSave?: () => Promise<boolean>;
}

export interface ComponentsInstitutionRegisterEditProps {
  onSaveSucceed?: () => void;
}

const Edit: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { cleanLocal } = useInstitution();
  const { showModal } = useModal();
  const generalDataRef = useRef<ComponentsInstitutionRegisterEditRef>(null);
  const addressRef = useRef<ComponentsInstitutionRegisterEditRef>(null);
  const bindPatientsRef = useRef<ComponentsInstitutionRegisterEditRef>(null);
  const [selected, setSelected] = useState<SelectedType>('GeneralData');
  const [type] = useState<TypePageType>(() =>
    location.pathname.includes('edit') ? 'edit' : 'register',
  );
  const [buttonLoadingStatus, setButtonLoadingStatus] = useState({
    finish: false,
    save: false,
    next: false,
  });

  useEffect(() => {
    return () => cleanLocal();
  }, [cleanLocal]);

  const pages = useMemo<ElementPageTree[]>(
    () => [
      {
        name: 'Administração',
      },
      {
        name: 'Instituições',
        link: '/institution/list',
      },
      {
        name: type === 'register' ? 'Nova Instituição' : 'Editar Instituição',
      },
    ],
    [type],
  );

  const handleProceedOnSaveSuccess = useCallback(() => {
    if (type === 'edit') {
      toast.success('Instituição atualizado com sucesso');
    }

    history.push('/institution/list');
  }, [history, type]);

  const options = useMemo(
    () => ({
      GeneralData: {
        key: 0,
        icon: <FiMenu />,
        name: 'Dados gerais',
        component: (
          <GeneralData
            ref={generalDataRef}
            onSaveSucceed={handleProceedOnSaveSuccess}
          />
        ),
        ref: generalDataRef,
        visible: true,
        buttons: ['cancel', 'back', 'next', 'save'],
      },
      Address: {
        key: 1,
        icon: <HiOutlineLocationMarker />,
        name: 'Endereço',
        component: (
          <Address
            ref={addressRef}
            onSaveSucceed={handleProceedOnSaveSuccess}
          />
        ),
        ref: addressRef,
        visible: true,
        buttons: ['cancel', 'back', 'next', 'save'],
      },
      BindPatients: {
        key: 2,
        icon: <BsPeople />,
        name: 'Vincular pacientes',
        component: (
          <BindPatients
            ref={bindPatientsRef}
            onSaveSucceed={handleProceedOnSaveSuccess}
          />
        ),
        ref: bindPatientsRef,
        visible: true,
        buttons: ['cancel', 'back', 'save', 'next', 'finish'],
      },
    }),
    [handleProceedOnSaveSuccess],
  );

  const handleChange = useCallback(
    async (keyName: SelectedType) => {
      const canSave =
        options[selected as keyof typeof options].ref.current?.canSave;

      if (canSave && (await canSave())) {
        const canProceed = await options[
          selected as keyof typeof options
        ].ref.current?.next();

        if (canProceed) {
          setSelected(keyName);
        }
      }
    },
    [options, selected],
  );

  const areThereAnyButtonsLoading = useMemo(
    () => Object.values(buttonLoadingStatus).some((value) => value),
    [buttonLoadingStatus],
  );

  const handleCancel = useCallback(async () => {
    const canProceed = await options[
      selected as keyof typeof options
    ]?.ref?.current?.cancel();

    if (canProceed) {
      showModal({
        type: 'custom',
        data: (
          <Suspense fallback={false}>
            <CancelRegisterModal />
          </Suspense>
        ),
      });
    }
  }, [selected, showModal, options]);

  const handleBack = useCurrentCallback(
    (isCurrent) => async () => {
      const canProceed = await options[
        selected as keyof typeof options
      ]?.ref?.current?.back();

      if (canProceed) {
        const steps = Object.entries(options);
        const newIndex = options[selected as keyof typeof options].key - 1;

        if (isCurrent()) {
          if (newIndex >= 0) {
            setSelected(steps[newIndex][0] as SelectedType);
          } else {
            setSelected(steps[0][0] as SelectedType);
          }
        }
      }
    },
    [options, selected],
  );

  const handleNext = useCurrentCallback(
    (isCurrent) => async () => {
      const canSave =
        options[selected as keyof typeof options]?.ref?.current?.canSave;

      if (canSave && (await canSave())) {
        setButtonLoadingStatus((state) => ({ ...state, next: true }));

        const canProceed = await options[
          selected as keyof typeof options
        ]?.ref?.current?.next();

        if (canProceed && isCurrent()) {
          const steps = Object.entries(options);
          const newIndex = options[selected as keyof typeof options].key + 1;

          setSelected(steps[newIndex][0] as SelectedType);
        }

        setButtonLoadingStatus((state) => ({ ...state, next: false }));
      }
    },
    [options, selected],
  );

  const handleFinish = useCurrentCallback(
    (isCurrent) => async () => {
      setButtonLoadingStatus((state) => ({ ...state, finish: true }));

      const canSave =
        options[selected as keyof typeof options].ref.current?.canSave;

      const finish =
        options[selected as keyof typeof options].ref.current?.finish;

      const canProceed =
        canSave && (await canSave()) && finish && (await finish());

      if (canProceed) {
        showModal({
          type: 'custom',
          closeActionSync: () => history.push('/institution/list'),
          data: (
            <Suspense fallback={false}>
              <FinishRegisterModal />
            </Suspense>
          ),
        });
      }

      isCurrent() &&
        setButtonLoadingStatus((state) => ({ ...state, finish: false }));
    },
    [options, selected, history, showModal],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <PageTree pages={pages} />
      <Container>
        <h1>
          {type === 'register' ? 'Nova Instituição' : 'Editar Instituição'}
        </h1>
        <Content>
          <MenuOptions>
            {Object.entries(options).map(
              ([keyName, { visible, icon, name, key }]) =>
                visible && (
                  <Option
                    key={keyName}
                    onClick={() => {
                      handleChange(keyName as SelectedType);
                    }}
                    selected={keyName === selected}
                    disabled={
                      options[selected as keyof typeof options].key < key &&
                      type !== 'edit'
                    }
                  >
                    {icon}

                    <span>{name}</span>
                  </Option>
                ),
            )}
          </MenuOptions>
          <OptionComponent>
            {options[selected as keyof typeof options].component}
          </OptionComponent>
        </Content>
      </Container>
      <ButtonsContainer>
        {options[selected as keyof typeof options].buttons.map((buttonType) => (
          <React.Fragment key={buttonType}>
            {buttonType === 'cancel' && (
              <Button
                buttonType="secondary"
                disabled={areThereAnyButtonsLoading}
                onClick={handleCancel}
                color="white"
              >
                Cancelar
              </Button>
            )}
            {buttonType === 'back' && (
              <Button
                buttonType="primary-alternative"
                disabled={
                  selected === 'GeneralData' || areThereAnyButtonsLoading
                }
                onClick={handleBack}
              >
                Voltar
              </Button>
            )}
            {buttonType === 'next' && (
              <Button
                buttonType="primary-alternative"
                disabled={
                  selected === 'BindPatients' ||
                  buttonLoadingStatus.finish ||
                  buttonLoadingStatus.save
                }
                loading={buttonLoadingStatus.next}
                onClick={handleNext}
              >
                Avançar
              </Button>
            )}
            {buttonType === 'finish' && type !== 'edit' && (
              <Button
                buttonType="gradient"
                disabled={buttonLoadingStatus.next || buttonLoadingStatus.save}
                loading={buttonLoadingStatus.finish}
                onClick={handleFinish}
              >
                Finalizar
              </Button>
            )}
          </React.Fragment>
        ))}
      </ButtonsContainer>
    </DashboardTemplate>
  );
};

export default Edit;
