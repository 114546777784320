import React, { useEffect, useRef, useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import ReactInputMask, { Props as InputMaskProps } from 'react-input-mask';

import { Container, Error } from './styles';

interface InputProps extends InputMaskProps {
  name: string;
  title: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const InputMask: React.FC<InputProps> = ({
  name,
  icon: Icon,
  title,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled] = useState(false);
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      <strong>{title}</strong>
      {Icon && <Icon size={20} />}
      <ReactInputMask
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore:next-line
        maskChar={null}
        ref={inputRef}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default InputMask;
