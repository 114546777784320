import React, { useCallback, useState } from 'react';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';

import { useModal } from 'react-brave-modal';
import { useHistory } from 'react-router-dom';
import {
  Day,
  DetailsAppointmentWrapper,
  Month,
  TimelineDay,
  TypeAppointment,
  Year,
} from './styles';
import { Appointment } from '../../../../../entities/Appointment';
import AppointmentTypeEnum from '../../../../../enums/AppointmentTypeEnum';

import { ReactComponent as IconPhoneOutline } from '../../../../../assets/images/icon-timeline-appointmentType-calling-outline-gray.svg';
import { ReactComponent as IconFilmOutline } from '../../../../../assets/images/icon-timeline-appointmentType-video-outline-gray.svg';
import { ReactComponent as IconMessageOutline } from '../../../../../assets/images/icon-timeline-appointmentType-sms-outline-gray.svg';
import AppointmentEventTooltip from '../AppointmentEventTooltip';
import ShowMessageScheduleModal from '../../../../../modals/ShowMessageScheduleModal';
import { useAppointment } from '../../../../../hooks/appointment';

interface AppointmentEventDetailsProps {
  position: 'top' | 'bottom';
  indexDay: number;
  indexMonth: number;
  appointment: Appointment;
  selected: boolean;
  disableScrolling: (disabledScroll: boolean) => void;
}

export const AppointmentEventDetails: React.FC<
  AppointmentEventDetailsProps
> = ({
  position,
  indexDay,
  appointment,
  indexMonth,
  selected,
  disableScrolling,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const { appointmentSelected, sync } = useAppointment();
  const { showModal } = useModal();
  const history = useHistory();

  const handleGoToConsult = useCallback(
    (id: string, type: AppointmentTypeEnum, text?: string, phone?: string) => {
      if (id === appointmentSelected?.id) {
        return;
      }
      if (
        type === AppointmentTypeEnum.VIDEO ||
        type === AppointmentTypeEnum.PHONE
      ) {
        history.push(`/patient/attendance?id=${id}`);
        sync(id);
        window.location.reload();
      } else if (text && phone) {
        showModal({
          type: 'custom',
          data: (
            <ShowMessageScheduleModal
              message={{
                text,
                phone,
              }}
            />
          ),
        });
      }
    },
    [appointmentSelected, history, showModal, sync],
  );

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsHovering(true);
    setCoords({ x: event.clientX, y: event.clientY });
  };

  const hasCoords = coords.x !== 0 && coords.y !== 0;

  return (
    <TimelineDay position={position} key={appointment.id}>
      <DetailsAppointmentWrapper position={position}>
        <div>
          {indexDay === 0 && (
            <Month>
              {format(new Date(appointment.details?.start || ''), 'MMMM', {
                locale: ptBR,
              })}
            </Month>
          )}
          {indexMonth === 0 && indexDay === 0 && (
            <Year>
              {new Date(appointment.details?.start || '').getFullYear()}
            </Year>
          )}
        </div>
        <div
          style={{ maxWidth: 82 }}
          onMouseEnter={() => {
            setIsHovering(true);
            disableScrolling(true);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
            disableScrolling(false);
          }}
        >
          <Day
            onMouseEnter={(e) => handleMouseEnter(e)}
            onMouseLeave={() => {
              setIsHovering(false);
              setCoords({ x: 0, y: 0 });
            }}
            selected={selected}
            onClick={() =>
              appointment.id &&
              appointment.type &&
              handleGoToConsult(
                appointment.id,
                appointment.type,
                appointment.message?.text,
                appointment.contact_phone ?? '',
              )
            }
          >
            {format(new Date(appointment.details?.start || ''), 'dd')}
            {isHovering &&
              appointment.id &&
              appointment &&
              coords &&
              hasCoords && (
                <AppointmentEventTooltip
                  coordMouseX={coords.x}
                  coordMouseY={coords.y}
                  appointment={appointment}
                  OnHandleGoToConsult={handleGoToConsult}
                  style={{ opacity: isHovering ? 1 : 0 }}
                />
              )}
          </Day>

          <TypeAppointment>
            <span>
              {appointment.type === AppointmentTypeEnum.PHONE && (
                <IconPhoneOutline />
              )}
              {appointment.type === AppointmentTypeEnum.VIDEO && (
                <IconFilmOutline />
              )}
              {appointment.type === AppointmentTypeEnum.SMS && (
                <IconMessageOutline />
              )}
            </span>
            <time dateTime={new Date(appointment.date || '').toTimeString()}>
              <span>{`${new Date(appointment.date || '').getHours()}h`}</span>

              <span>{new Date(appointment.date || '').getMinutes()}</span>
            </time>
          </TypeAppointment>
        </div>
      </DetailsAppointmentWrapper>
    </TimelineDay>
  );
};
