import { api } from '../api';

export const getAvatarImage = async (fileName: string): Promise<string> => {
  if (!fileName) {
    return '';
  }

  const { data } = await api.get<{ image: string }>(
    `/users/avatar/${fileName}`,
  );

  return data.image;
};
