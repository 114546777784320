import React from 'react';
import { Icd10 as Icd10Base } from '../../entities/Icd10';
import TooltipHealthCondition, {
  TooltipHealhConditionProps as TooltipHealhConditionsProps,
} from '../TooltipHealthCondition';
import { Container } from './styles';
import Icd10Icon from '../Icd10Icon';

interface Icd10 extends Icd10Base {
  isMainHealthCondition?: boolean;
}

interface HealthConditionsProps extends TooltipHealhConditionsProps {
  displayableIcd10?: Icd10;
}

const HealthConditions: React.FC<HealthConditionsProps> = ({
  healthConditions,
  displayableIcd10,
  healthConditionsCount,
}) => {
  return (
    <Container>
      {displayableIcd10?.chapter && (
        <Icd10Icon condition={displayableIcd10?.chapter} />
      )}
      <span>
        {`${displayableIcd10?.icd} - ${displayableIcd10?.description}${
          displayableIcd10?.isMainHealthCondition ? ' (principal)' : ''
        }`}
      </span>
      {healthConditions && healthConditions.length > 0 && (
        <TooltipHealthCondition
          healthConditions={healthConditions}
          healthConditionsCount={healthConditionsCount}
        />
      )}
    </Container>
  );
};

export default HealthConditions;
