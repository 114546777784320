import React from 'react';
import { useModal } from 'react-brave-modal';

import Banner from '../../../../../../../assets/images/profile_update_confirmation.svg';

import { Container } from '../styles';
import { Button, FooterSuccess } from './styles';

interface SuccessModalProps {
  keys: string[];
}

const Success: React.FC<SuccessModalProps> = ({ keys }) => {
  const { closeModal } = useModal();

  return (
    <Container>
      <section>
        <div>
          <img
            src={Banner}
            alt="Banner da página de sucesso da alteração de um perfil"
          />
        </div>
        <div>
          <h1>
            {keys.length > 1
              ? 'Os usuários foram adicionados!'
              : 'O usuário foi adicionado!'}
          </h1>
          <h2>
            {keys.length > 1
              ? 'Os usuários selecionados agora possuem as permissões definidas para o perfil selecionado.'
              : 'O usuário selecionado agora possui as permissões definidas para o perfil selecionado.'}
          </h2>
          <FooterSuccess>
            <Button onClick={() => closeModal()}>Ok</Button>
          </FooterSuccess>
        </div>
      </section>
    </Container>
  );
};

export default Success;
