import styled from 'styled-components';

import { CardDetails } from '../styles';

export const Container = styled(CardDetails)``;

export const CardAttention = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  z-index: 0;
  margin-top: 3px;
  color: #dd3636;
  margin-bottom: 30px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    strong {
      font-weight: 800;
      font-size: 13px;
      margin-left: 4px;
    }
  }

  > span {
    font-size: 14px;
    background: rgba(234, 118, 118, 0.2);
    border-radius: 6px;
    padding: 5px 10px;
    margin-top: 8px;
  }
`;

export const CardLatestRecords = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  z-index: 0;
  text-align: right;

  > h4 {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    color: #bdbdbd;
  }

  > img {
    align-self: center;
    margin: 10px 0 10px 20%;
  }

  > div {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: 15px;
    font-variant-numeric: lining-nums;
    color: ${({ theme }) => theme.colors.primary};
    row-gap: 12px;

    > h3 {
      font-weight: 600;
      font-size: 36px;
      text-align: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      height: 23px;
    }

    > h5 {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      margin-left: 4px;
    }
  }

  > div + div {
    display: flex;
    flex-direction: column;
    row-gap: 0;

    > span {
      font-size: 10px;
      line-height: 12px;
      color: #bdbdbd;
    }
  }
`;
