import { api } from '../../api';
import { Appointment } from '../../../entities/Appointment';
import AppointmentTypeEnum from '../../../enums/AppointmentTypeEnum';

export interface GetAttendancesByFilter {
  patientName?: string;
  professionalName?: string;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
  attendanceType?: AppointmentTypeEnum;
  sheet?: string;
  riskDegree?: string;
  orderBy?: string;
  ordenationType?: 'ASC' | 'DESC';
  page?: number;
  limit?: number;
}

export interface GetAttendancesByFilterResponse {
  attendances: Appointment[];
  totalAttendances: number;
  totalPages: number;
  totalAttendancesOnPage: number;
}

export const getAttendancesByFilter = async (
  attendanceData: GetAttendancesByFilter,
): Promise<GetAttendancesByFilterResponse> => {
  const { data } = await api.get<GetAttendancesByFilterResponse>(
    'attendances/filter',
    { params: { ...attendanceData } },
  );

  const attendanceListData = {
    attendances: data.attendances,
    totalAttendances: data.totalAttendances,
    totalPages: data.totalPages,
    totalAttendancesOnPage: data.totalAttendancesOnPage,
  };

  return attendanceListData;
};
