import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useModal } from 'react-brave-modal';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useCurrentCallback } from '../../../../lib/use-current-effect';

import { ModalProps } from '../..';
import InputIcon from '../../../../components/InputIcon';
import ModalButton from '../../../../components/ModalButton';
import { usePatient } from '../../../../hooks/patient';
import { useAppointment } from '../../../../hooks/appointment';
import { getAppointmentsByPatient } from '../../../../services/appointments';
import getValidationErrors from '../../../../utils/getValidationErrors';

import IconMessageGray from '../../../../assets/images/icon_schedule_message_gray.svg';

import { ButtonsContainer, Button } from '../../styles';
import {
  Container,
  InputNumber,
  InputTitle,
  MessageTable,
  StatusItemTable,
  ConfirmModal,
  Textarea,
} from './styles';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';

interface FormProps {
  phone?: string;
  message?: string;
}

interface HistorySMSProps {
  key?: string;
  professionalName?: string;
  messageText?: string;
  date: string;
  status?: string;
  color?: string;
}

const SMSTextHistory: React.FC<ModalProps> = ({ back, next }) => {
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { closeModal } = useModal();
  const { patient, sync } = usePatient();
  const { appointmentSelected, saveLocal } = useAppointment();
  const [historySMS, setHistorySMS] = useState<HistorySMSProps[]>([]);

  useEffect(() => {
    sync(appointmentSelected?.patientId ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [statusType] = useState([
    { key: 'scheduled', name: 'AGENDADO', color: '#2d9cdb' },
    { key: 'sent', name: 'ENVIADO', color: '#27AE60' },
    { key: 'error', name: 'FALHOU', color: '#E12F54' },
  ]);

  const getHistorySMS = useCurrentCallback(
    (isCurrent) => async (patientId: string) => {
      const data = await getAppointmentsByPatient({
        patientId,
        type: AppointmentTypeEnum.SMS,
      });

      const newHistorySMS = data.appointments
        .filter(
          (appointment) =>
            appointment.message?.status === 'scheduled' ||
            appointment.message?.status === 'sent' ||
            appointment.message?.status === 'error',
        )
        .map((appointment) => {
          const status = statusType.find(
            (s) => s.key === appointment.message?.status,
          );

          return {
            key: appointment.message?.id,
            professionalName: appointment.professional?.user?.name,
            messageText: appointment.message?.text,
            date: format(
              new Date(appointment.message?.sendDate ?? ''),
              new Date(appointment.message?.sendDate ?? '').getMinutes() !== 0
                ? "dd'/'MM'/'yy' 'HH':'mm'h'"
                : "dd'/'MM'/'yy' 'HH'h'",
            ),
            status: status?.name,
            color: status?.color,
          };
        });

      if (isCurrent()) {
        setHistorySMS(newHistorySMS);
      }
    },
    [statusType],
  );

  useEffect(() => {
    if (patient && patient.id) {
      getHistorySMS(patient.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  const validate = useCallback(async (data: FormProps) => {
    const schema = Yup.object().shape({
      phone: Yup.string()
        .required('O telefone é obrigatório')
        .length(16, 'Digite um número de telefone válido'),
      message: Yup.string()
        .required('Mensagem obrigatória')
        .max(150, 'Limite: 150 caracteres'),
    });

    await schema.validate(data, {
      abortEarly: false,
    });
  }, []);

  const handleNext = useCurrentCallback(
    (isCurrent) => async () => {
      const formData: FormProps = { ...formRef.current?.getData() };

      try {
        formRef.current?.setErrors({});

        await validate(formData);

        if (isCurrent()) {
          saveLocal({
            message: {
              ...appointmentSelected?.message,
              text: formData.message ?? '',
              contact_phone: formData.phone ?? '',
            },
            contact_phone: formData.phone,
          });

          if (next) {
            next();
          }
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }
      }
    },
    [appointmentSelected, next, saveLocal, validate],
  );

  const handleBack = useCallback(() => {
    if (back) {
      back();
    }
  }, [back]);

  return (
    <>
      <Container>
        <section>
          <header>
            <h1>
              Enviar Mensagem{' '}
              <img src={IconMessageGray} alt="Icone de message" />
            </h1>
          </header>
          <Form
            ref={formRef}
            initialData={{ phonenumber: patient?.user?.phone }}
            onSubmit={() => {}}
          >
            <InputTitle>Número do destinatário:</InputTitle>
            <InputNumber>
              <InputIcon
                name="phone"
                textSize="24px"
                textColor="#2d9cdb"
                mask="(99) 9 9999-9999"
                placeholder="(00) 00000-0000"
                defaultValue={appointmentSelected?.contact_phone}
              />
            </InputNumber>

            <InputTitle>Mensagem (até 150 caracteres)</InputTitle>
            <Textarea
              name="message"
              defaultValue={appointmentSelected?.message?.text}
            />
          </Form>
        </section>
        {!!historySMS?.length && (
          <section>
            <InputTitle>Histórico de Mensagens do Paciente</InputTitle>
            <MessageTable>
              <header>
                <h3>Remetente</h3>
                <h3>Mensagem</h3>
                <h3>Data/hora</h3>
              </header>

              <div>
                {historySMS.map((history) => (
                  <section key={history.key}>
                    <div>{history.professionalName}</div>
                    <div>
                      <p>{history.messageText}</p>
                    </div>
                    <div>
                      <p>{history.date}</p>
                      <StatusItemTable color={history.color}>
                        {history.status}
                      </StatusItemTable>
                    </div>
                  </section>
                ))}
              </div>
            </MessageTable>
          </section>
        )}
        {showModalConfirm && (
          <ConfirmModal>
            <h1>Você confirma o envio da mensagem?</h1>
            <footer>
              <ModalButton
                type="button"
                styleType="blue"
                onClick={() => formRef.current?.submitForm()}
              >
                Enviar
              </ModalButton>
              <ModalButton
                styleType="gray"
                type="button"
                onClick={() => setShowModalConfirm(false)}
              >
                Voltar
              </ModalButton>
            </footer>
          </ConfirmModal>
        )}
      </Container>

      <ButtonsContainer>
        <Button color="white" onClick={() => closeModal()}>
          Cancelar
        </Button>

        <Button color="primary" lite onClick={handleBack}>
          Voltar
        </Button>

        <Button color="primary" onClick={handleNext}>
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default SMSTextHistory;
