import { api } from '../api';

export const removeSheet = async (sheetId: string): Promise<boolean> => {
  const { status } = await api.delete('/patients/sheet', {
    data: {
      sheetId,
    },
  });

  return status === 204;
};
