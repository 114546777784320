import { api } from '../api';
import getBase64 from '../../utils/getBase64';
import { ExamProcedure } from '../../entities/ExamProcedure';

interface CreateExamProcedure {
  patientId: string;
  professionalId: string;
  examType: string;
  name: string;
  description: string;
  file: File;
}

export const createExamProcedure = async ({
  patientId,
  professionalId,
  examType,
  name,
  description,
  file,
}: CreateExamProcedure): Promise<ExamProcedure> => {
  let fileInBase64: string | undefined;

  if (file) {
    fileInBase64 = await getBase64(file);
  }

  const { data } = await api.post<ExamProcedure>('/patients/examProcedure', {
    patientId,
    professionalId,
    examType,
    name,
    description,
    file: fileInBase64,
  });

  return data;
};
