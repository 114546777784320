import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #fff;
  border-radius: 6px;
  position: relative;
  padding: 12px 16px;
  width: 100%;

  border: 2px solid #fff;
  color: #828282;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  strong {
    position: absolute;
    top: 9px;
    font-style: normal;
    font-weight: 600;
    font-size: 0.6rem;
    line-height: 0.5rem;
    text-transform: uppercase;
    color: #828282;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #4f4f4f;
      border-color: ${props.theme.colors.primary};
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #4f4f4f;
    `}



  input {
    flex: 1;
    border: 0;
    background: transparent;
    color: #000;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 14px;
    padding-top: 15px;

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &::placeholder {
      color: #4f4f4f;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
