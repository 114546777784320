import styled from 'styled-components';
import {
  Container as GridCard,
  CardTitle as TitleCard,
} from '../../../components/GridCard/styles';
import { colors } from '../../../styles/theme/colors';

interface ComorbidityColorBallType {
  color: string;
}

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  min-height: 85vh;
  grid-template: repeat(2, minmax(310px, 1fr)) / repeat(11, 1fr);
  grid-template-areas:
    'an an an an an ln ln ln ln ln ln'
    'ni ni ni ni ni ni ni ni ni ni ni';
  align-items: flex-start;
  justify-items: center;
  grid-gap: 24px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    grid-template: 1fr 2fr 2fr / 1fr;
    grid-template-areas:
      'an'
      'ln'
      'ni';
  }
`;

export const CardContainer = styled(GridCard)`
  padding: 20px 30px;
  width: 100%;
  height: 100%;
  max-height: 42vh;
  min-height: 310px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    padding: 20px;
    max-height: 60vh;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    padding: 20px 10px;
    max-height: 90vh;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 15px 10px;
    max-height: 190vh;
  }
`;

export const CardTitle = styled(TitleCard)``;

export const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: ${colors.primary};
    border: 0;
    background: transparent;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ComorbidityColorBall = styled.div<ComorbidityColorBallType>`
  border-radius: 50%;
  background-color: ${({ color }) => color};
  width: 10px;
  height: 10px;
`;
