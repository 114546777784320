import styled from 'styled-components';
import { Container as GridCard } from '../../../../components/GridCard/styles';

interface DotProps {
  color: string;
}

export const Container = styled(GridCard)``;

export const Content = styled.div`
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const LegendContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin: 4px 30px;

  font-family: Raleway;
  font-style: normal;
  letter-spacing: 0em;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  strong {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-align: left;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }
`;

export const TotalLegend = styled.div`
  position: absolute;
  width: 100px;
  left: 37%;
  top: 43%;
  text-align: center;
  font-family: 'Raleway';
  font-style: normal;

  > h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: ${({ theme }) => theme.colors.gray3};
  }

  > h4 {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: ${({ theme }) => theme.colors.grayAlternative};
  }
`;

export const Dot = styled.div<DotProps>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;

  background: ${({ color }) => color};
`;

export const ChartContainer = styled.div`
  position: relative;
`;
