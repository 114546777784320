import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import { BsFillEyeFill } from 'react-icons/bs';
import { RiDownloadLine } from 'react-icons/ri';
import { ComponentsProfileRef } from '..';

import { ReactComponent as ReportsIcon } from '../../../../assets/images/file2.svg';

import {
  Container,
  CardHeader,
  CardContent,
  ReportBox,
  ReportTitle,
  ButtonGroup,
} from './styles';

const Reports: React.ForwardRefRenderFunction<ComponentsProfileRef> = (
  _,
  ref,
) => {
  const reports = useMemo(
    () => [
      {
        name: 'Dados de Uso',
      },
      {
        name: 'Histórico de Atendimentos',
      },
      {
        name: 'Atendimentos Agendados',
      },
    ],
    [],
  );

  useImperativeHandle(ref, () => ({
    save: async () => true,
    back: async () => true,
  }));

  return (
    <Container>
      <CardHeader>
        <h1>Relatórios</h1>
      </CardHeader>
      <CardContent>
        {reports.map((report) => (
          <ReportBox key={report.name}>
            <ReportTitle>
              <ReportsIcon />
              <span>{report.name}</span>
            </ReportTitle>
            <ButtonGroup>
              <button type="button">
                <BsFillEyeFill />
                <span>VISUALIZAR</span>
              </button>
              <button type="button">
                <RiDownloadLine />
                <span>BAIXAR</span>
              </button>
            </ButtonGroup>
          </ReportBox>
        ))}
      </CardContent>
    </Container>
  );
};

export default forwardRef(Reports);
