import React, { useRef, useState, useMemo } from 'react';
import { FormHandles } from '@unform/core';

import Filter from './Filter';
import Table from './Table';
import DashboardTemplate from '../../../../templates/Dashboard';
import PageTree from '../../../../components/PageTree';
import { ElementPageTree } from '../../../../components/PageTree/types';
import Spinner from '../../../../components/Spinner';
import Pagination from '../../../../components/Pagination';
import {
  getAttendancesByFilter,
  GetAttendancesByFilter as ListFilter,
  GetAttendancesByFilterResponse as ListData,
} from '../../../../services/reports/attendances';
import { useCurrentEffect } from '../../../../lib/use-current-effect';
import MainAreasEnum from '../../../../enums/MainAreasEnum';

const List: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const [listFilter, setListFilter] = useState<ListFilter>({
    orderBy: 'patientName',
    ordenationType: 'ASC',
    limit: 10,
  });
  const [listData, setListData] = useState<ListData>({
    attendances: [],
    totalAttendances: 0,
    totalPages: 0,
    totalAttendancesOnPage: 0,
  });

  const pages = useMemo<ElementPageTree[]>(
    () => [{ name: 'Relatórios' }, { name: 'Atendimentos' }],
    [],
  );

  useCurrentEffect(
    (isCurrent) => {
      getAttendancesByFilter({ ...listFilter, page: currentPage })
        .then((listDataResponse) => {
          if (isCurrent()) {
            setListData(listDataResponse);
          }
        })
        .finally(() => {
          if (isCurrent()) {
            setIsLoading(false);
          }
        });
    },
    [currentPage, listFilter],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.REPORTS}>
      <PageTree pages={pages} />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Filter
            onChangePage={(page: number) => setCurrentPage(page)}
            setListFilter={setListFilter}
            formRef={formRef}
          />
          <Table setListFilter={setListFilter} listData={listData} />
          {listData.attendances.length > 0 && (
            <Pagination
              totalPages={listData.totalPages}
              page={currentPage}
              onChangePage={(page: number) => setCurrentPage(page)}
            />
          )}
        </>
      )}
    </DashboardTemplate>
  );
};

export default List;
