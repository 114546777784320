import React, { useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useCurrentEffect } from '../../../lib/use-current-effect';

import Window from '../../../components/Window';
import PasswordRedefinition from './PasswordRedefinition';
import FinishRedefinition from './FinishRedefinition';
import useQuery from '../../../hooks/query';
import { identifyUserType } from '../../../services/user';

import { Container } from './styles';

const ResetPassword: React.FC = () => {
  const [stepKey, setStepKey] = useState('Redefinition');
  const [userType, setUserType] = useState<string>('patient');
  const history = useHistory();
  const query = useQuery();
  const token = useMemo(() => query.get('token'), [query]);

  useCurrentEffect(
    (isCurrent) => {
      if (token) {
        identifyUserType(token).then((result) => {
          if (isCurrent()) {
            setUserType(result);
          }
        });
      } else {
        history.push('/login');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token],
  );

  const handleStepChange = useCallback((key: string) => {
    setStepKey(key);
  }, []);

  const steps = useMemo(
    () => [
      {
        component: (
          <PasswordRedefinition
            handleStepChange={handleStepChange}
            token={token ?? ''}
          />
        ),
        key: 'Redefinition',
      },
      {
        component: <FinishRedefinition userType={userType ?? ''} />,
        key: 'FinishRedefinition',
      },
    ],
    [handleStepChange, token, userType],
  );

  return (
    <Window userType={userType}>
      <Container>
        {steps.find((step) => step.key === stepKey)?.component}
      </Container>
    </Window>
  );
};

export default ResetPassword;
