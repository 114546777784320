/* eslint-disable import/no-duplicates */
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { BsPencil } from 'react-icons/bs';

import { useModal } from 'react-brave-modal';
import { NextAppointment } from '../..';
import { useAppointment } from '../../../../../../hooks/appointment';

import {
  CardHeader,
  Content,
  ContentNav,
  DataSection,
  DateView,
  HourView,
  DetailSection,
} from './styles';

import {
  ContainerScreensDefault,
  CardFooterDefault,
  ButtonFooterDefault,
} from '../../styles';

import filmImageBlue from '../../../../../../assets/images/icon_schedule_film_blue.svg';
import phoneImageBlue from '../../../../../../assets/images/icon_schedule_phone_blue.svg';
import messageImageBlue from '../../../../../../assets/images/icon_schedule_message_blue.svg';

import timeImageGray from '../../../../../../assets/images/icon_schedule_time_spacegray.svg';
import scheduleImageGray from '../../../../../../assets/images/icon_schedule_schedule_spacegray.svg';
import consultImageGray from '../../../../../../assets/images/iconeConsulta.svg';

import { usePermission } from '../../../../../../hooks/permission';
import RunAppointmentNewTabModal from '../../../../../../modals/RunAppointmentNewTabModal';
import { useAuth } from '../../../../../../hooks/auth';
import AppointmentTypeEnum from '../../../../../../enums/AppointmentTypeEnum';
import AppointmentStatusEnum from '../../../../../../enums/AppointmentStatusEnum';
import { usePatient } from '../../../../../../hooks/patient';

interface AfterSchedulingProps {
  appointment: NextAppointment;
  index: number;
  totalAppointments: number;
  loadingAppointments: boolean;
  change: (value: 'AfterScheduling' | 'Main') => void;
  setIndex: (value: number) => void;
}

const AfterScheduling: React.FC<AfterSchedulingProps> = ({
  appointment,
  index,
  totalAppointments,
  change,
  setIndex,
  loadingAppointments,
}) => {
  const { hasPermission } = usePermission();
  const { updateAppointmentStatus } = useAppointment();
  const { showModal } = useModal();
  const { user } = useAuth();
  const { patient } = usePatient();

  const canStart = useMemo(() => {
    switch (appointment.type) {
      case AppointmentTypeEnum.PHONE:
        return (
          hasPermission('call_attendance') && user?.type === 'professional'
        );
      case AppointmentTypeEnum.SMS:
        return (
          hasPermission('message_attendance') && user?.type === 'professional'
        );
      case AppointmentTypeEnum.VIDEO:
        return (
          hasPermission('video_attendance') && user?.type === 'professional'
        );
      default:
        return false;
    }
  }, [appointment, hasPermission, user]);

  const canCreate = useMemo(
    () =>
      hasPermission('register_appointment') && user?.type === 'professional',
    [hasPermission, user],
  );

  const handleOnConfirm = useCallback(async () => {
    await updateAppointmentStatus({
      appointmentId: appointment.id,
      status: AppointmentStatusEnum.STARTED,
      attendNow: true,
    });
  }, [appointment, updateAppointmentStatus]);

  const handleStartAttendance = useCallback(() => {
    if (patient?.id === appointment.patientId) {
      const appointmentData = {
        type: appointment.type,
        date: appointment.date,
        patientName: patient?.user?.name,
        professionalName: appointment.professional?.user?.name,
        patientPhone: appointment.contact_phone,
      };

      showModal({
        type: 'custom',
        data: (
          <RunAppointmentNewTabModal
            onConfirm={handleOnConfirm}
            appointment={appointmentData}
          />
        ),
      });
    }
  }, [appointment, handleOnConfirm, showModal, patient]);

  return (
    <ContainerScreensDefault>
      <CardHeader>
        <h2>Próximo atendimento</h2>

        {canStart ? (
          <Link to="/appointments/list?appointments=my">Ver todos</Link>
        ) : (
          <span>Ver todos</span>
        )}
      </CardHeader>

      <Content>
        <ContentNav>
          <button
            disabled={index === 1 || !canStart}
            type="button"
            onClick={() => canStart && setIndex(index - 1)}
          >
            <MdKeyboardArrowLeft size={25} />
          </button>

          <div>
            {appointment.type === AppointmentTypeEnum.PHONE && (
              <div>
                <img src={phoneImageBlue} alt="" />
                <h4>Ligação agendada</h4>
              </div>
            )}
            {appointment.type === AppointmentTypeEnum.VIDEO && (
              <div>
                <img src={filmImageBlue} alt="" />
                <h4>Atendimento por Vídeo</h4>
              </div>
            )}
            {appointment.type === AppointmentTypeEnum.SMS && (
              <div>
                <img src={messageImageBlue} alt="" />
                <h4>Mensagem agendada</h4>
              </div>
            )}
            <div>
              <span>
                <b>{index}</b> de {totalAppointments}
              </span>
            </div>
          </div>

          <button
            disabled={
              index === totalAppointments || loadingAppointments || !canStart
            }
            type="button"
            onClick={() => canStart && setIndex(index + 1)}
          >
            <MdKeyboardArrowRight size={25} />
          </button>
        </ContentNav>
        {appointment.type !== AppointmentTypeEnum.SMS ? (
          <>
            <DataSection>
              <div>
                <img src={scheduleImageGray} alt="" />

                <DateView>
                  <h4>{format(new Date(appointment.date ?? ''), 'dd')}</h4>
                  <div>
                    <span>
                      {format(new Date(appointment.date ?? ''), 'MMM', {
                        locale: ptBR,
                      })}
                    </span>

                    <span>
                      {format(new Date(appointment.date ?? ''), 'yyyy')}
                    </span>
                  </div>
                </DateView>
              </div>
              <div>
                <img src={timeImageGray} alt="" />

                <HourView>
                  <h2>
                    {format(new Date(appointment.date ?? ''), "HH'h 'mm'm'")}
                  </h2>
                </HourView>
              </div>
            </DataSection>

            <DetailSection>
              <div>
                <img src={consultImageGray} alt="" />

                <span>
                  {appointment?.professional?.user?.name?.split(' ')[0] ?? ''}
                </span>
              </div>
            </DetailSection>
          </>
        ) : (
          <>
            <DataSection>
              <p>{appointment?.message?.text}</p>
            </DataSection>

            <DetailSection>
              <div>
                <div>
                  <img src={scheduleImageGray} alt="" />

                  <span>
                    {format(new Date(appointment.date ?? ''), "dd'/'MM'/'yyyy")}
                  </span>
                </div>

                <div>
                  <img src={timeImageGray} alt="" />

                  <span>
                    {format(new Date(appointment.date ?? ''), "HH':'mm'h'")}
                  </span>
                </div>
              </div>

              <button type="button">
                Editar
                <BsPencil size={13} />
              </button>
            </DetailSection>
          </>
        )}
      </Content>

      <CardFooterDefault>
        {appointment.type !== AppointmentTypeEnum.SMS && (
          <ButtonFooterDefault
            disabled={!canStart}
            type="button"
            color="primary"
            onClick={() => handleStartAttendance()}
          >
            Iniciar consulta
          </ButtonFooterDefault>
        )}

        <ButtonFooterDefault
          disabled={!canCreate}
          type="button"
          color="light"
          onClick={() => change('Main')}
        >
          Nova consulta
        </ButtonFooterDefault>
      </CardFooterDefault>
    </ContainerScreensDefault>
  );
};

export default AfterScheduling;
