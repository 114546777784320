import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import DashboardTemplate from '../../../templates/Dashboard';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import PhoneCall from '../../../components/PhoneCall';
import VideoCall, { VideoCallRef } from '../../../components/VideoCall';
import InputCopy from '../../../components/InputCopy';
import Button from '../../../components/Button';
import history from '../../../services/history';
import {
  Container,
  HeaderAnamnese,
  SectionAnamnese,
  ConsultArea,
  Title,
  Subtitle,
  VideoBox,
  FooterAnamnese,
} from './styles';
import { useAppointment } from '../../../hooks/appointment';
import PersonalDataCard from '../Profile/PersonalDataCard';
import NextAppointments from '../Profile/NextAppointments';
import { usePatient } from '../../../hooks/patient';
import SectionSummary from './SectionSummary';
import AttendanceDataArea, {
  AttendanceDataAreaRef,
} from './AttendanceDataArea';
import { usePermission } from '../../../hooks/permission';
import { useCurrentEffect } from '../../../lib/use-current-effect';
import { useAuth } from '../../../hooks/auth';
import DigitalPrescription from './DigitalPrescriptionCard';

import AppointmentTypeEnum from '../../../enums/AppointmentTypeEnum';
import AppointmentStatusEnum from '../../../enums/AppointmentStatusEnum';
import RoleEnum from '../../../enums/RoleEnum';
import MainAreasEnum from '../../../enums/MainAreasEnum';

type FooterButtonDataProps = {
  [key: string]: {
    props: React.ComponentPropsWithoutRef<typeof Button>;
    text: string;
  };
};

const Attendance: React.FC = () => {
  const [inCall, setInCall] = useState<boolean>(false);

  const [didSync, setDidSync] = useState<boolean>(false);

  const { hasPermission } = usePermission();

  const dataRef = useRef<AttendanceDataAreaRef>(null);
  const videoCallRef = useRef<VideoCallRef>(null);

  const [id] = useState(new URLSearchParams(useLocation().search).get('id'));

  const { user } = useAuth();

  const {
    sync: syncAppointment,
    appointmentSelected,
    cleanLocal,
    updateAppointmentDetails,
  } = useAppointment();
  const { sync: syncPatient, patient } = usePatient();
  const pagesPrev: ElementPageTree[] = useMemo(
    () => [
      { name: 'Início' },
      { name: 'Consultas', link: '/doctor/dashboard/consults' },
      { name: 'Paciente', link: `/patient/profile?id=${patient?.id}` },
    ],
    [patient],
  );

  const [appointmentData, setAppointmentData] = useState({
    type: appointmentSelected?.type,
    patientId: appointmentSelected?.patient?.id,
  });

  useEffect(() => {
    if (
      appointmentSelected?.type !== appointmentData.type ||
      appointmentSelected?.patientId !== appointmentData.patientId
    ) {
      setAppointmentData({
        patientId: appointmentSelected?.patientId,
        type: appointmentSelected?.type,
      });
    }
  }, [appointmentData, appointmentSelected]);

  const [canStayHere, setCanStayHere] = useState(true);

  useEffect(() => {
    if (id) {
      syncAppointment(id)
        .then(() => {
          setDidSync(true);
        })
        .catch((error) => {
          history.push(`/patient/list`);

          if (error.response) {
            toast.error(error.response.data.message);
          }
        });
    } else {
      history.push(`/patient/list`);
    }

    return () => {
      cleanLocal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useCurrentEffect(
    (isCurrent) => {
      switch (appointmentData.type) {
        case AppointmentTypeEnum.PHONE:
          if (isCurrent()) {
            setCanStayHere(hasPermission('call_attendance'));
          }
          break;
        case AppointmentTypeEnum.VIDEO:
          if (isCurrent()) {
            setCanStayHere(hasPermission('video_attendance'));
          }
          break;
        case AppointmentTypeEnum.SMS:
          if (isCurrent()) {
            setCanStayHere(false);
          }
          break;
        default:
          break;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [patient],
  );

  useEffect(
    () => {
      if (appointmentData.patientId) {
        syncPatient(appointmentData.patientId);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointmentData],
  );

  const handleBack = useCallback(() => {
    if (history.length <= 1) {
      history.push(`/patient/profile?id=${appointmentData?.patientId}`);
    } else {
      history.goBack();
    }
  }, [appointmentData]);

  const [buttonStatusLoading, setButtonStatusLoading] = useState(false);

  const handleFinished = useCallback(async () => {
    setButtonStatusLoading(true);

    await dataRef.current?.finish();

    setButtonStatusLoading(false);
  }, []);

  const footerButtonData = useMemo(
    () =>
      appointmentSelected?.status &&
      (
        {
          [AppointmentStatusEnum.STARTED]: {
            props: {
              disabled: inCall,
              onClick: () => handleFinished(),
            },
            text: 'Concluir',
          },
          [AppointmentStatusEnum.UNFINISHED]: {
            props: {
              buttonType: 'primary-alternative',
              loading: buttonStatusLoading,
              onClick: () => handleFinished(),
            },
            text: 'Finalizar',
          },
          [AppointmentStatusEnum.FINISHED]: {
            props: {
              buttonType: 'primary-alternative',
              onClick: () => handleBack(),
            },
            text: 'Voltar',
          },
        } as FooterButtonDataProps
      )[appointmentSelected.status],
    [
      appointmentSelected,
      buttonStatusLoading,
      handleBack,
      handleFinished,
      inCall,
    ],
  );

  useEffect(() => {
    if (!canStayHere) {
      handleBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canStayHere]);

  useEffect(() => {
    return () => {
      videoCallRef?.current?.leaveTheRoom();
    };
  }, []);

  const handleCallHangUp = useCallback(async () => {
    await updateAppointmentDetails(
      {
        appointmentId: appointmentSelected?.id,
      },
      false,
    );
  }, [appointmentSelected, updateAppointmentDetails]);

  return (
    <DashboardTemplate name={MainAreasEnum.PATIENTS}>
      <PageTree pages={pagesPrev} />
      <Container>
        <HeaderAnamnese>
          <PersonalDataCard
            isPageAttendanceSummary={
              appointmentSelected?.status === AppointmentStatusEnum.FINISHED
            }
            gridArea="pr"
          />
          {user?.professional?.role !== RoleEnum.DOCTOR ||
          !user?.professional?.memedToken ? (
            <NextAppointments showAttendanceNow={false} gridArea="pa" />
          ) : (
            <DigitalPrescription gridArea="pa" />
          )}
        </HeaderAnamnese>
        {didSync && (
          <>
            {appointmentSelected?.status !== AppointmentStatusEnum.STARTED ? (
              <SectionSummary ref={dataRef} />
            ) : (
              <SectionAnamnese id="SectionAnamnese">
                {appointmentSelected?.status ===
                  AppointmentStatusEnum.STARTED && (
                  <ConsultArea>
                    <Title>Atendimento</Title>
                    {appointmentSelected?.type ===
                      AppointmentTypeEnum.PHONE && (
                      <PhoneCall
                        inCall={inCall}
                        setInCall={setInCall}
                        onHangUp={handleCallHangUp}
                        phoneNumber={appointmentSelected?.contact_phone}
                        appointmentId={appointmentSelected?.id ?? ''}
                      />
                    )}
                    {appointmentSelected.type === AppointmentTypeEnum.VIDEO && (
                      <VideoBox isVisible>
                        <VideoCall
                          roomName={appointmentSelected?.id ?? ''}
                          identity={appointmentSelected?.professionalId ?? ''}
                          isProfessional
                          setInCall={setInCall}
                          ref={videoCallRef}
                          onHangUp={handleCallHangUp}
                        />
                        <Subtitle>Sala da consulta:</Subtitle>
                        <InputCopy value={appointmentSelected.videoRoom} />
                      </VideoBox>
                    )}
                  </ConsultArea>
                )}
                <AttendanceDataArea ref={dataRef} />
              </SectionAnamnese>
            )}
            {footerButtonData && (
              <FooterAnamnese>
                <Button {...footerButtonData.props}>
                  {footerButtonData.text}
                </Button>
              </FooterAnamnese>
            )}
          </>
        )}
      </Container>
    </DashboardTemplate>
  );
};

export default Attendance;
