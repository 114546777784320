import React from 'react';
import { useModal } from 'react-brave-modal';

import ModalButton from '../../../../../components/ModalButton';
import Banner from '../../../../../assets/images/check-ok-2.svg';

import { Container } from './styles';

const FinishEditRiskDegreeModal: React.FC = () => {
  const { closeModal } = useModal();

  return (
    <Container>
      <img src={Banner} alt="" />
      <h1>Grau de risco atualizado!</h1>
      <ModalButton styleType="blue-light" onClick={() => closeModal()}>
        Fechar
      </ModalButton>
    </Container>
  );
};

export default FinishEditRiskDegreeModal;
