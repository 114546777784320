import React, {
  forwardRef,
  Suspense,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { useModal } from 'react-brave-modal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ComponentsInstitutionRegisterEditProps,
  ComponentsInstitutionRegisterEditRef,
} from '..';
import Button from '../../../../components/Button';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';
import { useInstitution } from '../../../../hooks/institution';

import { Container, Header, Content } from './styles';
import CloudFileGrayImage from '../../../../assets/images/cloud-file-gray.svg';
import BindPatientsModal from '../modals/BindPatients';
import Table from './Table';
import { BulkList } from '../../../../components/InputSuggestUser';
import BoundPatientsModal from '../modals/BoundPatients';

const BindPatients: React.ForwardRefRenderFunction<
  ComponentsInstitutionRegisterEditRef,
  ComponentsInstitutionRegisterEditProps
> = ({ onSaveSucceed }, ref) => {
  const history = useHistory();
  const { showModal } = useModal();
  const { institution, save, cleanLocal } = useInstitution();
  const [bulkList, setBulkList] = useState<BulkList[]>([]);

  useImperativeHandle(ref, () => ({
    back: async () => true,
    cancel: async () => true,
    next: async () => true,
    save: async () => true,
    finish: handleFinish,
    canSave: async () => true,
  }));

  const handleFinish = useCallback(async () => {
    try {
      const patientsId = bulkList.map((patient) => ({ id: patient.id }));
      const response = await save({ ...institution, patients: patientsId });

      if (response.patientsLists.relatedPatients.length > 0) {
        showModal({
          type: 'custom',
          closeActionSync: () => history.push('/institution/list'),
          data: (
            <Suspense fallback={false}>
              <BoundPatientsModal
                patients={response.patientsLists.relatedPatients}
              />
            </Suspense>
          ),
        });
        cleanLocal();
        return false;
      }
      cleanLocal();
      return true;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }
      return undefined;
    }
  }, [save, bulkList, institution, cleanLocal, showModal, history]);

  const handleBulkList = useCallback(
    (list: BulkList[]) => {
      setBulkList([
        ...bulkList,
        ...list.filter(
          (item) => !bulkList.some((bulk) => bulk.id === item.id) && item,
        ),
      ]);
    },
    [bulkList],
  );

  const handleUnbind = useCallback(
    (id: string) => {
      setBulkList(bulkList.filter((patient) => patient.id !== id));
    },
    [bulkList, setBulkList],
  );

  const handleBindPatients = useCallback(() => {
    showModal({
      type: 'custom',
      data: <BindPatientsModal handleList={handleBulkList} />,
    });
  }, [showModal, handleBulkList]);

  return (
    <Container>
      <Header>
        <h1>Vincular paciente</h1>
        <Button color="primary" onClick={handleBindPatients}>
          Novo Vínculo
        </Button>
      </Header>
      <Content>
        {bulkList.length ? (
          <Table data={bulkList} handleUnbind={handleUnbind} />
        ) : (
          <CardContentNothingToDisplay imageWidth="160px">
            <img src={CloudFileGrayImage} alt="" />
            Sem informações cadastradas
          </CardContentNothingToDisplay>
        )}
      </Content>
    </Container>
  );
};

export default forwardRef(BindPatients);
