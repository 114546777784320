import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template: 245px 570px / repeat(5, 1fr);
  grid-template-areas:
    'ud ud ud ud ud'
    'na na na ah ah';
  gap: 24px;
  width: 100%;
`;
