import React, { useState, useEffect } from 'react';

import Spinner from '../../components/Spinner';
import { usePatient } from '../../hooks/patient';
import { getCurrentPatientMeasurementNotice } from '../../services/patientMeasurementNotice';

import Main from './screens/Main';
import UpdateStatus from './screens/UpdateStatus';
import PatientAttended from './screens/PatientAttended';

import { Container, Loading } from './styles';
import PatientMeasurementNoticeStatusEnum from '../../enums/PatientMeasurementNoticeStatusEnum';

export type ScreensType = 'main' | 'updateStatus' | 'patientAttended';

type ScreensOptionsType = {
  [key in ScreensType]: JSX.Element;
};

interface PatientOnAlertModalProps {
  updateStatus?: boolean;
  patientId?: string;
}

const PatientOnAlertModal: React.FC<PatientOnAlertModalProps> = ({
  updateStatus,
  patientId,
}) => {
  const { checkPatientWaitingForAttendance } = usePatient();

  const [selected, setSelected] = useState<ScreensType>();

  useEffect(() => {
    if (patientId) {
      getCurrentPatientMeasurementNotice(patientId).then((response) => {
        if (response) {
          if (
            response.status ===
            PatientMeasurementNoticeStatusEnum.WAITING_FOR_ATTENDANCE
          ) {
            if (updateStatus) {
              setSelected('updateStatus');
            } else {
              setSelected('main');
            }
          } else {
            setSelected('patientAttended');
          }
        } else {
          setSelected('patientAttended');
        }
      });
    } else {
      checkPatientWaitingForAttendance().then((response) => {
        if (response) {
          if (updateStatus) {
            setSelected('updateStatus');
          } else {
            setSelected('main');
          }
        } else {
          setSelected('patientAttended');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const screens: ScreensOptionsType = {
    main: <Main updateScreen={setSelected} patientId={patientId} />,
    updateStatus: <UpdateStatus />,
    patientAttended: <PatientAttended patientId={patientId} />,
  };

  return (
    <Container>
      {selected ? (
        screens[selected]
      ) : (
        <Loading>
          <Spinner />
        </Loading>
      )}
    </Container>
  );
};

export default PatientOnAlertModal;
