import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Container as GridCardContainer } from '../GridCard/styles';

interface StyledLinkProps {
  disabled?: boolean;
}

export const Container = styled(GridCardContainer)``;

export const CardHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.borderGray};
  height: 65px;
  padding: 20px 50px 10px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 20px 24px 10px 24px;
  }

  h1 {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  overflow-y: auto;
  padding: 30px 25px;

  > section {
    width: 100%;
  }
`;

export const YearTitle = styled.h1`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.gray};
  padding-top: 5px;
`;

export const MonthTitle = styled.h2`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #7a7786;
  padding: 10px 0 15px;
  text-transform: capitalize;
`;

export const AppointmentSection = styled.div`
  display: grid;
  width: 100%;
  grid-template: repeat(5, 1fr) / repeat(6, 1fr);
  grid-template-areas:
    'dt in in in in in'
    'dt in in in in in'
    'ln in in in in in'
    'ln in in in in in'
    'ln in in in in in';
  align-items: flex-start;
  justify-items: center;
`;

export const DateAppointmentSection = styled.div`
  grid-area: dt;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  box-sizing: border-box;
  width: 41px;
  height: 41px;

  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  display: block;

  max-width: min-content;

  padding: 7px 0 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-decoration: none;

  color: #707683;
  transition: color 0.7s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`;

export const InformationsAppointmentSection = styled.div`
  grid-area: in;
  padding-left: 10px;
  width: 100%;
  text-align: left;

  display: flex;
  flex-direction: column;

  > h2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #707683;
  }

  > button {
    border: 0;
    background: transparent;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const LineAppointmentSection = styled.hr`
  grid-area: ln;
  border: 0.5px dashed #707683;
  width: 1px;
  height: 100%;
`;

export const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 24px;
  height: 100%;
  text-align: center;
`;

export const CardFooter = styled.div`
  border-top: 0.5px solid ${({ theme }) => theme.colors.borderGray};
  height: 60px;
`;
