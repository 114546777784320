import styled, { css } from 'styled-components';

interface DropNotificationsProps {
  isOpen?: boolean;
}

interface NotificationsBadgeProps {
  hasNewNotifications?: boolean;
}

export const DropNotifications = styled.div<DropNotificationsProps>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;

  position: absolute;
  top: 5.7vh;
  right: 50px;
  width: 100%;
  max-width: 450px;
  max-height: 500px;
  min-height: 250px;
  overflow-y: auto;
  transition: display 0.4s;
  -webkit-box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);
  -moz-box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);
  box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);
  padding: 30px 30px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.gray1};
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    right: 20px;
    max-width: 300px;
  }

  > ul {
    width: 100%;
    list-style: none;
    box-sizing: border-box;

    li + li {
      div:last-child {
        border-top: 1px solid ${({ theme }) => theme.colors.gray2};
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 0;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.borderGray};

  h3 {
    font-weight: 600;
    font-size: 16px;
    ${({ theme }) => theme.colors.graySecondary};
  }

  a {
    display: flex;
    align-items: center;

    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};

    span {
      font-weight: 600;
      font-size: 14px;
      margin-right: 5px;
    }
  }
`;

export const WithoutNotifications = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 20px 0;
  width: 100%;
  height: 150px;

  h3 {
    color: ${({ theme }) => theme.colors.grayAlternative};
    text-align: center;
    max-width: 250px;
  }
`;

export const NotificationsBadge = styled.button<NotificationsBadgeProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  border: 0;
  border-radius: 4px;
  background: transparent;
  padding: 5px 10px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 5px 5px 6px;
  }

  svg {
    height: 30px;
    width: 30px;
    color: ${({ theme }) => theme.colors.white};

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      height: 25px;
      width: 25px;
      color: ${({ theme }) => theme.colors.gray};
    }
  }

  ${(props) =>
    props.hasNewNotifications &&
    css`
      &::after {
        position: absolute;
        right: 10px;
        top: 5px;
        width: 8px;
        height: 8px;
        background: #f2c94c;
        content: '';
        border-radius: 50%;
        border: 2px solid #fff;
      }
    `}

  div {
    display: ${({ hasNewNotifications }) =>
      hasNewNotifications ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    background: #f2c94c;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;
