import styled from 'styled-components';

import InputTextStyle from '../../../../../../components/InputText';
import InputTextAreaStyle from '../../../../../../components/InputTextArea';

interface InputTextAreaProps {
  editing: boolean;
}

export const Container = styled.div`
  height: 570px;
  width: 930px;
  padding: 70px 45px 45px;
`;

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 30px;

  h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-weight: 500;
    font-size: 26px;
  }

  button {
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
    font-weight: 700;
    margin-left: 55px;

    svg {
      margin-left: 10px;
      height: 15px;
      width: 15px;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  height: 395px;

  > form {
    flex: 1;

    > div:nth-child(1),
    > div:nth-child(2) {
      align-items: center;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.graySecondary};
  width: 100%;

  > label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 22px;
    text-align: end;
    width: 100px;
  }

  .inputSelect {
    z-index: 5;
  }

  & + & {
    margin-top: 30px;
  }
`;

export const SendBy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #efefef;
  border: 1px solid ${(props) => props.theme.colors.gray2};
  border-radius: 6px;
  height: 110px;
  width: 300px;
  margin-left: auto;
  margin-top: 30px;
  padding: 30px 25px;

  h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  span {
    color: graySecondary;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const InputBox = styled.div`
  font-size: 14px;
  width: 100%;
  max-width: 300px;
`;

export const InputText = styled(InputTextStyle)`
  color: ${(props) => props.theme.colors.graySecondary} !important;
  font-size: 14px !important;
  height: 16px !important;
`;

export const InputTextArea = styled(InputTextAreaStyle)<InputTextAreaProps>`
  font-weight: 400 !important;
  height: ${(props) => (props.editing ? '80px' : '145px')} !important;
  width: 200px !important;
`;

export const FileBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 50px;

  > span {
    color: ${(props) => props.theme.colors.graySecondary};
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 25px;
  }

  form {
    color: ${(props) => props.theme.colors.graySecondary};
  }
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  height: 60px;
  margin-top: 20px;
  padding-left: 14px;
  padding-right: 16px;
`;

export const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 600;

  svg {
    height: 40px;
    width: 30px;

    path {
      stroke: ${(props) => props.theme.colors.gray};
    }
  }

  > span {
    margin-left: 15px;
    margin-right: auto;
  }
`;

export const TrashButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #707683;
  font-size: 10px;
  text-transform: uppercase;

  span {
    margin-top: 2px;
  }

  > svg {
    height: 20px;
    width: 18px;
    margin-left: 10px;

    path {
      stroke: #707683;
    }
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;

  button + button {
    margin-left: 20px;
  }
`;
