import React, {
  forwardRef,
  useCallback,
  useState,
  useImperativeHandle,
  useRef,
  useMemo,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useModal } from 'react-brave-modal';
import { toast } from 'react-toastify';
import { HiOutlinePlus } from 'react-icons/hi';

import InputTextArea from '../../../../components/InputTextArea';
import InputDegreeRadioColored from '../../../../components/InputDegreeRadioColored';
import { usePatient } from '../../../../hooks/patient';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { RisksHealthConditionProps as CustomerRisksHealthConditionProps } from '..';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/images/trash.svg';

import {
  Container,
  Content,
  RiskDegrees,
  HealthConditionList,
  HealthConditionBox,
  CheckBox,
  HealthConditionForm,
  Field,
} from './styles';
import { useAuth } from '../../../../hooks/auth';
import Button from '../../../../components/Button';
import { useCurrentCallback } from '../../../../lib/use-current-effect';
import InputAsyncSelect from '../../../../components/InputAsyncSelect';
import { getIcd10 } from '../../../../services/icd10';
import AddEditHealthConditionModal from '../../../../modals/AddEditHealthConditionModal';
import RemoveHealthConditionModal from '../../../../modals/RemoveHealthConditionModal';
import Icd10Icon from '../../../../components/Icd10Icon';

export interface RisksHealthConditionRef {
  getValues(): CustomerRisksHealthConditionProps;
  getValidate(): Promise<boolean>;
}

interface SeverityRiskDegreeOptions {
  name: string;
  value: string;
  color: string;
  background: string;
  backgroundSelected: string;
}

interface RisksHealthCondition {
  type?: 'register' | 'edit';
}

const RisksHealthCondition: React.ForwardRefRenderFunction<
  RisksHealthConditionRef,
  RisksHealthCondition
> = ({ type }, ref) => {
  const { user: userAuth } = useAuth();
  const { patient, addHealthCondition, editHealthCondition, sync } =
    usePatient();
  const { showModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const activeHeathCondition = useMemo(() => {
    return patient?.healthConditions?.filter(
      (icd10) => icd10.status === 'active',
    );
  }, [patient]);

  const riskDegreeOptions: SeverityRiskDegreeOptions[] = [
    {
      name: '1',
      value: 'safe',
      color: '#27AE60',
      background: 'rgba(111, 207, 151, 0.4)',
      backgroundSelected: 'rgb(111, 207, 151)',
    },
    {
      name: '2',
      value: 'low',
      color: '#C89A0F',
      background: 'rgba(242, 201, 076, 0.4)',
      backgroundSelected: 'rgb(242, 201, 076)',
    },
    {
      name: '3',
      value: 'medium',
      color: '#C8660E',
      background: 'rgba(250, 136, 034, 0.5)',
      backgroundSelected: 'rgb(250, 136, 034)',
    },
    {
      name: '4',
      value: 'high',
      color: '#E62929',
      background: 'rgba(234, 118, 118, 0.5)',
      backgroundSelected: 'rgb(234, 118, 118)',
    },
  ];

  const severityOptions: SeverityRiskDegreeOptions[] = useMemo(
    () => [
      {
        name: 'B',
        value: 'B',
        color: '#19CAC0',
        background: 'rgba(025, 202, 192, 0.1)',
        backgroundSelected: 'rgb(025, 202, 192)',
      },
      {
        name: 'M',
        value: 'M',
        color: '#F2B94A',
        background: 'rgba(242, 185, 074, 0.1)',
        backgroundSelected: 'rgb(242, 185, 074)',
      },
      {
        name: 'G',
        value: 'G',
        color: '#EA7676',
        background: 'rgba(234, 118, 118, 0.1)',
        backgroundSelected: 'rgb(234, 118, 118)',
      },
    ],
    [],
  );

  const riskDegreSelected: string | undefined = useMemo(() => {
    if (patient?.newRiskDegree) {
      return patient.newRiskDegree;
    }

    if (patient?.currentRiskDegree) {
      return patient.currentRiskDegree.degree;
    }

    return undefined;
  }, [patient]);

  const handleEdit = useCallback(
    (id: string) => {
      showModal({
        type: 'custom',
        data: <AddEditHealthConditionModal icd10PatientId={id} action="edit" />,
      });
    },
    [showModal],
  );

  const handleRemove = useCallback(
    (id: string) => {
      showModal({
        type: 'custom',
        data: <RemoveHealthConditionModal icd10PatientId={id} />,
      });
    },
    [showModal],
  );

  const getValues = useCallback(() => {
    const risksHealthCondition: CustomerRisksHealthConditionProps = {
      ...formRef.current?.getData(),
    };

    return risksHealthCondition;
  }, []);

  const getValidate = useCallback(
    async (risksHealthConditionData: CustomerRisksHealthConditionProps) => {
      try {
        formRef.current?.setErrors({});

        const schema =
          risksHealthConditionData.type === 'icd10'
            ? Yup.object().shape({
                icd10Id: Yup.string(),
                severity: Yup.string().when('icd10Id', {
                  is: (value) => value !== '',
                  then: Yup.string().required(),
                }),
                medicine: Yup.string(),
                observation: Yup.string(),
              })
            : Yup.object().shape({
                riskDegree:
                  type === 'edit' && !userAuth?.professional_id
                    ? Yup.string()
                    : Yup.string(),
              });

        await schema.validate(risksHealthConditionData, { abortEarly: false });

        return true;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          if (risksHealthConditionData.type === 'icd10') {
            toast.info(
              'Selecione uma condição de saúde e sua respectiva gravidade.',
            );
          } else {
            toast.info('Selecione o grau de risco do paciente.');
          }
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }

        return false;
      }
    },
    [type, userAuth],
  );

  const handleSubmit = useCurrentCallback(
    (isCurrent) => async (data: CustomerRisksHealthConditionProps) => {
      setIsButtonDisabled(true);

      data.type = 'icd10';
      const validData = await getValidate(data);

      if (validData) {
        try {
          await addHealthCondition({
            ...data,
            icd10Id: data.icd10Id || '',
            severity: data.severity || '',
            isMainHealthCondition: data.isMainHealthCondition || false,
          });

          sync(patient?.id);

          if (isCurrent()) {
            setIsButtonDisabled(false);
          }

          toast.success('Condição de saúde adicionada com sucesso.');
        } catch (error) {
          if (isCurrent()) {
            setIsButtonDisabled(false);
          }

          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error('Entre em contato com o administrador.');
          }
        }
      } else if (isCurrent()) {
        setIsButtonDisabled(false);
      }
    },
    [addHealthCondition, getValidate, patient, sync],
  );

  const handleChooseMain = useCallback(
    async (id: string) => {
      if (patient && patient.healthConditions) {
        const icd10 =
          patient?.healthConditions?.find((item) => item.id === id) || null;

        await editHealthCondition({
          icd10PatientId: id,
          isMainHealthCondition: true,
          severity: icd10?.severity,
        });

        sync(patient.id);
      }
    },
    [editHealthCondition, patient, sync],
  );

  const loadIdc10Options = async (
    inputValue: string,
    _: any,
    additional?: { page: number },
  ) => {
    const { data: results } = await getIcd10({
      keyword: inputValue,
      limit: 10,
      page: additional?.page || 1,
    });
    const options = results.map((option) => ({
      value: option.id,
      label: `${option.icd} - ${option.description}`,
    }));

    return {
      options,
      hasMore: results.length > 0,
      additional: { page: (additional?.page ? additional.page : 1) + 1 },
    };
  };

  useImperativeHandle(ref, () => ({
    getValues,
    getValidate: () => getValidate(getValues()),
  }));

  return (
    <Container>
      <h1>Condições de saúde e grau de risco</h1>
      <Content>
        <p>
          Considerando a saúde geral do paciente, qual o Grau de Risco
          dele/dela?
        </p>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <RiskDegrees>
            <InputDegreeRadioColored
              name="riskDegree"
              title=""
              optionStyle="circle"
              options={riskDegreeOptions}
              defaultOption={riskDegreSelected}
              disabled={type === 'edit' && !userAuth?.professional_id}
            />
          </RiskDegrees>
          {activeHeathCondition && (
            <HealthConditionList>
              {activeHeathCondition.map(
                ({ id, icd10, isMainHealthCondition }) => (
                  <li key={id}>
                    <HealthConditionBox>
                      <Icd10Icon condition={icd10.chapter} />
                      <span>{`${icd10.icd} - ${icd10.description}`}</span>
                      <div>
                        <button type="button" onClick={() => handleEdit(id)}>
                          <EditIcon />
                        </button>
                        {activeHeathCondition.length > 1 && (
                          <button
                            type="button"
                            onClick={() => handleRemove(id)}
                          >
                            <TrashIcon />
                          </button>
                        )}
                      </div>
                    </HealthConditionBox>
                    {activeHeathCondition.length > 1 && (
                      <>
                        <CheckBox
                          type="button"
                          isChecked={isMainHealthCondition}
                          onClick={() => handleChooseMain(id)}
                        >
                          <span />
                        </CheckBox>
                        <span>Principal</span>
                      </>
                    )}
                  </li>
                ),
              )}
            </HealthConditionList>
          )}
          <HealthConditionForm>
            <Field>
              <label>Condição de saúde</label>
              <InputAsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={loadIdc10Options}
                name="icd10Id"
                placeholder="Pesquise uma condição de saúde"
              />
            </Field>
            <Field>
              <label>Gravidade da condição de saúde</label>
              <InputDegreeRadioColored
                name="severity"
                title=""
                optionStyle="roundedRectangle"
                options={severityOptions}
              />
            </Field>
            <Field>
              <label>
                Medicamentos utilizados pelo paciente no tratamento da condição
                de saúde.
              </label>
              <InputTextArea name="medicine" />
            </Field>
            <Field>
              <label>Observações</label>
              <InputTextArea name="observation" />
            </Field>
          </HealthConditionForm>
        </Form>
        <Button
          loading={isButtonDisabled}
          buttonType="primary"
          type="submit"
          onClick={() => formRef?.current?.submitForm()}
        >
          <HiOutlinePlus />
          Incluir condição de saúde
        </Button>
      </Content>
    </Container>
  );
};

export default forwardRef(RisksHealthCondition);
