import React, { useState, useMemo, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { IoMdSearch } from 'react-icons/io';

import InputWithoutTitle from '../../../../../components/InputWithoutTitle';
import { GetAttendancesByFilter as ListFilter } from '../../../../../services/reports/attendances';
import { getAllHealthConditionFromPatients } from '../../../../../services/patient/GetAllHealthConditionFromPatients';
import { getSheets } from '../../../../../services/sheet';

import { Container, GridContent, Field, InputSelect, Button } from './styles';
import { useCurrentEffect } from '../../../../../lib/use-current-effect';
import ButtonFilterRangeDate, {
  DateValueProps,
} from '../../../../../components/ButtonFilterRangeDate';
import AppointmentTypeEnum from '../../../../../enums/AppointmentTypeEnum';
import RiskDegreeEnum from '../../../../../enums/RiskDegreeEnum';
import InputAsyncSelect from '../../../../../components/InputAsyncSelect';

interface Options {
  value: string;
  label: string;
}

interface FilterProps {
  onChangePage?: (page: number) => void;
  setListFilter(listFilter: React.SetStateAction<ListFilter>): void;
  formRef: React.RefObject<FormHandles>;
}

const Filter: React.FC<FilterProps> = ({
  setListFilter,
  formRef,
  onChangePage,
}) => {
  const [sheets, setSheets] = useState<Options[]>();
  const [datesSelected, setDatesSelected] = useState<Partial<DateValueProps>>(
    {},
  );

  const loadHealthConditionOptions = async (
    inputValue: string,
    _: any,
    additional?: { page: number },
  ) => {
    const { data: results } = await getAllHealthConditionFromPatients({
      limit: 10,
      page: additional?.page || 1,
      keyword: inputValue,
    });

    const icd10Options = results.map((option) => ({
      value: option.id,
      label: `${option.icd} - ${option.description}`,
    }));

    return {
      options: icd10Options,
      hasMore: icd10Options.length > 0,
      additional: { page: (additional?.page ? additional.page : 1) + 1 },
    };
  };

  const handleUpdateDate = useCallback(
    ({ startDate, endDate }: DateValueProps) => {
      if (startDate && endDate) {
        setDatesSelected({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        });
      }

      if (!startDate && !endDate) {
        setDatesSelected({});
      }
    },
    [],
  );

  useCurrentEffect((isCurrent) => {
    (async () => {
      const allSheets = await getSheets();

      const sheetOptions = allSheets.map((sheet) => ({
        value: sheet.id,
        label: sheet.name,
      }));

      sheetOptions.unshift({ value: '', label: 'Todas' });
      sheetOptions.push({ value: 'noSheets', label: 'Sem fichas' });

      if (isCurrent()) {
        setSheets(sheetOptions);
      }
    })();
  }, []);

  const riskDegrees = useMemo(
    () => [
      { value: '', label: 'Todos' },
      { value: RiskDegreeEnum.SAFE, label: '1' },
      { value: RiskDegreeEnum.LOW, label: '2' },
      { value: RiskDegreeEnum.MEDIUM, label: '3' },
      { value: RiskDegreeEnum.HIGH, label: '4' },
    ],
    [],
  );

  const attendanceTypes = useMemo(
    () => [
      { value: '', label: 'Todos' },
      { value: AppointmentTypeEnum.VIDEO, label: 'Videochamada' },
      { value: AppointmentTypeEnum.PHONE, label: 'Ligação' },
      { value: AppointmentTypeEnum.SMS, label: 'Mensagem' },
      { value: AppointmentTypeEnum.PRESENTIAL, label: 'Presencial' },
    ],
    [],
  );

  const handleSubmit = useCallback(() => {
    onChangePage && onChangePage(1);

    const { endDate, startDate } = datesSelected;

    setListFilter((prevState) => ({
      ...prevState,
      ...formRef.current?.getData(),
      endDate,
      startDate,
    }));
  }, [datesSelected, formRef, onChangePage, setListFilter]);

  return (
    <Container>
      <h1>Filtrar atendimento</h1>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <GridContent>
          <Field gridArea="pc">
            <label>Nome do paciente</label>
            <InputWithoutTitle name="patientName" />
          </Field>
          <Field gridArea="pf">
            <label>Nome do profissional</label>
            <InputWithoutTitle name="professionalName" />
          </Field>
          <Field gridArea="dt">
            <label>Período</label>
            <ButtonFilterRangeDate
              dateValue={datesSelected}
              onChange={handleUpdateDate}
              canClearDates
            />
          </Field>
          <Field gridArea="cm">
            <label>Condição de saúde</label>
            <InputAsyncSelect
              name="icd10"
              cacheOptions
              loadOptions={loadHealthConditionOptions}
              defaultOptions
              placeholder="Selecione"
            />
          </Field>
          <Field gridArea="at">
            <label>Tipo de atendimento</label>
            <InputSelect
              name="attendanceType"
              placeholder="Selecione"
              options={attendanceTypes}
            />
          </Field>
          <Field gridArea="sh">
            <label>Fichas</label>
            <InputSelect
              name="sheet"
              placeholder="Selecione"
              options={sheets}
            />
          </Field>
          <Field gridArea="rd">
            <label>Grau de Risco</label>
            <InputSelect
              name="riskDegree"
              placeholder="Selecione"
              options={riskDegrees}
            />
          </Field>
          <Field gridArea="bt" isButton>
            <Button type="submit">
              <IoMdSearch />
              Buscar
            </Button>
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default Filter;
