import React from 'react';
import { BulkList } from '../../../../../components/InputSuggestUser';
import { Container, TableHeader, TableRow, Button } from './styles';

interface TableProps {
  data: BulkList[];
  handleUnbind: (id: string) => void;
}

const Table: React.FC<TableProps> = ({ data, handleUnbind }) => {
  return (
    <Container>
      <TableHeader>
        <strong>Nome</strong>
        <strong>CPF</strong>
        <strong>E-mail</strong>
      </TableHeader>
      {data.map((patient) => (
        <TableRow key={patient.id}>
          <strong>
            <img width="40px" src={patient.avatar} alt="avatar" />
            {patient.name}
          </strong>
          <strong>{patient.cpf}</strong>
          <strong>{patient.email ? patient.email : ''}</strong>
          <Button
            buttonType="primary-alternative"
            onClick={() => handleUnbind(patient.id)}
          >
            Desvincular
          </Button>
        </TableRow>
      ))}
    </Container>
  );
};

export default Table;
