import styled, { css } from 'styled-components';

interface ButtonProps {
  selected: boolean;
}

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  min-height: 500px;
  width: 100%;
`;

export const MessageTable = styled.div`
  margin-top: 30px;

  > header {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr 40px 1.5fr 1.5fr;
    border-radius: 4px 4px 0 0;
    background: rgba(189, 189, 189, 0.2);
    padding: 10px 20px;

    > h3 {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #707683;
      margin: 0 10px;
    }
  }

  > div {
    overflow-y: auto;
    max-height: 350px;
    height: 100%;

    > section {
      display: grid;
      grid-template-columns: 1fr 1fr 3fr 40px 1.5fr 1.5fr;
      padding: 8px 20px;
      background: #fdfdfd;

      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #707683;

      > div {
        margin: 0 10px;

        overflow: hidden;

        display: flex;
        align-items: center;
      }

      > div:nth-child(4) {
        justify-self: center;
      }

      > div > p {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;

        overflow: hidden;
        text-overflow: ellipsis;

        padding: 0;
        position: relative;
      }
    }

    > section + section {
      border-top: 1px solid #eaeaea;
    }
  }
`;

export const CardHeader = styled.div`
  > h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #707683;
  }
`;

export const CardContent = styled.div`
  font-size: 14px;
  margin-top: 32px;
  max-width: 1200px;
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StatusBox = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #707683;
  }
`;

export const StatusTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #daedf7;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  min-width: 145px;
  margin-top: 6px;
  padding: 0 15px;
`;

export const Buttons = styled.div`
  margin-top: 6px;
`;

export const Button = styled.button<ButtonProps>`
  border: none;
  border-radius: 5px;
  color: #707683;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  width: 145px;
  margin-right: 20px;
  outline: none;
  transition: 0.2s;

  &:hover {
    background-color: #daedf7;
    color: ${(props) => props.theme.colors.primary};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: #daedf7;
      color: ${(props) => props.theme.colors.primary};
    `}

  @media (max-width: 1029px) {
    & + button {
      margin-top: 8px;
    }
  }
`;

export const DateBox = styled.div`
  display: flex;
  flex-direction: column;

  span:last-child {
    font-weight: 600;
    margin-top: 6px;
  }
`;

export const ObservationBox = styled.div`
  margin-top: 30px;

  textarea {
    height: 60px;
  }

  > div {
    margin: 6px 0 15px;
  }
`;

export const ReasonDateBox = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 15px;

  > div:nth-child(1) {
    > div {
      margin-top: 6px;
    }
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;

    > span {
      margin: 5px 10px 0;

      color: ${({ theme }) => theme.colors.primary};
    }

    > div {
      margin-left: 30px;

      > label.switch {
        width: 40px;
        height: 22px;

        > input:checked + span:before {
          transform: translateX(17.5px);
        }

        > span.slider:before {
          height: 12px !important;
          width: 12px !important;
          box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
          left: 5px;
        }
      }
    }
  }
`;

export const ReasonBox = styled.div`
  margin-top: 30px;

  > div {
    margin-top: 6px;
    max-width: 350px;
  }
`;
