import styled, { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const InputCopy = styled.div`
  border: none;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: none;
  outline: none;
  caret-color: none;
  background-color: ${({ theme }) => theme.colors.gray6};
  gap: 14px;

  > span a {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }

  &:hover span a {
    text-decoration: underline;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all ease 0.3s;
    border: none;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    &:hover > img {
      transform: scale(1.2);
    }

    &:focus {
      caret-color: transparent;
      outline: transparent;
    }

    &:active {
      animation: ${pulseAnimation} 0.3s ease;
    }
  }
`;
