import React, { useMemo, useCallback, useState } from 'react';
import { useModal } from 'react-brave-modal';
import DashboardTemplate from '../../../templates/Dashboard';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import Table from './Table';
import CreateEditAppointmentModal from '../../../modals/CreateEditAppointmentModal';

import { Button, ButtonsContainer } from './styles';
import { usePermission } from '../../../hooks/permission';
import { useAppointment } from '../../../hooks/appointment';
import { useAuth } from '../../../hooks/auth';
import MainAreasEnum from '../../../enums/MainAreasEnum';

export interface AppointmentDataProps {
  patientName?: string;
  profissionalName?: string;
}

const List: React.FC = () => {
  const { cleanLocal } = useAppointment();
  const { hasPermission } = usePermission();
  const { showModal } = useModal();
  const { user } = useAuth();

  const canCreate = useMemo(
    () =>
      hasPermission('register_appointment') && user?.type === 'professional',
    [hasPermission, user],
  );

  const [updateList, setUpdateList] = useState(0);

  const handleFinish = useCallback(() => {
    setUpdateList((state) => state + 1);
  }, []);

  const handleCreateNewAppointment = useCallback(() => {
    showModal({
      type: 'custom',
      closeActionSync: () => cleanLocal(),
      data: <CreateEditAppointmentModal onFinish={handleFinish} />,
    });
  }, [cleanLocal, handleFinish, showModal]);

  const pages = useMemo<ElementPageTree[]>(
    () => [
      {
        name: 'Agendamentos',
      },
    ],
    [],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.APPOINTMENTS}>
      <PageTree pages={pages} />
      <ButtonsContainer>
        <Button
          onClick={handleCreateNewAppointment}
          color="primary"
          disabled={!canCreate}
        >
          Novo Agendamento
        </Button>
        {/*         <Button onClick={() => handleOnClick('medic')} lite color="white">
          Relatório de Atendimentos
        </Button> */}
      </ButtonsContainer>

      <Table updateList={updateList} />
    </DashboardTemplate>
  );
};

export default List;
