import React, { useMemo } from 'react';
import { Chart as ChartGoogle } from 'react-google-charts';
import Spinner from '../../../../../../components/Spinner';

interface ChartProps {
  data?: { date: string; number: number }[];
}

const Chart: React.FC<ChartProps> = ({ data }) => {
  const display = useMemo(
    () => data?.map((item) => [item.date, item.number]) ?? [],
    [data],
  );

  return (
    <>
      {!!display.length && (
        <ChartGoogle
          width="95%"
          height="150px"
          chartType="Bar"
          loader={<Spinner />}
          style={{
            marginTop: '15px',
          }}
          options={{
            legend: { position: 'none' },
            colors: ['#30E3E5', '#2D9CDB'],
            bar: { groupWidth: 7 },
          }}
          data={[['', 'Atendimentos'], ...display]}
          rootProps={{ 'data-testid': '1' }}
        />
      )}
    </>
  );
};

export default Chart;
