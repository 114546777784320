import { AddRiskDegreePatientRequestDTO } from '../../dtos/AddRiskDegreePatientRequestDTO';
import { Patient } from '../../entities/Patient';
import { api } from '../api';

export const addRiskDegree = async ({
  patientId,
  riskDegree,
}: AddRiskDegreePatientRequestDTO): Promise<Patient> => {
  const { data } = await api.post('/patients/riskDegree', {
    patientId,
    riskDegree,
  });

  return data;
};
