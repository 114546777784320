import styled from 'styled-components';

export const OptionRadio = styled.li`
  display: flex;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #fff;
    border: 2px solid ${({ theme }) => theme.colors.grayAlternative};
    height: 20px;
    width: 20px;
    border-radius: 50%;

    &:disabled:before {
      content: '';
      background: ${({ theme }) => theme.colors.primary};
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }
  }

  strong {
    font-size: 16px;
    margin-left: 15px;
    color: ${({ theme }) => theme.colors.graySecondary};
  }

  & + li {
    margin-top: 15px;
  }
`;
