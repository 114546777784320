import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.gray2};
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px 15px;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0;
    margin-left: 10px;

    > svg {
      color: ${(props) => props.theme.colors.grayAlternative};
    }

    &:hover {
      > svg {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;
