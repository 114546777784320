import { api } from '../api';
import { User } from '../../entities/User';
import CreateUserStatusRequestDTO from '../../dtos/CreateUserStatusRequestDTO';

export const createUserStatus = async (
  status: CreateUserStatusRequestDTO,
): Promise<User> => {
  const { data } = await api.post('/status', status);

  return data;
};
