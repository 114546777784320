import { api } from '../api';
import { Appointment } from '../../entities/Appointment';
import { UpdateAppointmentStatusRequestDTO } from '../../dtos/UpdateAppointmentStatusRequestDTO';

export const updateAppointmentStatus = async ({
  status,
  appointmentId,
}: UpdateAppointmentStatusRequestDTO): Promise<Appointment> => {
  const { data } = await api.put('/appointments/status', {
    status,
    appointmentId,
  });

  return data;
};
