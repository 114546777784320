import styled, { css } from 'styled-components';

interface RiskDegreeColorProps {
  riskDegreeColor?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  height: 100%;
  padding: 28px 20px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 32px;
`;

export const Field = styled.div`
  margin-bottom: 32px;
  width: 100%;

  &.buttons {
    width: 278px;
  }
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.graySecondary};
  }

  & {
    margin-bottom: 12px;
  }

  label {
    display: inline-flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.graySecondary};
  }

  > label span {
    margin-left: 9.5px;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template: 335px / repeat(7, 1fr);
  grid-gap: 27px;
  grid-template-areas: 'flexRow1 flexRow1 flexRow1 flexRow1 flexRow1 aside aside' 'flexRow2 flexRow2 flexRow2 flexRow2 flexRow2 aside aside';
`;

export const InputPlaceholder = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray6};
  height: 34px;
  border-radius: 6px;
  padding: 8px 14px;
  align-items: center;
  justify-content: center;
  gap: 14px;
  max-width: 278px;
`;

export const PlayRecorderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: ${({ theme, disabled }) =>
    disabled === true ? theme.colors.gray : theme.colors.primary};
  cursor: ${({ disabled }) => (disabled === true ? 'not-allowed' : 'pointer')};
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-top: 29px;
  transition: ease-out 0.3s;

  &:not(:disabled):hover {
    text-decoration: underline;
  }

  & img {
    margin-right: 8px;
    opacity: ${({ disabled }) => (disabled ? 0.85 : 1)};
    font-size: 26px;
  }
`;

export const ContainerAudioPlayer = styled.div`
  transition: all 0.3s;
  position: relative;
  transform: translateY(50%);
`;

export const Duration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: ${({ theme }) => theme.colors.graySecondary};
  }
  span {
    font-size: 14px;
    font-weight: 400;
  }

  svg {
    color: ${(props) => props.theme.colors.gray};
    font-size: 22px;
    vertical-align: middle;
    margin-right: 8px;
  }
`;

export const RiskDegree = styled.div`
  display: flex;
`;

export const Risk = styled.div<RiskDegreeColorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.riskDegreeColor};
  border-radius: 6px;
  color: ${(props) => props.theme.colors.white};
  font-size: 22px;
  font-weight: 800;
  height: 35px;
  width: 33px;
`;

export const EditButton = styled.button`
  background: transparent;
  border: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray : theme.colors.primary};
  font-size: 14px;
  margin-left: 22px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  svg {
    margin-bottom: 2px;
    margin-right: 8px;
    vertical-align: middle;
  }
`;

export const Status = styled.div`
  display: flex;
`;

export const Sheets = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

export const Sheet = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 43px;
  min-width: 208px;
`;

export const InputWrapper = styled.div`
  flex: 1;

  div {
    background: white;
  }
`;
