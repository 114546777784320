import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  position: relative;

  width: 925px;
  height: 570px;

  padding: 60px 40px 40px 75px;

  > h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.primary};

    max-width: 75%;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  margin-top: 50px;

  gap: 35px;
`;

export const ModalForm = styled(Form)`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 5px;

  .react-select__placeholder {
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray};
  }

  > label {
    font-weight: 500;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grayTertiary};
  }

  > div + label {
    padding-top: 20px;
  }

  > div:last-child {
    height: 100%;

    textarea {
      height: inherit;

      ::placeholder {
        font-weight: 500;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray};
      }
    }
  }
`;

export const ExclusionDataCard = styled.div`
  flex: 1;

  background: #f8f8f8;
  border-radius: 6px;

  display: grid;
  grid-template-columns: min-content 1fr;

  align-items: center;
  padding: 25px 30px 35px;
  gap: 25px;

  p {
    padding: 0;

    font-size: 18px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.grayTertiary};
  }

  > svg:first-child {
    > path {
      fill: ${({ theme }) => theme.colors.grayTertiary};
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  gap: 20px;

  position: absolute;
  right: 40px;
  bottom: 40px;
`;
