import { EditHealthConditionPatientRequestDTO } from '../../dtos/EditHealthConditionPatientRequestDTO';
import { Patient } from '../../entities/Patient';
import { api } from '../api';

export const editHealthConditionService = async ({
  icd10PatientId,
  patientId,
  isMainHealthCondition,
  severity,
  medicine,
  observation,
}: EditHealthConditionPatientRequestDTO): Promise<Patient> => {
  const { data } = await api.put('/patients/icd10', {
    icd10PatientId,
    patientId,
    isMainHealthCondition,
    severity,
    medicine,
    observation,
  });

  return data;
};
