import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

import { differenceInDays, format } from 'date-fns';

import { FiX } from 'react-icons/fi';
import {
  ButtonClose,
  ButtonDatePicker,
  Container,
  DatePickerComponent,
} from './styles';
import CalendarFilterIcon from '../../assets/images/date-picker-dark.svg';

export interface DateValueProps {
  startDate?: Date;
  endDate?: Date;
}
interface ButtonFilterRangeDateProps {
  onChange: (newValue: DateValueProps) => void;
  dateValue: DateValueProps;
  disabled?: boolean;
  rangeLimit?: number;
  canClearDates?: boolean;
}

const ButtonFilterRangeDate: React.FC<ButtonFilterRangeDateProps> = ({
  onChange,
  dateValue,
  disabled,
  rangeLimit,
  canClearDates,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [value, setValue] = useState<DateValueProps>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [invalidDate, setInvalidDate] = useState(false);

  const maxDate = useMemo<Date>(() => new Date(), []);

  const minDate = useMemo<Date>(() => new Date(0), []);

  const buttonLabel = useMemo(
    () =>
      !dateValue.endDate || !dateValue.startDate
        ? 'Selecione um intervalo'
        : `${format(dateValue.startDate, "dd'/'MM'/'yyyy")} - ${format(
            dateValue.endDate,
            "dd'/'MM'/'yyyy",
          )}`,
    [dateValue],
  );

  const setNewValue = useCallback(
    (range: DateValueProps) => {
      const { startDate, endDate } = range;

      setValue({
        startDate,
        endDate,
      });

      if (endDate && startDate) {
        const difference = differenceInDays(endDate, startDate);

        if (difference !== 0) {
          if (rangeLimit && difference >= rangeLimit) {
            setInvalidDate(true);
          } else {
            setInvalidDate(false);

            onChange({
              startDate,
              endDate,
            });

            setShowFilter(false);
          }
        }
      }
    },
    [onChange, rangeLimit],
  );

  const handleClick = useCallback(() => {
    setInvalidDate(false);

    const { endDate, startDate } = dateValue;

    setValue(
      endDate && startDate
        ? {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          }
        : {
            startDate: new Date(),
            endDate: new Date(),
          },
    );

    setShowFilter((state) => !state);
  }, [dateValue]);

  useEffect(() => {
    if (rangeLimit) {
      const calendarComponent = document.getElementsByClassName(
        'rdrDateRangeWrapper',
      )[0];

      const element = document.createElement('p');

      element.setAttribute('class', 'titleCalendar');

      const text = document.createTextNode(
        `Selecione um período de ${rangeLimit} dias`,
      );

      element.appendChild(text);
      calendarComponent.appendChild(element);
    }
  }, [rangeLimit]);

  const handleOnClickListener = useCallback((event: MouseEvent) => {
    const withinBoundaries = event
      .composedPath()
      .includes(containerRef.current as Element);

    if (!withinBoundaries) {
      setShowFilter(false);
    }
  }, []);

  useEffect(() => {
    if (containerRef) {
      document.addEventListener('click', handleOnClickListener);
    }

    return () => {
      document.removeEventListener('click', handleOnClickListener);
    };
  }, [handleOnClickListener]);

  useEffect(() => {
    dateValue.endDate &&
      dateValue.startDate &&
      setValue({
        startDate: new Date(dateValue.startDate),
        endDate: new Date(dateValue.endDate),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container ref={containerRef}>
      <ButtonDatePicker type="button" onClick={handleClick} disabled={disabled}>
        <p>{buttonLabel}</p>
        <div>
          <img src={CalendarFilterIcon} alt="Ícone de Calendário" />
        </div>
      </ButtonDatePicker>

      {canClearDates && (
        <ButtonClose
          type="button"
          onClick={() => {
            onChange({});
            setShowFilter(false);
          }}
        >
          <FiX size={16} color="#ffffff" />
        </ButtonClose>
      )}
      <DatePickerComponent invisible={!showFilter} invalidDate={invalidDate}>
        <DateRange
          locale={locales.pt}
          onChange={(item: any) => setNewValue(item.range1)}
          weekStartsOn={0}
          ranges={[{ ...value }]}
          maxDate={maxDate}
          minDate={minDate}
          showMonthArrow={false}
        />
      </DatePickerComponent>
    </Container>
  );
};

export default ButtonFilterRangeDate;
