import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import { usePatient } from '../../../../hooks/patient';
import { ComplementaryData as AdditionalDataProps } from '../../../../entities/ComplementaryData';

import EmergencyContacts, { EmergencyContactsRef } from './EmergencyContacts';
import AdditionalDataChildren, {
  AdditionalDataRef as AdditionalDataChildrenRef,
} from './AdditionalData';
import SleepInformation, { SleepInformationRef } from './SleepInformation';

import { Container } from './styles';

export interface AdditionalDataRef {
  getValues(): AdditionalDataProps;
}

const AdditionalData: React.ForwardRefRenderFunction<AdditionalDataRef> = (
  _,
  ref,
) => {
  const additionalDataChildrenRef = useRef<AdditionalDataChildrenRef>(null);
  const sleepInformationRef = useRef<SleepInformationRef>(null);
  const emergencyContactsRef = useRef<EmergencyContactsRef>(null);

  const { patient } = usePatient();

  const getValues = useCallback(() => {
    const additionalData: AdditionalDataProps = {
      ...additionalDataChildrenRef.current?.getValues(),
      ...sleepInformationRef.current?.getValues(),
      emergencyContacts: emergencyContactsRef.current?.getValues(),
    };

    return additionalData;
  }, []);

  useImperativeHandle(ref, () => ({
    getValues,
  }));

  return (
    <Container>
      <AdditionalDataChildren
        ref={additionalDataChildrenRef}
        height={patient?.complementaryData?.height}
        weight={patient?.complementaryData?.weight}
      />

      <EmergencyContacts
        ref={emergencyContactsRef}
        emergencyContacts={patient?.complementaryData?.emergencyContacts}
      />

      <SleepInformation
        ref={sleepInformationRef}
        bedTime={patient?.complementaryData?.bedTime}
        wakeUpTime={patient?.complementaryData?.wakeUpTime}
        observation={patient?.complementaryData?.observation}
      />
    </Container>
  );
};

export default forwardRef(AdditionalData);
