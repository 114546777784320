enum SignatureStatusEnum {
  REFUSED_PAYMENT = 'refused_payment',
  AWAITING_PAYMENT = 'awaiting_payment',
  AWAITING_FIRST_APPOINTMENT = 'awaiting_first_appointment',
  UNDER_REVIEW = 'under_review',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
}

export default SignatureStatusEnum;
