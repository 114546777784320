import React from 'react';

import BlankSketchesImage from '../../../../../assets/images/blank-sketches-2.svg';
import ChartDetails, { ChartDetailsProps } from '../ChartDetails';
import Spinner from '../../../../../components/Spinner';

import { CardChart } from '../styles';

import { Empty, CardTypeTitle, ChartArea, DetailsSArea } from './styles';

type ChartType = {
  styleData: 'chart';
  dataDetails?: ChartDetailsProps;
  cardTitle: string;
};

type InfoType = {
  styleData: 'info';
  textColor?: string;
  dataDetails?: string | number;
};

type MeasurementsAreaType = {
  isLoading: boolean;
} & (ChartType | InfoType);

interface MeasurementsAreaProps {
  data: MeasurementsAreaType;
}

const MeasurementsArea: React.FC<MeasurementsAreaProps> = ({
  data,
  children,
}) => {
  return (
    <>
      {data.isLoading ? (
        <Spinner />
      ) : !data.dataDetails ? (
        <Empty>
          <img src={BlankSketchesImage} alt="Sem dados de monitoramento" />
          <p>Sem dados de monitoramento</p>
        </Empty>
      ) : (
        <>
          {data.styleData === 'info' ? (
            <DetailsSArea color={data.textColor}>{children}</DetailsSArea>
          ) : (
            <ChartArea>
              <CardTypeTitle>{data.cardTitle}</CardTypeTitle>

              <CardChart>{children}</CardChart>

              <ChartDetails {...data.dataDetails} />
            </ChartArea>
          )}
        </>
      )}
    </>
  );
};

export default MeasurementsArea;
