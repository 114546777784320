/* eslint-disable import/no-duplicates */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  format,
  differenceInDays,
  startOfDay,
  isBefore,
  isValid,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useModal } from 'react-brave-modal';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { BiCalendarAlt, BiInfoCircle } from 'react-icons/bi';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../../../lib/use-current-effect';

import { ModalProps, BulkAvailability } from '../..';
import { BulkList } from '../../../../components/InputSuggestUser';
import Spinner from '../../../../components/Spinner';
import InputIcon from '../../../../components/InputIcon';
import CalendarDefault from '../../../../components/CalendarDefault';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import { useAppointment } from '../../../../hooks/appointment';
import {
  getProfessionalScheduleByDay,
  getProfessionalScheduleByDayRange,
} from '../../../../services/professionalSchedule';
import getValidationErrors from '../../../../utils/getValidationErrors';

import { ReactComponent as PhoneIcon } from '../../../../assets/images/icon_schedule_phone.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/images/icon_schedule_message.svg';
import { ReactComponent as FilmIcon } from '../../../../assets/images/icon_schedule_film.svg';
import { ReactComponent as PeopleIcon } from '../../../../assets/images/people.svg';
import { ReactComponent as IconInputPhoneSpacegray } from '../../../../assets/images/icon_schedule_phone_spacegray.svg';
import { ReactComponent as IconInputClockSpacegray } from '../../../../assets/images/icon_schedule_time_spacegray.svg';

import { ButtonsContainer, Button } from '../../styles';
import {
  Container,
  CalendarBox,
  BulkBox,
  Profissional,
  InputTimeBox,
  LoadingBox,
  TimeBox,
  NoAvailableBox,
  InvalidAppointmentTimeError,
  PhoneSection,
} from './styles';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';

interface FormProps {
  time: string;
  phone: string;
}

interface AvailabilityPerDay {
  value: string;
  label: string;
}

export interface DateRangeProps {
  startDate: Date;
  endDate: Date;
}

interface ScheduleAppointmentProps extends ModalProps {
  isBulk: boolean;
  isBulkFilter?: boolean;
  bulkList: BulkList[];
  bulkAvailability: BulkAvailability[];
  handleBulkAvailability: (availabilities: BulkAvailability[]) => void;
}

const ScheduleAppointment: React.FC<ScheduleAppointmentProps> = ({
  flow,
  isBulk,
  isBulkFilter,
  bulkList,
  bulkAvailability,
  goTo,
  back,
  next,
  edit,
  handleBulkAvailability,
}) => {
  const { closeModal } = useModal();
  const { saveLocal, appointmentSelected: appointment } = useAppointment();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);

  const [singleDate, setSingleDate] = useState(() =>
    appointment?.date ? new Date(appointment.date) : startOfDay(new Date()),
  );

  const [availabilityPerDay, setAvailabilityPerDay] = useState<
    AvailabilityPerDay[]
  >([]);

  const dateSelected = useMemo(() => singleDate, [singleDate]);

  const [dateRange, setDateRange] = useState<DateRangeProps>({
    startDate: startOfDay(new Date()),
    endDate: startOfDay(new Date()),
  });

  const schedulesPerDayRange = useMemo(
    () =>
      bulkAvailability
        .filter((item) => item.availabilities.length > 0)
        .map((item) => ({
          date: format(new Date(item.date), 'dd/MM'),
          schedules: item.availabilities.length,
        })),
    [bulkAvailability],
  );

  const enoughSchedules = useMemo(() => {
    const schedules = bulkAvailability.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.availabilities.length,
      0,
    );

    return bulkList.length <= schedules;
  }, [bulkAvailability, bulkList]);

  const [isAppointmentTimeInvalid, setIsAppointmentTimeInvalid] =
    useState(false);

  const getAvailabilityPerDay = useCurrentCallback(
    (isCurrent) =>
      async (
        callback?: (
          s: {
            value: string;
            label: string;
          }[],
        ) => void,
      ) => {
        await getProfessionalScheduleByDay({
          professionalId: appointment?.professionalId,
          patientId: appointment?.patientId,
          day: singleDate.getDate(),
          month: singleDate.getMonth() + 1,
          year: singleDate.getFullYear(),
        }).then((schedules) => {
          const newSchedules = schedules
            .filter((schedule) => schedule.available && !schedule.booked)
            .map((schedule) => ({
              value: format(new Date(schedule.interval), 'HH:mm'),
              label: format(new Date(schedule.interval), 'HH:mm'),
            }));

          if (callback && isCurrent()) {
            callback(newSchedules);
          }
        });
      },
    [appointment, singleDate],
  );

  const setTimeValueToField = useCallback(() => {
    if (appointment?.date) {
      const appointmentDate = startOfDay(new Date(appointment.date));
      const currentDate = startOfDay(dateSelected);

      if (differenceInDays(appointmentDate, currentDate) !== 0) {
        formRef.current?.setFieldValue('time', '00:00');

        return;
      }

      const scheduleTime = format(new Date(appointment.date), 'HH:mm', {
        locale: ptBR,
      });

      formRef.current?.setFieldValue('time', `${scheduleTime}`);
    } else {
      formRef.current?.setFieldValue('time', '00:00');
    }
  }, [appointment, dateSelected]);

  const getAvailabilityPerDayRange = useCurrentCallback(
    (isCurrent) => async () => {
      setLoading(true);

      getProfessionalScheduleByDayRange({
        professionalId: appointment?.professionalId ?? '',
        startDay: dateRange.startDate.getDate(),
        endDay: dateRange.endDate.getDate(),
        month: dateRange.startDate.getMonth() + 1,
        year: dateRange.startDate.getFullYear(),
      })
        .then((schedules) => {
          if (isCurrent()) {
            handleBulkAvailability(schedules);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [appointment, dateRange, handleBulkAvailability],
  );

  useCurrentEffect(
    (isCurrent) => {
      if (isBulk) {
        getAvailabilityPerDayRange();
      } else {
        getAvailabilityPerDay((schedules) => {
          if (isCurrent()) {
            setAvailabilityPerDay(schedules);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRange, singleDate],
  );

  useEffect(() => {
    setTimeValueToField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityPerDay]);

  const getValues = useCallback(
    ({ phone, time: formDataTime }: FormProps) => {
      const time = formDataTime.split(':');
      const hour = Number(time[0]);
      const minutes = Number(time[1]);
      const date = dateSelected.setHours(hour, minutes);

      return { time: new Date(date).toString(), phone };
    },
    [dateSelected],
  );

  const getRawValues = useCallback(() => {
    const formData: FormProps = {
      ...formRef.current?.getData(),
    } as FormProps;

    return formData;
  }, []);

  const getValidate = useCurrentCallback(
    (isCurrent) => async (data: FormProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          phone: Yup.string()
            .required('O telefone é obrigatório')
            .length(16, 'Digite um número de telefone válido'),
          time: Yup.string()
            .required('Horário obrigatório')
            .test('validate-time', 'Horário invalido', (value) =>
              isValid(new Date(value)),
            )
            .test(
              'time-has-passed',
              'O Horário já passou',
              (value) => !isBefore(new Date(value), new Date()),
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (isCurrent()) {
          setIsAppointmentTimeInvalid(false);
        }

        return true;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          if (isCurrent()) {
            setSingleDate(startOfDay(new Date()));
            setIsAppointmentTimeInvalid(true);
          }
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Entre em contato com o administrador.');
        }

        return false;
      }
    },
    [],
  );

  const handleNext = useCurrentCallback(
    (isCurrent) => async () => {
      if (isBulk) {
        if (!enoughSchedules) {
          toast.info('Horários insuficientes para a quantidade de pacientes.');
          return;
        }
      } else {
        const canProceed = await getValidate(getValues(getRawValues()));
        const { phone, time: scheduleTime } = getValues(getRawValues());

        if (!canProceed || !isCurrent()) {
          return;
        }

        saveLocal({
          date: scheduleTime,
          contact_phone: phone ?? appointment?.contact_phone,
        });
      }

      if (flow === 'create') {
        if (next) {
          next();
        }
      } else if (edit) {
        edit();
      }
    },
    [
      appointment,
      enoughSchedules,
      flow,
      isBulk,
      edit,
      getRawValues,
      getValidate,
      getValues,
      next,
      saveLocal,
    ],
  );

  const handleBack = useCallback(() => {
    if (flow === 'create') {
      if (isBulkFilter && goTo) {
        goTo('ScheduleContact');
      } else if (back) {
        back();
      }
    } else if (edit) {
      edit();
    }
  }, [flow, isBulkFilter, goTo, back, edit]);

  const handleNewDateRange = useCallback((range: DateRangeProps) => {
    setDateRange(range);
  }, []);

  return (
    <>
      <Container>
        <section>
          <header>
            <h1>
              {flow === 'create'
                ? `Agendar ${appointment?.type} `
                : `Editar ${appointment?.type} `}
              {appointment?.type === AppointmentTypeEnum.PHONE && <PhoneIcon />}
              {appointment?.type === AppointmentTypeEnum.SMS && <MessageIcon />}
              {appointment?.type === AppointmentTypeEnum.VIDEO && <FilmIcon />}

              {isBulk && <span>(Em massa)</span>}
            </h1>

            {isBulk ? (
              <p>Próximas datas com horários disponíveis:</p>
            ) : (
              <>
                <h3>Selecione a data e horário do atendimento:</h3>
                <p>
                  Nós enviaremos um lembrete para o paciente, conforme o prazo
                  escolhido.
                </p>
              </>
            )}
          </header>

          {isBulk ? (
            <CalendarBox>
              <DateRange
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(date: any) => handleNewDateRange(date.range1)}
                weekStartsOn={0}
                locale={locales.pt}
                ranges={[dateRange]}
                minDate={new Date()}
                showMonthArrow={false}
              />
            </CalendarBox>
          ) : (
            <CalendarBox>
              <CalendarDefault
                defaultDate={singleDate}
                onChange={(date) => setSingleDate(startOfDay(date))}
                selectRange={false}
              />
            </CalendarBox>
          )}
        </section>

        <section>
          {isBulk ? (
            <BulkBox>
              <div>
                <PeopleIcon />
                <h1>
                  Agendamento para <strong>{bulkList.length}</strong>{' '}
                  {bulkList.length === 1 ? 'pessoa' : 'pessoas'}
                </h1>
                <button type="button" onClick={handleBack}>
                  Alterar
                </button>
              </div>

              <Profissional>
                <h1>Profissional:</h1>

                <div>
                  <p>{appointment?.professional?.user?.name}</p>
                  <img
                    src={appointment?.professional?.user?.avatar}
                    alt={`Foto de ${appointment?.professional?.user?.name}`}
                  />
                </div>
              </Profissional>

              {loading ? (
                <LoadingBox>
                  <Spinner />
                </LoadingBox>
              ) : (
                <>
                  {schedulesPerDayRange.length ? (
                    <TimeBox>
                      {schedulesPerDayRange.map(({ date, schedules }) => (
                        <div key={date}>
                          <BiCalendarAlt />
                          <strong>{date}</strong>
                          <span>Horários disponíveis: {schedules}</span>
                        </div>
                      ))}
                    </TimeBox>
                  ) : (
                    <NoAvailableBox>
                      <BiInfoCircle />
                      <h1>Sem horários disponíveis</h1>
                    </NoAvailableBox>
                  )}
                </>
              )}
            </BulkBox>
          ) : (
            <Form
              ref={formRef}
              initialData={{ doctor: appointment?.professional?.user?.name }}
              onSubmit={getValidate}
            >
              <h3>Escolha um horário:</h3>

              {appointment?.type !== AppointmentTypeEnum.SMS ? (
                <InputSelectAlternative
                  name="time"
                  className="inputSelectTime"
                  maxMenuHeight={150}
                  options={availabilityPerDay}
                  placeholder={
                    availabilityPerDay.length
                      ? '00:00'
                      : 'Sem horários disponíveis'
                  }
                  isCentered
                />
              ) : (
                <InputTimeBox>
                  <InputIcon
                    name="time"
                    mask="99:99"
                    placeholder="00:00"
                    icon={<IconInputClockSpacegray />}
                  />
                </InputTimeBox>
              )}

              {isAppointmentTimeInvalid && (
                <InvalidAppointmentTimeError
                  isFullWidth={appointment?.type !== AppointmentTypeEnum.SMS}
                >
                  <strong>Atenção!</strong> O horário selecionado é inválido ou
                  não está mais disponível. Por favor, selecione outro horário.
                </InvalidAppointmentTimeError>
              )}

              <PhoneSection isVisible>
                <h3>Número a ser discado:</h3>

                <InputIcon
                  name="phone"
                  mask="(99) 9 9999-9999"
                  defaultValue={
                    appointment?.contact_phone ??
                    appointment?.patient?.user?.phone
                  }
                  icon={<IconInputPhoneSpacegray />}
                  withChangeButton
                />
              </PhoneSection>
            </Form>
          )}
        </section>
      </Container>

      <ButtonsContainer>
        <Button color="white" onClick={() => closeModal()}>
          Cancelar
        </Button>

        <Button color="primary" lite onClick={handleBack}>
          Voltar
        </Button>

        <Button color="primary" onClick={handleNext}>
          {flow === 'create' ? 'Próximo' : 'Salvar'}
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default ScheduleAppointment;
