import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'react-brave-modal';
import { format, subHours } from 'date-fns';
import { MdRemoveRedEye } from 'react-icons/md';

import ActionMenuButtonList from '../../../../../components/ActionMenuButtonList';
import InputCheckWithoutForm from '../../../../../components/InputCheckWithoutForm';
import TooltipGeneric from '../../../../../components/TooltipGeneric';
import ShowMessageScheduleModal from '../../../../../modals/ShowMessageScheduleModal';
import ContentNothingToDisplay from '../../../../../components/ContentNothingToDisplay';
import { usePermission } from '../../../../../hooks/permission';
import {
  GetAttendancesByFilter as ListFilter,
  GetAttendancesByFilterResponse as ListData,
} from '../../../../../services/reports/attendances';
import { getAvatarImage } from '../../../../../services/user';
import getRiskDegree from '../../../../../utils/getRiskDegree';
import Avatar from '../../../../../assets/images/avatar.svg';
import { ReactComponent as ArrowUpDownIcon } from '../../../../../assets/images/arrowupdown.svg';
import { ReactComponent as PhoneIcon } from '../../../../../assets/images/icon_schedule_phone.svg';
import { ReactComponent as MessageIcon } from '../../../../../assets/images/icon_schedule_message.svg';
import { ReactComponent as FilmIcon } from '../../../../../assets/images/icon_schedule_film.svg';
import { ReactComponent as PresentialIcon } from '../../../../../assets/images/icon_schedule_presential.svg';

import {
  Container,
  TopBar,
  Actions,
  Info,
  AttendanceList,
  SortButton,
  DegreeTag,
  TypeTag,
  StatusTag,
  ViewButton,
  ContentNothingToDisplayBox,
  StyledLink,
  AttendanceListRow as AttendanceListHeaderRow,
  AttendanceListSectionRow,
  TableStyledCell as AttendanceListHeaderCell,
  AttendanceListSectionCell,
} from './styles';
import { useCurrentEffect } from '../../../../../lib/use-current-effect';
import AppointmentTypeEnum from '../../../../../enums/AppointmentTypeEnum';
import AttendanceStatusEnum from '../../../../../enums/AttendanceStatusEnum';
import OrdenationTypeEnum from '../../../../../enums/OrdenationTypeEnum';

interface AttendanceList {
  id: string;
  patientId: string;
  patientName: string;
  patientImage: string;
  attendanceDate: string;
  risk: {
    degree: string | number;
    color: string;
  };
  type?: {
    icon: JSX.Element;
    title: string;
  };
  status: {
    abbreviation: string;
    background: string;
    color: string;
    title: string;
  };
  professionalName: string;
  attendanceDuration: string;
  totalDuration: string;
  message: {
    text?: string;
    phone?: string;
  };
  isMessage: boolean;
  isChecked: boolean;
}

interface ImageList {
  id?: string;
  avatar?: string;
}

interface TableProps {
  setListFilter(listFilter: React.SetStateAction<ListFilter>): void;
  listData: ListData;
}

enum AttendanceOrderByEnum {
  PATIENT_NAME = 'patientName',
  ATTENDANCE_DATE = 'attendanceDate',
  RISK_DEGREE = 'riskDegree',
  ATTENDANCE_TYPE = 'attendanceType',
  ATTENDANCE_STATUS = 'attendanceStatus',
  PROFESSIONAL_NAME = 'professionalName',
  ATTENDANCE_DURATION = 'attendanceDuration',
  TOTAL_DURATION = 'totalDuration',
}

const Table: React.FC<TableProps> = ({ setListFilter, listData }) => {
  const { hasPermission } = usePermission();
  const history = useHistory();
  const { showModal } = useModal();
  const [attendanceList, setAttendanceList] = useState<AttendanceList[]>([]);
  const [imageList, setImageList] = useState<ImageList[]>([]);
  const [orderBy, setOrderBy] = useState(AttendanceOrderByEnum.PATIENT_NAME);
  const [isAscending, setIsAscending] = useState(true);

  const canView = useMemo(
    () => ({
      [AppointmentTypeEnum.PHONE]: hasPermission('call_attendance'),
      [AppointmentTypeEnum.SMS]: hasPermission('message_attendance'),
      [AppointmentTypeEnum.VIDEO]: hasPermission('video_attendance'),
      [AppointmentTypeEnum.PRESENTIAL]: hasPermission('attendances'),
      patientProfile: hasPermission('patient_profile'),
    }),
    [hasPermission],
  );

  const allItemsChecked = useMemo(
    () => attendanceList.every((attendance) => attendance.isChecked),
    [attendanceList],
  );

  const attendanceType = useMemo(
    () => ({
      [AppointmentTypeEnum.PHONE]: {
        icon: <PhoneIcon />,
        title: AppointmentTypeEnum.PHONE,
      },
      [AppointmentTypeEnum.SMS]: {
        icon: <MessageIcon />,
        title: AppointmentTypeEnum.SMS,
      },
      [AppointmentTypeEnum.VIDEO]: {
        icon: <FilmIcon />,
        title: AppointmentTypeEnum.VIDEO,
      },
      [AppointmentTypeEnum.PRESENTIAL]: {
        icon: <PresentialIcon />,
        title: AppointmentTypeEnum.PRESENTIAL,
      },
    }),
    [],
  );

  const attendanceStatus = useMemo(
    () => ({
      [AttendanceStatusEnum.ACCOMPLISHED]: {
        abbreviation: 'R',
        background: 'rgba(111, 207, 151, 0.2)',
        color: '#47C27A',
        title: 'Realizado',
      },
      [AttendanceStatusEnum.NOT_ACCOMPLISHED]: {
        abbreviation: 'N.R',
        background: 'rgba(235, 87, 87, 0.2)',
        color: '#EB5757',
        title: 'Não realizado',
      },
      [AttendanceStatusEnum.EFFECTED]: {
        abbreviation: 'E',
        background: 'rgba(111, 207, 151, 0.2)',
        color: '#47C27A',
        title: 'Efetuado',
      },
      [AttendanceStatusEnum.NOT_EFFECTED]: {
        abbreviation: 'N.E',
        background: 'rgba(235, 87, 87, 0.2)',
        color: '#EB5757',
        title: 'Não efetuado',
      },
      [AttendanceStatusEnum.UNPRODUCTIVE]: {
        abbreviation: 'I',
        background: 'rgba(242, 201, 76, 0.2)',
        color: '#EEBA1B',
        title: 'Improdutivo',
      },
      withoutStatus: {
        abbreviation: 'SS',
        background: 'rgba(189, 189, 189, 1.0)',
        color: '#FFFFFF',
        title: 'Sem status',
      },
    }),
    [],
  );

  useEffect(() => {
    const attendances = listData.attendances.map<AttendanceList>(
      (attendance) => ({
        id: attendance.id ?? '-',
        patientId: attendance.patientId || '',
        patientName: attendance.patient?.user?.name ?? '-',
        patientImage:
          imageList.find(
            (patient) => patient.id === attendance?.patient?.user?.id,
          )?.avatar ?? Avatar,
        attendanceDate: attendance.details?.created_at
          ? format(
              subHours(new Date(attendance.details.created_at), 3),
              'dd/MM/yyyy - HH:mm:ss',
            )
          : '-',
        risk: {
          degree: getRiskDegree.risk(
            attendance.patient?.currentRiskDegree &&
              attendance.patient.currentRiskDegree.degree,
          ),
          color: getRiskDegree.color(
            attendance.patient?.currentRiskDegree &&
              attendance.patient.currentRiskDegree.degree,
          ),
        },
        type: attendance.type && attendanceType[attendance.type],
        status: attendance.details?.attendanceStatus
          ? attendanceStatus[attendance.details.attendanceStatus]
          : attendanceStatus.withoutStatus,
        professionalName:
          (attendance.type !== AppointmentTypeEnum.SMS
            ? attendance.attendantProfessional?.user?.name
            : attendance.professional?.user?.name) ?? '-',
        attendanceDuration: attendance.details?.duration ?? '-',
        totalDuration: attendance.details?.totalDuration ?? '-',
        message: {
          text: attendance.message?.text,
          phone: attendance.message?.contact_phone,
        },
        isMessage: attendance.type === AppointmentTypeEnum.SMS,
        isChecked: false,
      }),
    );

    setAttendanceList(attendances);
  }, [listData, imageList, attendanceType, attendanceStatus]);

  useCurrentEffect(
    (isCurrent) => {
      const patientList = listData.attendances
        .map((attendance) => ({
          id: attendance.patient?.user?.id,
          avatar: attendance.patient?.user?.avatar,
        }))
        .reduce((accumulator, current) => {
          if (!accumulator.some((item) => item.id === current.id)) {
            accumulator.push(current);
          }

          return accumulator;
        }, [] as ImageList[]);

      Promise.all(
        patientList.map(async (patient) => ({
          id: patient.id,
          avatar: patient.avatar
            ? await getAvatarImage(patient.avatar)
            : Avatar,
        })),
      ).then((imageListResult) => {
        if (isCurrent()) {
          setImageList(imageListResult);
        }
      });
    },
    [listData],
  );

  const handleOrdenation = useCallback(
    (orderKey: AttendanceOrderByEnum, isInitialOrdering: boolean) => {
      if (isInitialOrdering) {
        setListFilter((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: OrdenationTypeEnum.ASC,
        }));
        setIsAscending(true);
      } else {
        setListFilter((prevState) => ({
          ...prevState,
          orderBy: orderKey,
          ordenationType: isAscending
            ? OrdenationTypeEnum.DESC
            : OrdenationTypeEnum.ASC,
        }));
        setIsAscending((prevState) => !prevState);
      }

      setOrderBy(orderKey);
    },
    [isAscending, setListFilter],
  );

  const handleCheck = useCallback(
    (key: string, value: boolean) => {
      const newListDisplay = attendanceList.map((item) => {
        if (item.id === key) {
          item.isChecked = value;
        }

        return item;
      });

      setAttendanceList([...newListDisplay]);
    },
    [attendanceList],
  );

  const handleCheckAllInputs = useCallback((value: boolean) => {
    setAttendanceList((state) =>
      state.map((item) => ({ ...item, isChecked: value })),
    );
  }, []);

  const handleAttendanceView = useCallback(
    (attendance: AttendanceList) => {
      if (attendance.isMessage) {
        showModal({
          type: 'custom',
          data: (
            <ShowMessageScheduleModal
              message={{
                text: attendance.message.text,
                phone: attendance.message.phone,
              }}
            />
          ),
        });
      } else {
        history.push(`/patient/attendance?id=${attendance.id}`);
      }
    },
    [history, showModal],
  );

  return (
    <>
      {attendanceList.length ? (
        <Container>
          <TopBar>
            <Actions>
              <span>Ações</span>
              <ActionMenuButtonList
                disabled
                list={[
                  { name: 'Editar', disabled: true },
                  { name: 'Remover', disabled: true },
                ]}
              />
            </Actions>
            <Info>
              <span>
                Exibindo <strong>{listData.totalAttendancesOnPage}</strong>{' '}
                {`resultado${listData.totalAttendancesOnPage > 1 ? 's' : ''} `}
                de <strong>{listData.totalAttendances}</strong>
              </span>
            </Info>
          </TopBar>
          <AttendanceList>
            <header>
              <AttendanceListHeaderRow>
                <InputCheckWithoutForm
                  type="checkbox"
                  onChange={(event) =>
                    handleCheckAllInputs(event.target.checked)
                  }
                  checked={allItemsChecked}
                />
                <AttendanceListHeaderCell>
                  Paciente
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={orderBy === AttendanceOrderByEnum.PATIENT_NAME}
                    onClick={() =>
                      handleOrdenation(
                        AttendanceOrderByEnum.PATIENT_NAME,
                        orderBy !== AttendanceOrderByEnum.PATIENT_NAME,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </AttendanceListHeaderCell>
                <AttendanceListHeaderCell>
                  Data de Atendimento
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={orderBy === AttendanceOrderByEnum.ATTENDANCE_DATE}
                    onClick={() =>
                      handleOrdenation(
                        AttendanceOrderByEnum.ATTENDANCE_DATE,
                        orderBy !== AttendanceOrderByEnum.ATTENDANCE_DATE,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </AttendanceListHeaderCell>
                <AttendanceListHeaderCell>
                  Grau de Risco
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={orderBy === AttendanceOrderByEnum.RISK_DEGREE}
                    onClick={() =>
                      handleOrdenation(
                        AttendanceOrderByEnum.RISK_DEGREE,
                        orderBy !== AttendanceOrderByEnum.RISK_DEGREE,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </AttendanceListHeaderCell>
                <AttendanceListHeaderCell>
                  Tipo de Atendimento
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={orderBy === AttendanceOrderByEnum.ATTENDANCE_TYPE}
                    onClick={() =>
                      handleOrdenation(
                        AttendanceOrderByEnum.ATTENDANCE_TYPE,
                        orderBy !== AttendanceOrderByEnum.ATTENDANCE_TYPE,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </AttendanceListHeaderCell>
                <AttendanceListHeaderCell>
                  Status
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={
                      orderBy === AttendanceOrderByEnum.ATTENDANCE_STATUS
                    }
                    onClick={() =>
                      handleOrdenation(
                        AttendanceOrderByEnum.ATTENDANCE_STATUS,
                        orderBy !== AttendanceOrderByEnum.ATTENDANCE_STATUS,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </AttendanceListHeaderCell>
                <AttendanceListHeaderCell>
                  Profissional
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={
                      orderBy === AttendanceOrderByEnum.PROFESSIONAL_NAME
                    }
                    onClick={() =>
                      handleOrdenation(
                        AttendanceOrderByEnum.PROFESSIONAL_NAME,
                        orderBy !== AttendanceOrderByEnum.PROFESSIONAL_NAME,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </AttendanceListHeaderCell>
                <AttendanceListHeaderCell>
                  Duração do Atendimento
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={
                      orderBy === AttendanceOrderByEnum.ATTENDANCE_DURATION
                    }
                    onClick={() =>
                      handleOrdenation(
                        AttendanceOrderByEnum.ATTENDANCE_DURATION,
                        orderBy !== AttendanceOrderByEnum.ATTENDANCE_DURATION,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </AttendanceListHeaderCell>
                <AttendanceListHeaderCell>
                  Duração Total
                  <SortButton
                    type="button"
                    isAscending={isAscending}
                    isActive={orderBy === AttendanceOrderByEnum.TOTAL_DURATION}
                    onClick={() =>
                      handleOrdenation(
                        AttendanceOrderByEnum.TOTAL_DURATION,
                        orderBy !== AttendanceOrderByEnum.TOTAL_DURATION,
                      )
                    }
                  >
                    <ArrowUpDownIcon />
                  </SortButton>
                </AttendanceListHeaderCell>
              </AttendanceListHeaderRow>
            </header>
            <section>
              {attendanceList.map((attendance) => (
                <AttendanceListSectionRow key={attendance.id}>
                  <AttendanceListSectionCell>
                    <InputCheckWithoutForm
                      type="checkbox"
                      onChange={({ target: { checked } }) =>
                        handleCheck(attendance.id ?? '', checked)
                      }
                      checked={attendance.isChecked}
                    />
                  </AttendanceListSectionCell>
                  <AttendanceListSectionCell>
                    <StyledLink
                      to={`/patient/profile?id=${attendance.patientId}`}
                      disabled={!canView.patientProfile}
                    >
                      <img
                        src={attendance.patientImage}
                        alt={`Foto de ${attendance.patientName}`}
                      />
                      <span>{attendance.patientName}</span>
                    </StyledLink>
                  </AttendanceListSectionCell>
                  <AttendanceListSectionCell>
                    <span>{attendance.attendanceDate}</span>
                  </AttendanceListSectionCell>
                  <AttendanceListSectionCell>
                    <DegreeTag color={attendance.risk.color}>
                      <span>{attendance.risk.degree}</span>
                    </DegreeTag>
                  </AttendanceListSectionCell>
                  <AttendanceListSectionCell>
                    {attendance.type && (
                      <TooltipGeneric
                        element={<TypeTag>{attendance.type.icon}</TypeTag>}
                        title={attendance.type.title}
                      />
                    )}
                  </AttendanceListSectionCell>
                  <AttendanceListSectionCell>
                    <TooltipGeneric
                      element={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <StatusTag
                          background={attendance.status.background}
                          color={attendance.status.color}
                        >
                          {attendance.status.abbreviation}
                        </StatusTag>
                      }
                      title={attendance.status.title}
                    />
                  </AttendanceListSectionCell>
                  <AttendanceListSectionCell>
                    <span>{attendance.professionalName}</span>
                  </AttendanceListSectionCell>
                  <AttendanceListSectionCell>
                    <span>{attendance.attendanceDuration}</span>
                  </AttendanceListSectionCell>
                  <AttendanceListSectionCell>
                    <span>{attendance.totalDuration}</span>
                  </AttendanceListSectionCell>
                  <AttendanceListSectionCell>
                    <ViewButton
                      type="button"
                      onClick={() => handleAttendanceView(attendance)}
                      disabled={
                        attendance.type &&
                        !canView[attendance.type.title as AppointmentTypeEnum]
                      }
                    >
                      <MdRemoveRedEye size={20} />
                      <span>Visualizar</span>
                    </ViewButton>
                  </AttendanceListSectionCell>
                </AttendanceListSectionRow>
              ))}
            </section>
          </AttendanceList>
        </Container>
      ) : (
        <ContentNothingToDisplayBox>
          <ContentNothingToDisplay text="Nenhum dado encontrado" />
        </ContentNothingToDisplayBox>
      )}
    </>
  );
};

export default Table;
