import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  button {
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    max-width: 235px;
  }

  > form {
    margin-top: 35px;

    > div {
      > textarea {
        min-width: 400px;
        height: 275px;
        resize: none;
      }

      > textarea,
      input {
        font-weight: normal;
      }

      :nth-child(4) > textarea {
        height: 2.5vh;
      }

      :nth-child(6) > textarea {
        height: 5vh;
      }
    }
  }
`;
