import Resizer from 'react-image-file-resizer';

const compressImage = (file: File): Promise<string> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      50,
      0,
      (result) => {
        if (result) {
          resolve(result.toString());
        }
      },
      'base64',
    );
  });

export default compressImage;
