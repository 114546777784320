import React from 'react';
import Button from '../../../../components/Button';

import { Container } from './styles';

interface CancelProps {
  goBack: () => any;
  cancel: () => any;
}

const Cancel: React.FC<CancelProps> = ({ goBack, cancel }) => {
  return (
    <Container>
      <h1>Deseja realmente cancelar?</h1>
      <h1>Os dados não serão salvos.</h1>
      <footer>
        <Button buttonType="primary" onClick={goBack}>
          Não, continuar editando
        </Button>
        <Button buttonType="primary-alternative" onClick={cancel}>
          Sim, cancelar
        </Button>
      </footer>
    </Container>
  );
};

export default Cancel;
