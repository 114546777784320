import React, { InputHTMLAttributes } from 'react';

import { Container } from './styles';
import './style.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean | undefined;
}

const InputCheckWithoutForm: React.FC<InputProps> = ({
  checked = false,
  ...rest
}) => {
  return (
    <Container>
      <label className="container">
        <input type="checkbox" checked={checked} {...rest} />
        <span className="checkmark" />
      </label>
    </Container>
  );
};

export default InputCheckWithoutForm;
