import React, { useMemo } from 'react';
import Chart from 'react-google-charts';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';
import CloudFileGrayImage from '../../../../assets/images/cloud-file-gray.svg';

import { CardHeader, CardTitle } from '../styles';
import {
  Container,
  Content,
  LeftSide,
  RightSide,
  Pipe,
  InstitutionsTotal,
  TableContainer,
} from './styles';
import Spinner from '../../../../components/Spinner';
import theme from '../../../../styles/theme';

interface PatientByInstitution {
  id: string;
  name: string;
  patients: number;
  percentage: string;
}
export interface PatientsByInstitutionProps {
  data: {
    totalInstitutions: number;
    patientsByInstitutions: PatientByInstitution[];
  };
}

const PatientsByInstitution: React.FC<PatientsByInstitutionProps> = ({
  data,
}) => {
  const displayableData = useMemo(
    () =>
      data?.patientsByInstitutions.map<[string, number, string]>((info) => {
        const fullname = info.name.split(' ');
        const shortName = `${fullname
          .shift()
          ?.substring(0, 4)} ${fullname.pop()}`;

        return [
          shortName,
          info.patients,
          `<div style="padding: 5px"><strong>${info.name}</strong><p>pacientes: <b>${info.patients}</b></p><p>porcentagem: <b>${info.percentage}%</b></p></div>`,
        ];
      }),
    [data],
  );

  return (
    <Container gridArea="pi">
      <CardHeader>
        <CardTitle>Pacientes por Instituição</CardTitle>
      </CardHeader>
      {data && data.patientsByInstitutions ? (
        <Content>
          <LeftSide>
            <InstitutionsTotal>
              <span>Total de Instituições</span>
              <strong>{data.totalInstitutions}</strong>
            </InstitutionsTotal>
            <TableContainer>
              <table>
                <tr>
                  <th>Principais Instituições</th>
                  <th>Nº</th>
                  <th>%</th>
                </tr>
                {data.patientsByInstitutions.map(
                  (institution, index) =>
                    index < 4 && (
                      <tr key={institution.id}>
                        <td>{institution.name}</td>
                        <td>{institution.patients}</td>
                        <td>{institution.percentage}</td>
                      </tr>
                    ),
                )}
              </table>
            </TableContainer>
          </LeftSide>
          <Pipe />
          <RightSide>
            <Chart
              width="100%"
              height="300px"
              chartType="ColumnChart"
              loader={<Spinner />}
              style={{
                marginBottom: '30px',
              }}
              options={{
                legend: { position: 'none' },
                colors: [theme.colors.primary],
                bar: { groupWidth: '80%' },
                chartArea: { width: '85%' },
                tooltip: { isHtml: true },
              }}
              data={[
                [
                  '',
                  '',
                  { type: 'string', role: 'tooltip', p: { html: true } },
                ],
                ...(displayableData as unknown as string[]),
              ]}
              rootProps={{ 'data-testid': '1' }}
            />
          </RightSide>
        </Content>
      ) : (
        <CardContentNothingToDisplay imageWidth="140px">
          <img src={CloudFileGrayImage} alt="" />
          Sem informações cadastradas
        </CardContentNothingToDisplay>
      )}
    </Container>
  );
};

export default PatientsByInstitution;
