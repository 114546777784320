import CallStatusEnum from '../enums/CallStatusEnum';

export const getPhoneCallStatus = [
  {
    title: 'Ligação não efetivada:',
    options: [
      {
        value: CallStatusEnum.PHONE_NOT_EXIST,
        label: 'Telefone não existe',
      },
      {
        value: CallStatusEnum.DID_NOT_ANSWER,
        label: 'Não atendeu',
      },
      {
        value: CallStatusEnum.UNCOMPLETED_CALL,
        label: 'Ligação não completada',
      },
      {
        value: CallStatusEnum.MESSAGE_BOX,
        label: 'Caixa de Mensagem',
      },
    ],
  },
  {
    title: 'Ligação efetivada:',
    options: [
      {
        value: CallStatusEnum.AGREED_PARTICIPATE,
        label: 'Paciente aceitou participar do projeto',
      },
      {
        value: CallStatusEnum.ACTIVITY_PERFORMED,
        label: 'Realizada a atividade programada',
      },
      {
        value: CallStatusEnum.HOSPITALIZED_PATIENT,
        label: 'Paciente internado',
      },
    ],
  },
  {
    title: 'Ligação improdutiva:',
    options: [
      {
        value: CallStatusEnum.REFUSED_ANSWER,
        label: 'Paciente se recusou a atender',
      },
      {
        value: CallStatusEnum.IS_NOT_HOME,
        label: 'Paciente não está em casa ',
      },
      {
        value: CallStatusEnum.PHONE_NOT_BELONG_PATIENT,
        label: 'Telefone não pertence ao paciente',
      },
    ],
  },
];

export const getVideoCallStatus = [
  {
    title: 'Chamada de vídeo não efetivada:',
    options: [
      {
        value: CallStatusEnum.DID_NOT_ANSWER,
        label: 'Não atendeu',
      },
      {
        value: CallStatusEnum.UNCOMPLETED_CALL,
        label: 'Chamada não completada',
      },
    ],
  },
  {
    title: 'Chamada de vídeo efetivada:',
    options: [
      {
        value: CallStatusEnum.AGREED_PARTICIPATE,
        label: 'Paciente aceitou participar do projeto',
      },
      {
        value: CallStatusEnum.ACTIVITY_PERFORMED,
        label: 'Realizada a atividade programada',
      },
      {
        value: CallStatusEnum.HOSPITALIZED_PATIENT,
        label: 'Paciente internado',
      },
    ],
  },
  {
    title: 'Chamada de vídeo improdutiva:',
    options: [
      {
        value: CallStatusEnum.REFUSED_ANSWER,
        label: 'Paciente se recusou a atender',
      },
      {
        value: CallStatusEnum.IS_NOT_HOME,
        label: 'Paciente não está em casa ',
      },
      {
        value: CallStatusEnum.CONNECTION_PROBLEM,
        label: 'Problema de conexão',
      },
    ],
  },
];

export const getAllCallStatusByKey = (
  callStatus?: CallStatusEnum,
): string | undefined => {
  switch (callStatus) {
    case CallStatusEnum.ACTIVITY_PERFORMED:
      return 'Realizada a atividade programada';
    case CallStatusEnum.AGREED_PARTICIPATE:
      return 'Paciente aceitou participar do projeto';
    case CallStatusEnum.CONNECTION_PROBLEM:
      return 'Problema de conexão';
    case CallStatusEnum.DID_NOT_ANSWER:
      return 'Não atendeu';
    case CallStatusEnum.HOSPITALIZED_PATIENT:
      return 'Paciente internado';
    case CallStatusEnum.IS_NOT_HOME:
      return 'Paciente não está em casa';
    case CallStatusEnum.MESSAGE_BOX:
      return 'Caixa de Mensagem';
    case CallStatusEnum.PHONE_NOT_BELONG_PATIENT:
      return 'Telefone não pertence ao paciente';
    case CallStatusEnum.PHONE_NOT_EXIST:
      return 'Telefone não existe';
    case CallStatusEnum.REFUSED_ANSWER:
      return 'Paciente se recusou a atender';
    case CallStatusEnum.UNCOMPLETED_CALL:
      return 'Chamada não completada';
    default:
      return undefined;
  }
};
