import { api } from '../api';

interface ResetPassword {
  token: string;
  password: string;
  passwordConfirmation: string;
  firstAccess?: boolean;
}

export const resetPassword = async (userData: ResetPassword): Promise<void> => {
  await api.patch('/users/password/reset', userData);
};
