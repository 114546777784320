import styled from 'styled-components';

export const Container = styled.div`
  width: 547px;
  height: 639px;
  margin: 0 auto;
  padding: 0 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.colors.grayAlternative};
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  > h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    margin: 0.5rem 0;
  }

  > p {
    strong {
      color: ${({ theme }) => theme.colors.primary};
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  button {
    width: 184px;
  }
`;

export const PatientsList = styled.div`
  max-height: 230px;
  display: flex;
  flex-direction: column;
  min-width: 430px;
  margin: 0.5rem 0;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 5px;
  padding: 1rem;

  overflow: auto;

  position: relative;

  strong {
    font-weight: bold;
    padding: 1rem 0 2rem 0;
  }

  button {
    position: absolute;
    top: 15px;
    right: 5px;
  }
`;

export const PatientsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 0.5rem 0;

  img {
    margin-right: 10px;
  }
`;
