import React, { useState } from 'react';

import { getPatientsByProfessional } from '../../../../../services/patient';
import { Patient } from '../../../../../entities/Patient';
import { User } from '../../../../../entities/User';

import Spinner from '../../../../../components/Spinner';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../../../../lib/use-current-effect';
import PatientsByHealthConditionsChart from '../../../../../components/PatientsByHealthConditionChart';
import HealthConditionPerChapter from '../../../../../enums/HealthConditionPerChapter';

interface InformationProps {
  id: string;
  name: string;
  patients: number;
  color: string;
}

interface PatientsByHealthConditionProps {
  user?: User;
}

interface ColorMappingHealthCondition {
  colors: { [key in HealthConditionPerChapter]: string };
}

const PatientsByHealthConditions: React.FC<PatientsByHealthConditionProps> = ({
  user,
}) => {
  const [informations, setInformations] = useState<InformationProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [patientsByProfessional, setPatientsByProfessional] = useState<
    Patient[]
  >([]);

  const colorMapping: ColorMappingHealthCondition = {
    colors: {
      [HealthConditionPerChapter.PerinatalDisorders]: '#19C5C7',
      [HealthConditionPerChapter.InfectiousAndParasiticDiseases]: '#19C5C7',
      [HealthConditionPerChapter.ExternalCauses]: '#19C5C7',
      [HealthConditionPerChapter.SpecialPurposeCodes]: '#19C5C7',
      [HealthConditionPerChapter.SkinAndSubcutaneousTissueDiseases]: '#DB3E5A',
      [HealthConditionPerChapter.CirculatorySystemDiseases]: '#DB3E5A',
      [HealthConditionPerChapter.DigestiveSystemDiseases]: '#DB3E5A',
      [HealthConditionPerChapter.GenitourinarySystemDiseases]: '#DB3E5A',
      [HealthConditionPerChapter.RespiratorySystemDiseases]: '#F2994A',
      [HealthConditionPerChapter.EyeAndAdnexaDiseases]: '#F2994A',
      [HealthConditionPerChapter.EarAndMastoidProcessDiseases]: '#F2994A',
      [HealthConditionPerChapter.BloodAndHematopoieticOrganDiseases]: '#F2994A',
      [HealthConditionPerChapter.NervousSystemDiseases]: '#27AE60',
      [HealthConditionPerChapter.MusculoskeletalSystemDiseases]: '#27AE60',
      [HealthConditionPerChapter.EndocrineNutritionalMetabolicDiseases]:
        '#27AE60',
      [HealthConditionPerChapter.HealthStatusAndHealthServices]: '#27AE60',
      [HealthConditionPerChapter.PregnancyChildbirthAndPuerperium]: '#27AE60',
      [HealthConditionPerChapter.InjuriesAndPoisonings]: '#27AE60',
      [HealthConditionPerChapter.CongenitalMalformationsAndAbnormalities]:
        '#EEB711',
      [HealthConditionPerChapter.Neoplasms]: '#EEB711',
      [HealthConditionPerChapter.SymptomsAndAbnormalFindings]: '#EEB711',
      [HealthConditionPerChapter.MentalAndBehavioralDisorders]: '#EEB711',
    },
  };

  const getInformationsList = useCurrentCallback(
    (isCurrent) => (patients: Patient[]) => {
      const data: InformationProps[] = [];

      patients.forEach((patient) => {
        patient.healthConditions?.forEach((item) => {
          const dataId = data.findIndex(
            (dataItem) => dataItem.id === item.icd10.id,
          );

          if (item.isMainHealthCondition) {
            if (dataId >= 0) {
              data[dataId].patients += 1;
            } else {
              data.push({
                id: item.icd10.id,
                name: `${item.icd10.icd} - ${item.icd10.description}`,
                patients: 1,
                color:
                  colorMapping.colors[
                    item.icd10.chapter as HealthConditionPerChapter
                  ],
              });
            }
          }
        });
      });

      if (isCurrent()) {
        setLoading(false);
        setInformations([...data]);
      }
    },
    [],
  );

  useCurrentEffect(
    (isCurrent) => {
      if (user && user.professional_id) {
        getPatientsByProfessional(user.professional_id).then((response) => {
          getInformationsList(response);
          setPatientsByProfessional(response);
        });
      } else if (user && !user.professional_id && isCurrent()) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  return loading ? (
    <Spinner />
  ) : (
    <PatientsByHealthConditionsChart
      reportData={informations}
      totalPatientsByHealthConditions={patientsByProfessional.length}
    />
  );
};

export default PatientsByHealthConditions;
