import React, { useMemo, useState, useCallback, lazy, Suspense } from 'react';
import { useModal } from 'react-brave-modal';
import { toast } from 'react-toastify';
import { MdClose } from 'react-icons/md';
import { BiCalendarAlt } from 'react-icons/bi';

import { useAuth } from '../../../../../../hooks/auth';
import { usePatient } from '../../../../../../hooks/patient';
import { useAppointment } from '../../../../../../hooks/appointment';
import { usePermission } from '../../../../../../hooks/permission';

import FilmIcon from '../../../../../../assets/images/icon_schedule_film.svg';
import PhoneIcon from '../../../../../../assets/images/icon_schedule_phone.svg';
import MessageIcon from '../../../../../../assets/images/icon_schedule_message.svg';

import FilmIconWhite from '../../../../../../assets/images/icon_schedule_film_white.svg';
import PhoneIconWhite from '../../../../../../assets/images/icon_schedule_phone_white.svg';
import MessageIconWhite from '../../../../../../assets/images/icon_schedule_message_white.svg';

import {
  ContainerScreensDefault,
  CardFooterDefault,
  ButtonFooterDefault,
} from '../../styles';
import { Content, CardHeader, ButtonSelect, ButtonGroup } from './styles';
import AppointmentTypeEnum from '../../../../../../enums/AppointmentTypeEnum';
import AppointmentAreaEnum from '../../../../../../enums/AppointmentAreaEnum';

const NewMessageScheduleModal = lazy(
  () =>
    import(
      /* webpackChunkName: "NewMessageScheduleModal" */ '../../modals/NewMessageScheduleModal'
    ),
);

const RunAppointmentNewTabModal = lazy(
  () =>
    import(
      /* webpackChunkName: "RunAppointmentNewTabModal" */ '../../../../../../modals/RunAppointmentNewTabModal'
    ),
);

const CreateEditAppointmentModal = lazy(
  () =>
    import(
      /* webpackChunkName: "CreateEditAppointmentModal" */ '../../../../../../modals/CreateEditAppointmentModal'
    ),
);

interface MainProps {
  hasAppointment?: boolean;
  showAttendanceNow?: boolean;
  isPatientActive: boolean;
  buttonClose: boolean;
  change: (value: 'AfterScheduling' | 'Main') => void;
}

const Main: React.FC<MainProps> = ({
  hasAppointment,
  showAttendanceNow = true,
  isPatientActive,
  buttonClose,
  change,
}) => {
  const { showModal } = useModal();
  const { user } = useAuth();
  const { patient } = usePatient();
  const { createAttendance, syncByProfessional } = useAppointment();
  const { hasPermission } = usePermission();
  const [selected, setSelected] = useState<AppointmentTypeEnum>();

  const canShow = useMemo(
    () => ({
      card: isPatientActive,
      videoButton:
        hasPermission('video_attendance') && user?.type === 'professional',
      callButton:
        hasPermission('call_attendance') && user?.type === 'professional',
      messageButton:
        hasPermission('message_attendance') && user?.type === 'professional',
      attendButton:
        ((selected === AppointmentTypeEnum.VIDEO &&
          hasPermission('video_attendance')) ||
          (selected === AppointmentTypeEnum.PHONE &&
            hasPermission('call_attendance')) ||
          (selected === AppointmentTypeEnum.SMS &&
            hasPermission('message_attendance'))) &&
        user?.type === 'professional',
      scheduleButton:
        hasPermission('register_appointment') && user?.type === 'professional',
    }),
    [hasPermission, isPatientActive, selected, user],
  );

  // Agendar atendimento
  const handleAppointment = useCallback(() => {
    showModal({
      type: 'custom',
      data: (
        <Suspense fallback={false}>
          <CreateEditAppointmentModal
            onFinish={() => syncByProfessional(user?.professional?.id ?? '')}
            patient={patient}
            attendanceType={selected}
          />
        </Suspense>
      ),
    });
  }, [user, patient, selected, syncByProfessional, showModal]);

  // Iniciar atendimento
  const handleAttendance = useCallback(() => {
    let attendanceArea: AppointmentAreaEnum | undefined;

    switch (user?.professional?.occupationArea) {
      case 'Medicina':
        attendanceArea = AppointmentAreaEnum.MEDICINE;
        break;
      case 'Enfermagem':
        attendanceArea = AppointmentAreaEnum.NURSING;
        break;
      default:
        attendanceArea = undefined;
        break;
    }

    const appointmentData = {
      patientName: patient?.user?.name,
      professionalName: user?.name,
      patientPhone:
        selected === AppointmentTypeEnum.PHONE
          ? patient?.user?.phone
          : undefined,
      type: selected,
    };

    switch (selected) {
      case AppointmentTypeEnum.PHONE:
        showModal({
          type: 'custom',
          data: (
            <Suspense fallback={false}>
              <RunAppointmentNewTabModal
                appointment={appointmentData}
                onConfirm={async () => {
                  await createAttendance({
                    patientId: patient?.id ?? '',
                    professionalId: user?.professional_id ?? '',
                    attendanceType: AppointmentTypeEnum.PHONE,
                    attendanceArea,
                    contactPhone: patient?.user?.phone,
                  });
                }}
              />
            </Suspense>
          ),
        });
        break;
      case AppointmentTypeEnum.VIDEO:
        showModal({
          type: 'custom',
          data: (
            <Suspense fallback={false}>
              <RunAppointmentNewTabModal
                appointment={appointmentData}
                onConfirm={async () => {
                  await createAttendance({
                    professionalId: user?.professional_id ?? '',
                    patientId: patient?.id ?? '',
                    attendanceType: AppointmentTypeEnum.VIDEO,
                    attendanceArea,
                  });
                }}
              />
            </Suspense>
          ),
        });
        break;
      case AppointmentTypeEnum.SMS:
        showModal({
          type: 'custom',
          data: (
            <Suspense fallback={false}>
              <NewMessageScheduleModal type="start_now" />
            </Suspense>
          ),
        });
        break;
      default:
        toast.info('Selecione uma opção...');
        break;
    }
  }, [user, patient, selected, createAttendance, showModal]);

  return (
    <ContainerScreensDefault>
      <CardHeader>
        <h2>Novo atendimento</h2>
        {buttonClose && (
          <button type="button" onClick={() => change('AfterScheduling')}>
            <MdClose size={20} />
          </button>
        )}
      </CardHeader>
      <Content>
        {hasAppointment ? (
          <span>Selecione o tipo de atendimento:</span>
        ) : (
          <>
            <h4>Sem Agendamento</h4>
            <span>Inicie um novo atendimento:</span>
          </>
        )}
        <ButtonGroup>
          <ButtonSelect
            disabled={!canShow.videoButton}
            selected={selected === AppointmentTypeEnum.VIDEO && isPatientActive}
            onClick={() =>
              setSelected(
                selected === AppointmentTypeEnum.VIDEO
                  ? undefined
                  : AppointmentTypeEnum.VIDEO,
              )
            }
          >
            <div>
              {selected === AppointmentTypeEnum.VIDEO && isPatientActive ? (
                <img src={FilmIconWhite} alt="Ícone de Vídeo" />
              ) : (
                <img src={FilmIcon} alt="Ícone de Vídeo" />
              )}
            </div>
            Vídeo
          </ButtonSelect>
          <ButtonSelect
            disabled={!canShow.callButton}
            selected={selected === AppointmentTypeEnum.PHONE && isPatientActive}
            onClick={() =>
              setSelected(
                selected === AppointmentTypeEnum.PHONE
                  ? undefined
                  : AppointmentTypeEnum.PHONE,
              )
            }
          >
            <div>
              {selected === AppointmentTypeEnum.PHONE && isPatientActive ? (
                <img src={PhoneIconWhite} alt="Ícone de Ligação" />
              ) : (
                <img src={PhoneIcon} alt="Ícone de Ligação" />
              )}
            </div>
            Ligação
          </ButtonSelect>
          <ButtonSelect
            disabled={!canShow.messageButton}
            selected={selected === AppointmentTypeEnum.SMS && isPatientActive}
            onClick={() =>
              setSelected(
                selected === AppointmentTypeEnum.SMS
                  ? undefined
                  : AppointmentTypeEnum.SMS,
              )
            }
          >
            <div>
              {selected === AppointmentTypeEnum.SMS && isPatientActive ? (
                <img src={MessageIconWhite} alt="Ícone de Mensagem" />
              ) : (
                <img src={MessageIcon} alt="Ícone de Mensagem" />
              )}
            </div>
            Mensagem
          </ButtonSelect>
        </ButtonGroup>
      </Content>
      <CardFooterDefault>
        {showAttendanceNow && (
          <ButtonFooterDefault
            onClick={handleAttendance}
            type="button"
            color="light"
            disabled={!canShow.attendButton || !isPatientActive}
          >
            Atender agora
          </ButtonFooterDefault>
        )}
        <ButtonFooterDefault
          onClick={handleAppointment}
          type="button"
          color="grey"
          disabled={!canShow.scheduleButton || !isPatientActive || !selected}
        >
          Agendar <BiCalendarAlt size={14} />
        </ButtonFooterDefault>
      </CardFooterDefault>
    </ContainerScreensDefault>
  );
};

export default Main;
