import styled from 'styled-components';
import ButtonBase from '../../../../../../components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex: 1;
  width: 100%;

  > header {
    width: 100%;
  }

  > section {
    display: flex;
    margin-top: 35px;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    max-height: 40vh;
    overflow-y: scroll;

    > div {
      flex: 1;

      > h1 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #707683;
        margin-bottom: 15px;
      }
    }
  }

  > footer {
    display: flex;
    max-width: 350px;
    width: 100%;
    margin-top: 50px;

    > button {
      border-radius: 100px;
      border: 1px solid #2d9cdb;
      padding: 10px 15px;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      flex: 1;
    }

    > button + button {
      margin-left: 19px;
    }

    > button:nth-child(1) {
      background: #2d9cdb;
      color: #ffffff;
    }
    > button:nth-child(1):disabled {
      background: transparent;
      border: 1px solid #bdbdbd;
      box-sizing: border-box;
      border-radius: 30px;
      color: #bdbdbd;
      pointer-events: none;
    }

    > button:nth-child(2) {
      background: transparent;
      color: #2d9cdb;
    }
  }
`;

export const Button = styled(ButtonBase)`
  border-radius: 100px;
  border: 1px solid #2d9cdb;
  padding: 10px 15px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  flex: 1;

  + button {
    margin-left: 19px;
  }

  &:nth-child(1) {
    background: #2d9cdb;
    color: #ffffff;
  }

  &:nth-child(1):disabled {
    background: transparent;
    border: 1px solid #bdbdbd;
    box-sizing: border-box;
    border-radius: 30px;
    color: #bdbdbd;
    pointer-events: none;
  }

  &:nth-child(2) {
    background: transparent;
    color: #2d9cdb;
  }
`;

export const AnamneseArea = styled.div``;
