import React, { useState } from 'react';
import { useCurrentEffect } from '../../lib/use-current-effect';

import { getAvatarImage } from '../../services/user';

import AvatarDefault from '../../assets/images/avatar.svg';

interface AvatarProps {
  avatarName?: string;
  avatarSrc?: string;
}

const Avatar: React.FC<AvatarProps> = ({ avatarName, avatarSrc }) => {
  const [src, setSrc] = useState(avatarSrc || AvatarDefault);

  useCurrentEffect(
    (isCurrent) => {
      if (avatarName) {
        getAvatarImage(avatarName).then((response) => {
          if (isCurrent()) {
            setSrc(response);
          }
        });
      }
    },
    [avatarName],
  );

  return <img src={src} alt="Foto de avatar" />;
};

export default Avatar;
