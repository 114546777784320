import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import Logo from '../../../assets/images/cincoLogo1.svg';
import ErrorImg from '../../../assets/images/error.svg';
import ButtonWhatsApp from '../../../components/ButtonWhatsApp';

const Error404: React.FC = () => {
  return (
    <Container>
      <header>
        <img src={Logo} alt="Cinco" />
      </header>
      <img src={ErrorImg} alt="Error 404" />
      <strong>Nós não encontramos essa página.</strong>
      <p>
        Mas não se preocupe, você pode voltar para o início ou, se preferir,
        entre em contato com nosso suporte técnico
      </p>
      <ButtonWhatsApp />
      <Link to="/"> Voltar para o início </Link>
    </Container>
  );
};

export default Error404;
