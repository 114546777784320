import React, { useState, useMemo, useCallback } from 'react';
import { useModal } from 'react-brave-modal';
import { useCurrentEffect } from '../../../../lib/use-current-effect';

import List from './List';
import AddEditModal from './modals/AddEditModal';
import SeeAllModal from './modals/SeeAllModal';
import Spinner from '../../../../components/Spinner';
import { CardTitle } from '../../../../components/GridCard/styles';
import { GridCardProps } from '../../../../components/GridCard';
import AddButton from '../../../../components/AddButton';
import CardContentNothingToDisplay from '../../../../components/CardContentNothingToDisplay';
import { ExamProcedure } from '../../../../entities/ExamProcedure';
import { usePatient } from '../../../../hooks/patient';
import { useAuth } from '../../../../hooks/auth';
import { getExamsProceduresByFilter } from '../../../../services/examProcedure';
import OrdenationTypeEnum from '../../../../enums/OrdenationTypeEnum';

import { ReactComponent as CloudFileGrayImage } from '../../../../assets/images/cloud-file-gray.svg';

import {
  Container,
  CardHeader,
  CardContent,
  SpinnerBox,
  SeeAllButton,
} from './styles';
import ExamsProceduresOrderByEnum from '../../../../enums/ExamsProceduresOrderByEnum';

type ExamsProceduresProps = GridCardProps;

const ExamsProcedures: React.FC<ExamsProceduresProps> = ({ gridArea }) => {
  const { showModal } = useModal();
  const { patient } = usePatient();
  const { user } = useAuth();
  const [examsProcedures, setExamsProcedures] = useState<ExamProcedure[]>([]);
  const [loading, setLoading] = useState(true);

  const canCreate = useMemo(() => user?.type === 'professional', [user]);

  useCurrentEffect(
    (isCurrent) => {
      if (isCurrent() && patient?.id) {
        setLoading(true);

        getExamsProceduresByFilter({
          patientId: patient.id,
          orderBy: ExamsProceduresOrderByEnum.DATE,
          ordenationType: OrdenationTypeEnum.DESC,
          limit: 6,
          page: 1,
        })
          .then((response) => {
            if (isCurrent()) {
              setExamsProcedures(response.examsProcedures);
            }
          })
          .finally(() => {
            if (isCurrent()) {
              setLoading(false);
            }
          });
      }
    },
    [patient],
  );

  const handleUpdateList = useCallback((examProcedure: ExamProcedure) => {
    setExamsProcedures((prevValue) => [examProcedure, ...prevValue]);
  }, []);

  const handleRemoveList = useCallback((examProcedure: ExamProcedure) => {
    setExamsProcedures((prevValue) =>
      prevValue.filter(
        (deletedExamProcedure) => deletedExamProcedure.id !== examProcedure.id,
      ),
    );
  }, []);

  const handleNewExamProcedure = useCallback(() => {
    showModal({
      type: 'custom',
      data: (
        <AddEditModal
          handleRemoveList={handleRemoveList}
          handleUpdateList={handleUpdateList}
        />
      ),
    });
  }, [showModal, handleUpdateList, handleRemoveList]);

  const handleSeeAll = useCallback(() => {
    showModal({
      type: 'custom',
      data: (
        <SeeAllModal
          handleRemoveList={handleRemoveList}
          handleUpdateList={handleUpdateList}
        />
      ),
    });
  }, [showModal, handleUpdateList, handleRemoveList]);

  return (
    <Container gridArea={gridArea}>
      <CardHeader>
        <CardTitle>Exames e Procedimentos</CardTitle>
        <AddButton disabled={!canCreate} onClick={handleNewExamProcedure}>
          Novo
        </AddButton>
      </CardHeader>

      <CardContent>
        {loading ? (
          <SpinnerBox>
            <Spinner />
          </SpinnerBox>
        ) : (
          <>
            {examsProcedures.length ? (
              <>
                <List
                  examsProcedures={examsProcedures}
                  handleUpdateList={handleUpdateList}
                  handleRemoveList={handleRemoveList}
                />
                {examsProcedures.length >= 6 && (
                  <SeeAllButton onClick={handleSeeAll}>Ver todos</SeeAllButton>
                )}
              </>
            ) : (
              <CardContentNothingToDisplay imageWidth="140px">
                <CloudFileGrayImage />
                Sem informações cadastradas
              </CardContentNothingToDisplay>
            )}
          </>
        )}
      </CardContent>
    </Container>
  );
};

export default ExamsProcedures;
