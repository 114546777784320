import { OccupationArea } from '../entities/Professional';

interface GetOccupationAreaProps {
  value: OccupationArea;
  label: OccupationArea;
}

const getOccupationArea: GetOccupationAreaProps[] = [
  {
    value: 'Enfermagem',
    label: 'Enfermagem',
  },
  {
    value: 'Medicina',
    label: 'Medicina',
  },
];

export default getOccupationArea;
