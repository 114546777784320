import React, { useState } from 'react';
import { useModal } from 'react-brave-modal';
import { toast } from 'react-toastify';

import Button from '../../../../components/Button';

import { usePatient } from '../../../../hooks/patient';
import { useAuth } from '../../../../hooks/auth';
import { useAppointment } from '../../../../hooks/appointment';

import { ReactComponent as ClockIcon } from '../../../../assets/images/icon_schedule_time_gray2.svg';
import { ReactComponent as StethoscopeIcon } from '../../../../assets/images/stethoscope-two.svg';
import CloudPhonecallManImage from '../../../../assets/images/cloud-phonecall-man.png';
import VideoFilesImage from '../../../../assets/images/video-files.png';

import { SmsDataType } from '../..';
import { ButtonsContainer } from '../../styles';
import { Container, Content, ContentDetails, ReviewCard, Row } from './styles';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';
import AppointmentAreaEnum from '../../../../enums/AppointmentAreaEnum';
import OccupationAreaEnum from '../../../../enums/OccupationAreaEnum';

interface ReviewAttendanceProps {
  attendanceType: AppointmentTypeEnum;
  smsData?: SmsDataType;
  back(): void;
  finish(): void;
}

const ReviewAttendance: React.FC<ReviewAttendanceProps> = ({
  attendanceType,
  smsData,
  back,
  finish,
}) => {
  const { patient } = usePatient();
  const { user } = useAuth();
  const { closeModal } = useModal();
  const { createAttendance } = useAppointment();

  const [isLoading, setIsLoading] = useState(false);

  const handleInitAttendance = async () => {
    setIsLoading(true);

    const attendanceArea =
      user?.professional?.occupationArea === OccupationAreaEnum.MEDICINE
        ? AppointmentAreaEnum.MEDICINE
        : AppointmentAreaEnum.NURSING;

    try {
      if (attendanceType === AppointmentTypeEnum.SMS) {
        await createAttendance({
          professionalId: user?.professional_id ?? '',
          patientId: patient?.id ?? '',
          attendanceType,
          attendanceArea,
          smsText: smsData?.message,
          contactPhone: smsData?.phone,
        });
      } else {
        await createAttendance({
          patientId: patient?.id ?? '',
          professionalId: user?.professional_id ?? '',
          attendanceType,
          attendanceArea,
          contactPhone: patient?.user?.phone,
        });
      }

      finish();
    } catch (error) {
      setIsLoading(false);

      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Entre em contato com o administrador.');
      }
    }
  };

  return (
    <>
      <Container>
        <Content>
          <h1>Iniciar Atendimento</h1>
          <p>{`Deseja iniciar um atendimento por ${attendanceType} imediatamente?`}</p>

          <ReviewCard>
            <Row>
              <ClockIcon />
              <strong>Atendimento imediato (sem agendamento)</strong>
            </Row>

            <Row>
              <StethoscopeIcon />
              <strong>Profissional: {user?.name}</strong>
            </Row>
          </ReviewCard>
        </Content>

        <ContentDetails>
          {attendanceType === AppointmentTypeEnum.PHONE ? (
            <img src={CloudPhonecallManImage} alt="" />
          ) : attendanceType === AppointmentTypeEnum.VIDEO ? (
            <img src={VideoFilesImage} alt="" />
          ) : (
            <>
              <span>Telefone</span>

              <div>
                <p>{smsData?.phone}</p>
              </div>

              <span>Mensagem (até 150 caracteres)</span>

              <div>
                <p>{smsData?.message}</p>
              </div>
            </>
          )}
        </ContentDetails>
      </Container>

      <ButtonsContainer>
        <Button
          buttonType="secondary"
          onClick={() => closeModal()}
          disabled={isLoading}
        >
          Cancelar
        </Button>

        <Button
          buttonType="primary-alternative"
          onClick={() => back()}
          disabled={isLoading}
        >
          Voltar
        </Button>

        <Button
          buttonType="primary"
          onClick={handleInitAttendance}
          loading={isLoading}
        >
          {attendanceType === AppointmentTypeEnum.SMS ? 'Enviar' : 'Iniciar'}
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default ReviewAttendance;
