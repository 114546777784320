enum CallStatusEnum {
  PHONE_NOT_EXIST = 'phone_not_exist',
  DID_NOT_ANSWER = 'did_not_answer',
  UNCOMPLETED_CALL = 'uncompleted_call',
  MESSAGE_BOX = 'message_box',
  AGREED_PARTICIPATE = 'agreed_participate',
  ACTIVITY_PERFORMED = 'activity_performed',
  HOSPITALIZED_PATIENT = 'hospitalized_patient',
  REFUSED_ANSWER = 'refused_answer',
  IS_NOT_HOME = 'is_not_home',
  PHONE_NOT_BELONG_PATIENT = 'phone_not_belong_patient',
  CONNECTION_PROBLEM = 'connection_problem',
}

export default CallStatusEnum;
