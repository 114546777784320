import React, { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useModal } from 'react-brave-modal';

import { ModalProps, BulkList, HandleBulkList } from '../..';
import { useAppointment } from '../../../../hooks/appointment';

import PhoneCheckImage from '../../../../assets/images/call-ok-banner.svg';
import WomanLaptopOk from '../../../../assets/images/woman-laptop-ok.png';

import { Button, ButtonsContainer, ModalElement, ModalGrouper } from './styles';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';

interface FinishAppointmentProps extends ModalProps {
  bulkList: BulkList[];
  isBulkFilter: boolean;
  handleBulkList?: (data: HandleBulkList) => void;
}

const FinishAppointment: React.FC<FinishAppointmentProps> = ({
  bulkList,
  isBulkFilter,
  handleBulkList,
  goTo,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { closeModal } = useModal();
  const { appointmentSelected: appointment, cleanLocal } = useAppointment();

  const handleResetStepper = useCallback(() => {
    cleanLocal();

    if (!isBulkFilter && handleBulkList) {
      handleBulkList({});
    }

    if (goTo) {
      goTo('AppointmentType');
    }
  }, [isBulkFilter, cleanLocal, handleBulkList, goTo]);

  const handleFinish = useCallback(() => {
    closeModal();

    if (location.pathname !== '/appointments/list') {
      history.push('/appointments/list');
    }
  }, [closeModal, location, history]);

  return (
    <ModalElement appointmentType={appointment?.type as AppointmentTypeEnum}>
      <div>
        <img
          src={
            appointment?.type === AppointmentTypeEnum.PHONE
              ? PhoneCheckImage
              : WomanLaptopOk
          }
          alt="Ícone de preenchimento de formulário"
        />
      </div>

      <ModalGrouper>
        <h1>Agendamento Concluído!</h1>
        <p>
          {bulkList.length > 1 ? 'Os pacientes' : 'O paciente'} e o profissional
          já foram notificados da data e hora da consulta, e{' '}
          {bulkList.length > 1 ? 'os pacientes irão' : 'o paciente irá'} receber
          o Termo de Livre Consentimento automaticamente.
        </p>
        <p>O que deseja fazer agora?</p>

        <ButtonsContainer>
          <Button color="primary" onClick={handleFinish}>
            Voltar para a agenda
          </Button>

          <Button lite color="white" onClick={handleResetStepper}>
            Novo agendamento
          </Button>
        </ButtonsContainer>
      </ModalGrouper>
    </ModalElement>
  );
};

export default FinishAppointment;
