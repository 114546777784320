import React, { useCallback, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { ReactComponent as SearchIcon } from '../../../../assets/images/search.svg';
import { Container, GridContent, Button, Field } from './styles';

import InputWithoutTitle from '../../../../components/InputWithoutTitle';

import { AppointmentDataProps } from '..';
import { AppointmentFiltersDTO } from '../../../../dtos/AppointmentFiltersDTO';
import ButtonFilterRangeDate, {
  DateValueProps,
} from '../../../../components/ButtonFilterRangeDate';

export interface AppointmentDataRef {
  getValues(): AppointmentDataProps;
}

interface FilterProps {
  formRef: React.RefObject<FormHandles>;
  setFilters: React.Dispatch<React.SetStateAction<AppointmentFiltersDTO>>;
  onChangePage: (newPage: number) => any;
}

const Filter: React.FC<FilterProps> = ({
  formRef,
  setFilters,
  onChangePage,
}) => {
  const [datesSelected, setDatesSelected] = useState<Partial<DateValueProps>>(
    {},
  );

  const handleUpdateDate = useCallback(
    ({ startDate, endDate }: DateValueProps) => {
      if (startDate && endDate) {
        setDatesSelected({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        });
      }

      if (!startDate && !endDate) {
        setDatesSelected({});
      }
    },
    [],
  );

  const getValues = useCallback(() => {
    onChangePage(1);

    const newFilters: AppointmentFiltersDTO = { ...formRef.current?.getData() };

    setFilters((state) => ({
      ...state,
      ...newFilters,
      startDate: datesSelected.startDate,
      endDate: datesSelected.endDate,
    }));
  }, [onChangePage, formRef, setFilters, datesSelected]);

  return (
    <Container>
      <h1>Filtrar Agendamentos</h1>
      <Form ref={formRef} onSubmit={getValues}>
        <GridContent>
          <Field gridArea="na">
            <label>Nome do Paciente</label>
            <InputWithoutTitle name="patientName" />
          </Field>
          <Field gridArea="np">
            <label>Nome do Profissional</label>
            <InputWithoutTitle name="professionalName" />
          </Field>
          <Field gridArea="pr">
            <label>Período</label>
            <ButtonFilterRangeDate
              onChange={handleUpdateDate}
              dateValue={datesSelected}
              canClearDates
            />
          </Field>
          <Field isButon gridArea="bt">
            <Button>
              <SearchIcon /> Buscar
            </Button>
          </Field>
        </GridContent>
        {/*         <button type="button">
          Busca avançada <MdKeyboardArrowDown />
        </button> */}
      </Form>
    </Container>
  );
};

export default Filter;
