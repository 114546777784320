import styled, { css } from 'styled-components';

interface ButtonProps {
  lite?: boolean;
  isHidden?: boolean;
  color: 'white' | 'primary';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  margin-bottom: 20px;
  margin-top: 21px;
  padding-left: 25px;
  padding-top: 30px;
  min-height: 360px;
  width: 100%;

  > h1 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 50px;
  margin-top: 30px;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #30e3e5 0%, #2d9cdb 100%);
  color: #ffffff;

  &:hover {
    background: linear-gradient(180deg, #2d9cdb 0%, #30e3e5 100%);
  }
  border: 0;
  border-radius: 100px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 16px 48px;
  transition: all 0.6s;

  ${(props) =>
    props.color === 'white' &&
    css`
      background: ${props.theme.colors.white};
      color: ${props.theme.colors.grayAlternative};

      &:hover {
        color: ${props.theme.colors.white};
      }
    `}

  ${(props) =>
    props.color === 'primary' &&
    css`
      color: ${props.theme.colors.white};
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: #f5f6f8;
      border: 1px solid ${props.theme.colors.gray};
      color: ${props.theme.colors.gray};
      cursor: not-allowed;
      transition: none;

      &:hover {
        background: #f5f6f8;
        color: ${props.theme.colors.gray};
      }
    `}

  ${(props) =>
    props.isHidden &&
    css`
      display: none;
    `}

  ${(props) =>
    props.lite &&
    css`
      background: #f5f6f8;
      border: 1px solid ${props.theme.colors.primary};
      color: ${props.theme.colors.primary};
    `}

  & ~ & {
    margin-left: 20px;
    padding: 16px 20px;
  }
`;
