import { toast } from 'react-toastify';
import { api } from '../api';
import { User } from '../../entities/User';
import { FilterPatientDTO } from '../../dtos/FilterPatientDTO';

interface FilterPatientsProps {
  dataFilter?: FilterPatientDTO;
  currentPage?: number;
}

export interface FilterResponseDTO {
  list: User[];
  total: number;
  totalPage: number;
  totalInThisPage: number;
}

export const filterPatients = async ({
  dataFilter = {},
  currentPage = 1,
}: FilterPatientsProps): Promise<FilterResponseDTO> => {
  try {
    const { data } = await api.get('/patients/filter', {
      params: { ...dataFilter, currentPage },
    });

    return data;
  } catch (error) {
    toast.error('Aconteceu um problema. Entre em contato com o administrador.');

    return {
      list: [],
      total: 0,
      totalPage: 0,
      totalInThisPage: 0,
    };
  }
};
