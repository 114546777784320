import styled from 'styled-components';

export const ModalElement = styled.div`
  display: flex;

  width: 831px;
  height: 463px;

  @media only screen and (orientation: portrait) and (max-width: 550px) {
    width: 80vw;
    height: 70vh;
    flex-direction: column;
  }

  @media only screen and (orientation: portrait) and (max-width: 850px) {
    width: 80vw;
    height: 70vh;
    flex-direction: column;

    margin: unset;
  }

  @media only screen and (orientation: landscape) and (max-width: 950px) {
    width: 80vw;
    height: 80vh;
  }
`;

export const ModalGrouper = styled.div`
  display: flex;

  flex: 1;
`;

export const ModalGrouperImage = styled(ModalGrouper)`
  margin-left: 42px;
  margin-right: 50px;

  > img {
    width: 100%;
  }

  @media only screen and (orientation: portrait) and (max-width: 550px) {
    > img {
      width: 50%;
    }
  }

  @media only screen and (orientation: portrait) and (max-width: 850px) {
    padding: 5%;

    margin: unset;

    justify-content: center;

    > img {
      width: 50%;
      flex: 0.75;
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 950px) {
    padding: 5%;

    > img {
      margin: auto;
      max-width: 25%;
      margin-left: 10%;
    }
  }
`;

export const ModalGrouperText = styled(ModalGrouper)`
  flex-direction: column;

  justify-content: center;
  align-items: center;

  min-height: 300px;

  font-size: 3vmin;

  > * {
    max-width: 330px;
  }

  > div:first-child {
    flex: 1;

    display: flex;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;

      color: ${({ theme }) => theme.colors.primary};

      + div {
        margin-top: 50px;
      }
    }

    p {
      width: 320px;

      padding: 0;
      margin-top: 10px;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      color: ${({ theme }) => theme.colors.graySecondary};
    }
  }

  @media only screen and (orientation: portrait) and (max-width: 850px) {
    min-height: unset;
    flex: 1.25;

    > * {
      max-width: unset;
    }

    > div:first-child {
      margin: auto;
      align-items: center;

      > h1 {
        text-align: center;
        font-size: 1.8em;
        max-width: 80%;
      }

      > p {
        font-size: 1em;
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 950px) {
    display: flex;
    min-height: unset;

    > * {
      max-width: unset;
    }

    > div:first-child {
      padding: 5%;
      align-items: center;

      > h1 {
        text-align: center;
        font-size: 1.6em;
        max-width: 80%;

        + div {
          justify-content: center;
          margin-top: 15%;
        }
      }

      > p {
        font-size: 1em;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  > button {
    padding: 16px 30px;
  }

  @media only screen and (max-width: 850px) {
    font-size: inherit;

    > button {
      padding: 1.2em 2em;
      min-width: unset;
      font-size: 1em;
    }
  }
`;
