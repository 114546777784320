import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${animation} 2s linear infinite;

  > svg {
    width: 1em;
    height: 1em;
  }
`;
