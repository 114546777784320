/* eslint-disable import/no-duplicates */
import React, { useMemo, useState } from 'react';
import { useModal } from 'react-brave-modal';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Button from '../../../../components/Button';
import { Container, ReviewCard, Row } from './styles';

import PhoneImage from '../../../../assets/images/phone.svg';
import VideoImage from '../../../../assets/images/group-video-example.svg';
import { ReactComponent as DatePickerIconSVG } from '../../../../assets/images/date-picker.svg';
import { ReactComponent as ClockIconSVG } from '../../../../assets/images/icon_schedule_time_gray2.svg';
import { ReactComponent as PersonIconSVG } from '../../../../assets/images/icon_schedule_person_gray2.svg';
import { ReactComponent as PhoneIconSvg } from '../../../../assets/images/icon_schedule_phone_gray2.svg';
import { ReactComponent as StethoscopeIconSVG } from '../../../../assets/images/stethoscope-two.svg';
import IconPhoneGray from '../../../../assets/images/icon_schedule_phone_gray.svg';
import IconVideoGray from '../../../../assets/images/icon_schedule_film_gray.svg';

import { RunAppointmentNewTabModalStepsProps } from '../..';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';

interface ConfirmProps extends RunAppointmentNewTabModalStepsProps {
  next: () => void;
}

type AppointmentImage = {
  [key in AppointmentTypeEnum]?: JSX.Element;
};

type AppointmentIconTitle = {
  [key in AppointmentTypeEnum]?: JSX.Element;
};

const Confirm: React.FC<ConfirmProps> = ({ next, appointment }) => {
  const { closeModal } = useModal();
  const [loading, setLoading] = useState(false);

  const appointmentImage: AppointmentImage = {
    Ligação: <img src={PhoneImage} alt="Imagem de um telefone" />,
    Videochamada: <img src={VideoImage} alt="Imagem de uma chamada de vídeo" />,
  };

  const appointmentIconTitle: AppointmentIconTitle = {
    Ligação: <img src={IconPhoneGray} alt="Ícone de telefone" />,
    Videochamada: <img src={IconVideoGray} alt="Ícone de chamada de vídeo" />,
  };

  const appointmentDate = useMemo(
    () =>
      appointment?.date
        ? format(new Date(appointment?.date), "d 'de' MMMM 'de' yyyy ", {
            locale: ptBR,
          })
        : 'Imediatamente',
    [appointment],
  );

  const appointmentHours = useMemo(() => {
    const scheduleTime = appointment?.date
      ? format(new Date(appointment?.date), 'HH:mm', { locale: ptBR })
      : false;

    if (scheduleTime) {
      const splittedTime = scheduleTime.split(':');
      const hours: string = splittedTime[0].slice(-2);
      const minutes: string = splittedTime[1];

      return `${hours}:${minutes}`;
    }

    return 'Imediatamente';
  }, [appointment]);

  return (
    <Container>
      <header>
        <h1>Iniciar Atendimento</h1>
        {
          appointmentIconTitle[
            appointment.type as keyof typeof appointmentIconTitle
          ]
        }
      </header>
      <p>Pronto para iniciar o atendimento?</p>
      <div>
        <ReviewCard>
          <Row>
            <DatePickerIconSVG />
            <strong>Data: </strong> &nbsp;
            {appointmentDate}
          </Row>
          <Row>
            <ClockIconSVG />
            <strong>Hora: </strong> &nbsp; {appointmentHours}
          </Row>
          <Row>
            <PersonIconSVG />
            <strong>Paciente: </strong> &nbsp; {appointment?.patientName}
          </Row>
          {appointment?.type !== AppointmentTypeEnum.SMS && (
            <Row>
              <StethoscopeIconSVG />
              <strong>Profissional: </strong> &nbsp;
              {appointment?.professionalName}
            </Row>
          )}
          <Row>
            {appointment?.type === AppointmentTypeEnum.PHONE && (
              <>
                <PhoneIconSvg />
                <strong>Telefone: </strong> &nbsp; {appointment?.patientPhone}
              </>
            )}
          </Row>
        </ReviewCard>
        {appointmentImage[appointment.type as keyof typeof appointmentImage]}
      </div>
      <footer>
        <Button
          buttonType="secondary"
          color="black"
          onClick={() => closeModal()}
        >
          Cancelar
        </Button>
        <Button
          buttonType="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);
            next();
          }}
        >
          Iniciar
        </Button>
      </footer>
    </Container>
  );
};

export default Confirm;
