import React, { useMemo } from 'react';
import { IoIosWarning, IoMdEye } from 'react-icons/io';
import { useModal } from 'react-brave-modal';

import { useAppointment } from '../../../../hooks/appointment';
import { usePermission } from '../../../../hooks/permission';
import { useAuth } from '../../../../hooks/auth';
import history from '../../../../services/history';

import Button from '../../../../components/Button';
import AttendanceNowModal from '../../../AttendanceNowModal';

import { ScreensType } from '../..';

import { Container, Options } from './styles';
import AppointmentStatusEnum from '../../../../enums/AppointmentStatusEnum';

interface MainProps {
  updateScreen: React.Dispatch<React.SetStateAction<ScreensType | undefined>>;
  patientId?: string;
}

const Main: React.FC<MainProps> = ({ updateScreen, patientId }) => {
  const { closeModal, showModal } = useModal();
  const { appointmentSelected } = useAppointment();
  const { hasPermission } = usePermission();
  const { user } = useAuth();

  const canShow = useMemo(
    () => ({
      viewProfile: hasPermission('patient_profile'),
      canAttend:
        (hasPermission('video_attendance') ||
          hasPermission('call_attendance') ||
          hasPermission('message_attendance')) &&
        user?.type === 'professional',
    }),
    [hasPermission, user],
  );

  const handleUpdateScreen = (screen: ScreensType) => {
    updateScreen(screen);
  };

  const handleAttendanceNow = () => {
    showModal({
      type: 'custom',
      data: <AttendanceNowModal />,
    });
  };

  const handleViewProfile = () => {
    history.push(`/patient/profile?id=${patientId}`);
    closeModal();
  };

  return (
    <Container>
      <IoIosWarning size={70} />

      <h2>O paciente necessita de atenção</h2>

      <p>
        Um ou mais indicadores de saúde do paciente apresentam alterações, os
        detalhes encontram-se no painel Evolução. Recomendamos iniciar um
        atendimento para que a situação seja avaliada e monitorada.
      </p>

      {patientId && canShow.viewProfile && (
        <button type="button" onClick={handleViewProfile}>
          <IoMdEye size={20} />

          <span>VER PRONTUÁRIO</span>
        </button>
      )}

      <Options>
        <p>O que você deseja fazer agora?</p>

        <div>
          <Button
            buttonType="primary"
            onClick={handleAttendanceNow}
            disabled={
              !canShow.canAttend ||
              (appointmentSelected &&
                (appointmentSelected.status === AppointmentStatusEnum.STARTED ||
                  appointmentSelected.status ===
                    AppointmentStatusEnum.UNFINISHED))
            }
          >
            Iniciar um atendimento
          </Button>

          <Button
            buttonType="primary-alternative"
            onClick={() => handleUpdateScreen('updateStatus')}
            disabled={!canShow.canAttend}
          >
            Alterar Status do alerta
          </Button>

          <Button buttonType="secondary" onClick={closeModal}>
            Fechar
          </Button>
        </div>
      </Options>
    </Container>
  );
};

export default Main;
