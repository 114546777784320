import styled, { css } from 'styled-components';

import datePickerDark from '../../../../assets/images/date-picker-dark.svg';

import InputSelectAlternative from '../../../../components/InputSelectAlternative';

interface FieldProps {
  isButon?: boolean;
  gridArea: 'na' | 'np' | 'pr' | 'bt';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 180px;

  margin-top: 21px;
  margin-bottom: 20px;
  padding: 30px;

  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;

  > h1 {
    margin: 0;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;

    color: #4f4f4f;
  }

  > form {
    > button {
      margin-top: 18px;

      display: flex;
      align-items: center;

      border: none;
      background: none;

      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;

      letter-spacing: 0.01em;

      color: #2d9cdb;

      > svg {
        margin-left: 6px;
      }
    }
  }

  .datepicker {
    color: ${(props) => props.theme.colors.graySecondary};
  }
`;

export const InputSelect = styled(InputSelectAlternative)`
  > div:first-child {
    border: 1px solid ${(props) => props.theme.colors.gray2};
    border-width: 1px !important;

    &:focus {
      border-width: 1px !important;
    }
  }

  > div .react-select__control {
    border-width: 1px !important;

    &:focus {
      border-width: 1px !important;
    }
  }

  &:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-areas: 'na na na np np np pr pr bt bt';
  grid-column-gap: 45px;
  margin-top: 28px;
  width: 100%;
  height: 100%;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;

  background: ${(props) => props.theme.colors.primary};
  align-self: end;
  justify-self: center;
  border-radius: 100px;
  border: 0;

  padding: 16px 48px;
  color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  /* or 100% */

  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;

  > svg {
    margin-right: 8px;

    > path {
      fill: #fff;
    }
  }
`;

export const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: column;
  grid-area: ${({ gridArea }) => gridArea};

  ${({ isButon }) =>
    isButon &&
    css`
      align-self: end;
    `}

  > label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.01em;

    color: #707683;
    white-space: nowrap;
    > span {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      letter-spacing: 0.01em;

      color: ${({ theme }) => theme.colors.danger};
    }
  }

  > div {
    margin-top: 4px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    max-height: 43px;
  }

  > div {
    margin-top: 4px;

    > input[type='date']::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      background: url(${datePickerDark}) no-repeat;
      background-size: 75%;
      width: 12px;
      height: 13px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    max-height: 43px;
  }
`;
