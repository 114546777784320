import React, { ButtonHTMLAttributes } from 'react';

import SmallSpinner from '../SmallSpinner';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isWhite?: boolean;
  loading?: boolean;
}

const ButtonSecondary: React.FC<ButtonProps> = ({
  children,
  loading,
  ...rest
}) => {
  return (
    <Container loading={loading} type="button" {...rest}>
      {loading ? <SmallSpinner /> : children}
    </Container>
  );
};

export default ButtonSecondary;
