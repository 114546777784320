import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import { Button, ButtonsContainer } from './styles';
import DashboardTemplate from '../../../templates/Dashboard';
import Table from './Table';
import MainAreasEnum from '../../../enums/MainAreasEnum';

const List: React.FC = () => {
  const history = useHistory();

  const pages = useMemo<ElementPageTree[]>(
    () => [
      {
        name: 'Administração',
      },
      {
        name: 'Instituições',
      },
    ],
    [],
  );

  const handleOnClick = useCallback(
    (local) => history.push(`/${local}/register`),
    [history],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <PageTree pages={pages} />
      <ButtonsContainer>
        <Button onClick={() => handleOnClick('institution')} color="primary">
          <HiOutlineOfficeBuilding />
          Nova Instituição
        </Button>
      </ButtonsContainer>
      <Table />
    </DashboardTemplate>
  );
};

export default List;
