import styled from 'styled-components';

export const Container = styled.div`
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    > div:first-child {
      div {
        display: flex;
        flex-direction: column;
        gap: 4px;

        h3 {
          font-size: 20px;
          font-weight: 700;
          color: ${({ theme }) => theme.colors.graySecondary};
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: ${({ theme }) => theme.colors.graySecondary};
        }
      }
    }

    > div:last-child {
      span {
        font-size: 12px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.graySecondary};
      }
    }
  }
`;

export const Content = styled.main`
  margin-top: 40px;
  display: grid;
  grid-template: 165px 325px 300px 400px / 1fr 1fr 2fr;
  grid-template-areas:
    'tp np pxr'
    'pc pc pxr'
    'pl pl pnw'
    'pi pi pi';
  grid-gap: 28px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.xl}) {
    grid-template: 155px 450px 325px 300px 300px 400px/ 1fr 1fr;
    grid-template-areas:
      'tp np'
      'pxr pxr'
      'pc pc'
      'pl pl'
      'pnw pnw'
      'pi pi';
  }
`;

export const CardHeader = styled.header`
  margin: 25px auto 0 45px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    margin: 25px auto 0 24px;
  }
`;

export const CardTitle = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.graySecondary};
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;
