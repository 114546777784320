import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Content, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: any;
  textColor?: string;
  textSize?: string;
  withChangeButton?: boolean;
  mask?: string;
}

const InputTime: React.FC<InputProps> = ({
  name,
  textColor = '#707683',
  textSize = '16px',
  disabled = false,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref: any) => inputRef?.current?.value ?? '',
      setValue: (ref: any, value: string) => {
        if (inputRef?.current) {
          inputRef.current.value = value;
        }
      },
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <Container>
      <Content
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        textColor={textColor}
        textSize={textSize}
        disabled={disabled}
      >
        <input
          type="time"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          disabled={disabled}
          {...rest}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Content>
    </Container>
  );
};

export default InputTime;
