import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, ListBox } from './styles';

export interface ActionMenuListProps {
  icon?: JSX.Element;
  name: string;
  action?: string | (() => any);
  disabled?: boolean;
}

export interface ActionMenuButtonProps {
  list?: ActionMenuListProps[];
  alignBox?: 'left' | 'center' | 'right';
  disabled?: boolean;
  previousLocation?: string;
}

const ActionMenuButton: React.FC<ActionMenuButtonProps> = ({
  children,
  list,
  alignBox = 'center',
  disabled = false,
  previousLocation,
}) => {
  const history = useHistory();
  const [showList, setShowList] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickItem = useCallback(
    (action?: string | (() => any)) => {
      if (action) {
        if (typeof action === 'string') {
          history.push(action, { from: previousLocation });
        } else {
          action();
        }
      }
    },
    [history, previousLocation],
  );

  const handleClick = useCallback(() => {
    setShowList(true);
  }, []);

  const handleOnClickListener = useCallback((event: MouseEvent) => {
    const withinBoundaries = event
      .composedPath()
      .includes(containerRef.current as Element);

    if (!withinBoundaries) {
      setShowList(false);
    }
  }, []);

  useEffect(() => {
    if (containerRef) {
      document.addEventListener('click', handleOnClickListener);
    }

    return () => {
      document.removeEventListener('click', handleOnClickListener);
    };
  }, [handleOnClickListener]);

  return (
    <Container align={alignBox} ref={containerRef} disabled={disabled}>
      <button type="button" onClick={handleClick} disabled={disabled}>
        {children}
      </button>

      {list && list.length > 0 && (
        <ListBox show={showList}>
          {list?.map((item) => (
            <li key={item.name}>
              <button
                type="button"
                onClick={() => handleClickItem(item.action)}
                disabled={item.disabled ?? false}
              >
                <div>{item.icon}</div>
                <span>{item.name}</span>
              </button>
            </li>
          ))}
        </ListBox>
      )}
    </Container>
  );
};

export default ActionMenuButton;
