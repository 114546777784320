import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  min-height: 100vh;

  font-size: 18px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.graySecondary};

  > header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: linear-gradient(141.94deg, #30e3e5 4.43%, #2d9cdb 107.87%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    height: 97px;
    width: 100vw;
    margin-bottom: 40px;
  }

  > strong {
    font-weight: 600;
    margin-top: 25px;
  }

  > p {
    font-weight: 500;
    margin-top: 30px;
    font-family: 'Raleway', sans-serif;
  }
  > a {
    text-decoration: none;
    background: #2d9cdb;
    border-radius: 50px;
    border: 0;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: ${(props) => props.theme.colors.white};
    padding: 16px 28px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const ButtonRedesError = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 50px;
  font-weight: 500;
  line-height: 14px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #4f4f4f;
  padding: 10px 20px;
  margin-top: 40px;

  > svg {
    margin-right: 7px;
  }
`;

export const ButtonBackError = styled.button``;
