import styled from 'styled-components';

interface DetailsSAreaProps {
  color?: string;
}

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  margin-bottom: 20px;

  p {
    color: #707683;
    font-size: 12px;
    font-weight: 600;
    margin-top: 20px;
  }
`;

export const CardTypeTitle = styled.strong`
  grid-area: tl;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2d9cdb;
  z-index: 1;
`;

export const ChartArea = styled.div`
  height: 250px;
  display: grid;
  grid-template: repeat(8, 1fr) / repeat(8, 1fr);
  grid-template-areas:
    'tl tl tl tl tl tl dt dt'
    'ch ch ch ch ch ch dt dt'
    'ch ch ch ch ch ch dt dt'
    'ch ch ch ch ch ch dt dt'
    'ch ch ch ch ch ch dt dt'
    'ch ch ch ch ch ch dt dt'
    'ch ch ch ch ch ch dt dt'
    'ch ch ch ch ch ch dt dt';
`;

export const DetailsSArea = styled.div<DetailsSAreaProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 250px;
  width: 100%;

  img {
    width: 120px;
    margin-right: 25px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    span {
      font-weight: 500;
      font-size: 18px;
      color: ${({ theme }) => theme.colors.grayAlternative};
    }

    h1 {
      font-weight: bold;
      font-size: 40px;
      color: ${({ color, theme }) => color || theme.colors.primary};
    }
  }
`;
