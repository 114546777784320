import React, { useState, useMemo } from 'react';
import { useModal } from 'react-brave-modal';

import { useAuth } from '../../../../hooks/auth';
import { usePermission } from '../../../../hooks/permission';
import Button from '../../../../components/Button';

import FilmIcon from '../../../../assets/images/icon_schedule_film.svg';
import PhoneIcon from '../../../../assets/images/icon_schedule_phone.svg';
import MessageIcon from '../../../../assets/images/icon_schedule_message.svg';
import FilmIconWhite from '../../../../assets/images/icon_schedule_film_white.svg';
import PhoneIconWhite from '../../../../assets/images/icon_schedule_phone_white.svg';
import MessageIconWhite from '../../../../assets/images/icon_schedule_message_white.svg';
import ScheduleFormImage from '../../../../assets/images/agenda-form.svg';

import { ButtonsContainer } from '../../styles';
import { Container, Grouper, ButtonGroup, ButtonSelect } from './styles';
import AppointmentTypeEnum from '../../../../enums/AppointmentTypeEnum';

interface AttendanceTypeProps {
  next(attendanceSelected: AppointmentTypeEnum): void;
  setAttendanceType: React.Dispatch<
    React.SetStateAction<AppointmentTypeEnum | undefined>
  >;
}

const AttendanceType: React.FC<AttendanceTypeProps> = ({
  setAttendanceType,
  next,
}) => {
  const { closeModal } = useModal();
  const { hasPermission } = usePermission();
  const { user } = useAuth();

  const [
    attendanceSelected,
    setAttendanceSelected,
  ] = useState<AppointmentTypeEnum>();

  const canShow = useMemo(
    () => ({
      videoButton:
        hasPermission('video_attendance') && user?.type === 'professional',
      callButton:
        hasPermission('call_attendance') && user?.type === 'professional',
      messageButton:
        hasPermission('message_attendance') && user?.type === 'professional',
    }),
    [hasPermission, user],
  );

  const handleNext = () => {
    if (attendanceSelected) {
      setAttendanceType(attendanceSelected);
      next(attendanceSelected);
    }
  };

  return (
    <>
      <Container>
        <Grouper>
          <h1>Iniciar atendimento</h1>
          <p>Qual tipo de atendimento você gostaria de começar?</p>

          <ButtonGroup>
            <ButtonSelect
              selected={attendanceSelected === AppointmentTypeEnum.VIDEO}
              disabled={!canShow.videoButton}
              onClick={() =>
                setAttendanceSelected(
                  attendanceSelected === AppointmentTypeEnum.VIDEO
                    ? undefined
                    : AppointmentTypeEnum.VIDEO,
                )
              }
            >
              <div>
                {attendanceSelected === AppointmentTypeEnum.VIDEO ? (
                  <img src={FilmIconWhite} alt="Ícone de Vídeo" />
                ) : (
                  <img src={FilmIcon} alt="Ícone de Vídeo" />
                )}
              </div>
              Vídeo
            </ButtonSelect>

            <ButtonSelect
              disabled={!canShow.callButton}
              selected={attendanceSelected === AppointmentTypeEnum.PHONE}
              onClick={() =>
                setAttendanceSelected(
                  attendanceSelected === AppointmentTypeEnum.PHONE
                    ? undefined
                    : AppointmentTypeEnum.PHONE,
                )
              }
            >
              <div>
                {attendanceSelected === AppointmentTypeEnum.PHONE ? (
                  <img src={PhoneIconWhite} alt="Ícone de Ligação" />
                ) : (
                  <img src={PhoneIcon} alt="Ícone de Ligação" />
                )}
              </div>
              Ligação
            </ButtonSelect>

            <ButtonSelect
              disabled={!canShow.messageButton}
              selected={attendanceSelected === AppointmentTypeEnum.SMS}
              onClick={() =>
                setAttendanceSelected(
                  attendanceSelected === AppointmentTypeEnum.SMS
                    ? undefined
                    : AppointmentTypeEnum.SMS,
                )
              }
            >
              <div>
                {attendanceSelected === AppointmentTypeEnum.SMS ? (
                  <img src={MessageIconWhite} alt="Ícone de Mensagem" />
                ) : (
                  <img src={MessageIcon} alt="Ícone de Mensagem" />
                )}
              </div>
              Mensagem
            </ButtonSelect>
          </ButtonGroup>
        </Grouper>

        <Grouper>
          <img src={ScheduleFormImage} alt="" />
        </Grouper>
      </Container>

      <ButtonsContainer>
        <Button buttonType="secondary" onClick={() => closeModal()}>
          Cancelar
        </Button>

        <Button
          buttonType="primary"
          disabled={!attendanceSelected}
          onClick={handleNext}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default AttendanceType;
