import React, { ReactNode, useContext, createContext, useState } from 'react';
import { colors } from '../styles/theme/colors';

interface Patient {
  idExterno: string;
  nome: string;
  nome_social?: string;
  endereco?: string;
  cidade?: string;
  telefone: string;
  peso?: number;
  altura?: number;
  nome_mae?: string;
  dificuldade_locomocao?: boolean;
}

interface MemedContextData {
  logOut: () => void;
  open: () => void;
  initMemed: (token: string) => void;
  definePacient: (pacient: Patient) => Promise<any>;
  loading: boolean;
}

interface Props {
  children: ReactNode;
}

const MemedContext = createContext<MemedContextData>({} as MemedContextData);

export function MemedProvider({ children }: Props) {
  const [loading, setLoading] = useState(true);
  const logOut = () => {
    try {
      MdHub.server.unbindEvents();

      delete window.MdHub;
      delete window.MdSinapsePrescricao;

      const scripts = Array.from(document.getElementsByTagName('script'));
      if (scripts && scripts.length > 0) {
        scripts.forEach((script) => {
          if (script.src.includes('memed.com.br'))
            script?.parentNode?.removeChild(script);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const initEventsMemed = () => {
    try {
      MdSinapsePrescricao.event.add(
        'core:moduleHide',
        (module: { moduleName: string }) => {},
      );

      MdSinapsePrescricao.event.add(
        'core:moduleInit',
        (module: { name: string }) => {
          if (module.name === 'plataforma.prescricao') {
            MdHub.command.send('plataforma.prescricao', 'setFeatureToggle', {
              deletePatient: false,
              removePatient: false,
              editPatient: false,
              optionsPrescription: false,
            });

            MdHub.event.add(
              'prescricaoImpressa',
              (prescriptionData: any) => {},
            );
            setLoading(false);
          }
        },
      );
    } catch (err) {
      console.error(err);
    }
  };

  const initMemed = (token: string) => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('data-color', colors.primary);
    script.setAttribute('data-token', token);
    script.src = `${process.env.REACT_APP_MEMED_FRONTEND_URL}/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js`;
    script.onload = () => {
      initEventsMemed();
    };
    document.body.appendChild(script);
  };

  const definePacient = (pacient: Patient): Promise<any> => {
    return new Promise((resolve, reject): void => {
      try {
        MdHub.command
          .send('plataforma.prescricao', 'setPaciente', {
            idExterno: pacient?.idExterno,
            nome: pacient?.nome,
            nome_social: pacient?.nome_social,
            endereco: pacient?.endereco,
            cidade: pacient?.cidade,
            telefone: pacient?.telefone,
            peso: pacient?.peso,
            altura: pacient?.altura,
            nome_mae: pacient?.nome_mae,
            dificuldade_locomocao: pacient?.dificuldade_locomocao,
          })
          .then(
            (response: any) => {
              resolve('');
            },
            (error: any) => {
              reject(error);
            },
          );
      } catch (err) {
        console.error(err);
      }
    });
  };

  const open = (module = 'plataforma.prescricao') => {
    MdHub.module.show(module);
  };

  return (
    <MemedContext.Provider
      value={{ logOut, initMemed, definePacient, open, loading }}
    >
      {children}
    </MemedContext.Provider>
  );
}

export const useMemed = (): MemedContextData => {
  const context = useContext(MemedContext);

  if (!context) {
    throw new Error('useAppointment must be used within a AppointmentProvider');
  }

  return context;
};
