import React from 'react';
import { useModal } from 'react-brave-modal';

import { AddEditHealthConditionModalProps } from '..';
import ModalButton from '../../../components/ModalButton';
import Banner from '../../../assets/images/check-ok-2.svg';

import { Container, ButtonGroup } from './styles';

interface CompleteProps extends AddEditHealthConditionModalProps {
  back: () => void;
}

const Complete: React.FC<CompleteProps> = ({ back, action }) => {
  const { closeModal } = useModal();

  return (
    <Container>
      <section>
        <div>
          <img src={Banner} alt="Ok" />
          {action === 'add' && <h1>Condição de saúde salva!</h1>}
          {action === 'edit' && <h1>Condição de saúde atualizada!</h1>}
          <ButtonGroup>
            {action === 'add' && (
              <ModalButton styleType="blue" onClick={() => back()}>
                Adicionar nova
              </ModalButton>
            )}
            <ModalButton styleType="blue-light" onClick={() => closeModal()}>
              Fechar
            </ModalButton>
          </ButtonGroup>
        </div>
      </section>
    </Container>
  );
};

export default Complete;
