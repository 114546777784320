import React, { useEffect } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import Button from '../../../../components/Button';
import { GridCardProps } from '../../../../components/GridCard';
import { useAuth } from '../../../../hooks/auth';
import { useMemed } from '../../../../hooks/memed';
import { usePatient } from '../../../../hooks/patient';
import { Container } from './styles';

type DigitalPrescriptionProps = GridCardProps;

const DigitalPrescriptionCard: React.FC<DigitalPrescriptionProps> = ({
  gridArea,
}) => {
  const { patient } = usePatient();
  const { user } = useAuth();
  const { initMemed, logOut, definePacient, open, loading } = useMemed();

  useEffect(() => {
    if (loading) {
      if (user?.professional?.memedToken)
        initMemed(user?.professional?.memedToken);
    } else {
      definePacient({
        idExterno: patient?.id as string,
        nome: patient?.user?.name as string,
        telefone: patient?.user?.phone as string,
      });
    }
    return () => {
      if (!loading) {
        logOut();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleMemed = () => {
    open();
  };

  return (
    <Container gridArea={gridArea}>
      <h3>Prescrição digital Memed</h3>
      <p>
        Elabore requisições, laudos, prescrições de medicamentos ou atestados
        digitais para este paciente e envie por e-mail.
      </p>
      <Button
        loading={loading}
        buttonType="primary-alternative"
        onClick={handleMemed}
      >
        Portal Memed
        <FiExternalLink />
      </Button>
    </Container>
  );
};

export default DigitalPrescriptionCard;
