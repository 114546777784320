import { api } from '../api';
import { SheetPatient } from '../../entities/SheetPatient';

export interface GetSheetPatientsByFilter {
  status?: string;
  patientName?: string;
  orderBy?: string;
  startDate?: Date;
  endDate?: Date;
  ordenationType?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export interface GetSheetPatientsByFilterResponse {
  sheetName: string;
  sheetPatients: SheetPatient[];
  totalSheetPatients: number;
  totalPages: number;
  totalSheetPatientsOnPage: number;
}

export const getSheetPatientsByFilter = async (
  sheetId: string,
  sheetPatientData: GetSheetPatientsByFilter,
): Promise<GetSheetPatientsByFilterResponse> => {
  const { data } = await api.get<GetSheetPatientsByFilterResponse>(
    `sheets/patients/${sheetId}`,
    {
      params: { ...sheetPatientData },
    },
  );

  const sheetPatientsListData = {
    sheetName: data.sheetName,
    sheetPatients: data.sheetPatients,
    totalSheetPatients: data.totalSheetPatients,
    totalPages: data.totalPages,
    totalSheetPatientsOnPage: data.totalSheetPatientsOnPage,
  };

  return sheetPatientsListData;
};
