import React, { useState } from 'react';
import menuImage from '../../../assets/images/menu.svg';
import { Container, SidebarContainer } from './styles';
import NotificationsList from '../../MenuTop/NotificationsList';
import Sidebar from '../Sidebar';

interface MenuMobileProps {
  selected?: string;
}

const MenuMobile: React.FC<MenuMobileProps> = ({ selected }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return !isSidebarOpen ? (
    <Container>
      <button type="button" onClick={() => setIsSidebarOpen(true)}>
        <img src={menuImage} alt="Menu" />
      </button>

      <div>
        <span>Novas notificações</span>
        <NotificationsList />
      </div>
    </Container>
  ) : (
    <SidebarContainer>
      <Sidebar
        selected={selected}
        closeSidebar={(value) => setIsSidebarOpen(value)}
        isMobile
      />
    </SidebarContainer>
  );
};

export default MenuMobile;
