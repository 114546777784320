import styled from 'styled-components';
import { Container as GridCardContainer } from '../../../../../components/GridCard/styles';

export const Container = styled(GridCardContainer)`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;
  height: 100%;

  > header {
    margin: 25px 45px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      flex-wrap: wrap;
      margin: 24px 24px 0;

      > div:last-child {
        margin: 0 0 0 auto;
      }
    }

    > div:first-child {
      > h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #4f4f4f;
      }
    }
  }

  > footer {
    > h1 {
      margin-left: 45px;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      color: #4f4f4f;
      margin-bottom: 6px;

      @media screen and (max-width: ${({ theme }) =>
          theme.gridBreakpoints.sm}) {
        margin-left: 25px;
      }
    }

    > div {
      display: flex;
      border-top: 1px solid #f2f2f2;

      @media screen and (max-width: ${({ theme }) =>
          theme.gridBreakpoints.sm}) {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 1rem;
      }

      > section {
        flex: 1;
      }

      > section + section {
        border-left: 1px solid #f2f2f2;

        @media screen and (max-width: ${({ theme }) =>
            theme.gridBreakpoints.sm}) {
          border: none;
        }
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  padding: 15px 45px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 16px 25px 32px 25px;
    flex-direction: column-reverse;
  }

  > section:nth-child(1) {
    flex: 1;
    min-width: 120px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      margin-top: 2rem;
    }
  }
  > section:nth-child(2) {
    flex: 2;

    > div {
      @media screen and (max-width: ${({ theme }) =>
          theme.gridBreakpoints.sm}) {
        margin: 0 !important;
      }
    }
  }

  > section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    > h1 {
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      color: #4f4f4f;
    }

    > h2 {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #828282;
      margin: 7px 0 15px;
    }

    > h3 {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #828282;
    }
  }
`;

export const FooterItem = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 0;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 17px 0 17px 25px;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  > div:nth-child(1) {
    background: #f2f2f2;
    padding: 8px;
    border-radius: 50%;
  }

  > div:nth-child(2) {
    margin-left: 7px;

    > h1 {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #828282;
    }

    > h2 {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #4f4f4f;
    }
  }
`;

export const WithoutDataChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  margin: 0 15px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    margin: 0;
  }

  > img {
    height: 100px;
  }

  > span {
    margin-top: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #707683;
  }
`;
