import styled from 'styled-components';
import { Container as GridCard } from '../../../../components/GridCard/styles';

export const Container = styled(GridCard)``;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;
  margin-top: 20px;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0.5;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.5;
`;

export const Pipe = styled.div`
  width: 1px;
  height: 70%;
  background-color: #a4a9b4;
  margin: 0 50px;
`;

export const InstitutionsTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    width: 100px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  strong {
    font-size: 60px;
    font-weight: 700;
    line-height: 70px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  table {
    border-collapse: collapse;
  }

  td,
  th {
    font-size: 14px;

    border-bottom: 1px solid #efefef;

    padding: 13px 35px;
  }

  td {
    font-weight: 500;
  }

  td:first-of-type {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
    text-align: start;
  }

  th {
    font-weight: 600;
  }
`;
