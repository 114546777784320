import styled, { css } from 'styled-components';
import ButtonBase from '../../../../components/Button';

interface ButtonProps {
  isWhite?: boolean;
}

interface ChangeOrderButtonProps {
  isActive: boolean;
  ordenationType: 'ASC' | 'DESC';
}

export const Container = styled.div``;

export const PageSection = styled.section`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  border-radius: 6px;
  min-height: 80vh;
  padding: 30px 25px;
  margin-top: 20px;

  > header {
    > h1 {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #4f4f4f;
    }
  }
`;

export const PageSectionHeader = styled.section`
  display: flex;
  margin-top: 40px;

  > div:first-child {
    display: flex;
    flex-direction: column;

    > h1 {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #707683;
    }

    > h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #4f4f4f;
      margin-top: 8px;
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-left: 40px;

    > button + button {
      margin-left: 30px;
    }
  }
`;

export const Button = styled(ButtonBase)<ButtonProps>`
  background: transparent;
  border: 0;
  background: linear-gradient(180deg, #30e3e5 0%, #2d9cdb 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;

  &:hover {
    background: #2d9cdb;
  }

  &:disabled {
    opacity: 0.7;
    background: linear-gradient(180deg, #30e3e5 0%, #2d9cdb 100%);
  }

  ${({ isWhite }) =>
    isWhite &&
    css`
      background: #ffffff;
      color: #828282;

      &:hover {
        background: whitesmoke;
      }

      &:disabled {
        opacity: 0.7;
        background: #ffffff;
      }
    `}
`;

export const ChangeOrderButton = styled.button<ChangeOrderButtonProps>`
  background: 0;
  border: 0;
  display: flex;
  margin-left: 10px;

  > svg {
    ${({ ordenationType }) => {
      if (ordenationType === 'ASC') {
        return css`
          > path {
            stroke: #1a7fb8;
          }
        `;
      }

      return css`
        > g > path {
          stroke: #1a7fb8;
        }
      `;
    }}

    ${({ isActive }) =>
      !isActive &&
      css`
        > path {
          stroke: #707683;
        }

        > g > path {
          stroke: #707683;
        }
      `}
  }
`;
