import React, { createContext, useContext, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import { useCurrentEffect } from '../lib/use-current-effect';
import {
  getAvatarImage as getUserAvatarImage,
  getUser,
} from '../services/user';
import { User } from '../entities/User';
import { CreateSessionDTO } from '../dtos/CreateSessionDTO';
import { createSession } from '../services/user/CreateSession';
import { confirmPassword } from '../services/user/ConfirmPassword';

type Credentials = {
  user: User | undefined;
  token: string | undefined;
};

interface AuthContextData {
  user?: User;
  loading: boolean;
  sync(userData?: User): Promise<void>;
  confirmatePassword: (password: string) => Promise<boolean>;
  passwordAttempts: number;
  passwordAttemptsFailed: boolean;
  resetPasswordAttempts: () => void;
  signIn(data: CreateSessionDTO): Promise<Credentials>;
  signOut(options?: { failedAttempt?: boolean }): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [userAuth, setUserAuth] = useState<User>();
  const [attempts, setAttempts] = useState(0);
  const [passwordAttemptsFailed, setPasswordAttemptsFailed] = useState(
    !!localStorage.getItem('@Cinco:password_attempts_failed'),
  );
  const [loading, setLoading] = useState(true);

  const getAvatarImage = useCallback(
    async (avatar: string) =>
      !avatar.includes('data:image') ? getUserAvatarImage(avatar) : avatar,
    [],
  );

  const sync = useCallback(
    async (userData?: User) => {
      let newUser = { ...userAuth, ...userData };

      if (!userData) {
        newUser = await getUser();
      }

      if (newUser && newUser.avatar) {
        newUser.avatar = await getAvatarImage(newUser.avatar);
      }

      setUserAuth({ ...newUser });
    },
    [getAvatarImage, userAuth],
  );

  const confirmatePassword = useCallback(
    async (password: string) => {
      const didMatch = await confirmPassword(password);

      if (didMatch) {
        setAttempts(0);

        localStorage.setItem('@Cinco:password_attempts', `${0}`);
      } else {
        setAttempts((state) => state + 1);

        localStorage.setItem('@Cinco:password_attempts', `${attempts + 1}`);
      }

      return didMatch;
    },
    [attempts],
  );

  const resetPasswordConfirmation = useCallback(() => {
    setAttempts(0);

    localStorage.removeItem('@Cinco:password_attempts');

    setPasswordAttemptsFailed(false);

    localStorage.removeItem('@Cinco:password_attempts_failed');
  }, []);

  const syncUser = useCallback(async () => {
    const data = await getUser();

    if (data && data.avatar) {
      data.avatar = await getAvatarImage(data.avatar);
    }

    setUserAuth(data);
  }, [getAvatarImage]);

  useCurrentEffect(() => {
    const token = localStorage.getItem('@Cinco:token');
    const previousAttempts =
      localStorage.getItem('@Cinco:password_attempts') &&
      parseInt(localStorage.getItem('@Cinco:password_attempts') as string, 10);

    previousAttempts &&
      !Number.isNaN(previousAttempts) &&
      setAttempts(previousAttempts);

    if (token) {
      syncUser().finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(
    async (data: CreateSessionDTO): Promise<Credentials> => {
      try {
        const newSession = await createSession({
          ...data,
        });
        const { token, user } = newSession;

        setUserAuth(user);
        localStorage.setItem('@Cinco:token', token);
        setLoading(false);

        return { user, token };
      } catch (err) {
        if (err.response) {
          if (err.response.status !== 409) {
            toast.error(err.response.data.message);
          }
        } else {
          toast.error(
            'Aconteceu um problema. Entre em contato com o administrador.',
          );
        }

        return { user: undefined, token: undefined };
      }
    },
    [],
  );

  const signOut = useCallback(
    (options?: { failedAttempt?: boolean }) => {
      localStorage.removeItem('@Cinco:token');

      options?.failedAttempt
        ? localStorage.setItem('@Cinco:password_attempts_failed', '1')
        : resetPasswordConfirmation();

      window.location.replace(
        `${window.location.protocol}//${window.location.host}/login`,
      );
    },
    [resetPasswordConfirmation],
  );

  return (
    <AuthContext.Provider
      value={{
        user: userAuth,
        loading,
        sync,
        signIn,
        signOut,
        confirmatePassword,
        passwordAttempts: attempts,
        passwordAttemptsFailed,
        resetPasswordAttempts: resetPasswordConfirmation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
