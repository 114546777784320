import { PatientByRiskDegree } from '../../pages/Reports/Patients/PatientsRiskDegree';
import { api } from '../api';

export interface PatientReport {
  totalPatients: number;
  newPatientsCurrentMonth: number;
  patientsByComorbities: {
    id: string;
    name: string;
    patients: number;
    color: string;
  }[];
  totalPatientsByComorbities: number;
  patientsByGender: {
    gender: 'M' | 'F' | 'ND';
    percentage: number;
  }[];
  ageGroupSixtyToSeventy: number;
  ageGroupOverSeventy: number;
  patientsByLocation: {
    uf: string;
    percentage: number;
  }[];
  patientsByInstitution: {
    id: string;
    name: string;
    patients: number;
    percentage: string;
  }[];
  patientsByImc: {
    overweight: number;
    healthy: number;
    unknown: number;
  }[];
  patientsByRiskDegree: PatientByRiskDegree[];
  totalPatientsByRiskDegree: number;
  totalInstitutions: number;
}

export const getPatientsReport = async (): Promise<PatientReport> => {
  const { data } = await api.get<PatientReport>(`/patients/report`);

  return data;
};
