import React, { ButtonHTMLAttributes } from 'react';
import { BsPlus } from 'react-icons/bs';

import { Button } from './styles';

interface AddButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
}

const AddButton: React.FC<AddButtonProps> = ({
  children,
  disabled,
  ...rest
}) => {
  return (
    <Button disabled={disabled} {...rest}>
      <BsPlus size={14} /> {children}
    </Button>
  );
};

export default AddButton;
