import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import Filter from './Filter';
import Table from './Table';
import DashboardTemplate from '../../../templates/Dashboard';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import Spinner from '../../../components/Spinner';
import {
  getSheetPatientsByFilter,
  GetSheetPatientsByFilter as ListFilter,
  GetSheetPatientsByFilterResponse as ListData,
} from '../../../services/sheet';

import useQuery from '../../../hooks/query';
import Pagination from '../../../components/Pagination';
import { useCurrentEffect } from '../../../lib/use-current-effect';
import MainAreasEnum from '../../../enums/MainAreasEnum';

const PendenciesList: React.FC = () => {
  const history = useHistory();
  const [totalSheetPatients, setTotalSheetPatients] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [listFilter, setListFilter] = useState<ListFilter>({
    orderBy: 'patientName',
    ordenationType: 'ASC',
    limit: 10,
  });
  const [listData, setListData] = useState<ListData>({
    sheetName: '',
    sheetPatients: [],
    totalSheetPatients: 0,
    totalPages: 0,
    totalSheetPatientsOnPage: 0,
  });

  const pages = useMemo<ElementPageTree[]>(
    () => [
      { name: 'Administração' },
      { name: 'Fichas de saúde', link: '/sheet/list' },
      { name: listData.sheetName },
      { name: 'Pendências' },
    ],
    [listData],
  );

  const query = useQuery();
  const sheetId = useMemo(() => query.get('id') ?? '', [query]);

  useCurrentEffect(
    (isCurrent) => {
      getSheetPatientsByFilter(sheetId, { ...listFilter, page: currentPage })
        .then((listDataResponse) => {
          if (isFirstLoad && isCurrent()) {
            setTotalSheetPatients(listDataResponse.totalSheetPatients);
            setIsFirstLoad(false);
          }

          if (isCurrent()) {
            setListData(listDataResponse);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          history.push('/sheet/list');
          toast.error(err.response.data.message);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, listFilter, sheetId],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <PageTree pages={pages} />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Filter
            totalSheetPatients={totalSheetPatients}
            setListFilter={setListFilter}
            listData={listData}
            onChangePage={(page: number) => page}
          />
          <Table setListFilter={setListFilter} listData={listData} />
          {listData.sheetPatients.length > 0 && (
            <Pagination
              page={currentPage}
              totalPages={listData.totalPages}
              onChangePage={(page: number) => setCurrentPage(page)}
            />
          )}
        </>
      )}
    </DashboardTemplate>
  );
};

export default PendenciesList;
