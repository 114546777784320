import { CreateAttendanceRequestDTO } from '../../dtos/CreateAttendanceRequestDTO';
import { Appointment } from '../../entities/Appointment';
import { api } from '../api';

export const createAttendance = async ({
  attendanceType,
  patientId,
  professionalId,
  attendanceArea,
  contactPhone,
  smsText,
}: CreateAttendanceRequestDTO): Promise<Appointment> => {
  const { data } = await api.post('/attendances', {
    attendanceType,
    patientId,
    professionalId,
    attendanceArea,
    contactPhone,
    smsText,
  });

  return data;
};
