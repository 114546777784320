import { api } from '../api';
import { Appointment } from '../../entities/Appointment';

interface GetAppointmentsRequest {
  professionalId?: string;
  limit?: number;
  page?: number;
  status?: 'confirmed' | 'finished';
}

export const getAppointmentsByProfessional = async ({
  professionalId,
  limit,
  page,
  status,
}: GetAppointmentsRequest): Promise<Appointment[]> => {
  const { data } = await api.get(
    `/appointments/professional/${professionalId}`,
    {
      params: {
        limit,
        page,
        status,
      },
    },
  );

  return data;
};
