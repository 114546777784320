import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useModal } from 'react-brave-modal';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageTree from '../../../../components/PageTree';
import { ElementPageTree } from '../../../../components/PageTree/types';
import { UserFiltersDTO } from '../../../../dtos/UserFiltersDTO';
import { User } from '../../../../entities/User';
import MainAreasEnum from '../../../../enums/MainAreasEnum';

import { usePermission } from '../../../../hooks/permission';
import { useUser } from '../../../../hooks/user';
import {
  useCurrentCallback,
  useCurrentEffect,
} from '../../../../lib/use-current-effect';
import { getProfileByType } from '../../../../services/profile';
import DashboardTemplate from '../../../../templates/Dashboard';
import FilterProfileList, { FilterProfileListRef } from './FilterProfileList';
import UpdateProfileConfirmModal from './modals/UpdateProfileConfirmModal';

import { Container, PageSection, PageSectionHeader, Button } from './styles';
import UserProfileList from './UserProfileList';

interface PageParams {
  id: string;
}

export type OrderBy = 'name' | 'role' | 'profileTitle';

const UserItemProfile: React.FC = () => {
  const { hasPermission } = usePermission();
  const { id } = useParams<PageParams>();
  const [title, setTitle] = useState(id);
  const [profileID, setProfileID] = useState('');
  const filterProfileListRef = useRef<FilterProfileListRef>(null);
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState<OrderBy>('name');
  const [ordenationType, setOrdenationType] = useState<'ASC' | 'DESC'>('ASC');
  const { showModal } = useModal();
  const [type, setType] = useState<'view' | 'create'>('view');
  const [list, setList] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [itemsSelected, setItemsSelected] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<UserFiltersDTO>({});
  const { getUsers } = useUser();
  const history = useHistory();

  const pageTreeList = useMemo<ElementPageTree[]>(
    () =>
      type === 'view'
        ? [
            { name: 'ADMINISTRAÇÃO' },
            { name: 'PERFIS E PERMISSÕES', link: '/profile/list' },
            { name: title },
          ]
        : [
            { name: 'ADMINISTRAÇÃO' },
            { name: 'PERFIS E PERMISSÕES', link: '/profile/list' },
            { name: title },
            { name: 'Novo' },
          ],
    [title, type],
  );

  const handleOrdenationChange = useCallback(
    (newOrderBy: OrderBy, isInitialOrdering: boolean) => {
      setOrderBy(newOrderBy);
      setOrdenationType((state) =>
        // eslint-disable-next-line no-nested-ternary
        isInitialOrdering ? 'ASC' : state === 'ASC' ? 'DESC' : 'ASC',
      );
    },
    [],
  );

  const canChangePermission = useMemo(
    () => hasPermission('edit_profile'),
    [hasPermission],
  );

  const onUpdate = useCurrentCallback(
    (isCurrent) => () => {
      isCurrent() && setType('view');
    },
    [],
  );

  const handleSave = useCallback(
    (keys: string[]) => {
      showModal({
        type: 'custom',
        data: (
          <UpdateProfileConfirmModal
            keys={keys}
            type={profileID}
            onUpdate={onUpdate}
          />
        ),
      });
    },
    [onUpdate, profileID, showModal],
  );

  const handleClearFilters = useCallback(() => {
    setFilters({});
    filterProfileListRef.current?.clear();
  }, []);

  useCurrentEffect((isCurrent) => {
    getProfileByType(id)
      .then((resp) => {
        if (isCurrent()) {
          setTitle(resp.title ?? id);
          setProfileID(resp.id ?? '');
        }
      })
      .catch((err) => {
        history.push('/profile/list');
        toast.error(err.response.data.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCurrentEffect(
    (isCurrent) => {
      setLoading(true);

      getUsers(
        page,
        {
          profile: type === 'view' ? id : undefined,
          status: 'active',
          ...filters,
        },
        { profile: type === 'create' ? id : undefined },
        orderBy,
        ordenationType,
      )
        .then((resp) => {
          if (isCurrent()) {
            setList([...resp.list] as User[]);
            setTotalPages(resp.totalPages);
            setTotalUsers(resp.totalUsers);
          }
        })
        .finally(() => {
          if (isCurrent()) {
            setLoading(false);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, filters, id, page, orderBy, ordenationType],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <Container>
        <header>
          <PageTree pages={pageTreeList} />
        </header>
        <PageSection>
          <header>
            <h1>Perfis e Permissões</h1>
            <PageSectionHeader>
              <div>
                <h1>Usuários</h1>
                <h2>{title}</h2>
              </div>
              <div>
                {type === 'view' ? (
                  <Button
                    onClick={() => setType('create')}
                    disabled={!canChangePermission}
                  >
                    Adicionar Usuários
                  </Button>
                ) : (
                  <>
                    <Button onClick={() => setType('view')} isWhite>
                      Cancelar
                    </Button>
                    <Button
                      disabled={
                        itemsSelected.length === 0 || !canChangePermission
                      }
                      onClick={() => handleSave(itemsSelected)}
                    >
                      Salvar
                    </Button>
                  </>
                )}
              </div>
            </PageSectionHeader>
          </header>
          <FilterProfileList
            ref={filterProfileListRef}
            type={type}
            onSearch={(data) => setFilters({ ...data })}
            onChangePage={(newPage: number) => setPage(newPage)}
          />
          <UserProfileList
            type={type}
            list={list}
            totalPages={totalPages}
            totalUsers={totalUsers}
            loading={loading}
            onChangePage={(newPage: number) => setPage(newPage)}
            page={page}
            changeType={setType}
            clearFilters={handleClearFilters}
            onItemSelected={(keys: string[]) => setItemsSelected(keys)}
            onSave={handleSave}
            onOrdenationChange={(
              newOrderBy: OrderBy,
              newOrdenationType: boolean,
            ) => handleOrdenationChange(newOrderBy, newOrdenationType)}
            orderBy={orderBy}
            ordenationType={ordenationType}
          />
        </PageSection>
      </Container>
    </DashboardTemplate>
  );
};

export default UserItemProfile;
