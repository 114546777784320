const validateImage = (file: File): boolean => {
  let validationResult = false;

  if (/\.(jpe?g|png)$/i.test(file.name) && file.size <= 2000000) {
    validationResult = true;
  }

  return validationResult;
};

export default validateImage;
