import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from '../../../hooks/user';
import { usePermission } from '../../../hooks/permission';
import { useAuth } from '../../../hooks/auth';
import useQuery from '../../../hooks/query';

import DashboardTemplate from '../../../templates/Dashboard';
import PageTree from '../../../components/PageTree';
import Spinner from '../../../components/Spinner';
import { ElementPageTree } from '../../../components/PageTree/types';
import GeneralProfile from './GeneralProfile';
import ChangePassword from './ChangePassword';
import ResetPassword from './ResetPassword';
import Status from './Status';
import Reports from './Reports';

import { ReactComponent as SettingsIcon } from '../../../assets/images/settings.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/images/file2.svg';
import { ReactComponent as KeyIcon } from '../../../assets/images/key.svg';
import { ReactComponent as TurnOnOffIcon } from '../../../assets/images/turn-on-off.svg';

import {
  Container,
  ButtonGroup,
  MenuOptions,
  Option,
  OptionComponent,
  Button,
} from './styles';
import { useCurrentCallback } from '../../../lib/use-current-effect';

export interface ComponentsProfileRef {
  back: () => Promise<boolean>;
  save: () => Promise<boolean>;
}

const Profile: React.FC = () => {
  const { hasPermission } = usePermission();
  const { user: userAuth } = useAuth();
  const { user, sync, cleanLocal } = useUser();
  const [isMyUser, setIsMyUser] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const history = useHistory();
  const params = useQuery();
  const userId = useMemo(() => params.get('id') ?? undefined, [params]);

  const changePasswordRef = useRef<ComponentsProfileRef>(null);
  const resetPasswordRef = useRef<ComponentsProfileRef>(null);
  const generalProfileRef = useRef<ComponentsProfileRef>(null);
  const reportsRef = useRef<ComponentsProfileRef>(null);
  const statusRef = useRef<ComponentsProfileRef>(null);

  const loadUser = useCallback(async () => {
    if (userId) {
      await sync(userId);
    } else if (!userId && userAuth) {
      await sync(userAuth.id);
    }
  }, [sync, userId, userAuth]);

  useEffect(() => {
    loadUser();

    return () => {
      cleanLocal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (userId) {
      if (userId === userAuth?.id) {
        setIsMyUser(true);
      }
    } else if (userAuth) {
      setIsMyUser(true);
    }
  }, [userId, userAuth]);

  const pages = useMemo<ElementPageTree[]>(
    () => [
      { name: 'Administração' },
      { name: 'Usuários e profissionais', link: '/user/list' },
      { name: user?.name || '' },
    ],
    [user],
  );

  const canChangeStatus = useMemo(
    () =>
      userAuth?.id === user?.id
        ? hasPermission('edit_my_details')
        : hasPermission('edit_other_users'),
    [hasPermission, user, userAuth],
  );

  const [canUpdateUser, setCanUpdateUser] = useState(false);

  const options = useMemo<{
    [key: string]: {
      icon: JSX.Element;
      name: string;
      component: JSX.Element;
      ref: React.RefObject<any>;
      visible: boolean;
      buttons: ('back' | 'change' | 'send' | 'save')[];
    };
  }>(
    () => ({
      GeneralProfile: {
        icon: <SettingsIcon />,
        name: 'Perfil Geral',
        component: <GeneralProfile key={1} ref={generalProfileRef} />,
        ref: generalProfileRef,
        visible: true,
        buttons: ['back'],
      },
      Reports: {
        icon: <ReportsIcon />,
        name: 'Relatórios',
        component: <Reports key={2} ref={reportsRef} />,
        ref: reportsRef,
        visible: true,
        buttons: ['back'],
      },
      ChangePassword: {
        icon: <KeyIcon />,
        name: 'Trocar senha',
        component: <ChangePassword key={3} ref={changePasswordRef} />,
        ref: changePasswordRef,
        visible: isMyUser,
        buttons: ['back', 'change'],
      },
      ResetPassword: {
        icon: <KeyIcon />,
        name: 'Trocar senha',
        component: <ResetPassword key={4} ref={resetPasswordRef} />,
        ref: resetPasswordRef,
        visible: !isMyUser,
        buttons: ['back', 'send'],
      },
      Status: {
        icon: <TurnOnOffIcon />,
        name: 'Status',
        component: (
          <Status
            key={5}
            ref={statusRef}
            canChange={canChangeStatus}
            setCanUpdateUser={setCanUpdateUser}
          />
        ),
        ref: statusRef,
        visible: true,
        buttons: canChangeStatus ? ['back', 'change'] : ['back'],
      },
    }),
    [canChangeStatus, isMyUser],
  );

  const urlSelectedMenuOption = useMemo(() => {
    const selectedMenuOption = params.get('selectedMenuOption');

    return Object.keys(options).includes(selectedMenuOption ?? '')
      ? selectedMenuOption
      : undefined;
  }, [options, params]);

  const [selected, setSelected] = useState(
    urlSelectedMenuOption ?? 'GeneralProfile',
  );

  const handleChange = useCallback((keyName: string) => {
    setSelected(keyName);
  }, []);

  const handleBack = useCallback(async () => {
    const canProceed = await options[
      selected as keyof typeof options
    ]?.ref?.current?.back();

    if (canProceed) {
      history.push('/user/list');
    }
  }, [history, options, selected]);

  const handleSave = useCurrentCallback(
    (isCurrent) => async () => {
      setIsButtonDisabled(true);

      const canProceed = await options[
        selected as keyof typeof options
      ]?.ref?.current?.save();

      if (canProceed) {
        // Fazer algo
      }

      if (isCurrent()) {
        setIsButtonDisabled(false);
      }
    },
    [options, selected],
  );

  return (
    <DashboardTemplate name="">
      {user ? (
        <>
          <PageTree pages={pages} />
          <Container>
            <MenuOptions>
              <h1>Perfil do Usuário</h1>
              {Object.entries(options).map(
                ([keyName, { visible, icon, name }]) =>
                  visible && (
                    <Option
                      key={keyName}
                      onClick={() => {
                        handleChange(keyName);
                      }}
                      selected={keyName === selected}
                    >
                      {icon}
                      <span>{name}</span>
                    </Option>
                  ),
              )}
            </MenuOptions>
            <OptionComponent>
              {options[selected as keyof typeof options].component}
            </OptionComponent>
          </Container>
          <ButtonGroup>
            {options[selected as keyof typeof options].buttons.map(
              (buttonType) => (
                <React.Fragment key={buttonType}>
                  {buttonType === 'back' && (
                    <Button onClick={handleBack} color="white" lite>
                      Voltar
                    </Button>
                  )}
                  {buttonType === 'save' && (
                    <Button onClick={handleSave} color="primary">
                      Salvar
                    </Button>
                  )}
                  {buttonType === 'send' && (
                    <Button
                      loading={isButtonDisabled}
                      onClick={handleSave}
                      color="primary"
                    >
                      Enviar
                    </Button>
                  )}
                  {buttonType === 'change' && (
                    <Button
                      loading={isButtonDisabled}
                      disabled={canUpdateUser}
                      onClick={handleSave}
                      color="primary"
                    >
                      Alterar
                    </Button>
                  )}
                </React.Fragment>
              ),
            )}
          </ButtonGroup>
        </>
      ) : (
        <Spinner />
      )}
    </DashboardTemplate>
  );
};

export default Profile;
