import React, { useCallback } from 'react';
import { useModal } from 'react-brave-modal';

import { ButtonsContainer, ModalElement, ModalGrouperText, ModalGrouperImage } from './styles';

import WomanFinishedAppointment from '../../../../../assets/images/woman-finished-appointment.svg';
import Button from '../../../../Button';

interface ModalFinishedAppointmentProps {
  handleJoinTheRoom?: () => Promise<void>;
}

const ModalFinishedAppointment: React.FC<ModalFinishedAppointmentProps> = ({
  handleJoinTheRoom,
}) => {
  const { closeModal } = useModal();

  const handleReturnToRoom = useCallback(async () => {
    if (handleJoinTheRoom) {
      await handleJoinTheRoom();
    }

    closeModal();
  }, [closeModal, handleJoinTheRoom]);

  return (
    <ModalElement>
      <ModalGrouperImage>
        <img
          src={WomanFinishedAppointment}
          alt="Ícone de preenchimento de formulário"
        />
      </ModalGrouperImage>
      <ModalGrouperText>
        <div>
          <h1>Seu atendimento foi finalizado!</h1>
          {handleJoinTheRoom && (
            <ButtonsContainer>
              <Button
                buttonType="primary-alternative"
                onClick={handleReturnToRoom}
              >
                Voltar à sala
              </Button>
            </ButtonsContainer>
          )}
        </div>
      </ModalGrouperText>
    </ModalElement>
  );
};

export default ModalFinishedAppointment;
