import { subDays } from 'date-fns';
import React, { useState } from 'react';
import { MetricTypeEnum } from '../../../../../../enums/MetricTypeEnum';

import ContentChart, { ContentChartProps } from './ContentChart';
import { Container, Header, Menu } from './styles';

type MetricsType = {
  key: MetricTypeEnum;
  abbreviation: string;
  title: string;
};

type KeyOptionsType = 'summary' | 'details';

export type DateRangeType = {
  startDate: Date;
  endDate: Date;
};
export type OptionsType = {
  key: 'today' | 'week' | 'month';
  title: string;
  rangeDate: DateRangeType;
};

type ContentOptionsType = {
  key: KeyOptionsType;
  title: string;
  component: React.FC<ContentChartProps>;
};

const EvolutionModal: React.FC = () => {
  const metrics: MetricsType[] = [
    {
      key: MetricTypeEnum.BLOOD_PREASSURE,
      abbreviation: 'P.A',
      title: 'Pressão Arterial (mmHg)',
    },
    {
      key: MetricTypeEnum.HEART_BEAT_RATE,
      abbreviation: 'BPM',
      title: 'Batimento Cardíaco (bpm)',
    },
    {
      key: MetricTypeEnum.BODY_TEMPERATURE,
      abbreviation: 'ºC',
      title: 'Temperatura (ºC)',
    },
    {
      key: MetricTypeEnum.BLOOD_OXYGEN,
      abbreviation: 'OX',
      title: 'Oxigênio (SpO2%)',
    },
    {
      key: MetricTypeEnum.SLEEP,
      abbreviation: 'SONO',
      title: 'Sono (Horas dormidas)',
    },
    {
      key: MetricTypeEnum.CALORIES,
      abbreviation: 'CAL',
      title: 'Calorias (Kcal)',
    },
    {
      key: MetricTypeEnum.STEPS,
      abbreviation: 'PASSOS',
      title: 'Passos',
    },
    {
      key: MetricTypeEnum.RESPIRATORY_RATE,
      abbreviation: 'FR',
      title: 'Frequência Respiratória (mrm)',
    },
  ];

  const [metricSelected, setMetricSelected] = useState<MetricsType>(metrics[0]);

  const contentOptions: ContentOptionsType[] = [
    {
      key: 'summary',
      title: 'Resumo',
      component: ContentChart,
    },
    // {
    //   key: 'details',
    //   title: 'Detalhes',
    //   component: ContentChart,
    // },
  ];

  const [contentSelected, setContentSelected] = useState<ContentOptionsType>(
    contentOptions[0],
  );

  const today = new Date();
  const options: OptionsType[] = [
    {
      key: 'today',
      title: 'Hoje',
      rangeDate: {
        startDate: today,
        endDate: today,
      },
    },
    {
      key: 'week',
      title: 'Uma semana',
      rangeDate: {
        startDate: subDays(today, 6),
        endDate: today,
      },
    },
    {
      key: 'month',
      title: 'Um mês',
      rangeDate: {
        startDate: subDays(today, 29),
        endDate: today,
      },
    },
  ];

  const [selectedDateOption, setSelectedDateOption] = useState<OptionsType>(
    options[0],
  );

  return (
    <Container>
      <Header>
        <h1>Evolução</h1>

        {metrics.map((item) => (
          <button
            key={item.key}
            type="button"
            onClick={() => setMetricSelected(item)}
            disabled={metricSelected.key === item.key}
          >
            {item.abbreviation}
          </button>
        ))}
      </Header>

      <Menu>
        <h3>{metricSelected.title}</h3>

        {contentOptions.map((item) => (
          <button
            key={item.key}
            type="button"
            disabled={contentSelected.key === item.key}
            onClick={() => setContentSelected(item)}
          >
            {item.title}
          </button>
        ))}
      </Menu>

      <contentSelected.component
        selectedDateOption={selectedDateOption}
        updateDateOption={setSelectedDateOption}
        options={options}
        metric={metricSelected.key}
      />
    </Container>
  );
};

export default EvolutionModal;
