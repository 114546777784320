import { api } from '../api';
import { PatientMeasurementNotices } from '../../entities/PatientMeasurementNotices';

export const getCurrentPatientMeasurementNotice = async (
  patientId: string,
): Promise<PatientMeasurementNotices> => {
  const currentMeasurementNotice = (
    await api.get(`/measurements/notices/${patientId}`)
  ).data as PatientMeasurementNotices;

  return currentMeasurementNotice;
};
