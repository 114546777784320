import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { IoMdSearch } from 'react-icons/io';

import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import {
  GetSheetSubmissionsByFilter as ListFilter,
  GetSheetSubmissionsByFilterResponse as ListData,
} from '../../../../services/sheet';

import { Container, Title, GridContent, Field, Button } from './styles';
import ButtonFilterRangeDate, {
  DateValueProps,
} from '../../../../components/ButtonFilterRangeDate';

interface FilterProps {
  totalSheetSubmissions: number;
  setListFilter(listFilter: React.SetStateAction<ListFilter>): void;
  listData: ListData;
  onChangePage: (page: number) => void;
}

const Filter: React.FC<FilterProps> = ({
  totalSheetSubmissions,
  setListFilter,
  listData,
  onChangePage,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [datesSelected, setDatesSelected] = useState<Partial<DateValueProps>>(
    {},
  );

  const handleUpdateDate = useCallback(
    ({ startDate, endDate }: DateValueProps) => {
      if (startDate && endDate) {
        setDatesSelected({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        });
      }

      if (!startDate && !endDate) {
        setDatesSelected({});
      }
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    onChangePage(1);

    const { endDate, startDate } = datesSelected;

    setListFilter((prevState) => ({
      ...prevState,
      ...formRef.current?.getData(),
      endDate,
      startDate,
    }));
  }, [datesSelected, onChangePage, setListFilter]);

  return (
    <Container>
      <h1>Filtrar resultado</h1>
      <Title>
        <h2>{listData.sheetName}</h2>
        {totalSheetSubmissions === 0 && <span>Nenhuma resposta</span>}
        {totalSheetSubmissions === 1 && (
          <span>{totalSheetSubmissions} resposta</span>
        )}
        {totalSheetSubmissions > 1 && (
          <span>{totalSheetSubmissions} respostas</span>
        )}
      </Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <GridContent>
          <Field gridArea="pn">
            <label>Nome do paciente</label>
            <InputWithoutTitle name="patientName" />
          </Field>
          <Field gridArea="dt">
            <label>Período de preenchimento</label>
            <ButtonFilterRangeDate
              dateValue={datesSelected}
              onChange={handleUpdateDate}
              canClearDates
            />
          </Field>
          <Field gridArea="an">
            <label>Nome do profissional de atendimento</label>
            <InputWithoutTitle name="professionalName" />
          </Field>
          <Field gridArea="bt" isButton>
            <Button type="submit">
              <IoMdSearch />
              Buscar
            </Button>
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default Filter;
