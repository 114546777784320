import styled from 'styled-components';

export const Container = styled.div`
  width: 900px;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 450px;
`;
