import styled, { css } from 'styled-components';

interface ColorNumberProps {
  color: 'primary' | 'black';
}

interface PaginationButtonProps {
  selected?: boolean;
}

export const Container = styled.div`
  margin-top: 20px;

  > header {
    display: flex;
    justify-content: flex-end;

    > div {
      display: flex;
      align-items: center;
    }
  }

  > footer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-left: 40px;

    > button + button {
      margin-left: 30px;
    }
  }
`;

export const Table = styled.div`
  margin-top: 25px;

  > header {
    color: #707683;
    background: #eaecf0;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
  }

  > section {
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #333333;

      img {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        margin-right: 10px;
        object-fit: cover;
        object-position: center;
      }

      &:hover {
        color: #2d9cdb;
      }
    }

    > div:nth-child(3),
    > div:nth-child(4) {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #707683;
    }

    > div:nth-child(5) {
      display: flex;

      > div {
        padding: 5px 10px;
        background: #2d9cdb33;
        border-radius: 5px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #2d9cdb;
        text-transform: capitalize;
      }
    }

    > div:nth-child(6) {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > button {
        border: 0;
        background: transparent;

        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
  }

  > header,
  > section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;

    > div {
      margin: 0 8px;

      display: flex;
      align-items: center;
    }

    > div:nth-child(2) {
      flex: 5;
    }

    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5) {
      flex: 2;
    }

    > div:nth-child(6) {
      flex: 3;
      padding-right: 30px;
    }
  }
`;

export const OrderSelect = styled.select`
  color: #4f4f4f;
  border-style: unset;
  background: none;
`;

export const ColorNumber = styled.span<ColorNumberProps>`
  font-weight: bolder;
  margin: 0 5px;

  ${(props) =>
    props.color === 'black' &&
    css`
      color: ${props.theme.colors.black};
    `}

  ${(props) =>
    props.color === 'primary' &&
    css`
      color: ${props.theme.colors.primary};
    `}
`;

export const PaginationList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55px;
`;

export const PaginationButton = styled.button<PaginationButtonProps>`
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  box-sizing: border-box;
  padding: 15px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #bdbdbd;

  &:hover {
    background: whitesmoke;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #2d9cdb;
      border: 0.5px solid #2d9cdb;
      font-weight: 600;
      color: #ffffff;

      &:hover {
        background: #2d9cdb;
      }
    `}

  &:disabled {
    background: #ffffff;
    opacity: 0.6;
  }
`;

export const ActionSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;

  span {
    margin-right: 20px;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
`;
