import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';

import { Container, ButtonGroupRadio, ButtonRadio } from './styles';

interface ButtonOptions {
  name: string;
  value: string;
  color: string;
  background: string;
  backgroundSelected: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: ButtonOptions[];
  title: string;
  defaultOption?: string;
  disabledOption?: string;
  optionStyle: 'circle' | 'roundedRectangle';
}

const InputDegreeRadioColored: React.FC<InputProps> = ({
  name,
  options,
  defaultOption,
  optionStyle,
  disabledOption,
  disabled,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [elementValue, setElementValue] = useState('');

  useEffect(() => {
    if (defaultOption && !elementValue) {
      setElementValue(defaultOption);
    }
  }, [defaultOption, elementValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return ref.value;
      },
      setValue: (ref, value: string) => {
        setElementValue(value);
      },
      clearValue: () => {
        setElementValue('');
      },
    });
  }, [fieldName, registerField]);

  const handleClick = useCallback(
    (value) => {
      setElementValue(value);
    },
    [setElementValue],
  );

  const alterButtonSelected = useCallback(
    (value: string): boolean => value === elementValue,
    [elementValue],
  );

  return (
    <Container isErrored={!!error}>
      <ButtonGroupRadio>
        {options.map((valueObject) => (
          <ButtonRadio
            isErrored={!!error}
            type="button"
            onClick={() => handleClick(valueObject.value)}
            isSelected={alterButtonSelected(valueObject.value)}
            disabled={disabledOption === valueObject.value || disabled}
            key={valueObject.value}
            color={valueObject.color}
            background={valueObject.background}
            backgroundSelected={valueObject.backgroundSelected}
            optionStyle={optionStyle}
          >
            {valueObject.name}
          </ButtonRadio>
        ))}
      </ButtonGroupRadio>
      <input
        type="hidden"
        defaultValue={defaultValue}
        ref={inputRef}
        value={elementValue}
      />
    </Container>
  );
};

export default InputDegreeRadioColored;
