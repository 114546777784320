import React from 'react';
import { GoGear } from 'react-icons/go';
import { TiArrowSortedDown } from 'react-icons/ti';

import ActionMenuButton, { ActionMenuButtonProps } from '../ActionMenuButton';

import { Icons } from './styles';

const ActionMenuButtonList: React.FC<ActionMenuButtonProps> = ({ ...rest }) => {
  return (
    <ActionMenuButton {...rest}>
      <Icons>
        <GoGear />
        <TiArrowSortedDown />
      </Icons>
    </ActionMenuButton>
  );
};

export default ActionMenuButtonList;
