import { api } from '../api';

interface GetProfessionalScheduleByDayRange {
  professionalId: string;
  startDay: number;
  endDay: number;
  month: number;
  year: number;
}

interface GetProfessionalScheduleByDayRangeResponse {
  date: string;
  availabilities: {
    available: boolean;
    booked: boolean;
    interval: Date;
  }[];
}

export const getProfessionalScheduleByDayRange = async (
  data: GetProfessionalScheduleByDayRange,
): Promise<GetProfessionalScheduleByDayRangeResponse[]> => {
  const resp = await api.get('/professionalSchedule/availability/range', {
    params: {
      professionalId: data.professionalId,
      startDay: data.startDay,
      endDay: data.endDay,
      month: data.month,
      year: data.year,
    },
  });

  return resp.data;
};
