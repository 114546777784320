import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'react-brave-modal';

import { usePatient } from '../../../../../hooks/patient';

import CheckOkImage from '../../../../../assets/images/check-ok-2.svg';
import Button from '../../../../../components/Button';

import { Container } from './styles';

const FinishPatientRegisterModal: React.FC = () => {
  const history = useHistory();
  const { closeModal } = useModal();
  const { patient } = usePatient();

  const handleCheckout = useCallback(() => {
    history.push('/patient/list');
    closeModal();
  }, [closeModal, history]);

  const handleProceed = useCallback(() => {
    history.push(`/patient/profile?id=${patient?.id}`);
    closeModal();
  }, [closeModal, history, patient]);

  return (
    <Container>
      <img src={CheckOkImage} alt="Imagem de sucesso" />
      <h1>O Perfil do paciente foi cadastrado com sucesso!</h1>
      <p>
        Agora, o perfil do paciente está ativo e já pode receber agendamentos.
      </p>

      <div>
        <Button buttonType="primary" onClick={handleProceed}>
          Ver perfil
        </Button>
        <Button buttonType="primary-alternative" onClick={handleCheckout}>
          Ver pacientes
        </Button>
      </div>
    </Container>
  );
};

export default FinishPatientRegisterModal;
