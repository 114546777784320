import styled, { css } from 'styled-components';

interface InvalidAppointmentTimeErrorProps {
  isFullWidth: boolean;
}

interface PhoneSectionProps {
  isVisible: boolean;
}

export const Container = styled.div`
  display: flex;
  column-gap: 8px;
  height: 100%;
  width: 100%;
  padding: 45px 45px 0;

  h3 {
    color: ${(props) => props.theme.colors.graySecondary};
    font-size: 16px;
    font-weight: bold;
  }

  > section:first-child {
    flex: 1;

    > header {
      > h1 {
        color: ${(props) => props.theme.colors.graySecondary};
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 15px;
        margin-top: 25px;

        span {
          color: ${(props) => props.theme.colors.gray};
          font-size: 14px;
          font-weight: 500;
          margin-left: 20px;
        }
      }

      > p {
        color: ${(props) => props.theme.colors.grayAlternative};
        font-size: 14px;
        font-weight: 500;
        margin: 5px 0 30px;
        padding: 0;
      }
    }
  }

  > section:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > form {
      align-self: center;
      margin-bottom: auto;
      padding-top: 74px;
      width: 250px;
    }

    > div {
      margin: 0;
    }
  }

  /* InputSelectAlternative */
  .inputSelectTime {
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: 22px;
    text-align: center;
    width: 236px;

    .react-select__control {
      height: 50px;
    }

    .react-select__single-value {
      font-size: 16px;
    }

    .react-select__value-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const CalendarBox = styled.div`
  margin: 0 auto;
  max-width: 325px;

  .rdrWeekDays {
    margin-bottom: 5px;
  }

  .rdrMonth {
    padding: 0;
  }

  .rdrMonthAndYearWrapper {
    padding: 0;
  }

  .rdrDateRangeWrapper {
    display: flex;
    flex-direction: column;
  }

  .titleCalendar {
    color: #707683;
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
    margin: 0 0 10px 20px;
  }

  .rdrMonthAndYearPickers {
    display: flex;
    justify-content: space-between;

    span > select {
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthsVertical {
    order: 1;
  }

  .rdrWeekDay {
    color: ${(props) => props.theme.colors.black};
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.01em;
    line-height: 11px;
  }

  .rdrDay > span {
    color: #5f5c6b;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.01em;
    line-height: 12px;
  }

  .rdrDayPassive {
    color: #c6c5ce;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
  }

  .rdrInRange {
    background: rgba(45, 156, 219, 0.5);
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #5f5c6b;
  }

  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
    font-weight: bold;
  }
`;

export const BulkBox = styled.div`
  align-self: flex-end;
  margin-bottom: auto;
  padding-top: 74px;
  width: 372px;

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(45, 156, 219, 0.1);
    border-radius: 6px;
    height: 50px;
    margin-bottom: 35px;
    padding: 0 15px;

    > svg {
      height: 20px;
      width: 20px;
      margin-right: 6px;
    }

    h1 {
      color: ${(props) => props.theme.colors.graySecondary};
      font-size: 14px;
      font-weight: 600;
    }

    strong {
      color: ${(props) => props.theme.colors.primary};
      font-weight: 700;
    }

    button {
      background-color: ${(props) => props.theme.colors.primary};
      border: 0;
      border-radius: 6px;
      color: ${(props) => props.theme.colors.white};
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      height: 26px;
      width: 62px;
      margin-left: auto;
      outline: none;
    }
  }
`;

export const Profissional = styled.div`
  margin-bottom: 20px;

  h1 {
    color: ${(props) => props.theme.colors.grayAlternative};
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  div {
    display: flex;
    align-items: center;

    p {
      color: ${(props) => props.theme.colors.graySecondary};
      font-size: 14px;
      font-weight: 600;
      padding: 0;
    }

    img {
      border-radius: 50%;
      height: 30px;
      width: 30px;
      margin-left: 10px;
    }
  }
`;

export const LoadingBox = styled.div`
  margin-top: 50px;

  > div {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    height: 60px;
    width: 60px;
  }
`;

export const TimeBox = styled.div`
  height: 175px;
  overflow-y: auto;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(45, 156, 219, 0.3);
    border-radius: 6px;
    color: ${(props) => props.theme.colors.primary};
    height: 32px;
    padding: 0 15px;
  }

  svg {
    margin-right: 10px;
  }

  strong {
    font-size: 14px;
    font-weight: 700;
    margin-right: auto;
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }

  div + div {
    margin-top: 10px;
  }
`;

export const NoAvailableBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(45, 156, 219, 0.1);
  border-radius: 6px;
  height: 50px;
  margin-bottom: 35px;
  padding: 0 15px;

  svg {
    color: ${(props) => props.theme.colors.primary};
    font-size: 20px;
    margin-right: 6px;
  }

  h1 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
`;

export const InputTimeBox = styled.div`
  font-size: 16px;
  margin-bottom: 30px;
  margin-left: 38px;
  margin-top: 22px;
  text-align: center;
  width: 236px;
`;

export const InvalidAppointmentTimeError = styled.div<InvalidAppointmentTimeErrorProps>`
  background: rgba(242, 153, 74, 0.1);
  border-radius: 6px;
  color: #f2994a;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-top: 25px;
  padding: 12px 15px;

  ${(props) =>
    !props.isFullWidth &&
    css`
      margin-right: 78px;
    `}

  > strong {
    font-weight: bolder;
  }
`;

export const PhoneSection = styled.div<PhoneSectionProps>`
  display: ${(props) => (props.isVisible ? 'initial' : 'none')};

  > div {
    display: flex;
    justify-content: flex-start;
    column-gap: 15px;
    margin-top: 22px;

    button {
      margin: 0;
    }
  }
`;
