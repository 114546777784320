import styled, { css } from 'styled-components';

interface ButtonProps {
  selected: boolean;
}

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  height: 630px;
  width: 100%;
  padding: 80px 50px;
`;

export const MessageTable = styled.div`
  margin-top: 30px;

  > header {
    display: grid;
    grid-template-columns: 2fr 4fr 3fr 3fr;
    border-radius: 4px 4px 0 0;
    background: rgba(189, 189, 189, 0.2);
    padding: 10px 20px;

    > h3 {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #707683;
      margin: 0 10px;
    }
  }

  > div {
    overflow-y: auto;
    max-height: 350px;
    height: 100%;

    > section {
      display: grid;
      grid-template-columns: 2fr 3fr 1fr 3fr 3fr;
      padding: 8px 20px;
      background: #fdfdfd;

      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #707683;

      > div {
        margin: 0 10px;
      }

      > div > p {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;

        overflow: hidden;
        text-overflow: ellipsis;

        padding: 0;
        position: relative;
      }
    }

    > section + section {
      border-top: 1px solid #eaeaea;
    }
  }
`;

export const CardHeader = styled.div`
  h1 {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const CardContent = styled.div`
  font-size: 14px;
  margin-top: 32px;
  max-width: 800px;
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StatusBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled.div`
  margin-top: 6px;
`;

export const Button = styled.button<ButtonProps>`
  border: none;
  border-radius: 5px;
  color: #707683;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  width: 145px;
  margin-right: 20px;
  outline: none;
  transition: 0.2s;

  &:hover {
    background-color: #daedf7;
    color: ${(props) => props.theme.colors.primary};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: #daedf7;
      color: ${(props) => props.theme.colors.primary};
    `}

  @media (max-width: 1029px) {
    & + button {
      margin-top: 8px;
    }
  }
`;

export const DateBox = styled.div`
  display: flex;
  flex-direction: column;

  span:last-child {
    font-weight: 600;
    margin-top: 6px;
  }
`;

export const ObservationBox = styled.div`
  margin-top: 30px;

  form > div {
    margin-top: 6px;
  }

  textarea {
    height: 60px;
  }
`;
