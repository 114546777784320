/* eslint-disable import/no-duplicates */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { format, isToday } from 'date-fns';
import { IoIosArrowForward } from 'react-icons/io';
import { useModal } from 'react-brave-modal';

import { User } from '../../entities/User';
import { usePermission } from '../../hooks/permission';
import { getAppointmentsByProfessional } from '../../services/appointments';

import Spinner from '../Spinner';
import CreateEditAppointmentModal from '../../modals/CreateEditAppointmentModal';
import Button from '../ButtonBlue';

import { ReactComponent as FilmIcon } from '../../assets/images/icon_schedule_film_spacegray.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/icon_schedule_phone_spacegray.svg';
import { ReactComponent as MessageIcon } from '../../assets/images/icon_schedule_message_spacegray.svg';
import { ReactComponent as ScheduleImage } from '../../assets/images/agenda.svg';

import {
  Container,
  CardHeader,
  CardContent,
  ContentArea,
  DateBox,
  AppointmentBox,
  AppointmentInfo,
  LinkWhite,
  ButtonGroup,
  EmptyList,
  StyledPatientLink,
} from './styles';

import { useCurrentCallback } from '../../lib/use-current-effect';
import AppointmentTypeEnum from '../../enums/AppointmentTypeEnum';

interface SectionListProps {
  friendlyDateName: string;
  appointments: {
    id: string;
    type: AppointmentTypeEnum;
    patientName: string;
    patientId: string;
    time: string;
  }[];
}

interface ProfessionalNextAppointmentsProps {
  user?: User;
}

const ProfessionalNextAppointments: React.FC<
  ProfessionalNextAppointmentsProps
> = ({ user }) => {
  const { hasPermission } = usePermission();
  const { showModal } = useModal();

  const [sectionList, setSectionList] = useState<SectionListProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const canCreateAppointment = useMemo(
    () =>
      hasPermission('register_appointment') && user?.type === 'professional',
    [hasPermission, user],
  );

  const getAppointment = useCurrentCallback(
    (isCurrent) => async (professionalId: string) => {
      const appointments = await getAppointmentsByProfessional({
        professionalId,
        limit: 10,
        page: 1,
        status: 'confirmed',
      });

      const appointmentWithFriendlyDate = appointments.map((appointment) => {
        const date = new Date(appointment.date ?? '');

        const friendlyDate = isToday(date)
          ? 'Hoje'
          : format(date, "dd'/'MM'/'yyy");

        return {
          ...appointment,
          friendlyDate,
        };
      });

      const newSectionList: SectionListProps[] = [];

      appointmentWithFriendlyDate.forEach((appointment) => {
        const sectionId = newSectionList.findIndex(
          (section) => section.friendlyDateName === appointment.friendlyDate,
        );

        const newAppointment = {
          id: appointment.id ?? '',
          patientName: appointment.patient?.user?.name ?? '',
          patientId: appointment.patient?.id ?? '',
          time: format(new Date(appointment.date ?? ''), "HH':'mm"),
          type: appointment.type as AppointmentTypeEnum,
        };

        if (sectionId >= 0) {
          newSectionList[sectionId].appointments.push(newAppointment);
        } else {
          newSectionList.push({
            friendlyDateName: appointment.friendlyDate,
            appointments: [newAppointment],
          });
        }
      });

      if (isCurrent()) {
        setLoading(false);
        setSectionList(newSectionList);
      }
    },
    [],
  );

  const handleCreateNewAppointment = useCallback(() => {
    showModal({
      type: 'custom',
      data: (
        <CreateEditAppointmentModal
          onFinish={() =>
            user && user.professional_id && getAppointment(user.professional_id)
          }
        />
      ),
    });
  }, [showModal, getAppointment, user]);

  useEffect(() => {
    if (user && user.professional_id) {
      getAppointment(user.professional_id);
    } else if (user && !user.professional_id) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Container gridArea="na">
      <CardHeader>
        <h1>Próximos Atendimentos</h1>
        <Link to="/appointments/list?appointments=my">
          Ver todos
          <IoIosArrowForward />
        </Link>
      </CardHeader>

      {loading && <Spinner />}

      {!loading && sectionList.length > 0 && (
        <CardContent>
          <ContentArea>
            {sectionList.map((section) => (
              <DateBox key={section.friendlyDateName}>
                <h2>{section.friendlyDateName}</h2>
                {section.appointments.map((appointment) => (
                  <AppointmentBox key={appointment.id}>
                    <AppointmentInfo>
                      <span>{appointment.time}</span>
                      {appointment.type === AppointmentTypeEnum.PHONE && (
                        <PhoneIcon />
                      )}
                      {appointment.type === AppointmentTypeEnum.VIDEO && (
                        <FilmIcon />
                      )}
                      {appointment.type === AppointmentTypeEnum.SMS && (
                        <MessageIcon />
                      )}
                      <StyledPatientLink
                        to={`/patient/profile?id=${appointment.patientId}`}
                      >
                        {appointment.patientName}
                      </StyledPatientLink>
                    </AppointmentInfo>
                    <LinkWhite
                      to={`/patient/profile?id=${appointment.patientId}`}
                    >
                      Ver perfil
                    </LinkWhite>
                  </AppointmentBox>
                ))}
              </DateBox>
            ))}
          </ContentArea>
          <ButtonGroup>
            <Button
              type="button"
              onClick={handleCreateNewAppointment}
              disabled={!canCreateAppointment}
            >
              Agendar atendimento
            </Button>

            <Link to="/appointments/list?appointments=my">
              Ver todos
              <IoIosArrowForward />
            </Link>
          </ButtonGroup>
        </CardContent>
      )}

      {!loading && sectionList.length <= 0 && (
        <EmptyList>
          <ScheduleImage />
          <span>
            Configure os horários disponíveis em Agenda e Preferências
          </span>
          <span>Ainda não há nenhum agendamento.</span>
          <Button
            type="button"
            onClick={handleCreateNewAppointment}
            disabled={!canCreateAppointment}
          >
            Agendar atendimento
          </Button>
        </EmptyList>
      )}
    </Container>
  );
};

export default ProfessionalNextAppointments;
