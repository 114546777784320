import React, { useCallback } from 'react';

import { useModal } from 'react-brave-modal';

import { useHistory } from 'react-router-dom';
import Button from '../../../../../components/Button';
import { useInstitution } from '../../../../../hooks/institution';
import { ButtonsContainer, ModalElement, ModalGrouper } from './styles';

const CancelRegisterModal: React.FC = () => {
  const { closeModal } = useModal();
  const { cleanLocal } = useInstitution();
  const history = useHistory();
  const {
    location: { state: locationState },
  } = history;

  const handleCancel = useCallback(() => {
    cleanLocal();
    let hasPreviousPath = false;

    if (locationState) {
      hasPreviousPath = !!(history.location.state as { from?: string })?.from
        ?.length;
    }

    if (hasPreviousPath) {
      history.goBack();
    } else {
      history.push('/institution/list');
    }
    closeModal();
  }, [cleanLocal, closeModal, history, locationState]);

  const handleContinue = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <ModalElement>
      <ModalGrouper>
        <h1>Deseja realmente cancelar? Os dados não serão salvos.</h1>
        <ButtonsContainer>
          <Button buttonType="primary" onClick={handleContinue}>
            Não, continuar criando
          </Button>
          <Button buttonType="primary-alternative" onClick={handleCancel}>
            Sim, cancelar
          </Button>
        </ButtonsContainer>
      </ModalGrouper>
    </ModalElement>
  );
};

export default CancelRegisterModal;
