import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 445px;
  width: 545px;

  > section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > img {
        margin-bottom: 35px;
      }

      > h1 {
        color: ${(props) => props.theme.colors.primary};
        font-size: 24px;
        font-style: normal;
        font-weight: bold;
        line-height: 18px;
        padding-bottom: 7px;
        text-align: center;
      }

      > button {
        margin-top: 30px;
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 30px;

  > button + button {
    margin-left: 15px;
  }
`;
