import { AddHealthConditionPatientRequestDTO } from '../../dtos/AddHealthConditionPatientRequestDTO';
import { Patient } from '../../entities/Patient';
import { api } from '../api';

export const addHealthConditionService = async ({
  icd10Id,
  patientId,
  severity,
  isMainHealthCondition,
  medicine,
  observation,
}: AddHealthConditionPatientRequestDTO): Promise<Patient> => {
  const { data } = await api.post('/patients/icd10', {
    icd10Id,
    patientId,
    severity,
    isMainHealthCondition,
    medicine,
    observation,
  });

  return data;
};
