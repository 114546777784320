import axios from 'axios';
import history from './history';

const baseApiURL = process.env.REACT_APP_API_BASE_URL;

const apiTwilioRecordings = axios.create({
  baseURL: baseApiURL,
});

apiTwilioRecordings.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers.authorization = `${process.env.REACT_APP_TWILIO_API_KEY}-${process.env.REACT_APP_TWILIO_API_SECRET}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiTwilioRecordings.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('@Cinco:token');
      history.push('/');
    }
    return Promise.reject(error);
  },
);

export { apiTwilioRecordings };
