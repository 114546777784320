import styled from 'styled-components';

import { Container as GridCardContainer } from '../../../../components/GridCard/styles';

export const Container = styled(GridCardContainer)`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  padding: 1.5rem;
  h3 {
    font-weight: 600;
    font-size: 1rem;
  }

  p {
    font-weight: 400;
    font-size: 0.8rem;
    margin: 1rem 0 2.5rem 0;
    line-height: 18px;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    letter-spacing: 1.25px;

    height: 36px;
    > svg {
      width: 15px;
      height: 15px;
      margin-left: 5px;
    }
  }
`;
