import React, { useCallback, useState } from 'react';

import { ActionsProps } from '../..';
import { Title } from '../../../styles';
import Banner from '../../../../../../assets/images/sheet_attendance_banner.svg';

import { Container, FormComponent } from './styles';

import { usePatient } from '../../../../../../hooks/patient';
import InputSelectAlternative from '../../../../../../components/InputSelectAlternative';
import { getSheets } from '../../../../../../services/sheet';
import { useCurrentEffect } from '../../../../../../lib/use-current-effect';

interface ItemProps {
  id: string;
  name: string;
  status: 'received' | 'not_received' | 'removed';
}

interface SelectItemProps {
  label: string;
  value: string;
}

const RequestAnother: React.FC<ActionsProps> = ({ goTo }) => {
  const [list, setList] = useState<SelectItemProps[]>();

  const { associateSheet } = usePatient();

  const [selected, setSelected] = useState<string>();

  const getList = useCallback(async (isCurrent?: () => boolean) => {
    const dataSheet = await getSheets();
    const listSheet: SelectItemProps[] = dataSheet.map((sheet) => ({
      value: sheet.id,
      label: sheet.name,
    }));

    if (isCurrent && isCurrent()) {
      setList(listSheet);
    }
  }, []);

  const handleAssociate = useCallback(async () => {
    if (associateSheet) {
      await associateSheet(selected ?? '');
      if (goTo) {
        goTo('fill-form');
      }
    }
  }, [associateSheet, goTo, selected]);

  useCurrentEffect((isCurrent) => {
    (async () => {
      await getList(isCurrent);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <header>
        <Title>Solicitar outra Ficha</Title>
      </header>
      <section>
        <img src={Banner} alt="ficha" />
        <div>
          <h1>Selecione a ficha a ser preenchida:</h1>
          <FormComponent onSubmit={() => {}}>
            <InputSelectAlternative
              name="sheet"
              options={list}
              onChange={(data: any) => setSelected(data?.value)}
            />
          </FormComponent>
        </div>
      </section>
      <footer>
        <button type="button" disabled={!selected} onClick={handleAssociate}>
          Continuar
        </button>
        <button type="button" onClick={() => goTo && goTo('fill-form')}>
          Cancelar
        </button>
      </footer>
    </Container>
  );
};

export default RequestAnother;
