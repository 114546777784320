import React from 'react';

import { Container } from './styles';

interface InputProps {
  change: Function;
  defaultValue: boolean;
  disabled?: boolean;
}

const InputSwitchWithoutForm: React.FC<InputProps> = ({
  change,
  defaultValue,
  disabled,
}) => {
  return (
    <Container disabled={disabled ?? false}>
      <label className="switch">
        <input
          type="checkbox"
          onChange={(value) => change(value.target.checked)}
          defaultChecked={defaultValue}
          disabled={disabled}
        />
        <span className="slider round" />
      </label>
    </Container>
  );
};

export default InputSwitchWithoutForm;
