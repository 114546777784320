/* eslint-disable import/no-duplicates */
import React, { useCallback, useState, useEffect } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import { useModal } from 'react-brave-modal';

import { User } from '../../entities/User';
import { getAppointmentsByProfessional } from '../../services/appointments';

import Spinner from '../Spinner';
import CardContentNothingToDisplay from '../CardContentNothingToDisplay';
import ShowMessageScheduleModal from '../../modals/ShowMessageScheduleModal';

import BlankSketchesImage from '../../assets/images/blank-sketches.svg';

import {
  Container,
  CardHeader,
  CardContent,
  AppointmentSection,
  YearTitle,
  MonthTitle,
  DateAppointmentSection,
  InformationsAppointmentSection,
  LineAppointmentSection,
  CardFooter,
  StyledLink,
} from './styles';
import { useCurrentCallback } from '../../lib/use-current-effect';
import AppointmentTypeEnum from '../../enums/AppointmentTypeEnum';

type AppointmentData = {
  id: string;
  day: string;
  title: string;
  patientName: string;
  patientId?: string;
  type: AppointmentTypeEnum;
  message?: string;
  contactPhone?: string;
};
interface ItemProps {
  year: string;
  months: {
    name: string;
    appointments: AppointmentData[];
  }[];
}

interface ProfessionalAppointmentHistoryProps {
  user?: User;
}

const ProfessionalAppointmentHistory: React.FC<
  ProfessionalAppointmentHistoryProps
> = ({ user }) => {
  const history = useHistory();
  const { showModal } = useModal();

  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<ItemProps[]>([]);

  const getAppointmentList = useCurrentCallback(
    (isCurrent) => async (professionalId: string) => {
      const appointments = await getAppointmentsByProfessional({
        professionalId,
        limit: 10,
        page: 1,
        status: 'finished',
      });

      const newList: ItemProps[] = [];

      appointments.forEach((appointment) => {
        const dateAppointment = new Date(
          appointment.details?.start ?? appointment.date ?? '',
        );

        const monthAppointment = format(dateAppointment, 'MMMM', {
          locale: ptBR,
        });

        let appointmentTitle = '';

        switch (appointment.type) {
          case AppointmentTypeEnum.VIDEO:
            appointmentTitle = 'Chamada de Vídeo';
            break;
          case AppointmentTypeEnum.PHONE:
            appointmentTitle = 'Chamada telefônica';
            break;
          case AppointmentTypeEnum.SMS:
            appointmentTitle = 'Mensagem de texto';
            break;
          default:
            break;
        }

        const yearIndex = newList.findIndex(
          (item) => item.year === dateAppointment.getFullYear().toString(),
        );

        const monthIndex = newList[yearIndex]?.months?.findIndex(
          (item) => item.name === monthAppointment,
        );

        const appointmentData: AppointmentData = {
          id: appointment.id ?? '',
          day: format(dateAppointment, 'dd'),
          title: appointmentTitle,
          patientId: appointment.patientId,
          patientName: appointment.patient?.user?.name ?? '',
          type: appointment.type as AppointmentTypeEnum,
          message: appointment.message?.text || undefined,
          contactPhone: appointment.contact_phone || undefined,
        };

        if (yearIndex === -1) {
          newList.push({
            year: dateAppointment.getFullYear().toString(),
            months: [
              {
                name: monthAppointment,
                appointments: [{ ...appointmentData }],
              },
            ],
          });
        } else if (monthIndex === -1) {
          newList[yearIndex].months.push({
            name: monthAppointment,
            appointments: [{ ...appointmentData }],
          });
        } else {
          newList[yearIndex].months[monthIndex].appointments.push({
            ...appointmentData,
          });
        }
      });

      if (isCurrent()) {
        setLoading(false);
        setList(newList);
      }
    },
    [],
  );

  useEffect(() => {
    if (user && user.professional_id) {
      getAppointmentList(user.professional_id);
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleGoToConsult = useCallback(
    (id: string, type: AppointmentTypeEnum, text?: string, phone?: string) => {
      if (
        type === AppointmentTypeEnum.VIDEO ||
        type === AppointmentTypeEnum.PHONE
      ) {
        history.push(`/patient/attendance?id=${id}`);
      } else if (text && phone) {
        showModal({
          type: 'custom',
          data: (
            <ShowMessageScheduleModal
              message={{
                text,
                phone,
              }}
            />
          ),
        });
      }
    },
    [history, showModal],
  );

  return (
    <Container gridArea="ah">
      <CardHeader>
        <h1>Histórico de Atendimentos</h1>
      </CardHeader>

      <CardContent>
        {loading && <Spinner />}

        {!loading && list.length > 0 && (
          <section>
            {list.map((item) => (
              <React.Fragment key={item.year}>
                <YearTitle>{item.year}</YearTitle>
                {item.months.map((monthItem) => (
                  <React.Fragment key={monthItem.name}>
                    <MonthTitle>{monthItem.name}</MonthTitle>
                    {monthItem.appointments.map((appointmentItem, index) => (
                      <AppointmentSection key={appointmentItem.id}>
                        <DateAppointmentSection>
                          {appointmentItem.day}
                        </DateAppointmentSection>
                        <InformationsAppointmentSection>
                          <h2>{appointmentItem.title}</h2>
                          <StyledLink
                            to={`/patient/profile?id=${appointmentItem.patientId}`}
                          >
                            {appointmentItem.patientName}
                          </StyledLink>
                          <button
                            type="button"
                            onClick={() =>
                              handleGoToConsult(
                                appointmentItem.id,
                                appointmentItem.type,
                                appointmentItem.message,
                                appointmentItem.contactPhone,
                              )
                            }
                          >
                            Ver detalhes
                          </button>
                        </InformationsAppointmentSection>
                        {monthItem.appointments.length - 1 !== index && (
                          <LineAppointmentSection />
                        )}
                      </AppointmentSection>
                    ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </section>
        )}

        {!loading && list.length <= 0 && (
          <CardContentNothingToDisplay imageWidth="140px">
            <img src={BlankSketchesImage} alt="" />
            Nenhum atendimento realizado
          </CardContentNothingToDisplay>
        )}
      </CardContent>
      <CardFooter />
    </Container>
  );
};

export default ProfessionalAppointmentHistory;
